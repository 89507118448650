import React from "react";

const IconAlertTriangle = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.89623 1.71126C8.2332 1.52154 8.61337 1.42188 9.00008 1.42188C9.38678 1.42188 9.76695 1.52154 10.1039 1.71126C10.4409 1.90097 10.7233 2.17433 10.9238 2.50496L10.926 2.50852L17.2785 13.1135L17.2846 13.1239C17.481 13.4641 17.585 13.8498 17.5861 14.2427C17.5872 14.6356 17.4854 15.0219 17.2908 15.3632C17.0963 15.7045 16.8158 15.989 16.4771 16.1882C16.1385 16.3875 15.7537 16.4946 15.3608 16.4989L15.3526 16.499L2.63933 16.4989C2.24648 16.4946 1.86161 16.3875 1.52301 16.1882C1.1844 15.989 0.903866 15.7045 0.709314 15.3632C0.514761 15.0219 0.412971 14.6356 0.414071 14.2427C0.415171 13.8498 0.519123 13.4641 0.715584 13.1239L0.721676 13.1135L7.07632 2.50496C7.27687 2.17433 7.55926 1.90097 7.89623 1.71126ZM9.00008 2.92188C8.87118 2.92188 8.74445 2.9551 8.63213 3.01834C8.52031 3.08129 8.42653 3.17187 8.35973 3.28141L2.01209 13.8783C1.94821 13.9907 1.91443 14.1176 1.91407 14.2469C1.9137 14.3779 1.94763 14.5067 2.01248 14.6204C2.07733 14.7342 2.17084 14.829 2.28371 14.8954C2.39559 14.9613 2.52262 14.9969 2.65237 14.9989H15.3478C15.4775 14.9969 15.6046 14.9613 15.7164 14.8954C15.8293 14.829 15.9228 14.7342 15.9877 14.6204C16.0525 14.5067 16.0865 14.3779 16.0861 14.2469C16.0857 14.1176 16.052 13.9907 15.9881 13.8783L9.64133 3.2829C9.64102 3.28241 9.64072 3.28191 9.64042 3.28141C9.57362 3.17187 9.47984 3.08129 9.36802 3.01834C9.2557 2.9551 9.12898 2.92188 9.00008 2.92188Z"
        fill="#EA8124"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9 6C9.41421 6 9.75 6.33579 9.75 6.75V9.75C9.75 10.1642 9.41421 10.5 9 10.5C8.58579 10.5 8.25 10.1642 8.25 9.75V6.75C8.25 6.33579 8.58579 6 9 6Z"
        fill="#EA8124"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.25 12.75C8.25 12.3358 8.58579 12 9 12H9.0075C9.42171 12 9.7575 12.3358 9.7575 12.75C9.7575 13.1642 9.42171 13.5 9.0075 13.5H9C8.58579 13.5 8.25 13.1642 8.25 12.75Z"
        fill="#EA8124"
      />
    </svg>
  );
};

export default IconAlertTriangle;
