import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

import { emailReminder, getExpressUser, deleteExpressUser } from "../../../api";
import _ from "../../../i18n";
import { StatusButton } from "../../";
import Loader from "../../common/Loader";
import styles from "./Collaborator.module.scss";
import Button from "../../common/Button";

const UserExpressList = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [status, setStatus] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(false);

  let { isLoading, data } = useQuery(
    ["listExpress", token, status],
    async () => {
      if (token) {
        const response = await getExpressUser({
          token,
          status,
          fiduciaryId: fiduciary.id,
        });
        return response.data;
      }
    }
  );

  //   useEffect(() => {
  //     if (props.refreshList) {
  //       queryClient.invalidateQueries("listCollaborators");
  //       props.setRefreshList(false);
  //     }
  //   }, [props.refreshList]);

  const handleClick = (id) => {
    setIsSending(true);
    emailReminder({ token, id, language })
      .then(() => {
        toast.success(_("reminder_email_sent_successfully"));
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      })

      .finally(() => setIsSending(false));
  };

  const tabs = ["in_progress", "PENDING", "REJECTED"];

  const toggleDropDown = (e, userId) => {
    e.stopPropagation();
    dropDownId === userId ? setDropDownId(null) : setDropDownId(userId);
  };

  const handleDeleteAction = () => {
    if (currentDeleteItem) {
      setDeleting(true);
      deleteExpressUser(auth.token, currentDeleteItem.id)
        .then((resp) => {
          queryClient.invalidateQueries("listExpress");
          setTimeout(() => {
            toast.success(_("successfully_deleted"));
            setDeleting(false);
            setIsOpenDeleteModal(false);
          }, 1000);
        })
        .catch((e) => {
          setDeleting(false);
        });
    }
  };

  const getActions = (item) => {
    if (
      auth.user.role === "OFFICIAL" ||
      auth.user.role === "EXTERNAL" ||
      ((auth.user.role === "MANAGER" ||
        auth.user.role == "FIDUCIARIES_ADMIN") &&
        item.role.type === "LEGAL_REPRESENTATIVE")
    ) {
      return null;
    }
    if (
      "LEGAL_REPRESENTATIVE" === auth.user.role &&
      "ADMIN" === auth.user.role.typeStatus &&
      "LEGAL_REPRESENTATIVE" === item.role.type &&
      auth.user.id !== item.id
    ) {
      return null;
    }

    return (
      <div className={styles.dropDownContainer}>
        <span
          className={`${styles.cellIcon} ${
            dropDownId === item.id && styles.active
          }`}
        >
          <span
            className={`${
              dropDownId === item.id ? "icon-ttp-close" : "icon-ttp-three-dots"
            } ${styles.settingsIcon}`}
            onClick={(e) => toggleDropDown(e, item.id)}
          />
        </span>
        <ul
          className={`${styles.menuDropdown} ${
            dropDownId === item.id && styles.show
          }`}
        >
          {(!["LEGAL_REPRESENTATIVE", "MANAGER"].includes(auth.user.role) ||
            "ADMIN" === auth.user.type) &&
            auth.user.id !== item.id && (
              <li
                onClick={() => {
                  setCurrentDeleteItem(item);
                  setIsOpenDeleteModal(true);
                }}
              >
                {_("delete")}
              </li>
            )}
        </ul>
      </div>
    );
  };

  return (
    <div className={props.addFormClass ? styles.form : ""}>
      <div
        className={`${styles.form_content} ${
          props.addFormClass && styles.form_content_pad
        } ${isSending && styles.loading} 
        `}
      >
        {props.setSideBar && (
          <div
            className={styles.close}
            onClick={() => props.setSideBar("DEFAULT")}
          >
            <i className="icon-ttp-close"></i>
          </div>
        )}
        <div className={styles.groupTitle}>
          <h4>User Express list</h4>

          <div
            className={styles.addBtn}
            onClick={() =>
              props.page === "join"
                ? props.setCurrentTab("EXPRESS")
                : props.setSideBar("COLLABORATOR_EXPRESS")
            }
          >
            {_("add")}
          </div>
        </div>
        <ul className={styles.status}>
          <li
            className={status === "" ? styles.active : ""}
            onClick={() => setStatus("")}
          >
            {_("all")}
          </li>
          {tabs.map((tab) => (
            <li
              key={tab}
              className={status === tab ? styles.active : ""}
              onClick={() => setStatus(tab)}
            >
              {_(tab.toLocaleLowerCase())}
            </li>
          ))}
        </ul>
        <table className={`${styles.table} unstriped`}>
          <thead>
            <tr>
              <th width="70%">
                <span>Email</span>
              </th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={6} className={styles.centerCell}>
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                </td>
              </tr>
            ) : data?.data && data.data.length > 0 ? (
              data.data.map((item) => (
                <tr key={item.id}>
                  <td>{item.email}</td>
                  <td className={styles.nopad}>
                    <StatusButton
                      status={
                        item.status === "VALIDATED"
                          ? `${item.status}_EXPRESS`
                          : item.status
                      }
                      handleClick={() => handleClick(item.id)}
                    />
                  </td>
                  <td className={styles.nopad}>{getActions(item)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className={styles.centerCell}>
                  {_("empty_list")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* {props.setSideBar && (
          <div className={styles.left} style={{ marginTop: "1rem" }}>
            <Button
              variant="default"
              onClick={() => props.setSideBar("DEFAULT")}
            >
              {_("cancel")}
            </Button>
          </div>
        )} */}
      </div>

      <div className={styles.actions}>
        {props.setSideBar ? (
          <Button variant="default" onClick={() => props.setSideBar("DEFAULT")}>
            {_("cancel")}
          </Button>
        ) : (
          <div></div>
        )}
        {props.page === "join" && (
          <Button onClick={() => props.setCurrentTab("EXPRESS")}>
            {_("add")}
          </Button>
        )}
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={deleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default UserExpressList;
