import { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./PeriodChoice.module.scss";

import _ from "../../../i18n";
import { displayDocument } from "../../../api";

const PeriodChoice = ({ period, setPeriod, rate }) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  if (!fiduciary || !rate) {
    return null;
  }

  return (
    <div className="grid-container">
      <div className={styles.content}>
        <div className={styles.top}>
          <div>
            <h3>Abonnement United Associates</h3>
            <p>
              L'adhésion au réseau se calcule sur la base de la taille de votre
              cabinet (nombre de collaborateur). Vous disposez d'une simulation
              du prix immédiate.
            </p>
          </div>
          <div className={styles.top_right}>
            <p>{fiduciary.name}</p>
            <p>{fiduciary.address1}</p>
            <p>{fiduciary.zipCode + " " + fiduciary.city}</p>
          </div>
        </div>

        {!rate.isBeta && (
          <div className={styles.savings}>
            Économisez 30%{" "}
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_56231_49405)">
                <path
                  d="M15.2083 16.161L10.0857 14.7884C9.92589 14.7427 9.79014 14.6367 9.70705 14.4928C9.62396 14.3489 9.60004 14.1783 9.64035 14.0171C9.68429 13.8562 9.78997 13.7192 9.9344 13.6358C10.0788 13.5524 10.2504 13.5294 10.4117 13.5718L14.0761 14.5594L11.08 9.37C10.1668 7.79251 8.66523 6.64164 6.90461 6.16988C5.14399 5.69812 3.26813 5.944 1.68856 6.85358C1.54501 6.93646 1.37441 6.95892 1.2143 6.91601C1.05419 6.87311 0.917676 6.76836 0.834796 6.62481C0.751916 6.48126 0.729456 6.31066 0.772358 6.15055C0.81526 5.99044 0.920009 5.85392 1.06356 5.77104C2.93071 4.69781 5.14706 4.40819 7.2273 4.96558C9.30753 5.52298 11.0821 6.88198 12.1625 8.745L15.1586 13.9344L16.1355 10.2671C16.1909 10.1201 16.2989 9.99888 16.4387 9.9271C16.5784 9.85532 16.7398 9.83808 16.8916 9.87874C17.0433 9.9194 17.1745 10.0151 17.2597 10.1471C17.3448 10.2791 17.3778 10.4381 17.3522 10.5931L15.9796 15.7157C15.9356 15.8766 15.8299 16.0136 15.6855 16.097C15.5411 16.1804 15.3696 16.2034 15.2083 16.161Z"
                  fill="#18A0FB"
                />
              </g>
              <defs>
                <clipPath id="clip0_56231_49405">
                  <rect
                    width="20"
                    height="20"
                    fill="white"
                    transform="translate(20) rotate(90)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
        )}
        <div
          className={`${styles.period} ${
            rate.isBeta ? styles.period_center : ""
          }`}
        >
          {/* <div className={styles.period_item}>
            <div
              className={`${styles.period_item_box} ${
                period === "DAILY" && styles.checked
              }`}
              onClick={() => setPeriod("DAILY")}
            >
              <p className={styles.period_name}>Paiement Journalier</p>
              <p className={styles.period_price}>
                {rate.daily}€ <span>HTVA</span> <span>/par mois</span>
              </p>

              {period === "DAILY" ? (
                <div className={styles.period_item_checkicon}>
                  <i className="icon-ttp-check"></i>
                </div>
              ) : (
                <div className={styles.period_item_check}></div>
              )}
            </div>
          </div> */}

          <div className={styles.period_item}>
            <div
              className={`${styles.period_item_box} ${
                period === "MONTHLY" && styles.checked
              }`}
              onClick={() => setPeriod("MONTHLY")}
            >
              <p className={styles.period_name}>Paiement Mensuel</p>
              <p className={styles.period_price}>
                {rate.monthly}€ <span>HTVA</span> <span>/par mois</span>
              </p>
              <p className={styles.period_txt}>
                soit {rate.monthlyPerYear}€ /par an
              </p>

              {period === "MONTHLY" ? (
                <div className={styles.period_item_checkicon}>
                  <i className="icon-ttp-check"></i>
                </div>
              ) : (
                <div className={styles.period_item_check}></div>
              )}
            </div>
            {rate.monthlyPerPerson > 0 && (
              <p className={styles.period_collab}>
                Prix par collaborateur : <b>{rate.monthlyPerPerson}€</b> /par
                mois
              </p>
            )}
          </div>

          {!rate.isBeta && (
            <div className={styles.period_item}>
              <div
                className={`${styles.period_item_box} ${
                  period === "YEARLY" && styles.checked
                }`}
                onClick={() => setPeriod("YEARLY")}
              >
                <p className={styles.period_name}>Paiement Annuel</p>
                <p className={styles.period_price}>
                  {rate.yearly}€ <span>HTVA</span> <span>/par an</span>
                </p>
                <p className={styles.period_txt}>
                  soit {rate.yearlyPerMonth}€ /par mois
                </p>

                {period === "YEARLY" ? (
                  <div className={styles.period_item_checkicon}>
                    <i className="icon-ttp-check"></i>
                  </div>
                ) : (
                  <div className={styles.period_item_check}></div>
                )}
              </div>
              <p className={styles.period_collab}>
                Prix par collaborateur : <b>{rate.yearlyPerMonthPerson}€</b>{" "}
                /par mois
              </p>
            </div>
          )}
        </div>

        <div className={styles.freePeriod}>
          {period === "YEARLY" ? (
            <>
              <p className={styles.freePeriod_title}>
                <b>Satisfait ou remboursé</b> pendant vos 30 premiers jours.
              </p>
              <p>
                Gardez l'esprit tranquille en sachant que votre abonnement est{" "}
                <b>valable jusqu'au {rate.nextYear}.</b>
              </p>
            </>
          ) : (
            <>
              <p className={styles.freePeriod_title}>
                <b>Essai Gratuit</b> pendant vos 30 premiers jours.
              </p>
              <p>
                Nous vous enverrons un e-mail de facturation pour chaque
                prélèvement fait.
              </p>
              <p>
                Prochain prélèvement mensuel est pour le{" "}
                <b>
                  {period === "DAILY"
                    ? rate.nextDay + " " + rate.daily
                    : rate.nextMonth + " " + rate.monthly}{" "}
                  €
                </b>{" "}
                HTVA
              </p>
            </>
          )}
          <p>
            <b>Sans engagement.</b> Abonnement annulable en ligne à tout moment.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PeriodChoice;
