import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { useQuery } from "react-query";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Avatar } from "tamtam-components";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Switch from "../../common/Switch/Switch";
import Button from "../../common/Button";
import Loader from "../../common/Loader";

import { setFiduciary } from "../../../store";
import { saveAppsPreferences } from "../../../api";
import _ from "../../../i18n";

import styles from "./InstalledApp.module.scss";

const WebtoolsModal = ({ showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const auth = useSelector((state) => state.auth);

  const [isSaving, setIsSaving] = useState(false);
  const [source, setSource] = useState([]);

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      if (fiduciary?.webtoolsPreferences?.source) {
        setSource(fiduciary.webtoolsPreferences.source);
      }
    }
  }, [showModal]);

  const handleSave = () => {
    let data = {
      token: auth.token,
      communityId: fiduciary.id,
      data: {
        appName: "webtools",
        source,
        lRId: fiduciary.legalRepresentative.id,
      },
    };
    setIsSaving(true);
    saveAppsPreferences(data)
      .then((resp) => {
        setIsSaving(false);
        toast.success(_("successfully_saved"));
        const preferences = fiduciary.webtoolsPreferences || {};
        dispatch(
          setFiduciary({
            ...fiduciary,
            webtoolsPreferences: {
              ...preferences,
              source,
            },
          })
        );
      })
      .catch((e) => {
        setIsSaving(false);
        toast.error(_("error"));
      });
  };

  const handleChange = (isChecked, value) => {
    let tmp = [...source];
    if (isChecked) {
      tmp.push(value);
    } else {
      tmp = tmp.filter((i) => i !== value);
    }
    setSource(tmp);
  };

  return (
    <Modal
      width="80vw"
      height="50vh"
      closable={false}
      visible={showModal}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>
        <i className="icon-ttp-settings"></i> Webtools
      </div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          handleClose();
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>
      <div className={styles.modal_content}>
        <h3>Configurez les sources des webtools</h3>

        <ul className={styles.blog_box}>
          <li className={styles.blog_box_item}>
            <div className={styles.blog_box_item_header}>
              <h3>Ma bibliothèque</h3>
              <Switch
                isChecked={source.includes("MY_LIBRARY")}
                onChange={(e) => handleChange(e, "MY_LIBRARY")}
              />
            </div>
            <p>{_("app_blog_tools_1_desc")}</p>
          </li>

          <li className={styles.blog_box_item}>
            <div className={styles.blog_box_item_header}>
              <h3>Activer la bibliothèque FFF</h3>
              <Switch
                isChecked={source.includes("FFF_LIBRARY")}
                onChange={(e) => handleChange(e, "FFF_LIBRARY")}
              />
            </div>
            <p>
              Afficher les webtools de Forum for the future. Vous aurez la
              possibilité de partager vos webtools avec la bibliothèque FFF
            </p>
          </li>

          <li className={styles.blog_box_item}>
            <div className={styles.blog_box_item_header}>
              <h3>Partager avec les confrères</h3>
              <Switch
                isChecked={source.includes("COLLEAGUE")}
                onChange={(e) => handleChange(e, "COLLEAGUE")}
              />
            </div>
            <p>Mes webtools seront partager avec mes confrères</p>
          </li>
        </ul>

        <div className={styles.actions2}>
          <Button variant="default" onClick={() => handleClose()}>
            {_("close")}
          </Button>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={handleSave}>{_("save")}</Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WebtoolsModal;
