import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getCategories } from "../api";

const initialState = {
  fetching: false,
  fetched: false,
  categories: [],
};

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (tmp, { getState, dispatch }) => {
    const { token } = getState().auth;
    if (!token) {
      return false;
    }

    const response = await getCategories({
      token,
    });

    return response.data.data;
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.fetched = true;
      state.categories = action.payload;
    });
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.fetched = true;
    });
  },
});

export default categoriesSlice.reducer;
