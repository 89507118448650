import React from 'react';
import { TTP_BLOG_URL } from "../../../config"
import styles from "./OurAuthors.module.scss";
import {sanitize} from "../../../utils";

const AuthorCard = ({author, lng}) => {

    const buildUrl = () => {
        const name = `${author.user.firstName}-${author.user.lastName}`;
        return TTP_BLOG_URL + `/${lng}/author/${sanitize(name)}/${author.user.id}`
    }

    return <div className={styles.card}>
        
        <img src={author.user.avatar} with="65" height="65" alt=""/> 
        <div className={styles.text}>
            <a href={buildUrl()} className={styles.name} target="_blank" rel="noreferrer">
                {author.user.firstName + ' ' + author.user.lastName}
            </a>
            <span className={styles.role}>
                {/* {author[`headline${lng.charAt(0).toUpperCase() + lng.slice(1)}`].title} */}
                </span>
        </div>
    </div>
}
export default AuthorCard;