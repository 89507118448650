import classNames from "classnames";

import styles from "./Home.module.scss";
import _ from "../../i18n";

const Pending = ({ welcome }) => {
  return (
    <div>
      <div className={classNames(styles.homePage, "grid-x")}>
        <div
          className={classNames(styles.leftSide, "cell medium-9 small-12")}
          style={{
            backgroundColor: "#06D9B1",
          }}
        >
          <svg
            width="770"
            height="781"
            viewBox="0 0 770 781"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5" filter="url(#filter0_b_45323_20495)">
              <ellipse
                cx="232.066"
                cy="232.573"
                rx="232.066"
                ry="232.573"
                fill="url(#paint0_radial_45323_20495)"
              />
              <circle
                cx="466.163"
                cy="548.427"
                r="232.573"
                fill="url(#paint1_radial_45323_20495)"
              />
              <ellipse
                cx="468.701"
                cy="389.484"
                rx="301.127"
                ry="300.111"
                fill="url(#paint2_radial_45323_20495)"
              />
            </g>
            <defs>
              <filter
                id="filter0_b_45323_20495"
                x="-200"
                y="-200"
                width="1169.83"
                height="1181"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="100" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_45323_20495"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_45323_20495"
                  result="shape"
                />
              </filter>
              <radialGradient
                id="paint0_radial_45323_20495"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(232.066 232.573) rotate(90) scale(232.573 232.066)"
              >
                <stop stop-color="#C7E8FE" />
                <stop offset="1" stop-color="#C7E8FE" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint1_radial_45323_20495"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(466.163 548.427) rotate(90) scale(232.573)"
              >
                <stop stop-color="#C7E8FE" />
                <stop offset="1" stop-color="#C7E8FE" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint2_radial_45323_20495"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(468.701 389.484) rotate(90) scale(300.111 301.127)"
              >
                <stop stop-color="#D8DDE2" />
                <stop offset="1" stop-color="#D8DDE2" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
          <div className={classNames(styles.content, styles.contentPending)}>
            <div className={styles.pending}>
              <svg
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="80" height="80" rx="40" fill="white" />
                <g clip-path="url(#clip0_45510_22378)">
                  <path
                    d="M35.8574 56.9998C34.8005 56.9998 33.8009 56.5053 33.1583 55.6572L24.4795 44.1958C23.3507 42.7052 23.6446 40.5826 25.1352 39.4533C26.6283 38.3236 28.7494 38.6194 29.8787 40.109L35.5868 47.6464L49.9431 24.5953C50.9315 23.0095 53.0186 22.5242 54.6079 23.5112C56.1952 24.4992 56.68 26.5877 55.691 28.175L38.7318 55.4042C38.1427 56.3508 37.1256 56.9463 36.0118 56.9968C35.9599 56.9988 35.9089 56.9998 35.8574 56.9998Z"
                    fill="#06D9B1"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_45510_22378">
                    <rect
                      width="34"
                      height="34"
                      fill="white"
                      transform="translate(23 23)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <h1>
                Nous avons bien-reçu votre demande d'adhésion au réseau United
                Associates
              </h1>
              <p>
                La demande d’adhésion est bien enregistrée.
                <br />
                Un animateur de notre nouveau réseau technologique prendra
                contact avec vous très rapidement.
              </p>
              <span className={styles.contact_span}>
                Dès que votre adhésion sera validée, vous recevrez une
                invitation à payer.
                <br />
                N’hésitez pas à{" "}
                <a href="mailto: net@unitedassociates.be">nous contacter</a> en
                cas de difficulté
              </span>
            </div>

            <div className={styles.footer}>
              Powered by tamtam International
              <br />
              {_("home_footer")}
            </div>
          </div>
        </div>
        <div className={classNames(styles.rightSide, "cell medium-3 small-12")}>
          <svg
            width="527"
            height="526"
            viewBox="0 0 527 526"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M527 263C527 408.251 409.251 526 264 526C263.999 375.339 264.002 408.251 264.002 263C264.002 117.749 264 145.965 264 0C409.251 0 527 117.749 527 263Z"
              fill="url(#paint0_radial_45323_20511)"
            />
            <path
              d="M338.212 262.501C338.212 304.311 304.067 338.21 261.938 338.21C219.81 338.21 185.664 304.311 185.664 262.501C185.664 220.691 219.81 186.791 261.938 186.791C304.067 186.791 338.212 220.691 338.212 262.501Z"
              stroke="#06D9B1"
            />
            <circle
              cx="262.5"
              cy="262.5"
              r="123.129"
              stroke="#06D9B1"
              stroke-dasharray="4 4"
            />
            <path
              d="M452.242 263.064C452.242 368.169 367.29 453.371 262.5 453.371C157.71 453.371 72.7578 368.169 72.7578 263.064C72.7578 157.96 157.71 72.7578 262.5 72.7578C367.29 72.7578 452.242 157.96 452.242 263.064Z"
              stroke="#06D9B1"
            />
            <circle
              cx="262.5"
              cy="262.5"
              r="262"
              stroke="#06D9B1"
              stroke-dasharray="4 4"
            />
            <defs>
              <radialGradient
                id="paint0_radial_45323_20511"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(263.998 263) rotate(90) scale(263)"
              >
                <stop stop-color="#C7E8FE" />
                <stop offset="1" stop-color="#C7E8FE" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        {/* <div className={styles.video}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M49.9987 91.6673C73.0106 91.6673 91.6654 73.0125 91.6654 50.0006C91.6654 26.9888 73.0106 8.33398 49.9987 8.33398C26.9868 8.33398 8.33203 26.9888 8.33203 50.0006C8.33203 73.0125 26.9868 91.6673 49.9987 91.6673Z"
              stroke="#18A0FB"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M41.668 33.334L66.668 50.0007L41.668 66.6673V33.334Z"
              stroke="#18A0FB"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default Pending;
