import React from "react";
import { useSelector } from "react-redux";

import _ from "../../i18n";
import styles from "./PublicPortal.module.scss";

const OrganizationInfos = (props) => {
  const language = useSelector((state) => state.params.language);
  const { community, page, info } = props;
  const { email, phone } = page;

  const descriptionAttr = `description${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  return (
    <div className={styles.fiduciaryInfos}>
      <h4 className={styles.title}>
        {community.abbreviation && community.abbreviation.length > 5
          ? community.abbreviation
          : community.name}
      </h4>
      <span className={styles.description}>{info[descriptionAttr]}</span>
      <div className={styles.flex}>
        <div className={styles.infoDiv}>
          <ul>
            {!community.address1 &&
            !community.zipCode &&
            !community.city ? null : (
              <li>
                <i className="icon-ttp-pin" />
                <span>
                  {community.address1 ? community.address1 : ""}
                  {community.address2 ? " " + community.address2 : ""}
                  {community.zipCode ? " - " + community.zipCode : ""}
                  {community.city ? " " + community.city : ""}
                </span>
              </li>
            )}
            {!phone ? null : (
              <li>
                <i className="icon-ttp-smartphone" />
                <span>Tel. {phone}</span>
              </li>
            )}
            {!community.fax ? null : (
              <li>
                <i className="icon-ttp-print" />
                <span>Fax. {community.fax}</span>
              </li>
            )}
            {!email ? null : (
              <li>
                <i className="icon-ttp-email-read" />
                <span>{email}</span>
              </li>
            )}
          </ul>
        </div>
        <div className={styles.infoDiv}>
          <ul>
            {!community.uen || !community.country ? null : (
              <li>{`TVA ${community.country} ${community.uen}`}</li>
            )}
          </ul>
        </div>
      </div>

      <ul className={styles.otherInfos}></ul>
    </div>
  );
};

export default OrganizationInfos;
