import React from "react";
import styles from "./History.module.scss";
import _ from "../../../../../../../../i18n";
import { useSelector } from "react-redux";

const History = ({ data, hideTitle }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  return (
    <div className={styles.history}>
      <div className={styles.left}>
        {!hideTitle && (
          <div className={styles.section_title}>{_("Votre Histoire")}</div>
        )}
        <div className={styles.title}>
          {data && data[titleAttr] ? data[titleAttr] : "Loream title"}
        </div>
      </div>
      <div className={styles.right}>
        {data && data[descriptionAttr]
          ? data[descriptionAttr]
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id donec ultrices tincidunt arcu non sodales neque."}
      </div>
    </div>
  );
};

export default History;
