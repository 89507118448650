import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import Select from "react-select";

// import { savePackApp, saveApplication } from ".../../../api";
import _ from "../../../../i18n";
import { SELECT_STYLES } from "../../../../config";

import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import Tabs from "../../../common/Tabs";

import styles from "./ModalDelegationType.module.scss";
import Form from "./Form";
import List from "./List";
import { useQuery } from "react-query";
import { ModalConfirm } from "tamtam-components";
import {
  deleteDelegationType,
  getDelegationTypes,
  updateDelegationTypeStatus,
} from "../../../../api";

const ModalDelegationType = ({
                               currentApp,
                               currentPack,
                               closeModal,
                               onSave,
                               type,
                               parentApp,
                               applications,
                             }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [isFormTabActive, setIsFormTabActive] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({
    titleFr: "",
    titleEn: "",
    titleNl: "",
    abbriviationFr: "",
    abbriviationEn: "",
    abbriviationNl: "",
    descriptionFr: "",
    descriptionEn: "",
    descriptionNl: "",
  });

  const [isSaving, setIsSaving] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!isFormTabActive) {
      setCurrentItem(null);
    }
  }, [isFormTabActive]);
  const handleClose = () => {
    closeModal();
  };

  let { isLoading, data, refetch } = useQuery(
    ["listDelegationTypes", token], //, sort, search, currentPage
    async () => {
      if (token) {
        try {
          const response = await getDelegationTypes({
            token,
            organizationId: navCommunity.id,
            limit: 10,
            page: 1,
            // sort,
          });
          return response.data;
        } catch (e) {
          console.log("error" + e);
          return null;
        }
      }
    },
  );
  const handleDeleteAction = async () => {
    if (currentDeleteItem) {
      setIsDeleting(true);
      deleteDelegationType(token, currentDeleteItem.id)
        .then((resp) => {
          refetch();
          toast.success(_("status_updated"));
          setIsDeleting(false);
          setIsOpenDeleteModal(false);
        })
        .catch((e) => {
          toast.success(_("error"));
        })
        .finally(() => {
          setIsDeleting(false);
          setIsOpenDeleteModal(false);
        });
    }
  };

  const toogleTypeStatus = async (item) => {
    setIsSaving(true);
    await updateDelegationTypeStatus(
      token,
      item.id,
      item.status === "ENABLED" ? "DISABLED" : "ENABLED",
    )
      .then((res) => {
        toast.success(_("successfully_deleted"));
        refetch();
      })
      .catch((e) => {
        toast.success(_("error"));
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>
        <div className={styles.title}>
          <h3>{_("title_delegation_type")}</h3>
          <div
            className={styles.btTab}
            onClick={() => setIsFormTabActive(!isFormTabActive)}
          >
            {isFormTabActive ? (
              <div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <i className="icon-ttp-plus"></i>
            )}
          </div>
        </div>

        {isFormTabActive ? (
          <Form
            currentItem={currentItem}
            refetch={refetch}
            setIsFormTabActive={setIsFormTabActive}
          />
        ) : (
          <List
            isLoading={isLoading}
            isSaving={isSaving}
            data={data}
            setIsFormTabActive={setIsFormTabActive}
            setCurrentItem={setCurrentItem}
            setCurrentDeleteItem={setCurrentDeleteItem}
            setIsOpenDeleteModal={setIsOpenDeleteModal}
            toogleTypeStatus={toogleTypeStatus}
          />
        )}
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={isDeleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default ModalDelegationType;
