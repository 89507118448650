import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import SortableCollaborator from "./SortableCollaborator";
import styles from "./Collaborator.module.scss";
import classNames from "classnames";

export default SortableContainer(
  ({ collaborators, setCollaborators, inModal }) => {
    let collaboratorsJsx = collaborators.map((user, index) => {
      return (
        <SortableCollaborator
          user={user}
          key={user.id}
          index={index}
          userIndex={index}
          setCollaborators={setCollaborators}
          collaborators={collaborators}
        />
      );
    });

    return (
      <div
        className={classNames(
          styles.list,
          "grid-x",
          inModal ? styles.inModal : ""
        )}
      >
        {collaboratorsJsx}
      </div>
    );
  }
);
