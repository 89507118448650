import React from "react";

const IconCopy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 96 960 960"
      width="20"
    >
      <path
        d="M246.002 932.306q-27.007 0-45.657-18.65-18.65-18.65-18.65-45.657V368.31h51.999v499.689q0 4.615 3.846 8.462 3.846 3.846 8.462 3.846h403.689v51.999H246.002Zm123.999-123.999q-27.008 0-45.658-18.65-18.649-18.649-18.649-45.657V288.617q0-27.007 18.649-45.657 18.65-18.65 45.658-18.65h359.383q27.007 0 45.657 18.65 18.65 18.65 18.65 45.657V744q0 27.008-18.65 45.657-18.65 18.65-45.657 18.65H370.001Zm0-51.998h359.383q4.615 0 8.462-3.847 3.846-3.846 3.846-8.462V288.617q0-4.616-3.846-8.462-3.847-3.846-8.462-3.846H370.001q-4.616 0-8.462 3.846-3.847 3.846-3.847 8.462V744q0 4.616 3.847 8.462 3.846 3.847 8.462 3.847Zm-12.309 0v-480V756.309Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCopy;
