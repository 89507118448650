import React, { useEffect, useState } from "react";
import styles from "./NewsletterModal.module.scss";

import _ from "../../../i18n";
import {
  DAYS,
  NL_PLANS,
  SELECT_STYLES,
  SELECT_STYLES_NEWSLETTER,
} from "../../../config";
import Select from "react-select";
import CollaboratorBlock from "./CollaboratorBlock";
import Loader from "../Loader";
import { toast } from "react-toastify";
import Switch from "../Switch/Switch";
import CollaboratorFrom from "./CollaboratorFrom";
import IconPlus from "../icons/IconPlus";

const NewsletterSettings = ({
  newsletterPlan,
  frequency,
  setFrequency,
  frequencyTabs,
  selectedDay,
  setSelectedDay,
  isAutomaticNewsletter,
  setIsAutomaticNewsletter,
  selectedWeek,
  setSelectedWeek,
  selectedHour,
  setSelectedHour,
  selectedMinutes,
  setSelectedMinutes,
  collaborators,
  senders,
  setSenders,
  setSlide,
  verifiedEmailsList,
  handleFetchVerifiedEmails,
  isVerifiedEmailsLoading,
  isLogoUaVisible,
  setIsLogoUaVisible,
}) => {
  const [collaboratorList, setCollaboratorList] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const Periods = [
    {
      index: "BY_TWO_WEEKS",
      choices: [
        { value: "FIRST_WEEK", label: _("week_desc_1") },
        { value: "SECOND_WEEK", label: _("week_desc_2") },
      ],
    },
    {
      index: "BY_MONTH",
      choices: [
        { value: "FIRST_WEEK", label: _("first_week") },
        { value: "SECOND_WEEK", label: _("second_week") },
        { value: "THIRD_WEEK", label: _("third_week") },
        { value: "FOURTH_WEEK", label: _("fourth_week") },
      ],
    },
  ];

  const hours = [];
  // Générer une liste d'heures
  for (let i = 0; i < 24; i++) {
    hours.push({
      value: i < 10 ? `0${i}` : `${i}`,
      label: i < 10 ? `0${i}` : `${i}`,
    });
  }

  const minutes = [];

  // Générer une liste des minutes
  for (let i = 0; i < 60; i++) {
    minutes.push({
      value: i < 10 ? `0${i}` : `${i}`,
      label: i < 10 ? `0${i}` : `${i}`,
    });
  }
  useEffect(() => {
    let collab_list = [];
    if (!isVerifiedEmailsLoading) {
      collaborators?.forEach((collaborator) => {
        const matchedEmail = verifiedEmailsList.find(
          (emailObj) => emailObj.email === collaborator.mainEmail
        );

        const verificationStatus = matchedEmail
          ? matchedEmail.VerificationStatus
          : "Failed";

        collab_list.push({
          value: collaborator.id,
          label: collaborator.firstName + " " + collaborator.lastName,
          email: collaborator.mainEmail,
          status: verificationStatus,
        });
      });
      setCollaboratorList(collab_list);
    }
  }, [isVerifiedEmailsLoading, collaborators, verifiedEmailsList]);

  const handleRemoveClick = (sender) => {
    setSenders(senders.filter((item) => item.value !== sender.value));

    if (!collaboratorList.some((item) => item.email === sender.email)) {
      setCollaboratorList([sender, ...collaboratorList]);
    }
  };
  const handleChangeSelectedWeek = (el) => {
    setFrequency(el);
    if (el.value === "BY_WEEK") {
      setSelectedWeek("");
    } else if (el.value === "BY_TWO_WEEKS") {
      setSelectedWeek({ value: "FIRST_WEEK", label: _("week_desc_1") });
    } else {
      setSelectedWeek({ value: "FIRST_WEEK", label: _("first_week") });
    }
  };


  const svgStyle = {
    transform: isVisible ? "rotate(-45deg)" : "rotate(0deg)",
    transition: "transform 0.5s ease",
  };

  return (
    <div className={"cell small-12 medium-12"}>
      <div
        className={`grid-x
            ${styles.config}`}
      >
        <div
          className={`cell small-12 medium-3 
            ${styles.mail_list}`}
        >
          <span className={styles.title}> {_("define_sender")}</span>
          <span className={styles.sub_title}>
            {" "}
            {_("manage_sending_emails")}
          </span>
          <div className={`grid-x ${styles.container_select}`}>
            <div className={`cell small-10 ${styles.sender_select}`}>
              {isVisible ? (
                <p className={styles.sender_title}>{_("new_sender")}</p>
              ) : (
                <Select
                  styles={SELECT_STYLES}
                  className={styles.sender_options}
                  options={collaboratorList.filter(
                    (item) =>
                      !senders
                        .map((sender) => sender.value)
                        .includes(item.value)
                  )}
                  isSearchable={true}
                  isClearable={false}
                  value={""}
                  inputProps={{
                    autoComplete: "off",
                  }}
                  onChange={(e) => {
                    if (
                      (newsletterPlan === "LITE" && senders.length === 0) ||
                      (["PERSONALIZED", "STANDARD"].includes(newsletterPlan) &&
                        senders.length < 20)
                    ) {
                      setSenders([e, ...senders]);
                      setCollaboratorList(
                        collaboratorList.filter(
                          (collaborator) => collaborator.value !== e.value
                        )
                      );
                    } else {
                      toast.info(
                        _(
                          "Vous avez atteint la limite des expéditeurs prévue par ce plan "
                        )
                      );
                    }
                  }}
                  isLoading={isVerifiedEmailsLoading}
                />
              )}
            </div>
            <div
              className={`cell small-2 ${styles.sender_add_icon}`}
              onClick={() => {
                setIsVisible(!isVisible);
              }}
            >
              <IconPlus style={svgStyle} />
            </div>
          </div>
          <div className={styles.separator} />
          {senders.length > 0 && !isVisible && (
            <span className={styles.senders_count}>
              {senders.length} expéditeur(s) :
            </span>
          )}
          <div className={styles.senders_list}>
            {isVerifiedEmailsLoading ? (
              <Loader
                style={{ height: "13px", margin: "15% 30%" }}
                color={"#06d9b1"}
              />
            ) : isVisible ? (
              <CollaboratorFrom
                collaboratorList={collaboratorList}
                setCollaboratorList={setCollaboratorList}
                senders={senders}
                setSenders={setSenders}
                setIsVisible={setIsVisible}
              />
            ) : (
              senders.length > 0 &&
              senders.map((sender) => (
                <CollaboratorBlock
                  sender={sender}
                  removeClick={handleRemoveClick}
                  verifiedEmailsList={verifiedEmailsList}
                  handleFetchVerifiedEmails={handleFetchVerifiedEmails}
                />
              ))
            )}
          </div>
        </div>
        <div
          className={`cell small-12 medium-6 
            ${styles.config_details}`}
        >
          <div className={styles.details}>
            <span className={styles.title}>
              {_("program_nl_title")}
              {
                NL_PLANS.filter((plan) => plan.value === newsletterPlan)[0]
                  .label
              }
              )
            </span>
            <span className={styles.title_link} onClick={() => setSlide(1)}>
              Changer de plan
            </span>
          </div>
          <span className={styles.sub_title}> {_("program_nl_desc")}</span>

          <div className={styles.config_block}>
            {newsletterPlan === "PERSONALIZED" && (
              <div className={styles.show_logo}>
                <span className={styles.details_title}>Logo UA :</span>
                <Switch
                  isChecked={isLogoUaVisible}
                  onChange={() => setIsLogoUaVisible(!isLogoUaVisible)}
                />
              </div>
            )}
            <div className={styles.config_item}>
              <span className={styles.details_title}>
                {_("articles_option_3")} :
              </span>
              <Select
                styles={SELECT_STYLES_NEWSLETTER}
                options={frequencyTabs}
                isSearchable={false}
                isClearable={false}
                value={frequency}
                onChange={(e) => handleChangeSelectedWeek(e)}
                isDisabled={true}
              />
            </div>
            {/*<div className={styles.automatic_check}>
              <input
                type="checkbox"
                className={styles.styled_checkbox}
                id="my_checkbox"
                checked={isAutomaticNewsletter}
                onChange={() =>
                  setIsAutomaticNewsletter(!isAutomaticNewsletter)
                }
              />
              <label htmlFor="my_checkbox">
                Envoyer la campagne automatiquement
              </label>
            </div>*/}
            {/* {isAutomaticNewsletter && (
              <>
                <div className={styles.separator} />
                <span className={styles.title}> {_("choose_date")} : </span>
                <span className={styles.sub_title}>
                  {_("choose_date_desc")}
                </span>

                <div className={styles.config_item}>
                  <span className={styles.details_title}>Jour :</span>
                  <Select
                    isDisabled={!isAutomaticNewsletter}
                    styles={SELECT_STYLES}
                    options={DAYS}
                    isSearchable={false}
                    isClearable={false}
                    value={selectedDay}
                    onChange={e => setSelectedDay(e)}
                  />
                </div>
                {frequency.value !== "BY_WEEK" && (
                  <div className={styles.config_item}>
                    <span className={styles.details_title}>{_("week")} :</span>
                    <Select
                      isDisabled={!isAutomaticNewsletter}
                      styles={SELECT_STYLES}
                      options={
                        Periods.filter(
                          period => period.index === frequency.value
                        )[0]?.choices
                      }
                      isSearchable={false}
                      isClearable={false}
                      value={selectedWeek}
                      onChange={e => setSelectedWeek(e)}
                    />
                  </div>
                )}

                <div className={styles.config_item}>
                  <span className={styles.details_title}>Heure :</span>
                  <div className={styles.time_select}>
                    <Select
                      isDisabled={!isAutomaticNewsletter}
                      styles={SELECT_STYLES}
                      options={hours}
                      isSearchable={false}
                      isClearable={false}
                      value={selectedHour}
                      onChange={e => setSelectedHour(e)}
                    />
                  </div>
                  <span className={styles.space}>:</span>
                  <div className={styles.minute_select}>
                    <Select
                      isDisabled={!isAutomaticNewsletter}
                      styles={SELECT_STYLES}
                      options={minutes}
                      isSearchable={false}
                      isClearable={false}
                      value={selectedMinutes}
                      onChange={e => setSelectedMinutes(e)}
                    />
                  </div>
                </div>
              </>
            )}*/}
          </div>
        </div>
        <div
          className={`cell small-12 medium-3 
            ${styles.config_dates}`}
        >
          <span className={styles.title}> Programmation des dates OFF</span>
          <div className={styles.calendar}>
            <span>Bientôt disponible</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSettings;
