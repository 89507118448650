import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import debounce from "lodash.debounce";
import { ModalConfirm } from "tamtam-components";
import { toast } from "react-toastify";

import Loader from "../../common/Loader";
import _ from "../../../i18n";
import {
  getCollaborators,
  getTmpCollaborators,
  getExpressUser,
  deleteExpressUser,
  detachUserAssociations,
  deleteTmpCollab,
  emailReminder,
} from "../../../api";
import { formatPhone, formatHidePhone } from "../../../utils";
import styles from "./Collaborator.module.scss";
import Pagination from "../../common/Pagination";
import { StatusButton } from "../../";

const CollaboratorList = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [sort, setSort] = useState({
    property: "lastName",
    dir: "asc",
  });
  const [dropDownId, setDropDownId] = useState(null);
  const [search, setSearch] = useState("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCollaborators, setTotalCollaborators] = useState(0);
  const [totalExpress, setTotalExpress] = useState(0);
  const [totalTmpCollaborators, setTotalTmpCollaborators] = useState(0);
  const [totalRejectedExpress, setTotalRejectedExpress] = useState(0);
  const [totalRejectedTmpCollaborators, setTotalRejectedTmpCollaborators] =
    useState(0);
  const [listView, setListView] = useState("CURRENT"); // CURRENT, REJECTED
  const [isSending, setIsSending] = useState(false);
  const { token } = auth;
  const LIMIT = 10;

  useEffect(() => {
    props.setNbCollaborators(
      totalCollaborators + totalExpress + totalTmpCollaborators
    );
  }, [totalCollaborators, totalExpress, totalTmpCollaborators]);

  let { isLoading, data } = useQuery(
    ["listCollaborators", token, fiduciary, sort, search, currentPage],
    async () => {
      if (token && fiduciary) {
        const response = await getCollaborators({
          token,
          search,
          page: currentPage,
          limit: LIMIT,
          sort,
          organizationId: fiduciary.id,
        });

        if (response.data?.nbResult) {
          setTotalCollaborators(response.data.nbResult);
        }

        const tab = response.data.data.map((item) => {
          const role = item.roles.filter(
            (r) => r.organization.id === fiduciary.id
          );
          if (role.length !== 1) {
            return null;
          }
          return { ...item, role: role[0] };
        });

        return { data: tab };
      }
    }
  );

  let listExpress = useQuery(["listExpress", token, fiduciary], async () => {
    if (token && fiduciary) {
      let response = await getExpressUser({
        token,
        status: "PENDING,IN_PROGRESS",
        fiduciaryId: fiduciary.id,
      });
      if (response?.data?.data) {
        setTotalExpress(response.data.data.length);
      } else {
        setTotalExpress(0);
      }

      return response.data;
    }
  });

  let listTmpCollaborators = useQuery(
    ["listTmpCollaborators", token, fiduciary],
    async () => {
      if (token && fiduciary) {
        try {
          let response = await getTmpCollaborators({
            token,
            status: "PENDING",
            organizationId: fiduciary.id,
          });
          if (response?.data?.data) {
            setTotalTmpCollaborators(response.data.data.length);
          } else {
            setTotalTmpCollaborators(0);
          }

          return response.data;
        } catch (e) {
          setTotalTmpCollaborators(0);
          return [];
        }
      }
    }
  );

  let listRejectedExpress = useQuery(
    ["listRejectedExpress", token, fiduciary],
    async () => {
      if (token && fiduciary) {
        let response = await getExpressUser({
          token,
          status: "REJECTED",
          fiduciaryId: fiduciary.id,
        });
        if (response?.data?.data) {
          setTotalRejectedExpress(response.data.data.length);
        } else {
          setTotalRejectedExpress(0);
        }

        return response.data;
      }
    }
  );

  let listRejectedTmpCollaborators = useQuery(
    ["listRejectedTmpCollaborators", token, fiduciary],
    async () => {
      if (token && fiduciary) {
        try {
          let response = await getTmpCollaborators({
            token,
            status: "REJECTED",
            organizationId: fiduciary.id,
          });
          if (response?.data?.data) {
            setTotalRejectedTmpCollaborators(response.data.data.length);
          } else {
            setTotalRejectedTmpCollaborators(0);
          }

          return response.data;
        } catch (e) {
          setTotalRejectedTmpCollaborators(0);
          return [];
        }
      }
    }
  );

  useEffect(() => {
    if (props.refreshList) {
      queryClient.invalidateQueries("listCollaborators");
      queryClient.invalidateQueries("listExpress");
      queryClient.invalidateQueries("listTmpCollaborators");
      props.setRefreshList(false);
    }
  }, [props.refreshList]);

  const toggleDropDown = (e, userId) => {
    e.stopPropagation();
    dropDownId === userId ? setDropDownId(null) : setDropDownId(userId);
  };

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setSearch(search);
    setCurrentPage(1);
  }, 1000);

  const handleDeleteAction = () => {
    if (currentDeleteItem) {
      setDeleting(true);
      if (currentDeleteItem.itemType === "CLASSIC") {
        detachUserAssociations(auth.token, currentDeleteItem.id)
          .then((resp) => {
            queryClient.invalidateQueries("listCollaborators");
            setTimeout(() => {
              toast.success(_("successfully_deleted"));
              setDeleting(false);
              setIsOpenDeleteModal(false);
              props.afterDelete();
            }, 1000);
          })
          .catch((e) => {
            setDeleting(false);
          });
      } else if (currentDeleteItem.itemType === "EXPRESS") {
        deleteExpressUser(auth.token, currentDeleteItem.id)
          .then((resp) => {
            queryClient.invalidateQueries("listExpress");
            setTimeout(() => {
              toast.success(_("successfully_deleted"));
              setDeleting(false);
              setIsOpenDeleteModal(false);
              props.afterDelete();
            }, 1000);
          })
          .catch((e) => {
            setDeleting(false);
          });
      } else if (currentDeleteItem.itemType === "TMP") {
        deleteTmpCollab(auth.token, currentDeleteItem.id)
          .then((resp) => {
            queryClient.invalidateQueries("listTmpCollaborators");
            setTimeout(() => {
              toast.success(_("successfully_deleted"));
              setDeleting(false);
              setIsOpenDeleteModal(false);
              props.afterDelete();
            }, 1000);
          })
          .catch((e) => {
            setDeleting(false);
          });
      }
    }
  };

  const getItemActions = (item, itemType) => {
    if (auth.user.role === "OFFICIAL" || auth.user.role === "EXTERNAL") {
      return null;
    }

    return (
      <div className={styles.dropDownContainer}>
        <span
          className={`${styles.cellIcon} ${
            dropDownId === item.id && styles.active
          }`}
        >
          <span
            className={`${
              dropDownId === item.id ? "icon-ttp-close" : "icon-ttp-three-dots"
            } ${styles.settingsIcon}`}
            onClick={(e) => toggleDropDown(e, item.id)}
          />
        </span>
        <ul
          className={`${styles.menuDropdown} ${
            dropDownId === item.id && styles.show
          }`}
        >
          {(!["LEGAL_REPRESENTATIVE", "MANAGER"].includes(auth.user.role) ||
            "ADMIN" === auth.user.type) && (
            <li
              onClick={() => {
                setCurrentDeleteItem({ ...item, itemType });
                setIsOpenDeleteModal(true);
              }}
            >
              {_("delete")}
            </li>
          )}
        </ul>
      </div>
    );
  };

  const getActions = (item) => {
    if (
      auth.user.role === "OFFICIAL" ||
      auth.user.role === "EXTERNAL" ||
      ((auth.user.role === "MANAGER" ||
        auth.user.role == "FIDUCIARIES_ADMIN") &&
        item.role.type === "LEGAL_REPRESENTATIVE")
    ) {
      return null;
    }

    if (
      "LEGAL_REPRESENTATIVE" === auth.user.role &&
      "ADMIN" === auth.user.role.typeStatus &&
      "LEGAL_REPRESENTATIVE" === item.role.type &&
      auth.user.id !== item.id
    ) {
      return null;
    }
    if (item?.role?.exist && item.uaStatus === "PENDING") {
      return null;
    }

    return (
      <div className={styles.dropDownContainer}>
        <span
          className={`${styles.cellIcon} ${
            dropDownId === item.id && styles.active
          }`}
        >
          <span
            className={`${
              dropDownId === item.id ? "icon-ttp-close" : "icon-ttp-three-dots"
            } ${styles.settingsIcon}`}
            onClick={(e) => toggleDropDown(e, item.id)}
          />
        </span>
        <ul
          className={`${styles.menuDropdown} ${
            dropDownId === item.id && styles.show
          }`}
        >
          <li
            onClick={(e) => {
              toggleDropDown(e, item.id);
              props.setSideBar && props.setSideBar("COLLABORATOR_FORM");
              props.setCurrentItem(item);
            }}
          >
            {_("edit")}
          </li>

          {(!["LEGAL_REPRESENTATIVE", "MANAGER"].includes(auth.user.role) ||
            "ADMIN" === auth.user.type) &&
            auth.user.id !== item.id && (
              <li
                onClick={() => {
                  setCurrentDeleteItem({ ...item, itemType: "CLASSIC" });
                  setIsOpenDeleteModal(true);
                }}
              >
                {_("delete")}
              </li>
            )}
        </ul>
      </div>
    );
  };

  const handleClick = (type, id) => {
    setIsSending(true);
    let data = { token, language };
    type === "uid" ? (data.uid = id) : (data.id = id);
    emailReminder(data)
      .then(() => {
        toast.success(_("reminder_email_sent_successfully"));
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      })

      .finally(() => setIsSending(false));
  };

  const renderData = () => {
    let collabData =
      data?.data && data.data.length > 0
        ? data.data.map((item) => (
            <tr key={item.id}>
              <td>{getActions(item)}</td>
              <td>{`${item.lastName} ${item.firstName}`}</td>
              <td>{item.mainEmail}</td>
              <td className={styles.nowrap}>
                {item.mainPhone && formatPhone(item.mainPhone)}
              </td>
              <td>{_(item.role?.type)}</td>
              <td>{item.language}</td>
              <td>
                <StatusButton
                  status={"VALIDATED"}
                  handleClick={() => handleClick("uid", item.id)}
                />
              </td>
            </tr>
          ))
        : [];

    let expressData =
      listExpress?.data && listExpress.data?.data?.length > 0
        ? listExpress.data.data.map((item) => (
            <tr key={item.id}>
              <td>{getItemActions(item, "EXPRESS")}</td>
              <td>{`${item?.user?.lastName ? item.user.lastName : "-"} ${
                item?.user?.firstName ? item.user.firstName : ""
              }`}</td>
              <td>{item.email}</td>
              <td className={styles.nowrap}>
                {item?.user?.mainPhone
                  ? formatHidePhone(item.user.mainPhone)
                  : "-"}
              </td>
              <td>{_("OFFICIAL")}</td>
              <td>-</td>
              <td>
                <StatusButton
                  status={item.status}
                  handleClick={() => handleClick("id", item.id)}
                />
              </td>
            </tr>
          ))
        : [];

    let tmpCollabData =
      listTmpCollaborators?.data && listTmpCollaborators.data?.data?.length > 0
        ? listTmpCollaborators.data.data.map((item) => (
            <tr key={item.id}>
              <td>{getItemActions(item, "TMP")}</td>
              <td>{`${item?.user?.lastName ? item.user.lastName : "-"} ${
                item?.user?.firstName ? item.user.firstName : ""
              }`}</td>
              <td>{item.user.mainEmail}</td>
              <td className={styles.nowrap}>
                {item?.user?.mainPhone
                  ? formatHidePhone(item.user.mainPhone)
                  : "-"}
              </td>
              <td>{_(item.type)}</td>
              <td>{item.user.language}</td>
              <td>
                <StatusButton
                  status={item.status}
                  handleClick={() => handleClick("uid", item.user.id)}
                />
              </td>
            </tr>
          ))
        : [];
    if (!collabData && !expressData && !tmpCollabData) {
      return (
        <tr>
          <td colSpan={6} className={styles.centerCell}>
            {_("empty_list")}
          </td>
        </tr>
      );
    }

    return [...expressData, ...tmpCollabData, ...collabData];
  };

  const renderRejectedData = () => {
    let expressData =
      listRejectedExpress?.data && listRejectedExpress.data?.data?.length > 0
        ? listRejectedExpress.data.data.map((item) => (
            <tr key={item.id}>
              <td>{getItemActions(item, "EXPRESS")}</td>
              <td>{`${item?.user?.lastName ? item.user.lastName : "-"} ${
                item?.user?.firstName ? item.user.firstName : ""
              }`}</td>
              <td>{item.email}</td>
              <td className={styles.nowrap}>
                {item?.user?.mainPhone
                  ? formatHidePhone(item.user.mainPhone)
                  : "-"}
              </td>
              <td>{_("OFFICIAL")}</td>
              <td>-</td>
              <td>
                <StatusButton
                  status={item.status}
                  handleClick={() => handleClick("id", item.id)}
                />
              </td>
            </tr>
          ))
        : [];

    let tmpCollabData =
      listRejectedTmpCollaborators?.data &&
      listRejectedTmpCollaborators.data?.data?.length > 0
        ? listRejectedTmpCollaborators.data.data.map((item) => (
            <tr key={item.id}>
              <td>{getItemActions(item, "TMP")}</td>
              <td>{`${item?.user?.lastName ? item.user.lastName : "-"} ${
                item?.user?.firstName ? item.user.firstName : ""
              }`}</td>
              <td>{item.user.mainEmail}</td>
              <td className={styles.nowrap}>
                {item?.user?.mainPhone
                  ? formatHidePhone(item.user.mainPhone)
                  : "-"}
              </td>
              <td>{_(item.type)}</td>
              <td>{item.user.language}</td>
              <td>
                <StatusButton
                  status={item.status}
                  handleClick={() => handleClick("uid", item.user.id)}
                />
              </td>
            </tr>
          ))
        : [];
    if (!expressData && !tmpCollabData) {
      return (
        <tr>
          <td colSpan={6} className={styles.centerCell}>
            {_("empty_list")}
          </td>
        </tr>
      );
    }

    return [...expressData, ...tmpCollabData];
  };

  return (
    <>
      {isSending && <div className="lmask"></div>}
      <div className={styles.listContainer}>
        <h3>
          {fiduciary.uaMode === "UNIVERSITY"
            ? _("students_list")
            : _("collaborators_list")}{" "}
          {!isLoading &&
            !listExpress.isLoading &&
            !listTmpCollaborators.isLoading && (
              <span>
                ({totalCollaborators + totalExpress + totalTmpCollaborators})
              </span>
            )}
        </h3>
        <div className={styles.inputSearch}>
          <span className={styles.inputSearch_icon}>
            <i className="icon-ttp-magnifier"></i>
          </span>
          <input
            type="text"
            placeholder="Recherche ..."
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
      </div>
      <div className={styles.tabContainer}>
        {props.page && props.page === "SETTING" && (
          <ul className={styles.tabForms}>
            <li
              onClick={() => {
                props.setCurrentItem(null);
                props.setSideBar("COLLABORATOR_FORM");
              }}
              className={
                props.sideBar === "COLLABORATOR_FORM" ? styles.active : ""
              }
            >
              Ajout Manuel
            </li>
            <li
              onClick={() => {
                props.setCurrentItem(null);
                props.setSideBar("COLLABORATOR_IMPORT");
              }}
              className={
                props.sideBar === "COLLABORATOR_IMPORT" ? styles.active : ""
              }
            >
              Import (.xls)
            </li>
            <li
              onClick={() => {
                props.setCurrentItem(null);
                props.setSideBar("COLLABORATOR_EXPRESS");
              }}
              className={
                props.sideBar === "COLLABORATOR_EXPRESS" ? styles.active : ""
              }
            >
              Ajout Express
            </li>
          </ul>
        )}
        <div className={styles.tabAction}>
          <span
            onClick={() =>
              setListView(listView === "CURRENT" ? "REJECTED" : "CURRENT")
            }
          >
            {_("rejected")}
            {listView === "REJECTED"
              ? " ( X )"
              : " (" +
                (totalRejectedExpress + totalRejectedTmpCollaborators) +
                ")"}
          </span>
        </div>
      </div>
      <div className={styles.tableContainer}>
        <table
          className={`${styles.table} unstriped`}
          onClick={() => setDropDownId(null)}
        >
          <thead>
            <tr>
              <th></th>
              <th width="30%" className={styles.nowrap}>
                <span>{_("lastname") + " / " + _("firstname")}</span>
                <span className={styles.sortBox}>
                  <i
                    className={`icon-ttp-arrow-down ${
                      sort.property === "lastName" &&
                      sort.dir === "asc" &&
                      styles.iconActive
                    }`}
                    onClick={() =>
                      setSort({ property: "lastName", dir: "asc" })
                    }
                  ></i>
                  <i
                    className={`icon-ttp-arrow-up ${
                      sort.property === "lastName" &&
                      sort.dir === "desc" &&
                      styles.iconActive
                    }`}
                    onClick={() =>
                      setSort({ property: "lastName", dir: "desc" })
                    }
                  ></i>
                </span>
              </th>
              <th width="30%">
                <span>Email</span>
                <span className={styles.sortBox}>
                  <i
                    className={`icon-ttp-arrow-down ${
                      sort.property === "email" &&
                      sort.dir === "asc" &&
                      styles.iconActive
                    }`}
                    onClick={() => setSort({ property: "email", dir: "asc" })}
                  ></i>
                  <i
                    className={`icon-ttp-arrow-up ${
                      sort.property === "email" &&
                      sort.dir === "desc" &&
                      styles.iconActive
                    }`}
                    onClick={() => setSort({ property: "email", dir: "desc" })}
                  ></i>
                </span>
              </th>
              <th width="25%">{_("phone_number")}</th>
              <th width="25%">{_("role")}</th>
              <th>{_("language")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ||
            listExpress.isLoading ||
            listTmpCollaborators.isLoading ? (
              <tr>
                <td colSpan={6} className={styles.centerCell}>
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                </td>
              </tr>
            ) : listView === "CURRENT" ? (
              renderData()
            ) : (
              renderRejectedData()
            )}
          </tbody>
        </table>
      </div>
      {data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={deleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
};

export default CollaboratorList;
