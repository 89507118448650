import React from "react";

const ClockIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 4C12.9543 4 4 12.9543 4 24C4 35.0456 12.9543 44 24 44C35.0456 44 44 35.0456 44 24C44 18.6957 41.8928 13.6086 38.1422 9.85786C34.3914 6.10714 29.3044 4 24 4ZM24 40C15.1634 40 8 32.8366 8 24C8 15.1634 15.1634 8 24 8C32.8366 8 40 15.1634 40 24C40 28.2434 38.3142 32.3132 35.3138 35.3138C32.3132 38.3142 28.2434 40 24 40ZM25.76 13V23.28L33.02 30.54C33.4036 30.9356 33.4036 31.5644 33.02 31.96L31.96 33.02C31.5644 33.4036 30.9356 33.4036 30.54 33.02L22.54 25.02C22.3568 24.8318 22.2498 24.5824 22.24 24.32V13C22.24 12.4477 22.6878 12 23.24 12H24.76C25.3122 12 25.76 12.4477 25.76 13Z"
        fill="black"
      />
    </svg>
  );
};

export default ClockIcon;
