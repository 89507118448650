import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Button from "../../common/Button";
import FormInput from "../../common/FormInput";
import _ from "../../../i18n";
import styles from "./Client.module.scss";
import { DATA_BE_ID, DATA_BE_KEY, DATA_BE_URL } from "../../../config";
import ExpressClientModal from "./ExpressClientModal";
import { getClientsByUen, importClients } from "../../../api";
import { toast } from "react-toastify";

const ClientExpress = (props) => {
  const token = useSelector((state) => state.auth.token);
  const fidudiary = useSelector((state) => state.folder.fiduciary);
  const [input, setInput] = useState("");
  const [uens, setUens] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);

  const getCity = (addresses) => {
    if (addresses && addresses[0]["city"]) return addresses[0]["city"];
    else if (addresses[0]["municipality"]) {
      return addresses[0]["municipality"];
    } else return "";
  };

  const getAddress = (addresses) => {
    return addresses
      ? addresses[0]["street"] + " " + addresses[0]["house_number"]
      : "";
  };

  const formatData = (data) => {
    let formated =
      "uen\tname\tadresse1\tcp\tville\tpays\tnom_representant\tprenom_representant\temail_representant\tmobile_representant\tlangue_represenatant";

    data.forEach((folder) => {
      formated += "\n";
      formated += `${folder.uen}\t${folder.name}\t${folder.address1}\t${folder.zipCode}\t${folder.city}\t${folder.country}\t`;
      formated += `${folder.lRepresentativeFName}\t${folder.lRepresentativeLName}\t${folder.lRepresentativeEmail}\t${folder.phone}\t${folder.lRepresentativeLanguage}\t`;
    });
    return formated;
  };

  const fetchInDataBe = async (tabUens, ids) => {
    let data = [];
    let i = 0;
    for (const uen of tabUens) {
      i++;
      if (!ids.includes(uen)) {
        if (i > 1) await new Promise((resolve) => setTimeout(resolve, 1000));
        const info = await axios.get(`${DATA_BE_URL}/BE${uen}/info`, {
          headers: {
            API_ID: DATA_BE_ID,
            API_KEY: DATA_BE_KEY,
          },
        });
        if (info.data.valid) {
          const agent = await axios.get(
            `${DATA_BE_URL}/BE${uen}/legal_representative_persons`,
            {
              headers: {
                API_ID: DATA_BE_ID,
                API_KEY: DATA_BE_KEY,
              },
            }
          );

          let firstName = "";
          let lastName = "";
          if (agent.data.vat_valid && agent.data.total > 0) {
            firstName = agent.data.list[0].first_name;
            lastName = agent.data.list[0].last_name;
          }

          data.push({
            uen: info.data.vat_formatted,
            iban: info.data.identifier,
            name: info.data.company_name,
            address1: getAddress(info.data.addresses),
            zipCode:
              info.data.addresses && info.data.addresses.length > 0
                ? info.data.addresses[0]["zip_code"]
                : "",
            city: getCity(info.data.addresses),
            country:
              info.data.addresses && info.data.addresses.length > 0
                ? info.data.addresses[0]["country_code"]
                : "",
            phone: info.data.phone_number ? info.data.phone_number : "",
            language: info.data.language ? info.data.language : "",
            firstName: firstName,
            lastName: lastName,
            email: "",
          });
        }
      }
    }
    return data;
  };
  const handleClick = async () => {
    let data = [];
    let dataBe = [];
    let ids = [];
    let resp = null;
    const tabUens = uens.replace(/\./g, "").match(/\d+/g);
    if (tabUens) {
      setFetching(true);
      try {
        resp = await getClientsByUen({ token, uens: tabUens });
        if (resp && resp.data.result === "OK") {
          ids = resp.data.data.map((folder) => folder.uen);
          data = resp.data.data
            .filter(
              (folder) =>
                folder.owner === null || folder?.owner.id !== fidudiary.id
            )
            .map((folder) => {
              return {
                id: folder.id,
                owner: fidudiary.id,
                address1: folder.address1,
                city: folder.city,
                country: folder.country,
                iban: "",
                name: folder.name,
                phone: folder.legalRepresentative.mainPhone
                  ? folder.legalRepresentative.mainPhone
                  : "",
                firstName: folder.legalRepresentative.firstName
                  ? folder.legalRepresentative.firstName
                  : "",
                lastName: folder.legalRepresentative.lastName
                  ? folder.legalRepresentative.lastName
                  : "",
                language: folder.legalRepresentative.language
                  ? folder.legalRepresentative.language
                  : "",
                email: folder.legalRepresentative.mainEmail
                  ? folder.legalRepresentative.mainEmail
                  : "",
                uen: folder.uen,
                zipCode: folder.zipCode,
              };
            });
        }
      } catch (e) {}

      try {
        dataBe = await fetchInDataBe(tabUens, ids);
        data = [...data, ...dataBe];
      } catch (e) {}

      setFetching(false);
      if (data.length) {
        setResponseData({
          head: [
            "uen",
            "name",
            "address1",
            "city",
            "country",
            "phone",
            "firstName",
            "lastName",
            "language",
            "email",
            "zipCode",
          ],
          body: data,
        });
        setModalOpen(true);

        importClients(token, {
          data: formatData(data),
        })
          .then((resp) => {
            let table = resp.data.data;
            table["extra"] = data
              .filter((item) => item.id)
              .reduce(function (map, obj) {
                map[obj.id] = obj;
                return map;
              }, {});
            setResponseData(table);
            setModalOpen(true);
          })
          .finally(() => setFetching(false));
      }
    }
  };

  return (
    <div className={props.addFormClass ? styles.form : ""}>
      {isFetching && <div className="lmask"></div>}
      <div
        className={`${styles.form_content} ${
          props.addFormClass && styles.form_content_pad
        }`}
      >
        <div className={styles.titles}>
          <h4>Ajout Express</h4>
          <p>
            Ils seront invités par United Associates et devront compléter leur
            formulaire par eux-même
          </p>
        </div>

        <FormInput
          required={true}
          label={_("uen")}
          autoComplete="off"
          value={input}
          type="textarea"
          rows="15"
          onChange={(e) => {
            setInput(e.target.value);
            setUens(e.target.value);
          }}
        />
      </div>
      <div className={styles.actions}>
        <Button
          variant="default"
          onClick={() => {
            if (props.setSideBar) props.setSideBar("DEFAULT");
          }}
        >
          {_("cancel")}
        </Button>

        <Button onClick={handleClick}>{_("add")}</Button>
      </div>
      <ExpressClientModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        responseData={responseData}
        afterSave={props.afterSave}
      />
    </div>
  );
};

export default ClientExpress;
