import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import classNames from "classnames";

import {
  deleteSpecialty,
  deleteSpecialtyType,
  getSpecialties,
} from "../../api";
import _ from "../../i18n";
import styles from "./ManageSpecialties.module.scss";
import Sidebar from "../common/Sidebar";

const ManageSpecialties = () => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const { token } = auth;

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [specialties, setSpecialties] = useState([]);
  const [currentType, setCurrentType] = useState(null);
  const [parent, setParent] = useState(null);
  const [createType, setCreateType] = useState("type");
  const [dropDownId, setDropDownId] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  let { isLoading } = useQuery(
    ["getSpecialties", token, fiduciary],
    async () => {
      try {
        if (token && fiduciary) {
          const response = await getSpecialties({
            token,
          });
          if (response.data.data) {
            setCurrentType(response.data.data[0]);
            setSpecialties(response.data.data);
          }
          return response.data.data;
        }
      } catch (error) {
        setSpecialties(null);
      }
    }
  );

  const onConfirmTypeDelete = async () => {
    if (createType === "type") {
      await deleteSpecialtyType({ token: auth.token, id: currentType.id });
      const filtered = specialties.filter((item) => item.id !== currentType.id);
      setSpecialties(filtered);
      filtered.length > 0 ? setCurrentType(filtered[0]) : setCurrentType(null);
    } else {
      await deleteSpecialty({ token: auth.token, id: dropDownId.id });
      if (parent) {
        setCurrentType({
          ...currentType,
          specialties: currentType.specialties.map((spec) => {
            if (spec.id === parent.id) {
              return {
                ...spec,
                specialties: spec.specialties.filter(
                  (el) => el.id !== dropDownId.id
                ),
              };
            }
            return spec;
          }),
        });
        setSpecialties(
          specialties.map((type) => {
            if (type.id === currentType.id) {
              return {
                ...type,
                specialties: type.specialties.map((spec) => {
                  if (spec.id === parent.id) {
                    return {
                      ...spec,
                      specialties: spec.specialties.filter(
                        (el) => el.id !== dropDownId.id
                      ),
                    };
                  }
                  return spec;
                }),
              };
            } else return type;
          })
        );
      } else {
        setCurrentType({
          ...currentType,
          specialties: currentType.specialties.filter(
            (spec) => spec.id !== dropDownId.id
          ),
        });
        setSpecialties(
          specialties.map((type) => {
            if (type.id === currentType.id) {
              return {
                ...type,
                specialties: type.specialties.filter(
                  (spec) => spec.id !== dropDownId.id
                ),
              };
            } else return type;
          })
        );
      }
    }

    setDeleteModalOpen(false);
    setDropDownId(null);
  };
  const toggleDropDown = ({ e, typeId, elementType }) => {
    e.stopPropagation();
    dropDownId &&
    dropDownId.id === typeId &&
    dropDownId.elementType === elementType
      ? setDropDownId(null)
      : setDropDownId({ id: typeId, elementType });
  };

  const showDelete = ({ type, specialty, subSpecialty }) => {
    if (type) {
      if (type.specialties)
        return (
          type.specialties
            .map((spec) => {
              return [spec, ...(spec.specialties ? spec.specialties : [])];
            })
            .flat(1)
            .filter((el) => el.isUsed).length === 0
        );
      else return true;
    }
    if (specialty) {
      if (specialty.specialties)
        return specialty.specialties.filter((el) => el.isUsed).length === 0;
      else if (currentType.hasChoices) {
        return !specialty.isUsed;
      } else return true;
    }
    if (subSpecialty) {
      return !subSpecialty.isUsed;
    }
  };
  return (
    <div className={styles.servicesPage}>
      <div className={styles.header}>
        <div>
          <h1>{_("manage_specialties")}</h1>
          <span>{_("manage_fiduciary_specialties")}</span>
        </div>
      </div>
      {isLoading && specialties && specialties.length === 0 && (
        <div className="lmask"></div>
      )}
      {!isLoading && !specialties && (
        <div
          style={{
            textAlign: "center",
            maxWidth: "600px",
            margin: "0 auto 60px",
            color: "#2C394B",
            fontSize: "2rem",
          }}
        >
          <img alt="not found" src="/img/notFound.svg" />
          <div>{_("nothing_found")}</div>
        </div>
      )}
      {specialties && specialties.length > 0 && (
        <div className="grid-x">
          <div className="cell small-3">
            <ul className={styles.typesList}>
              {specialties.map((type) => (
                <li
                  className={classNames(
                    styles.type,
                    currentType && currentType.id === type.id
                      ? styles.active
                      : ""
                  )}
                  onClick={() => setCurrentType(type)}
                >
                  <span className={styles.name}>{type[nameAttr]}</span>
                  {currentType && type.id === currentType.id && (
                    <div className={styles.dropDownContainer}>
                      <span
                        className={`${styles.cellIcon} ${
                          dropDownId &&
                          dropDownId.id === type.id &&
                          dropDownId.elementType === "type" &&
                          styles.active
                        }`}
                      >
                        <span
                          className={`${
                            dropDownId &&
                            dropDownId.id === type.id &&
                            dropDownId.elementType === "type"
                              ? "icon-ttp-close"
                              : "icon-ttp-three-dots"
                          } ${styles.settingsIcon}`}
                          onClick={(e) =>
                            toggleDropDown({
                              e,
                              typeId: type.id,
                              elementType: "type",
                            })
                          }
                        />
                      </span>
                      <ul
                        className={`${styles.menuDropdown} ${
                          dropDownId &&
                          dropDownId.id === type.id &&
                          dropDownId.elementType === "type" &&
                          styles.show
                        }`}
                      >
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setCreateType("type");
                            setCurrentItem(type);
                            setSidebarOpened(true);
                          }}
                        >
                          {_("edit")}
                        </li>
                        {showDelete({ type }) && (
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              setDeleteModalOpen(true);
                              setCreateType("type");
                            }}
                          >
                            {_("delete")}
                          </li>
                        )}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div
            className={classNames(
              "cell small-9",
              currentType && currentType.specialties ? styles.right : ""
            )}
          >
            <div className={styles.top}>
              <button
                className={classNames(styles.addButton)}
                onClick={() => {
                  setCreateType("specialty");
                  setSidebarOpened(true);
                }}
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.2402 6.0242H7.97672V0.760665C7.97672 0.520889 7.65113 0.000976562 6.99997 0.000976562C6.34881 0.000976562 6.02322 0.520917 6.02322 0.760665V6.02423H0.759688C0.519941 6.0242 0 6.34979 0 7.00092C0 7.65205 0.519941 7.97767 0.759688 7.97767H6.02325V13.2412C6.02325 13.481 6.34881 14.0009 7 14.0009C7.65119 14.0009 7.97675 13.481 7.97675 13.2412V7.97767H13.2403C13.48 7.97767 14 7.65211 14 7.00092C14 6.34974 13.48 6.0242 13.2402 6.0242Z"
                    fill="#18A0FB"
                  />
                </svg>
                {_("add_specialty_bloc")}
              </button>
            </div>
            {currentType.specialties && (
              <div className="grid-x">
                {currentType.specialties.map((specialty, i) => (
                  <div
                    className={classNames(
                      "cell small-4",
                      styles.specialty,
                      (i + 1) % 3 === 0 ? styles.noBorder : ""
                    )}
                  >
                    <div className={styles.title}>
                      <div className={styles.flex}>
                        {specialty.icon && (
                          <i
                            className={`icon webtool-${specialty.icon.label}`}
                          />
                        )}
                        <span className={styles.name}>
                          {specialty[nameAttr]}
                        </span>
                      </div>

                      <div className={styles.dropDownContainer}>
                        <span
                          className={`${styles.cellIcon} ${
                            dropDownId &&
                            dropDownId.id === specialty.id &&
                            dropDownId.elementType === "specialty" &&
                            styles.active
                          }`}
                        >
                          <span
                            className={`${
                              dropDownId &&
                              dropDownId.id === specialty.id &&
                              dropDownId.elementType === "specialty"
                                ? "icon-ttp-close"
                                : "icon-ttp-three-dots"
                            } ${styles.settingsIcon}`}
                            onClick={(e) =>
                              toggleDropDown({
                                e,
                                typeId: specialty.id,
                                elementType: "specialty",
                              })
                            }
                          />
                        </span>
                        <ul
                          className={`${styles.menuDropdown} ${
                            dropDownId &&
                            dropDownId.id === specialty.id &&
                            dropDownId.elementType === "specialty" &&
                            styles.show
                          }`}
                        >
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              setCreateType("specialty");
                              setCurrentItem(specialty);
                              setSidebarOpened(true);
                            }}
                          >
                            {_("edit")}
                          </li>
                          {showDelete({ specialty }) && (
                            <li
                              onClick={(e) => {
                                e.stopPropagation();
                                setCreateType("specialty");
                                setDeleteModalOpen(true);
                              }}
                            >
                              {_("delete")}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {specialty.specialties && (
                      <ul className={styles.specialtiesList}>
                        {specialty.specialties.map((subSpecialty) => (
                          <li className={styles.subSpec}>
                            {subSpecialty[nameAttr]}
                            <div className={styles.dropDownContainer}>
                              <span
                                className={`${styles.cellIcon} ${
                                  dropDownId &&
                                  dropDownId.id === subSpecialty.id &&
                                  dropDownId.elementType === "subSpecialty" &&
                                  styles.active
                                }`}
                              >
                                <span
                                  className={`${
                                    dropDownId &&
                                    dropDownId.id === subSpecialty.id &&
                                    dropDownId.elementType === "subSpecialty"
                                      ? "icon-ttp-close"
                                      : "icon-ttp-three-dots"
                                  } ${styles.settingsIcon}`}
                                  onClick={(e) =>
                                    toggleDropDown({
                                      e,
                                      typeId: subSpecialty.id,
                                      elementType: "subSpecialty",
                                    })
                                  }
                                />
                              </span>
                              <ul
                                className={`${styles.menuDropdown} ${
                                  dropDownId &&
                                  dropDownId.id === subSpecialty.id &&
                                  dropDownId.elementType === "subSpecialty" &&
                                  styles.show
                                }`}
                              >
                                <li
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCreateType("specialty");
                                    setCurrentItem(subSpecialty);
                                    setSidebarOpened(true);
                                    setParent(specialty);
                                  }}
                                >
                                  {_("edit")}
                                </li>
                                {showDelete({ subSpecialty }) && (
                                  <li
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setCreateType("specialty");
                                      setDeleteModalOpen(true);
                                      setParent(specialty);
                                    }}
                                  >
                                    {_("delete")}
                                  </li>
                                )}
                              </ul>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    <button
                      className={classNames(
                        styles.addButton,
                        styles.addButton_small
                      )}
                      onClick={() => {
                        setCreateType("specialty");
                        setParent(specialty);
                        setSidebarOpened(true);
                      }}
                    >
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.2402 6.0242H7.97672V0.760665C7.97672 0.520889 7.65113 0.000976562 6.99997 0.000976562C6.34881 0.000976562 6.02322 0.520917 6.02322 0.760665V6.02423H0.759688C0.519941 6.0242 0 6.34979 0 7.00092C0 7.65205 0.519941 7.97767 0.759688 7.97767H6.02325V13.2412C6.02325 13.481 6.34881 14.0009 7 14.0009C7.65119 14.0009 7.97675 13.481 7.97675 13.2412V7.97767H13.2403C13.48 7.97767 14 7.65211 14 7.00092C14 6.34974 13.48 6.0242 13.2402 6.0242Z"
                          fill="#18A0FB"
                        />
                      </svg>
                      {_("add_specialty")}
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      <ModalConfirm
        type="delete"
        isOpen={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={onConfirmTypeDelete}
        title={_("delete_confirm")}
        text={_("text_delete_item")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
      <div className={styles.bubbles}>
        <div
          onClick={() => {
            setParent(null);
            setCreateType("type");
            setSidebarOpened(true);
          }}
        >
          <i className="icon-ttp-plus-outline"></i>
          <span style={{ paddingLeft: "1rem" }}>{_("add")}</span>
        </div>
      </div>

      <Sidebar
        isOpened={sidebarOpened}
        hideSidebar={(e) => {
          e.stopPropagation();
          setCurrentItem(null);
          setDropDownId(null);
          setParent(null);
          setSidebarOpened(false);
        }}
        sidebarClass="sidebar"
        parent={parent}
        currentType={currentType}
        setCurrentType={setCurrentType}
        createType={createType}
        currentItem={currentItem}
        specialties={specialties}
        setSpecialties={setSpecialties}
      />
    </div>
  );
};

export default ManageSpecialties;
