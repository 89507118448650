import React from "react";
import classNames from "classnames";
import { SortableContainer } from "react-sortable-hoc";

import styles from "./Testimonial.module.scss";
import SortableTestimonial from "./SortableTestimonial";

export default SortableContainer(
  ({ testimonials, onDelete, onEdit, onHide }) => {
    let testimonialsJsx = testimonials.map((testimonial, index) => {
      return (
        <SortableTestimonial
          testimonial={testimonial}
          key={testimonial.id}
          index={index}
          onDelete={onDelete}
          onEdit={onEdit}
          onHide={onHide}
        />
      );
    });

    return <div className={classNames(styles.list)}>{testimonialsJsx}</div>;
  }
);
