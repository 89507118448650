import classNames from "classnames";
import React from "react";
import styles from "./Service.module.scss";
import { IconEye } from "../../common/icons/IconEye";
import { IconPen } from "../../common/icons/IconPen";
import { IconTrash } from "../../common/icons/IconTrash";
import { useSelector } from "react-redux";

const Service = ({ service, onDelete, onEdit, onHide }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  return (
    <div
      className={classNames("cell small-12 medium-6 large-4", styles.cardCont)}
    >
      <div
        className={classNames(
          styles.service_card,
          !service.show ? styles.service_card_hide : ""
        )}
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(9, 22, 38, 0.2), rgba(9, 22, 38, 0.2)),url(${service.image})`,
        }}
      >
        <div className={styles.actions}>
          {onHide && (
            <div
              className={classNames(
                styles.icon,
                !service.show ? styles.icon_hide : ""
              )}
              onClick={() => onHide(service)}
            >
              <IconEye />
            </div>
          )}
          {onEdit && (
            <div className={styles.icon} onClick={() => onEdit(service)}>
              <IconPen />
            </div>
          )}
          <div className={styles.icon} onClick={() => onDelete(service)}>
            <IconTrash />
          </div>
        </div>
        {service[titleAttr] && (
          <div className={styles.title}>
            {service[titleAttr].length > 50
              ? service[titleAttr].substring(0, 47) + "..."
              : service[titleAttr]}
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;
