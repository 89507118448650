import React from "react";

const EmailIcon = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M8 8H40C42.2092 8 44 9.79086 44 12V36C44 38.2092 42.2092 40 40 40H8C5.79086 40 4 38.2092 4 36V12C4 9.79086 5.79086 8 8 8ZM27.3 30.9L40 22V17.8L25.3 28.1C24.5182 28.6426 23.4818 28.6426 22.7 28.1L8 17.8V22L20.7 30.9C22.682 32.2854 25.318 32.2854 27.3 30.9Z'
        fill='black'
      />
    </svg>
  );
};

export default EmailIcon;
