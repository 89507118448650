import React from "react";

import { TTP_API_URL } from "../../config";

import styles from "./PublicPortal.module.scss";

const User = ({ user }) => {
  return (
    <div className="cell small-12 large-3 medium-6 ">
      <div className={styles.user}>
        <div
          className={styles.userImg}
          style={{
            backgroundImage: `url(${
              user.avatarUrl || user.avatar
                ? user.avatarUrl
                  ? user.avatarUrl
                  : require(`${TTP_API_URL}/${user.avatar}`)
                : "/img/default_avatar.jpg"
            })`,
          }}
        />

        <div className={styles.officialInfo}>
          <h4>{user.firstName + " " + user.lastName}</h4>
          {/* <span className={styles.function}>
            {user.function ? user.function : null}
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default User;
