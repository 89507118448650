import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ClientBox from "./ClientBox";
import ClientList from "./ClientList";
import Button from "../../common/Button";
import { saveFolderStep } from "../../../api";
import { setOnBoardingStep } from "../../../store";
import _ from "../../../i18n";

import styles from "./Client.module.scss";

const Client = (props) => {
  const token = useSelector((state) => state.auth.token);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [currentItem, setCurrentItem] = useState(null);
  const [refreshList, setRefreshList] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();

  const handleAfterSave = () => {
    setRefreshList(true);
  };

  const handleNextStep = () => {
    if (fiduciary.onBoardingStep === "COLLABORATORS") {
      setIsSaving(true);
      saveFolderStep(token, { step: "COMPLETE", id: fiduciary.id })
        .then((resp) => {
          dispatch(setOnBoardingStep("COMPLETE"));
          setIsSaving(false);
          navigate("/settings");
          // props.setCurrentStep(5);
        })
        .catch((e) => {
          setIsSaving(false);
        });
    } else {
      navigate("/settings");
      // props.setCurrentStep(5);
    }
  };

  return (
    <div className="grid-container">
      {isSaving ? <div className="lmask"></div> : null}
      <h1 className={styles.pageTitle}>{_("add_customer_records_title")}</h1>
      <p className={styles.pageHelpTitle}>
        Nous vous recommandons l’importation via le fichier excel. Idéalement,
        lié au moins un contact à chaque dossier afin de lui rendre un accès
        futur possible.
      </p>

      <div className="grid-x grid-margin-x">
        <div className="cell small-12 medium-12 large-5">
          <ClientBox
            afterSave={handleAfterSave}
            currentItem={currentItem}
            setCurrentItem={setCurrentItem}
            resetForm={resetForm}
            setResetForm={setResetForm}
          />
        </div>
        <div className="cell small-12 medium-12 large-7">
          <ClientList
            onEdit={(e) => setCurrentItem(e)}
            setCurrentItem={setCurrentItem}
            refreshList={refreshList}
            setRefreshList={setRefreshList}
            afterDelete={() => setResetForm(true)}
          />
        </div>
      </div>

      <div className={styles.actionBar}>
        <div className="grid-container">
          <Button
            variant="default"
            icon="ttp-double-arrow-left"
            onClick={() => props.setCurrentStep(3)}
          >
            {_("retour").toUpperCase()}
          </Button>

          <Button iconRight="ttp-double-arrow-right" onClick={handleNextStep}>
            {_("continue").toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Client;
