export function SendingWidget(options) {
  this.url = options.url;

  this.widget = null;
  this.overlay = null;

  this.isWidgetLoaded = false;
  this.messageQueue = [];

  const self = this;

  const WIDGET_WIDTH = 820;
  const WIDGET_HEIGHT = 566;

  const WIDGET_IFRAME_ID = "sending-widget-iframe";

  const CONTEXT_TYPE = "PORTAL";

  const HOOKS = {
    WIDGET_LOADED: "WIDGET_LOADED",
    SET_CONTEXT: "SET_RECIPIENT_CONTEXT",
    WIDGET_HIDDEN: "WIDGET_HIDDEN",
    WIDGET_MINIMIZED: "WIDGET_MINIMIZED",
    WIDGET_MAXIMIZED: "WIDGET_MAXIMIZED",
    WIDGET_FULL_SCREEN: "WIDGET_FULL_SCREEN",
  };

  this.showEmailWidget = (recipientContext) => {
    if (self.widget === null) {
      self.widget = self.createComposeEmailWidget();
      self.attachMessageListener();
    }
    self.maximizeWidget();
    self.setRecipientContext(recipientContext);
  };

  this.createComposeEmailWidget = function () {
    let widget = document.createElement("div");
    widget.className = "sending-widget";
    self.restyleElement(widget, {
      position: "fixed",
      width: `${WIDGET_WIDTH}px`,
      height: `${WIDGET_HEIGHT}px`,
      maxHeight: "100vh",
      bottom: "0",
      borderRadius: "5px",
      background: "#F3FAFF",
      right: "100px",
      "z-index": "12345",
    });

    const container = document.createElement("div");
    container.style.width = "100%";
    container.style.height = "100%";

    let iframe = document.createElement("iframe");
    iframe.id = WIDGET_IFRAME_ID;
    iframe.allowTransparency = "true";
    iframe.src = `${this.url}/compose-email`;

    self.restyleElement(iframe, {
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: "5px",
      backgroundColor: "transparent",
    });

    container.appendChild(iframe);
    widget.appendChild(container);
    document.body.appendChild(widget);

    return widget;
  };

  this.attachMessageListener = () => {
    window.addEventListener(
      "message",
      (msg) => {
        if (typeof msg.data !== "object" || !msg.data.action) {
          return;
        }
        self.applyAction(msg.data);
      },
      false
    );
  };

  this.applyAction = (data) => {
    switch (data.action) {
      case HOOKS.WIDGET_LOADED:
        self.isWidgetLoaded = true;
        if (self.messageQueue.length > 0) {
          self.messageQueue.forEach((message) => {
            self.callHook(message);
          });
        }
        break;

      case HOOKS.WIDGET_FULL_SCREEN:
        self.showOverlay();
        self.restyleElement(self.widget, {
          width: "80%",
          height: "87%",
          right: "unset",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        });
        break;

      case HOOKS.WIDGET_HIDDEN:
      case HOOKS.WIDGET_MAXIMIZED:
      case HOOKS.WIDGET_MINIMIZED:
        self.hideOverlay();
        self.restyleElement(self.widget, {
          width: `${data.width}px`,
          height: `${data.height}px`,
          right: "100px",
          left: "unset",
          top: "unset",
          transform: "unset",
        });
        break;
    }
  };

  this.maximizeWidget = () => {
    if (self.widget === null) {
      return;
    }
    self.widget.style.width = `${WIDGET_WIDTH}px`;
    self.widget.style.height = `${WIDGET_HEIGHT}px`;
    // this.applyAction(HOOKS.WIDGET_MAXIMIZED);
  };

  this.restyleElement = (element, style) => {
    if (!style) {
      return;
    }
    Object.keys(style).forEach(
      (property) => (element.style[property] = style[property])
    );
  };

  this.showOverlay = () => {
    if (self.overlay == null) {
      self.overlay = document.createElement("div");
      self.restyleElement(self.overlay, {
        position: "fixed",
        inset: 0,
        "background-color": "rgba(10, 10, 10, 0.45)",
        "z-index": 100,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      });
      document.body.appendChild(self.overlay);
    }
    self.overlay.style.display = "block";
  };

  this.hideOverlay = () => {
    if (self.overlay) {
      self.overlay.style.display = "none";
    }
  };

  this.setRecipientContext = (recipientContext) => {
    self.callHook({
      action: HOOKS.SET_CONTEXT,
      params: {
        recipientId: recipientContext.recipientId,
        recipientType: recipientContext.recipientType,
        email: recipientContext.email,
        language: recipientContext.language || "fr",
        targetId: recipientContext.event,
        targetApp: CONTEXT_TYPE,
      },
    });
  };

  this.callHook = (message) => {
    if (self.isWidgetLoaded) {
      document
        .getElementById(WIDGET_IFRAME_ID)
        .contentWindow.postMessage(message, "*");
    } else {
      self.messageQueue.push(message);
    }
  };
}
