import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { HeaderUA } from "tamtam-components";
import { PopupButton } from "react-calendly";

import _ from "../../i18n";
import {
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  fetchAuthUser,
  setLanguage,
  fetchFiduciary,
  setFolderFetched,
} from "../../store";
import {
  getAuthInfosFromCookie,
  authHasExpired,
  getCookie,
  setCookie,
  logout,
} from "../../utils";
import { getUserHasFiduciaryPlan } from "../../api";
import { APP_ENV, TTP_ONBOARDING_URL } from "../../config";

import styles from "./MemberShipSuccess.module.scss";

const rightIcons = {
  home: {
    activated: false,
    url: TTP_ONBOARDING_URL,
  },
  profile: {
    activated: false,
    url: "/profile",
  },
  ebox: {
    activated: false,
  },
  search: {
    activated: false,
  },
  notifs: {
    activated: false,
  },
  backoffice: {
    activated: false,
  },
  faq: {
    activated: false,
  },
  apps: {
    activated: false,
  },
};

const MemberShipSuccess = () => {
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);

  const [loaderMembership, setLoaderMembership] = useState(false);
  const [hasFiduciaryPlan, setHasFiduciaryPlan] = useState(false);

  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const paramType = urlParams.get("type");

  const handleAuthUser = (user) => {
    dispatch(setAuthToken(user.token));
    dispatch(fetchAuthUser(user));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(fetchFiduciary());

    if (paramType === "FIDUCIARY") {
      checkHasFiduciaryPlan(user);
    }
  };

  const checkHasFiduciaryPlan = (user) => {
    setLoaderMembership(true);
    getUserHasFiduciaryPlan(user.token)
      .then((resp) => {
        if (resp.data.data.hasFiduciary) {
          setHasFiduciaryPlan(true);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setLoaderMembership(false);
      });
  };

  useEffect(() => {
    let authInfos = getAuthInfosFromCookie();

    if (!authInfos || authHasExpired(authInfos)) {
      dispatch(setAuthUser(null));

      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

      setCookie(
        "ttp_auth_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
      setCookie(
        "ttp_community_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
    }

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos) {
      handleAuthUser(authInfos);
    } else {
      dispatch(setFolderFetched(true));
    }

    $("#app-loader")
      .fadeOut()
      .promise()
      .done(() => $("#app-loader").remove());
  }, []);

  const changeLng = (language) => {
    document.location.href = "/" + language;
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <>
      {auth.user ? (
        <HeaderUA
          rightIcons={rightIcons}
          app={{
            appName: "United Associates",
            appLogoUrl:
              "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
            appUrl: "/",
          }}
          auth={{
            navCommunity: null,
            user: auth.user,
            token: auth.token,
          }}
          settings={[]}
          lng={language}
          languages={[]}
          env={APP_ENV}
          onLanguageChange={(langue) => changeLng(langue)}
          onLogoutClick={(e) => handleLogout(e)}
          RouterLink={Link}
        />
      ) : (
        <HeaderUA
          auth={{
            navCommunity: null,
            user: null,
            token: null,
          }}
          lng={language}
          languages={[]}
          app={{
            appName: "United Associates",
            appLogoUrl:
              "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
            appUrl: "/",
          }}
          env={APP_ENV}
          RouterLink={Link}
          signInUrl="/login"
          signUpUrl="/register"
        />
      )}
      <div className={styles.container}>
        {loaderMembership && <div className="lmask"></div>}
        <div className={styles.icon}>
          <i className="icon-ttp-check"></i>
        </div>
        <h3 className={styles.title}>
          Félicitations! Vous êtes membre de
          <br />
          l’Ordre des experts comptable (OECCBB)
        </h3>
        {paramType === "FIDUCIARY" && hasFiduciaryPlan && (
          <>
            <p className={styles.subtitle}>
              Vous allez être contacté par United Associated pour vous
              accompagner dans l'onboarding
            </p>
            <PopupButton
              url="https://calendly.com/info-unitedassociates23-03/demonstration-united-associates"
              rootElement={document.getElementById("root")}
              text="Cliquez ici pour planifier"
              className={styles.calendlyBtn}
              prefill={
                auth.user
                  ? {
                      email: auth.user.mainEmail,
                      firstName: auth.user.firstName,
                      lastName: auth.user.lastName,
                      name: auth.user.firstName + " " + auth.user.lastName,
                    }
                  : {}
              }
            />
          </>
        )}
      </div>
    </>
  );
};

export default MemberShipSuccess;
