import React from 'react';
import { useSelector } from 'react-redux';
import { Article } from "tamtam-components";
import { APP_ENV, TTP_ONBOARDING_URL } from "../../../config";

const ArticleList = ({ articles, types}) => {

    const user = useSelector(state => state.auth.user);

    if (Array.isArray(types)) {

        const classes = (types.length === 5) 
                            ? ['cell small-8', 'cell small-4'] 
                            : ['cell small-6', 'cell small-3'];
        return (
            <div className="grid-x grid-margin-x grid-margin-y">
                { articles.map((article, index) => <div key={article.id} className={(types[index]==='type6') ? classes[0]: classes[1]}>
                    <Article
                        article={article}
                        showSummary={true}
                        type={types[index]}
                        isFetching={false}
                        env={APP_ENV}
                        onLike={() => console.log('like')}
                        host={TTP_ONBOARDING_URL}
                        user={user}
                    />
                </div>)}
            </div>
        );
    }

    return (
        <div className="grid-x grid-margin-x grid-margin-y">
            { articles.map((article, index) => <div className="cell small-6">
                <Article
                    article={article}
                    showSummary={true}
                    type={types}
                    isFetching={false}
                    env={APP_ENV}
                    onLike={() => console.log('like')}
                    host={TTP_ONBOARDING_URL}
                    user={user}
                />
            </div>)}
        </div>
    ); 
};

export default  ArticleList;