import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./Payment.module.scss";

import _ from "../../../i18n";
import { displayDocument } from "../../../api";

const CommandDetails = ({ orderPayment, orderDetails }) => {
  const auth = useSelector((state) => state.auth);

  const handleDownload = () => {
    displayDocument(auth.token, orderPayment.invoice.id, "INVOICE");
  };

  return (
    <div className={classNames(styles.command_details, "cell medium-8")}>
      <div className={classNames(styles.header, "grid-x")}>
        <div className={classNames(styles.titles, "cell medium-9")}>
          <h4>{_("facture_details")} :</h4>
          <span>{orderPayment.comment}</span>
        </div>
        <div className="cell medium-3">
          <div className={styles.vcs}>
            <span>{orderPayment.clientOrganization}</span>
            <span>{orderPayment.clientStreet}</span>
            <span>
              {orderPayment.clientPostalCode +
                " " +
                orderPayment.clientLocality}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.details}>
        <table>
          <thead>
            <th className={styles.desc}>{_("description")}</th>
            <th>{_("price")}</th>
            <th>{_("quantity")}</th>
            <th>{_("tva_rate")}</th>
            <th>{_("tvac_amount")}</th>
          </thead>
          <tbody>
            {orderDetails.map((row, index) => (
              <tr key={index}>
                <td className={styles.desc}>{row.description}</td>
                <td>{row.unitPrice / 100} €</td>
                <td>1</td>
                <td>{row.vatRate}%</td>
                <td>{row.vatAmount / 100} €</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className={styles.total}>
        {orderPayment.invoice ? (
          <div className={styles.download} onClick={handleDownload}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.5 7C1.77614 7 2 7.22386 2 7.5V9.5C2 9.63261 2.05268 9.75978 2.14645 9.85355C2.24021 9.94732 2.36739 10 2.5 10H9.5C9.63261 10 9.75978 9.94732 9.85355 9.85355C9.94732 9.75978 10 9.63261 10 9.5V7.5C10 7.22386 10.2239 7 10.5 7C10.7761 7 11 7.22386 11 7.5V9.5C11 9.89783 10.842 10.2794 10.5607 10.5607C10.2794 10.842 9.89783 11 9.5 11H2.5C2.10217 11 1.72064 10.842 1.43934 10.5607C1.15804 10.2794 1 9.89782 1 9.5V7.5C1 7.22386 1.22386 7 1.5 7Z"
                fill="#6D7F92"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.14645 4.64645C3.34171 4.45118 3.65829 4.45118 3.85355 4.64645L6 6.79289L8.14645 4.64645C8.34171 4.45118 8.65829 4.45118 8.85355 4.64645C9.04882 4.84171 9.04882 5.15829 8.85355 5.35355L6.35355 7.85355C6.15829 8.04882 5.84171 8.04882 5.64645 7.85355L3.14645 5.35355C2.95118 5.15829 2.95118 4.84171 3.14645 4.64645Z"
                fill="#6D7F92"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 1C6.27614 1 6.5 1.22386 6.5 1.5V7.5C6.5 7.77614 6.27614 8 6 8C5.72386 8 5.5 7.77614 5.5 7.5V1.5C5.5 1.22386 5.72386 1 6 1Z"
                fill="#6D7F92"
              />
            </svg>
            <span>{_("download_invoice")}</span>
          </div>
        ) : (
          <div></div>
        )}
        <div className={styles.total_details}>
          <table>
            <tbody>
              <tr className={styles.first}>
                <td>Total HTVA</td>
                <td className={styles.val}>
                  {orderPayment.excludingVatAmount / 100} €
                </td>
              </tr>
              <tr>
                <td>TVA</td>
                <td className={styles.val}>{orderPayment.vatAmount / 100} €</td>
              </tr>
              <tr className={styles.last}>
                <td>Total TVAC</td>
                <td className={styles.val}>
                  {orderPayment.includingVatAmount / 100} €
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CommandDetails;
