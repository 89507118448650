import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Switch from "../../common/Switch/Switch";
import styles from "./AppCard.module.scss";
import { saveAppsSettings } from "../../../api";
import { setFiduciary } from "../../../store";

import _ from "../../../i18n";
import Button from "../Button";
import NewsletterModal from "../NewsletterModal";

const AppCard = ({ app, type, openModal, setCurrentApp }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [tools, setTools] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (fiduciary && app) {
      const preferences = fiduciary[`${app.index}Preferences`];
      if (preferences && preferences.apps) {
        setTools(preferences.apps);
      } else setTools([]);
    } else {
      setTools([]);
    }
  }, [fiduciary, app]);

  const handleClick = () => {
    setShow(!show);
  };
  const getIcon = () => {
    if (app?.icon) return `/img/apps/${app.icon}.svg`;
    else return `/img/apps/${app?.name.toLowerCase()}.svg`;
  };

  const handleCheckApp = (tool) => {
    if (tools.filter((t) => t.type === tool).length > 0) {
      setTools(tools.filter((t) => t.type !== tool));
    } else {
      setTools([...tools, { type: tool, config: null }]);
      if (tool === "BLOG_NL_CREATE") {
        setIsOpen(true);
      }
    }
  };

  const handleSave = () => {
    let data = {
      token: auth.token,
      communityId: fiduciary.id,
      data: {
        appName: app.index,
        tools: tools,
      },
    };
    if (app.index === "blog") {
      data.data.lRId = fiduciary.legalRepresentative.id;
    }
    setIsLoading(true);
    saveAppsSettings(data)
      .then((resp) => {
        setIsLoading(false);
        toast.success(_("successfully_saved"));
        const preferences = fiduciary[`${app.index}Preferences`] || {};
        dispatch(
          setFiduciary({
            ...fiduciary,
            [`${app.index}Preferences`]: {
              ...preferences,
              apps: tools,
            },
          })
        );
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(_("error"));
      });
  };

  return (
    <>
      {isLoading && <div className="lmask" />}
      <div className={`${styles.card} ${styles[type]}`}>
        <div className={styles.header}>
          {type === "default" ? (
            <h3 className={styles.default}>Portail Public</h3>
          ) : (
            <>
              <img src={getIcon()} width="34" alt={app?.name} />
              <h3>{app?.name}</h3>
              {type === "installed" && (
                <span>
                  <i className="icon-ttp-check" /> {_("installed")}
                </span>
              )}
            </>
          )}
        </div>
        {app?.desc && <p dangerouslySetInnerHTML={{ __html: app.desc }} />}
        {type === "installed" && (
          <>
            {app?.details && (
              <ul className={styles.details}>
                {app?.details.map((detail, index) => (
                  <>
                    {detail.title && (
                      <li className="" key={`${type}-${index}`}>
                        {detail.title}
                      </li>
                    )}
                    {detail?.list.map((item, idx) => (
                      <li key={`${idx}`}>
                        <span>{item}</span>
                      </li>
                    ))}
                  </>
                ))}
              </ul>
            )}
            {app.tools.length > 0 && (
              <div className={styles.accordion}>
                <div
                  onClick={handleClick}
                  className={show && styles.accordion_active}
                >
                  {show ? _("reduce") : _("show_tools")}
                  <span>
                    {`${tools.length}/${app.tools.length}   `}
                    <i
                      className={
                        show ? "icon-ttp-chevron-up" : "icon-ttp-chevron-down"
                      }
                    />
                  </span>
                </div>
                {show && app?.tools && (
                  <ul>
                    {app.tools.map((tool, index) => (
                      <li key={`${type}-tools-${index}`}>
                        <div className="d-flex">
                          <img
                            src={`/img/icons/${tool.icon}.svg`}
                            width=""
                            alt={tool.icon}
                          />
                          <h4>{tool.title}</h4>
                          <Switch
                            isChecked={
                              tools.filter((t) => t.type === tool.app).length >
                              0
                            }
                            onChange={() => handleCheckApp(tool.app)}
                          />
                        </div>
                        <p>{tool.desc}</p>
                        {tool.tag && (
                          <span className={styles.version}>{tool.tag}</span>
                        )}{" "}
                        {tool.dispo && <span>{tool.dispo}</span>}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            <NewsletterModal
              isOpen={isOpen}
              title={_("newsletter_preferences_title")}
              inProcess={false}
              setIsOpen={setIsOpen}
            />
          </>
        )}
        {app?.second_desc && (
          <p dangerouslySetInnerHTML={{ __html: app.second_desc }} />
        )}
        {type === "installed" && (
          <div className={styles.footer}>
            {app.index === "offfcourse" && (
              <div
                className={styles.userRights}
                onClick={() => {
                  openModal(app.index, true);
                  setCurrentApp(app.index);
                }}
              >
                <i className="icon-ttp-settings-alt" />
                {_("manage_premium_sharing")}
              </div>
            )}

            {app.index == "newsletter" && (
              <div
                className={styles.userRights}
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <i className="icon-ttp-settings-alt" />
                {_("manage_newsletter_campaign")}
              </div>
            )}
            {["blog", "webtools", "faq"].includes(app.index) && (
              <div
                className={styles.userRights}
                onClick={() => {
                  openModal(app.index, true);
                  setCurrentApp(app.index);
                }}
              >
                <i className="icon-ttp-settings-alt" />
                {_("configuration")}
              </div>
            )}
            {/* {app.tools.length > 0 && (
                <div className={styles.install}>
                  <Button onClick={() => handleSave()}>{_("save")}</Button>
                </div>
              )} */}
            {["future", "option", "default"].includes(type) && app?.dispo && (
              <div>
                <i className="icon-ttp-alert-circle" />
                {app.dispo}
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default AppCard;
