import React from "react";
import AppLoader from "../common/Loader/AppLoader";
import styles from "./ManageAccount.module.scss";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { getBillingSubscriptionId } from "../../api";
import { TTP_ONBOARDING_URL, TTP_PAYMENT_URL } from "../../config";
import BackButton from "../common/BackButton";
import _ from "../../i18n";

const ManageAccount = () => {
  const token = useSelector((state) => state.auth.token);
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  const { isLoading, data } = useQuery(
    ["getMedias", token, fiduciary],
    async () => {
      if (token && fiduciary && fiduciary.subscriptionId) {
        const response = await getBillingSubscriptionId({
          token,
          id: fiduciary.subscriptionId,
        });

        return response.data.data.id;
      }
    }
  );

  return (
    <div>
      <BackButton
        onClick={() => (window.location = `${TTP_ONBOARDING_URL}/settings`)}
        title={_("manage_account")}
      />
      {isLoading && !data ? (
        <div className={styles.loaderContainer}>
          <AppLoader />
        </div>
      ) : (
        <iframe
          className={styles.iframe}
          src={`${TTP_PAYMENT_URL}/subscription/${data}?token=${token}&embedded=1`}
          title="UA"
        />
      )}
    </div>
  );
};

export default ManageAccount;
