import React from "react";
import styles from "./ClientModal.module.scss";
import _ from "../../../i18n";
import Button from "../../common/Button";

const Tooltip = (props) => {
  return props.show ? (
    <div className={styles.popup}>
      <div className={styles.main}>
        <div>
          Cette entreprise{" "}
          <strong>{props.bodyData[props.currentIndex].uen}</strong> est client
          d'une autre fiduciaire d'après les données united associates <br />
          <h3>Est ce vraiment votre client ?</h3>
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={props.handleCancel}>
          {_("No")}
        </Button>

        <Button onClick={props.handleClickOk}>{_("Yes")}</Button>
      </div>
    </div>
  ) : null;
};

export default Tooltip;
