import React, { useState, useEffect } from "react";
import _ from "../../../../i18n";
import Loader from "../../../common/Loader";
import styles from "../Client.module.scss";
import { arrayMoveImmutable } from "array-move";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import Button from "../../../common/Button";
import { saveDelegationPriority, saveDelegationType } from "../../../../api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const DragHandle = sortableHandle(() => (
  <span className={styles.dragHandler}>
        <i className="icon-ttp-drag"></i>
    </span>
));

const List = (
  {
    data,
    isLoading,
    setIsFormTabActive,
    setCurrentItem,
    setCurrentDeleteItem,
    setIsOpenDeleteModal,
    toogleTypeStatus,
    isSaving,
    setIsSaving,
  }) => {
  const { token } = useSelector((state) => state.auth);
  const [dropDownId, setDropDownId] = useState(null);
  const [sortedData, setSortedData] = useState([]);
  const [isSavingPriorities, setIsSavingPriorities] = useState(false);
  const [isOrderChanged, setIsOrderChanged] = useState(false);

  useEffect(() => {
    if (data?.data) {
      const sorted = [...data.data].sort((a, b) => {
        if (a.priority === 0) return 1;
        if (b.priority === 0) return -1;
        return a.priority - b.priority;
      });
      setSortedData(sorted);
    }
  }, [data]);

  const toggleDropDown = (e, itemId) => {
    e.stopPropagation();
    dropDownId === itemId ? setDropDownId(null) : setDropDownId(itemId);
  };

  const getActions = (item) => {
    return (
      <div className={styles.dropDownContainer}>
        <span className={`${styles.cellIcon} ${dropDownId === item.id && styles.active}`}>
          <span
            className={`${dropDownId === item.id ? "icon-ttp-close" : "icon-ttp-three-dots"} ${
              styles.settingsIcon
            }`}
            onClick={(e) => toggleDropDown(e, item.id)}
          />
        </span>
        <ul className={`${styles.menuDropdown} ${dropDownId === item.id && styles.show}`}>
          <li
            onClick={(e) => {
              toggleDropDown(e, item.id);
              setCurrentItem(item);
              setIsFormTabActive(true);
            }}
          >
            {_("edit")}
          </li>
          {!item.isUsed && !item.isSynchro && (
            <li
              onClick={(e) => {
                toggleDropDown(e, item.id);
                setCurrentDeleteItem(item);
                setIsOpenDeleteModal(true);
              }}
            >
              {_("delete")}
            </li>
          )}
          {!item.isSynchro && <li
            onClick={(e) => {
              toggleDropDown(e, item.id);
              toogleTypeStatus(item);
            }}
          >
            {item.status === "ENABLED" ? _("Désactiver") : _("Activer")}
          </li>}
        </ul>
      </div>
    );
  };

  const SortableItem = sortableElement(({ value }) => (
    <tr>
      <td>
        <div className={styles.lockIcon}>
          {value.isSynchro ? <i className="icon-ttp-locked"></i> : null}
        </div>
      </td>
      <td className={styles.dragCell}><DragHandle /> {value.titleFr}</td>
      <td>{value.titleNl}</td>
      <td>{value.titleEn}</td>
      <td>
        <span className={styles.icons}>
          {value.isNlSender
            ? <i className={`icon-ttp-check ${styles.check_icon}`} />
            : <i className={`icon-ttp-close ${styles.close_icon}`} />}
        </span>
      </td>
      <td>{getActions(value)}</td>
    </tr>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return <tbody>{children}</tbody>;
  });

  const appsOnSortEnd = ({ oldIndex, newIndex }) => {
    const reorderedData = arrayMoveImmutable(sortedData, oldIndex, newIndex);
    const updatedData = reorderedData.map((item, index) => {
      return { ...item, priority: index + 1 };
    });

    setSortedData(updatedData);
    setIsOrderChanged(true);
  };

  const savePriority = () => {
    if (sortedData.length > 0) {
      setIsSavingPriorities(true);
      saveDelegationPriority(token, sortedData)
        .then((resp) => {
          if (resp.data.result === "OK") {
            toast.success(_("successfully_saved"));
            setIsOrderChanged(false);
          } else {
            toast.error(_("error"));
          }
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
        })
        .finally(() => {
          setIsSavingPriorities(false);
        });
    }
  };


  return (
    <div>
      <table className={`${styles.table} unstriped`}>
        <thead>
        <tr>
          <th></th>
          <th>
            <span>{_("title")} (fr)</span>
          </th>
          <th>
            <span>{_("title")} (nl)</span>
          </th>
          <th>
            <span>{_("title")} (en)</span>
          </th>
          {/*<th>
                 <span>{_("status")}</span>
           </th> */}
          <th>
            <span>{_("senders")}</span>
          </th>
          <th></th>
        </tr>
        </thead>
        {isLoading || isSaving ? (
          <tbody>
          <tr>
            <td colSpan={7} className={styles.centerCell}>
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#B2BCC6"}
              />
            </td>
          </tr>
          </tbody>
        ) : sortedData.length > 0 ? (
          <>
            <SortableContainer onSortEnd={(e) => appsOnSortEnd(e)} useDragHandle>
              {sortedData.map((item, i) => (
                <SortableItem key={`item-${i}`} index={i} value={item} />
              ))}
            </SortableContainer>
            {isOrderChanged && (
              <tr className={styles.buttonContainer}>
                <td colSpan={6} className={styles.centerCell}>
                  <Button onClick={savePriority}>{_("save_priority")}</Button>
                </td>
              </tr>)}
          </>
        ) : (
          <tbody>
          <tr>
            <td colSpan={6} className={styles.centerCell}>
              Liste vide (aucun délégation type n'a été ajouté)
            </td>
          </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default List;

