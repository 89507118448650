import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import Select from "react-select";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { writeFile, utils } from "xlsx";

import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Loader from "../common/Loader";
import { getHotels, getMembersByHotel, saveHotel } from "../../api";
import { SELECT_STYLES, GOOGLE_MAP_API_KEY } from "../../config";
import _ from "../../i18n";
import styles from "./OeccbbHotels.module.scss";

const distanceOptions = [
  { value: 5, label: "5 km" },
  { value: 10, label: "10 km" },
  { value: 15, label: "15 km" },
  { value: 20, label: "20 km" },
  { value: 25, label: "25 km" },
  { value: 30, label: "30 km" },
  { value: 35, label: "35 km" },
  { value: 40, label: "40 km" },
  { value: 45, label: "45 km" },
  { value: 50, label: "50 km" },
];

const limitOptions = [
  { value: 30, label: 30 },
  { value: 50, label: 50 },
  { value: 100, label: 100 },
  { value: 150, label: 150 },
  { value: 200, label: 200 },
  { value: 250, label: 250 },
  { value: 300, label: 300 },
];

const OeccbbHotels = () => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);

  const [hotelOptions, setHotelOptions] = useState([]);
  const [hotel, setHotel] = useState(null);
  const [distance, setDistance] = useState(distanceOptions[3]);
  const [limit, setLimit] = useState(limitOptions[1]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectAllNoAddress, setSelectAllNoAddress] = useState(false);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [hotelName, setHotelName] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [errors, setErrors] = useState({
    name: "",
    address: "",
    zipcode: "",
    city: "",
  });

  let { isLoading, data } = useQuery(["getHotels", auth.token], async () => {
    if (auth.token) {
      const response = await getHotels({ token: auth.token });

      const hotels = response.data.data.map((hotel) => {
        return {
          value: hotel.id,
          label: hotel.name,
          address: hotel.address + " - " + hotel.zipCode + " - " + hotel.city,
        };
      });
      setHotelOptions(hotels);

      return response.data.data;
    }
  });

  let dataMembers = useQuery(
    ["getMembers", auth.token, hotel, distance, limit],
    async () => {
      if (auth.token && hotel) {
        const response = await getMembersByHotel({
          token: auth.token,
          hotel: hotel.value,
          distance: distance.value,
          limit: limit.value,
        });

        return response.data.data;
      }
    }
  );

  const selectMember = (item) => {
    if (selectedIds.includes(item.user.id)) {
      setSelectedIds([
        ...selectedIds.filter((element) => element !== item.user.id),
      ]);
    } else {
      setSelectedIds([...selectedIds, item.user.id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedIds([]);
    } else {
      if (dataMembers?.data?.result) {
        setSelectedIds(dataMembers.data.result.map((item) => item.user.id));
      }
    }
    setSelectAll(!selectAll);
  };

  const handleSelectAllNoAddress = () => {
    if (selectAllNoAddress) {
      setSelectedIds([]);
    } else {
      if (dataMembers?.data?.other) {
        setSelectedIds(dataMembers.data.other.map((item) => item.user.id));
      }
    }
    setSelectAllNoAddress(!selectAllNoAddress);
  };

  const handleCopy = async () => {
    if (selectedIds.length === 0 || !dataMembers.data) {
      return null;
    }
    const emails = [];
    dataMembers.data?.result.forEach((item) => {
      if (selectedIds.includes(item.user.id)) {
        emails.push(item.user.mainEmail);
      }
    });

    try {
      await navigator.clipboard?.writeText(emails.join(","));
      toast.success("Données copiées");
    } catch (error) {
      console.error("Unable to copy to clipboard:", error);
    }
  };

  const handleExport = () => {
    if (selectedIds.length === 0 || !dataMembers.data) {
      return null;
    }

    const rows = [];
    const columnsWidth = [{ wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }];
    let data = [...dataMembers.data?.result];
    if (dataMembers.data?.other) {
      data = [...data, ...dataMembers.data.other];
    }

    data.forEach((item) => {
      if (selectedIds.includes(item.user.id)) {
        const name = `${item.user.lastName ? item.user.lastName : ""} ${
          item.user.firstName ? item.user.firstName : ""
        }`;
        if (name.length > columnsWidth[0].wch) {
          columnsWidth[0].wch = name.length;
        }
        const row = {
          "Nom / Prénom": name,
          Email: item.user.mainEmail ?? "",
          Téléphone: item.user.mainPhone
            ? formatPhoneNumberIntl(item.user.mainPhone)
            : "",
          Distance: item.distance ? item.distance : "",
        };
        rows.push(row);

        if (row.Email.length > columnsWidth[1].wch) {
          columnsWidth[1].wch = row.Email.length;
        }
        if (row["Téléphone"].length > columnsWidth[2].wch) {
          columnsWidth[2].wch = row["Téléphone"].length;
        }
        if (row.Distance.length > columnsWidth[3].wch) {
          columnsWidth[3].wch = row.Distance.length;
        }
      }
    });

    const worksheet = utils.json_to_sheet(rows);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Dates");

    /* fix headers */
    utils.sheet_add_aoa(
      worksheet,
      [["Nom / Prénom", "Email", "Téléphone", "Distance"]],
      { origin: "A1" }
    );

    /* calculate column width */
    const max_width = rows.reduce(
      (w, r) => Math.max(w, r["Nom / Prénom"].length),
      10
    );
    worksheet["!cols"] = columnsWidth;

    /* create an XLSX file and try to save to Presidents.xlsx */
    writeFile(workbook, "Contacts - " + hotel.label + ".xlsx");
  };

  const handleCancel = () => {
    setHotelName("");
    setAddress("");
    setZipcode("");
    setCity("");
    setLatitude("");
    setLongitude("");
    setErrors({ name: "", address: "", zipcode: "", city: "" });
    setAddModalOpen(false);
  };

  const validate = () => {
    let tabErrors = { ...errors };
    tabErrors.name = hotelName.length === 0 ? _("required_field") : "";
    tabErrors.address = address.length === 0 ? _("required_field") : "";
    tabErrors.zipcode = zipcode.length === 0 ? _("required_field") : "";
    tabErrors.city = city.length === 0 ? _("required_field") : "";

    setErrors(tabErrors);

    return tabErrors.name ||
      tabErrors.address ||
      tabErrors.zipcode ||
      tabErrors.city
      ? true
      : false;
  };

  const handleSave = async () => {
    let error = validate();
    if (error) {
      return null;
    }
    let lat = "",
      lng = "";
    setIsSaving(true);
    try {
      const addressStr = encodeURIComponent(
        hotelName + " " + address + " " + zipcode + " " + city + " Belgique"
      );
      let response = await fetch(
        "https://maps.google.com/maps/api/geocode/json?address=" +
          addressStr +
          "&sensor=false&key=" +
          GOOGLE_MAP_API_KEY
      );
      let responseJson = await response.json();
      if (responseJson.status == "OK") {
        lat = responseJson.results[0].geometry.location.lat;
        lng = responseJson.results[0].geometry.location.lng;
        setLatitude(lat);
        setLongitude(lng);
      }
    } catch (error) {
      console.log(error);

      toast.error(
        "Veuillez vérifier l'adresse, nous n'avons pas pu la geocoder"
      );
      setIsSaving(false);
      return null;
    }

    const data = {
      token: auth.token,
      name: hotelName,
      address,
      zipcode,
      city,
      latitude: lat,
      longitude: lng,
    };

    saveHotel(data)
      .then((resp) => {
        queryClient.invalidateQueries("getHotels");
        setTimeout(() => {
          toast.success(_("successfully_added"));
          handleCancel();
        }, 1000);
      })
      .catch((e) => {})
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <div className="grid-container">
      {isLoading && <div className="lmask"></div>}
      <div className={styles.content}>
        <h1 className={styles.title}>
          <span>Liste des hôtels :</span>

          <div className={styles.top_actions}>
            <Button
              onClick={handleCopy}
              variant="default"
              size="sm"
              disabled={selectedIds.length === 0}
            >
              <i className="icon-ttp-copy" /> Copier
            </Button>
            <Button
              onClick={handleExport}
              variant="default"
              size="sm"
              disabled={selectedIds.length === 0}
            >
              <i className="icon-ttp-download" /> Exporter
            </Button>
            <Button
              onClick={() => setAddModalOpen(true)}
              variant="primary"
              size="sm"
            >
              <i className="icon-ttp-plus-outline" /> Ajouter un hôtel
            </Button>
          </div>
        </h1>

        {!isLoading && data && (
          <>
            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-12 large-6">
                <div className="ttp-form-group-h">
                  <label className="ttp-label">Hôtel</label>
                  <Select
                    styles={SELECT_STYLES}
                    isSearchable={false}
                    options={hotelOptions}
                    getOptionLabel={(options) => (
                      <div>
                        <span>{options.label}</span>
                        <br />
                        <small>{options.address}</small>
                      </div>
                    )}
                    value={hotel}
                    onChange={(e) => setHotel(e)}
                  />
                </div>
              </div>
              <div className="cell small-12 medium-6 large-3">
                <div className="ttp-form-group-h">
                  <label className="ttp-label">Distance</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={distanceOptions}
                    isSearchable={false}
                    value={distance}
                    onChange={(e) => setDistance(e)}
                  />
                </div>
              </div>
              <div className="cell small-12 medium-6 large-3">
                <div className="ttp-form-group-h">
                  <label className="ttp-label">Limit</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={limitOptions}
                    isSearchable={false}
                    value={limit}
                    onChange={(e) => setLimit(e)}
                  />
                </div>
              </div>
            </div>

            <div className={styles.tableContainer}>
              <table className={`${styles.table} unstriped`}>
                <thead>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        checked={selectAll}
                        onClick={() => handleSelectAll()}
                      />
                    </th>
                    <th width="30%" className={styles.nowrap}>
                      {_("lastname") + " / " + _("firstname")}
                    </th>
                    <th width="40%">Email</th>
                    <th width="25%">Téléphone</th>
                    <th width="25%">Distance</th>
                  </tr>
                </thead>
                <tbody>
                  {dataMembers.isLoading ? (
                    <tr>
                      <td colSpan={5} className={styles.centerCell}>
                        <Loader
                          style={{
                            height: "10px",
                          }}
                          color={"#B2BCC6"}
                        />
                      </td>
                    </tr>
                  ) : dataMembers.data && dataMembers.data.result.length > 0 ? (
                    dataMembers.data.result.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedIds.includes(item.user.id)}
                            onClick={() => selectMember(item)}
                          />
                        </td>
                        <td>
                          {`${item.user.lastName ? item.user.lastName : ""} ${
                            item.user.firstName ? item.user.firstName : ""
                          }`}
                        </td>
                        <td>{item.user.mainEmail}</td>
                        <td className={styles.nowrap}>
                          {item.user.mainPhone
                            ? formatPhoneNumberIntl(item.user.mainPhone)
                            : "-"}
                        </td>
                        <td>{item.distance}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5} className={styles.centerCell}>
                        Liste vide (aucun contact trouvé)
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {!dataMembers.isLoading &&
              dataMembers.data &&
              dataMembers.data?.other?.length > 0 && (
                <>
                  <h4 style={{ marginTop: "1rem" }}>
                    Liste des membres sans adresse:
                  </h4>
                  <div className={styles.tableContainer}>
                    <table className={`${styles.table} unstriped`}>
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              checked={selectAllNoAddress}
                              onClick={() => handleSelectAllNoAddress()}
                            />
                          </th>
                          <th width="30%" className={styles.nowrap}>
                            {_("lastname") + " / " + _("firstname")}
                          </th>
                          <th width="40%">Email</th>
                          <th width="25%">Téléphone</th>
                          <th width="25%">Distance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataMembers.data.other.map((item) => (
                          <tr key={item.id}>
                            <td>
                              <input
                                type="checkbox"
                                checked={selectedIds.includes(item.user.id)}
                                onClick={() => selectMember(item)}
                              />
                            </td>
                            <td>
                              {`${
                                item.user.lastName ? item.user.lastName : ""
                              } ${
                                item.user.firstName ? item.user.firstName : ""
                              }`}
                            </td>
                            <td>{item.user.mainEmail}</td>
                            <td className={styles.nowrap}>
                              {item.user.mainPhone
                                ? formatPhoneNumberIntl(item.user.mainPhone)
                                : "-"}
                            </td>
                            <td>{item.distance}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              )}
          </>
        )}
      </div>

      <Modal
        isOpen={addModalOpen}
        // onRequestClose={() => resetData()}
        className={{
          base: styles.modalContent,
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modal}>
          <div className={styles.header}>Ajout d'un hôtel</div>
          <div className={styles.close} onClick={() => setAddModalOpen(false)}>
            <i className="icon-ttp-close"></i>
          </div>

          <div className={styles.body}>
            <FormInput
              name="name"
              required={true}
              label="Nom de l'hôtel"
              autocomplete="off"
              error={errors.name}
              value={hotelName}
              onChange={(e) => setHotelName(e.target.value)}
            />

            <FormInput
              name="address"
              required={true}
              label="Adresse"
              autocomplete="off"
              error={errors.address}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />

            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6">
                <FormInput
                  name="zipcode"
                  required={true}
                  label="Code postal"
                  autocomplete="off"
                  error={errors.zipcode}
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                />
              </div>

              <div className="cell small-12 medium-6">
                <FormInput
                  name="city"
                  required={true}
                  label="Ville"
                  autocomplete="off"
                  error={errors.city}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>

            <div className="grid-x grid-margin-x">
              <div className="cell small-12 medium-6">
                <FormInput
                  name="latitude"
                  label="Latitude"
                  autocomplete="off"
                  value={latitude}
                  disabled
                />
              </div>

              <div className="cell small-12 medium-6">
                <FormInput
                  name="longitude"
                  label="Longitude"
                  autocomplete="off"
                  value={longitude}
                  disabled
                />
              </div>
            </div>
          </div>

          <div className={styles.actions}>
            <Button variant="default" onClick={handleCancel}>
              {_("cancel")}
            </Button>

            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button onClick={handleSave}>{_("save")}</Button>
            )}
          </div>
        </div>
      </Modal>

      {/* <Modal
        width="50vw"
        height="50vh"
        closable={false}
        visible={addModalOpen}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation();
          setAddModalOpen(false);
          // closeFieldsModal();
        }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.modal_header}>{_("merge_modal_title")}</div>
        <div
          className={styles.modal_close}
          onClick={(e) => {
            e.stopPropagation();
            setAddModalOpen(false);
            // closeFieldsModal();
          }}
        >
          <i className="icon-ttp-close"></i>
        </div>

        <div className={styles.modal_body}>dfsdf</div>
      </Modal> */}
    </div>
  );
};

export default OeccbbHotels;
