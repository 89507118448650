import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { AppCard } from "../../";
import _ from "../../../i18n";
import WatchSharing from "./WatchSharing";
import BlogModal from "./BlogModal";
import WebtoolsModal from "./WebtoolsModal";
import FaqModal from "./FaqModal";
// import styles from "./InstalledApp.module.scss";

const apps = [
  {
    index: "offfcourse",
    name: "oFFFcourse",
    icon: "offfcourse",
    desc: _("offfcourse_app_txt"),
    second_desc: _("offfcourse_app_txt2"),
    details: [
      {
        title: _("app_offfcourse_details_1_title"),
        list: [
          _("app_offfcourse_details_1_detail1"),
          _("app_offfcourse_details_1_detail2"),
        ],
      },
    ],
    tools: [],
  },
  {
    index: "blog",
    name: "Blog",
    desc: _("app_blog_desc"),
    details: [
      {
        list: [
          _("app_blog_details_1_subTitle"),
          _("app_blog_details_2_subTitle"),
        ],
      },
    ],
    tools: [],
  },
  {
    index: "newsletter",
    name: "E-news",
    icon: "sending", // to modify
    desc: _("newsletter_app_txt"),
    details: [
      {
        title: _("app_newsletter_details_1_title"),
        list: [_("details_pack_lite"), _("details_pack_perso")],
      },
    ],
    tools: [],
  },
];

const InstalledApp = () => {
  const [userRightModalOpen, setUserRightModalOpen] = useState(false);
  const [blogRightModalOpen, setBlogRightModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState({
    offfcourse: false,
    blog: false,
    webtools: false,
    faq: false,
  });
  const [currentApp, setCurrentApp] = useState("");
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  // const apps = [
  //   {
  //     index: "offfcourse",
  //     name: "oFFFcourse",
  //     icon: "offfcourse",
  //     desc: _("offfcourse_app_txt"),
  //     second_desc: _("offfcourse_app_txt2"),
  //     details: [
  //       {
  //         title: _("app_offfcourse_details_1_title"),
  //         list: [
  //           _("app_offfcourse_details_1_detail1"),
  //           _("app_offfcourse_details_1_detail2"),
  //         ],
  //       },
  //     ],
  //     tools: [],
  //   },
  //   {
  //     index: "blog",
  //     name: "Blog",
  //     desc: _("app_blog_desc"),
  //     details: [
  //       {
  //         list: [
  //           _("app_blog_details_1_subTitle"),
  //           _("app_blog_details_2_subTitle"),
  //         ],
  //       },
  //     ],
  //     tools: [
  //       // {
  //       //   icon: "edit",
  //       //   title: _("app_blog_tools_1_title"),
  //       //   desc: _("app_blog_tools_1_desc"),
  //       //   // tag: _("offered_by_ua"),
  //       //   app: "BLOG_CREATE",
  //       // },
  //       // {
  //       //   icon: "link",
  //       //   title: _("app_blog_tools_2_title"),
  //       //   desc: _("app_blog_tools_2_desc"),
  //       //   tag: _("offered_by_ua"),
  //       //   app: "BLOG_TAMTAMIT_CREATE",
  //       // },
  //       // {
  //       //   icon: "send",
  //       //   title: _("app_blog_tools_3_title"),
  //       //   desc: _("app_blog_tools_3_desc"),
  //       //   tag: _("version_beta"),
  //       //   dispo: `${_("until")} 31/12/22`,
  //       //   app: "BLOG_NL_CREATE",
  //       // },
  //     ],
  //   },
  //   {
  //     index: "newsletter",
  //     name: "E-news",
  //     icon: "sending", // to modify
  //     desc: _("newsletter_app_txt"),
  //     details: [
  //       {
  //         title: _("app_newsletter_details_1_title"),
  //         list: [
  //           _("details_pack_lite"),
  //           _("details_pack_perso"),
  //           //_("app_newsletter_details_1_detail1"),
  //           //_("app_newsletter_details_1_detail2"),
  //           //_("app_newsletter_details_1_detail3"),
  //         ],
  //       },
  //     ],
  //     tools: [],
  //   },
  //   /*{
  //     index: "faq",
  //     name: "FAQ",
  //     desc: _("app_faq_desc"),
  //     details: [
  //       {
  //         title: _("app_faq_details_1_title"),
  //         subTitle: _("app_faq_details_1_subTitle"),
  //       },
  //       {
  //         title: _("app_faq_details_2_title"),
  //         subTitle: _("app_faq_details_2_subTitle"),
  //       },
  //     ],
  //     tools: [
  //       // {
  //       //   icon: "edit",
  //       //   title: _("app_faq_tools_1_title"),
  //       //   desc: _("app_faq_tools_1_desc"),
  //       //   tag: _("offered_by_ua"),
  //       //   app: "FAQ_CREATE",
  //       // },
  //       // {
  //       //   icon: "book-open",
  //       //   title: _("app_faq_tools_2_title"),
  //       //   desc: _("app_faq_tools_2_desc"),
  //       //   tag: _("offered_by_ua"),
  //       //   app: "FAQ_HELP_CENTER",
  //       // },
  //     ],
  //   },*/
  //   /*{
  //     index: "webinar",
  //     name: "Webinar",
  //     desc: _("app_webinar_desc"),
  //     details: [
  //       {
  //         title: _("app_webinar_details_1_title"),
  //       },
  //       {
  //         title: _("app_webinar_details_2_title"),
  //         subTitle: _("app_webinar_details_2_subTitle"),
  //       },
  //     ],
  //     tools: [
  //       {
  //         icon: "bookmark",
  //         title: _("app_webinar_tools_1_title"),
  //         desc: _("app_webinar_tools_1_desc"),
  //         tag: _("offered_by_ua"),
  //         app: "WEBINAR_SHARE_COLLABORATOR",
  //       },
  //       {
  //         icon: "users",
  //         title: _("app_webinar_tools_2_title"),
  //         desc: _("app_webinar_tools_2_desc"),
  //         tag: _("offered_by_ua"),
  //         app: "WEBINAR_SHARE_ALL",
  //       },
  //       {
  //         icon: "mic",
  //         title: _("app_webinar_tools_3_title"),
  //         desc: _("app_webinar_tools_3_desc"),
  //         tag: _("version_beta"),
  //         app: "WEBINAR_CREATE",
  //       },
  //       {
  //         icon: "briefcase",
  //         title: _("app_webinar_tools_4_title"),
  //         desc: _("app_webinar_tools_4_desc"),
  //         tag: _("version_beta"),
  //         app: "WEBINAR_SHARE_CLIENT",
  //       },
  //     ],
  //   },*/
  //   /*{
  //     index: "webtools",
  //     name: "Webtools",
  //     desc: _("app_webtools_desc"),
  //     details: [
  //       {
  //         title: _("app_webtools_details_1_title"),
  //         subTitle: _("app_webtools_details_1_subTitle"),
  //       },
  //       {
  //         title: _("app_webtools_details_2_title"),
  //         subTitle: _("app_webtools_details_2_subTitle"),
  //       },
  //     ],
  //     tools: [
  //       {
  //         icon: "edit",
  //         title: _("app_webtools_tools_1_title"),
  //         desc: _("app_webtools_tools_1_desc"),
  //         tag: _("offered_by_ua"),
  //         app: "WEBTOOLS_CREATE",
  //       },
  //       {
  //         icon: "disc",
  //         title: _("app_webtools_tools_2_title"),
  //         desc: _("app_webtools_tools_2_desc"),
  //         tag: _("offered_by_ua"),
  //         app: "WEBTOOLS_TOOL_CENTER",
  //       },
  //     ],
  //   },
  //   {
  //     index: "event",
  //     name: "Event",
  //     desc: _("app_event_desc"),
  //     details: [
  //       {
  //         title: _("app_event_details_1_title"),
  //       },
  //       {
  //         title: _("app_event_details_2_title"),
  //         subTitle: _("app_event_details_2_subTitle"),
  //       },
  //     ],
  //     tools: [
  //       {
  //         icon: "calendar",
  //         title: _("app_event_tools_1_title"),
  //         desc: _("app_event_tools_1_desc"),
  //         tag: _("offered_by_ua"),
  //         app: "EVENT_CREATE_FREE",
  //       },
  //       {
  //         icon: "shopping-cart",
  //         title: _("app_event_tools_2_title"),
  //         desc: _("app_event_tools_2_desc"),
  //         tag: _("offered_by_ua"),
  //         app: "EVENT_CREATE_PAID",
  //       },
  //     ],
  //   },*/
  //   /*{
  //     index: "dashboard",
  //     name: "Dashboard",
  //     desc: _("app_accounting_desc"),
  //     details: [
  //       {
  //         title: _("app_accounting_details_1_title"),
  //         subTitle: _("app_accounting_details_1_subTitle"),
  //       },
  //       {
  //         title: _("app_accounting_details_2_title"),
  //         subTitle: _("app_accounting_details_2_subTitle"),
  //       },
  //     ],
  //     tools: [
  //       // {
  //       //   icon: "trello",
  //       //   title: _("app_accounting_tools_1_title"),
  //       //   desc: _("app_accounting_tools_1_desc"),
  //       //   tag: _("offered_by_ua"),
  //       //   app: "ACCOUNTING_DASHBOARD_EXTENSION",
  //       // },
  //       // {
  //       //   icon: "home",
  //       //   title: _("app_accounting_tools_2_title"),
  //       //   desc: _("app_accounting_tools_2_desc"),
  //       //   tag: _("offered_by_ua"),
  //       //   app: "ACCOUNTING_SHARE_DASHBOARD",
  //       // },
  //       // {
  //       //   icon: "layers",
  //       //   title: _("app_accounting_tools_3_title"),
  //       //   desc: _("app_accounting_tools_3_desc"),
  //       //   tag: _("version_beta"),
  //       //   app: "ACCOUNTING_GENERATE_DASHBOARD",
  //       // },
  //     ],
  //   },*/
  // ];

  useEffect(() => {
    if (fiduciary?.uaSettings?.allowedApps.includes("WEBTOOLS")) {
      const tmp = apps.filter((i) => i.index === "webtools");
      if (tmp.length === 0) {
        apps.push({
          index: "webtools",
          name: "Webtools",
          desc: _("app_webtools_desc"),
          details: [
            {
              title: _("app_webtools_details_1_title"),
              list: [
                _("app_webtools_details_1_subTitle"),
                _("app_webtools_details_2_subTitle"),
              ],
            },
          ],
          tools: [],
        });
      }
    }
    if (fiduciary?.uaSettings?.allowedApps.includes("FAQ")) {
      const tmp = apps.filter((i) => i.index === "faq");
      if (tmp.length === 0) {
        apps.push({
          index: "faq",
          name: "FAQ",
          desc: _("app_faq_desc"),
          details: [
            {
              title: _("app_faq_details_1_title"),
              list: [
                _("app_faq_details_1_subTitle"),
                _("app_faq_details_2_subTitle"),
              ],
            },
            {
              title: _("app_faq_tools_1_title"),
              list: [_("app_faq_tools_1_desc")],
            },
            {
              title: _("app_faq_tools_2_title"),
              list: [_("app_faq_tools_2_desc")],
            },
          ],
          tools: [],
        });
      }
    }
  }, [fiduciary]);

  const handleOpenModal = (appName, value) => {
    setIsModalOpen({ ...isModalOpen, [appName]: value });
  };
  return (
    <>
      <div className="grid-x grid-margin-x grid-margin-y">
        {apps.map((app, index) => (
          <div className="cell small-12 medium-6" key={app.index}>
            <AppCard
              key={`installed-${index}`}
              app={app}
              type="installed"
              setUserRightModalOpen={
                app.index === "blog"
                  ? setBlogRightModalOpen
                  : setUserRightModalOpen
              }
              openModal={(e) => handleOpenModal(e, true)}
              setCurrentApp={setCurrentApp}
            />
          </div>
        ))}
      </div>
      <WatchSharing
        showModal={isModalOpen.offfcourse}
        setShowModal={(e) => handleOpenModal("offfcourse", e)}
      />
      <BlogModal
        showModal={isModalOpen.blog}
        setShowModal={(e) => handleOpenModal("blog", e)}
      />
      <WebtoolsModal
        showModal={isModalOpen.webtools}
        setShowModal={(e) => handleOpenModal("webtools", e)}
      />
      <FaqModal
        showModal={isModalOpen.faq}
        setShowModal={(e) => handleOpenModal("faq", e)}
      />
    </>
  );
};

export default InstalledApp;
