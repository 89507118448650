import React from 'react';

import _ from "../../../i18n";
import styles from "./MoreButton.module.scss";

const MoreButton = ({handleClick}) => {
    return <div className={styles.btn}>
        <div>
            <button onClick={handleClick}>
                {_('show-more-articles')}  
                <i className="icon-ttp-chevron-down"></i>
            </button>
        </div>
    </div>
}

export default MoreButton;