import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import IconBackArrow from "../../common/icons/IconBackArrow";

import { formatDecimalHours, getDateLabel } from "../../../utils";
import { getUserWatchStats } from "../../../api";
import _ from "../../../i18n";

import styles from "./WatchSharingStats.module.scss";

const WatchSharingStats = ({ step, setStep, currentUser }) => {
  const auth = useSelector((state) => state.auth);
  const [totalHours, setTotalHours] = useState(0);
  const [totalTraining, setTotalTraining] = useState(0);
  const { token } = auth;

  let { isLoading, data } = useQuery(
    ["getUserData", token, step],
    async () => {
      if (token && step === "STATS") {
        try {
          let result = [];
          const response = await getUserWatchStats({
            token,
            userId: currentUser.id,
          });

          if (response.data?.data && response.data?.data.length > 0) {
            let totalProgress = 0,
              count = 0;
            let tab = {};
            response.data.data.forEach((row) => {
              if (row.lastViewingDate) {
                totalProgress += row.playProgress;
                count++;
                const year = new Date(row.lastViewingDate).getFullYear();
                if (!tab[year]) {
                  tab[year] = [];
                }
                tab[year].push(row);
              }
            });
            for (const key in tab) {
              let totalViewsPerYear = 0;
              tab[key].forEach((a) => {
                totalViewsPerYear += a.playProgress;
              });
              tab[key].sort((a, b) =>
                a.lastViewingDate < b.lastViewingDate ? 1 : -1
              );
              result.push({
                year: key,
                totalViews: totalViewsPerYear,
                data: tab[key],
              });
            }
            if (result.length > 1) {
              result.sort((a, b) => (a.year < b.year ? 1 : -1));
            }
            setTotalHours(totalProgress);
            setTotalTraining(count);
          }

          return result;
        } catch (e) {
          return [];
        }
      }
    },
    { cacheTime: 0 }
  );

  const renderData = () => {
    return (
      <div>
        <div className={styles.top}>
          <div>
            Nombre de formations visionnées :{" "}
            <span className={styles.top_value}>{totalTraining}</span>
          </div>
          <div className={styles.top_hours}>
            Total d'heures visionnées :{" "}
            <span className={styles.top_value}>
              {formatDecimalHours(totalHours / 3600)}
            </span>
          </div>
        </div>

        {data.map((tabYear, idxYear) => (
          <table className={styles.bloc}>
            {idxYear === 0 && (
              <tr>
                <th></th>
                <th width="50%">Formation</th>
                <th width="25%">Dernière date de visionnage</th>
                <th width="25%">Durée de visionnage</th>
              </tr>
            )}

            {tabYear.data.map((row, index) => {
              const percentage = Math.round(
                (row.playProgress / row.duration) * 100
              );
              const color = percentage === 100 ? "#06D9B1" : "#EA8124";
              return (
                <tr>
                  {index === 0 && (
                    <td className={styles.year} rowspan={tabYear.data.length}>
                      <p>{tabYear.year}</p>
                      <p className={styles.year_hours}>
                        <span className={styles.year_label}>
                          Total d'heures visionnées:
                        </span>
                        {formatDecimalHours(tabYear.totalViews / 3600)}
                      </p>
                    </td>
                  )}
                  <td width="50%">{row.name}</td>
                  <td width="25%" className={styles.date}>
                    <span>
                      {getDateLabel(row.lastViewingDate).split(",")[0]}
                    </span>
                    <span>
                      {getDateLabel(row.lastViewingDate).split(",")[1]}
                    </span>
                  </td>
                  <td width="25%">
                    <div className={styles.duration}>
                      <div style={{ width: 44, height: 44 }}>
                        <CircularProgressbar
                          value={percentage}
                          text={`${percentage}%`}
                          strokeWidth={5}
                          styles={{
                            path: {
                              // Trail color
                              stroke: color,
                            },
                            text: {
                              fill: color,
                              fontWeight: "500",
                              fontSize: "26px",
                            },
                          }}
                        />
                      </div>

                      <p className={styles.duration_value}>
                        <span>
                          {formatDecimalHours(row.playProgress / 3600)}
                        </span>
                        <span>
                          {_("over") +
                            " " +
                            formatDecimalHours(row.duration / 3600)}
                        </span>
                      </p>
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.modal_content2}>
      <div className={styles.backNav}>
        <button onClick={() => setStep("INITIAL")}>
          <IconBackArrow /> {_("back")}
        </button>
        <span className={styles.backNav_title}>
          {_("performance_of") + " : "}
          <span>{currentUser.firstName + " " + currentUser.lastName}</span>
        </span>
      </div>

      <div className={styles.content}>
        {isLoading ? (
          <div style={{ marginTop: "2rem" }}>
            <Skeleton count={4} height={50} />
          </div>
        ) : data && data.length > 0 ? (
          renderData()
        ) : (
          <p className={styles.no_data}>{_("no_data_found")}</p>
        )}
      </div>
    </div>
  );
};

export default WatchSharingStats;
