import axios from "axios";

import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
  throwCatchedError,
} from "../utils";

import { TTP_API_URL, ARTICLE_LIMIT } from "../config";

let getArticlesCTS;

export const getArticles = (
  token,
  filters,
  scope = "PUBLIC,COLLABORATOR",
  options,
  cancellationTokenSource = null
) => {
  let requestCancellationToken = getRequestCancellationToken(
    getArticlesCTS,
    cancellationTokenSource
  );

  options = options || {};
  filters = filters || {};

  const limit = options.limit || ARTICLE_LIMIT;
  const page = options.page || 1;

  const fields = [
    "*",
    "url",
    "introduction",
    "author",
    "main_media",
    "category",
    "tags",
    "image_reduced",
    "organization",
    "avatars",
    "pages",
    "theme",
    "type",
    "social",
  ];
  const requestUrl = `${TTP_API_URL}/blog/article`;

  const filterData = [
    {
      property: "isExternal",
      value: 0,
      operator: "eq",
    },
    {
      property: "status",
      value: "PUBLISHED",
      operator: "eq",
    },
    {
      property: "csScope",
      value: scope,
      operator: "in",
    },
    {
      property: "organization.id",
      value: "9",
      operator: "eq",
    },
  ];

  if (filters.search) {
    filterData.push({
      property: "word",
      value: encodeURI(filters.search),
      operator: "like",
    });
  }

  if (filters.languages) {
    filterData.push({
      property: "language",
      value: filters.languages,
      operator: "in",
    });
  } else {
    filterData.push({
      property: "language",
      value: "fr",
      operator: "in",
    });
  }

  if (filters.categories) {
    filterData.push({
      property: "category.id",
      value: filters.categories,
      operator: "in",
    });
  }

  const sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];

  let params = {
    access_token: token,
    filter: JSON.stringify(filterData),
    sort: JSON.stringify(sort),
    fields: fields.join(","),
    limit: limit,
    start: options.start ? options.start : (page - 1) * limit,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getCategories = ({
  token,
  language = "fr",
  customFilter = null,
}) => {
  const requestUrl = `${TTP_API_URL}/blog/category`;

  let filter = [];

  filter.push({
    property: "language",
    value: language,
    operator: Array.isArray(language) ? "in" : "like",
  });

  if (customFilter !== null) {
    if (Array.isArray(customFilter)) {
      filter.push(...customFilter);
    } else {
      filter.push(customFilter);
    }
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
    },
  });
};

export const getChains = ({ token, language = "fr" }) => {
  const requestUrl = `${TTP_API_URL}/blog/avatar`;
  const fields = ["*", "mediaChain"];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: fields.join(","),
    },
  });
};

export const getTops = ({ token, language = "fr" }) => {
  const requestUrl = `${TTP_API_URL}/blog/article/organization/fff/top`;
  const filters = [
    {
      property: "language",
      value: language,
      operator: "eq",
    },
  ];
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
    },
  });
};

export const getNewsLetterArticles = ({
  token,
  communityId,
  month,
  year,
  currentDate,
  period,
}) => {
  const requestUrl = `${TTP_API_URL}/blog/newsletter-articles-list`;

  let filters = [];
  if (communityId) {
    filters.push({
      property: "organization",
      value: communityId,
      operator: "eq",
    });
  }

  if (month) {
    filters.push({
      property: "month",
      value: month,
      operator: "eq",
    });
  }

  if (year) {
    filters.push({
      property: "year",
      value: year,
      operator: "eq",
    });
  }

  if (currentDate) {
    filters.push({
      property: "endDate",
      value: new Date(),
      operator: "gt",
    });

    filters.push({
      property: "startDate",
      value: new Date(),
      operator: "lt",
    });
  }

  if (period) {
    filters.push({
      property: "period",
      value: period,
      operator: "eq",
    });
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filters),
    },
  });
};

export const getPublicArticlesPortal = ({
  token,
  limit,
  language = "fr",
  communityId,
  notIds,
}) => {
  const filter = [
    {
      property: "status",
      value: "PUBLISHED",
      operator: "like",
    },
    {
      property: "language",
      value: language,
      operator: "like",
    },
    {
      property: "isExternal",
      value: 0,
      operator: "eq",
    },
  ];

  if (communityId) {
    filter.push({
      property: "organization.id",
      value: communityId,
      operator: "eq",
    });
  }
  if (notIds) {
    filter.push({
      property: "id",
      value: notIds,
      operator: "nin",
    });
  }

  const sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "url",
    "author",
    "introduction",
    "main_media",
    "tags",
    "category",
    "image_reduced",
    "organization",
    "avatars",
    "albums",
    "content",
    "contentState",
  ];

  const requestUrl = `${TTP_API_URL}/blog/article`;
  const params = {
    access_token: token,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    fields: fields.join(","),
    limit,
    start: 0,
  };

  return axios
    .get(requestUrl, {
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getBlogOrgArticles = ({
  token,
  communityId,
  date,
  language,
  limit,
}) => {
  const requestUrl = `${TTP_API_URL}/blog/article`;
  let sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];

  let filter = [
    { property: "oFFFcourse", value: null, operator: "null" },
    { property: "language", value: language, operator: "eq" },
    { property: "organization.id", value: communityId, operator: "eq" },
    { property: "publishedAt", value: date, operator: "gte" },
  ];

  const fields = [
    {
      entity: "article",
      fields: [
        "id",
        "language",
        "isPrivate",
        "isExternal",
        "externalUrl",
        "csScope",
        "readTime",
        "relevance",
        "featured",
        "countLikes",
        "countDislikes",
        "countComments",
        "title",
        "url",
        "fffLibrary",
        "relatedArticles",
      ],
    },
    {
      entity: "main_media",
      fields: ["id", "yPos", "yHeight", "webPath", "path"],
    },
    {
      entity: "author",
      fields: {
        author: ["id", "enableAvatar"],
        user: ["id", "firstName", "lastName", "url", "avatar", "isStrict"],
      },
    },
    { entity: "type", fields: ["id", "nameFr"] },
    { entity: "chains", fields: ["id", "nameFr", "mediaChain"] },
    { entity: "category", fields: ["id", "nameFr", "colorCode"] },
    {
      entity: "organization",
      fields: ["id", "name", "url", "abbreviation", "avatarWebPath"],
    },
    {
      entity: "organizationSource",
      fields: ["id", "name", "url", "abbreviation", "avatarWebPath"],
    },
    { entity: "social", fields: [] },
  ];

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    fields: JSON.stringify(fields),
    start: 0,
    limit,
    isCustomFields: 1,
    workspace: "fiduciary",
  };

  return axios.get(requestUrl, {
    params,
  });
};

export const getBlogFFFArticles = ({ token, communityId, language, limit }) => {
  const requestUrl = `${TTP_API_URL}/blog/article`;
  let sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];

  let filter = [
    { property: "oFFFcourse", value: null, operator: "null" },
    { property: "language", value: language, operator: "eq" },
    { property: "organization.id", value: 9, operator: "eq" },
    { property: "organizationSource", value: communityId, operator: "neq" },
  ];

  const fields = [
    {
      entity: "article",
      fields: [
        "id",
        "language",
        "isPrivate",
        "isExternal",
        "externalUrl",
        "csScope",
        "readTime",
        "relevance",
        "featured",
        "countLikes",
        "countDislikes",
        "countComments",
        "title",
        "url",
        "fffLibrary",
        "relatedArticles",
      ],
    },
    {
      entity: "main_media",
      fields: ["id", "yPos", "yHeight", "webPath", "path"],
    },
    {
      entity: "author",
      fields: {
        author: ["id", "enableAvatar"],
        user: ["id", "firstName", "lastName", "url", "avatar", "isStrict"],
      },
    },
    { entity: "type", fields: ["id", "nameFr"] },
    { entity: "chains", fields: ["id", "nameFr", "mediaChain"] },
    { entity: "category", fields: ["id", "nameFr", "colorCode"] },
    {
      entity: "organization",
      fields: ["id", "name", "url", "abbreviation", "avatarWebPath"],
    },
    {
      entity: "organizationSource",
      fields: ["id", "name", "url", "abbreviation", "avatarWebPath"],
    },
    { entity: "social", fields: [] },
  ];

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    sort: JSON.stringify(sort),
    fields: JSON.stringify(fields),
    start: 0,
    limit,
    isCustomFields: 1,
    workspace: "fff",
  };

  return axios.get(requestUrl, {
    params,
  });
};
