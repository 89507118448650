import axios from "axios";

import { TTP_API_URL } from "../config";

export const getOrder = (token, orderId) => {
  const filter = [{ property: "id", value: orderId, operator: "eq" }];
  const fields = ["*", "invoice"];

  const requestUrl = `${TTP_API_URL}/billing/order`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
    },
  });
};

export const getOrderDetails = (token, orderId) => {
  const filter = [
    { property: "documentId", value: orderId, operator: "eq" },
    { property: "documentType", value: "ORDER", operator: "eq" },
  ];

  const fields = ["*"];

  const requestUrl = `${TTP_API_URL}/billing/document_lines`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
    },
  });
};

export const displayDocument = (token, documentId, type) => {
  const requestUrl =
    TTP_API_URL +
    `/billing/order/documentDisplay/` +
    type.toLowerCase() +
    `/` +
    documentId;

  window.open(requestUrl + `?access_token=` + token, "_blank");
};
