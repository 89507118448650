import React from "react";
import { useSelector } from "react-redux";
import AsyncCreatableSelect from "react-select/async-creatable";
import { getContacts } from "../../../../api";

export default function ContactSelect({
  isMulti,
  selectedCollaborator,
  communityId,
  onChange,
}) {
  const token = useSelector((state) => state.auth.token);

  const fetchCollaborators = (inputValue) => {
    return getContacts({
      token,
      communityId,
      search: inputValue,
      fields: "*",
      // sort: [{ property: "lastName", dir: "asc" }],
    }).then((result) => {
      return result.data.data.map((contact) => ({
        value: contact.id,
        label: `${contact.firstName} ${contact.lastName}`,
      }));
    });
  };

  return (
    <div>
      <AsyncCreatableSelect
        isMulti={isMulti}
        cacheOptions
        value={selectedCollaborator}
        onChange={onChange}
        loadOptions={fetchCollaborators}
        createOptionPosition="first"
      />
    </div>
  );
}
