import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import SortableService from "./SortableService";
import styles from "./Service.module.scss";
import classNames from "classnames";

export default SortableContainer(({ services, onDelete, onEdit, onHide }) => {
  let servicesJsx = services.map((service, index) => {
    return (
      <SortableService
        service={service}
        key={service.id}
        index={index}
        onDelete={onDelete}
        onEdit={onEdit}
        onHide={onHide}
      />
    );
  });

  return <div className={classNames(styles.list, "grid-x")}>{servicesJsx}</div>;
});
