import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { isValidPhoneNumber } from "react-phone-number-input";

import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import FormInput from "../../../common/FormInput";
import _ from "../../../../i18n";
import { getUserByEmail, getFolderByUen, saveContact } from "../../../../api";
import {
  DATA_BE_ID,
  DATA_BE_KEY,
  GENDER_OPTIONS,
  LANGUAGES,
  SELECT_STYLES,
} from "../../../../config";
import { validateEmail, validateUen } from "../../../../utils";
import styles from "./Modal.module.scss";
import axios from "axios";

const ContactForm = (props) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [email, setEmail] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState(GENDER_OPTIONS[0]);
  const [language, setLanguage] = useState(LANGUAGES[0]);
  const [uen, setUen] = useState("");
  const [uName, setUname] = useState("");
  const [fonction, setFonction] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);

  const [disableFields, setDisableFields] = useState(false);
  const [disableFieldsUen, setDisableFieldsUen] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [fetchedFolder, setFetchedFolder] = useState(null);
  const [oldFetchedFolder, setOldFetchedFolder] = useState(null);

  const [errors, setErrors] = useState({
    email: "",
    lastname: "",
    firstname: "",
    phone: "",
    uen: "",
  });

  useEffect(() => {
    if (props.currentItem) {
      if (props.currentItem.mainEmail) setEmail(props.currentItem.mainEmail);
      if (props.currentItem.firstName)
        setFirstname(props.currentItem.firstName);
      if (props.currentItem.lastName) setLastname(props.currentItem.lastName);
      if (props.currentItem.language)
        setLanguage(
          LANGUAGES.filter((e) => e.value === props.currentItem.language)[0]
        );
      if (props.currentItem.mainPhone) setPhone(props.currentItem.mainPhone);
      if (props.currentItem.gender)
        setGender(
          GENDER_OPTIONS.filter((e) => e.value === props.currentItem.gender)[0]
        );
      if (props.currentItem.contactClient.details) {
        if (props.currentItem.contactClient.details.uen) {
          setUen(props.currentItem.contactClient.details.uen);

          handleUenBlur(props.currentItem.contactClient.details.uen);
        }
        if (props.currentItem.contactClient.details.name)
          setUname(props.currentItem.contactClient.details.name);
        if (props.currentItem.contactClient.details.fonction)
          setFonction(props.currentItem.contactClient.details.fonction);
      }
      setIsValidForm(true);
    }
  }, [props.currentItem]);
  useEffect(() => {
    if (email.length > 0 && firstname.length > 0 && lastname.length > 0) {
      setIsValidForm(
        errors.email || errors.lastname || errors.firstname || errors.phone
          ? false
          : true
      );
    }
  }, [errors]);

  const handleKeyUp = (fieldName, fieldValue) => {
    let value = "";

    switch (fieldName) {
      case "email":
        if (!validateEmail(fieldValue)) {
          value = _("validate_email");
        }
        break;
      case "phone":
        if (!isValidPhoneNumber(fieldValue)) {
          value = _("validate_phone");
        }
        break;
      case "lastname":
      case "firstname":
        if (fieldValue.length < 2) {
          value = _("required_2_characters");
        }
        break;
      default:
    }
    if (fieldValue.length === 0 && fieldName !== "phone") {
      value = _("required_field");
    }
    if (fieldName === "phone" && fieldValue.length === 0) {
      value = "";
    }
    setErrors({ ...errors, [fieldName]: value });
  };

  const handleEmailBlur = (e) => {
    if (email.length > 0 && validateEmail(email)) {
      setIsFetchingUser(true);
      getUserByEmail(auth.token, email)
        .then((resp) => {
          if (resp.data.data.length > 0) {
            setFetchedUser(resp.data.data[0]);
            setFirstname(resp.data.data[0].firstName);
            setLastname(resp.data.data[0].lastName);
            if (resp.data.data[0].language)
              setLanguage(
                LANGUAGES.filter(
                  (e) => e.value === resp.data.data[0].language
                )[0]
              );

            if (resp.data.data[0].gender)
              setGender(
                GENDER_OPTIONS.filter(
                  (e) => e.value === resp.data.data[0].gender
                )[0]
              );
            if (resp.data.data[0].mainPhone) {
              setPhone(resp.data.data[0].mainPhone);
            }
            setDisableFields(true);

            setErrors({
              email: "",
              lastname: "",
              firstname: "",
              phone: "",
            });
          }
          setIsFetchingUser(false);
          // validate();
        })
        .catch((e) => {
          setDisableFields(false);
          setIsFetchingUser(false);
          handleResetForm(false);
        });
    }
  };

  const handleUenBlur = (value) => {
    if (!value) value = uen;
    if (value.length > 0 && validateUen(value)) {
      setIsFetchingUser(true);
      getFolderByUen({
        token: auth.token,
        uen: value,
      })
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            setFetchedFolder(response.data.data[0]);
            setUname(response.data.data[0].name);
            setDisableFieldsUen(true);
            if (props.currentItem && !oldFetchedFolder) {
              setOldFetchedFolder(response.data.data[0].id);
            }
          }
        })
        .catch((e) => {
          if (e.response && e.response.status === 404) {
            axios
              .get(
                `https://api.data.be/2.0/companies/BE${value.replace(
                  /\.|\s|BE|be/g,
                  ""
                )}/info`,
                {
                  headers: {
                    API_ID: DATA_BE_ID,
                    API_KEY: DATA_BE_KEY,
                  },
                }
              )
              .then((res) => {
                const resp = res.data;
                if (resp.valid) {
                  setUname(resp.company_name);
                  setDisableFieldsUen(true);
                }
              })
              .finally(() => {
                setIsFetchingUser(false);
              });
          }
        })
        .finally(() => {
          setIsFetchingUser(false);
        });
    }
  };

  const validate = () => {
    let errors = [];
    handleKeyUp("email", email);
    handleKeyUp("lastname", lastname);
    handleKeyUp("firstname", firstname);
    handleKeyUp("phone", phone);

    return errors.email || errors.lastname || errors.firstname || errors.phone
      ? true
      : false;
  };

  const save = () => {
    let error = validate();
    if (error) {
      return null;
    }

    const data = {
      email,
      firstname,
      lastname,
      phone,
      language: language.value,
      gender: gender.value,
      details: JSON.parse(
        JSON.stringify({
          uen: uen.replace(/\.|\s|BE|be/g, ""),
          name: uName,
          fonction,
          isClient: false,
        })
      ),
      fetchedUser,
      fetchedFolder,
      oldFetchedFolder,
    };

    if (props.currentItem) {
      data.userID = props.currentItem.id;
      if (props.currentItem.email && props.currentItem.email.length > 0) {
        data.emailId = props.currentItem.email.filter(
          (item) => item.email === props.currentItem.mainEmail
        )[0].id;
      }
      if (props.currentItem.phone && props.currentItem.phone.length > 0) {
        data.phoneId = props.currentItem.phone.filter(
          (item) => item.number === props.currentItem.mainPhone
        )[0].id;
      }
    }

    setIsSaving(true);
    saveContact(auth.token, data, fiduciary.id)
      .then((resp) => {
        setIsSaving(false);

        // toast.success(
        //   props.currentItem
        //     ? _("successfully_updated")
        //     : _("successfully_added")
        // );
        handleResetForm();
        props.setCurrentTab("LIST");
        props.afterSave(resp?.data?.data);
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleResetForm = (resetEmail = true) => {
    if (resetEmail) {
      setEmail("");
    }
    setFirstname("");
    setLastname("");
    setPhone("");
    setDisableFields(false);
    setGender(GENDER_OPTIONS[0]);
    setLanguage(LANGUAGES[0]);
    setErrors({
      email: "",
      lastname: "",
      firstname: "",
      phone: "",
      uen: "",
    });
    setUen("");
    setUname("");
    setFonction("");
    document
      .getElementById("contactForm")
      .scroll({ top: 0, behavior: "smooth" });
    setDisableFieldsUen(false);
    setFetchedUser(null);
    setFetchedFolder(null);
    setOldFetchedFolder(null);
  };
  return (
    <div className={props.addFormClass ? styles.form : ""}>
      <div
        id={"contactForm"}
        className={`${styles.form_content} ${
          props.addFormClass && styles.form_content_pad
        } ${props.addFormClass ? styles.form_content_settings : ""} ${
          isFetchingUser && styles.loading
        }`}
      >
        {props.addFormClass && (
          <div className={styles.titles}>
            <h4>Ajout Manuel d'un contact</h4>
            <p>Remplissez les champs pour ajouter un contact</p>
          </div>
        )}

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <FormInput
              name="email"
              required={true}
              label={_("email_address")}
              autocomplete="off"
              error={errors.email}
              value={email}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              handleBlur={(e) => handleEmailBlur(e)}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="cell small-12 medium-6">
            <FormInput
              name="phone"
              placeholder="+32 ___ ___ ___"
              label={_("Phone")}
              autocomplete="off"
              error={errors.phone}
              value={phone}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <FormInput
              name="lastname"
              required={true}
              label={_("lastname")}
              autocomplete="off"
              error={errors.lastname}
              value={lastname}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => setLastname(e.target.value)}
              disabled={disableFields}
            />
          </div>
          <div className="cell small-12 medium-6">
            <FormInput
              name="firstname"
              required={true}
              label={_("firstname")}
              autocomplete="off"
              error={errors.firstname}
              value={firstname}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => setFirstname(e.target.value)}
              disabled={disableFields}
            />
          </div>
        </div>

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <div className="ttp-form-group">
              <label className="ttp-label">{_("gender_1")}</label>
              <Select
                styles={SELECT_STYLES}
                options={GENDER_OPTIONS}
                isSearchable={false}
                value={gender}
                onChange={(e) => setGender(e)}
                isDisabled={disableFields}
              />
            </div>
          </div>
          <div className="cell small-12 medium-6">
            <div className="ttp-form-group">
              <label className="ttp-label">{_("language")}</label>
              <Select
                styles={SELECT_STYLES}
                options={LANGUAGES}
                isSearchable={false}
                value={language}
                onChange={(e) => setLanguage(e)}
                isDisabled={disableFields}
              />
            </div>
          </div>
        </div>

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <FormInput
              name="uen"
              placeholder="BE XXXX.XXX.XXX"
              label={_("business_number")}
              value={uen}
              autoComplete="none"
              error={errors.uen}
              onChange={(e) => {
                if (e.target.value.length > 0 && !validateUen(e.target.value))
                  setErrors({ ...errors, uen: _("validate_uen") });
                else setErrors({ ...errors, uen: _("") });
                setUen(e.target.value);
              }}
              onBlur={(e) => handleUenBlur()}
            />
          </div>
          <div className="cell small-12 medium-6">
            <FormInput
              name="name"
              value={uName}
              label={_("company_name")}
              autoComplete="none"
              onChange={(e) => setUname(e.target.value)}
              disabled={disableFieldsUen}
            ></FormInput>
          </div>
        </div>

        <FormInput
          name="fonction"
          label={_("Fonction")}
          value={fonction}
          autoComplete="none"
          onChange={(e) => setFonction(e.target.value)}
        ></FormInput>
      </div>
      <div className={styles.actions}>
        <Button
          variant="default"
          onClick={() => {
            handleResetForm();
            props.setCurrentTab("LIST");
          }}
        >
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button disabled={!isValidForm} onClick={() => save()}>
            {props.currentItem ? _("edit") : _("add")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
