import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ModalConfirm } from "tamtam-components";

import {
  getPack,
  getApplications,
  deletePackApp,
  saveApplication,
} from "../../../../api";
import _ from "../../../../i18n";
import Button from "../../../common/Button";

import AppPopOver from "./AppPopOver";
import Accordion from "./Accordion";
import styles from "./Accordion.module.scss";
import sidebarStyles from "../Sidebar.module.scss";

const AppPack = ({ currentPack, onBack, onNext }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [isLoading, setIsLoading] = useState(false);
  const [pack, setPack] = useState(null);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [currentApp, setCurrentApp] = useState(null);
  const [appType, setAppType] = useState("APP");
  const [parentApp, setParentApp] = useState(null);
  const [hasApps, setHasApps] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    if (currentPack) {
      setIsLoading(true);
      getPack(token, currentPack.id)
        .then(async (response) => {
          if (response?.data?.data?.length === 1) {
            try {
              const resultApps = await getApplications(token);
              setApplications(resultApps.data.data);
            } catch (e) {}
            setPack(response.data.data[0]);
            // let added = false;
            // response.data.data[0]?.uaPackApps?.forEach((app) => {
            //   app.children.forEach((category) => {
            //     category.children.forEach((subCategory) => {
            //       if (subCategory.children.length > 0) {
            //         // setHasApps(true);
            //         added = true;
            //       }
            //     });
            //   });
            // });
            // if (!added) {
            //   setHasApps(false);
            // }
          }
        })
        .catch((e) => {})
        .finally(() => {
          setIsLoading(false);
          if (refetch) {
            setRefetch(false);
          }
        });
    }
  }, [currentPack, refetch]);

  const handleOpenPopover = (app, type, parentApp = null) => {
    setAppType(type);
    setCurrentApp(app);
    setParentApp(parentApp);
    setOpenPopOver(true);
  };

  const handleDelete = async () => {
    console.log("eeee", currentApp);
    if (token) {
      try {
        setDeleting(true);
        await deletePackApp(token, currentApp.id);
        if (currentApp.type === "APP") {
          await saveApplication(token, {
            id: currentApp.uaApplication.id,
            isUsed: 0,
          });
        }
        setRefetch(true);
        setDeleting(false);
        setIsOpenDeleteModal(false);
        toast.success(_("successfully_deleted"));
      } catch (error) {
        setDeleting(false);
        toast.error(_("error_try_again"));
      }
    }
  };

  return (
    <div className={styles.page}>
      {isLoading && <div className="lmask"></div>}
      {pack && (
        <div className={styles.accordionBox}>
          {pack?.uaPackApps?.map((app) => (
            <Accordion
              key={app.id}
              currentApp={app}
              currentPack={currentPack}
              setCurrentApp={setCurrentApp}
              openPopover={handleOpenPopover}
              showDeleteModal={() => setIsOpenDeleteModal(true)}
            />
          ))}

          {pack?.uaPackFormulas ? (
            <>
              <div
                className={styles.add_app_trigger}
                onClick={() => handleOpenPopover(null, "APP")}
              >
                {_("add_app")}
              </div>

              <div className={sidebarStyles.actions}>
                <Button variant="default" onClick={onBack}>
                  {_("back")}
                </Button>

                <Button
                  disabled={!pack?.uaPackApps || pack?.uaPackApps.length === 0}
                  onClick={onNext}
                >
                  {_("continue")}
                </Button>
              </div>
            </>
          ) : (
            <h3>Veuillez valider l'étape précédente</h3>
          )}

          {openPopOver && (
            <AppPopOver
              closePopOver={() => {
                setOpenPopOver(false);
                setCurrentApp(null);
              }}
              onSave={() => {
                setRefetch(true);
                setOpenPopOver(false);
                setCurrentApp(null);
              }}
              currentApp={currentApp}
              currentPack={pack}
              type={appType}
              parentApp={parentApp}
              applications={applications}
              setApplications={setApplications}
            />
          )}

          <ModalConfirm
            type="delete"
            isOpen={isOpenDeleteModal}
            onCancel={() => setIsOpenDeleteModal(false)}
            onConfirm={() => handleDelete()}
            inProcess={deleting}
            title={_("modal_delete_title")}
            text={_("modal_delete_text")}
            labelNo={_("no")}
            labelYes={_("yes")}
            labelError={_("error")}
          />
        </div>
      )}
    </div>
  );
};

export default AppPack;
