import React from "react";

const IconCheck = () => {
  return (
    <svg
      width="54"
      height="43"
      viewBox="0 0 54 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_54723_47822)">
        <path
          d="M49.5 24.9312V27.0012C49.4972 31.8531 47.9261 36.5742 45.021 40.4603C42.1159 44.3463 38.0324 47.1892 33.3796 48.5649C28.7267 49.9406 23.7538 49.7754 19.2026 48.094C14.6513 46.4125 10.7655 43.3049 8.12466 39.2346C5.48385 35.1642 4.22953 30.3493 4.54877 25.5079C4.86802 20.6664 6.74372 16.0579 9.89613 12.3696C13.0485 8.68125 17.3088 6.11077 22.0414 5.04149C26.7741 3.97221 31.7256 4.46142 36.1575 6.43616"
          stroke="#FFF0E4"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M49.5 9L27 31.5225L20.25 24.7725"
          stroke="#FFF0E4"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_54723_47822">
          <rect width="54" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconCheck;
