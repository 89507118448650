import React from "react";

const IconLoading = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='64' height='64' version='1.0' viewBox='0 0 128 128'>
      <g>
        <path fill='#18a0fb' d='M38.52 33.37 21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z' />
        <path
          fill='#c6e8fe'
          d='M67.64159992 24.3242385 67.648671.0492627a63.6 63.6 0 0 1 38.3110454 15.62705987L88.77702162 32.85901736a39.5 39.5 0 0 0-21.14249275-8.52770778zM94.63 38.52l17.17-17.16a63.6 63.6 0 0 1 16.04 38.14h-24.3a39.5 39.5 0 0 0-8.92-20.98zM103.6757615 67.64159992l24.2749758.00707107a63.6 63.6 0 0 1-15.62705987 38.3110454L95.14098264 88.77702162a39.5 39.5 0 0 0 8.52770778-21.14249275zM89.48 94.63l17.16 17.17a63.6 63.6 0 0 1-38.14 16.04v-24.3a39.5 39.5 0 0 0 20.98-8.92zM60.35840008 103.6757615l-.00707107 24.2749758a63.6 63.6 0 0 1-38.3110454-15.62705987l17.18269478-17.18269479a39.5 39.5 0 0 0 21.14249275 8.52770778zM33.37 89.48 16.2 106.64A63.6 63.6 0 0 1 .16 68.5h24.3a39.5 39.5 0 0 0 8.92 20.98zM24.3242385 60.35840008.0492627 60.351329a63.6 63.6 0 0 1 15.62705987-38.3110454l17.18269479 17.18269478a39.5 39.5 0 0 0-8.52770778 21.14249275z'
        />
        <animateTransform
          attributeName='transform'
          calcMode='discrete'
          dur='720ms'
          repeatCount='indefinite'
          type='rotate'
          values='0 64 64;45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64'
        />
      </g>
    </svg>
  );
};

export default IconLoading;
