import axios from "axios";
import { TTP_API_URL } from "../config";

export const getSubscriptionDetails = async (cmail) => {
  const requestUrl = `${TTP_API_URL}/mailing/subscription/details`;

  try {
    const response = await axios.get(requestUrl, {
      params: {
        cmail: cmail,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching subscription details:", error);
    throw error;
  }
};

export const updateSubscription = (data) => {
  const requestUrl = `${TTP_API_URL}/mailing/toggle/subscription`;

  var formData = new FormData();
  formData.append("cmail", data.cmail);
  formData.append("subscriberId", data.id);
  formData.append("type", data.type);

  return axios.post(requestUrl, formData);
};

export const updateSubscriptionTT = (data) => {
  const requestUrl = `${TTP_API_URL}/mailing/toggle/subscriptionForTT`;

  var formData = new FormData();
  formData.append("cmail", data.cmail);
  formData.append("subscriberId", data.id);
  formData.append("type", data.type);
  formData.append("unsubscibedFromTT", 1);

  return axios.post(requestUrl, formData);
};

