import { TTP_TALK_URL } from "../../../config";

const TALK_WIDGET = "talk-widget";

const installTalkWidget = (userId, communityId, token) => {
  // Detach to give priority to main app rendering
  setTimeout(() => {
    removeTalkWidget();

    const query = `v=0.0.1&user_id=${userId}&access_token=${token}&organization_id=${communityId}`;
    const widget = document.createElement("script");
    widget.id = TALK_WIDGET;
    widget.setAttribute("src", `${TTP_TALK_URL}/api/v1/widget.min.js?${query}`);
    // widget.setAttribute(
    //   "src",
    //   `https://talk.tamtam.pro/api/v1/widget.min.js?v=0.0.1&user_id=8650&access_token=c29c1958a15ad6e61078ced7d8e69de14b5f715d&organization_id=4`
    // );
    document.body.appendChild(widget);
  }, 0);
};

const removeTalkWidget = () => {
  // Remove Widget script tag
  const widgetScript = document.querySelector(`#${TALK_WIDGET}`);
  widgetScript && widgetScript.parentNode.removeChild(widgetScript);

  // Remove Widget
  const widget = document.querySelector(`.${TALK_WIDGET}`);
  widget && widget.parentNode.removeChild(widget);
};

export const handleTalkWidget = (store) => {
  let currentUser = null;
  let currentCommunity = null;
  let currentToken = null;
  let currentLoggedAs = null;

  try {
    let previousUser = currentUser;
    let previousCommunity = currentCommunity;
    let previousToken = currentToken;
    let previousLoggedAs = currentLoggedAs;

    const auth = store.getState().auth;

    if (!auth) {
      removeTalkWidget();
      return;
    }

    currentUser = auth.user;
    currentCommunity = auth.navCommunity;
    currentToken = auth.token;
    currentLoggedAs = auth.loggedAs;

    if (
      previousUser !== currentUser ||
      previousCommunity !== currentCommunity ||
      previousToken !== currentToken ||
      previousLoggedAs !== currentLoggedAs
    ) {
      if (
        currentToken &&
        currentUser &&
        currentUser.id &&
        currentUser.roles &&
        currentCommunity &&
        currentCommunity.id &&
        currentLoggedAs
      ) {
        let organizationId = currentCommunity.id;

        if (currentLoggedAs === "CLIENT") {
          const role = currentUser.roles[0];
          if (
            !role ||
            !role.organization ||
            !role.organization.id ||
            !(
              role.type === "LEGAL_REPRESENTATIVE" &&
              role.typeStatus === "FOLDER"
            )
          ) {
            return removeTalkWidget();
          }
          organizationId = role.organization.id;
        }

        installTalkWidget(currentUser.id, organizationId, currentToken);
      } else {
        removeTalkWidget();
      }
    }
  } catch (e) {
    console.log("Failed to handle Talk Widget", e);
  }
};
