import { useState } from "react";
import _ from "../../../../i18n";
import Loader from "../../../common/Loader";

import styles from "../Client.module.scss";
import { useSelector } from "react-redux";

const List = ({
  data,
  isLoading,
  setCurrentItem,
  setCurrentDeleteItem,
  setIsOpenDeleteModal,
}) => {
  const language = useSelector((state) => state.params.language);
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [dropDownId, setDropDownId] = useState(null);
  const toggleDropDown = (e, itemId) => {
    e.stopPropagation();
    dropDownId === itemId ? setDropDownId(null) : setDropDownId(itemId);
  };

  return (
    <div>
      <table className={`${styles.table} unstriped`}>
        <thead>
          <tr>
            <th>
              <span>{_("client")}</span>
            </th>
            <th>
              <span>{_("collaborator")}</span>
            </th>
            <th>
              <span>{_("delegation_type")}</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={7} className={styles.centerCell}>
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#B2BCC6"}
                />
              </td>
            </tr>
          ) : data?.data && data.data.length > 0 ? (
            data.data.map((item) => (
              <tr key={item.id}>
                <td>{item.folder.name}</td>
                <td>{`${item.user.firstName} ${item.user.lastName}`}</td>
                <td>{item.type[titleAttr]}</td>
                <td>
                  <div
                    className={styles.cellIcon}
                    onClick={(e) => {
                      setCurrentDeleteItem(item);
                      setIsOpenDeleteModal(true);
                    }}
                  >
                    <span className="icon-ttp-close" />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className={styles.centerCell}>
                Liste vide (aucun délégation n'a été ajouté)
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default List;
