import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./TestimonialModal.module.scss";
import tabstyles from "../tabs/Portals/Portals.module.scss";
import _ from "../../../i18n";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import Tabs from "../../common/Tabs";

import { saveTestimonial } from "../../../api/testimonial";

const TestimonialModal = ({
  isOpen,
  onClose,
  currentElement,
  languages,
  afterSave,
  setCurrentElement,
}) => {
  const token = useSelector((state) => state.auth.token);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [description, setDescription] = useState({ fr: "", en: "", nl: "" });
  const [author, setAuthor] = useState("");
  const [headline, setHeadline] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (currentElement) {
      setAuthor(currentElement.author);
      setHeadline(currentElement.headline);
      setDescription({
        fr: currentElement.descriptionFr,
        en: currentElement.descriptionEn,
        nl: currentElement.descriptionNl,
      });
    }
  }, [currentElement]);

  const resetData = () => {
    setAuthor("");
    setHeadline("");
    setDescription({ fr: "", en: "", nl: "" });
    setErrors([]);
    setIsSaving(false);
    setCurrentElement(null);
    onClose();
  };

  const validate = () => {
    const err = [];
    if (
      description.fr.length === 0 &&
      description.en.length === 0 &&
      description.nl.length === 0
    ) {
      err.push(_("testimonial_required"));
    }
    if (author.length === 0) {
      err.push(_("author_required"));
    }

    setErrors(err);
    return err.length === 0;
  };

  const save = async () => {
    if (!validate()) {
      return;
    }
    setIsSaving(true);
    try {
      const data = {
        author,
        headline,
        descriptionFr: description.fr,
        descriptionEn: description.en,
        descriptionNl: description.nl,
        organization: fiduciary.id,
      };
      if (currentElement) {
        data.id = currentElement.id;
      }
      const res = await saveTestimonial(token, data);
      afterSave(
        currentElement
          ? { ...res.data.data, show: currentElement.show }
          : { ...res.data.data, show: false }
      );
      setIsSaving(false);
      resetData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => resetData()}
      className={{
        base: styles.modalContent,
        afterOpen: styles.modalContentAfterOpen,
        beforeClose: styles.modalContentBeforeClose,
      }}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <div className={styles.header}>
          {_(currentElement ? "edit_testimonial" : "create_testimonial")}
        </div>
        <div className={styles.close} onClick={() => resetData()}>
          <i className="icon-ttp-close"></i>
        </div>

        <div className={styles.body}>
          {errors.length > 0 && (
            <ul className={styles.errors}>
              {errors.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
          )}
          <Tabs theme="serviceTheme">
            {languages.map((language) => (
              <div
                key={`langtab${language.value}`}
                label={language.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
              >
                <div className={tabstyles.langTab}>
                  <label
                    className={`${tabstyles.configLabel} ${tabstyles.descriptionLabel}`}
                  >
                    {_(`testimonial_${language.value}`)}
                  </label>
                  <textarea
                    rows="4"
                    className={classNames(
                      tabstyles.descriptionInput,
                      styles.desc
                    )}
                    value={description[language.value]}
                    onChange={(e) =>
                      setDescription({
                        ...description,
                        [language.value]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </Tabs>

          <label className={classNames(tabstyles.configLabel, styles.mt)}>
            {_(`author`)}
          </label>

          <input
            className={tabstyles.formInput}
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
          />
          <label className={classNames(tabstyles.configLabel, styles.mt)}>
            {_(`headline`)}
          </label>

          <input
            className={tabstyles.formInput}
            value={headline}
            onChange={(e) => setHeadline(e.target.value)}
          />
        </div>

        <div className={styles.controls}>
          <Button onClick={() => resetData()} variant="default">
            {_("cancel")}
          </Button>

          <Button variant="primary" onClick={() => save()}>
            {isSaving ? (
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            ) : (
              _("save")
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TestimonialModal;
