import { useEffect } from "react";

const UpgradePaymentSuccessRedirect = () => {
  useEffect(() => {
    window.parent.location = "/settings?ps=1"; // paymentSuccess = 1
  }, []);

  return null;
};

export default UpgradePaymentSuccessRedirect;
