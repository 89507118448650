import classNames from "classnames";
import { useState } from "react";
import { ReactBingmaps } from "react-bingmaps";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { createUaContactMessage } from "../../api";
import _ from "../../i18n";
import { validateEmail } from "../../utils";
import Loader from "../common/Loader";
import styles from "./PublicPortal.module.scss";

const Contact = ({ community, page }) => {
  const auth = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    subject: "",
  });
  const [saving, setSaving] = useState(false);

  const renderSchedulePart = (schedule, moment) => {
    return (
      <td
        className={`${
          schedule[moment].FROM === "NO_WORK" ||
          schedule[moment].FROM === "" ||
          schedule[moment].TO === ""
            ? styles.closed
            : ""
        }`}
      >
        {schedule[moment].FROM === "NO_WORK" ||
        schedule[moment].FROM === "" ||
        schedule[moment].TO === ""
          ? _("office_closed")
          : `${schedule[moment].FROM.replace(/_/g, "0")} > ${schedule[
              moment
            ].TO.replace(/_/g, "0")}`}
      </td>
    );
  };

  const renderSchedule = () => {
    const schedule = page.schedule;
    let scheduleBlocs = [];
    let check = [];
    for (const [key, value] of Object.entries(schedule)) {
      check.push(
        value.MORNING.FROM,
        value.MORNING.TO,
        value.AFTERNOON.FROM,
        value.AFTERNOON.TO
      );
      scheduleBlocs.push(
        <tr key={key}>
          <td className={styles.day}>{_(`${key}`).substring(0, 1)}</td>
          {renderSchedulePart(value, "MORNING")}
          {renderSchedulePart(value, "AFTERNOON")}
        </tr>
      );
    }

    return (
      <table>
        <tbody>{scheduleBlocs}</tbody>
      </table>
    );
  };

  const showSchedule = () => {
    const schedule = page.schedule;
    let check = [];
    for (const [key, value] of Object.entries(schedule)) {
      check.push(
        value.MORNING.FROM,
        value.MORNING.TO,
        value.AFTERNOON.FROM,
        value.AFTERNOON.TO
      );
    }
    let show = false;
    check.forEach((element) => {
      if (element !== "") {
        show = true;
      }
    });
    return show;
  };

  const { latitude, longitude } = page;

  const validate = () => {
    let err = 0;
    let tmp = errors;
    if (name.length === 0) {
      tmp = {
        ...tmp,
        name: "required_field",
      };
      err++;
    } else {
      tmp = {
        ...tmp,
        name: "",
      };
    }
    if (email.length === 0) {
      tmp = {
        ...tmp,
        email: "required_field",
      };
      err++;
    } else if (!validateEmail(email)) {
      tmp = {
        ...tmp,
        email: "invalid_email",
      };
      err++;
    } else {
      tmp = {
        ...tmp,
        email: "",
      };
    }
    if (subject.length === 0) {
      tmp = {
        ...tmp,
        subject: "required_field",
      };
      err++;
    } else {
      tmp = {
        ...tmp,
        subject: "",
      };
    }

    setErrors(tmp);
    return err === 0;
  };
  const save = async () => {
    if (!validate()) return null;
    if (
      errors.name.length > 0 ||
      errors.email.length > 0 ||
      errors.subject.length > 0
    )
      return null;
    const data = {
      organization: community.id,
      name,
      email,
      subject,
      message,
    };
    try {
      setSaving(true);
      await createUaContactMessage({ token: auth.token, data });
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setErrors({ name: "", email: "", subject: "" });
      setSaving(false);
      toast.success(_("successfully_sent"));
    } catch (error) {}
  };

  return (
    <section
      id="portal-contact"
      style={{ paddingBottom: "13rem" }}
      className={classNames(styles.contactSection, "grid-x")}
    >
      <div className="cell small-12 medium-6 grid-x">
        <div
          className={
            showSchedule() ? "cell small-12 medium-6 " : "cell small-12 "
          }
        >
          <div className={styles.organizationSchedule}>
            <h5>{_("Info")}</h5>
            <h4>
              {community.abbreviation ? community.abbreviation : community.name}
            </h4>
            <ul className={styles.list1}>
              {!community.address1 &&
              !community.zipCode &&
              !community.city ? null : (
                <li>
                  <i className="icon-ttp-pin" />
                  <span>
                    {community.address1 ? community.address1 : ""}
                    {community.address2 ? " " + community.address2 : ""}
                    {community.zipCode ? " - " + community.zipCode : ""}
                    {community.city ? " " + community.city : ""}
                  </span>
                </li>
              )}
              {!page.phone ? null : (
                <li>
                  <i className="icon-ttp-smartphone" />
                  <span>Tel. {page.phone}</span>
                </li>
              )}
              {!community.fax ? null : (
                <li>
                  <i className="icon-ttp-print" />
                  <span>Fax. {community.fax}</span>
                </li>
              )}
              {!page.email ? null : (
                <li>
                  <i className="icon-ttp-email-read" />
                  <span>{page.email}</span>
                </li>
              )}
            </ul>
            <ul className={styles.list2}>
              {!community.uen || !community.country ? null : (
                <li>{`TVA ${community.country} ${community.uen}`}</li>
              )}
            </ul>
          </div>
        </div>
        {showSchedule() && (
          <div className="cell small-12 medium-6 ">
            <div className={styles.organizationSchedule}>
              <h5>{_("Info")}</h5>
              <h4>{_("opening_time")}</h4>
              {renderSchedule()}
            </div>
          </div>
        )}
        <div className={"cell small-12 medium-12"}>
          <div className={styles.contactMap}>
            {latitude && longitude && (
              <ReactBingmaps
                bingmapKey="AlfqRT9EH4KtKEzZQp4yhxAIWxiujabG1jpSuUJaQ_GWxN9ONI_MQvd_e2kJpK5H"
                center={[parseFloat(latitude), parseFloat(longitude)]}
                navigationBarMode={"compact"}
                zoom={14}
                mapTypeId={"aerial"}
                mapOptions={{ disableScrollWheelZoom: true }}
                pushPins={[
                  {
                    location: [parseFloat(latitude), parseFloat(longitude)],
                    option: {
                      color: "#18A0FB",
                      //icon: "/img/pin.png"
                      //icon: "https://unpkg.com/leaflet@1.3.3/dist/images/marker-icon-2x.png"
                    },
                  },
                ]}
              />
            )}
          </div>
        </div>
      </div>
      <div className="cell small-12 medium-6">
        <div className={styles.contactForm}>
          <h5>{_("Contact")}</h5>
          <div className={styles.flex}>
            <div className={styles.formInput}>
              <label>{_("first_last_name")}</label>
              <input
                className={classNames(
                  styles.small,
                  errors.name.length > 0 && styles.errorBorder
                )}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onBlur={() => {
                  if (name.length === 0) {
                    setErrors({
                      ...errors,
                      name: "required_field",
                    });
                  } else {
                    setErrors({
                      ...errors,
                      name: "",
                    });
                  }
                }}
              />
              <span className={styles.error}>{_(`${errors.name}`)}</span>
            </div>
            <div className={styles.formInput}>
              <label>{_("email")}</label>
              <input
                className={classNames(
                  styles.small,
                  errors.email.length > 0 && styles.errorBorder
                )}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => {
                  if (email.length === 0) {
                    setErrors({
                      ...errors,
                      email: "required_field",
                    });
                  } else if (!validateEmail(email)) {
                    setErrors({
                      ...errors,
                      email: "validate_email",
                    });
                  } else {
                    setErrors({
                      ...errors,
                      email: "",
                    });
                  }
                }}
              />
              <span className={styles.error}>{_(`${errors.email}`)}</span>
            </div>
          </div>
          <div className={styles.formInput}>
            <label>{_("subject")}</label>
            <input
              className={classNames(
                errors.subject.length > 0 && styles.errorBorder
              )}
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              onBlur={() => {
                if (subject.length === 0) {
                  setErrors({
                    ...errors,
                    subject: "required_field",
                  });
                } else {
                  setErrors({
                    ...errors,
                    subject: "",
                  });
                }
              }}
            />
            <span className={styles.error}>{_(`${errors.subject}`)}</span>
          </div>
          <div className={styles.formInput}>
            <label>{_("message")}</label>
            <textarea
              cols="30"
              rows="10"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
          <div className={styles.actions}>
            <button onClick={save}>
              {saving ? (
                <Loader
                  color={"#fff"}
                  style={{
                    height: "10px",
                    width: "79px",
                  }}
                />
              ) : (
                _("send_message")
              )}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
