import classNames from "classnames";
import React from "react";
import Loader from "../common/Loader";
import Media from "../common/Media";
import styles from "./Settings.module.scss";

const MediaList = ({
  medias,
  setMedias,
  language,
  newFile,
  uploading,
  isUsed,
  setSingleImage,
}) => {
  return (
    <div className={classNames(styles.mediasList, "grid-x")}>
      {uploading && (
        <div className={classNames(styles.mediaCont, "cell small-12 medium-4")}>
          <div
            className={classNames(
              styles.uploadingMedia,
              "cell small-12 medium-4"
            )}
            style={{
              backgroundImage: newFile
                ? `url("${URL.createObjectURL(newFile)}")`
                : "",
            }}
          >
            <div className={styles.uploadingMediaOverlay}>
              <Loader
                color={"#fff"}
                style={{
                  width: "100%",
                  height: "0.5rem",
                  margin: "auto",
                }}
              />
            </div>
          </div>
        </div>
      )}
      {medias.map((item) => (
        <div className={classNames(styles.mediaCont, "cell small-12 medium-4")}>
          <Media
            media={item}
            language={language}
            medias={medias}
            setMedias={setMedias}
            isUsed={isUsed}
            setSingleImage={setSingleImage}
          />
        </div>
      ))}
    </div>
  );
};

export default MediaList;
