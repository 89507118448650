import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useInfiniteQuery } from "react-query";

import _ from "../../i18n";

import { getArticles, getCategories, getChains, getEventList } from "../../api";
import { CarouselCategory, CarouselChain } from "./CarouselItem";
import TopAricles from "./TopAricles";
import OurAuthors from "./OurAuthors";
import ArticleList from "./ArticleList";
import SearchBar from "./SearchBar";
import MoreButton from "./MoreButton";
import OFFFCourse from "./OFFFCourse";

import { ARTICLE_LIMIT } from "../../config";

import styles from "./News.module.scss";

const types1 = ["type5", "type6", "type5", "type7", "type7"];
const types2 = ["type5", "type5", "type5", "type5", "type7", "type7", "type6"];
const types3 = ["type6", "type7", "type7", "type5", "type5", "type5", "type5"];
const types4 = ["type5", "type5", "type5", "type5", "type6", "type7", "type7"];

const News = () => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const currentPortal = useSelector((state) => state.params.currentPortal);
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [chains, setChains] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchLng, setSearchLng] = useState(["fr"]);
  const [searchCat, setSearchCat] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    if (auth.token) {
      getChains({
        token: auth.token,
      }).then((resp) => {
        setChains(resp.data.data);
      });

      getCategories({
        token: auth.token,
      }).then((resp) => {
        setCategories(resp.data.data);
      });
      getEventList(auth.token, auth.user.id, lng).then((resp) => {
        setEvents(resp.data.data);
      });
    }
  }, [auth.token]);

  useEffect(() => {
    if (auth.token) {
      let filters = {
        search: searchText,
        languages: searchLng,
      };
      getArticles(
        auth.token,
        filters,
        currentPortal === "CLIENT" ? "PUBLIC,CLIENT" : "PUBLIC,COLLABORATOR",
        { start: 0, limit: 41 }
      ).then((resp) => {
        setArticles(resp.data.data);
      });
    }
  }, [auth.token, currentPortal, searchLng]);

  const searchArticle = async ({ pageParam = 1 }) => {
    const options = {
      page: pageParam,
    };
    let filters = {
      search: searchText,
      languages: searchLng,
    };
    if (searchCat.length > 0) filters.categories = searchCat;
    if (searchText === "" && searchCat.length === 0) return undefined;
    if (auth.token !== "") {
      const response = await getArticles(auth.token, filters, options);
      return response.data;
    }
  };

  const { data, error, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery(
      ["searchList", searchText, searchLng, searchCat],
      searchArticle,
      {
        getNextPageParam: (lastPage, pages) => {
          if (
            lastPage &&
            pages &&
            pages.length * ARTICLE_LIMIT < lastPage.nbResult
          )
            return pages.length + 1;
          else return false;
        },
      }
    );

  const fechArticle = async ({ pageParam = 41 }) => {
    const options = {
      start: pageParam,
      limit: 12,
    };
    let filters = {
      search: searchText,
      languages: searchLng,
    };

    if (auth.token !== "") {
      const response = await getArticles(
        auth.token,
        filters,
        currentPortal === "CLIENT" ? "PUBLIC,CLIENT" : "PUBLIC,COLLABORATOR",
        options
      );
      return response.data;
    }
  };

  const list = useInfiniteQuery(
    ["articleList", auth.token, searchText, searchLng, searchCat],
    fechArticle,
    {
      getNextPageParam: (lastPage, pages) => {
        if (
          lastPage &&
          pages &&
          pages.length * ARTICLE_LIMIT < lastPage.nbResult
        )
          return pages.length + 1;
        else return false;
      },
    }
  );

  return (
    <div className="grid-container">
      {(isFetching || list.isFetching) && <div className="lmask"></div>}
      <div className={styles.header}>
        <h1 className={styles.title}>
          {_("Toutes l’actualités powered by")} <span>FFF</span>
        </h1>
        <SearchBar
          setSearchText={setSearchText}
          searchLng={searchLng}
          setSearchLng={setSearchLng}
          categories={categories}
          searchCat={searchCat}
          setSearchCat={setSearchCat}
        />
      </div>
      {error && (
        <div className={styles["no-data"]}>{_("no_article_found")}</div>
      )}
      {data && data.pages.length > 0 && data.pages[0] ? (
        <>
          {data.pages.map((page) => (
            <ArticleList types="type5" articles={page.data} />
          ))}
          {hasNextPage && <MoreButton handleClick={fetchNextPage} />}
        </>
      ) : (
        !isFetching &&
        !error && (
          <>
            <div className="grid-x grid-margin-x news-block">
              <div className="cell small-9">
                <ArticleList
                  types={types1}
                  articles={articles.filter((article, index) => index < 5)}
                />
              </div>
              <div className="cell small-3">
                <TopAricles />
              </div>
            </div>

            <CarouselChain chains={chains} lng={lng} />

            <div className="news-block">
              <ArticleList
                types={types2}
                articles={articles.filter(
                  (article, index) => index > 4 && index < 12
                )}
              />
            </div>

            <OFFFCourse events={events} lng={lng} />

            <div className="news-block">
              <ArticleList
                types="type8"
                articles={articles.filter(
                  (article, index) => index > 11 && index < 18
                )}
              />
            </div>

            <CarouselCategory categories={categories} lng={lng} />

            <div className="news-block">
              <ArticleList
                types={types3}
                articles={articles.filter(
                  (article, index) => index > 17 && index < 25
                )}
              />
            </div>

            <div className="news-block">
              <ArticleList
                types="type8"
                articles={articles.filter(
                  (article, index) => index > 24 && index < 27
                )}
              />
            </div>

            <div className="grid-x grid-margin-x news-block">
              <div className="cell small-9">
                <ArticleList
                  types={types1}
                  articles={articles.filter(
                    (article, index) => index > 26 && index < 32
                  )}
                />
              </div>
              <div className="cell small-3">
                <OurAuthors />
              </div>
            </div>

            <div className="news-block">
              <ArticleList
                types="type6"
                articles={articles.filter(
                  (article, index) => index > 31 && index < 34
                )}
              />
            </div>
            <div className="news-block">
              <ArticleList
                types={types4}
                articles={articles.filter(
                  (article, index) => index > 33 && index < 41
                )}
              />
            </div>

            <CarouselChain chains={chains} lng={lng} />

            <div className="news-block">
              {list.data &&
                list.data.pages.map((page, index) => (
                  <ArticleList
                    key={`page-${index}`}
                    types="type8"
                    articles={page.data}
                  />
                ))}
              {list.hasNextPage && (
                <MoreButton handleClick={list.fetchNextPage} />
              )}
            </div>

            <CarouselCategory categories={categories} lng={lng} />
          </>
        )
      )}
    </div>
  );
};

export default News;
