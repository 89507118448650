import React, { useEffect, useState } from "react";

import _ from "../../../i18n";
// import UserExpressList from "./UserExpressList";
import styles from "./Collaborator.module.scss";
import CollaboratorExpress from "./CollaboratorExpress";
import CollaboratorForm from "./CollaboratorForm";
import CollaboratorImport from "./CollaboratorImport";

const CollaboratorBox = (props) => {
  const [currentTab, setCurrentTab] = useState("EXPRESS");

  useEffect(() => {
    if (props.currentItem) {
      setCurrentTab("MANUAL");
    }
  }, [props.currentItem]);

  return (
    <div className={`${styles.form}`}>
      <ul className={styles.tabs}>
        <li
          className={`${styles.tab} ${
            ["EXPRESS", "USER_EXPRESS"].includes(currentTab) && styles.active
          }`}
          onClick={() => setCurrentTab("EXPRESS")}
        >
          {_("express")}
        </li>
        <li
          className={`${styles.tab} ${
            currentTab === "MANUAL" && styles.active
          }`}
          onClick={() => setCurrentTab("MANUAL")}
        >
          {_("manual")}
        </li>
        <li
          className={`${styles.tab} ${
            currentTab === "IMPORT" && styles.active
          }`}
          onClick={() => setCurrentTab("IMPORT")}
        >
          {_("import_xls_file")}
        </li>
      </ul>

      {currentTab === "EXPRESS" && (
        <CollaboratorExpress
          setCurrentTab={setCurrentTab}
          page="join"
          nbCollaborators={props.nbCollaborators}
          setShowUpgradeModal={props.setShowUpgradeModal}
          afterSave={props.afterSave}
          noLimit={props.noLimit}
        />
      )}

      {currentTab === "MANUAL" && (
        <CollaboratorForm
          afterSave={props.afterSave}
          currentItem={props.currentItem}
          resetForm={props.resetForm}
          setResetForm={props.setResetForm}
          nbCollaborators={props.nbCollaborators}
          setShowUpgradeModal={props.setShowUpgradeModal}
          noLimit={props.noLimit}
        />
      )}
      {currentTab === "IMPORT" && (
        <CollaboratorImport
          afterSave={props.afterSave}
          nbCollaborators={props.nbCollaborators}
          setShowUpgradeModal={props.setShowUpgradeModal}
          noLimit={props.noLimit}
        />
      )}

      {/* {currentTab === "USER_EXPRESS" && (
        // <UserExpressList
          setCurrentTab={setCurrentTab}
          page="join"
          nbCollaborators={props.nbCollaborators}
          setShowUpgradeModal={props.setShowUpgradeModal}
        />
      )} */}
    </div>
  );
};

export default CollaboratorBox;
