import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";

import { savePack } from "../../../api";
import _ from "../../../i18n";
import { SELECT_STYLES, LANGUAGES } from "../../../config";

import Tabs from "../../common/Tabs";
import Loader from "../../common/Loader";
import Button from "../../common/Button";
import Switch from "../../common/Switch/Switch";

import styles from "./Sidebar.module.scss";

const STATUS_OPTIONS = [
  { value: "ENABLED", label: _("enabled") },
  { value: "DISABLED", label: _("disabled") },
];

const PackForm = ({ currentPack, onSave, onCancel }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState({
    fr: "",
    nl: "",
    en: "",
  });
  const [descriptionNl, setDescriptionNl] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [status, setStatus] = useState(STATUS_OPTIONS[0]);
  const [selectedLanguages, setSelectedLanguages] = useState([LANGUAGES[0]]);
  const [hasSlider, setHasSlider] = useState(false);
  const [sliderLabelFr, setSliderLabelFr] = useState("");
  const [sliderLabelNl, setSliderLabelNl] = useState("");
  const [sliderLabelEn, setSliderLabelEn] = useState("");
  const [sliderTextFr, setSliderTextFr] = useState("");
  const [sliderTextNl, setSliderTextNl] = useState("");
  const [sliderTextEn, setSliderTextEn] = useState("");
  const [sliderMin, setSliderMin] = useState("");
  const [sliderMax, setSliderMax] = useState("");
  const [sliderStep, setSliderStep] = useState("");

  useEffect(() => {
    if (currentPack) {
      setName(currentPack.name);
      setDescription({
        fr: currentPack.descriptionFr,
        nl: currentPack.descriptionNl,
        en: currentPack.descriptionEn,
      });
      setStatus(
        STATUS_OPTIONS.filter(
          (status) => status.value === currentPack.status
        )[0]
      );
      setHasSlider(currentPack.hasSlider ? true : false);
      setSliderLabelFr(currentPack.sliderLabelFr ?? "");
      setSliderTextFr(currentPack.sliderTextFr ?? "");
      setSliderLabelNl(currentPack.sliderLabelNl ?? "");
      setSliderTextNl(currentPack.sliderTextNl ?? "");
      setSliderLabelEn(currentPack.sliderLabelEn ?? "");
      setSliderTextEn(currentPack.sliderTextEn ?? "");
      setSliderMin(currentPack.sliderMin);
      setSliderMax(currentPack.sliderMax);
      setSliderStep(currentPack.sliderStep);
      const langs = currentPack.languages.map((lang) => {
        return LANGUAGES.filter((l) => l.value === lang)[0];
      });
      setSelectedLanguages(langs);
    } else {
      setName("");
      setDescription({ fr: "", nl: "", en: "" });
      setStatus(STATUS_OPTIONS[0]);
      setSelectedLanguages([LANGUAGES[0]]);
      setHasSlider(false);
      setSliderLabelFr("");
      setSliderTextFr("");
      setSliderLabelNl("");
      setSliderTextNl("");
      setSliderLabelEn("");
      setSliderTextEn("");
      setSliderMin("");
      setSliderMax("");
      setSliderStep("");
    }
  }, [currentPack]);

  const validate = () => {
    let errors = [];

    if (!name) {
      errors.push(_("pack_name_required"));
    }

    if (hasSlider) {
      if (
        sliderLabelFr?.length < 3 ||
        sliderLabelNl?.length < 3 ||
        sliderLabelEn?.length < 3
      ) {
        errors.push(_("slider_label_required"));
      }
      if (!sliderMin || parseInt(sliderMin) === 0) {
        errors.push(_("slider_min_required"));
      }
      if (!sliderMax || parseInt(sliderMax) === 0) {
        errors.push(_("slider_max_required"));
      }
      if (!sliderStep || parseInt(sliderStep) === 0) {
        errors.push(_("slider_step_required"));
      }
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const langs = selectedLanguages.map((l) => l.value);

    const data = {
      name,
      description,
      status: status.value,
      hasSlider: hasSlider ? 1 : 0,
      sliderLabelFr,
      sliderTextFr,
      sliderLabelNl,
      sliderTextNl,
      sliderLabelEn,
      sliderTextEn,
      sliderMin,
      sliderMax,
      sliderStep,
      languages: JSON.stringify(langs),
    };

    if (currentPack) {
      data.id = currentPack.id;
    }

    setIsSaving(true);
    savePack(token, data)
      .then((response) => {
        setIsSaving(false);
        onSave(response.data.data);
        toast.success(_("successfully_saved"));
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleDescription = (lang, value) => {
    setDescription({ ...description, [lang]: value });
  };

  return (
    <div>
      <div className={styles.formRow}>
        <div>
          <label className="ttp-label">{_("name")}</label>
          <input
            type="text"
            className="ttp-input"
            autocomplete="off"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div>
          <label className="ttp-label">{_("status")}</label>
          <Select
            styles={SELECT_STYLES}
            options={STATUS_OPTIONS}
            value={status}
            onChange={(e) => setStatus(e)}
          />
        </div>

        <div>
          <label className="ttp-label">{_("language")}</label>
          <Select
            isMulti
            isClearable={false}
            styles={SELECT_STYLES}
            options={LANGUAGES}
            value={selectedLanguages}
            onChange={(e) => setSelectedLanguages(e)}
          />
        </div>
      </div>

      <Tabs theme="wtheme">
        {selectedLanguages.map((lang) => (
          <div
            key={`langtab${lang.value}`}
            label={lang.label}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${lang.value}.png`}
          >
            <div>
              <label className="ttp-label">
                {_(`description`) + " " + lang.value.toUpperCase()}
              </label>
              <textarea
                rows="4"
                className="ttp-input"
                value={description[lang.value]}
                onChange={(e) => handleDescription(lang.value, e.target.value)}
              />
            </div>
          </div>
        ))}
      </Tabs>

      <div className={styles.slider}>
        <div className="d-flex">
          <label className="ttp-label">{_("has_slider")}</label>
          <Switch isChecked={hasSlider} onChange={(e) => setHasSlider(e)} />
        </div>

        {hasSlider && (
          <>
            <div className="grid-x grid-margin-x grid-margin-y">
              <div className="cell small-12 medium-4">
                <label className="ttp-label">{_("slider_min")}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={sliderMin}
                  onChange={(e) => setSliderMin(e.target.value)}
                />
              </div>

              <div className="cell small-12 medium-4">
                <label className="ttp-label">{_("slider_max")}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={sliderMax}
                  onChange={(e) => setSliderMax(e.target.value)}
                />
              </div>

              <div className="cell small-12 medium-4">
                <label className="ttp-label">{_("slider_step")}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={sliderStep}
                  onChange={(e) => setSliderStep(e.target.value)}
                />
              </div>
            </div>
            <Tabs theme="wtheme">
              <div
                key={`langtabfr`}
                label={_("french")}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
              >
                <div>
                  <label className="ttp-label">
                    {_("slider_label") + " FR"}
                  </label>
                  <input
                    type="text"
                    className="ttp-input"
                    autocomplete="off"
                    value={sliderLabelFr}
                    onChange={(e) => setSliderLabelFr(e.target.value)}
                  />
                </div>

                <div>
                  <label className="ttp-label">
                    {_(`slider_text`) + " FR"}
                  </label>
                  <textarea
                    rows="4"
                    className="ttp-input"
                    value={sliderTextFr}
                    onChange={(e) => setSliderTextFr(e.target.value)}
                  />
                </div>
              </div>
              <div
                key={`langtabnl`}
                label={_("dutch")}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
              >
                <div>
                  <label className="ttp-label">
                    {_("slider_label") + " NL"}
                  </label>
                  <input
                    type="text"
                    className="ttp-input"
                    autocomplete="off"
                    value={sliderLabelNl}
                    onChange={(e) => setSliderLabelNl(e.target.value)}
                  />
                </div>

                <div>
                  <label className="ttp-label">
                    {_(`slider_text`) + " NL"}
                  </label>
                  <textarea
                    rows="4"
                    className="ttp-input"
                    value={sliderTextNl}
                    onChange={(e) => setSliderTextNl(e.target.value)}
                  />
                </div>
              </div>
              <div
                key={`langtaben`}
                label={_("english")}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
              >
                <div>
                  <label className="ttp-label">
                    {_("slider_label") + " EN"}
                  </label>
                  <input
                    type="text"
                    className="ttp-input"
                    autocomplete="off"
                    value={sliderLabelEn}
                    onChange={(e) => setSliderLabelEn(e.target.value)}
                  />
                </div>

                <div>
                  <label className="ttp-label">
                    {_(`slider_text`) + " EN"}
                  </label>
                  <textarea
                    rows="4"
                    className="ttp-input"
                    value={sliderTextEn}
                    onChange={(e) => setSliderTextEn(e.target.value)}
                  />
                </div>
              </div>
            </Tabs>
          </>
        )}
      </div>

      <div className={styles.actions}>
        <Button variant="default" onClick={onCancel}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={save}>{_("save_and_continue")}</Button>
        )}
      </div>
    </div>
  );
};

export default PackForm;
