import React, { useState } from "react";
import classNames from "classnames";
import ArrowDownIcon from "../icons/ArrowDownIcon";
import styles from "./Faq.module.scss";

const FaqList = ({ faqs, lng }) => {
  const [activeFaq, setActiveFaq] = useState(null);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <ul className={styles.list}>
      {faqs.length < 1 ? (
        <li
          className={classNames(styles.item)}
          // onClick={() => setActiveFaq(activeFaq === i ? null : i)}
        >
          <div className={styles.question}>
            {"Lorem ipsum dolor sit amet, consectetur adipiscing elit ?"}
            <ArrowDownIcon />
          </div>
        </li>
      ) : (
        faqs.map((item, i) => (
          <li
            key={i}
            className={classNames(
              styles.item,
              activeFaq === i ? styles.open : ""
            )}
            onClick={() => setActiveFaq(activeFaq === i ? null : i)}
          >
            <div className={styles.question}>
              {item[titleAttr]} <ArrowDownIcon />
            </div>
            <div className={styles.answer}>{item[descriptionAttr]}</div>
          </li>
        ))
      )}
    </ul>
  );
};

export default FaqList;
