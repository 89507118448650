import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetching: false,
  fetched: false,
  items: [],
  error: null,
};

export const privacySlice = createSlice({
  name: "privacy",
  initialState,
  reducers: {
    setPrivacyRubrics: (state, { payload }) => {
      state.items = payload;
    },
  },
});

export const { setPrivacyRubrics } = privacySlice.actions;

export default privacySlice.reducer;
