import React from "react";

const IconNo = ({ size = 20 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_66836_43527)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.9987 2.49479C5.85656 2.49479 2.4987 5.85266 2.4987 9.99479C2.4987 14.1369 5.85656 17.4948 9.9987 17.4948C14.1408 17.4948 17.4987 14.1369 17.4987 9.99479C17.4987 5.85266 14.1408 2.49479 9.9987 2.49479ZM0.832031 9.99479C0.832031 4.93218 4.93609 0.828125 9.9987 0.828125C15.0613 0.828125 19.1654 4.93218 19.1654 9.99479C19.1654 15.0574 15.0613 19.1615 9.9987 19.1615C4.93609 19.1615 0.832031 15.0574 0.832031 9.99479Z"
          fill="#DE4848"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.0906 6.91595C13.416 7.24139 13.416 7.76903 13.0906 8.09446L8.09056 13.0945C7.76512 13.4199 7.23748 13.4199 6.91205 13.0945C6.58661 12.769 6.58661 12.2414 6.91205 11.916L11.912 6.91595C12.2375 6.59052 12.7651 6.59052 13.0906 6.91595Z"
          fill="#DE4848"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.91205 6.91595C7.23748 6.59052 7.76512 6.59052 8.09056 6.91595L13.0906 11.916C13.416 12.2414 13.416 12.769 13.0906 13.0945C12.7651 13.4199 12.2375 13.4199 11.912 13.0945L6.91205 8.09446C6.58661 7.76903 6.58661 7.24139 6.91205 6.91595Z"
          fill="#DE4848"
        />
      </g>
      <defs>
        <clipPath id="clip0_66836_43527">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export function NoLineIcon({ size = 24 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289Z"
        fill="#DE4848"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
        fill="#DE4848"
      />
    </svg>
  );
}
export default IconNo;
