import React, { useRef } from "react";
import Slider from "react-slick";
import classNames from "classnames";

import styles from "./Testimonials.module.scss";
import LeftArrowIcon from "../icons/LeftArrowIcon";
import RightArrowIcon from "../icons/RightArrowIcon";
import TestimonialCard from "./TestimonialCard";

const TestimonialsSlider = ({ testimonials, lng }) => {
  const slider = useRef();
  let settings = {
    dots: true,
    dotsClass: `${styles.slick_dots}`,
    infinite: true,
    arrows: false,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1028,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
        },
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className={styles.slider}>
      <Slider {...settings} ref={slider}>
        {testimonials.map((testimonial, i) => (
          <div key={i} className={styles.cardCont}>
            <TestimonialCard testimonial={testimonial} lng={lng} />
          </div>
        ))}
      </Slider>

      <div
        className={styles.slider_btn}
        onClick={() => slider?.current?.slickPrev()}
      >
        <LeftArrowIcon />
      </div>
      <div
        className={classNames(styles.slider_btn, styles.right_btn)}
        onClick={() => slider?.current?.slickNext()}
      >
        <RightArrowIcon />
      </div>
    </div>
  );
};

export default TestimonialsSlider;
