import { createSlice } from "@reduxjs/toolkit";

import { getDefaultLanguage } from "../utils";

const defaultLanguage = localStorage.getItem("lng") || getDefaultLanguage();
const initialState = {
  language: defaultLanguage,
  currentPortal: "SETTINGS",
  isSuccessPayment: false,
  hideFooter: false,
};

const paramsSlice = createSlice({
  name: "params",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("lng", action.payload);
    },
    setCurrentPortal: (state, action) => {
      state.currentPortal = action.payload;
    },
    setIsSuccessPayment: (state, action) => {
      state.isSuccessPayment = action.payload;
    },
    setHideFooter: (state, action) => {
      state.hideFooter = action.payload;
    },
  },
});

export const {
  setLanguage,
  setCurrentPortal,
  setIsSuccessPayment,
  setHideFooter,
} = paramsSlice.actions;

export default paramsSlice.reducer;
