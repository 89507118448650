import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import { getTeam } from "../../api";
import _ from "../../i18n";
import LeftBanner from "./LeftBanner";
import User from "./User";
import styles from "./PublicPortal.module.scss";

const Team = ({ community, info, lng }) => {
  const [showAll, setShowAll] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  let { isLoading, data } = useQuery(
    ["getTeam", token, community, info],
    async () => {
      if (token && community && info) {
        const response = await getTeam({
          token,
          communityId: community.id,
          ids: info.list && info.list.length > 0 ? info.list : [],
        });
        if (info.list) {
          const list = [];
          info.list.forEach((element) => {
            list.push(
              response.data.data.filter((item) => item.id === element)[0]
            );
          });
          return list;
        } else {
          return response.data.data;
        }
      }
    }
  );

  if (isLoading || !data || data.length === 0) {
    return null;
  }

  let help = [];
  if (
    community &&
    community.portalPreferences &&
    community.portalPreferences.team
  ) {
    const { team } = community.portalPreferences;
    for (let i = 0; i < team.length; i++) {
      const current = data.filter(
        (user) => parseInt(user.id, 10) === parseInt(team[i].id, 10)
      );
      if (current && current.length > 0) {
        help.push({ ...current[0], title: team[i].title });
      }
    }
  } else {
    help = [...data];
  }

  const defaultDescription = () => {
    switch (lng) {
      case "fr":
        return `Découvrez l'équipe. Le cabinet est composée d'une équipe compétente et entièrement dédiée aux besoins de ses clients`;
      case "en":
        return `Discover the team. The firm is made up of a competent team entirely dedicated to the needs of its clients`;
      case "nl":
        return `Ontdek het team. Het kantoor bestaat uit een competent team dat volledig toegewijd is aan de behoeften van zijn klanten`;
      default:
        break;
    }
  };

  const defaultTitle = () => {
    switch (lng) {
      case "fr":
        return `Collaborateurs ${community.abbreviation || community.name}`;
      case "en":
        return `Collaborators ${community.abbreviation || community.name}`;
      case "nl":
        return `Bijdragers ${community.abbreviation || community.name}`;
      default:
        break;
    }
  };

  return (
    <section id="fiduciary-team">
      <div className="team-content grid-x">
        <LeftBanner
          buttons={false}
          type={_("Team")}
          title={info[titleAttr] ? info[titleAttr] : defaultTitle()}
          text={
            info[descriptionAttr] ? info[descriptionAttr] : defaultDescription()
          }
          style={{ minHeight: "334px" }}
        />
        <div
          className={`${styles.teamListContainer} cell large-9 medium-8 small-12`}
        >
          <div className={`${styles.teamList} grid-x grid-margin-y`}>
            {help.slice(0, showAll ? help.length : 8).map((user) => (
              <User key={user.id} user={user} />
            ))}
          </div>
          {!showAll && help.length > 8 && (
            <div className={styles.viewMore}>
              <hr />
              <button
                onClick={() => {
                  setShowAll(true);
                }}
              >
                View More <i className="icon-ttp-chevron-down " />
              </button>
              <hr />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Team;
