import React from "react";
import { useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import { getDelegationTypes, getUsers } from "../../../../api";

export default function DelegationTypeSelect({
  isMulti,
  setDelegationType,
  organizationId,
  onChange,
}) {
  const token = useSelector((state) => state.auth.token);
  const language = useSelector((state) => state.params.language);
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const fetchDelegationTypes = (inputValue) => {
    return getDelegationTypes({
      token,
      organizationId,
      search: inputValue,
      status: "ENABLED",
    }).then((result) => {
      return result.data.data;
    });
  };

  return (
    <div>
      <AsyncSelect
        isMulti={isMulti}
        cacheOptions
        value={setDelegationType}
        onChange={(e) => onChange(e)}
        loadOptions={fetchDelegationTypes}
        defaultOptions={true}
        getOptionLabel={(option) => option[titleAttr]}
        getOptionValue={(option) => option.id}
        classNamePrefix="custom-select"
      />
    </div>
  );
}
