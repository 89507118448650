import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import _ from "../../../i18n";
import { addUserExpress } from "../../../api";
import { getRemainAddCollaborator } from "../../../utils";

import Button from "../../common/Button";
import FormInput from "../../common/FormInput";
import Loader from "../../common/Loader";
import styles from "./Collaborator.module.scss";

const CollaboratorExpress = (props) => {
  const [emails, setEmails] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [remainAddCount, setRemainAddCount] = useState(1);

  const token = useSelector((state) => state.auth.token);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const language = useSelector((state) => state.params.language);

  const handleClick = () => {
    const tabEmails = emails.match(
      /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g
    );

    const allEmails = emails.split(/\r\n|\r|\n/);

    if (!props.noLimit && allEmails.length > remainAddCount) {
      toast.error(
        _("collaborator_count_reached").replace("%count%", remainAddCount)
      );
      return null;
    }

    if (tabEmails) {
      setIsSaving(true);
      addUserExpress({
        token,
        emails: tabEmails,
        fiduciaryId: fiduciary.id,
        language,
      })
        .then((resp) => {
          let result = "";
          if (resp.data.data) {
            if (resp.data.data.length === allEmails.length) {
              toast.success(`${resp.data.data.length} ${_("user_added")}`);
              props.afterSave();
            } else if (resp.data.data.length < allEmails.length) {
              toast.info(
                `${resp.data.data.length} ${_("user_added_with_error")}`
              );
              allEmails.forEach((email) => {
                if (!tabEmails.includes(email)) {
                  if (result) {
                    result += "\n";
                  }
                  result += email;
                }
              });
            }
          }
          setEmails(result);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          )
            toast.error(_(e.response.data.errors[0].message));
          else toast.error(_("error"));
        })
        .finally((e) => {
          setIsSaving(false);
        });
    } else {
      toast.error(_("validate_email"));
    }
  };

  useEffect(() => {
    if (fiduciary) {
      setRemainAddCount(
        getRemainAddCollaborator(fiduciary.officeSize, props.nbCollaborators)
      );
    }
  }, [fiduciary, props.nbCollaborators]);

  return (
    <div className={props.addFormClass ? styles.form : ""}>
      <div
        className={`${styles.form_content} ${
          props.addFormClass && styles.form_content_pad
        }`}
      >
        {props.setSideBar && (
          <div
            className={styles.close}
            onClick={() => props.setSideBar("DEFAULT")}
          >
            <i className="icon-ttp-close"></i>
          </div>
        )}
        <div className={styles.titles}>
          <div className={styles.groupTitle}>
            <h4>Ajout Express</h4>
          </div>
          <p>
            Ils seront invités par United Associates et devront compléter leur
            formulaire par eux-même
          </p>
        </div>

        {!props.noLimit && remainAddCount <= 0 ? (
          <div className={styles.upgrade}>
            <h3>
              <i className="icon-ttp-alert-circle"></i> Limite atteinte !
            </h3>
            <p>Vous avez atteint votre limite de nombre de collaborateurs.</p>
            <p>
              Pour pouvoir ajouter de nouveaux collaborateurs vous pouvez
              augmenter la taille de votre entreprise
            </p>
            {props?.page !== "join" && (
              <Button
                variant="send"
                onClick={() => props.setShowUpgradeModal(true)}
              >
                Ajouter
              </Button>
            )}
          </div>
        ) : (
          <FormInput
            name="emails"
            required={true}
            label={_("email_address")}
            autocomplete="off"
            value={emails}
            type="textarea"
            rows="15"
            onChange={(e) => setEmails(e.target.value)}
          />
        )}
      </div>
      <div className={styles.actions}>
        {props.setSideBar ? (
          <Button
            variant="default"
            onClick={() => {
              props.setSideBar("DEFAULT");
            }}
          >
            {_("cancel")}
          </Button>
        ) : (
          <div></div>
        )}
        {(props.noLimit || remainAddCount > 0) && (
          <>
            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button onClick={handleClick}>{_("add")}</Button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default CollaboratorExpress;
