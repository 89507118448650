import { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./MemberShipChoice.module.scss";

import _ from "../../../i18n";

const MemberShipChoice = ({ period, setPeriod, rate }) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const lng = useSelector((state) => state.params.language);

  if (!fiduciary || !rate) {
    return null;
  }

  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const getTotal = () => {
    let total = 0;
    rate.formulasToPurchase.forEach((item) => {
      total += item.annualPrice;
    });

    return total;
  };

  return (
    <div className="grid-container">
      <div className={styles.content}>
        <div className={styles.top}>
          <div>
            <h3>Abonnement United Associates</h3>
            <p>
              L'adhésion au réseau se calcule sur la base de la taille de votre
              cabinet (nombre de collaborateur). Vous disposez d'une simulation
              du prix immédiate.
            </p>
          </div>
          <div className={styles.top_right}>
            <p>{fiduciary.name}</p>
            <p>{fiduciary.address1}</p>
            <p>{fiduciary.zipCode + " " + fiduciary.city}</p>
          </div>
        </div>

        <div className={styles.savings}>
          Pour bénéficier de notre plateforme, vous devez payer:
        </div>

        <div
          className={`${styles.period} ${
            rate.isBeta ? styles.period_center : ""
          }`}
        >
          {rate.formulasToPurchase.map((item) => (
            <div key={item.id} className={styles.period_item}>
              <div className={`${styles.period_item_box}`}>
                <p className={styles.period_name}>
                  {item.membership[nameAttr]}
                </p>
                <p className={styles.formula}>
                  <span>{_("formula")}</span>
                  {item[nameAttr]}
                </p>
                <p className={styles.period_price}>
                  {item.annualPrice}€{" "}
                  {item.price && (
                    <span className={styles.price_barred}>{item.price}</span>
                  )}{" "}
                  <span>HTVA</span> <span>/par an</span>
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className={styles.total}>
          Total à payer: <span>{getTotal()}</span> <small>€/HTVA</small>
        </div>
      </div>
    </div>
  );
};

export default MemberShipChoice;
