import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Select from "react-select";

import { getPack } from "../../api";
import _ from "../../i18n";
import { SELECT_INPUT_STYLES } from "../../config";

import Button from "../common/Button";
import IconYes from "../common/icons/IconYes";
import IconNo from "../common/icons/IconNo";
import IconUsers from "../common/icons/IconUsers";
import styles from "./PackPage.module.scss";

const PackPage = () => {
  const navigate = useNavigate();
  let params = useParams();

  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const descriptionAttr = `description${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const sliderLabelAttr = `sliderLabel${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const sliderTextAttr = `sliderText${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const suffixAttr = `suffix${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [sliderValue, setSliderValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pack, setPack] = useState(null);
  const [options, setOptions] = useState({});
  const [optionsValues, setOptionsValues] = useState({});

  useEffect(() => {
    if (params.packId && token) {
      setIsLoading(true);
      getPack(token, params.packId)
        .then((response) => {
          if (response?.data?.data?.length === 1) {
            let tab = {},
              tabOptionsValues = {};
            const result = response.data.data[0];
            if (result.uaPackFormulas) {
              result?.uaPackFormulas.forEach((formula) => {
                tab[formula.id] = formula.apps;
                formula.apps.forEach((app) => {
                  if (app.type === "SELECT") {
                    // const values = JSON.parse(app.content);
                    // tabOptionsValues[app.appId] = values[0];
                  }
                });
              });
            }
            setOptions(tab);
            setOptionsValues(tabOptionsValues);
            setPack(response.data.data[0]);
            if (response.data.data[0].hasSlider) {
              setSliderValue(parseInt(response.data.data[0].sliderMin));
            }
          }
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [params.packId, token]);

  const handleChangeSlider = (event, newValue) => {
    setSliderValue(newValue);
  };

  const getMonthPrice = (formula) => {
    let totalMonthOptions = 0,
      totalMonthMemberOptions = 0;

    if (options[formula.id].length > 0) {
      const tabSelect = options[formula.id].filter(
        (option) => option.type === "SELECT"
      );
      if (tabSelect.length > 0) {
        tabSelect.forEach((i) => {
          if (optionsValues[i.appId]) {
            totalMonthOptions += parseInt(optionsValues[i.appId].monthPrice);
            totalMonthMemberOptions += parseInt(
              optionsValues[i.appId].monthMemberPrice
            );
          }
        });
      }
    }

    let monthMemberPrice = formula.monthMemberPrice + totalMonthMemberOptions;
    let monthPrice = formula.monthPrice + totalMonthOptions;
    if (pack.hasSlider === 1) {
      if (sliderValue > pack.sliderMin) {
        const step = (sliderValue - pack.sliderMin) / pack.sliderStep;
        monthMemberPrice += step * formula.monthMemberStepPrice;
        monthPrice += step * formula.monthStepPrice;
      }
    }
    return (
      <>
        {monthMemberPrice === 0 ? (
          <div className={styles.price}>{"Gratuit"}</div>
        ) : (
          <div className={styles.price}>
            {monthMemberPrice}
            <span>€ /mois</span>
          </div>
        )}
        <div className={styles.price_member}>
          <span className={styles.barred}>{monthPrice}</span>
          prix membre
        </div>
      </>
    );
  };

  const getYearPrice = (formula) => {
    let totalAnnualOptions = 0,
      totalAnnualMemberOptions = 0;

    if (options[formula.id].length > 0) {
      const tabSelect = options[formula.id].filter(
        (option) => option.type === "SELECT"
      );
      if (tabSelect.length > 0) {
        tabSelect.forEach((i) => {
          if (optionsValues[i.appId]) {
            totalAnnualOptions += parseInt(optionsValues[i.appId].annualPrice);
            totalAnnualMemberOptions += parseInt(
              optionsValues[i.appId].annualMemberPrice
            );
          }
        });
      }
    }

    let annualMemberPrice =
      formula.annualMemberPrice + totalAnnualMemberOptions;
    let annualPrice = formula.annualPrice + totalAnnualOptions;
    if (pack.hasSlider === 1) {
      if (sliderValue > pack.sliderMin) {
        const step = (sliderValue - pack.sliderMin) / pack.sliderStep;
        annualMemberPrice += step * formula.annualMemberStepPrice;
        annualPrice += step * formula.annualStepPrice;
      }
    }
    return (
      <>
        {annualMemberPrice === 0 ? (
          <div className={styles.price}>
            {"Gratuit"}
            <span> pour les membres</span>
          </div>
        ) : (
          <div className={styles.price}>
            {annualMemberPrice}
            <span>€ /an</span>
          </div>
        )}
        <div className={styles.price_member}>
          <span className={styles.barred}>{annualPrice}</span>
          prix membre
        </div>
      </>
    );
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      border: "1px solid #dadde9",
      maxWidth: "100%",
    },
  }));

  const getOptionIcon = (formulaId, appId) => {
    const value = options[formulaId].filter((i) => i.appId === appId);
    if (value.length === 0) {
      return <IconNo />;
    }
    if (value[0].type === "TEXT") {
      return value[0].value + " " + value[0][suffixAttr];
    } else if (value[0].type === "SELECT" && value[0].options) {
      let options = value[0].options.map((i) => {
        return { label: i.value + " " + i[suffixAttr], value: i };
      });
      return (
        <div className={styles.selectBox}>
          <Select
            defaultValue={options[0]}
            options={options}
            styles={SELECT_INPUT_STYLES}
          />
        </div>
      );
    }
    return <IconYes />;
  };

  return (
    <div className={styles.page}>
      {isLoading && <div className="lmask"></div>}
      {!pack ? (
        <p className={styles.no_data}>{_("no_data_found")}</p>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.header_left}>
              <Button
                onClick={() => navigate("/manage-packs")}
                variant="default"
              >
                {_("back")}
              </Button>
              <h1 className={styles.pack_name}>Pack {pack.name}</h1>
            </div>
          </div>

          <div className={styles.sticky}>
            <div className={styles.pack_detail}>
              <div className={styles.pack_info}>
                <div className={styles.pack_info_top}>
                  <h4 className={styles.pack_info_name}>Pack {pack.name}</h4>
                  <span
                    className={`${styles.pack_status} ${
                      styles[pack.status.toLowerCase()]
                    }`}
                  >
                    {_(pack.status.toLowerCase())}
                  </span>
                </div>
                <p>{pack[descriptionAttr]}</p>
              </div>

              <div className={styles.pack_formulas}>
                {pack.hasSlider === 1 && (
                  <div className={styles.pack_formulas_slider}>
                    <div className={styles.pack_formulas_slider_top}>
                      <span>
                        {pack[sliderLabelAttr]}{" "}
                        {pack[sliderTextAttr] && (
                          <Tooltip title={pack[sliderTextAttr]} arrow>
                            <i className="icon-ttp-alert-circle" />
                          </Tooltip>
                        )}
                      </span>
                      <span className={styles.pack_formulas_slider_value}>
                        <IconUsers /> {sliderValue}
                      </span>
                    </div>
                    <Slider
                      aria-label="Temperature"
                      defaultValue={sliderValue}
                      // getAriaValueText={valuetext}
                      // valueLabelDisplay="auto"
                      step={parseInt(pack.sliderStep)}
                      marks
                      min={parseInt(pack.sliderMin)}
                      max={parseInt(pack.sliderMax)}
                      sx={{
                        color: "primary.light",
                      }}
                      onChange={handleChangeSlider}
                    />
                  </div>
                )}

                <div className={styles.pack_formulas_box}>
                  {pack?.uaPackFormulas?.map((formula) => (
                    <div className={styles.formula}>
                      <div className={styles.formula_name}>
                        <span>{_("formula") + " "}</span> {formula.name}
                        <span
                          className={`${styles.formula_status} ${
                            styles[formula.status.toLowerCase()]
                          }`}
                        >
                          {_(formula.status.toLowerCase())}
                        </span>
                      </div>

                      <div className={styles.formula_bottom}>
                        <div
                          className={`${styles.formula_price} ${styles.formula_price_left}`}
                        >
                          <p className={styles.price_label}>Mensuel</p>

                          <div className={styles.price_box}>
                            {getMonthPrice(formula)}
                          </div>
                        </div>

                        <div className={styles.formula_price}>
                          <p className={styles.price_label}>Annuel</p>
                          <div className={styles.price_box}>
                            {getYearPrice(formula)}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.pack_detail_bottom}>
            {pack.uaPackApps.map((app, idx) => (
              <div key={idx}>
                <div
                  className={`${styles.pack_app} ${
                    app.children.length === 0 && styles.pack_app_hover
                  }`}
                >
                  <div
                    className={`${styles.pack_app_left} ${styles.pack_app_name}`}
                  >
                    {app?.uaApplication.icon && (
                      <img
                        width="25"
                        src={`/img/apps/${app.uaApplication.icon}`}
                        alt=""
                      />
                    )}{" "}
                    {app[nameAttr]}
                  </div>
                  {pack.uaPackFormulas.map((formula) =>
                    app.children.length === 0 ? (
                      <div className={styles.pack_app_app}>
                        {getOptionIcon(formula.id, app.id)}
                      </div>
                    ) : (
                      <div className={styles.pack_app_pro}></div>
                    )
                  )}
                </div>

                {app.children.map((category, categoryIdx) => (
                  <div key={categoryIdx} className={styles.pack_app_category}>
                    {app.children.length > 0 && (
                      <div
                        className={`${styles.pack_app} ${
                          category.children.length === 0 &&
                          styles.pack_app_hover
                        }`}
                      >
                        <div
                          className={`${styles.pack_app_left} ${styles.pack_app_cat_left} ${styles.pack_app_fonct}`}
                        >
                          {category[nameAttr]}
                          {category[descriptionAttr] && (
                            <Tooltip title={category[descriptionAttr]} arrow>
                              <i className="icon-ttp-alert-circle" />
                            </Tooltip>
                          )}
                        </div>
                        {pack.uaPackFormulas.map((formula) =>
                          category.children.length === 0 ? (
                            <div className={styles.pack_app_app}>
                              {getOptionIcon(formula.id, category.id)}
                            </div>
                          ) : (
                            <div className={styles.pack_app_pro}></div>
                          )
                        )}
                      </div>
                    )}

                    {category.children?.map((subCategory, subCategoryIdx) => (
                      <div key={subCategoryIdx}>
                        <div
                          className={`${styles.pack_app} ${
                            styles.pack_app_subcat
                          } ${
                            subCategory.children.length === 0 &&
                            styles.pack_app_hover
                          }`}
                        >
                          <div
                            className={`${styles.pack_app_left} ${styles.pack_app_subcat_left} ${styles.pack_app_fonct}`}
                          >
                            {subCategory[nameAttr]}
                            {subCategory[descriptionAttr] && (
                              <Tooltip
                                title={subCategory[descriptionAttr]}
                                arrow
                              >
                                <i className="icon-ttp-alert-circle" />
                              </Tooltip>
                            )}
                          </div>
                          {pack.uaPackFormulas.map((formula) =>
                            subCategory.children.length === 0 ? (
                              <div className={styles.pack_app_app}>
                                {getOptionIcon(formula.id, subCategory.id)}
                              </div>
                            ) : (
                              <div className={styles.pack_app_pro}></div>
                            )
                          )}
                        </div>
                        {subCategory.children.map((option, optionIdx) => (
                          <div
                            key={optionIdx}
                            className={`${styles.pack_app} ${styles.pack_app_hover}`}
                          >
                            <div
                              className={`${styles.pack_app_left} ${styles.pack_app_fonct}`}
                            >
                              <span>{option[nameAttr]}</span>
                              {option[descriptionAttr] && (
                                <Tooltip title={option[descriptionAttr]} arrow>
                                  <i className="icon-ttp-alert-circle" />
                                </Tooltip>
                              )}
                            </div>
                            {pack.uaPackFormulas.map((formula) => {
                              if (!formula.apps) {
                                return null;
                              }
                              return (
                                <div className={styles.pack_app_app}>
                                  {getOptionIcon(formula.id, option.id)}
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div className={`${styles.pack_app} ${styles.pack_app_end}`}>
            <div className={styles.pack_app_left}></div>
            {pack?.uaPackFormulas?.map((formula) => (
              <div className={styles.pack_app_pro}></div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PackPage;
