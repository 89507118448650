import React from "react";

const IconDone = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 96 960 960"
      width="20"
    >
      <path
        d="M395 757.153 240.616 601.769l35.769-35.768L395 683.616l288.615-287.615 35.769 36.768L395 757.153Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconDone;
