import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { Article } from "tamtam-components";

import { getPublicArticles } from "../../api";
import _ from "../../i18n";

import LeftBanner from "./LeftBanner";
import styles from "./PublicPortal.module.scss";
import { TTP_BLOG_URL } from "../../config";
import classNames from "classnames";

const News = ({ community, info, lng, mediaActivated }) => {
  const [showAll, setShowAll] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  let { isLoading, data } = useQuery(
    ["getPublicArticles", token, community],
    async () => {
      if (token && community) {
        let filters = {};
        let scope = [];
        if (info.categories && info.categories.length > 0) {
          filters.categories = info.categories;
        }
        if (info.authorTypes && info.authorTypes.length > 0) {
          filters.authorTypes = info.authorTypes;
        }
        if (info.relevance) {
          filters.relevance = info.relevance;
        }
        if (info.scope) {
          if (info.scope.includes("COLLABORATOR")) {
            if (info.type) {
              if (info.type === "MINE") {
                scope.push("COLLABORATOR");
              } else if (info.type === "BOTH") {
                scope.push("COLLABORATOR");
                scope.push("FID_COLLABORATOR");
              } else {
                scope.push("FID_COLLABORATOR");
              }
            }
          }
          if (info.scope.includes("CLIENT")) {
            if (info.type) {
              if (info.type === "MINE") {
                scope.push("CLIENT");
              } else if (info.type === "BOTH") {
                scope.push("CLIENT");
                scope.push("FID_CLIENT");
              } else {
                scope.push("FID_CLIENT");
              }
            }
          }
        }
        if (scope.length === 0) {
          scope.push("PUBLIC");
        }
        const response = await getPublicArticles({
          token,
          communityId: community.id,
          limit: 6,
          type: info.type ? info.type : null,
          filters,
          scope,
        });

        return response.data.data;
      }
    }
  );

  const defaultDescription = () => {
    switch (lng) {
      case "fr":
        return `Suivez toute l'actualité de ${
          community.abbreviation || community.name
        }, et ne ratez aucune information en suivant nos derniers articles`;
      case "en":
        return `Follow all the news of ${
          community.abbreviation || community.name
        }, and do not miss any information by following our latest articles`;
      case "nl":
        return `Volg al het nieuws van ${
          community.abbreviation || community.name
        } en mis geen enkele informatie door onze nieuwste artikelen te volgen`;
      default:
        break;
    }
  };

  const defaultTitle = () => {
    switch (lng) {
      case "fr":
        return `Publiés par ${community.abbreviation || community.name}`;
      case "en":
        return `Published by ${community.abbreviation || community.name}`;
      case "nl":
        return `Gepubliceerd door ${community.abbreviation || community.name}`;
      default:
        break;
    }
  };

  if (isLoading || !data || data.length === 0) {
    return null;
  }

  return (
    <section
      id="fiduciary-news"
      className={classNames(
        styles.fiduciaryNews,
        mediaActivated ? styles.fiduciaryNews_noMargin : ""
      )}
    >
      <div className="flex-container">
        <LeftBanner
          type={_("News")}
          title={info[titleAttr] ? info[titleAttr] : defaultTitle()}
          text={
            info[descriptionAttr] ? info[descriptionAttr] : defaultDescription()
          }
        />
        <div className={styles.newsList}>
          <div className={`${styles.articlesList} grid-x`}>
            {data.slice(0, showAll ? data.length : 5).map((article, i) => (
              <div
                key={i}
                className={`cell ${
                  i % 5 === 0 ? "large-8" : "large-4 "
                } medium-6 small-12`}
              >
                <Article
                  article={article}
                  showSummary={true}
                  type="type7"
                  isFetching={false}
                  size={i === 0 ? "large" : "default"}
                  // onEdit={() => console.log("edit")}
                  // onLike={() => console.log("like")}
                  // user={article.author ? article.author[0].id : null}
                  host={TTP_BLOG_URL}
                  targetBlank={true}
                />
              </div>
            ))}
          </div>
          {data.length > 6 && (
            <div className={styles.viewMore}>
              <hr />
              <button
                onClick={() => {
                  if (showAll) {
                    window.open(
                      "https://blog.tamtam.pro/fr/community/deg-and-partners-consulting-company/4"
                    );
                  } else {
                    setShowAll(true);
                  }
                }}
              >
                View More <i className="icon-ttp-chevron-down " />
              </button>
              <hr />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default News;
