import React from "react";
import classNames from "classnames";
import { SortableContainer } from "react-sortable-hoc";

import styles from "./Faq.module.scss";
import SortableFaq from "./SortableFaq";

export default SortableContainer(({ faqs, onDelete, onEdit, onHide }) => {
  let faqsJsx = faqs.map((faq, index) => {
    return (
      <SortableFaq
        faq={faq}
        key={faq.id}
        index={index}
        onDelete={onDelete}
        onEdit={onEdit}
        onHide={onHide}
      />
    );
  });

  return <div className={classNames(styles.list)}>{faqsJsx}</div>;
});
