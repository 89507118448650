import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { getHomePacks } from "../../api";
import LeftBanner from "../Settings/LeftBanner";
import TabLink from "../Settings/TabLink";
import Pack from "./Pack";

import styles from "../Settings/Settings.module.scss";
import myStyles from "./MyPacks.module.scss";

const MyPacks = () => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const lng = useSelector((state) => state.params.language);

  const [packs, setPacks] = useState([]);
  const [myPacks, setMyPacks] = useState([]);
  const [billingPeriod, setBillingPeriod] = useState("MONTHLY");

  let listPacks = useQuery(["listPacks", token], async () => {
    if (token) {
      try {
        const response = await getHomePacks(token);
        if (response?.data?.data) {
          const data = [...response.data.data];
          data.forEach((row) => {
            row.sliderValue = row.sliderMin;
          });

          const tab = [];
          const purchasedPackIds = [];
          if (fiduciary?.uaPremiumOrganization?.packs) {
            fiduciary.uaPremiumOrganization.packs.forEach((item) => {
              const pack = data.filter((i) => i.id === item.uaPack);
              if (pack) {
                const formulas = pack[0].uaPackFormulas.filter(
                  (i) => i.id === item.uaPackFormula
                );
                purchasedPackIds.push(pack[0].id);
                tab.push({
                  ...pack[0],
                  uaPackFormulas: formulas,
                });
              }
            });
          }

          setMyPacks(tab);

          setPacks(data.filter((i) => !purchasedPackIds.includes(i.id)));
        }
        return response.data.data;
      } catch (e) {
        console.log(e);

        return null;
      }
    }
  });

  return (
    <div
      className={classNames(styles.page, "grid-container")}
      style={{ maxWidth: "90%" }}
    >
      <div
        className="grid-x grid-margin-x"
        style={{ justifyContent: "flex-end" }}
      >
        <div className={"cell small-12 medium-6 large-3"}>
          <LeftBanner />
        </div>
        <div className="cell small-12 medium-6 large-8">
          <TabLink />

          <div className={styles.content}>
            {listPacks.isFetching ? (
              <div className="grid-container">
                <div className="grid-x grid-margin-x grid-margin-y">
                  {[...Array(3).keys()].map((i) => (
                    <div className="cell small-12 medium-6 large-4" key={i}>
                      <Skeleton height={300} width="100%" />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <>
                <h3 style={{ marginBottom: "1rem" }}>Mes packs</h3>
                <div className="grid-x grid-margin-x grid-margin-y">
                  {myPacks.map((pack) => (
                    <div
                      className="cell small-12 medium-12 large-6"
                      key={pack.key}
                    >
                      <Pack
                        offerType={
                          fiduciary?.uaPremiumOrganization?.isMember
                            ? "member"
                            : ""
                        }
                        pack={pack}
                        onSelect={(pack) => {
                          window.open(
                            "/packs/" + pack.id,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        lng={lng}
                        billingPeriod={
                          fiduciary?.uaPremiumOrganization?.frequency
                            ? fiduciary.uaPremiumOrganization.frequency
                            : "YEARLY"
                        }
                        // setSliderValue={setSliderValue}
                      />
                    </div>
                  ))}
                </div>

                <div className={myStyles.otherPacks}>
                  <h3 style={{ marginBottom: "1rem" }}>
                    Autres packs disponibles
                  </h3>
                  <div className="grid-x grid-margin-x grid-margin-y">
                    {packs.map((pack) => (
                      <div
                        className="cell small-12 medium-12 large-6"
                        key={pack.key}
                      >
                        <Pack
                          offerType={
                            fiduciary?.uaPremiumOrganization?.isMember
                              ? "member"
                              : ""
                          }
                          pack={pack}
                          onSelect={(pack) => {
                            window.open(
                              "/packs/" + pack.id,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                          lng={lng}
                          billingPeriod={billingPeriod}
                          // setSliderValue={setSliderValue}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPacks;
