import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { HeaderUA } from "tamtam-components";
import $ from "jquery";

import {
  setAuthToken,
  fetchAuthUser,
  setExpiresIn,
  setTokenCreatedAt,
  setCurrentPortal,
  setAuthFetched,
} from "../../store";
import { getAuthInfosFromCookie, authHasExpired, logout } from "../../utils";
import { getPublicClientCredential, getPublicOrganization } from "../../api";
import _ from "../../i18n";
import { TTP_ONBOARDING_URL, APP_ENV } from "../../config";

import Infos from "./Infos";
import Team from "./Team";
import News from "./News";

import Contact from "./Contact";
import styles from "./PublicPortal.module.scss";
import Footer from "../Layout/Footer";
import Specialty from "./Specialty";
import Subheader from "./Subheader";

const Home = () => {
  const auth = useSelector((state) => state.auth);
  const privacyRubrics = useSelector((state) => state.privacy.items);
  const { token, fetched, loggedAs, loggedAsPost } = auth;
  const lng = useSelector((state) => state.params.language);
  const currentPortal = useSelector((state) => state.params.currentPortal);
  const [portalData, setPortalData] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [portalSwitch, setPortalSwitch] = useState(null);
  const [currentAuth, setCurrentAuth] = useState({
    navCommunity: null,
    user: null,
    token: null,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(async () => {
    const authInfos = getAuthInfosFromCookie();

    if (authInfos && !authHasExpired(authInfos)) {
      dispatch(setAuthToken(authInfos.token));
      dispatch(setExpiresIn(authInfos.expiresIn));
      dispatch(setTokenCreatedAt(authInfos.createdAt));
      dispatch(fetchAuthUser(authInfos));
    } else {
      const resp = await getPublicClientCredential();
      const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
      const token = helpResp.token.access_token;
      dispatch(setAuthToken(token));
      dispatch(setAuthFetched(true));
    }
  }, []);

  const hideLoader = () => {
    $("#app-loader")
      .fadeOut()
      .promise()
      .done(() => $("#app-loader").remove());
  };

  useEffect(() => {
    if (token && fetched) {
      let subdomain = window.location.host;
      if (window.location.pathname.indexOf("/public/") !== -1) {
        subdomain += window.location.pathname;
        if (subdomain.charAt(subdomain.length - 1) === "/") {
          subdomain = subdomain.substring(0, subdomain.length - 1);
        }
      }
      getPublicOrganization(token, subdomain)
        .then((response) => {
          if (response.data.data) {
            const data = response.data.data;

            if (
              data.hubPreferences &&
              data.hubPreferences.portals &&
              data.hubPreferences?.portals.public.enabled
            ) {
              setPortalData(data.hubPreferences.portals.public.config);
              setOrganization(data.organization);

              let authInfos = getAuthInfosFromCookie();
              if (authInfos && !authHasExpired(authInfos)) {
                if (authInfos) {
                  setCurrentAuth({
                    navCommunity: null,
                    user: authInfos.user,
                    token: authInfos.token,
                  });
                }

                let tab = {
                  items: [],
                  onChange: (e) => {
                    dispatch(setCurrentPortal(e.key));
                    if (e.key === "PUBLIC") {
                      window.location.href = "http://" + e.url;
                    } else {
                      navigate(e.url);
                    }
                  },
                };

                if (loggedAs !== "CLIENT") {
                  if (
                    ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(loggedAsPost)
                  ) {
                    tab.items.push({
                      key: "SETTINGS",
                      label: "Configuration",
                      url: "/settings",
                    });
                  }
                  if (
                    data.hubPreferences.portals.public.enabled &&
                    data.subdomain
                  ) {
                    tab.items.push({
                      key: "PUBLIC",
                      label: "Portail public",
                      url: data.subdomain,
                    });
                    dispatch(setCurrentPortal("PUBLIC"));
                  }
                  if (data.hubPreferences && data.hubPreferences.portals) {
                    if (data.hubPreferences.portals.collaborator.enabled) {
                      tab.items.push({
                        key: "COLLABORATOR",
                        label: "Portail collaborateur",
                        url: "/collaborator",
                      });
                    }
                    // if (
                    //   ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(
                    //     loggedAsPost
                    //   ) &&
                    //   data.hubPreferences.portals.client.enabled
                    // ) {
                    //   tab.items.push({
                    //     key: "CLIENT",
                    //     label: "Portail client",
                    //     url: "/client",
                    //   });
                    // }
                  }
                } else {
                  // tab.items.push({
                  //   key: "CLIENT",
                  //   label: "Portail client",
                  //   url: "/client",
                  // });
                }
                setPortalSwitch(tab);
              }

              hideLoader();
            } else {
              handleNoPublicPortalFound();
            }
          }
        })
        .catch((e) => {
          handleNoPublicPortalFound();
        });
    }
  }, [token, fetched]);

  const handleNoPublicPortalFound = () => {
    let authInfos = getAuthInfosFromCookie();
    if (!authInfos || authHasExpired(authInfos)) {
      logout();
    } else {
      loggedAs === "CLIENT" ? navigate("/client") : navigate("/collaborator");
    }
    hideLoader();
  };

  const changeLng = (language) => {};

  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const policy = [];
  const cookies = [];

  let privacyRubs = [];
  if (privacyRubrics.length > 0) {
    if (
      privacyRubrics.filter((el) => el.termsOfUse).length > 0 &&
      privacyRubrics
        .filter((el) => el.termsOfUse)[0]
        .versions.filter((ver) => ver.name === "UNITED_ASSOCIATES").length > 0
    ) {
      policy.push({
        label: "terms_of_use",
        url: "/privacy/terms_of_use",
      });
    }

    privacyRubrics.forEach((el) => {
      if (el.isCookie) {
        if (
          el.versions &&
          el.showInFooter &&
          el.versions[0].status === "PUBLISHED"
        ) {
          cookies.push({
            label:
              el.versions[0][titleAttr].length > 25
                ? el.versions[0][titleAttr].substring(0, 25) + "..."
                : el.versions[0][titleAttr],
            url: `/privacy/cookies?rub=${el.id}`,
          });
        } else if (!el.versions) {
          cookies.push({
            label: _("manage_cookies"),
            url: `/privacy/cookies`,
          });
        }
      }
      if (
        el.showInFooter &&
        el.versions[0].status === "PUBLISHED" &&
        !el.isCookie &&
        !el.termsOfUse
      ) {
        privacyRubs.push({
          label:
            el.versions[0][titleAttr].length > 25
              ? el.versions[0][titleAttr].substring(0, 25) + "..."
              : el.versions[0][titleAttr],
          url: `/privacy?rub=${el.id}`,
        });
      }
    });
  }

  if (!organization) {
    return <div className={styles.portal} />;
  }

  const AppData = {
    appName: "United Associates",
    appLogoUrl: "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
    appUrl: "/",
  };

  const rightIcons = {
    home: {
      activated: false,
      url: TTP_ONBOARDING_URL,
    },
    profile: {
      activated: false,
      url: "/profile",
    },
    ebox: {
      activated: false,
    },
    search: {
      activated: false,
    },
    notifs: {
      activated: false,
    },
    backoffice: {
      activated: false,
    },
    faq: {
      activated: false,
    },
    apps: {
      activated: false,
    },
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <>
      {currentAuth.token ? (
        <HeaderUA
          rightIcons={rightIcons}
          app={AppData}
          auth={{
            navCommunity: auth.navCommunity,
            user: auth.user,
            token: auth.token,
          }}
          settings={[]}
          lng={lng}
          languages={[]}
          env={APP_ENV}
          onLanguageChange={(langue) => changeLng(langue)}
          onLogoutClick={(e) => handleLogout(e)}
          portalSwitch={portalSwitch}
          currentPortal={currentPortal}
          RouterLink={Link}
          firstList={privacyRubs.length > 0 ? privacyRubs : null}
          secondList={cookies.length > 0 ? cookies : null}
          thirdList={policy.length > 0 ? policy : null}
          navigateTo={(url) => {
            if (
              url.includes("privacy") ||
              url.includes("/client") ||
              url.includes("/collaborator") ||
              url.includes("/settings")
            ) {
              navigate(url);
            } else {
              window.location.href = "http://" + url;
            }
          }}
        />
      ) : (
        <HeaderUA
          auth={{
            navCommunity: null,
            user: null,
            token: null,
          }}
          lng={lng}
          languages={[]}
          app={AppData}
          env={APP_ENV}
          RouterLink={Link}
          signInUrl="/login"
          signUpUrl="/register"
        />
      )}
      <div className={styles.portal}>
        {portalData.media.activated ? (
          <Infos
            community={organization}
            page={portalData.contact}
            info={portalData.media}
            blocs={portalData}
          />
        ) : (
          <div className={styles.subHeader_container}>
            <Subheader blocs={portalData} community={organization} />
          </div>
        )}
        <div className={styles.portalContent}>
          {portalData.news.activated && (
            <News
              community={organization}
              info={portalData.news}
              lng={lng}
              mediaActivated={!portalData.media.activated}
            />
          )}
          {portalData.specialty && portalData.specialty.activated && (
            <Specialty
              community={organization}
              info={portalData.specialty}
              lng={lng}
            />
          )}
          {portalData.team.activated && (
            <Team community={organization} info={portalData.team} lng={lng} />
          )}
          {/* {portalData.services.activated &&
          portalData.services.list.length > 0 && (
            <Services
              community={organization}
              services={portalData.services.list}
              info={portalData.services}
              lng={lng}
            />
          )} */}
          {portalData.contact.activated && (
            <Contact page={portalData.contact} community={organization} />
          )}
        </div>
      </div>
      <Footer publicPortalSwitch={portalSwitch && portalSwitch.items} />
    </>
  );
};

export default Home;
