import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import classNames from "classnames";
import { toast } from "react-toastify";
import { arrayMoveImmutable } from "array-move";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";

import {
  ALLOWED_APPS_OPTIONS,
  DEFAULT_APPS,
  DEFAULT_TABS,
  ALLOWED_TABS_OPTIONS,
} from "../../config";
import _ from "../../i18n";
import { saveUaSettings } from "../../api";

import Loader from "../common/Loader";
import Switch from "../common/Switch/Switch";
import DisabledSwitch from "../common/Switch/Switch/DisabledSwitch";
import Button from "../common/Button";
import styles from "./ConfigModal.module.scss";

const DragHandle = sortableHandle(() => (
  <span className={styles.dragHandler}>
    <i className="icon-ttp-drag"></i>
  </span>
));

const ConfigModal = ({
  modalOpen,
  setModalOpen,
  currentItem,
  setCurrentItem,
  refreshList,
}) => {
  const auth = useSelector((state) => state.auth);
  const [isSaving, setIsSaving] = useState(false);
  const [appsOptions, setAppsOptions] = useState(ALLOWED_APPS_OPTIONS);
  const [allowedApps, setAllowedApps] = useState(DEFAULT_APPS);
  const [currentConfig, setCurrentConfig] = useState(null);
  const [title, setTitle] = useState("");
  const [tabsOptions, setTabsOptions] = useState(ALLOWED_TABS_OPTIONS);
  const [allowedTabs, setAllowedTabs] = useState(DEFAULT_TABS);

  useEffect(() => {
    if (currentItem && modalOpen) {
      if (currentItem.title) {
        setTitle(currentItem.title);
      }
      if (currentItem.allowedApps) {
        setAllowedApps(currentItem.allowedApps);

        let remainApps = [...appsOptions];
        let c = [];
        currentItem.allowedApps.forEach((i) => {
          remainApps = remainApps.filter((j) => j.value !== i);
          const row = appsOptions.find((j) => j.value === i);
          if (row) {
            c.push(row);
          }
        });

        if (remainApps.length > 0) {
          c = [...c, ...remainApps];
        }
        setAppsOptions(c);
      } else {
        setAllowedApps(DEFAULT_APPS);
        setAppsOptions(ALLOWED_APPS_OPTIONS);
      }
      if (currentItem.allowedTabs) {
        setAllowedTabs(currentItem.allowedTabs);

        let remainTabs = [...tabsOptions];
        let c = [];
        currentItem.allowedTabs.forEach((i) => {
          remainTabs = remainTabs.filter((j) => j.value !== i);
          const row = tabsOptions.find((j) => j.value === i);
          if (row) {
            c.push(row);
          }
        });

        if (remainTabs.length > 0) {
          c = [...c, ...remainTabs];
        }
        setTabsOptions(c);
      } else {
        setAllowedTabs(DEFAULT_TABS);
        setTabsOptions(ALLOWED_TABS_OPTIONS);
      }
      setCurrentConfig(currentItem);
    }
  }, [currentItem, modalOpen]);

  const handleSave = async () => {
    let data = {
      token: auth.token,
      title,
      allowedApps,
      allowedTabs,
    };
    if (currentConfig) {
      data.id = currentConfig.id;
    }
    setIsSaving(true);

    saveUaSettings(data)
      .then((resp) => {
        setIsSaving(false);
        if (resp.data.result === "OK") {
          refreshList();
          initValues();
          toast.success(_("successfully_saved"));
          setModalOpen(false);
        }
      })
      .catch((e) => {
        setIsSaving(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const initValues = () => {
    setCurrentItem(null);
    setAppsOptions(ALLOWED_APPS_OPTIONS);
    setAllowedApps(DEFAULT_APPS);
    setTabsOptions(ALLOWED_TABS_OPTIONS);
    setAllowedTabs(DEFAULT_TABS);
    setTitle("");
    setCurrentConfig(null);
  };

  const handleCheckTab = (value, item) => {
    if (value) {
      setAllowedTabs([...allowedTabs, item.value]);
    } else {
      const tab = allowedTabs.filter((i) => i !== item.value);
      setAllowedTabs(tab);
    }
  };

  const handleCheckApp = (value, item) => {
    if (value) {
      setAllowedApps([...allowedApps, item.value]);
    } else {
      const tab = allowedApps.filter((i) => i !== item.value);
      setAllowedApps(tab);
    }
  };

  const appsOnSortEnd = ({ oldIndex, newIndex }) => {
    let tabOptions = arrayMoveImmutable(appsOptions, oldIndex, newIndex);
    setAppsOptions(tabOptions);
    let tab = arrayMoveImmutable(allowedApps, oldIndex, newIndex);
    setAllowedApps(tab);
  };

  const SortableItem = sortableElement(({ value }) => (
    <li className={classNames(styles.appsItem, styles.appsItem_header)}>
      <div className={styles.appsItem_top}>
        <DragHandle />
        <img src={`img/apps/${value.icon}`} alt="" />
        {value.name}
      </div>
      {DEFAULT_APPS.includes(value.value) ? (
        <DisabledSwitch
          isChecked={allowedApps.includes(value.value)}
          onChange={(e) => handleCheckApp(e, value)}
        />
      ) : (
        <Switch
          isChecked={allowedApps.includes(value.value)}
          onChange={(e) => handleCheckApp(e, value)}
        />
      )}
    </li>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return <ul>{children}</ul>;
  });

  if (!modalOpen) {
    return null;
  }

  return (
    <Modal
      width="60vw"
      height="40vh"
      closable={false}
      visible={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        initValues();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>Configuration</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          initValues();
          setModalOpen(false);
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>

      <div className={styles.content}>
        <div className={"grid-x grid-margin-x grid-margin-y"}>
          <div className="cell small-12 medium-12 large-6">
            <div className="ttp-form-group">
              <label className="ttp-label">Titre</label>
              <input
                type="text"
                value={title}
                className="ttp-input-lg"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div key="tabTab" label="Tabs">
              <label className="ttp-label">Activer/désactiver les Tabs :</label>
              <ul>
                {tabsOptions.map((value, index) => (
                  <li
                    key={`tab-${index}`}
                    className={classNames(
                      styles.appsItem,
                      styles.appsItem_header,
                      styles.appsItem_tabs
                    )}
                  >
                    <div className={styles.appsItem_top}>{value.name}</div>
                    <Switch
                      isChecked={allowedTabs.includes(value.value)}
                      onChange={(e) => handleCheckTab(e, value)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="cell small-12 medium-12 large-6">
            <label className="ttp-label">
              Activer/désactiver ou Modifier l’orde des applications :
            </label>
            <SortableContainer
              onSortEnd={(e) => appsOnSortEnd(e)}
              useDragHandle
            >
              {appsOptions.map((value, index) => (
                <SortableItem
                  key={`item-${value.value}`}
                  index={index}
                  value={value}
                  type="collaborator"
                />
              ))}
            </SortableContainer>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("save")}</Button>
        )}
      </div>
    </Modal>
  );
};

export default ConfigModal;
