import React, { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import $ from "jquery";

import _ from "../../i18n";
import {
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  fetchAuthUser,
  setLanguage,
  fetchFiduciary,
  setFolderFetched,
} from "../../store";
import {
  getAuthInfosFromCookie,
  authHasExpired,
  getCookie,
  setCookie,
  logout,
} from "../../utils";
import { APP_ENV } from "../../config";

import Menu from "./Menu";

const Admin = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAuthUser = (user) => {
    dispatch(setAuthToken(user.token));
    dispatch(fetchAuthUser(user));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(fetchFiduciary());
  };

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          dispatch(setAuthUser(null));
          logout(false);
          navigate("/login");
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    let authInfos = getAuthInfosFromCookie();

    if (!authInfos || authHasExpired(authInfos)) {
      dispatch(setAuthUser(null));

      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

      setCookie(
        "ttp_auth_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
      setCookie(
        "ttp_community_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
    }

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos) {
      handleAuthUser(authInfos);
    } else {
      dispatch(setFolderFetched(true));
    }

    // if (!auth.user) {
    $("#app-loader")
      .fadeOut()
      .promise()
      .done(() => $("#app-loader").remove());
    // }
  }, []);

  return (
    <div>
      <Menu />
      <Outlet />
    </div>
  );
};

export default Admin;
