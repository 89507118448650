import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getFiduciary } from "../api";
import { getCommunityFromCookie } from "../utils";

const initialState = {
  fetching: false,
  fetched: false,
  fiduciary: null,
  freeCycle: false,
};

export const fetchFiduciary = createAsyncThunk(
  "folder/fetchFiduciary",
  async (tmp, { getState, dispatch }) => {
    let { token, user } = getState().auth;
    if (!token) {
      return false;
    }
    if (!user) {
      await new Promise((r) => setTimeout(r, 1000));
      user = getState().auth.user;
    }
    const community = getCommunityFromCookie();

    const response = await getFiduciary({
      token,
      fiduciaryId: community ? community.id : null,
      userId: user ? user.id : null,
    });

    if (response.data.data.length === 1) {
      return response.data.data[0];
    } else {
      let folder = null;
      response.data.data.forEach((item) => {
        if (!folder || folder?.uaStatus !== "VALIDATED") {
          if (!folder) {
            folder = item;
          } else {
            if (item.uaStatus === "VALIDATED") {
              folder = item;
            } else if (
              item.uaStatus === "PENDING" &&
              folder.uaStatus === "CREATED"
            ) {
              folder = item;
            }
          }
        }
      });

      return folder;
    }
  }
);

const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    setFreeCycle: (state, { payload }) => {
      state.freeCycle = payload;
    },
    setFiduciary: (state, { payload }) => {
      state.fiduciary = payload;
    },
    setFolderFetched: (state, { payload }) => {
      state.fetched = payload;
    },
    setOnBoardingStep: (state, { payload }) => {
      if (state.fiduciary) {
        state.fiduciary.onBoardingStep = payload;
      }
    },
    setPortalSubDomain: (state, { payload }) => {
      if (state.fiduciary) {
        state.fiduciary.subdomain = payload;
      }
    },
    setHubPreferencesPortals: (state, { payload }) => {
      if (state.fiduciary) {
        if (state.fiduciary.hubPreferences) {
          state.fiduciary.hubPreferences = {
            ...state.fiduciary.hubPreferences,
            portals: payload,
          };
        } else {
          state.fiduciary.hubPreferences = { portals: payload };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFiduciary.fulfilled, (state, action) => {
      state.fetched = true;
      state.fiduciary = action.payload;
    });
    builder.addCase(fetchFiduciary.rejected, (state, action) => {
      state.fetched = true;
    });
  },
});
export const {
  setFreeCycle,
  setFiduciary,
  setFolderFetched,
  setOnBoardingStep,
  setPortalSubDomain,
  setHubPreferencesPortals,
} = folderSlice.actions;

export default folderSlice.reducer;
