import React from "react";
import classNames from "classnames";

import styles from "./News.module.scss";

import ArticleCard from "./ArticleCard";

const ArticleList = ({ articles, lng }) => {
  if (!articles || articles.length === 0) return null;
  return (
    <>
      <div
        className={classNames(
          articles.length < 3 ? styles.list : styles.list_grid
        )}
      >
        {articles.map((article, i) => (
          <div key={article.id} className={classNames(styles.cardCont)}>
            <ArticleCard article={article} lng={lng} />
          </div>
        ))}
      </div>
    </>
  );
};

export default ArticleList;
