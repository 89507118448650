import _ from "../../i18n";

import styles from "./LeftSide.module.scss";

const IconCheck = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18.1091" height="18" rx="9" fill="white" />
    </svg>
  );
};

const LeftSide = () => {
  return (
    <>
      <img src="/img/logo-white.png" width="180" />
      <p className={styles.title}>
        United Associates est un réseau technologique à l’initiative du Forum
        For the Future.
      </p>
      <p>
        Moyennant un abonnement forfaitaire en fonction de la taille de votre
        cabinet, United Associates vous permet d'obtenir un socle numérique et
        vous aide concrètement dans votre transformation digitale.
      </p>
      <p className={styles.space}>Les fondements de notre démarche :</p>
      <ul className={styles.list}>
        <li>
          <i className="icon-ttp-check"></i>
          Offrir un portail pour vos collaborateurs et vos clients
        </li>
        <li>
          <i className="icon-ttp-check"></i> Faciliter la communication et
          l'échange
        </li>
        <li>
          <i className="icon-ttp-check"></i> Assembler vos choix technologiques
          librement
        </li>
        <li>
          <i className="icon-ttp-check"></i> Partager plus facilement la
          connaissance
        </li>
        <li>
          <i className="icon-ttp-check"></i> Encourager la formation{" "}
        </li>
        <li>
          <i className="icon-ttp-check"></i> Matérialiser la confraternité
        </li>
      </ul>
      <p className={styles.footer}>
        <p>Powered by tamtam International</p>
        <p>Le socle technologique est développé par Tamtam international.</p>
      </p>
    </>
  );
};

export default LeftSide;
