import React from "react";

const IconUsers = ({ size = 20 }) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.976311 11.0394C1.60143 10.4589 2.44928 10.1328 3.33333 10.1328H8.66667C9.55072 10.1328 10.3986 10.4589 11.0237 11.0394C11.6488 11.6199 12 12.4071 12 13.2281V14.4661C12 14.808 11.7015 15.0852 11.3333 15.0852C10.9651 15.0852 10.6667 14.808 10.6667 14.4661V13.2281C10.6667 12.7355 10.456 12.2631 10.0809 11.9149C9.70581 11.5666 9.1971 11.3709 8.66667 11.3709H3.33333C2.8029 11.3709 2.29419 11.5666 1.91912 11.9149C1.54405 12.2631 1.33333 12.7355 1.33333 13.2281V14.4661C1.33333 14.808 1.03486 15.0852 0.666667 15.0852C0.298477 15.0852 0 14.808 0 14.4661V13.2281C0 12.4071 0.35119 11.6199 0.976311 11.0394Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.9974 3.94122C4.89283 3.94122 3.9974 4.77269 3.9974 5.79836C3.9974 6.82403 4.89283 7.65551 5.9974 7.65551C7.10197 7.65551 7.9974 6.82403 7.9974 5.79836C7.9974 4.77269 7.10197 3.94122 5.9974 3.94122ZM2.66406 5.79836C2.66406 4.08891 4.15645 2.70312 5.9974 2.70312C7.83835 2.70312 9.33073 4.08891 9.33073 5.79836C9.33073 7.50782 7.83835 8.8936 5.9974 8.8936C4.15645 8.8936 2.66406 7.50782 2.66406 5.79836Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.6854 10.6754C12.7774 10.3443 13.1411 10.1453 13.4976 10.2307C14.2127 10.4022 14.8462 10.7893 15.2987 11.3313C15.7512 11.8734 15.997 12.5396 15.9976 13.2254L15.9976 14.4639C15.9976 14.8058 15.6991 15.083 15.3309 15.083C14.9627 15.083 14.6642 14.8058 14.6642 14.4639L14.6642 13.2263C14.6642 13.2262 14.6642 13.2264 14.6642 13.2263C14.6638 12.8149 14.5164 12.415 14.2449 12.0899C13.9734 11.7647 13.5933 11.5324 13.1642 11.4295C12.8077 11.3441 12.5933 11.0064 12.6854 10.6754Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.021 3.2469C10.1123 2.91569 10.4755 2.71594 10.8322 2.80074C11.5492 2.97122 12.1847 3.35843 12.6386 3.90134C13.0924 4.44425 13.3387 5.11198 13.3387 5.79926C13.3387 6.48653 13.0924 7.15426 12.6386 7.69717C12.1847 8.24008 11.5492 8.6273 10.8322 8.79777C10.4755 8.88257 10.1123 8.68282 10.021 8.35161C9.92967 8.02041 10.1448 7.68317 10.5015 7.59836C10.9317 7.49608 11.313 7.26375 11.5853 6.938C11.8576 6.61226 12.0054 6.21162 12.0054 5.79926C12.0054 5.38689 11.8576 4.98625 11.5853 4.66051C11.313 4.33476 10.9317 4.10243 10.5015 4.00015C10.1448 3.91535 9.92967 3.57811 10.021 3.2469Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconUsers;
