import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";

import styles from "./Specialties.module.scss";

const Specialties = ({ data, types, folderData }) => {
  const lng = useSelector((state) => state.params.language);
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const listSpecs = () => {
    const specs = folderData && folderData.list ? folderData.list : [];
    const favorites =
      folderData && folderData.favorites ? folderData.favorites : [];

    const allSpecialties = types
      .map((type) => {
        return type.specialties
          ? type.specialties.map((sp) => {
              return {
                ...sp,
                type: {
                  id: type.id,
                  hasChoices: type.hasChoices,
                  nameEn: type.nameEn,
                  nameFr: type.nameFr,
                  nameNl: type.nameNl,
                },
              };
            })
          : [];
      })
      .flat(1);
    if (favorites.length > 0) {
      const sorted = [];

      allSpecialties.forEach((spec) => {
        const subSpecs = spec.specialties
          ? spec.specialties.filter((subSpec) => specs.includes(subSpec.id))
          : [];
        if (subSpecs.length > 0 && !spec.type.hasChoices) {
          sorted.push({
            ...spec,
            hasFavorites:
              subSpecs.filter((el) => favorites.includes(el.id)).length > 0,
            specialties: subSpecs.map((el) => {
              if (favorites.includes(el.id)) {
                return { ...el, isFavorite: true };
              }
              return { ...el, isFavorite: false };
            }),
          });
        }
        if (spec.type.hasChoices && specs.includes(spec.id)) {
          sorted.push({
            ...spec,
            nameFr: spec.type.nameFr,
            nameEn: spec.type.nameEn,
            nameNl: spec.type.nameNl,
            hasFavorites: true,
            specialties: [{ ...spec, isFavorite: true }],
          });
        }
      });

      return [
        ...sorted.filter((el) => el.hasFavorites),
        ...sorted.filter((el) => !el.hasFavorites),
      ];
    }

    const sorted = [];

    allSpecialties.forEach((spec) => {
      const subSpecs = spec.specialties
        ? spec.specialties.filter((subSpec) => specs.includes(subSpec.id))
        : [];
      if (subSpecs.length > 0 && !spec.type.hasChoices) {
        sorted.push({
          ...spec,
          specialties: subSpecs.map((el) => {
            return { ...el, isFavorite: false };
          }),
        });
      }
      if (spec.type.hasChoices && specs.includes(spec.id)) {
        sorted.push({
          ...spec,
          nameFr: spec.type.nameFr,
          nameEn: spec.type.nameEn,
          nameNl: spec.type.nameNl,
          hasFavorites: true,
          specialties: [{ ...spec, isFavorite: true }],
        });
      }
    });
    return sorted;
  };

  // if (listSpecs().length === 0) {
  //   return null;
  // }
  const specs = listSpecs();
  return (
    <div className={styles.expertise_section} id="expertise_section">
      <div className={styles.left}>
        <div className={styles.expertise_title}>{"Expertise"}</div>
        <div className={styles.title}>
          {data[titleAttr] ? data[titleAttr] : "Lorem ipsum dolor sit amet"}
        </div>
        <div className={styles.description}>
          {data[descriptionAttr]
            ? data[descriptionAttr]
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
        </div>
      </div>
      <div className={classNames(styles.right, "grid-x")}>
        {specs.length < 1
          ? [1, 2, 3, 4].map((s, i) => (
              <div
                key={i}
                className={classNames(
                  styles.cardCont,
                  "cell small-12 medium-6"
                )}
              >
                <div className={styles.specialty_card}>
                  <div className={styles.title}>
                    {"Excepteur sint occaecat"}
                  </div>
                  <ul className={styles.list}>
                    <li className={styles.favorite}>
                      {"consectetur adipiscing"}
                    </li>
                    <li>{"consectetur adipiscing"}</li>
                    <li>{"consectetur adipiscing"}</li>
                  </ul>
                </div>
              </div>
            ))
          : specs.map((specialty) => (
              <div
                key={specialty.id}
                className={classNames(
                  styles.cardCont,
                  "cell small-12 medium-6"
                )}
              >
                <div className={styles.specialty_card}>
                  {/* <div className={styles.icon}>
                    {specialty.icon && (
                      <i className={`icon webtool-${specialty.icon.label}`} />
                    )}
                  </div> */}
                  <div className={styles.title}>{specialty[nameAttr]}</div>
                  <ul className={styles.list}>
                    {specialty.specialties.map((spec) => (
                      <li
                        key={"e"}
                        className={spec.isFavorite ? styles.favorite : ""}
                      >
                        {spec[nameAttr]}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

export default Specialties;
