import React from "react";

const IconBackArrow = () => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.259444 6.71295L5.55299 11.9368C5.89429 12.2747 6.44627 12.2703 6.78408 11.9281C7.1219 11.586 7.11842 11.0349 6.77536 10.697L2.99241 6.96371L13.1294 6.96371C13.6108 6.96371 14 6.57455 14 6.09308C14 5.61162 13.6108 5.22241 13.1294 5.22241L2.99241 5.22242L6.77536 1.4891C7.11838 1.15128 7.121 0.600162 6.78408 0.258004C6.61345 0.0856139 6.38881 -0.00144034 6.16418 -0.00144032C5.94302 -0.0014403 5.7219 0.0821331 5.55299 0.249323L0.259444 5.47322C0.0940155 5.63689 -5.53185e-07 5.85981 -5.32787e-07 6.09313C-5.12397e-07 6.32636 0.0931554 6.54838 0.259444 6.71295Z"
        fill="#29394D"
      />
    </svg>
  );
};

export default IconBackArrow;
