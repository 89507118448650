import React from "react";
import {
  MemberMediaAppPublic,
  AboutAppPublic,
  BlogAppPublic,
  TeamAppPublic,
  ContactAppPublic,
  PartnersAppPublic,
  ServicesAppPublic,
  NumbersAppPublic,
  SpecialtyAppPublic,
  TestimonialsAppPublic,
  FaqsAppPublic,
  PacksAppPublic,
  NewsletterAppPublic,
  BannerAppPublic,
  HistoryAppPublic,
  ValuesAppPublic,
  GalleryAppPublic,
} from "../../PortalApps";
import PageTabs from "./MemberBlocs/Pages/Tabs";
import EditContainer from "./MemberBlocs/EditContainer";
import MediaSlider from "./MemberBlocs/MediaSlider";
import ConfigModal from "./ConfigModal";
import Clients from "./MemberBlocs/Clients";
import About from "./MemberBlocs/About";
import Services from "./MemberBlocs/Services";
import Stats from "./MemberBlocs/Stats";
import Contact from "./MemberBlocs/Contact";
import Team from "./MemberBlocs/Team";
import News from "./MemberBlocs/News";
import Faq from "./MemberBlocs/Faq";
import Testimonials from "./MemberBlocs/Testimonials";
import Specialties from "./MemberBlocs/Specialties";
import { useSelector } from "react-redux";
import Values from "./MemberBlocs/Values";
import { LANGUAGES } from "../../../../../../config";
import Banner from "./MemberBlocs/Banner";
import AboutPage from "./MemberBlocs/Pages/AboutPage";
import History from "./MemberBlocs/History";
import SingleService from "./MemberBlocs/SingleService";
import ServicePage from "./MemberBlocs/Pages/ServicePage";
import HomePage from "./MemberBlocs/Pages/HomePage";
import NewsPage from "./MemberBlocs/Pages/NewsPage";
import PacksPage from "./MemberBlocs/Pages/PacksPage";

const MemberV3 = ({
  handlePortalBlocChange,
  handlePortalBlocReorder,
  portals,
  setPortals,
  memberMedias,
  setMemberMedias,
  orderMemberSettings,
  titleAttr,
  handlePortalBlocTitleChange,
  partners,
  setPartners,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  aboutImage,
  setAboutImage,
  handleAboutPageBlocChange,
  handleAboutPageBlocDescriptionChange,
  handleAboutPageBlocTitleChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  services,
  setServices,
  sortServices,
  setNumbersList,
  numbersList,
  numbersImage,
  setNumbersImage,
  specialties,
  folderSpecialties,
  setFolderSpecialties,
  testimonials,
  setTestimonials,
  sortTestimonials,
  faqs,
  setFaqs,
  sortFaqs,
  sortCollaborators,
  collaborators,
  setCollaborators,
  handleGeoChange,
  addPushPinOnClick,
  handleChangeSchedule,
  handleApplySchedule,
  contactEmailError,
  setContactEmailError,
  contactPhoneError,
  setContactPhoneError,
  packsList,
  setPacksList,
  featuresCategoriesList,
  setFeaturesCategoriesList,
}) => {
  const [currentTab, setCurrentTab] = React.useState("HOME");
  // const tabs = [
  //   {
  //     name: "HOME",
  //     label: "Home",
  //   },
  //   {
  //     name: "ABOUT",
  //     label: "About",
  //   },
  //   {
  //     name: "SERVICES",
  //     label: "Services",
  //     items: [
  //       {
  //         name: "ABOUT",
  //         label: "About",
  //       },
  //       {
  //         name: "ABOUT",
  //         label: "About",
  //       },
  //     ],
  //   },
  //   {
  //     name: "NEWS",
  //     label: "News",
  //   },
  //   {
  //     name: "PACKS",
  //     label: "Our Packs",
  //   },
  // ];
  const tabs = {
    HOME: {
      name: "HOME",
      label: "Home",
    },
    ABOUT: {
      name: "ABOUT",
      label: "About",
    },
    SERVICES: {
      name: "SERVICES",
      label: "Services",
      items: services.map((service, index) => {
        return { name: `SERVICE${index}`, label: service.titleFr };
      }),
    },
    NEWS: {
      name: "NEWS",
      label: "News",
    },
    PACKS: {
      name: "PACKS",
      label: "Our Packs",
    },
  };
  const banners = [
    {
      name: "aboutBanner",
      page: "aboutPage",
    },
    {
      name: "newsBanner",
      page: "newsPage",
    },
    {
      name: "packsBanner",
      page: "packsPage",
    },
  ];
  const [editSection, setEditSection] = React.useState(null);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const renderHomeModals = () => {
    return (
      <ConfigModal
        isOpen={editSection !== null}
        close={() => setEditSection(null)}
      >
        {editSection === "memberMedia" && (
          <MemberMediaAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            medias={memberMedias}
            setMedias={setMemberMedias}
            maxPicks={orderMemberSettings.v3.media.maxPick}
            inModal={true}
          />
        )}
        {editSection === "partners" && (
          <PartnersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            partners={partners}
            setPartners={setPartners}
            maxPicks={orderMemberSettings.v3.partners.maxPick}
            inModal={true}
          />
        )}
        {editSection === "about" && (
          <AboutAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            aboutImage={aboutImage}
            setAboutImage={setAboutImage}
            handleAboutPageBlocChange={handleAboutPageBlocChange}
            handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
            handleAboutPageBlocDescriptionChange={
              handleAboutPageBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            showPageConfig={false}
            inModal={true}
          />
        )}

        {editSection === "services" && (
          <ServicesAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            services={services}
            setServices={setServices}
            sortServices={sortServices}
            maxPicks={orderMemberSettings.v3.services.maxPick}
            version={"v3"}
            inModal={true}
          />
        )}
        {editSection === "numbers" && (
          <NumbersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            setNumbersList={setNumbersList}
            numbersList={numbersList}
            maxPicks={orderMemberSettings.v2.stats.maxPick}
            allowImg={true}
            numbersImage={numbersImage}
            setNumbersImage={setNumbersImage}
            inModal={true}
          />
        )}
      </ConfigModal>
    );
  };

  return (
    <>
      <PageTabs
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
      <HomePage
        currentTab={currentTab}
        portals={portals}
        memberMedias={memberMedias}
        partners={partners}
        handlePortalBlocChange={handlePortalBlocChange}
        handlePortalBlocReorder={handlePortalBlocReorder}
        setEditSection={setEditSection}
        editSection={editSection}
        collaborators={collaborators}
        faqs={faqs}
        services={services}
        aboutImage={aboutImage}
        numbersList={numbersList}
        numbersImage={numbersImage}
        specialties={specialties}
        folderSpecialties={folderSpecialties}
        testimonials={testimonials}
        orderMemberSettings={orderMemberSettings}
      ></HomePage>

      <AboutPage
        currentTab={currentTab}
        orderMemberSettings={orderMemberSettings}
        editSection={editSection}
        setEditSection={setEditSection}
        handlePortalBlocChange={handlePortalBlocChange}
        handlePortalBlocReorder={handlePortalBlocReorder}
        portals={portals}
        numbersList={numbersList}
        numbersImage={numbersImage}
        testimonials={testimonials}
        collaborators={collaborators}
        partners={partners}
        setPartners={setPartners}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        aboutImage={aboutImage}
        setAboutImage={setAboutImage}
        handleAboutPageBlocChange={handleAboutPageBlocChange}
        handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
        handleAboutPageBlocDescriptionChange={
          handleAboutPageBlocDescriptionChange
        }
        valuesList={valuesList}
        setValuesList={setValuesList}
        valuesVideo={valuesVideo}
        setValuesVideo={setValuesVideo}
        galleryMedias={galleryMedias}
        setGalleryMedias={setGalleryMedias}
        setNumbersList={setNumbersList}
        setNumbersImage={setNumbersImage}
      />
      {services.map(
        (service, index) =>
          currentTab === `SERVICE${index}` && (
            <ServicePage
              service={service}
              services={services}
              setServices={setServices}
              setEditSection={setEditSection}
              portals={portals}
              handlePortalBlocChange={handlePortalBlocChange}
            ></ServicePage>
          )
      )}
      <NewsPage
        currentTab={currentTab}
        portals={portals}
        faqs={faqs}
        handlePortalBlocChange={handlePortalBlocChange}
        handlePortalBlocReorder={handlePortalBlocReorder}
        setEditSection={setEditSection}
      />
      <PacksPage
        currentTab={currentTab}
        portals={portals}
        packsList={packsList}
        faqs={faqs}
        featuresCategoriesList={featuresCategoriesList}
        handlePortalBlocChange={handlePortalBlocChange}
        handlePortalBlocReorder={handlePortalBlocReorder}
        setEditSection={setEditSection}
      />

      {/* Modal for all pages  */}
      <ConfigModal
        isOpen={editSection !== null}
        close={() => setEditSection(null)}
      >
        {editSection === "memberMedia" && (
          <MemberMediaAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            medias={memberMedias}
            setMedias={setMemberMedias}
            maxPicks={orderMemberSettings.v3.media.maxPick}
            inModal={true}
          />
        )}
        {editSection === "partners" && (
          <PartnersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            partners={partners}
            setPartners={setPartners}
            maxPicks={orderMemberSettings.v3.partners.maxPick}
            inModal={true}
          />
        )}
        {editSection === "about" && (
          <AboutAppPublic
            editSection={editSection}
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            aboutImage={aboutImage}
            setAboutImage={setAboutImage}
            handleAboutPageBlocChange={handleAboutPageBlocChange}
            handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
            handleAboutPageBlocDescriptionChange={
              handleAboutPageBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            showPageConfig={false}
            inModal={true}
          />
        )}

        {editSection === "services" && (
          <ServicesAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            services={services}
            setServices={setServices}
            sortServices={sortServices}
            maxPicks={orderMemberSettings.v3.services.maxPick}
            version={"v3"}
            inModal={true}
          />
        )}
        {editSection === "numbers" && (
          <NumbersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            setNumbersList={setNumbersList}
            numbersList={numbersList}
            maxPicks={orderMemberSettings.v2.stats.maxPick}
            allowImg={true}
            numbersImage={numbersImage}
            setNumbersImage={setNumbersImage}
            inModal={true}
          />
        )}
        {banners.map(
          (banner) =>
            editSection === banner.name && (
              <BannerAppPublic
                page={banner.page}
                data={portals?.public?.config}
                titleAttr={titleAttr}
                handlePortalBlocTitleChange={handlePortalBlocTitleChange}
                descriptionAttr={descriptionAttr}
                handlePortalBlocDescriptionChange={
                  handlePortalBlocDescriptionChange
                }
                inModal={true}
              />
            )
        )}
        {/* {editSection === "aboutBanner" && (
          <BannerAppPublic
            page="aboutPage"
            data={portals?.public?.config}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            inModal={true}
          />
        )} */}
        {/* {editSection === "specialty" && (
          <SpecialtyAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            specialties={specialties}
            folderSpecialties={folderSpecialties}
            setFolderSpecialties={setFolderSpecialties}
            maxPicks={orderMemberSettings.v3.specialties.maxPick}
            maxFavorites={orderMemberSettings.v3.specialties.maxFavorites}
            allowFavorites={orderMemberSettings.v3.specialties.allowFavorites}
          />
        )} */}
        {/* {editSection === "numbers" && (
          <NumbersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            setNumbersList={setNumbersList}
            numbersList={numbersList}
            maxPicks={orderMemberSettings.v3.stats.maxPick}
            allowImg={true}
            numbersImage={numbersImage}
            setNumbersImage={setNumbersImage}
          />
        )} */}

        {editSection === "specialty" && (
          <SpecialtyAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            specialties={specialties}
            folderSpecialties={folderSpecialties}
            setFolderSpecialties={setFolderSpecialties}
            maxPicks={orderMemberSettings.v3.specialties.maxPick}
            maxFavorites={orderMemberSettings.v3.specialties.maxFavorites}
            allowFavorites={orderMemberSettings.v3.specialties.allowFavorites}
          />
        )}
        {editSection === "packs" && (
          <PacksAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            packsList={packsList}
            setPacksList={setPacksList}
            featuresCategoriesList={featuresCategoriesList}
            setFeaturesCategoriesList={setFeaturesCategoriesList}
            titleAttr={titleAttr}
            descriptionAttr={descriptionAttr}
          />
        )}
        {editSection === "newsletter" && (
          <NewsletterAppPublic
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
          />
        )}
        {editSection === "testimonials" && (
          <TestimonialsAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            testimonials={testimonials}
            setTestimonials={setTestimonials}
            sortTestimonials={sortTestimonials}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            maxPicks={orderMemberSettings.v3.testimonials.maxPick}
          />
        )}
        {editSection === "faqs" && (
          <FaqsAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            faqs={faqs}
            setFaqs={setFaqs}
            sortFaqs={sortFaqs}
            maxPicks={orderMemberSettings.v3.faq.maxPick}
          />
        )}
        {editSection === "news" && (
          <BlogAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
          />
        )}
        {editSection === "team" && (
          <TeamAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            sortCollaborators={sortCollaborators}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
          />
        )}
        {editSection === "contact" && (
          <ContactAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            handleGeoChange={handleGeoChange}
            addPushPinOnClick={addPushPinOnClick}
            handleChangeSchedule={handleChangeSchedule}
            handleApplySchedule={handleApplySchedule}
            contactEmailError={contactEmailError}
            setContactEmailError={setContactEmailError}
            contactPhoneError={contactPhoneError}
            setContactPhoneError={setContactPhoneError}
          />
        )}
        {/* {editSection === "newsBanner" && (
          <BannerAppPublic
            bannerKey="newsPage|banner"
            handlePortalBlocChange={handlePortalBlocChange}
            data={portals.public.config.newsPage}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            aboutImage={aboutImage}
            setAboutImage={setAboutImage}
            handleAboutPageBlocChange={handleAboutPageBlocChange}
            handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
            handleAboutPageBlocDescriptionChange={
              handleAboutPageBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            editSection={editSection}
            showPageConfig={true}
            inModal={true}
          />
        )} */}
        {/* {editSection === "packsBanner" && (
          <BannerAppPublic
            bannerKey="packsPage|banner"
            handlePortalBlocChange={handlePortalBlocChange}
            data={portals.public.config.newsPage}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            aboutImage={aboutImage}
            setAboutImage={setAboutImage}
            handleAboutPageBlocChange={handleAboutPageBlocChange}
            handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
            handleAboutPageBlocDescriptionChange={
              handleAboutPageBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            editSection={editSection}
            showPageConfig={true}
            inModal={true}
          />
        )} */}
        {editSection === "history" && (
          <HistoryAppPublic
            data={portals.public.config?.history}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            inModal={true}
          />
        )}
        {editSection === "values" && (
          <ValuesAppPublic
            editSection={editSection}
            portals={portals}
            setPortals={setPortals}
            titleAttr={titleAttr}
            descriptionAttr={descriptionAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            inModal={true}
          />
        )}
        {editSection === "gallery" && (
          <GalleryAppPublic
            editSection={editSection}
            portals={portals}
            setPortals={setPortals}
            titleAttr={titleAttr}
            descriptionAttr={descriptionAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            inModal={true}
          />
        )}
      </ConfigModal>
    </>
  );
};

export default MemberV3;
