import React, { useState, useEffect } from "react";
import _ from "../../i18n";
import styles from "./SubscriptionNewsletter.module.scss";
import classNames from "classnames";
import { getSubscriptionDetails, updateSubscription, updateSubscriptionTT } from "../../api/newsletter";
import AppLoader from "../common/Loader/AppLoader";
import Button from "../common/Button";
import { logout } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HeaderUA } from "tamtam-components";
import { APP_ENV, TTP_ONBOARDING_URL } from "../../config";
import { useSelector } from "react-redux";


const SubscriptionNewsletter = () => {

  const navigate = useNavigate();
  const [subscribed, setSubscribed] = useState(false);
  const [cMail, setCMail] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isFetching, setFetching] = useState(false);
  const language = useSelector((state) => state.params.language);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cmailValue = urlParams.get("cmail");
    if (cmailValue !== null) {
      setCMail(cmailValue);
      fetchSubscriptionDetails(cmailValue);
    }
  }, []);

  const fetchSubscriptionDetails = (cmailValue) => {
    setFetching(true);
    getSubscriptionDetails(cmailValue).then((resp) => {
      const data = resp.data;
      if (data) {
        setSubscriptionData(data);
        setSubscribed(data.subscribed);
      }
      setFetching(false);
    });
  };
  const handleToggleSubscription = () => {
    const data = subscriptionData?.subscription;

    updateSubscription(data).then(
      (res) => {
        toast.success(_("successfully_saved"));
        setSubscribed(res.data.data.subscribed === 1);
      },
      (err) => toast.error(err),
    );
  };

  const handleToggleSubscriptionTT = () => {
    const data = subscriptionData?.subscription;

    updateSubscriptionTT(data).then(
      (res) => {
        toast.success(_("successfully_saved"));
        setSubscribed(res.data.data.subscribed === 1);
      },
      (err) => toast.error(err),
    );

  };

  const community = subscriptionData ? subscriptionData.community : null;
  const email = subscriptionData ? subscriptionData.email : null;

  return (
    <>
      <HeaderUA
        auth={{
          navCommunity: null,
          user: null,
          token: null,
        }}
        lng={language}
        languages={[]}
        app={{
          appName: "United Associates",
          appLogoUrl:
            "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
          appUrl: TTP_ONBOARDING_URL,
        }}
        env={APP_ENV}
        RouterLink={Link}
        signInUrl="/login"
        signUpUrl="/register"
      />
      {isFetching ? (
        <AppLoader
        />
      ) : subscribed ? (
        <div className={styles.subscription_item}>
          <div className={styles.logo}>
            <img src={community?.logo} alt="Community Logo" />
          </div>
          <div className={classNames("align-center", styles.subscription_block)}>
            <h1 className={classNames("column", styles.subscription_header)}>
              {_("unsubscribe")} ?
            </h1>
            <div className={classNames("column", styles.subscription_content)}>
              {_("confirm_unsubscribe_msg")} <b> {email}</b>{" "}
              {_("from_future_mails_from")}
              <b> {community?.name}</b>
            </div>
            <div className={styles.subscription_actions}>
              <Button className={"btn secondary"} onClick={logout}>
                {_("cancel")}
              </Button>
              <Button
                className={classNames("btn primary", styles.btn_unsubscribe)}
                onClick={handleToggleSubscription}
              >
                {_("unsubscribe")}
              </Button>
            </div>
            {/*<div
              className={styles.unsubscribe_from_tt}
              onClick={handleToggleSubscriptionTT}
            >
              <span> {_("unsubscribe_from_ua")} </span>
            </div>*/}
          </div>
        </div>
      ) : (
        <div className={styles.subscription_item}>
          <div className={classNames("align-center", styles.subscription_block)}>
            <h1 className={classNames("column", styles.subscription_header)}>
              {_("unsubscribed_msg")}?
            </h1>
            <div className={classNames("column", styles.subscription_content)}>
              {_("warning_unsubscribe_msg")} <b>{community?.name}</b>
              {_("unsubscribed_by_accident")}
            </div>
            <div className={styles.subscription_actions}>
              <Button onClick={() => navigate("/")} variant="secondary">
                {_("cancel")}
              </Button>
              <Button
                className={"btn primary"}
                onClick={handleToggleSubscription}
              >
                {_("subscribed_msg")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscriptionNewsletter;


