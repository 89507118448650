import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setIsSuccessPayment } from "../../store";
import _ from "../../i18n";

import Stepper from "./Stepper";
import CompanyForm from "./CompanyForm";
import Collaborator from "./Collaborator";
import Client from "./Client";
// import Contact from "./Contact";
// import OnBoarding from "./OnBoarding";
import Payment from "./Payment";
import styles from "./Join.module.scss";

const Join = () => {
  const folder = useSelector((state) => state.folder);
  const { fiduciary, fetched } = folder;
  const isSuccessPayment = useSelector(
    (state) => state.params.isSuccessPayment
  );
  const [currentStep, setCurrentStep] = useState(-1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccessPayment) {
      setCurrentStep(1);
      dispatch(setIsSuccessPayment(false));
    }
  }, [isSuccessPayment]);

  useEffect(() => {
    if (fetched) {
      if (fiduciary) {
        if (fiduciary.uaMode === "UNIVERSITY") {
          switch (fiduciary.onBoardingStep) {
            case "CREATED":
              setCurrentStep(1);
              break;
            case "COMPANY":
              setCurrentStep(2);
              break;
            case "COMPLETE":
              // navigate("/settings");
              window.location.href = "/settings";
              break;
          }
        } else {
          switch (fiduciary.onBoardingStep) {
            case "CREATED":
              setCurrentStep(1);
              break;
            case "PAYMENT":
              setCurrentStep(2);
              break;
            case "COMPANY":
              setCurrentStep(3);
              break;
            case "COLLABORATORS":
              setCurrentStep(4);
              break;
            case "CLIENTS":
              setCurrentStep(5);
              break;
            case "COMPLETE":
              // navigate("/settings");
              window.location.href = "/settings";
              break;
          }
        }
      } else {
        navigate("/");
        // setCurrentStep(0);
      }
    }
  }, [fiduciary, fetched]);

  if (currentStep > 0) {
    return (
      <div className={styles.body}>
        <Stepper currentStep={currentStep} uaMode={fiduciary.uaMode} />
        {fiduciary.uaMode === "UNIVERSITY" ? (
          <>
            {currentStep === 1 && (
              <CompanyForm
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
              />
            )}
            {currentStep === 2 && (
              <Collaborator
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
              />
            )}
          </>
        ) : (
          <>
            {currentStep === 1 && <Payment setCurrentStep={setCurrentStep} />}
            {currentStep === 2 && (
              <CompanyForm
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
              />
            )}
            {currentStep === 3 && (
              <Collaborator
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
              />
            )}
            {currentStep === 4 && <Client setCurrentStep={setCurrentStep} />}
            {/* {currentStep === 5 && <Contact setCurrentStep={setCurrentStep} />} */}
          </>
        )}
      </div>
    );
  }

  // if (currentStep === 0) {
  //   return <OnBoarding setCurrentStep={setCurrentStep} />;
  // }
  return null;
};

export default Join;
// FIDUCIARY
