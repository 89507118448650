import axios from "axios";

import { TTP_API_URL } from "../config";
import { throwCatchedError, updateSourceToken } from "../utils";

let getTeamSourceToken;

export const getTeam = ({
  token,
  limit,
  offset = 0,
  communityId,
  navCommunity,
  sourceToken = null,
  ids = [],
  excludeIds = [],
  noLimit = false,
  cfields = [],
}) => {
  getTeamSourceToken = updateSourceToken(getTeamSourceToken, sourceToken);

  const fields = cfields.length > 0 ? cfields : ["*", "avatar"];

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "enabled",
      value: 1,
      operator: "eq",
    },
  ];

  if (communityId) {
    filter.push({
      property: "organization.id",
      value: communityId,
      operator: "eq",
    });
  } else if (navCommunity) {
    requestUrl += `?organization_id=${navCommunity.id}`;
  }
  if (ids.length > 0) {
    filter.push({
      property: "id",
      value: ids,
      operator: "in",
    });
  }
  if (excludeIds.length > 0) {
    filter.push({
      property: "id",
      value: [...excludeIds],
      operator: "nin",
    });
  }
  const params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    start: offset,
    workspace: "ua",
  };
  if (noLimit) {
    params.nolimit = 1;
  } else {
    params.limit = limit;
  }
  return axios
    .get(requestUrl, {
      // cancelToken: getTeamSourceToken.token,
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getTeamPremium = ({ token, ids = [], organizationId = null }) => {
  let requestUrl = `${TTP_API_URL}/event/cycle/get-users-premium`;

  const params = {
    access_token: token,
  };
  if (organizationId) {
    params.organizationId = organizationId;
  } else {
    params.users = ids.join();
  }

  return axios
    .get(requestUrl, {
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getMediasInTheNews = ({ token, community }) => {
  let requestUrl = `${TTP_API_URL}/media/media`;

  const filter = [
    {
      property: "organization.id",
      operator: "eq",
      value: community.id,
    },
    {
      property: "inTheNews",
      operator: "eq",
      value: 1,
    },
  ];
  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  let fields = ["*", "webPath", "preview"];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      fields: fields.join(","),
      limit: 5,
    },
  });
};

export const getPublicArticles = ({
  token,
  limit,
  language = "fr",
  communityId,
  offset = 0,
  search = null,
  isPublic = true,
  sourceToken = null,
  conditions = [],
  loggedAs = "GUEST",
  type,
  filters,
  scope,
}) => {
  const filter = [
    {
      property: "status",
      value: "PUBLISHED",
      operator: "like",
    },
    {
      property: "language",
      value: language,
      operator: "like",
    },
    {
      property: "isExternal",
      value: 0,
      operator: "eq",
    },
  ];

  filter.push({
    property: "csScope",
    value: scope,
    operator: "in",
  });

  if (communityId) {
    filter.push({
      property: "organization.id",
      value: type && type === "FFF" ? 9 : communityId,
      operator: "eq",
    });
  }
  if (search) {
    filter.push({
      property: "word",
      operator: "like",
      value: search,
    });
  }
  if (filters) {
    if (filters.categories && filters.categories.length > 0) {
      filter.push({
        property: "category.id",
        value: filters.categories,
        operator: "in",
      });
    }
    if (filters.authorTypes && filters.authorTypes.length > 0) {
      filters.authorTypes.forEach((element) => {
        filter.push({
          property: "authorsTypes",
          value: element,
          operator: "like",
        });
        filter.push({
          property: "authorsTypes",
          value: element,
          operator: "flike",
        });
      });
    }
    if (filters.relevance) {
      filter.push({
        property: "relevance",
        value: filters.relevance,
        operator: "gte",
      });
    }
  }

  const sort = [
    {
      property: "publishedAt",
      dir: "desc",
    },
  ];
  const fields = [
    "*",
    "url",
    "author",
    "introduction",
    "main_media",
    "tags",
    "category",
    "image_reduced",
    "organization",
    "avatars",
    "albums",
  ];
  if (isPublic) {
    fields.push("content", "contentState");
  } else {
    fields.push("social");
  }
  const requestUrl = `${TTP_API_URL}/blog/article`;
  const params = {
    access_token: token,
    filter: JSON.stringify([...filter, ...conditions]),
    sort: JSON.stringify(sort),
    fields: fields.join(","),
    limit,
    start: offset,
  };
  if (type && (type === "MINE" || type === "FFF")) {
    params.workspace = "rss";
  }

  return axios
    .get(requestUrl, {
      params,
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getMedias = ({
  token,
  limit = 60,
  communityId,
  navCommunity,
  language,
  ids,
}) => {
  const fields = ["*", "avatar"];

  let requestUrl = `${TTP_API_URL}/media/media`;

  const filter = [
    { property: "mediaStatus", operator: "eq", value: "PUBLISHED" },
    { property: "objectType", operator: "nin", value: ["LOGO", "MASK"] },
    { property: "docType", operator: "eq", value: "IMAGE" },
    { property: "isPrivate", operator: "eq", value: "0" },
  ];
  if (language) {
    filter.push({ property: "languages", operator: "flike", value: language });
  } else {
    filter.push({ property: "languages", operator: "flike", value: "fr" });
  }
  if (communityId) {
    filter.push({
      property: "organization",
      value: [communityId, 9],
      operator: "in",
    });
  } else if (navCommunity) {
    requestUrl += `?organization=${navCommunity.id}`;
  }
  if (ids.length > 0) {
    filter.push({
      property: "id",
      value: ids,
      operator: "in",
    });
  }
  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        limit,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const saveMedia = ({
  token,
  community,
  file,
  titleFR,
  titleEN,
  titleNL,
  isPrivate,
  inTheNews,
  overrideMainImage = false,
  languages = ["FR", "EN", "NL"],
}) => {
  const requestUrl = `${TTP_API_URL}/media/media`;
  let config = {};

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("organization", community.id);

  if (languages && languages.includes("FR")) {
    formData.append("titleFr", titleFR || file.name);
  }

  if (languages && languages.includes("NL")) {
    formData.append("titleNl", titleNL || file.name);
  }

  if (languages && languages.includes("EN")) {
    formData.append("titleEn", titleEN || file.name);
  }
  formData.append("overrideMainImage", overrideMainImage);
  file && formData.append("file", file);

  formData.append("isPrivate", isPrivate ? 1 : 0);
  formData.append("inTheNews", inTheNews ? 1 : 0);

  languages && formData.append("languages", languages.join(","));
  return axios.post(requestUrl, formData, config);
};

export const saveSharedPremium = ({
  token,
  users,
  organizationId = null,
  premiumId,
  premiumSharedBy,
}) => {
  const requestUrl = `${TTP_API_URL}/event/guest/add-shared-premium-user-to-all-slots`;
  let config = {};

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("cycle", premiumId);
  formData.append("premiumSharedBy", premiumSharedBy);

  if (organizationId) {
    formData.append("organizationId", organizationId);
  } else {
    users.forEach((user, i) => {
      formData.append(`users[${i}]`, user);
    });
  }

  return axios.post(requestUrl, formData, config);
};

export const removeSharedPremium = ({ token, user }) => {
  const requestUrl = `${TTP_API_URL}/event/guest/remove-shared-premium-for-user`;
  let config = {};

  let formData = new FormData();
  formData.append("access_token", token);
  formData.append("user", user);

  return axios.post(requestUrl, formData, config);
};

export const getMediasByID = ({ token, ids }) => {
  const fields = ["*", "avatar"];

  let requestUrl = `${TTP_API_URL}/media/media`;

  const filter = [];

  if (ids.length > 0) {
    filter.push({
      property: "id",
      value: ids,
      operator: "in",
    });
  }
  const sort = [
    {
      property: "createdAt",
      dir: "desc",
    },
  ];
  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        filter: JSON.stringify(filter),
        sort: JSON.stringify(sort),
        fields: fields.join(","),
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const sendUaPremiumShareEmail = ({
  token,
  userId,
  name,
  invitation = 0,
}) => {
  let requestUrl = `${TTP_API_URL}/organization/organization/sendUaPremiumShareEmail`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        userId,
        name,
        invitation,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const getUserWatchStats = ({ token, userId }) => {
  let requestUrl = `${TTP_API_URL}/event/user-slot-participation/user-story`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        userId,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};
