import React from "react";
import { useSelector } from "react-redux";

import styles from "./SingleService.module.scss";
import _ from "../../../../../../../../i18n";

const SingleService = ({ service, singleServiceImage }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  return service ? (
    <div className={styles.container}>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${
            singleServiceImage
              ? singleServiceImage.fullMediaUrl
              : "img/no_image.png"
          })`,
        }}
      />
      <div className={styles.left}>
        <div className={styles.about_title}>{_("Services")}</div>
        <div className={styles.title}>
          {service[titleAttr]
            ? service[titleAttr]
            : "Velit laoreet id donec ultrices tincidunt"}
        </div>
        <div className={styles.description}>
          {service[descriptionAttr]
            ? service[descriptionAttr]
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Enim facilisis gravida neque convallis a cras semper auctor neque."}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url("img/no_image.png")`,
        }}
      />
      <div className={styles.left}>
        <div className={styles.about_title}>{_("Services")}</div>
        <div className={styles.title}>
          {
            "Court titre expliquant comment l'entreprise peut vous aider (Service 1)"
          }
        </div>
        <div className={styles.description}>
          {
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
          }
        </div>
      </div>
    </div>
  );
};

export default SingleService;
