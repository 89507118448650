import React, { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import $ from "jquery";

import {
  login,
  setIsSuccessPayment,
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  fetchAutoAuthUser,
  fetchFiduciary,
} from "../../store";
import { logout, decryptWithAES, createAuthCookie } from "../../utils";
import _ from "../../i18n";
import Menu from "./Menu";
import Footer from "./Footer";

const Layout = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const folder = useSelector((state) => state.folder);
  const hideFooter = useSelector((state) => state.params.hideFooter);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (auth.user && auth.user.role) {
      if (
        folder.fetched &&
        folder.fiduciary &&
        folder.fiduciary.subscription &&
        !["PENDING", "IN_PROGRESS"].includes(folder.fiduciary.subscription) &&
        location.pathname !== "/account-activation"
      ) {
        navigate("/account-suspended");
        return null;
      }
      if (auth.user.role.type === "OFFICIAL") {
        navigate({
          pathname: "/collaborator",
          search: searchParams.toString(),
        });
        return null;
      } else if (auth.user.role.typeStatus === "FOLDER") {
        navigate({
          pathname: "/client",
          search: searchParams.toString(),
        });
        return null;
      }
    }
    if (auth.user && folder.fetched) {
      if (!folder.fiduciary) {
        navigate("/create-office");
      } else if (folder.fiduciary) {
        if (
          folder.fiduciary.uaStatus === "CREATED" ||
          folder.fiduciary.uaStatus === "DELETED"
        ) {
          navigate("/create-office");
        } else if (folder.fiduciary.uaStatus === "PENDING") {
          navigate("/pending");
        } else if (folder.fiduciary.uaStatus === "VALIDATED") {
          if (searchParams.get("ps")) {
            dispatch(setIsSuccessPayment(true));
          }
          if (folder.fiduciary.onBoardingStep === "CREATED") {
            navigate("/welcome");
          } else if (folder.fiduciary.onBoardingStep !== "COMPLETE") {
            navigate("/join");
          }
        }
      }
    }
  }, [auth, folder]);

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          dispatch(setAuthUser(null));
          logout(false);
          navigate("/login");
          return Promise.reject(error);
        }

        return Promise.reject(error);
      }
    );

    let authInfos = null;
    const params = new URLSearchParams(window.location.search).get("params");
    if (params) {
      const decryptedParams = JSON.parse(decryptWithAES(params));
      if (
        decryptedParams["token"] &&
        decryptedParams["userId"] &&
        decryptedParams["tokenCreatedAt"] &&
        decryptedParams["tokenExpiresIn"]
      ) {
        authInfos = {
          id: decryptedParams["userId"],
          token: decryptedParams["token"],
          createdAt: decryptedParams["tokenCreatedAt"],
          expiresIn: decryptedParams["tokenExpiresIn"],
        };
        dispatch(setAuthToken(authInfos.token));
        dispatch(fetchAutoAuthUser(authInfos));
        dispatch(setExpiresIn(authInfos.expiresIn));
        dispatch(setTokenCreatedAt(authInfos.createdAt));
        dispatch(fetchFiduciary());
      }
    }

    if (!authInfos) {
      dispatch(login());
    }

    if (!auth.user) {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
  }, []);

  if (!folder.fetched) {
    return null;
  }

  const withoutFooterURL = [
    "/join",
    "/create-office",
    "/welcome",
    "/account-activation",
  ];

  if (hideFooter && location.pathname === "/collaborator") {
    withoutFooterURL.push(location.pathname);
  }

  return (
    <div>
      <Menu />
      <Outlet />
      {!withoutFooterURL.includes(location.pathname) && <Footer />}
    </div>
  );
};

export default Layout;
