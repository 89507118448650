import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";

import {
  ALLOWED_APPS_OPTIONS,
  DEFAULT_APPS,
  DEFAULT_TABS,
  ALLOWED_TABS_OPTIONS,
} from "../../config";
import _ from "../../i18n";
import { getUaSettings, saveFolderUaSettings } from "../../api";

import Loader from "../common/Loader";
import Button from "../common/Button";
import styles from "./FiduciaryModal.module.scss";

const FiduciaryModal = ({
  modalOpen,
  setModalOpen,
  fiduciary,
  refreshList,
}) => {
  const auth = useSelector((state) => state.auth);
  const [isSaving, setIsSaving] = useState(false);
  const [appsOptions, setAppsOptions] = useState(ALLOWED_APPS_OPTIONS);
  const [allowedApps, setAllowedApps] = useState(DEFAULT_APPS);
  const [currentFiduciary, setCurrentFiduciary] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [tabsOptions, setTabsOptions] = useState(ALLOWED_TABS_OPTIONS);
  const [allowedTabs, setAllowedTabs] = useState(DEFAULT_TABS);
  const [uaSettings, setUaSettings] = useState(null);

  useEffect(() => {
    if (fiduciary && modalOpen) {
      if (fiduciary.uaSettings) {
        setUaSettings(fiduciary.uaSettings);
      }
      setCurrentFiduciary(fiduciary);
    }
  }, [fiduciary, modalOpen]);

  const handleSave = async () => {
    if (!uaSettings) {
      toast.error("Please select a configuration");
      return null;
    }

    setIsSaving(true);
    uaSettings;
    saveFolderUaSettings(auth.token, {
      id: currentFiduciary.id,
      uaSettings: uaSettings.id,
    })
      .then((resp) => {
        setIsSaving(false);
        if (resp.data.result === "OK") {
          refreshList();
          toast.success(_("successfully_saved"));
          setModalOpen(false);
        }
      })
      .catch((e) => {
        setIsSaving(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const fetchSettings = (inputValue) => {
    // if (inputValue.length < 3) {
    //   return null;
    // }

    return getUaSettings({
      token: auth.token,
      limit: 20,
      name: inputValue,
    }).then((resp) => {
      return resp.data.data;
    });
  };

  if (!modalOpen || !currentFiduciary) {
    return null;
  }

  return (
    <Modal
      width="40vw"
      height="40vh"
      closable={false}
      visible={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>{currentFiduciary.name}</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(false);
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>

      <div className={styles.content}>
        <div className="ttp-form-group">
          <label className="ttp-label">Selectionnez la configuration</label>
          <AsyncSelect
            isClearable
            cacheOptions
            onChange={setUaSettings}
            loadOptions={(e) => fetchSettings(e)}
            defaultOptions
            value={uaSettings}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.id}
            className={styles.select}
          />
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("save")}</Button>
        )}
      </div>
    </Modal>
  );
};

export default FiduciaryModal;
