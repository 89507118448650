import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import SendWidget from "../common/Widgets/SendWidget";
import { handleTalkWidget } from "../common/Widgets/talk";
import Button from "../common/Button";

import { ALLOWED_APPS } from "../../config";
import { store, setCurrentPortal } from "../../store";
import AppsList from "../CollaboratorPortal/AppsList";
import Drawer from "../CollaboratorPortal/Drawer";
import collaboratorStyles from "../CollaboratorPortal/CollaboratorPortal.module.scss";

import styles from "./ClientPortal.module.scss";

const ClientPortal = () => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [clientEnabled, setClientEnabled] = useState(true);
  const [activeApp, setActiveApp] = useState("");
  const [apps, setApps] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { loggedAs, loggedIsAdmin } = auth;

  useEffect(() => {
    if (fiduciary && (loggedAs === "CLIENT" || loggedIsAdmin)) {
      if (fiduciary.hubPreferences && fiduciary.hubPreferences.portals) {
        if (fiduciary.hubPreferences.portals.client?.enabled) {
          const config = fiduciary.hubPreferences.portals.client.config;
          dispatch(setCurrentPortal("CLIENT"));
          if (location.pathname !== "/client") {
            window.history.pushState({}, null, "/client");
          }

          const tab = config
            ? config.apps.filter(
                (app) => app.enabled && ALLOWED_APPS.includes(app.id)
              )
            : [];
          setApps(tab);
          if (tab.length > 0) {
            if (!activeApp) {
              setActiveApp(tab[0].id);
            }
          }
          if (fiduciary.blogPreferences && fiduciary.blogPreferences.apps) {
            setWidgets(fiduciary.blogPreferences.apps);
          }
        } else {
          setClientEnabled(false);
        }
      } else {
        setClientEnabled(false);
      }
    }
  }, [fiduciary, location]);

  const renderCommunityPortal = () => {
    return (
      <div id="page">
        <AppsList
          apps={apps}
          activeApp={activeApp}
          setActiveApp={setActiveApp}
        />
        <Drawer activeApp={activeApp} />

        {/* {(widgets.includes("ARTICLE") || widgets.includes("TAMTAMIT")) && (
          <ArticleWidget widgets={widgets} activeApp={activeApp} />
        )} */}

        <div className={collaboratorStyles.widgets}>
          {widgets.includes("BLOG_NL_CREATE") && <SendWidget />}
        </div>

        {widgets.includes("TALK_FLOAT") && handleTalkWidget(store)}
      </div>
    );
  };

  if (!fiduciary) {
    return null;
  }

  if (!clientEnabled) {
    return (
      <div className={`${styles.emptyPage} grid-container`}>
        <h2 className={styles.emptyPage_title}>
          Bienvenue sur le portail Client
        </h2>
        <p className={styles.emptyPage_help}>
          Ce portail vous permet d’offrir la base de votre portail. Chaque
          client y retrouvera les applications que vous aurez activées.
          Moyennant l’usage d’un environnement ouvert, les solutions pourront
          être ajoutées progressivement. Votre portail client vous permet de
          créer une porte d’entrée unique à vos solutions.
        </p>
        {loggedIsAdmin ? (
          <Button onClick={() => navigate("/settings")}>
            Configurer le portail client
          </Button>
        ) : null}
      </div>
    );
  }

  return renderCommunityPortal();
};

export default ClientPortal;
