import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import $ from "jquery";

import _ from "../../i18n";
import {
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  fetchAuthUser,
  setLanguage,
  fetchFiduciary,
  setFolderFetched,
} from "../../store";
import {
  getAuthInfosFromCookie,
  authHasExpired,
  getCookie,
  setCookie,
  logout,
} from "../../utils";
import { APP_ENV } from "../../config";

const Auth = ({ children }) => {
  const auth = useSelector((state) => state.auth);
  const folder = useSelector((state) => state.folder);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const handleAuthUser = (user) => {
    dispatch(setAuthToken(user.token));
    dispatch(fetchAuthUser(user));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(fetchFiduciary());
  };

  useEffect(() => {
    let authInfos = getAuthInfosFromCookie();

    if (!authInfos || authHasExpired(authInfos)) {
      dispatch(setAuthUser(null));

      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

      setCookie(
        "ttp_auth_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be",
      );
      setCookie(
        "ttp_community_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be",
      );
    }

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos) {
      handleAuthUser(authInfos);
    } else {
      dispatch(setFolderFetched(true));
    }

    if (location.pathname !== "/") {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
  }, []);

  useEffect(() => {

    if (location.pathname === "/unsubscribe") {
      const params = new URLSearchParams(location.search);
      const cmail = params.get("cmail");
      navigate(`/unsubscribe?cmail=${cmail}`);
      return;
    }
    if (auth.user && folder.fetched) {
      if (!folder.fiduciary) {
        navigate("/create-office");
      } else if (folder.fiduciary) {
        if (
          folder.fiduciary.uaStatus === "CREATED" ||
          folder.fiduciary.uaStatus === "DELETED"
        ) {
          navigate("/create-office");
        } else if (folder.fiduciary.uaStatus === "PENDING") {
          navigate("/pending");
        } else if (folder.fiduciary.uaStatus === "VALIDATED") {
          if (folder.fiduciary.onBoardingStep === "CREATED") {
            navigate("/welcome");
          } else if (folder.fiduciary.onBoardingStep === "COMPLETE") {
            if (
              ["/resetPassword", "/rejectInvitation", "/register"].includes(
                location.pathname,
              )
            ) {
              logout(false);
            } else {
              if (auth.user.role.typeStatus === "FOLDER") {
                navigate("/client");
              } else {
                if (folder.fiduciary.uaMode === "COMMUNITY") {
                  navigate("/settings");
                } else {
                  navigate("/collaborator");
                }
              }
            }
          } else {
            navigate("/join");
          }
        }
      }
    }
  }, [auth, folder]);

  if (!folder.fetched) {
    return null;
  }

  return <Outlet />;
};

export default Auth;
