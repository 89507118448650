import { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";

import _ from "../../i18n";
import IconYes, { OrangeYesIcon } from "../common/icons/IconYes";
import IconNo from "../common/icons/IconNo";
import IconInfo from "../common/icons/IconInfo";

import PriceTag from "./PriceTag";
import Formule from "./Formule";
import styles from "./Pack.module.scss";

const Pack = ({
  offerType,
  pack,
  onSelect,
  lng,
  billingPeriod,
  setSliderValue,
  packApps,
}) => {
  const [currentFormula, setCurrentFormula] = useState(null);
  const [apps, setApps] = useState([]);

  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const sliderLabelAttr = `sliderLabel${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  const sliderTextAttr = `sliderText${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  useEffect(() => {
    setCurrentFormula(pack.uaPackFormulas[0]);
  }, [pack]);

  useEffect(() => {
    if (pack?.uaPackApps) {
      const tab = [];
      pack.uaPackApps.forEach((app) => {
        if (app.type === "APP") {
          const tabAppIds = [];
          if (app.children.length === 0) {
            tabAppIds.push(app.id);
          } else {
            app.children.forEach((category) => {
              if (category.children.length === 0) {
                tabAppIds.push(category.id);
              } else {
                category.children.forEach((subCategory) => {
                  if (subCategory.children.length === 0) {
                    tabAppIds.push(subCategory.id);
                  } else {
                    subCategory.children.forEach((option) => {
                      tabAppIds.push(option.id);
                    });
                  }
                });
              }
            });
          }

          tab.push({
            id: app.id,
            nameFr: app.nameFr,
            nameNl: app.nameNl,
            nameEn: app.nameEn,
            icon: app.uaApplication.icon,
            key: app.uaApplication.key,
            // isChecked: false,
            appIds: tabAppIds,
          });
        }
      });
      setApps(tab);
    }
  }, [currentFormula]);

  const handleChangeSlider = (event, newValue) => {
    setSliderValue(pack, newValue);
  };

  if (!pack) {
    return null;
  }

  return (
    <div className={styles.pack}>
      <ul className={styles.top}>
        {pack.uaPackFormulas.map((formula) => (
          <li
            key={`formula${formula.id}`}
            className={`${styles.top_link} ${
              currentFormula?.id === formula.id ? styles.top_link_active : ""
            }`}
            onClick={() => setCurrentFormula(formula)}
          >
            {formula.name}
          </li>
        ))}
      </ul>
      <div
        className={`${styles.content} ${
          pack.key === "learn" ? styles.smallpad : ""
        }`}
      >
        <div className={styles.left}>
          <div className={styles.left_top}>
            Pack
            <br />
            {pack.name}
          </div>
          <div
            className={`${styles.left_bottom}`}
            // className={`${styles.left_bottom} ${
            //   currentTab === "pro" ? styles.left_bottom_pro : ""
            // }`}
          >
            <Formule formula={currentFormula} />
            <PriceTag
              offerType={offerType}
              pack={pack}
              formula={currentFormula}
              billingPeriod={billingPeriod}
            />
          </div>
        </div>

        <div className={styles.apps_box}>
          {pack.hasSlider === 1 && (
            <div className={styles.slider}>
              <div className={styles.slider_top}>
                <span>
                  {pack[sliderLabelAttr]}{" "}
                  {pack[sliderTextAttr] && (
                    <Tooltip title={pack[sliderTextAttr]} arrow>
                      <span>
                        <IconInfo size="14" />
                      </span>
                    </Tooltip>
                  )}
                </span>
                <span className={styles.slider_value}>{pack.sliderValue}</span>
              </div>
              <Slider
                value={pack.sliderValue}
                step={parseInt(pack.sliderStep)}
                marks
                min={parseInt(pack.sliderMin)}
                max={parseInt(pack.sliderMax)}
                sx={{
                  color: "primary.light",
                }}
                onChange={handleChangeSlider}
              />
              {/* {pack.key === "learn" && (
                <div className={styles.slider_bottom}>
                  <div className={styles.slider_box}>
                    <div>
                      <span>1</span> titrés
                    </div>
                  </div>
                  <div className={styles.slider_box}>
                    <div>
                      <span>4</span> collab.
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          )}

          <ul className={styles.apps}>
            {apps.map((item, idx) => (
              <li className={styles.app} key={idx}>
                <span className={styles.app_name}>
                  {item.icon && (
                    <img width="25" src={`/img/apps/${item.icon}`} alt="" />
                  )}{" "}
                  {item[nameAttr]}
                </span>
                <span>
                  {item.appIds.length === currentFormula.apps.length ? (
                    <IconYes size="14" />
                  ) : currentFormula.apps.length > 0 ? (
                    <OrangeYesIcon size="14" />
                  ) : (
                    <IconNo size="14" />
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div
        className={styles.pack_btn}
        onClick={() => {
          if (onSelect) {
            onSelect(pack);
          }
        }}
      >
        Voir le pack {pack.name}
      </div>
    </div>
  );
};

export default Pack;
