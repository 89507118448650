import { configureStore, combineReducers } from "@reduxjs/toolkit";

import authReducer from "./auth";
import paramsSlice from "./params";
import folderSlice from "./folder";
import categoriesSlice from "./categories";
import privacySlice from "./privacy";

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    params: paramsSlice,
    folder: folderSlice,
    categories: categoriesSlice,
    privacy: privacySlice,
  }),
});
