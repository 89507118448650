import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";

import _ from '../../../i18n';

import { LANGUAGES } from "../../../config";
import styles from "./SearchBar.module.scss"; 

const SearchBar = ({ 
    setSearchText,
    searchLng,
    setSearchLng,
    searchCat,
    setSearchCat,
    categories
}) => {
    
    const lng = useSelector((state) => state.params.language);
    const [value, setValue] = useState('');
    
    const clearAll = () => {
        setValue("");
        setSearchText("");
        setSearchCat([]);
    }

    const debouncedLoadResults = debounce((value) => {
        setSearchText(value);
    }, 500);

    const debouceSearch = useCallback(debounce(debouncedLoadResults, 500), []);
    

    const handleChange = (e) => {
        setValue(e.target.value);
        debouceSearch(e.target.value);
    }

    const chandleLngClick = (lng) => {
        if (!searchLng.includes(lng)) setSearchLng([...searchLng, lng]);
        else if (searchLng.length>1) setSearchLng([...searchLng.filter(l => l!==lng)]);
    }

    const handleCatClick = (id) => {
        if (searchCat.length === 0 ) setSearchCat([id])
        else if (!searchCat.includes(id)) setSearchCat([...searchCat, id]);
        else setSearchCat([...searchCat.filter(catId => catId!==id)]);
    }

    return (
        <div className={styles.filterBar}>
            <div className={styles.searchBar}>
                <div className={styles.searchBox}>
                    <input
                        value={value}
                        onChange={handleChange}
                        placeholder={_('Recherche...')}
                    />
                    {(value.length>0 || searchCat.length>0) && (
                        <i
                            className="icon-ttp-close"
                            onClick={clearAll}
                        />
                    )}
                </div>
                <ul>
                    { LANGUAGES.map(lng => <li 
                        key={`fltr-lng-${lng.value}`}
                        onClick={(e) => chandleLngClick(lng.value)}
                        className={searchLng.includes(lng.value) ? styles.selected : ''}>
                        <span>{lng.value}</span>
                    </li>)}
                </ul>
            </div>
            <ul>
                {categories.map(cat => <li 
                    key={`fltr-cat-${cat.id}`}
                    onClick={(e) => handleCatClick(cat.id)}
                    className={searchCat.includes(cat.id) ? styles.selected : ''}>
                        {cat['name'+ lng.slice(0,1).toUpperCase() + lng.slice(1)]}
                </li>)}
            </ul>
        </div>
    );
    // }
}

export default SearchBar;
