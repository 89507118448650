import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import ServiceContent from "../common/ServiceContent";
import { getServices } from "../../api";
import _ from "../../i18n";

import LeftBanner from "./LeftBanner";
import styles from "./PublicPortal.module.scss";

const Services = ({ community, services, info, lng }) => {
  const token = useSelector((state) => state.auth.token);
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  // let { isLoading, data } = useQuery(
  //   ["getServices", token, services],
  //   async () => {
  //     if (token) {
  //       const response = await getServices(
  //         token,
  //         // fiduciary ? fiduciary.id : 4,
  //         null,
  //         info.list && info.list.length > 0 ? info.list : []
  //       );
  //       return response.data.data;
  //     }
  //   }
  // );

  const defaultDescription = () => {
    switch (lng) {
      case "fr":
        return `Spécificités ${
          community.abbreviation || community.name
        }. Voici les missions que le cabinet vous propose. Sans être exhaustive, elles représentent une partie des services proposés les plus habituels`;
      case "en":
        return `${
          community.abbreviation || community.name
        } specificities. Here are the missions that the firm offers you. Without being exhaustive, they represent some of the most common services offered`;
      case "nl":
        return `Bijzonderheden van ${
          community.abbreviation || community.name
        }. Dit zijn de missies die het bedrijf u biedt. Zonder volledig te zijn, vertegenwoordigen ze enkele van de meest voorkomende aangeboden diensten`;
      default:
        break;
    }
  };

  const defaultTitle = () => {
    switch (lng) {
      case "fr":
        return `Spécificités ${community.abbreviation || community.name}`;
      case "en":
        return `Specificities ${community.abbreviation || community.name}`;
      case "nl":
        return `Bijzonderheden ${community.abbreviation || community.name}`;
      default:
        break;
    }
  };
  return null;
  //   if (isLoading || !data || data.length === 0) {
  //     return null;
  //   }

  //   return (
  //     <section id="fiduciary-services">
  //       <div className={`${styles.servicesContent} grid-x`}>
  //         <LeftBanner
  //           buttons={false}
  //           type={_("Services")}
  //           title={info[titleAttr] ? info[titleAttr] : defaultTitle()}
  //           text={
  //             info[descriptionAttr] ? info[descriptionAttr] : defaultDescription()
  //           }
  //         />
  //         <div
  //           className={`${styles.listContainer} cell large-9 medium-8 small-12`}
  //         >
  //           <div className={`${styles.servicesList} grid-x`}>
  //             {data.map((item) => (
  //               <div
  //                 key={item.id}
  //                 className="cell large-4 small-12 medium-6"
  //                 style={{
  //                   paddingRight: "2%",
  //                   paddingBottom: "2%",
  //                 }}
  //               >
  //                 <ServiceContent service={item} home lng={lng} />
  //               </div>
  //             ))}
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   );
};

export default Services;
