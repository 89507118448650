import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";

import _ from "../../../../i18n";
import styles from "./ModalDelegation.module.scss";
import { useQuery } from "react-query";
import { ModalConfirm } from "tamtam-components";
import CollaboratorsSelect from "./CollaboratorsSelect";
import DelegationTypeSelect from "./DelegationTypeSelect";
import List from "./List";
import {
  deleteDelegation,
  getDelegations,
  saveDelegation,
} from "../../../../api";
import Loader from "../../../common/Loader";

const ModalDelegation = ({
  folderIds,
  folders,
  refetchClients,
  currentApp,
  currentPack,
  closeModal,
  onSave,
  type,
  parentApp,
  applications,
}) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  console.log(folders);

  const [collaborator, setCollaborator] = useState(null);
  const [delegationType, setDelegationType] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleClose = () => {
    closeModal();
  };

  let { isLoading, data, refetch } = useQuery(
    ["listDelegation", token, folderIds],
    async () => {
      if (token) {
        try {
          const response = await getDelegations({
            token,
            folderIds,
            limit: 20,
            page: 1,
          });
          return response.data;
        } catch (e) {
          console.log("error" + e);
          return null;
        }
      }
    }
  );

  const handleDeleteAction = async () => {
    if (currentDeleteItem) {
      setIsDeleting(true);
      deleteDelegation(token, currentDeleteItem.id)
        .then((resp) => {
          refetch();
          refetchClients();
          toast.success(_("successfully_deleted"));
          setIsDeleting(false);
          setIsOpenDeleteModal(false);
        })
        .catch((e) => {
          toast.success(_("error"));
        })
        .finally(() => {
          setIsDeleting(false);
          setIsOpenDeleteModal(false);
          setCurrentDeleteItem(null);
        });
    }
  };

  const handleSave = async () => {
    if (collaborator && delegationType) {
      setIsSaving(true);
      saveDelegation(token, folderIds, collaborator.id, delegationType.id)
        .then((resp) => {
          if (resp.data.result === "OK") {
            toast.success(_("successfully_saved"));
            refetch();
            refetchClients();
          } else {
            toast.error(_("error"));
          }
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          )
            toast.error(_(e.response.data.errors[0].message));
          else toast.error(_("error"));
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  const getFoldersName = () => {
    return folderIds.length > 1 ? (
      folders
        .filter((d) => folderIds.includes(d.id))
        .map((f) => <li>{f.name}</li>)
    ) : (
      <>
        {_("client")}: {folders[0].name}
      </>
    );
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>
        <div className={styles.title}>
          <h3>{_("title_delegation")}</h3>
          <div className={styles.info}>
            {folderIds.length > 1 ? (
              <>
                {_("for_count_clients").replace("%count%", folderIds.length)}
                <ul>{getFoldersName()}</ul>
              </>
            ) : (
              <span>{getFoldersName()}</span>
            )}
          </div>
        </div>
        <div className="d-flex ">
          <div className="ttp-form-group w-100">
            <label className="ttp-label">{_("collaborator")}</label>
            <CollaboratorsSelect
              isMulti={false}
              selectedCollaborator={collaborator}
              onChange={setCollaborator}
              organizationId={navCommunity.id}
            />
          </div>
          <div className="ttp-form-group mx-2 w-100">
            <label className="ttp-label">{_("delegation_type")}</label>
            <DelegationTypeSelect
              isMulti={false}
              selectedDelegationType={delegationType}
              onChange={setDelegationType}
              organizationId={navCommunity.id}
            />
          </div>

          <div
            className={styles.btTab}
            onClick={() => {
              if (!isSaving) handleSave();
            }}
          >
            {isSaving ? (
              <Loader
                style={{
                  height: "5px",
                }}
                color={"#fff"}
              />
            ) : (
              <i className="icon-ttp-plus"></i>
            )}
          </div>
        </div>
        <List
          isLoading={isLoading}
          data={data}
          setCurrentItem={setCurrentItem}
          setCurrentDeleteItem={setCurrentDeleteItem}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
        />
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={isDeleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default ModalDelegation;
