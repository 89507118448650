import React from "react";
import styles from "./BackButton.module.scss";
import _ from "../../../i18n";
import IconBackArrow from "../icons/IconBackArrow";

const BackButton = ({ onClick, title }) => {
  return (
    <div className={styles.backNav}>
      <button onClick={onClick}>
        <IconBackArrow /> {_("back")}
      </button>
      <span>{title}</span>
    </div>
  );
};

export default BackButton;
