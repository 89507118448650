import { useEffect } from "react";

const PaymentSuccessRedirect = () => {
  useEffect(() => {
    window.parent.location = window.parent.location + "?ps=1"; // paymentSuccess = 1
  }, []);

  return null;
};

export default PaymentSuccessRedirect;
