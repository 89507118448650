import React from "react";

import Tabs from "../../common/Tabs";
import { InstalledApp, FutureApp, OptionApp } from "./";
import styles from "./MyPortals/MyPortals.module.scss";

import _ from "../../../i18n";

const AppsTab = ({ setSideBar }) => {
  const appTabs = ["installed_app", "future_app", "option_app"];
  const components = {
    installed_app: InstalledApp,
    future_app: FutureApp,
    option_app: OptionApp,
  };

  const renderTab = (name) => {
    const DynamicComponent = components[name];
    return <DynamicComponent />;
  };

  return (
    <div>
      <p className={styles.subtitle}>
        Activez le ou les portails dont vous avez besoin et créez la base de
        votre fondation digitale
      </p>
      <Tabs theme="line-theme">
        {appTabs.map((l, i) => (
          <div key={`apptab${i}`} label={_(l)}>
            {renderTab(l)}
          </div>
        ))}
      </Tabs>
    </div>
  );
};

export default AppsTab;
