import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";

import { deleteRate, getRates } from "../../api";
import _ from "../../i18n";

import Button from "../common/Button";
import RatePopOver from "./RatePopOver";
import styles from "./ManageRates.module.scss";

const ManageRates = () => {
  const queryClient = useQueryClient();

  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [openPopOver, setOpenPopOver] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [ratesList, setRatesList] = useState({});
  const [currentRate, setCurrentRate] = useState(null);
  const [deleting, setDeleting] = useState(false);

  let listRates = useQuery(["listRates", token], async () => {
    if (token) {
      try {
        const response = await getRates(token);
        if (response.data.data) {
          let data = {};
          response.data.data.forEach((rate) => {
            if (!data[rate.type]) {
              data[rate.type] = [rate];
            } else {
              data[rate.type].push(rate);
            }
          });
          if (data["COLLABORATOR"]) {
            data["COLLABORATOR"] = data["COLLABORATOR"].sort((a, b) =>
              a.nbFrom < b.nbFrom ? -1 : 1
            );
          }
          const keys = Object.keys(data);
          setRatesList(data);
          return keys;
        }
      } catch (e) {
        return null;
      }
    }
  });

  const onConfirmRateDelete = async () => {
    try {
      setDeleting(true);
      await deleteRate(auth.token, currentRate.id);
      queryClient.invalidateQueries("listRates");
      setCurrentRate(null);
      setDeleteModalOpen(false);
    } catch (e) {}
    setDeleting(false);
  };

  return (
    <div className={styles.ratesPage}>
      <div className={styles.header}>
        <div>
          <h1>{_("rates_list")}</h1>
          <span>{_("manage_rates")}</span>
        </div>
        <Button
          className={styles.addMedia}
          onClick={() => setOpenPopOver(true)}
        >
          {_("add_new_rate")}
        </Button>
      </div>
      {listRates.isLoading && <div className="lmask"></div>}
      {!listRates.isLoading && !listRates.data && (
        <div
          style={{
            textAlign: "center",
            maxWidth: "600px",
            margin: "0 auto 60px",
            color: "#2C394B",
            fontSize: "2rem",
          }}
        >
          <img alt="not found" src="/img/notFound.svg" />
          <div>{_("nothing_found")}</div>
        </div>
      )}

      {!listRates.isLoading &&
        listRates.data &&
        listRates.data.map((rateType) => {
          return (
            <>
              <h3>{rateType}</h3>
              <div className="grid-x grid-margin-x">
                {ratesList[rateType]?.map((rate, index) => (
                  <div key={rate.id} className="cell small-6 medium-3">
                    <div className={styles.rateForm}>
                      {rate.type === "COLLABORATOR" && (
                        <h4 className={styles.appName}>
                          {_("number_from")} {rate.nbFrom} {_("to")} {rate.nbTo}
                        </h4>
                      )}
                      {rate.type === "APP" && (
                        <h4 className={styles.appName}>{rate.app}</h4>
                      )}

                      {rate.type === "OPTION" && (
                        <h4 className={styles.appName}>{rate.appOption}</h4>
                      )}

                      <div className={styles.priceRow}>
                        <span className={styles.price}>{rate.monthPrice}€</span>{" "}
                        / {_("per_month")}
                      </div>

                      <div className={styles.priceRow}>
                        <span className={styles.price}>
                          {rate.annualPrice}€
                        </span>{" "}
                        / {_("per_year")}
                      </div>

                      <div className={styles.priceRow}>
                        <label>{_("status")}</label>
                        <span
                          className={
                            rate.status === "ENABLED"
                              ? styles.activeStatus
                              : styles.disabledStatus
                          }
                        >
                          {_(rate.status.toLowerCase())}
                        </span>
                      </div>

                      <div className={styles.actions}>
                        {!rate.isUsed ? (
                          <div
                            className={styles.actions_del}
                            onClick={() => {
                              setCurrentRate(rate);
                              setDeleteModalOpen(true);
                            }}
                          >
                            <i className="icon-ttp-trash" />
                          </div>
                        ) : (
                          <span></span>
                        )}
                        <div
                          className={styles.actions_edit}
                          onClick={() => {
                            setCurrentRate(rate);
                            setOpenPopOver(true);
                          }}
                        >
                          <i className="icon-ttp-pencil" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          );
        })}

      <ModalConfirm
        type="delete"
        isOpen={deleteModalOpen}
        inProcess={deleting}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={onConfirmRateDelete}
        title={_("delete_confirm")}
        text={_("text_delete_rate")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
      {openPopOver && (
        <RatePopOver
          closePopOver={() => {
            setOpenPopOver(false);
            setCurrentRate(null);
            queryClient.invalidateQueries("listRates");
          }}
          currentRate={currentRate}
        />
      )}
    </div>
  );
};

export default ManageRates;
