import React, { useState } from "react";
import EditContainer from "../EditContainer";
import Banner from "../Banner";
import Stats from "../Stats";
import Clients from "../Clients";
import Team from "../Team";
import Testimonials from "../Testimonials";
import Contact from "../Contact";
import ConfigModal from "../../ConfigModal";
import { useSelector } from "react-redux";
import {
  AboutAppPublic,
  BannerAppPublic,
  HistoryAppPublic,
  MemberMediaAppPublic,
  NumbersAppPublic,
  PartnersAppPublic,
} from "../../../../PortalApps";
import History from "../History";
import Values from "../Values";
import { components } from "react-select";
import { Reorder, useMotionValue } from "framer-motion";
import { useRaisedShadow } from "../../use-raised-shadow";
import RecorderEditContainer from "../ReorderEditContainer";
import { arrayMerge } from "../../../../../../../../utils";
import MediaList from "../../../../../../MediaList";
import { data } from "jquery";
import Gallery from "../Gallery";

const AboutPage = ({
  currentTab,
  editSection,
  setEditSection,
  handlePortalBlocChange,
  handlePortalBlocReorder,
  portals,
  memberMedias,
  setMemberMedias,
  orderMemberSettings,
  titleAttr,
  handlePortalBlocTitleChange,
  partners,
  setPartners,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  aboutImage,
  setAboutImage,
  handleAboutPageBlocChange,
  handleAboutPageBlocDescriptionChange,
  handleAboutPageBlocTitleChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  numbersList,
  numbersImage,
  testimonials,
  collaborators,
  setNumbersList,
  setNumbersImage,
}) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const page = "aboutPage";
  const sections = {
    // banner: {
    //   name: "banner",
    //   key: "aboutPage|banner",
    //   alwaysVisible: true,
    //   data: portals.public.config?.aboutPage?.banner,
    //   component: <Banner data={portals.public.config?.aboutPage?.banner} />,
    // },
    history: {
      name: "history",
      key: "history",
      data: portals.public.config?.history,
      component: <History data={portals.public.config?.history} />,
    },
    values: {
      name: "values",
      key: "values",
      data: portals.public.config?.values,
      component: (
        <Values
          data={portals.public.config?.values}
          valuesList={valuesList}
          valuesVideo={valuesVideo}
        />
      ),
    },
    numbers: {
      name: "numbers",
      key: "numbers",
      data: portals.public.config?.numbers,
      component: (
        <Stats
          data={portals.public.config?.numbers}
          list={numbersList}
          image={numbersImage}
          version={"v3"}
        />
      ),
    },
    team: {
      name: "team",
      key: "team",
      data: portals.public.config?.team,
      component: (
        <Team
          data={portals.public.config.team}
          team={collaborators.filter((el) => el.enableAvatar).slice(0, 8)}
        />
      ),
    },
    partners: {
      name: "partners",
      key: "partners",
      data: portals.public.config.partners,
      component: (
        <Clients
          list={partners}
          data={portals.public.config.partners}
          version={"v3"}
        />
      ),
    },
    testimonials: {
      name: "testimonials",
      key: "testimonials",
      data: portals.public.config?.testimonials,
      component: (
        <Testimonials
          data={portals.public.config.testimonials}
          testimonials={testimonials.filter((el) => el.show).slice(0, 2)}
        />
      ),
    },
    gallery: {
      name: "gallery",
      key: "gallery",
      data: portals.public.config?.gallery,
      component: (
        <Gallery
          data={portals.public.config?.gallery}
          galleryMedias={galleryMedias}
          setGalleryMedias={setGalleryMedias}
        />
      ),
    },
    contact: {
      name: "contact",
      key: "contact",
      data: portals.public.config.contact,
      component: (
        <Contact
          navCommunity={fiduciary}
          publicPortal={portals.public}
          version={"v3"}
          onEdit={editSection === "contact"}
        />
      ),
    },
  };

  const renderAboutModals = () => {
    return (
      <ConfigModal
        isOpen={editSection !== null}
        close={() => setEditSection(null)}
      >
        {editSection === "numbers" && (
          <NumbersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            setNumbersList={setNumbersList}
            numbersList={numbersList}
            maxPicks={orderMemberSettings.v3.stats.maxPick}
            allowImg={false}
            numbersImage={numbersImage}
            setNumbersImage={setNumbersImage}
            inModal={true}
          />
        )}

        {/* {editSection === "memberMedia" && (
          <MemberMediaAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            medias={memberMedias}
            setMedias={setMemberMedias}
            maxPicks={orderMemberSettings.v1.media.maxPick}
            inModal={true}
          />
        )} */}

        {editSection === "partners" && (
          <PartnersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            partners={partners}
            setPartners={setPartners}
            maxPicks={orderMemberSettings.v3.partners.maxPick}
            inModal={true}
          />
        )}
        {/* {editSection === "aboutBanner" && (
          <BannerAppPublic
            bannerKey="aboutPage|banner"
            handlePortalBlocChange={handlePortalBlocChange}
            data={portals.public.config.aboutPage}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            aboutImage={aboutImage}
            setAboutImage={setAboutImage}
            handleAboutPageBlocChange={handleAboutPageBlocChange}
            handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
            handleAboutPageBlocDescriptionChange={
              handleAboutPageBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            editSection={editSection}
            showPageConfig={true}
            inModal={true}
          />
        )} */}
        {editSection === "history" && (
          <HistoryAppPublic
            data={portals.public.config.history}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            inModal={true}
          />
          // <AboutAppPublic
          //   handlePortalBlocChange={handlePortalBlocChange}
          //   portals={portals}
          //   titleAttr={titleAttr}
          //   handlePortalBlocTitleChange={handlePortalBlocTitleChange}
          //   descriptionAttr={descriptionAttr}
          //   handlePortalBlocDescriptionChange={
          //     handlePortalBlocDescriptionChange
          //   }
          //   aboutImage={aboutImage}
          //   setAboutImage={setAboutImage}
          //   handleAboutPageBlocChange={handleAboutPageBlocChange}
          //   handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
          //   handleAboutPageBlocDescriptionChange={
          //     handleAboutPageBlocDescriptionChange
          //   }
          //   valuesList={valuesList}
          //   setValuesList={setValuesList}
          //   valuesVideo={valuesVideo}
          //   setValuesVideo={setValuesVideo}
          //   galleryMedias={galleryMedias}
          //   setGalleryMedias={setGalleryMedias}
          //   editSection={editSection}
          //   showPageConfig={true}
          //   inModal={true}
          // />
        )}
      </ConfigModal>
    );
  };

  const orderedKeys = arrayMerge(
    portals.public.config?.[page]?.order,
    Object.keys(sections)
  );

  const [items, setItems] = useState(orderedKeys);
  const setOrder = (updatedOrder) => {
    setItems(updatedOrder);
    handlePortalBlocReorder(updatedOrder, page + "|order");
  };
  return currentTab === "ABOUT" ? (
    <>
      <EditContainer
        isVisible={true}
        edit={() => {
          setEditSection("aboutBanner");
        }}
      >
        <Banner data={portals.public.config?.[page]?.banner} />
      </EditContainer>
      <Reorder.Group axis="y" values={items} onReorder={setOrder}>
        {items.map((item) => (
          <RecorderEditContainer
            key={item}
            item={item}
            isVisible={
              sections[item].alwaysVisible
                ? true
                : portals.public.config?.[page]?.[item]
            }
            setVisibility={
              sections[item].alwaysVisible
                ? null
                : () =>
                    handlePortalBlocChange(
                      !portals.public.config?.[page]?.[item],
                      page + "|" + sections[item].key
                    )
            }
            edit={() => {
              setEditSection(sections[item].name);
            }}
          >
            {sections[item].component}
          </RecorderEditContainer>
        ))}
      </Reorder.Group>
    </>
  ) : null;
};

export default AboutPage;
