import React from "react";
import styles from "./NewsletterModal.module.scss";
import IconValidateCheck from "../icons/IconValidateCheck";
import IconAlertTriangle from "../icons/IconAlertTriangle";
import { registerEmail } from "../../../api";
import { toast } from "react-toastify";
import _ from "../../../i18n";
import { useSelector } from "react-redux";
import Loader from "../Loader";

const CollaboratorBlock = ({
  sender,
  removeClick,
  verifiedEmailsList,
  handleFetchVerifiedEmails,
}) => {
  const auth = useSelector((state) => state.auth);

  const { token } = auth;
  const verifiedEmail = verifiedEmailsList.find(
    (emailObj) =>
      emailObj.email === sender.email &&
      emailObj.VerificationStatus === "Success"
  );
  const isPendingEmail = verifiedEmailsList.find(
    (emailObj) =>
      emailObj.email === sender.email &&
      emailObj.VerificationStatus === "Pending"
  );

  const handleRegisterEmail = () => {
    registerEmail(token, sender.email)
      .then((resp) => {
        handleFetchVerifiedEmails();
      })
      .catch((e) => {
        toast.error(_("error"));
      });
  };

  return (
    <div className={styles.collab_block}>
      {isPendingEmail && (
        <div className={styles.info}>
          <span>
            Un email a été envoyé par Amazon à l'adresse email Répondez-y et
            cliquez sur Rafraîchir
          </span>
        </div>
      )}
      <div className={styles.block_name}>
        <span className={styles.name}>{sender.label}</span>
        <i className="icon-ttp-trash" onClick={() => removeClick(sender)} />
      </div>
      <div className={styles.block_email}>
        <span className={styles.email}>{sender.email}</span>
        {verifiedEmail ? <IconValidateCheck /> : <IconAlertTriangle />}
      </div>
      {!verifiedEmail && (
        <>
          <span className={styles.warning}> Email non confirmé !</span>
          {isPendingEmail ? (
            <>
              <button className={styles.load_btn}>
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#06d9b1"}
                />
              </button>
              <span
                className={styles.refresh}
                onClick={() => handleFetchVerifiedEmails()}
              >
                {" "}
                Rafraîchir
              </span>
            </>
          ) : (
            <button
              className={styles.confirm_btn}
              onClick={() => handleRegisterEmail()}
            >
              Envoyer une confirmation
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default CollaboratorBlock;
