import axios from "axios";

import { TTP_API_URL } from "../config";

export const saveTestimonial = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-testimonial`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("author", data.author);
  formData.append("headline", data.headline);
  formData.append("descriptionFr", data.descriptionFr);
  formData.append("descriptionEn", data.descriptionEn);
  formData.append("descriptionNl", data.descriptionNl);
  formData.append("organization", data.organization);

  if (data.id) {
    formData.append("id", data.id);
  }
  return await axios.post(requestUrl, formData);
};

export const getTestimonials = (token, organization) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-testimonial`;
  const serviceFilter = [];
  if (organization) {
    serviceFilter.push({
      property: "organization",
      value: organization,
      operator: "eq",
    });
  }
  const sort = [
    {
      property: "updatedAt",
      dir: "desc",
    },
  ];
  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(serviceFilter),
      sort: JSON.stringify(sort),
    },
  });
};

export const deleteTestimonial = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-testimonial/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};
