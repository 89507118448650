import React from "react";
import parse from "html-react-parser";
import classnames from "classnames";

import styles from "./FormInput.module.scss";

const FormInput = ({
  label,
  type,
  className,
  labelClassName,
  error,
  info,
  required,
  inputRef,
  handleBlur,
  handleKeyUp,
  handleChange,
  ...otherProps
}) => {
  const { disabled, hideLockIcon } = otherProps;

  return (
    <div className={styles.group}>
      <label className={classnames(styles.label, labelClassName)}>
        {label}
        {required ? <span className={styles.star}>*</span> : null}
        {disabled && !hideLockIcon ? (
          <span className={styles.star}>
            <i className="icon-ttp-locked"></i>
          </span>
        ) : null}
        {info ? (
          <div className={styles.info}>
            <i className="icon-ttp-alert-circle"></i>
            <ul>
              <li>{parse(info)}</li>
            </ul>
          </div>
        ) : null}
      </label>
      {type === "textarea" ? (
        <textarea
          className={classnames(styles.input, className)}
          ref={inputRef}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          {...otherProps}
        ></textarea>
      ) : (
        <input
          className={classnames(
            styles.input,
            error ? styles.error : "",
            className
          )}
          ref={inputRef}
          onChange={handleChange}
          onKeyUp={handleKeyUp}
          onBlur={handleBlur}
          type={
            type === "password" ? "password" : type === "date" ? "date" : "text"
          }
          {...otherProps}
        />
      )}
      <span className={styles.error}>{error}</span>
    </div>
  );
};

export default FormInput;
