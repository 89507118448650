import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import Select from "react-select";
import { toast } from "react-toastify";

import Loader from "../../common/Loader";
import Button from "../../common/Button";
import FormInput from "../../common/FormInput";
import _ from "../../../i18n";
import {
  saveClient,
  getClientsByUen,
  getFolderInfoByUen,
  getUserByEmail,
} from "../../../api";
import { LANGUAGES, LEGAL_FORM, SELECT_STYLES } from "../../../config";
import {
  validateEmail,
  validateUen,
  formatPhone,
  formatHidePhone,
  obscureEmail,
} from "../../../utils";
import styles from "./Client.module.scss";

let isFetchingEmail = false;
let contactEmailId = null;

const ClientForm = (props) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isFetchingCompany, setIsFetchingCompany] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [fetchedFolder, setFetchedFolder] = useState(null);
  const [showConfirmFolder, setShowConfirmFolder] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const [disableContactFields, setDisableContactFields] = useState(false);
  let saveInterval;

  const initErrors = {
    uen: "",
    name: "",
    legalForm: "",
    languagePreferences: "",
    contactEmail: "",
    contactFirstName: "",
    contactLastName: "",
    contactPhone: "",
  };
  const initData = {
    id: "",
    uen: "",
    name: "",
    legalForm: { value: "SRL", label: LEGAL_FORM.SRL },
    languagePreferences: [LANGUAGES[0]],
    contactId: "",
    contactEmailId: "",
    contactEmail: "",
    contactEmailValue: "",
    contactFirstName: "",
    contactLastName: "",
    contactPhone: "",
    contactPhoneId: "",
    contactPhoneValue: "",
  };
  const [errors, setErrors] = useState(initErrors);
  const [data, setData] = useState(initData);

  useEffect(() => {
    if (props.currentItem) {
      let languagePreferences = [];
      if (
        props.currentItem.languagePreferences &&
        props.currentItem.languagePreferences.length > 0
      ) {
        props.currentItem.languagePreferences.forEach((lng) => {
          LANGUAGES.forEach((i) => {
            if (i.value === lng) {
              languagePreferences.push(i);
            }
          });
        });
      }

      let emailResult = props.currentItem.legalRepresentative.email.filter(
        (i) => i.email === props.currentItem.legalRepresentative.mainEmail
      );
      let phoneResult = props.currentItem.legalRepresentative.phone.filter(
        (i) => i.number === props.currentItem.legalRepresentative.mainPhone
      );
      setData({
        id: props.currentItem.id,
        uen: props.currentItem.uen,
        name: props.currentItem.name,
        legalForm: {
          value: props.currentItem.legalForm,
          label: LEGAL_FORM[props.currentItem.legalForm],
        },
        languagePreferences: languagePreferences ? languagePreferences : "",
        contactId: props.currentItem.legalRepresentative.id,
        contactEmailId: emailResult[0].id,
        contactEmail: props.currentItem.legalRepresentative.mainEmail,
        contactEmailValue: props.currentItem.legalRepresentative.mainEmail,
        contactFirstName: props.currentItem.legalRepresentative.firstName,
        contactLastName: props.currentItem.legalRepresentative.lastName,
        contactPhone: props.currentItem.legalRepresentative.mainPhone,
        contactPhoneValue: props.currentItem.legalRepresentative.mainPhone,
        contactPhoneId:
          phoneResult && phoneResult.length > 0 ? phoneResult[0].id : "",
      });
      setDisableFields(false);
    }
  }, [props.currentItem]);

  useEffect(() => {
    setIsValidForm(
      errors.uen ||
        errors.name ||
        errors.legalForm ||
        errors.languagePreferences ||
        errors.contactEmail ||
        errors.contactFirstName ||
        errors.contactLastName ||
        errors.contactPhone
        ? false
        : true
    );
  }, [
    errors.uen,
    errors.name,
    errors.legalForm,
    errors.languagePreferences,
    errors.contactEmail,
    errors.contactFirstName,
    errors.contactLastName,
    errors.contactPhone,
  ]);

  const handleKeyUp = (fieldName, fieldValue) => {
    let value = "";
    if (fieldValue.length === 0) {
      value = _("required_field");
    } else {
      switch (fieldName) {
        case "uen":
          if (!validateUen(fieldValue)) {
            value = _("validate_uen");
          }
          break;
        case "name":
          if (fieldValue.length < 2) {
            value = _("required_2_characters");
          }
          break;
        case "contactEmail":
          if (!validateEmail(fieldValue)) {
            value = _("validate_email");
          }
          break;
        case "contactPhone":
          if (fieldValue.length < 10) {
            value = _("required_field");
          }
          break;
        default:
          break;
      }
    }
    setErrors({ ...errors, [fieldName]: value });
  };

  const handleEmailChange = (name, value) => {
    if (value.length === 0)
      setErrors({ ...errors, [name]: _("required_field") });
    else if (value.length > 0 && !validateEmail(value)) {
      setErrors({ ...errors, email: _("validate_email") });
    }
    setData({ ...data, [name]: value, contactEmailValue: value });
  };

  const handleSave = () => {
    if (!isFetchingEmail && !isSaving) {
      let clientData = {
        ...data,
        legalForm: data.legalForm.value,
        languagePreferences: data.languagePreferences.map((lng) => lng.value),
        owner: fiduciary.id,
        isEdit: props.currentItem,
      };
      if (
        fiduciary &&
        fetchedFolder &&
        fetchedFolder.owner &&
        fetchedFolder.owner.id !== fiduciary.id
      ) {
        clientData.owner = fiduciary.id;
      }
      clearInterval(saveInterval);
      if (contactEmailId) {
        clientData.contactEmailId = contactEmailId;
      }
      setIsSaving(true);
      saveClient(auth.token, clientData)
        .then((resp) => {
          setIsSaving(false);
          props.afterSave();
          setData(initData);
          props.setCurrentItem(null);
          toast.success(_("successfully_added"));
          clearInterval(saveInterval);
        })
        .catch((e) => {
          setIsSaving(false);
          clearInterval(saveInterval);
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          )
            toast.error(_(e.response.data.errors[0].message));
          else toast.error(_("error"));
        });
    }
  };

  const save = () => {
    setIsSaving(true);
    saveInterval = setInterval(handleSave, 800);
  };

  const handleUenKeyUp = (name, value) => {
    let error = "";
    if (!validateUen(value)) error = _("validate_uen");
    else error = "";
    if (value.length === 0) error = _("required_field");
    setErrors({ ...errors, [name]: error });
  };

  const handleConfirmFolderByUen = (folder = null) => {
    if (!folder) {
      folder = fetchedFolder;
    }
    let folderData = {
      id: folder.id,
      uen: folder.uen,
      name: folder.name,
    };
    if (folder.legalForm) {
      folderData.legalForm = {
        value: folder.legalForm,
        label: LEGAL_FORM[folder.legalForm],
      };
    }
    if (folder.legalRepresentative) {
      let emailResult = folder.legalRepresentative.email.filter(
        (i) => i.email === folder.legalRepresentative.mainEmail
      );
      let phoneResult = folder.legalRepresentative.phone.filter(
        (i) => i.number === folder.legalRepresentative.mainPhone
      );

      folderData = {
        ...folderData,
        contactId: folder.legalRepresentative.id,
        contactEmailId: emailResult[0].id,
        contactEmail: folder.legalRepresentative.mainEmail,
        contactEmailValue: obscureEmail(folder.legalRepresentative.mainEmail),
        contactFirstName: folder.legalRepresentative.firstName,
        contactLastName: folder.legalRepresentative.lastName,
        contactPhone: folder.legalRepresentative.mainPhone,
        contactPhoneValue: formatHidePhone(
          folder.legalRepresentative.mainPhone
        ),
        contactPhoneId:
          phoneResult && phoneResult.length > 0 ? phoneResult[0].id : "",
      };
    }
    setData({
      ...data,
      ...folderData,
    });
    setErrors({ ...errors, name: "" });
    setDisableFields(true);
    setShowConfirmFolder(false);
  };

  const handleUenBlur = (value) => {
    if (validateUen(value)) {
      setErrors({ ...errors, uen: "" });
      setIsFetchingCompany(true);

      getClientsByUen({
        token: auth.token,
        uens: [value.replace(/\.|\s|BE|be/g, "")],
      })
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            setFetchedFolder(response.data.data[0]);
            if (
              fiduciary &&
              response.data.data[0].owner &&
              response.data.data[0].owner.id !== fiduciary.id
            ) {
              setShowConfirmFolder(true);
            } else {
              handleConfirmFolderByUen(response.data.data[0]);
              // setData({
              //   ...data,
              //   uen: response.data.data[0].uen,
              //   name: response.data.data[0].name,
              // });
              // setErrors({ ...errors, name: "" });
              // setDisableFieldsUen(true);
            }
          }
        })
        .catch((e) => {
          if (fetchedFolder) {
            setData({
              ...data,
              id: "",
              name: "",
              legalForm: { value: "SRL", label: LEGAL_FORM.SRL },
              languagePreferences: [LANGUAGES[0]],
              contactId: "",
              contactEmailId: "",
              contactEmail: "",
              contactEmailValue: "",
              contactFirstName: "",
              contactLastName: "",
              contactPhone: "",
              contactPhoneId: "",
              contactPhoneValue: "",
            });
          }
          setFetchedFolder(null);
          if (e.response && e.response.status === 404) {
            getFolderInfoByUen({
              token: auth.token,
              uen: value,
            })
              .then((res) => {
                const resp = res.data.data;
                if (resp.isValid) {
                  setData({
                    ...data,
                    name: resp.name,
                  });
                  setErrors({ ...errors, name: "" });
                }
                // else {
                //   setData({ ...data, name: "" });
                // }
              })
              .finally(() => {
                setIsFetchingCompany(false);
                setDisableFields(false);
              });
          }
        })
        .finally(() => {
          setIsFetchingCompany(false);
        });
    } else {
      if (value.length === 0)
        setErrors({ ...errors, uen: _("required_field") });
      else setErrors({ ...errors, uen: _("validate_uen") });
    }
  };

  const handlePhoneBlur = (name, value) => {
    if (value.length < 6) setErrors({ ...errors, [name]: _("required_field") });
    else {
      setData({ ...data, contactPhone: formatPhone(value) });
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handleBlur = (name, value) => {
    if (value.length === 0)
      setErrors({ ...errors, [name]: _("required_field") });
    else setErrors({ ...errors, [name]: "" });
  };

  const handleLanguageChange = (option) => {
    if (option.length === 0)
      setErrors({ ...errors, languagePreferences: _("required_field") });
    else setErrors({ ...errors, languagePreferences: "" });
    setData({ ...data, languagePreferences: option });
  };

  const handleChange = (name, value) => {
    if (value.length === 0) {
      setErrors({ ...errors, [name]: _("required_field") });
    } else {
      setErrors({ ...errors, [name]: _("") });
    }
    if (name === "contactPhone") {
      setData({ ...data, [name]: value, contactPhoneValue: value });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleEmailBlur = (e) => {
    if (data.contactEmail.length > 0 && validateEmail(data.contactEmail)) {
      setIsFetchingUser(true);
      isFetchingEmail = true;
      getUserByEmail(auth.token, data.contactEmail)
        .then((resp) => {
          if (resp.data.data.length > 0) {
            let emailResult = resp.data.data[0].email.filter(
              (i) => i.email === data.contactEmail
            );
            if (emailResult.length > 0) {
              let phoneResult = resp.data.data[0].phone.filter(
                (i) => i.number === resp.data.data[0].mainPhone
              );

              setDisableContactFields(true);
              setData({
                ...data,
                contactFirstName: resp.data.data[0].firstName,
                contactLastName: resp.data.data[0].lastName,
                contactEmailId: emailResult[0].id,
                contactPhone: resp.data.data[0].mainPhone
                  ? resp.data.data[0].mainPhone
                  : "",
                contactPhoneValue: resp.data.data[0].mainPhone
                  ? formatHidePhone(resp.data.data[0].mainPhone)
                  : "",
                contactPhoneId:
                  phoneResult && phoneResult.length > 0
                    ? phoneResult[0].id
                    : "",
              });
              contactEmailId = emailResult[0].id;
            }

            setIsFetchingUser(false);
            isFetchingEmail = false;
          }
        })
        .catch((e) => {
          setIsFetchingUser(false);
          setDisableContactFields(false);
          isFetchingEmail = false;
          contactEmailId = null;
          setData({ ...data, contactEmailId: "", contactPhoneId: "" });
        });
    }
  };

  return (
    <div className={props.addFormClass ? styles.form : ""}>
      <div
        className={`${styles.form_content} ${
          props.addFormClass && styles.form_content_pad
        } ${isFetchingUser && styles.loading}`}
        style={{ position: "relative" }}
      >
        {isFetchingCompany && <div className="lmask"></div>}
        <div className={styles.titles}>
          <h4>Ajout Manuel d'un client</h4>
          <p>Remplissez les champs pour ajouter un client</p>
        </div>
        <FormInput
          name="uen"
          required={true}
          info={_("uen_info")}
          label={_("business_number")}
          autoComplete="none"
          error={errors.uen}
          value={data.uen}
          placeholder="BE XXXX.XXX.XXX"
          onKeyUp={(e) => handleUenKeyUp(e.target.name, e.target.value)}
          handleBlur={(e) => handleUenBlur(e.target.value)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
        />

        <FormInput
          name="name"
          value={data.name}
          required={true}
          label={_("Nom d’entreprise")}
          error={errors.name}
          autoComplete="none"
          handleBlur={(e) => handleBlur(e.target.name, e.target.value)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          disabled={disableFields}
        ></FormInput>

        <div className="ttp-form-group">
          <label className="ttp-label">{_("legal_form")}</label>
          <Select
            styles={SELECT_STYLES}
            options={Object.entries(LEGAL_FORM).map(([k, v]) => {
              return { value: k, label: v };
            })}
            isSearchable={false}
            required={true}
            value={data.legalForm}
            onChange={(e) => setData({ ...data, legalForm: e })}
            isDisabled={disableFields}
          />
        </div>

        <div className="ttp-form-group">
          <label className="ttp-label">{_("language")}</label>
          <Select
            isMulti
            styles={SELECT_STYLES}
            options={LANGUAGES}
            isSearchable={false}
            required={true}
            value={data.languagePreferences}
            onChange={(e) => handleLanguageChange(e)}
            isDisabled={disableFields}
          />
          {errors.languagePreferences && (
            <div className="ttp-error">{errors.languagePreferences}</div>
          )}
        </div>

        <div className={styles.linkedContact}>{_("linked_contact")}</div>
        <FormInput
          name="contactEmail"
          required={true}
          label={_("email_address")}
          autoComplete="none"
          error={errors.contactEmail}
          value={data.contactEmailValue}
          handleBlur={(e) => handleEmailBlur(e)}
          onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
          onChange={(e) => handleEmailChange(e.target.name, e.target.value)}
          disabled={disableFields}
        />
        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <FormInput
              name="contactLastName"
              required={true}
              label={_("lastname")}
              autoComplete="none"
              error={errors.contactLastName}
              value={data.contactLastName}
              handleBlur={(e) => handleBlur(e.target.name, e.target.value)}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              disabled={disableFields || disableContactFields}
            />
          </div>
          <div className="cell small-12 medium-6">
            <FormInput
              name="contactFirstName"
              required={true}
              label={_("firstname")}
              autoComplete="none"
              error={errors.contactFirstName}
              value={data.contactFirstName}
              handleBlur={(e) => handleBlur(e.target.name, e.target.value)}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              disabled={disableFields || disableContactFields}
            />
          </div>
        </div>
        <FormInput
          name="contactPhone"
          required={true}
          label={_("phone_number")}
          placeholder="+32XXXXXXXX"
          autocomplete="none"
          value={data.contactPhoneValue}
          error={errors.contactPhone}
          onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
          onChange={(e) => handleChange(e.target.name, e.target.value)}
          handleBlur={(e) => handlePhoneBlur(e.target.name, e.target.value)}
          disabled={disableFields || disableContactFields}
        />
      </div>
      <div className={styles.actions}>
        <Button
          variant="default"
          onClick={(e) => {
            setData(initData);
            setErrors(initErrors);
            if (props.setSideBar) props.setSideBar("DEFAULT");
          }}
        >
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button disabled={!isValidForm} onClick={() => save()}>
            {props.currentItem ? _("edit") : _("add")}
          </Button>
        )}
      </div>

      <ModalConfirm
        type="publish"
        isOpen={showConfirmFolder}
        onCancel={() => {
          setData(initData);
          setShowConfirmFolder(false);
          setDisableFields(false);
        }}
        onConfirm={() => handleConfirmFolderByUen()}
        title={_("add_confirm")}
        text={_("text_add_exist_client")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default ClientForm;
