import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import ReactDropzone from "react-dropzone";

import IconClose from "../icons/IconClose";
import IconUpload from "../icons/IconUpload";
import styles from "./AddSingleMedia.module.scss";
import _ from "../../../i18n";
import { getMedias, saveMedia } from "../../../api";
import MediaList from "../../Settings/MediaList";
import Loader from "../Loader";

const AddSingleMedia = ({ isOpen, close, token, community, setImage }) => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const [newFile, setNewFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [tmpMedias, setTmpMedias] = useState([]);
  const { isLoading } = useQuery(
    ["getMedias", auth.token, isOpen],
    async () => {
      if (auth.token && isOpen) {
        const response = await getMedias({
          token: auth.token,
          communityId: community.id,
          language: lng,
          ids: [],
        });
        setTmpMedias(
          response.data.data
            .map((item) => {
              if (item.id) {
                return { ...item, checked: false };
              }
              return null;
            })
            .filter(Boolean)
        );
      }
    }
  );

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setUploading(true);
      setNewFile(acceptedFiles[0]);
      const res = await saveMedia({
        token,
        community,
        file: acceptedFiles[0],
      });
      setImage(res.data.data);
      close();
    }
  };
  const onClose = () => {
    setTmpMedias([]);
    setNewFile(null);
    setUploading(false);
    close();
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => onClose()}
      className={{
        base: styles.modalContent,
        afterOpen: styles.modalContentAfterOpen,
        beforeClose: styles.modalContentBeforeClose,
      }}
      overlayClassName={{
        base: styles.modalOverlay,
        afterOpen: styles.modalOverlayAfterOpen,
        beforeClose: styles.modalOverlayBeforeClose,
      }}
      closeTimeoutMS={300}
      contentLabel="Add media"
    >
      <div className={styles.modal}>
        <div className={styles.close} onClick={() => onClose()}>
          <IconClose size={17} />
        </div>
        <div className={styles.body}>
          <ReactDropzone
            onDrop={onDrop}
            accept={"image/*"}
            className={styles.dropzone}
            activeClassName={styles.dropzoneActive}
            rejectClassName={styles.dropzoneReject}
          >
            {({ getRootProps, getInputProps }) => (
              <div className={styles.dzContent} {...getRootProps()}>
                <IconUpload size={51} />

                <p className={styles.dropzoneTextLarge}>
                  {_(
                    "Drag and drop files here, or click to select the files to download."
                  )}
                </p>
                <input {...getInputProps()} />
                <button>{_("Select file")}</button>
              </div>
            )}
          </ReactDropzone>
          <h4 className={styles.title}>{_("existing_medias")}</h4>
          <div className={styles.mediasList}>
            {isLoading || tmpMedias.length === 0 ? (
              <Loader
                color={"#fff"}
                style={{
                  width: "100%",
                  height: "0.5rem",
                  margin: "auto",
                }}
              />
            ) : (
              <MediaList
                medias={tmpMedias}
                setMedias={setTmpMedias}
                language={lng}
                isUsed={false}
                newFile={newFile}
                uploading={uploading}
                setSingleImage={(val) => {
                  setImage(val);
                  onClose();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddSingleMedia;
