import classNames from "classnames";
import React from "react";

import styles from "./ServiceContent.module.scss";

const ServiceContent = ({
  service,
  lng,
  openModal,
  setCurrentService,
  openConfirmation,
  checkList,
  setCheckList,
  allowEdit = false,
  allowDelete = false,
  home,
  biblio,
}) => {
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  return (
    <div
      className={classNames(
        styles.service,
        checkList && checkList.map((el) => el.id).includes(service.id)
          ? styles.checked
          : ""
      )}
      style={{
        height: home ? "320px" : biblio ? "200px" : "160px",
        cursor: biblio ? "pointer" : "",
      }}
      onClick={() => {
        if (checkList && checkList.map((el) => el.id).includes(service.id)) {
          setCheckList(checkList.filter((el) => el.id !== service.id));
        } else {
          setCheckList([...checkList, service]);
        }
      }}
    >
      <div
        className={styles.icon}
        style={{
          height: home ? "100px" : "70px",
          width: home ? "100px" : "70px",
          marginTop: home ? "4%" : "",
        }}
      >
        <div className={styles.blueCircle}>
          {service.icon && (
            <i
              className={`icon webtool-${service.icon.label} `}
              style={{
                fontSize: home ? "35px" : "25px",
              }}
            />
          )}
        </div>
      </div>
      <h3 style={{ fontSize: home ? "20px" : "14px" }}>
        {!home && !biblio && service[titleAttr].length > 13
          ? service[titleAttr].substring(0, 13) + "..."
          : biblio && service[titleAttr].length > 20
          ? service[descriptionAttr].substring(0, 20) + "..."
          : service[titleAttr]}
      </h3>
      <p style={{ fontSize: home ? "14px" : "12px" }}>
        {!home && !biblio && service[descriptionAttr].length > 40
          ? service[descriptionAttr].substring(0, 40) + "..."
          : biblio && service[descriptionAttr].length > 100
          ? service[descriptionAttr].substring(0, 100) + "..."
          : home && service[descriptionAttr].length > 160
          ? service[descriptionAttr].substring(0, 160) + "..."
          : service[descriptionAttr]}
      </p>
      {(allowDelete || allowEdit) && (
        <div className={styles.editDelete}>
          {allowEdit && (
            <button
              onClick={() => {
                openModal();
                setCurrentService(service);
              }}
            >
              <i className="icon-ttp-edit" />
            </button>
          )}
          <button
            onClick={() => {
              openConfirmation();
              setCurrentService(service);
            }}
          >
            <i className="icon-ttp-trash" />
          </button>
        </div>
      )}
    </div>
  );
};

export default ServiceContent;
