import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import md5 from "crypto-js/md5";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Loader from "../common/Loader";
import Button from "../common/Button";

import { fetchFiduciary } from "../../store";
import { getUpgradeRate, addUpgradeOrder } from "../../api";
import _ from "../../i18n";

import styles from "./UpgradeModal.module.scss";

const UpgradeModal = ({ showModal, setShowModal }) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const language = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const { token, navCommunity } = auth;
  const [rate, setRate] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNewRate, setIsLoadingNewRate] = useState(false);
  const [countCollaborators, setCountCollaborators] = useState(5);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let selectOptions = [];
  for (let i = 5; i < 500; i += 5) {
    selectOptions.push({ value: i, label: i });
  }

  useEffect(() => {
    if (token && fiduciary && showModal) {
      setIsLoading(true);
      getUpgradeRate(token, fiduciary.id, countCollaborators)
        .then((response) => {
          if (response.data.data) {
            setRate(response.data.data);
            setIsLoading(false);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          setRate({});
          setShowModal(false);
          toast.error(_("rate_notfound"));
        });
    }
  }, [token, fiduciary, showModal]);

  const handleSave = () => {
    if (rate.frequency === "YEARLY") {
      navigate(
        `/upgrade?count=${countCollaborators}&key=${md5(
          countCollaborators + "UACONTROL"
        )}`
      );
    } else {
      setIsSaving(true);
      addUpgradeOrder({
        token,
        organizationId: fiduciary.id,
        countCollaborators,
        period: rate.frequency,
        language,
      })
        .then((response) => {
          if (response.data.data) {
            dispatch(fetchFiduciary());
            toast.success(_("upgrade_successfully"));
            setShowModal(false);
            setIsSaving(false);
          }
        })
        .catch((e) => {
          setIsSaving(false);
          toast.error(_("error_add_order"));
        });
    }
  };

  const handleCollabClick = (action) => {
    let updateRate = false;
    let newCount = 5;
    if (action === "plus") {
      newCount = countCollaborators + 5;
      updateRate = true;
    } else {
      if (countCollaborators > 5) {
        newCount = countCollaborators - 5;
        updateRate = true;
      }
    }
    setCountCollaborators(newCount);
    if (updateRate && rate?.frequency === "YEARLY") {
      setIsLoadingNewRate(true);
      getUpgradeRate(token, fiduciary.id, newCount)
        .then((response) => {
          if (response.data.data) {
            setRate(response.data.data);
            setIsLoadingNewRate(false);
          }
        })
        .catch((e) => {
          setIsLoadingNewRate(false);
        });
    }
  };

  const getFiduciarySize = (isNew) => {
    const size = fiduciary.officeSize.split("-");
    if (size.length === 2) {
      let min = parseInt(size[0]);
      let max = parseInt(size[1]);
      if (isNew) {
        // if (min === 1) {
        //   min = 0;
        // }
        min += countCollaborators;
        max += countCollaborators;
      }
      return `${min}+${max} ${_("collaborators")}.`;
    }
  };

  const getNewPrice = () => {
    if (rate.frequency === "DAILY") {
      return (
        rate.currentPriceDaily + (countCollaborators / 5) * rate.priceDaily
      );
    } else if (rate.frequency === "MONTHLY") {
      return (
        rate.currentPriceMonth + (countCollaborators / 5) * rate.priceMonth
      );
    } else {
      return rate.currentPriceYear + (countCollaborators / 5) * rate.priceYear;
    }
  };

  return (
    <>
      <Modal
        width="70vw"
        height="60vh"
        closable={false}
        visible={showModal}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation();
          setShowModal(false);
        }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.modal_header}>
          <i className="icon-ttp-settings-alt"></i>{" "}
          {"Ajouter des collaborateurs"}
        </div>
        <div className={styles.modal_content}>
          <div className={styles.top}>
            <h3>Abonnement United Associates</h3>
            <p>
              L'adhésion au réseau se calcule sur la base de la taille de votre
              cabinet (nombre de collaborateur). Vous disposez d'une simulation
              du prix immédiate.
            </p>
          </div>

          {isLoading || !rate ? (
            <div className={styles.loadBox}>
              <Skeleton count={4} height={50} />
            </div>
          ) : (
            <>
              <h3 className={styles.pack}>Abonnement actuel</h3>
              <div className={styles.box}>
                <div className={styles.plan}>
                  {rate.frequency === "DAILY" && (
                    <>
                      <p className={styles.plan_name}>
                        Paiement Journalier
                        <span>{getFiduciarySize()}</span>
                      </p>
                      <p className={styles.plan_right}>
                        <span className={styles.plan_price}>
                          {rate.currentPriceDaily}€
                        </span>
                        <span className={styles.plan_info}>HTVA /par jour</span>
                      </p>
                    </>
                  )}
                  {rate.frequency === "MONTHLY" && (
                    <>
                      <p className={styles.plan_name}>
                        Paiement Mensuel
                        <span>{getFiduciarySize()}</span>
                      </p>
                      <p className={styles.plan_right}>
                        <span className={styles.plan_price}>
                          {rate.currentPriceMonth}€
                        </span>
                        <span className={styles.plan_info}>HTVA /par mois</span>
                      </p>
                    </>
                  )}
                  {rate.frequency === "YEARLY" && (
                    <>
                      <p className={styles.plan_name}>
                        Paiement Annuel <span>{getFiduciarySize()}</span>
                      </p>
                      <p className={styles.plan_right}>
                        <span className={styles.plan_price}>
                          {rate.currentPriceYear}€{" "}
                        </span>
                        <span className={styles.plan_info}>HTVA /par an</span>
                      </p>
                    </>
                  )}
                </div>

                <div className={styles.box_users}>
                  <div className={styles.box_users_name}>
                    Nombre de collaborateurs
                    <span>
                      Ajouter par pack de 5 (
                      {rate.frequency === "MONTHLY"
                        ? rate.priceMonth
                        : rate.priceYear}
                      €)
                    </span>
                  </div>
                  <div className={styles.countBox}>
                    <span
                      className={styles.minus}
                      onClick={() => handleCollabClick("minus")}
                    >
                      -
                    </span>
                    <span className={styles.value}>{countCollaborators}</span>
                    <span
                      className={styles.plus}
                      onClick={() => handleCollabClick("plus")}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.summary}>
                <h3 className={styles.pack}>Nouvel abonnement</h3>
                <div className={`${styles.box} ${styles.checked} `}>
                  {rate.frequency === "DAILY" && (
                    <div className={styles.plan}>
                      <p className={styles.plan_name}>
                        Paiement Journalier
                        <span>{getFiduciarySize(true)}</span>
                      </p>
                      <p className={styles.plan_right}>
                        <span className={styles.plan_price}>
                          {getNewPrice()}€
                        </span>
                        <span className={styles.plan_info}>HTVA /par mois</span>
                      </p>
                    </div>
                  )}

                  {rate.frequency === "MONTHLY" && (
                    <div className={styles.plan}>
                      <p className={styles.plan_name}>
                        Paiement Mensuel
                        <span>{getFiduciarySize(true)}</span>
                      </p>
                      <p className={styles.plan_right}>
                        <span className={styles.plan_price}>
                          {getNewPrice()}€
                        </span>
                        <span className={styles.plan_info}>HTVA /par mois</span>
                      </p>
                    </div>
                  )}

                  {rate.frequency === "YEARLY" && (
                    <div className={styles.newbox}>
                      {isLoadingNewRate && (
                        <div className={styles.overlay}>
                          <span className={styles.spinner}></span>
                        </div>
                      )}
                      <div className={styles.plan}>
                        <p className={styles.plan_name}>
                          Paiement Annuel <span>{getFiduciarySize(true)}</span>
                        </p>
                        <p className={styles.plan_right}>
                          <span className={styles.plan_price}>
                            {getNewPrice()}€{" "}
                          </span>
                          <span className={styles.plan_info}>HTVA /par an</span>
                        </p>
                      </div>
                      <div className={styles.plan}>
                        <p className={styles.plan_name}>
                          Reste à payer maintenant{" "}
                          <span>
                            Solde correspondant à la différence entre le nouvel
                            et l'ancien abonnement
                          </span>
                        </p>
                        <p className={styles.plan_right}>
                          <span className={styles.plan_price}>
                            {rate.remainPrice}€{" "}
                          </span>
                          <span className={styles.plan_info}>HTVA</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        <div className={styles.actions}>
          <Button variant="default" onClick={() => setShowModal(false)}>
            {_("close")}
          </Button>

          {isSaving ? (
            <Button
              variant="send"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={handleSave} variant="send">
              {rate?.frequency === "YEARLY" ? _("continue") : _("save")}
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default UpgradeModal;
