import React from "react";
import { AppCard } from "../../";

const OptionApp = () => {
  const apps = [
    {
      name: "Accounting",
      icon: "dashboard",
      desc: "Organiser la présentation de vos travaux annuels après de vos clients en utilisant le nouvel outil de présentation en format slides show (Dashboard).",
      dispo: 'Seulement "sur demande"',
    },
    {
      name: "Power Team",
      icon: "powerteam",
      desc: "Disposez d'un outil de gestion de vos prestations pour améliorer le rendement.  Cet outil exploite les données de vos ERP actuels par synchronisation.",
      dispo: 'Seulement "sur demande"',
    },
  ];
  return (
    <div className="grid-x grid-margin-x grid-margin-y">
      {apps.map((app, index) => (
        <div className="cell small-12 medium-6" key={`futre-${index}`}>
          <AppCard key={`appCard-${index}`} app={app} type="option" />
        </div>
      ))}
    </div>
  );
};

export default OptionApp;
