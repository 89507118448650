import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { HeaderUA } from "tamtam-components";
import $ from "jquery";
import { useDispatch } from "react-redux";

import DrawerIframe from "../common/DrawerIframe";
import { APP_ENV, TTP_HELP_URL } from "../../config";
import { login, setAuthFetched, setAuthToken } from "../../store";
import Menu from "../Layout/Menu";
import Footer from "../Layout/Footer";
import { getAuthInfosFromCookie } from "../../utils";
import { getPublicClientCredential } from "../../api";

const Privacy = () => {
  const location = useLocation();

  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const dispatch = useDispatch();

  const params = [];
  params.push("without_header=1");
  params.push("without_submenu=1");
  params.push("without_backnav=1");
  if (location.pathname.includes("terms_of_use")) {
    params.push("current_app=UNITED_ASSOCIATES");
  }
  // let faqUrl = TTP_HELP_URL + "/" + lng;
  let faqUrl = "https://help.tamtam.pro/" + lng;

  faqUrl += location.pathname + location.search;

  if (window) window.scrollTo({ top: 0, behavior: "smooth" });

  const AppData = {
    appName: "United Associates",
    appLogoUrl: "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
    appUrl: "/",
  };
  useEffect(async () => {
    let authInfos = getAuthInfosFromCookie();

    if (authInfos) {
      dispatch(login());
    } else {
      const resp = await getPublicClientCredential();
      const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
      const token = helpResp.token.access_token;
      dispatch(setAuthToken(token));
      dispatch(setAuthFetched(true));
    }
    if (!auth.user) {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
  }, []);

  return (
    <div>
      {auth.user ? (
        <Menu />
      ) : (
        <HeaderUA
          auth={{
            navCommunity: null,
            user: null,
            token: null,
          }}
          lng={lng}
          languages={[]}
          app={AppData}
          env={APP_ENV}
          RouterLink={Link}
          signInUrl="/login"
          signUpUrl="/register"
        />
      )}
      <DrawerIframe src={faqUrl} params={params} />
      <Footer />
    </div>
  );
};

export default Privacy;
