import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import debounce from "lodash.debounce";
import Select from "react-select";
import moment from "moment";
import { toast } from "react-toastify";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip as ChartTooltip,
  ResponsiveContainer,
} from "recharts";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import Tooltip from "@mui/material/Tooltip";

import {
  getMemberships,
  getMembershipStats,
  getUaMemberships,
  addMemberShipOrder,
} from "../../../../api";
import _ from "../../../../i18n";
import { SELECT_STYLES } from "../../../../config";

import Tabs from "../../../common/Tabs";
import Pagination from "../../../common/Pagination";
import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import IconUsers from "../../../common/icons/IconUsers";

import MemberOrderModal from "./MemberOrderModal";
import styles from "./ManageMembership.module.scss";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#fff",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #CED4DB",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#DFE2E6",
    },
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
    color: state.isSelected ? "#FFFFFF" : "#6D7F92",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
};

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884d8"];

const statusOptions = [
  { label: _("pending"), value: "PENDING" },
  { label: _("paid"), value: "PAID" },
];

const sourceOptions = [
  { label: "UA", value: "UA" },
  { label: "OECCBB", value: "OECCBB" },
];

const yearsOptions = [
  { value: moment().format("YYYY"), label: moment().format("YYYY") },
];

for (let i = moment().year() - 1; i >= 2020; i--) {
  yearsOptions.push({ value: i, label: i });
}

const ManageMembership = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const [status, setStatus] = useState(statusOptions[1]);
  const [source, setSource] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [memberships, setMemberShips] = useState(null);
  const [formulas, setFormulas] = useState(null);
  const [formulaFilter, setFormulaFilter] = useState(null);
  const [formulaOptions, setFormulaOptions] = useState([]);
  const [year, setYear] = useState(yearsOptions[0]);
  const [currentFetchedYear, setCurrentFetchedYear] = useState(null);
  const [sort, setSort] = useState({
    property: "createdAt",
    dir: "desc",
  });
  const [dataChart, setDataChart] = useState([]);
  const [previousYear, setPreviousYear] = useState([]);
  const [excludeYear, setExcludeYear] = useState(yearsOptions[0]);
  const [search, setSearch] = useState("");
  const [currentFilterTab, setCurrentFilterTab] = useState(0);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [currentMemberOrder, setCurrentMemberOrder] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const LIMIT = 20;

  let { isLoading, data } = useQuery(
    [
      "listMemberships",
      token,
      year,
      sort,
      status,
      source,
      currentPage,
      formulaFilter,
      excludeYear,
      previousYear,
      search,
    ],
    async () => {
      if (token) {
        if (!memberships) {
          try {
            const response = await getUaMemberships(token);
            let membershipTab = {},
              formulasTab = {},
              formulaOptionsTab = [];
            response.data.data.forEach((item) => {
              membershipTab[item.id] = {
                id: item.id,
                name: item.nameFr,
                hasSlider: item.hasSlider,
                type: item.type,
              };
              item.uaMemberShipFormulas.forEach((formula) => {
                formulasTab[formula.id] = {
                  id: formula.id,
                  name: formula.nameFr,
                  annualPrice: formula.annualPrice,
                };
                formulaOptionsTab.push({
                  value: formula.id,
                  label: formula.nameFr,
                  membership: item.id,
                  annualPrice: formula.annualPrice,
                });
              });
            });
            setFormulaOptions(formulaOptionsTab);
            setMemberShips(membershipTab);
            setFormulas(formulasTab);
          } catch (e) {}
        }
        if (currentFetchedYear !== year) {
          try {
            const resp = await getMembershipStats({
              token,
              year: year.value,
              status: status.value,
              source: source ? source.value : null,
            });
            setCurrentFetchedYear(year.value);
            setDataChart(
              resp.data.data.map((i) => {
                return {
                  name: i.formula,
                  value: i.count,
                };
              })
            );
          } catch (e) {
            setDataChart([]);
          }
        }
        try {
          const formulaIds = formulaFilter
            ? formulaFilter.map((i) => i.value)
            : [];
          let exYear =
            currentFilterTab === 1
              ? excludeYear
                ? excludeYear.value
                : null
              : null;
          let prevYear =
            currentFilterTab === 1
              ? previousYear.length > 0
                ? previousYear.map((i) => i.value)
                : null
              : null;
          const response = await getMemberships({
            token,
            status: status.value,
            page: currentPage,
            limit: LIMIT,
            sort,
            year: year.value,
            excludeYear: exYear,
            previousYear: prevYear,
            formulaIds,
            source: source ? source.value : null,
            search,
          });
          return response.data;
        } catch (e) {
          return [];
        }
      }
    },
    { cacheTime: 0 }
  );

  useEffect(() => {
    if (props.refreshList) {
      queryClient.invalidateQueries("listMemberships");
      props.setRefreshList(false);
    }
  }, [props.refreshList]);

  const handleTabFilterChange = (e) => {
    setCurrentFilterTab(e);
    if (e == 1) {
      setPreviousYear([yearsOptions[1]]);
      setExcludeYear(yearsOptions[0]);
      setStatus(statusOptions[1]);
      setSource(null);
      setFormulaFilter(null);
    } else {
      setExcludeYear(null);
    }
  };

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setSearch(search);
    setCurrentPage(1);
  }, 1000);

  const handleStatusChange = (e) => {
    setStatus(e);
  };

  const handleSendOrder = (item) => {
    setCurrentMemberOrder(item);
    setShowBillingModal(true);
  };

  const handleMemberShipOrder = (data) => {
    setIsSaving(true);
    let memberShip = JSON.stringify(data.data);
    addMemberShipOrder({
      token,
      language,
      memberShip,
      uen: data.uen,
      organization: data.organization,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      subjectToVAT: data.subjectToVAT,
      user: data.user.id,
    })
      .then((response) => {
        if (response.data.data) {
          toast.success(_("successfully_sent"));
          setIsSaving(false);
          queryClient.invalidateQueries("listMemberships");
        }
      })
      .catch((e) => {
        setIsSaving(false);
        toast.error(_("error_add_order"));
      });
  };

  const renderChart = () => {
    if (dataChart.length === 0) {
      return null;
    }
    return (
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={150} height={150}>
          <Pie
            dataKey="value"
            isAnimationActive={false}
            data={dataChart}
            cx="50%"
            cy="50%"
            outerRadius={45}
            fill="#8884d8"
            label
          >
            {dataChart.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <ChartTooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  return (
    <div className="grid-container">
      {isSaving && <div className="lmask"></div>}
      <div className={styles.listContainer}>
        <h3 className={styles.pageTitle}>
          Liste des adhésions OECCBB{" "}
          {!isLoading && data?.nbResult && <span>({data.nbResult})</span>}
        </h3>
        <div className={styles.inputSearch}>
          <span className={styles.inputSearch_icon}>
            <i className="icon-ttp-magnifier"></i>
          </span>
          <input
            type="text"
            placeholder="Recherche ..."
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
      </div>

      <ul className={styles.tabForms}>
        <li
          onClick={() => {
            props.setSideBar("MEMBERSHIP_FORM");
          }}
          className={props.sideBar === "MEMBERSHIP_FORM" ? styles.active : ""}
        >
          Ajout Manuel
        </li>
      </ul>
      <div className={styles.topBar}>
        <div style={{ flex: "1" }}>
          <Tabs theme="wtheme" onChangeTab={handleTabFilterChange}>
            <div key={`filter`} label={"Filtrer"}>
              <div className={styles.filterBar}>
                <div className={styles.filter}>
                  <label>Année</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={yearsOptions}
                    isSearchable={false}
                    value={year}
                    onChange={(e) => setYear(e)}
                    selectStyles={selectStyles}
                  />
                </div>
                <div className={styles.filter}>
                  <label>Status</label>
                  <Select
                    styles={SELECT_STYLES}
                    options={statusOptions}
                    isSearchable={false}
                    value={status}
                    onChange={handleStatusChange}
                    selectStyles={selectStyles}
                    classNamePrefix="react-select"
                  />
                </div>
                <div className={styles.filter}>
                  <label>Source</label>
                  <Select
                    isClearable
                    styles={SELECT_STYLES}
                    options={sourceOptions}
                    isSearchable={false}
                    value={source}
                    onChange={(e) => setSource(e)}
                    selectStyles={selectStyles}
                    classNamePrefix="react-select"
                  />
                </div>
                <div className={styles.filter}>
                  <label>Formule</label>
                  <div className={styles.formulaFilter}>
                    <Select
                      isMulti
                      styles={SELECT_STYLES}
                      options={formulaOptions}
                      isSearchable={false}
                      value={formulaFilter}
                      onChange={(e) => setFormulaFilter(e)}
                      selectStyles={selectStyles}
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div key={`compare`} label={"Comparer"}>
              <p className={styles.fitlerTxt}>
                Afficher les adhérents de l'année 1 qui ne sont pas dans l'année
                2
              </p>

              <div className={styles.compare}>
                <div className={styles.filter}>
                  <label>Année 1</label>
                  <Select
                    isMulti
                    styles={SELECT_STYLES}
                    options={yearsOptions}
                    isSearchable={false}
                    value={previousYear}
                    onChange={(e) => setPreviousYear(e)}
                    isOptionDisabled={(option) =>
                      option.value === excludeYear?.value
                    }
                  />
                </div>
                <div className={styles.filter}>
                  <label>Année 2</label>
                  <Select
                    isClearable
                    styles={SELECT_STYLES}
                    options={yearsOptions}
                    isSearchable={false}
                    value={excludeYear}
                    onChange={(e) => setExcludeYear(e)}
                    isOptionDisabled={(option) =>
                      previousYear?.filter((i) => i.value === option.value) > 0
                    }
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
            </div>
          </Tabs>
        </div>

        <div className={styles.chartContainer}>
          <div className={styles.box}>{renderChart()}</div>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <table className={`${styles.table} unstriped`}>
          <thead>
            <tr>
              <th width="40%" className={styles.nowrap}>
                <span>Formule</span>
              </th>
              <th width="20%">Utilisateur</th>
              <th width="20%">Email</th>
              <th>Téléphone</th>
              <th>Statut</th>
              <th>Date</th>
              <th></th>
              {/* {currentFilterTab === 1 && <th></th>} */}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={7} className={styles.centerCell}>
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#B2BCC6"}
                  />
                </td>
              </tr>
            ) : data?.data && data.data.length > 0 ? (
              data.data.map((item) => {
                let orderTooltip = [
                  <h3 className={styles.toolTitle}>Bon de commande envoyé</h3>,
                ];
                if (item.orderSent) {
                  let lines = [];
                  item.orderData.forEach((row) =>
                    lines.push(
                      <li>
                        {memberships[row.membersShip].name +
                          " " +
                          formulas[row.formula].name}
                      </li>
                    )
                  );
                  orderTooltip.push(
                    <ul className={styles.toolLines}>{lines}</ul>
                  );
                }
                let membersTooltip = [];
                if (currentFilterTab === 0 && item.associates.length > 0) {
                  membersTooltip.push(
                    <h3 className={styles.toolTitle}>Membres associés</h3>
                  );
                  let lines = [];
                  item.associates.forEach((row) =>
                    lines.push(<li>{row.firstName + " " + row.lastName}</li>)
                  );
                  membersTooltip.push(
                    <ul className={styles.toolLines}>{lines}</ul>
                  );
                }
                return (
                  <tr key={item.id}>
                    <td>
                      {item.data.map((row) => (
                        <p className={styles.btmBorder}>
                          {memberships[row.membersShip].name +
                            " " +
                            formulas[row.formula].name}
                        </p>
                      ))}
                    </td>
                    {/* <td>
                    {item.data.map((row) => (
                      <p className={styles.btmBorder}>
                        {formulas[row.formula].name}
                      </p>
                    ))}
                  </td> */}
                    <td>
                      {item.user.firstName} {item.user.lastName}
                    </td>
                    <td>{item.user.mainEmail}</td>
                    <td className={styles.nowrap}>
                      {item.user.mainPhone
                        ? formatPhoneNumberIntl(item.user.mainPhone)
                        : "-"}
                    </td>
                    <td>
                      {_(item.status.toLowerCase())}
                      <br />
                      {item.year}
                    </td>
                    <td>{moment(item.createdAt).format("DD/MM/YYYY")}</td>
                    {currentFilterTab === 0 && (
                      <td>
                        {item.associates.length > 0 ? (
                          <Tooltip title={membersTooltip} arrow>
                            <span className={styles.persons}>
                              <IconUsers />{" "}
                              <span>{item.associates.length}</span>
                            </span>
                          </Tooltip>
                        ) : (
                          <span className={styles.disablePerson}>
                            <IconUsers />
                          </span>
                        )}
                      </td>
                    )}
                    {currentFilterTab === 1 && (
                      <td>
                        {item.orderSent ? (
                          <Tooltip title={orderTooltip} arrow>
                            <span className={styles.successBtn}>
                              <i className="icon-ttp-check" />
                            </span>
                          </Tooltip>
                        ) : // <Button
                        //   // onClick={() => handleSendOrder(item)}
                        //   title="Bon de commande envoyé"
                        //   variant="send"
                        // >
                        //   <i className="icon-ttp-check" />
                        // </Button>

                        item.showBC ? (
                          <Button
                            onClick={() => handleSendOrder(item)}
                            title="Envoyer le bon de commande"
                          >
                            <i className="icon-ttp-plane" />
                          </Button>
                        ) : (
                          <></>
                        )}
                      </td>
                    )}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className={styles.centerCell}>
                  Liste vide (aucune donnée ne répond aux filtres)
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}

      <MemberOrderModal
        isOpen={showBillingModal}
        closeModal={() => setShowBillingModal(false)}
        currentMemberOrder={currentMemberOrder}
        onSave={handleMemberShipOrder}
        formulaOptions={formulaOptions}
        SELECT_STYLES={SELECT_STYLES}
      />
    </div>
  );
};

export default ManageMembership;
