import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { ModalConfirm } from "tamtam-components";
import debounce from "lodash.debounce";
import md5 from "crypto-js/md5";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Button from "../../common/Button";

import {
  getTeam,
  getTeamPremium,
  saveSharedPremium,
  removeSharedPremium,
  sendUaPremiumShareEmail,
} from "../../../api";
import _ from "../../../i18n";
import { TTP_OFFFCOURSE_URL } from "../../../config";

import WatchSharingStats from "./WatchSharingStats";
import styles from "./WatchSharing.module.scss";

let usersIdsPremium = [];

const WatchSharing = ({ showModal, setShowModal }) => {
  const queryClient = useQueryClient();
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [cycleId, setCycleId] = useState(null);
  const [premiumId, setPremiumId] = useState(null);
  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [offfCourseUsers, setOfffCourseUsers] = useState([]);
  const [sharedUsers, setSharedUsers] = useState([]);
  const [premiumUsers, setPremiumUsers] = useState([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [usedPlaces, setUsedPlaces] = useState(0);
  const [countPlaces, setCountPlaces] = useState(0);
  const [isOpenNoPremiumModal, setIsOpenNoPremiumModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [step, setStep] = useState("INITIAL");
  const [start, setStart] = useState(0);
  const [countResult, setCountResult] = useState(0);
  const [premiumFetched, setPremiumFetched] = useState(false);
  const [isOpenApplyAllModal, setIsOpenApplyAllModal] = useState(false);
  const [isOpenSendModal, setIsOpenSendModal] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const LIMIT = 20;

  let getTeamPremiumData = useQuery(
    ["getTeamPremiumData", token, showModal],
    async () => {
      if (token && showModal) {
        usersIdsPremium = [];
        try {
          const resp = await getTeamPremium({
            token,
            organizationId: fiduciary.id,
          });
          if (resp.data.data) {
            let shared = [],
              premium = [];
            if (resp.data.data.cycleId) {
              setCycleId(resp.data.data.cycleId);
            }
            if (resp.data.data.users && resp.data.data.users.length > 0) {
              resp.data.data.users.forEach((user) => {
                usersIdsPremium.push(parseInt(user.id));
                if (user.premiumId && user.premiumId !== "0") {
                  premium.push(user);
                  setPremiumId(user.premiumId);
                } else if (
                  user.idPremiumShared &&
                  user.idPremiumShared !== "0"
                ) {
                  if (!shared.includes(user)) {
                    shared.push(user);
                  }
                }
              });
            }
            setSharedUsers(shared);
            setUsedPlaces(shared.length);
            setPremiumUsers(premium);
            setCountPlaces(premium.length * 4);
            setPremiumFetched(true);
          }
        } catch (error) {
          setPremiumFetched(true);
        }
      }
    },
    { cacheTime: 0 }
  );

  let { isLoading, data } = useQuery(
    ["getUserRightsData", premiumFetched, showModal, start],
    async () => {
      if (premiumFetched && showModal) {
        try {
          let result = [...offfCourseUsers];
          const response = await getTeam({
            token,
            communityId: auth.navCommunity.id,
            excludeIds: usersIdsPremium,
            offset: start,
            limit: LIMIT,
            cfields: ["id", "firstName", "lastName"],
          });

          if (response.data?.data) {
            setCountResult(response.data.nbResult);

            const tab = response.data.data.filter(
              (i) => !usersIdsPremium.includes(i.id)
            );
            result = [...result, ...tab];
          }
          setOfffCourseUsers(result);
          return result;
        } catch (e) {
          return [];
        }
      }
    },
    { cacheTime: 0 }
  );

  const filterData = () => {
    if (searchName != "") {
      return offfCourseUsers.filter(
        (user) =>
          (user.firstName + " " + user.lastName)
            .toLowerCase()
            .search(searchName.toLowerCase()) > -1
      );
    } else {
      return offfCourseUsers;
    }
  };

  const handleSendPremiumInvitation = async () => {
    setIsSending(true);
    try {
      await sendUaPremiumShareEmail({
        token,
        userId: currentUser.id,
        name: auth.user.firstName,
        invitation: 1,
      });
      setIsOpenSendModal(false);
      setIsSending(false);
      toast.success(_("invitation_sent"));
    } catch (e) {
      setIsSending(false);
    }
  };

  const handleApplyAll = () => {
    if (countPlaces === 0) {
      setIsOpenNoPremiumModal(true);
      setIsOpenApplyAllModal(false);
      return null;
    }
    const usersIds = filterData().map((user) => user.id);

    if (isSavingLoading || usersIds.length === 0) {
      return null;
    }

    setIsSavingLoading(true);
    saveSharedPremium({
      token,
      organizationId: fiduciary.id,
      premiumId,
      premiumSharedBy: auth.user.id,
    })
      .then(async (resp) => {
        // let addedUsers = offfCourseUsers.filter((i) => usersIds.includes(i.id));
        let remainUsers = offfCourseUsers.filter(
          (i) => !usersIds.includes(i.id)
        );
        setOfffCourseUsers(remainUsers);
        // setSharedUsers([...sharedUsers, ...addedUsers]);
        // setUsedPlaces([...sharedUsers, ...addedUsers].length);
        queryClient.invalidateQueries("getTeamPremiumData");
        queryClient.invalidateQueries("getUserRightsData");
        toast.success(_("successfully_saved"));
        setIsSavingLoading(false);
      })
      .catch((e) => {
        setIsSavingLoading(false);
        toast.error(_("error_try_again"));
      });
  };

  const handleAddUser = (user) => {
    if (countPlaces === 0 || sharedUsers.length === countPlaces) {
      setIsOpenNoPremiumModal(true);
      return null;
    }

    setIsSavingLoading(true);
    saveSharedPremium({
      token,
      users: [user.id],
      premiumId,
      premiumSharedBy: auth.user.id,
    })
      .then(async (resp) => {
        try {
          await sendUaPremiumShareEmail({
            token,
            userId: user.id,
            name: auth.user.firstName,
          });
        } catch (e) {}

        setIsSavingLoading(false);

        let users = offfCourseUsers.filter((i) => i.id !== user.id);
        setOfffCourseUsers(users);
        setSharedUsers([...sharedUsers, user]);
        setUsedPlaces([...sharedUsers, user].length);
      })
      .catch((e) => {
        setIsSavingLoading(false);
        toast.error(_("error"));
      });
  };

  const handleDeleteAction = () => {
    setIsSavingLoading(true);
    setIsOpenDeleteModal(false);
    removeSharedPremium({ token, user: currentUser.id })
      .then((resp) => {
        let users = sharedUsers.filter((i) => i.id !== currentUser.id);
        let result = [...offfCourseUsers, currentUser];
        result.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setOfffCourseUsers(result);
        setSharedUsers(users);
        setUsedPlaces(users.length);
        setCurrentUser(null);

        setIsSavingLoading(false);
      })
      .catch((e) => {
        setIsSavingLoading(false);
        toast.error(_("error"));
      });
  };

  const renderOfffcourse = () => {
    return (
      <div className={styles.users}>
        {/* {isSavingLoading && <div className="lmask"></div>} */}

        {filterData().map((user, index) => (
          <div key={`user-${user.id}`} className={styles.user}>
            <div className={styles.user_name}>
              <span>{user.firstName + " " + user.lastName}</span>
              <span className={styles.user_status}>Non inscrit</span>
            </div>
            <div className={styles.user_actions}>
              <div
                className={`${styles.user_action} ${styles.user_action_send} `}
                onClick={() => {
                  setCurrentUser(user);
                  setIsOpenSendModal(true);
                }}
              >
                <i className="icon-ttp-paper-airplane" />
              </div>
              <div
                className={styles.user_action}
                onClick={() => handleAddUser(user)}
              >
                <i className="icon-ttp-plus" />
              </div>
            </div>
          </div>
        ))}

        {LIMIT + start < countResult && (
          <div
            className={styles.show_more}
            onClick={() => setStart(start + LIMIT)}
          >
            {_("show_more")}
          </div>
        )}
      </div>
    );
  };

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setSearchName(search);
  }, 1000);

  const closeModal = () => {
    setShowModal(false);
    setSharedUsers([]);
    setPremiumUsers([]);
    setOfffCourseUsers([]);
    setPremiumFetched(false);
    setStep("INITIAL");
  };

  return (
    <>
      <Modal
        width="80vw"
        height="60vh"
        closable={false}
        visible={showModal}
        footer={null}
        onCancel={(e) => {
          e.stopPropagation();
          closeModal();
        }}
        maskClosable={false}
        destroyOnClose={true}
      >
        <div className={styles.modal_header}>
          <i className="icon-ttp-settings-alt"></i>{" "}
          {_("manage_premium_sharing")}
        </div>
        {step === "INITIAL" && (
          <div className={styles.modal_content}>
            <div className={styles.left}>
              <p className={styles.subtitle}>
                Cliquez sur le bouton (+) pour leur attribuer un partage premium
                oFFFcourse
              </p>

              {premiumUsers.length > 0 && (
                <div className={styles.premiumBox}>
                  <h3 className={styles.title}>Comptes Premium</h3>
                  <div className={styles.users}>
                    {premiumUsers.map((user, i) => (
                      <div key={`user-${user.id}`} className={styles.user}>
                        <span>{user.firstName + " " + user.lastName}</span>
                        <span className={styles.user_status}>Premium</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <div className={styles.header}>
                <h3 className={styles.title}>Liste collaborateurs</h3>
                <div className={styles.header_left}>
                  {fiduciary.uaMode === "UNIVERSITY" && (
                    <span
                      className={styles.allAction}
                      onClick={() => setIsOpenApplyAllModal(true)}
                    >
                      Attribuer à tous
                    </span>
                  )}
                  <div className={styles.inputSearch}>
                    <span className={styles.inputSearch_icon}>
                      <i className="icon-ttp-magnifier"></i>
                    </span>
                    <input
                      type="text"
                      placeholder="Recherche ..."
                      onChange={(e) => handleSearchChange(e)}
                    />
                  </div>
                </div>
              </div>
              {isLoading ? (
                <div style={{ marginTop: "2rem" }}>
                  <Skeleton count={4} height={50} />
                </div>
              ) : (
                data && data.length > 0 && renderOfffcourse()
              )}
            </div>
            <div className={styles.right}>
              <h3 className={styles.title}>Mes places</h3>

              <div className={styles.info}>
                <i className="icon-ttp-alert-circle"></i>
                <div className={styles.info_box}>
                  <h3>Compte premium abonné :</h3>
                  <ul>
                    {premiumUsers.map((user, i) => (
                      <li key={`u-${i}`}>
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_56474_51154)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M7.8315 1.88958C6.94512 1.49463 5.95482 1.39679 5.00829 1.61064C4.06175 1.8245 3.20971 2.3386 2.57923 3.07626C1.94874 3.81392 1.5736 4.73563 1.50976 5.70392C1.44591 6.67221 1.69677 7.63519 2.22493 8.44926C2.7531 9.26332 3.53026 9.88485 4.44051 10.2211C5.35077 10.5574 6.34534 10.5905 7.27591 10.3153C8.20648 10.0402 9.02318 9.47161 9.6042 8.6944C10.1852 7.91718 10.4994 6.97297 10.5 6.00258V5.54286C10.5 5.26672 10.7239 5.04286 11 5.04286C11.2761 5.04286 11.5 5.26672 11.5 5.54286V6.00286C11.4993 7.18889 11.1153 8.34322 10.4051 9.29315C9.69499 10.2431 8.69681 10.938 7.55945 11.2743C6.42209 11.6106 5.20649 11.5702 4.09396 11.1592C2.98142 10.7481 2.03156 9.9885 1.38603 8.99354C0.740496 7.99857 0.433885 6.82158 0.511922 5.63812C0.589959 4.45466 1.04846 3.32813 1.81905 2.42654C2.58964 1.52495 3.63103 0.896608 4.7879 0.635228C5.94478 0.373848 7.15515 0.493433 8.2385 0.976148C8.49074 1.08854 8.60411 1.38413 8.49172 1.63636C8.37933 1.8886 8.08374 2.00197 7.8315 1.88958Z"
                              fill="#FB1E5B"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.3534 1.64627C11.5487 1.84143 11.5489 2.15802 11.3537 2.35338L6.35373 7.35838C6.25998 7.45222 6.13278 7.50497 6.00012 7.505C5.86747 7.50503 5.74025 7.45235 5.64645 7.35855L4.14645 5.85855C3.95118 5.66329 3.95118 5.34671 4.14645 5.15145C4.34171 4.95618 4.65829 4.95618 4.85355 5.15145L5.99982 6.29772L10.6463 1.64662C10.8414 1.45126 11.158 1.45111 11.3534 1.64627Z"
                              fill="#FB1E5B"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_56474_51154">
                              <rect width="12" height="12" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>

                        <span>
                          {user.firstName} {user.lastName}
                        </span>
                      </li>
                    ))}
                  </ul>
                  <p>
                    Pour avoir plus de places à attribuer, demandez a un de vos
                    collaborateurs d’acheter un abonnement premium oFFFcourse,
                    cela vous donnera droit à 4 places de plus.
                  </p>
                </div>
              </div>

              <div className={styles.sharedBox}>
                {isSavingLoading && (
                  <div className={styles.overlay}>
                    <span className={styles.spinner}></span>
                  </div>
                )}
                <div className={styles.sharedBox_top}>
                  <span>Compte premium oFFFcourse</span>
                  <span>
                    {fiduciary.uaMode === "UNIVERSITY"
                      ? usedPlaces
                      : usedPlaces + "/" + countPlaces}{" "}
                    places
                  </span>
                </div>

                <div className={styles.sharedBox_content}>
                  {sharedUsers.length === 0 ? (
                    <div className={styles.sharedBox_nodata}>
                      Aucune attribution de place n'a été faite.
                    </div>
                  ) : (
                    sharedUsers.map((user, index) => (
                      <div key={`user-${user.id}`} className={styles.user}>
                        <div className={styles.user_name}>
                          <span>{user.firstName + " " + user.lastName}</span>
                          <span className={styles.user_status}>
                            Inscrit premium partagé
                          </span>
                        </div>
                        <div className={styles.user_actions}>
                          <div
                            className={styles.user_action}
                            onClick={() => {
                              setCurrentUser(user);
                              setStep("STATS");
                            }}
                          >
                            <i className="icon-ttp-eye" />
                          </div>

                          <div
                            className={`${styles.user_action} ${styles.user_action_delete} `}
                            onClick={() => {
                              setCurrentUser(user);
                              setIsOpenDeleteModal(true);
                            }}
                          >
                            <i className="icon-ttp-close" />
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <ModalConfirm
              type="add"
              isOpen={isOpenSendModal}
              onCancel={() => {
                setIsOpenSendModal(false);
              }}
              inProcess={isSending}
              onConfirm={() => handleSendPremiumInvitation()}
              title={_("invitation_confirm")}
              text={_("invitation_confirm_text")}
              labelNo={_("no")}
              labelYes={_("yes")}
              labelError={_("error")}
            />
            <ModalConfirm
              type="add"
              isOpen={isOpenApplyAllModal}
              onCancel={() => {
                setIsOpenApplyAllModal(false);
              }}
              onConfirm={() => handleApplyAll()}
              title={_("add_confirm")}
              text={_("share_all_confirm_text")}
              labelNo={_("no")}
              labelYes={_("yes")}
              labelError={_("error")}
            />
            <ModalConfirm
              type="delete"
              isOpen={isOpenDeleteModal}
              onCancel={() => {
                setIsOpenDeleteModal(false);
              }}
              onConfirm={() => handleDeleteAction()}
              title={_("delete_confirm")}
              text={
                "Êtes-vous sûr(e) de vouloir mettre fin au partage premium avec cette personne ?"
              }
              labelNo={_("no")}
              labelYes={_("yes")}
              labelError={_("error")}
            />
            <ModalConfirm
              type="publish"
              isOpen={isOpenNoPremiumModal}
              onCancel={() => {
                setIsOpenNoPremiumModal(false);
              }}
              onConfirm={() => {
                let url = `${TTP_OFFFCOURSE_URL}/autolog?idApi2=${
                  auth.user.id
                }&check=${md5(
                  auth.user.id + "FFFCONTROL"
                )}&organization=${encodeURIComponent(fiduciary.name)}&uen=BE${
                  fiduciary.uen
                }`;
                if (cycleId) {
                  url += "&cycleId=" + cycleId + "&registration";
                }
                window.open(url, "_blank");
                setIsOpenNoPremiumModal(false);
              }}
              title={"Limite atteinte"}
              text={
                "Un de vos collaborateurs doit acheter un abonnement premium oFFFcourse pour pouvoir partager"
              }
              labelNo={_("no")}
              labelYes={"Voir oFFFcourse"}
              labelError={_("error")}
            />
          </div>
        )}
        {step === "STATS" && currentUser && (
          <WatchSharingStats
            currentUser={currentUser}
            step={step}
            setStep={setStep}
          />
        )}
        <div className={styles.actions}>
          <Button variant="default" onClick={() => closeModal()}>
            {_("close")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default WatchSharing;
