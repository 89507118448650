import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Button from "../common/Button";
import _ from "../../i18n";

import {
  getClientCredential,
  emailAcceptInvitation,
  emailRejectInvitation,
} from "../../api";
import { setAuthToken } from "../../store";

import LeftSide from "../Login/LeftSide";
import styles from "./Invitation.module.scss";

const Invitation = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [id, setId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [roles, setRoles] = useState([]);
  const [fiduciaryId, setfiduciaryId] = useState(null);
  const [fiduciaryName, setfiduciaryName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    let fidId = null;
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "id") {
        setId(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "uid") {
        setUserId(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "email") {
        setEmail(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "fid") {
        fidId = decodeURIComponent(pair[1]);
        setfiduciaryId(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "name") {
        setfiduciaryName(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "role") {
        setRole(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "roles") {
        const jsonRoles = decodeURIComponent(pair[1]);
        if (jsonRoles) {
          setRoles(JSON.parse(jsonRoles));
        }
      }
    }

    if (!auth.token) {
      getClientCredential().then((resp) => {
        const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
        const token = helpResp.token.access_token;
        dispatch(setAuthToken(token));
      });
    }
  }, []);

  const handleAccept = () => {
    if (id || userId) {
      setIsSaving(true);
      emailAcceptInvitation(auth.token, userId, id, email, fiduciaryId)
        .then((resp) => {
          if (resp.data.data && resp.data.data.redirectLink) {
            navigate(resp.data.data.redirectLink);
          }
          // navigate("/");
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
          navigate("/");
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.error(_("error"));
      navigate("/");
    }
  };

  const handleReject = () => {
    if (id || userId) {
      setIsSaving(true);
      emailRejectInvitation(auth.token, userId, id, email, fiduciaryId)
        .then((resp) => {
          toast.success(_("invitation_reject"));
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.error(_("error"));
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <LeftSide />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.topButtons}>
            <span>Déjà membre?</span>
            <span
              onClick={() => navigate("/login")}
              className={styles.register}
            >
              S’identifier
            </span>
          </div>

          <div className={styles.content}>
            <h1 className={styles.title}>Invitation</h1>

            <p>
              Vous êtes invité à rejoindre l'équipe <b>{fiduciaryName}</b> sur
              United Associates en tant que <b>{_(role)}</b>
            </p>

            {roles.length > 0 && (
              <div>
                <p className={styles.list_title}>
                  Vous avez déjà les roles suivants:
                </p>
                <ul className={styles.list}>
                  {roles.map((role, idx) => (
                    <li key={idx}>
                      {decodeURI(role.fid).replace(/\+/g, " ")}:{" "}
                      {role.type === "LEGAL_REPRESENTATIVE"
                        ? _(role.type + "_" + role.typeStatus)
                        : _(role.type)}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className={styles.actions}>
              <Button onClick={() => handleReject()} variant="danger">
                Refuser
              </Button>
              <Button onClick={() => handleAccept()}>Accepter</Button>
            </div>

            {isSaving && <div className="lmask"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
