import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";

import styles from "./ThemesLayout.module.scss";
import _ from "../../i18n";

const ThemesLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className={styles.layout}>
      <ul className={styles.headerTab}>
        <li
          className={classNames(
            styles.headerTab__button,
            location.pathname.includes("themes")
              ? styles.headerTab__button_active
              : ""
          )}
          onClick={() => {
            if (!location.pathname.includes("themes")) {
              navigate(`/themes`);
            }
          }}
        >
          {_("themes_pages")}
        </li>

        <li
          className={classNames(
            styles.headerTab__button,
            location.pathname.includes("domains")
              ? styles.headerTab__button_active
              : ""
          )}
          onClick={() => {
            if (!location.pathname.includes("domains")) {
              navigate(`/domains`);
            }
          }}
        >
          {_("domains")}
        </li>
      </ul>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ThemesLayout;
