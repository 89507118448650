import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import classNames from "classnames";
import { toast } from "react-toastify";
import moment from "moment";

import _ from "../../i18n";
import styles from "./ContactMessages.module.scss";
import {
  getContacts,
  getUaContactMessage,
  readMessage,
  saveContact,
} from "../../api";
import { API_DATE_FORMAT } from "../../config";
import IconSearch from "../common/icons/IconSearch";

const ContactMessages = () => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const lng = useSelector((state) => state.params.language);
  const { token } = auth;
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [search, setSearch] = useState("");
  const [start, setStart] = useState(0);
  const [scrollEnd, setScrollEnd] = useState(false);
  const [checkingIfContact, setCheckingIfContact] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [saving, setSaving] = useState(false);

  let { isLoading } = useQuery(
    ["getUaContactMessage", token, fiduciary, search, start],
    async () => {
      try {
        if (token && fiduciary) {
          const response = await getUaContactMessage({
            token,
            communityId: fiduciary.id,
            search,
            start,
          });
          if (start > 0 && messages.length > 0) {
            setMessages([...messages, ...response.data.data]);
          } else {
            setCurrentMessage(null);
            setMessages(response.data.data);
          }
        }
      } catch (error) {
        if (
          error.response.data &&
          error.response.data.statusCode === 404 &&
          start === 0
        ) {
          setMessages(null);
        }
      }
    }
  );

  const handleMessageClick = async (message) => {
    setCurrentMessage({
      ...message,
      isRead: true,
    });
    setMessages(
      messages.map((el) => {
        if (message.id === el.id) {
          return {
            ...message,
            isRead: true,
          };
        }
        return el;
      })
    );
    readMessage({
      token,
      data: {
        id: message.id,
      },
    });

    try {
      setCheckingIfContact(true);
      const response = await getContacts({
        token,
        search: message.email,
        start: 0,
        limit: 20,
        communityId: fiduciary.id,
        sort: {
          property: "lastName",
          dir: "asc",
        },
      });
      if (response.data.data.length > 0) {
        setCurrentMessage({
          ...message,
          isRead: true,
          isContact: true,
        });
        setMessages(
          messages.map((el) => {
            if (message.id === el.id) {
              return {
                ...message,
                isRead: true,
                isContact: true,
              };
            }
            return el;
          })
        );
      }

      setCheckingIfContact(false);
    } catch (error) {
      setCheckingIfContact(false);
      console.error(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.statusCode === 404
      ) {
        setCurrentMessage({
          ...message,
          isRead: true,
          isContact: false,
        });
        setMessages(
          messages.map((el) => {
            if (message.id === el.id) {
              return {
                ...message,
                isRead: true,
                isContact: false,
              };
            }
            return el;
          })
        );
      }
    }
  };

  useEffect(() => {
    if (scrollEnd) {
      setStart(start + 10);
    }
  }, [scrollEnd]);

  const getFirstName = (name) => {
    const tmp = name.trim().split(" ");
    return tmp.length > 1 ? tmp[tmp.length - 1] : name;
  };
  const getLastName = (name) => {
    const tmp = name.trim().split(" ");
    if (tmp.length > 1) {
      tmp.pop();
      return tmp.join(" ");
    }
    return name;
  };

  const addContact = async () => {
    try {
      setSaving(true);

      const data = {
        email: currentMessage.email,
        firstname: getFirstName(currentMessage.name),
        lastname: getLastName(currentMessage.name),
        phone: currentMessage.phone ? currentMessage.phone : null,
        language: "fr",
        gender: "MALE",
        details: JSON.parse(
          JSON.stringify({
            uen: "",
            name: "",
            fonction: "",
            isClient: false,
          })
        ),
        type: "CONTACT_FROM_SITE",
      };

      await saveContact(token, data, fiduciary.id);
      setCurrentMessage({
        ...currentMessage,
        isRead: true,
        isContact: true,
      });
      setMessages(
        messages.map((el) => {
          if (currentMessage.id === el.id) {
            return {
              ...currentMessage,
              isRead: true,
              isContact: true,
            };
          }
          return el;
        })
      );
      setSaving(false);
      setShowConfirmModal(false);
      toast.success(_("successfully_saved"));
    } catch (error) {
      setSaving(false);
      console.error(error);
    }
  };

  return (
    <div className={styles.servicesPage}>
      <div
        className={styles.header}
        style={{ marginBottom: currentMessage ? "1rem" : "3rem" }}
      >
        <div>
          <h1>{_("contact_messages")}</h1>
          <span>{_("fiduciary_contact_messages")}</span>
        </div>

        <div className={styles.search}>
          <IconSearch />
          <input
            type="text"
            placeholder={_("search_placeholder")}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              setStart(0);
            }}
          />
        </div>
      </div>
      {isLoading && messages && messages.length === 0 && (
        <div className="lmask"></div>
      )}
      {!isLoading && !messages && (
        <div
          style={{
            textAlign: "center",
            maxWidth: "600px",
            margin: "0 auto 60px",
            color: "#2C394B",
            fontSize: "2rem",
          }}
        >
          <img alt="not found" src="/img/notFound.svg" />
          <div>{_("nothing_found")}</div>
        </div>
      )}
      {currentMessage && (
        <a href={`mailto:${currentMessage.email}`} className={styles.reply}>
          {_("reply")}
        </a>
      )}
      {messages && messages.length > 0 && (
        <div className="grid-x">
          <div className="cell small-4">
            <ul
              className={styles.typesList}
              onScroll={(e) => {
                if (
                  e.target.offsetHeight + e.target.scrollTop + 0.5 >=
                  e.target.scrollHeight / 2
                ) {
                  setScrollEnd(true);
                } else {
                  setScrollEnd(false);
                }
              }}
            >
              {messages.map((message) => (
                <li
                  className={classNames(styles.type)}
                  onClick={() => handleMessageClick(message)}
                >
                  <span
                    className={classNames(
                      styles.unread,
                      message.isRead ? styles.read : ""
                    )}
                  ></span>
                  <div className={styles.flexColumn}>
                    <span className={styles.name}>{message.subject}</span>
                    <span className={styles.publishedAt}>
                      {_("sent_at") +
                        " " +
                        moment(message.createdAt.date, API_DATE_FORMAT)
                          .locale(lng)
                          .format(`DD MMM YYYY`) +
                        ` ${_("at")} ` +
                        moment(message.createdAt.date, API_DATE_FORMAT)
                          .locale(lng)
                          .format(`hh:mm`)}
                    </span>
                  </div>
                  {currentMessage && currentMessage.id === message.id && (
                    <div className={styles.arrow}>
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_50815_37489)">
                          <path
                            d="M10.6482 6.50031L4.3525 0.206666C4.07624 -0.0688887 3.62867 -0.0688887 3.35172 0.206666C3.07547 0.482221 3.07547 0.929795 3.35172 1.20535L9.14809 6.99962L3.35242 12.7939C3.07617 13.0695 3.07616 13.517 3.35242 13.7933C3.62867 14.0688 4.07694 14.0688 4.35319 13.7933L10.6489 7.49963C10.921 7.22693 10.921 6.77232 10.6482 6.50031Z"
                            fill="#18A0FB"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_50815_37489">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className={classNames("cell small-8", styles.right)}>
            {currentMessage ? (
              <>
                <div className={styles.top}>
                  <div className={styles.info}>
                    <div className={styles.item}>
                      <strong>{`${_("name")} : `}</strong>
                      {currentMessage.name}
                    </div>
                    <div className={styles.item}>
                      <strong>{`${_("email")} : `}</strong>
                      {currentMessage.email}
                      {(checkingIfContact ||
                        currentMessage.isContact === false) && (
                        <label
                          className={classNames(
                            styles.isContact,
                            checkingIfContact
                              ? styles.isContact_fetching
                              : styles.isContact_add
                          )}
                          onClick={() => {
                            if (
                              !checkingIfContact &&
                              currentMessage.isContact === false
                            ) {
                              setShowConfirmModal(true);
                            }
                          }}
                        >
                          {checkingIfContact
                            ? _("checking_contact_existence")
                            : _("add_to_contacts")}
                        </label>
                      )}
                    </div>
                    {currentMessage.source && (
                      <div className={styles.item}>
                        <strong>{`${_("source")} : `}</strong>
                        {_(currentMessage.source)}
                      </div>
                    )}
                  </div>
                  <div className={styles.publishedAt}>
                    {_("sent_at") +
                      " " +
                      moment(currentMessage.createdAt.date, API_DATE_FORMAT)
                        .locale(lng)
                        .format(`DD MMM YYYY`) +
                      ` ${_("at")} ` +
                      moment(currentMessage.createdAt.date, API_DATE_FORMAT)
                        .locale(lng)
                        .format(`hh:mm`)}
                  </div>
                </div>
                <h2 className={styles.subject}>{currentMessage.subject}</h2>
                <p className={styles.message}>{currentMessage.message}</p>
              </>
            ) : (
              <p className={styles.selectMessage}>
                {_("select_message_please")}
              </p>
            )}
          </div>
        </div>
      )}
      <ModalConfirm
        type="publish"
        isOpen={showConfirmModal}
        onCancel={() => {
          setSaving(false);
          setShowConfirmModal(false);
        }}
        onConfirm={() => addContact()}
        inProcess={saving}
        title={_("add_confirm")}
        text={_("text_add_contact")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default ContactMessages;
