import React from "react";
import styles from "./CheckboxCard.module.scss";
import classNames from "classnames";

const CheckBoxCard = ({
  selected,
  html,
  size,
  icon,
  title,
  subTitle,
  description,
  onClick,
  disabled,
}) => {
  return (
    <div
      className={classNames(
        styles.card,
        icon && size === "large" ? styles.large : "",
        selected ? styles.selected : "",
        disabled ? styles.disabled : ""
      )}
      onClick={() => onClick()}
    >
      <div className={styles.checkbox}>
        {selected && <i className="icon-ttp-check"></i>}
      </div>
      {icon && (
        <img
          className={size === "large" ? styles.icon : styles.iconCollab}
          src={icon}
          alt="icon"
          width={size === "large" ? "80" : "24"}
          height={size === "large" ? "80" : "24"}
        />
      )}
      {html ? (
        <label>{html}</label>
      ) : (
        <>
          <div className={styles.title}>{title}</div>
          {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
          {description && <div className={styles.desc}>{description}</div>}
        </>
      )}
    </div>
  );
};

export default CheckBoxCard;
