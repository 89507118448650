import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import $ from "jquery";
import { HeaderUA } from "tamtam-components";

import styles from "./Home.module.scss";
import { APP_ENV, TTP_ONBOARDING_URL, TTP_UA_HOME } from "../../config";
import _ from "../../i18n";

const Home = ({ welcome }) => {
  const auth = useSelector((state) => state.auth);
  const folder = useSelector((state) => state.folder);
  const language = useSelector((state) => state.params.language);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (folder.fetched) {
  //     $("#app-loader")
  //       .fadeOut()
  //       .promise()
  //       .done(() => $("#app-loader").remove());
  //   }
  // }, [auth, folder]);

  useEffect(() => {
    if (folder.fetched) {
      $("#app-loader")
        .fadeOut()
        .promise()
        .done(() => $("#app-loader").remove());
    }
    if (!auth.user && folder.fetched) {
      window.location.href = `${TTP_UA_HOME}/${language}`;
    }
  }, [auth, folder]);

  return (
    <div>
      {!auth.user && (
        <HeaderUA
          auth={{
            navCommunity: null,
            user: null,
            token: null,
          }}
          lng={language}
          languages={[]}
          app={{
            appName: "United Associates",
            appLogoUrl:
              "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
            appUrl: TTP_ONBOARDING_URL,
          }}
          env={APP_ENV}
          RouterLink={Link}
          signInUrl="/login"
          signUpUrl="/register"
        />
      )}
      <div className={classNames(styles.homePage, "grid-x")}>
        <div
          className={classNames(styles.leftSide, "cell large-9 medium-12")}
          style={{
            backgroundColor: "#18a0fb",
          }}
        >
          <svg
            width="770"
            height="781"
            viewBox="0 0 770 781"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5" filter="url(#filter0_b_45323_20495)">
              <ellipse
                cx="232.066"
                cy="232.573"
                rx="232.066"
                ry="232.573"
                fill="url(#paint0_radial_45323_20495)"
              />
              <circle
                cx="466.163"
                cy="548.427"
                r="232.573"
                fill="url(#paint1_radial_45323_20495)"
              />
              <ellipse
                cx="468.701"
                cy="389.484"
                rx="301.127"
                ry="300.111"
                fill="url(#paint2_radial_45323_20495)"
              />
            </g>
            <defs>
              <filter
                id="filter0_b_45323_20495"
                x="-200"
                y="-200"
                width="1169.83"
                height="1181"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="100" />
                <feComposite
                  in2="SourceAlpha"
                  operator="in"
                  result="effect1_backgroundBlur_45323_20495"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_backgroundBlur_45323_20495"
                  result="shape"
                />
              </filter>
              <radialGradient
                id="paint0_radial_45323_20495"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(232.066 232.573) rotate(90) scale(232.573 232.066)"
              >
                <stop stop-color="#C7E8FE" />
                <stop offset="1" stop-color="#C7E8FE" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint1_radial_45323_20495"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(466.163 548.427) rotate(90) scale(232.573)"
              >
                <stop stop-color="#C7E8FE" />
                <stop offset="1" stop-color="#C7E8FE" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint2_radial_45323_20495"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(468.701 389.484) rotate(90) scale(300.111 301.127)"
              >
                <stop stop-color="#D8DDE2" />
                <stop offset="1" stop-color="#D8DDE2" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
          <div className={classNames(styles.content)}>
            <div className={styles.presentation}>
              <span className={styles.welcome_to}>{_("welcome_to")}</span>
              <h1 className={styles.ua_h1}>
                United <label>Associates</label>
              </h1>
              <p className={styles.home_p}>{_("home_description")}</p>
              <button
                className={classNames(styles.firstBtn, styles.home_btn)}
                onClick={() => navigate("/register")}
              >
                {_("subscribe")}{" "}
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="show-for-large"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-1.94364e-07 9.01304C-8.70198e-08 8.39099 0.50368 7.88672 1.125 7.88672L16.875 7.88672C17.4963 7.88672 18 8.39099 18 9.01305C18 9.6351 17.4963 10.1394 16.875 10.1394L1.125 10.1394C0.503679 10.1394 -3.01709e-07 9.6351 -1.94364e-07 9.01304Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.2006 0.329893C8.63994 -0.109964 9.35225 -0.109964 9.79159 0.329893L17.6666 8.21417C18.1059 8.65402 18.1059 9.36717 17.6666 9.80703L9.79159 17.6913C9.35225 18.1312 8.63994 18.1312 8.2006 17.6913C7.76126 17.2514 7.76126 16.5383 8.2006 16.0984L11.7508 12.544C13.7007 10.5919 13.7007 7.42929 11.7508 5.47714L8.2006 1.92276C7.76126 1.4829 7.76126 0.76975 8.2006 0.329893Z"
                    fill="white"
                  />
                </svg>
              </button>
              <button
                className={styles.home_btn}
                onClick={() => navigate("/login")}
              >
                {_("already_subscribed")}{" "}
                <svg
                  width="18"
                  height="19"
                  viewBox="0 0 18 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="show-for-large"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M-1.94364e-07 9.03648C-8.70198e-08 8.41443 0.50368 7.91016 1.125 7.91016L16.875 7.91016C17.4963 7.91016 18 8.41443 18 9.03648C18 9.65854 17.4963 10.1628 16.875 10.1628L1.125 10.1628C0.503679 10.1628 -3.01709e-07 9.65853 -1.94364e-07 9.03648Z"
                    fill="#29394D"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.2006 0.35333C8.63994 -0.0865267 9.35225 -0.0865265 9.79159 0.353331L17.6666 8.2376C18.1059 8.67746 18.1059 9.39061 17.6666 9.83047L9.79159 17.7147C9.35225 18.1546 8.63994 18.1546 8.2006 17.7147C7.76126 17.2749 7.76126 16.5617 8.2006 16.1219L11.7508 12.5675C13.7007 10.6153 13.7007 7.45272 11.7508 5.50058L8.2006 1.94619C7.76126 1.50634 7.76126 0.793187 8.2006 0.35333Z"
                    fill="#29394D"
                  />
                </svg>
              </button>
            </div>

            <div className={styles.footer}>
              Powered by tamtam International
              <br />
              {_("home_footer")}
            </div>
          </div>
        </div>
        <div className={classNames(styles.rightSide, "cell large-3 medium-12")}>
          <svg
            width="527"
            height="526"
            viewBox="0 0 527 526"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M527 263C527 408.251 409.251 526 264 526C263.999 375.339 264.002 408.251 264.002 263C264.002 117.749 264 145.965 264 0C409.251 0 527 117.749 527 263Z"
              fill="url(#paint0_radial_45323_20511)"
            />
            <path
              d="M338.212 262.501C338.212 304.311 304.067 338.21 261.938 338.21C219.81 338.21 185.664 304.311 185.664 262.501C185.664 220.691 219.81 186.791 261.938 186.791C304.067 186.791 338.212 220.691 338.212 262.501Z"
              stroke={auth.user && !welcome ? "#06D9B1" : "#18A0FB"}
            />
            <circle
              cx="262.5"
              cy="262.5"
              r="123.129"
              stroke={auth.user && !welcome ? "#06D9B1" : "#18A0FB"}
              stroke-dasharray="4 4"
            />
            <path
              d="M452.242 263.064C452.242 368.169 367.29 453.371 262.5 453.371C157.71 453.371 72.7578 368.169 72.7578 263.064C72.7578 157.96 157.71 72.7578 262.5 72.7578C367.29 72.7578 452.242 157.96 452.242 263.064Z"
              stroke={auth.user && !welcome ? "#06D9B1" : "#18A0FB"}
            />
            <circle
              cx="262.5"
              cy="262.5"
              r="262"
              stroke={auth.user && !welcome ? "#06D9B1" : "#18A0FB"}
              stroke-dasharray="4 4"
            />
            <defs>
              <radialGradient
                id="paint0_radial_45323_20511"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(263.998 263) rotate(90) scale(263)"
              >
                <stop stop-color="#C7E8FE" />
                <stop offset="1" stop-color="#C7E8FE" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className={styles.video}>
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M49.9987 91.6673C73.0106 91.6673 91.6654 73.0125 91.6654 50.0006C91.6654 26.9888 73.0106 8.33398 49.9987 8.33398C26.9868 8.33398 8.33203 26.9888 8.33203 50.0006C8.33203 73.0125 26.9868 91.6673 49.9987 91.6673Z"
              stroke="#18A0FB"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M41.668 33.334L66.668 50.0007L41.668 66.6673V33.334Z"
              stroke="#18A0FB"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Home;
