import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import classnames from "classnames";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";

import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import Checkbox from "../../../common/Checkbox";
import FormInput from "../../../common/FormInput";
import _ from "../../../../i18n";
import { getUserByEmail, getUserById, saveOECCBBMember } from "../../../../api";
import { SELECT_STYLES } from "../../../../config";
import {
  validateEmail,
  validatePhone,
  formatPhone,
  formatHidePhone,
  validateUen,
} from "../../../../utils";
import styles from "./MembershipForm.module.scss";

const yearsOptions = [
  { value: moment().format("YYYY"), label: moment().format("YYYY") },
];

for (let i = moment().year() - 1; i >= 2020; i--) {
  yearsOptions.push({ value: i, label: i });
}

const formulaOptions = [
  { value: 1, label: "Membres effectifs" },
  { value: 2, label: "Membres cotisants +65 ans" },
  { value: 3, label: "Membres stagiaires" },
  { value: 3, label: "Membres cotisants étudiants et professeurs" },
  { value: 4, label: "Membres professions juridiques" },
];

const MembershipForm = (props) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [email, setEmail] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [year, setYear] = useState(yearsOptions[0]);
  const [formula, setFormula] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [fetchedUser, setFetchedUser] = useState(null);
  const [disableFields, setDisableFields] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [uen, setUen] = useState("");
  const [organization, setOrganization] = useState("");
  const [subjectToVAT, setSubjectToVAT] = useState(true);
  const [errors, setErrors] = useState({
    email: "",
    lastname: "",
    firstname: "",
    phone: "",
    formula: "",
    uen: "",
    organization: "",
    address: "",
    postalCode: "",
    city: "",
  });

  useEffect(() => {
    if (props.currentItem) {
      setIsFetchingUser(true);
      getUserById(auth.token, props.currentItem.id)
        .then((resp) => {
          if (resp.data.data.length > 0) {
            setEmail(resp.data.data[0].mainEmail);
            setFirstname(resp.data.data[0].firstName);
            setLastname(resp.data.data[0].lastName);
            if (resp.data.data[0].mainPhone) {
              setPhone(resp.data.data[0].mainPhone);
              setPhoneValue(resp.data.data[0].mainPhone);
            }
            setFetchedUser(resp.data.data[0]);
            setIsFetchingUser(false);
            setIsValidForm(true);
          }
        })
        .catch((e) => {
          setDisableFields(false);
          setIsFetchingUser(false);
          setFetchedUser(null);
        });
    }
  }, [props.currentItem]);

  useEffect(() => {
    if (props.resetForm) {
      handleResetForm();
      props.setResetForm(false);
    }
  }, [props.resetForm]);

  useEffect(() => {
    if (email.length > 0 && firstname.length > 0 && lastname.length > 0) {
      setIsValidForm(
        errors.email || errors.lastname || errors.firstname || errors.phone
          ? false
          : true
      );
    }
  }, [errors]);

  const handleKeyUp = (fieldName, fieldValue) => {
    let value = "";
    if (fieldValue.length === 0) {
      value = _("required_field");
    } else {
      switch (fieldName) {
        case "email":
          if (!validateEmail(fieldValue)) {
            value = _("validate_email");
          }
          break;
        case "phone":
          const result = validatePhone(fieldValue);
          if (!result.valid) {
            value = _("validate_phone");
          }
          break;
        case "lastname":
        case "firstname":
          if (fieldValue.length < 2) {
            value = _("required_2_characters");
          }
          break;
      }
    }
    setErrors({ ...errors, [fieldName]: value });
  };

  const handleEmailBlur = (e) => {
    if (email.length > 0 && validateEmail(email)) {
      if (fetchedUser && fetchedUser.mainEmail === email) {
        return null;
      }
      setIsFetchingUser(true);

      getUserByEmail(auth.token, email)
        .then((resp) => {
          if (resp.data.data.length > 0) {
            setFetchedUser(resp.data.data[0]);
            setFirstname(resp.data.data[0].firstName);
            setLastname(resp.data.data[0].lastName);
            if (resp.data.data[0].mainPhone) {
              setPhone(resp.data.data[0].mainPhone);
              setPhoneValue(formatHidePhone(resp.data.data[0].mainPhone));
            }
            setDisableFields(true);

            setErrors({
              email: "",
              lastname: "",
              firstname: "",
              phone: "",
            });
          }
          setIsFetchingUser(false);
          // validate();
        })
        .catch((e) => {
          setDisableFields(false);
          setIsFetchingUser(false);
          handleResetForm(false);
        });
    }
  };

  const handlePhoneBlur = (e) => {
    if (phone === phoneValue) {
      setPhone(formatPhone(phone));
      setPhoneValue(formatPhone(phone));
    }
  };

  const handlePhoneKeyUp = (fieldValue) => {
    let value = "";
    if (fieldValue.length > 0) {
      const result = validatePhone(fieldValue);
      if (!result.valid) {
        value = _("validate_phone");
      }
    }
    setErrors({ ...errors, phone: value });
  };

  const handleVatClick = (e) => {
    e.stopPropagation();
    setSubjectToVAT(!subjectToVAT);
  };

  const handleUenKeyUp = (value) => {
    let error = "";
    if (!validateUen(value)) {
      error = _("validate_uen");
    } else {
      error = "";
    }
    if (value.length === 0) {
      error = _("required_field");
    }
    setErrors({ ...errors, uen: error });
  };

  const handleAddressChange = (address) => {
    if (address.length === 0) {
      setErrors({ ...errors, address: _("required_field") });
    } else {
      setErrors({ ...errors, address: "" });
    }
    // setData({ ...data, address: address });
    setAddress(address);
  };

  const handleSelectAddress = (address) => {
    geocodeByAddress(address)
      .then((results) => results[0])
      .then((results) => {
        const addressComponents = results.address_components;
        const zip_code = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const comp_city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        const state = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("administrative_area_level_1")
        );
        const country = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("country")
        );
        const city = comp_city.length > 0 ? comp_city[0].long_name : "";
        const zipCode = zip_code.length > 0 ? zip_code[0].long_name : "";
        let cleanAdd = address;
        if (country.length > 0)
          cleanAdd = cleanAdd.replace(", " + country[0].long_name, "");
        if (state.length > 0)
          cleanAdd = cleanAdd.replace(", " + state[0].long_name, "");
        if (city.length > 0) cleanAdd = cleanAdd.replace(", " + city, "");

        setCity(city);
        setPostalCode(zipCode);
        setAddress(cleanAdd);

        setErrors({
          ...errors,
          postalCode: zipCode ? "" : _("required_field"),
          city: city ? "" : _("required_field"),
        });
      })
      .catch((error) => console.error("Error", error));
  };

  const validate = () => {
    // let errors = [];
    handleKeyUp("email", email);
    handleKeyUp("lastname", lastname);
    handleKeyUp("firstname", firstname);
    handlePhoneKeyUp(phone);

    let tabErrors = {
      formula: !formula ? _("required_field") : "",
      uen: !validateUen(uen) && subjectToVAT ? _("validate_uen") : "",
      organization: organization.length === 0 ? _("required_field") : "",
      address: address.length === 0 ? _("required_field") : "",
      postalCode: postalCode.length === 0 ? _("required_field") : "",
      city: city.length === 0 ? _("required_field") : "",
    };
    setErrors({ ...errors, ...tabErrors });

    return errors.email ||
      errors.lastname ||
      errors.firstname ||
      errors.phone ||
      tabErrors.uen ||
      tabErrors.organization ||
      tabErrors.address ||
      tabErrors.postalCode ||
      tabErrors.city ||
      !formula
      ? true
      : false;
  };

  const save = () => {
    let error = validate();
    if (error) {
      return null;
    }

    const data = {
      email,
      firstname,
      lastname,
      phone,
      year: year.value,
      formula: formula.value,
      uen,
      organization,
      address,
      postalCode,
      city,
      subjectToVAT,
    };

    if (props.currentItem) {
      data.userID = props.currentItem.id;
      if (props.currentItem.email && props.currentItem.email.length > 0) {
        data.emailId = props.currentItem.email.filter(
          (item) => item.email === props.currentItem.mainEmail
        )[0].id;
      }
      if (props.currentItem.phone && props.currentItem.phone.length > 0) {
        data.phoneId = props.currentItem.phone.filter(
          (item) => item.number === props.currentItem.mainPhone
        )[0].id;
      }
    }

    setIsSaving(true);
    saveOECCBBMember(auth.token, data, fiduciary.id)
      .then((resp) => {
        props.afterSave();
        setTimeout(() => {
          setIsSaving(false);
          toast.success(_("successfully_added"));
          handleResetForm();
          if (props.setSideBar) {
            props.setSideBar("DEFAULT");
          }
        }, 1000);
      })
      .catch((e) => {
        if (e?.response?.data?.errors) {
          const msg =
            e.response.data.errors?.length > 0
              ? _(e.response.data.errors[0].message)
              : e.response.data.errors?.message
              ? e.response.data.errors.message
              : _("error");
          toast.error(msg);
        } else {
          toast.error(_("error"));
        }
        setIsSaving(false);
      });
  };

  const handleResetForm = (resetEmail = true) => {
    if (resetEmail) {
      setEmail("");
    }
    setFirstname("");
    setLastname("");
    setPhone("");
    setPhoneValue("");
    setDisableFields(false);
    setFetchedUser(null);
    setYear(yearsOptions[0]);
    setFormula();
  };

  return (
    <div className={props.addFormClass ? styles.form : ""}>
      <div
        className={`${styles.form_content} ${
          props.addFormClass && styles.form_content_pad
        } ${isFetchingUser && styles.loading}`}
      >
        {props.setSideBar && (
          <div
            className={styles.close}
            onClick={() => props.setSideBar("DEFAULT")}
          >
            <i className="icon-ttp-close"></i>
          </div>
        )}
        <div className={styles.titles}>
          <h4>Ajout Manuel d'un membre OECCBB</h4>
          <p>Remplissez les champs pour l'ajout</p>
        </div>

        <FormInput
          name="email"
          required={true}
          label={_("email_address")}
          autocomplete="none"
          error={errors.email}
          value={email}
          onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
          handleBlur={(e) => handleEmailBlur(e)}
          onChange={(e) => setEmail(e.target.value)}
          disabled={props.currentItem}
        />

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <FormInput
              name="lastname"
              required={true}
              label={_("lastname")}
              autocomplete="none"
              error={errors.lastname}
              value={lastname}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => setLastname(e.target.value)}
              disabled={disableFields}
            />
          </div>
          <div className="cell small-12 medium-6">
            <FormInput
              name="firstname"
              required={true}
              label={_("firstname")}
              autocomplete="none"
              error={errors.firstname}
              value={firstname}
              onKeyUp={(e) => handleKeyUp(e.target.name, e.target.value)}
              onChange={(e) => setFirstname(e.target.value)}
              disabled={disableFields}
            />
          </div>
        </div>

        <FormInput
          name="phone"
          label={_("mobile_phone_number")}
          placeholder="+32XXXXXXXX"
          autocomplete="none"
          value={phoneValue}
          error={errors.phone}
          onKeyUp={(e) => handlePhoneKeyUp(e.target.value)}
          onChange={(e) => {
            setPhone(e.target.value);
            setPhoneValue(e.target.value);
          }}
          handleBlur={(e) => handlePhoneBlur(e)}
          disabled={disableFields && fetchedUser && fetchedUser.mainPhone}
        />

        <div className={styles.vatbox}>
          <div className={styles.acceptActions}>
            <Checkbox checked={subjectToVAT} onClick={handleVatClick} radio />
            <label className={styles.checkbox} onClick={handleVatClick}>
              {_("subject_to_vat")}
            </label>
          </div>
          <div className={styles.acceptActions}>
            <Checkbox
              checked={!subjectToVAT}
              onClick={handleVatClick}
              radio={true}
            />
            <label className={styles.checkbox} onClick={handleVatClick}>
              {_("not_subject_to_vat")}
            </label>
          </div>
        </div>

        <FormInput
          name="uen"
          required={subjectToVAT}
          placeholder="BE XXXX.XXX.XXX"
          label={_("business_number")}
          info={_("uen_info")}
          error={errors.uen}
          value={uen}
          autoComplete="none"
          onKeyUp={(e) => handleUenKeyUp(e.target.value)}
          //   handleBlur={(e) => handleUenBlur(e.target.value)}
          onChange={(e) => setUen(e.target.value)}
        ></FormInput>

        <FormInput
          name="organization"
          required={true}
          label={_("organization")}
          autoComplete="off"
          error={errors.organization}
          value={organization}
          onChange={(e) => setOrganization(e.target.value)}
        />

        <div className="ttp-form-group-h">
          <label className="ttp-label">
            {_("address")}
            <span className="star">*</span>
          </label>
          <PlacesAutocomplete
            value={address}
            onChange={handleAddressChange}
            onSelect={handleSelectAddress}
            searchOptions={{
              componentRestrictions: { country: ["be"] },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="group-input">
                <input
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: classnames(
                      "location-search-input",
                      errors.address ? "error" : ""
                    ),
                  })}
                  autoComplete="none"
                  name="address"
                  // onBlur={(e) => handleBlur(e.target.name, e.target.value)}
                />
                <span className="ttp-error">{errors.address}</span>
                {suggestions.length > 0 ? (
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <FormInput
              name="postalCode"
              required={true}
              label={_("zipCode")}
              autoComplete="off"
              error={errors.postalCode}
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>
          <div className="cell small-12 medium-6">
            <FormInput
              name="city"
              required={true}
              label={_("city")}
              autoComplete="off"
              error={errors.city}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </div>

        <div className="grid-x grid-margin-x">
          <div className="cell small-12 medium-6">
            <div className="ttp-form-group">
              <label className="ttp-label">{_("year")}</label>
              <Select
                styles={SELECT_STYLES}
                options={yearsOptions}
                isSearchable={false}
                value={year}
                onChange={(e) => setYear(e)}
              />
            </div>
          </div>
          <div className="cell small-12 medium-6">
            <div className="ttp-form-group">
              <label className="ttp-label">{_("formula")} OECCBB</label>
              <Select
                styles={SELECT_STYLES}
                options={formulaOptions}
                isSearchable={false}
                value={formula}
                onChange={(e) => setFormula(e)}
              />
            </div>
            {errors.formula && (
              <span className="ttp-error">{errors.formula}</span>
            )}
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          variant="default"
          onClick={() => {
            handleResetForm();
            if (props.setSideBar) {
              props.setSideBar("DEFAULT");
            }
          }}
        >
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button disabled={!isValidForm} onClick={() => save()}>
            {props.currentItem ? _("edit") : _("add")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default MembershipForm;
