import React from "react";
import StarIcon from "../icons/StarIcon";
import styles from "./Testimonials.module.scss";

const TestimonialCard = ({ testimonial, lng }) => {
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <div className={styles.testimonial_card}>
      <div className={styles.rating}>
        {[...Array(5)].map((i) => (
          <StarIcon key={i} />
        ))}
      </div>
      <div className={styles.message}>{`"${
        testimonial && testimonial[descriptionAttr]
          ? testimonial[descriptionAttr]
          : "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
      }"`}</div>
      <div className={styles.author}>
        <div className={styles.img} style={{ backgroundImage: `url()` }}></div>
        <div className={styles.info}>
          <div className={styles.name}>
            {testimonial?.author ? testimonial.author : "Lorem ipsum"}
          </div>
          <div className={styles.position}>
            {testimonial?.headline ? testimonial.headline : "dolor sit amet"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
