import React from "react";

export const IconStar = ({ filled, className, onClick }) => {
  return filled ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      className={className}
      onClick={onClick}
    >
      <path
        d="m7.325 19.925 1.25-5.325-4.125-3.55 5.425-.475L12 5.55l2.125 5.025 5.425.475-4.125 3.55 1.25 5.325L12 17.1Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      className={className}
      onClick={onClick}
    >
      <path d="m8.85 17.825 3.15-1.9 3.15 1.925-.825-3.6 2.775-2.4-3.65-.325-1.45-3.4-1.45 3.375-3.65.325 2.775 2.425Zm-1.525 2.1 1.25-5.325-4.125-3.55 5.425-.475L12 5.55l2.125 5.025 5.425.475-4.125 3.55 1.25 5.325L12 17.1ZM12 13.25Z" />
    </svg>
  );
};
