import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Popover from "@mui/material/Popover";
import Select from "react-select";

import { getPack, savePackSettings } from "../../../../api";
import { SELECT_INPUT_STYLES } from "../../../../config";
import _ from "../../../../i18n";
import Button from "../../../common/Button";
import Loader from "../../../common/Loader";
import Tabs from "../../../common/Tabs";
import Checkbox from "../../../common/Checkbox";
import IconYes from "../../../common/icons/IconYes";
import IconNo from "../../../common/icons/IconNo";

import styles from "./FormulaApp.module.scss";
import sidebarStyles from "../Sidebar.module.scss";

const FormulaAppPack = ({ currentPack, onBack, onSave }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const labelAttr = `label${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const suffixAttr = `suffix${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pack, setPack] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [options, setOptions] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentFormulaApp, setCurrentFormulaApp] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [textSuffix, setTextSuffix] = useState({
    fr: "",
    nl: "",
    en: "",
  });
  const [content, setContent] = useState("");
  const [contentSelect, setContentSelect] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [optionText, setOptionText] = useState("");
  const [monthPrice, setMonthPrice] = useState("");
  const [monthMemberPrice, setMonthMemberPrice] = useState("");
  const [annualPrice, setAnnualPrice] = useState("");
  const [annualMemberPrice, setAnnualMemberPrice] = useState("");

  useEffect(() => {
    if (currentPack) {
      setIsLoading(true);
      getPack(token, currentPack.id)
        .then((response) => {
          if (response?.data?.data?.length === 1) {
            let tab = {};
            const result = response.data.data[0];
            if (result.uaPackFormulas) {
              result?.uaPackFormulas.forEach((formula) => {
                tab[formula.id] = formula.apps.map((row) => {
                  return {
                    ...row,
                    suffixFr: row.suffixFr ?? "",
                    suffixNl: row.suffixNl ?? "",
                    suffixEn: row.suffixEn ?? "",
                  };
                });
              });
            }
            setOptions(tab);
            setPack(result);
          }
        })
        .catch((e) => {})
        .finally(() => {
          setIsLoading(false);
          if (refetch) {
            setRefetch(false);
          }
        });
    }
  }, [currentPack, refetch]);

  const handleOptionClick = (event, formula, option) => {
    const value = options[formula.id].filter((i) => i.appId === option.id);
    if (value.length > 0) {
      if (value[0].type === "SELECT" && value[0].options) {
        // const tab = value[0].options.map((item) => {
        //   return {
        //     ...item,
        //     label: item[labelAttr],
        //   };
        // });
        // setContentSelect(tab);
        setContentSelect(value[0].options);
      } else {
        setTextValue(value[0].value);
        setTextSuffix({
          fr: value[0].suffixFr ?? "",
          nl: value[0].suffixNl ?? "",
          en: value[0].suffixEn ?? "",
        });
      }
      setCurrentFormulaApp({ ...value[0], formula: formula.id });
    } else {
      setCurrentFormulaApp({
        type: "CHECK",
        content: "",
        formula: formula.id,
        appId: option.id,
      });
    }

    setAnchorEl(event.currentTarget);
  };

  const savePopover = () => {
    const tab = { ...options };
    if (!currentFormulaApp.type) {
      tab[currentFormulaApp.formula] = tab[currentFormulaApp.formula].filter(
        (i) => i.appId !== currentFormulaApp.appId
      );
    } else {
      let hasError = false;
      const newData = { ...currentFormulaApp };
      if (currentFormulaApp.type === "TEXT") {
        newData.value = textValue;
        if (textValue.length === 0) {
          hasError = true;
        }
        newData.suffixFr = textSuffix.fr;
        newData.suffixNl = textSuffix.nl;
        newData.suffixEn = textSuffix.en;
        // currentPack.languages.forEach((lng) => {
        //   if (!textSuffix[lng]) {
        //     hasError = true;
        //   }
        // });
      } else if (currentFormulaApp.type === "SELECT") {
        if (contentSelect.length === 0) {
          toast.error("Veuillez ajouter des options");
          return null;
        }
        newData.options = contentSelect;
      } else {
        newData.suffixFr = textSuffix.fr;
        newData.suffixNl = textSuffix.nl;
        newData.suffixEn = textSuffix.en;
      }

      if (hasError) {
        toast.error("Veuillez remplir le champ texte");
        return null;
      }
      const value = options[currentFormulaApp.formula].filter(
        (i) => i.appId === currentFormulaApp.appId
      );

      if (value.length > 0) {
        // Remove the option
        if (newData.type.length === 0) {
          tab[currentFormulaApp.formula] = tab[
            currentFormulaApp.formula
          ].filter((i) => i.appId !== currentFormulaApp.appId);
        } else {
          tab[currentFormulaApp.formula] = tab[currentFormulaApp.formula].map(
            (i) => {
              return i.appId === currentFormulaApp.appId ? newData : i;
            }
          );
        }
      } else {
        tab[currentFormulaApp.formula].push(newData);
      }
    }
    setOptions(tab);

    handleClosePopover();
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setCurrentFormulaApp(null);
    setTextValue("");
    setTextSuffix({
      fr: "",
      nl: "",
      en: "",
    });
  };

  const addRowOption = () => {
    if (textValue.length === 0) {
      toast.error("Veuillez remplir le champ texte");
      return null;
    }
    const tab = [...contentSelect];
    const row = {
      value: textValue,
      suffixFr: textSuffix.fr,
      suffixNl: textSuffix.nl,
      suffixEn: textSuffix.en,
    };
    row.monthPrice = monthPrice.length === 0 ? 0 : monthPrice;
    row.annualPrice = annualPrice.length === 0 ? 0 : annualPrice;
    row.monthMemberPrice = monthMemberPrice.length === 0 ? 0 : monthMemberPrice;
    row.annualMemberPrice =
      annualMemberPrice.length === 0 ? 0 : annualMemberPrice;

    if (currentIndex !== null) {
      tab[currentIndex] = row;
    } else {
      tab.push(row);
    }
    setCurrentIndex(null);
    setContentSelect(tab);
    setTextValue("");
    setTextSuffix({
      fr: "",
      nl: "",
      en: "",
    });
    setMonthPrice("");
    setAnnualPrice("");
    setMonthMemberPrice("");
    setAnnualMemberPrice("");
  };

  const editSelectRow = (index) => {
    setTextSuffix({
      fr: contentSelect[index].suffixFr,
      nl: contentSelect[index].suffixNl,
      en: contentSelect[index].suffixEn,
    });
    setTextValue(contentSelect[index].value);
    setMonthPrice(contentSelect[index].monthPrice);
    setMonthMemberPrice(contentSelect[index].monthMemberPrice);
    setAnnualPrice(contentSelect[index].annualPrice);
    setAnnualMemberPrice(contentSelect[index].annualMemberPrice);
    setCurrentIndex(index);
  };

  const removeSelectRow = (index) => {
    const tab = contentSelect.filter((i, idx) => idx !== index);
    setContentSelect(tab);
  };

  const getOptionIcon = (formulaId, appId) => {
    const value = options[formulaId].filter((i) => i.appId === appId);
    if (value.length === 0) {
      return <IconNo />;
    }
    if (value[0].type === "TEXT") {
      return value[0].value + " " + value[0][suffixAttr];
    } else if (value[0].type === "SELECT" && value[0].options) {
      let options = value[0].options.map((i) => {
        return { label: i.value + " " + i[suffixAttr], value: i };
      });
      return (
        <div className={styles.selectBox}>
          <div onClick={(e) => e.stopPropagation()}>
            <Select
              defaultValue={options[0]}
              options={options}
              styles={SELECT_INPUT_STYLES}
            />
          </div>

          <i className="icon-ttp-edit" />
        </div>
      );
    }
    return <IconYes />;
  };

  const handleSave = () => {
    const formulaApps = [];
    for (const formulaId in options) {
      if (options[formulaId].length > 0) {
        options[formulaId].forEach((option) => {
          formulaApps.push({
            uaPackFormula: parseInt(formulaId),
            uaPackApp: option,
          });
        });
      }
    }
    const data = {
      uaPack: currentPack.id,
      formulaApps,
    };

    setIsSaving(true);
    savePackSettings(token, data)
      .then((response) => {
        setIsSaving(false);
        toast.success(_("successfully_saved"));
        onSave();
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  return (
    <div className={styles.page}>
      {isLoading && <div className="lmask"></div>}
      {pack && (
        <>
          {pack.uaPackFormulas && (
            <>
              <div className={`${styles.pack_empty_detail} ${styles.sticky}`}>
                <div className={styles.pack_empty_detail_1}>&nbsp;</div>
                {pack.uaPackFormulas.map((formula) => (
                  <div className={styles.pack_empty_detail_2}>
                    {_("formula") + " "} {formula.name}
                  </div>
                ))}
              </div>

              <div className={styles.pack_detail_bottom}>
                {pack.uaPackApps.map((app, idx) => (
                  <div key={idx}>
                    <div
                      className={`${styles.pack_app} ${
                        app.children.length === 0 && styles.pack_app_hover
                      }`}
                    >
                      <div
                        className={`${styles.pack_app_left} ${styles.pack_app_name}`}
                      >
                        {app?.uaApplication.icon && (
                          <img
                            width="25"
                            src={`/img/apps/${app.uaApplication.icon}`}
                            alt=""
                          />
                        )}{" "}
                        {app[nameAttr]}
                      </div>
                      {pack.uaPackFormulas.map((formula) =>
                        app.children.length === 0 ? (
                          <div
                            className={styles.pack_app_app}
                            onClick={(e) => handleOptionClick(e, formula, app)}
                          >
                            {getOptionIcon(formula.id, app.id)}
                          </div>
                        ) : (
                          <div className={styles.pack_app_pro}></div>
                        )
                      )}
                    </div>

                    {app.children.map((category, categoryIdx) => (
                      <div
                        key={categoryIdx}
                        className={styles.pack_app_category}
                      >
                        {app.children.length > 0 && (
                          <div
                            className={`${styles.pack_app} ${
                              category.children.length === 0 &&
                              styles.pack_app_hover
                            }`}
                          >
                            <div
                              className={`${styles.pack_app_left} ${styles.pack_app_cat_left}`}
                            >
                              {category[nameAttr]}
                            </div>
                            {pack.uaPackFormulas.map((formula) =>
                              category.children.length === 0 ? (
                                <div
                                  className={styles.pack_app_app}
                                  onClick={(e) =>
                                    handleOptionClick(e, formula, category)
                                  }
                                >
                                  {getOptionIcon(formula.id, category.id)}
                                </div>
                              ) : (
                                <div className={styles.pack_app_pro}></div>
                              )
                            )}
                          </div>
                        )}

                        {category.children?.map(
                          (subCategory, subCategoryIdx) => (
                            <div key={subCategoryIdx}>
                              <div
                                className={`${styles.pack_app} ${
                                  styles.pack_app_subcat
                                } ${
                                  subCategory.children.length === 0 &&
                                  styles.pack_app_hover
                                }`}
                              >
                                <div
                                  className={`${styles.pack_app_left} ${styles.pack_app_subcat_left}`}
                                >
                                  {subCategory[nameAttr]}
                                </div>
                                {pack.uaPackFormulas.map((formula) =>
                                  subCategory.children.length === 0 ? (
                                    <div
                                      className={styles.pack_app_app}
                                      onClick={(e) =>
                                        handleOptionClick(
                                          e,
                                          formula,
                                          subCategory
                                        )
                                      }
                                    >
                                      {getOptionIcon(
                                        formula.id,
                                        subCategory.id
                                      )}
                                    </div>
                                  ) : (
                                    <div className={styles.pack_app_pro}></div>
                                  )
                                )}
                              </div>
                              {subCategory.children.map((option, optionIdx) => (
                                <div
                                  key={optionIdx}
                                  className={`${styles.pack_app} ${styles.pack_app_hover}`}
                                >
                                  <div
                                    className={`${styles.pack_app_left} ${styles.pack_app_fonct}`}
                                  >
                                    <span>{option[nameAttr]}</span>
                                  </div>
                                  {pack.uaPackFormulas.map((formula) => {
                                    if (!options[formula.id]) {
                                      return null;
                                    }
                                    return (
                                      <div
                                        className={styles.pack_app_app}
                                        onClick={(e) =>
                                          handleOptionClick(e, formula, option)
                                        }
                                      >
                                        {getOptionIcon(formula.id, option.id)}
                                      </div>
                                    );
                                  })}
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                ))}
              </div>

              <div className={`${styles.pack_app} ${styles.pack_app_end}`}>
                <div className={styles.pack_app_left}></div>
                {pack.uaPackFormulas.map((formula) => (
                  <div className={styles.pack_app_pro}></div>
                ))}
              </div>
            </>
          )}

          {pack?.uaPackApps.length > 0 ? (
            <div className={sidebarStyles.actions}>
              <Button variant="default" onClick={onBack}>
                {_("back")}
              </Button>

              {isSaving ? (
                <Button
                  variant="primary"
                  style={{ paddingTop: "15px", paddingBottom: "15px" }}
                >
                  <Loader
                    style={{
                      height: "10px",
                    }}
                    color={"#fff"}
                  />
                </Button>
              ) : (
                <Button onClick={handleSave}>{_("save")}</Button>
              )}
            </div>
          ) : (
            <h3 style={{ marginTop: "2rem" }}>
              Veuillez valider l'étape précédente
            </h3>
          )}

          <Popover
            id="simple-popover"
            open={Boolean(anchorEl) && currentFormulaApp}
            anchorEl={anchorEl}
            onClose={handleClosePopover}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {currentFormulaApp && (
              <>
                <div className={styles.popover}>
                  <h4>Sélectionnez le type de valeur</h4>
                  <div className={styles.popover_top}>
                    <div>
                      <Checkbox
                        checked={currentFormulaApp.type === "CHECK"}
                        radio={true}
                        onClick={(e) =>
                          setCurrentFormulaApp({
                            ...currentFormulaApp,
                            type:
                              currentFormulaApp.type === "CHECK" ? "" : "CHECK",
                          })
                        }
                      />
                      CHECK
                    </div>
                    <div>
                      <Checkbox
                        checked={currentFormulaApp.type === "TEXT"}
                        radio={true}
                        onClick={() =>
                          setCurrentFormulaApp({
                            ...currentFormulaApp,
                            type:
                              currentFormulaApp.type === "TEXT" ? "" : "TEXT",
                          })
                        }
                      />
                      TEXT
                    </div>
                    <div>
                      <Checkbox
                        checked={currentFormulaApp.type === "SELECT"}
                        radio={true}
                        onClick={() =>
                          setCurrentFormulaApp({
                            ...currentFormulaApp,
                            type:
                              currentFormulaApp.type === "SELECT"
                                ? ""
                                : "SELECT",
                          })
                        }
                      />
                      SELECT
                    </div>
                  </div>

                  {currentFormulaApp.type === "CHECK" ? (
                    <div className={styles.popover_icon}>
                      <IconYes />
                    </div>
                  ) : currentFormulaApp.type === "TEXT" ? (
                    <>
                      <div className="ttp-form-group">
                        <label className="ttp-label">Entrez la valeur</label>
                        <input
                          type="number"
                          className="ttp-input"
                          autocomplete="off"
                          value={textValue}
                          onChange={(e) => setTextValue(e.target.value)}
                        />
                      </div>

                      <Tabs theme="wtheme">
                        {currentPack.languages.map((lang) => (
                          <div
                            key={`langtab${lang}`}
                            label={lang}
                            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${lang}.png`}
                          >
                            <div className={styles.langTab}>
                              <label className="ttp-label">
                                {"Suffix " + lang.toUpperCase()}
                              </label>
                              <input
                                type="text"
                                className="ttp-input"
                                autocomplete="off"
                                value={textSuffix[lang]}
                                onChange={(e) =>
                                  setTextSuffix({
                                    ...textSuffix,
                                    [lang]: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </Tabs>
                    </>
                  ) : currentFormulaApp.type === "SELECT" ? (
                    <>
                      <h5>Ajoutez vos options</h5>
                      <div className={styles.add_row}>
                        <div className={styles.add_row_input}>
                          <label className="ttp-label">Entrez la valeur</label>
                          <input
                            type="text"
                            className="ttp-input"
                            autocomplete="off"
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                          />
                        </div>

                        <div className={styles.add_row_input}>
                          <label className="ttp-label">Suffix</label>
                          <input
                            type="text"
                            className="ttp-input"
                            autocomplete="off"
                            value={textSuffix.fr}
                            onChange={(e) =>
                              setTextSuffix({
                                ...textSuffix,
                                fr: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className={styles.add_row_price}>
                          <label className="ttp-label">Prix €/mois</label>
                          <input
                            type="number"
                            className="ttp-input"
                            autocomplete="off"
                            value={monthPrice}
                            onChange={(e) => setMonthPrice(e.target.value)}
                          />
                        </div>

                        <div className={styles.add_row_price}>
                          <label className="ttp-label">Prix €/an</label>
                          <input
                            type="number"
                            className="ttp-input"
                            autocomplete="off"
                            value={annualPrice}
                            onChange={(e) => setAnnualPrice(e.target.value)}
                          />
                        </div>

                        <div className={styles.add_row_price}>
                          <label className="ttp-label">
                            Prix €/mois/membre
                          </label>
                          <input
                            type="number"
                            className="ttp-input"
                            autocomplete="off"
                            value={monthMemberPrice}
                            onChange={(e) =>
                              setMonthMemberPrice(e.target.value)
                            }
                          />
                        </div>

                        <div className={styles.add_row_price}>
                          <label className="ttp-label">Prix €/an/membre</label>
                          <input
                            type="number"
                            className="ttp-input"
                            autocomplete="off"
                            value={annualMemberPrice}
                            onChange={(e) =>
                              setAnnualMemberPrice(e.target.value)
                            }
                          />
                        </div>
                        <Button size="sm" onClick={addRowOption}>
                          +
                        </Button>
                      </div>
                      {contentSelect.length > 0 && (
                        <table className={styles.table}>
                          <tbody>
                            {contentSelect.map((row, idx) => (
                              <tr key={idx}>
                                <td width="25%">
                                  {row.value + " " + row[suffixAttr]}
                                </td>
                                <td>{row.monthPrice}€/mois</td>
                                <td>{row.annualPrice}€/an</td>
                                <td>{row.monthMemberPrice}€/mois/membre</td>
                                <td>{row.annualMemberPrice}€/an/membre</td>
                                <td className={styles.actions}>
                                  <span
                                    className={styles.edit_row}
                                    onClick={() => editSelectRow(idx)}
                                  >
                                    <i className="icon-ttp-edit" />
                                  </span>
                                  {!row.isUsed && (
                                    <span
                                      className={styles.remove_row}
                                      onClick={() => removeSelectRow(idx)}
                                    >
                                      <i className="icon-ttp-close-outline" />
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </>
                  ) : (
                    <div className={styles.popover_icon}>
                      <IconNo />
                    </div>
                  )}
                </div>
                <div className={styles.popover_actions}>
                  <Button
                    variant="default"
                    onClick={handleClosePopover}
                    size="sm"
                  >
                    {_("cancel")}
                  </Button>
                  <Button onClick={savePopover} size="sm">
                    {_("validate")}
                  </Button>
                </div>
              </>
            )}
          </Popover>
        </>
      )}
    </div>
  );
};

export default FormulaAppPack;
