import React, { PureComponent } from "react";

import _ from "../../i18n";
import { TTP_API_URL } from "../../config";
import styles from "./PublicPortal.module.scss";

export default class Subheader extends PureComponent {
  state = {
    firstClick: true,
  };
  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window.onscroll = () => {
      if (document.getElementById("portal-header")) {
        if (
          document.body.scrollTop > 65 ||
          document.documentElement.scrollTop > 65
        ) {
          document.getElementById(
            "portal-header"
          ).className = `${styles.header} ${styles.header_fixed}`;
        } else {
          document.getElementById("portal-header").className = styles.header;
        }
      }

      const portalHeader = document.getElementById("portal-header");
      if (portalHeader) {
        const btns = portalHeader.getElementsByTagName("li");
        if (btns && btns.length > 0) {
          for (let i = 0; i < btns.length; i++) {
            const targetDiv = document.getElementById(
              btns[i].id.replace("-btn", "")
            );
            if (
              targetDiv &&
              window.pageYOffset > targetDiv.offsetTop - 150 &&
              window.pageYOffset <
                targetDiv.offsetTop + targetDiv.offsetHeight - 150
            ) {
              btns[i].classList.add(styles.activated);
            } else if (btns[i].classList.contains(styles.activated)) {
              btns[i].classList.remove(styles.activated);
            }
          }
        }
      }
    };
  }

  scrollToBloc(divId, e) {
    const targetDiv = document.getElementById(divId);
    if (!targetDiv) {
      return null;
    }
    window.scrollTo({
      top: targetDiv.offsetTop - 130,
      left: 0,
      behavior: "smooth",
    });
    this.setState({ firstClick: false });
  }

  render() {
    const { community, blocs } = this.props;
    const { avatarWebPath, avatarUrl, name } = community;
    return (
      <div id="portal-header" className={styles.header}>
        <div className={styles.content}>
          {!avatarWebPath ? (
            <div></div>
          ) : (
            <div
              className={styles.logo}
              style={{
                backgroundImage: `url(${
                  avatarUrl ? avatarUrl : TTP_API_URL + "/" + avatarWebPath
                })`,
              }}
            ></div>
          )}
          <ul className={styles.subMenu}>
            {blocs.news.activated && (
              <li
                id="fiduciary-news-btn"
                className={styles.subMenu_btn}
                onClick={this.scrollToBloc.bind(this, "fiduciary-news")}
              >
                {_("News")}
              </li>
            )}
            {blocs.specialty && blocs.specialty.activated && (
              <li
                id="fiduciary-specialty-btn"
                className={styles.subMenu_btn}
                onClick={this.scrollToBloc.bind(this, "fiduciary-specialty")}
              >
                {_("Specialty")}
              </li>
            )}
            {blocs.team.activated && (
              <li
                id="fiduciary-team-btn"
                className={styles.subMenu_btn}
                onClick={this.scrollToBloc.bind(this, "fiduciary-team")}
              >
                {_("Team")}
              </li>
            )}

            {blocs.contact.activated && (
              <li
                id="portal-contact-btn"
                className={`${styles.subMenu_btn} ${styles.subMenu_contact}`}
                onClick={this.scrollToBloc.bind(this, "portal-contact")}
              >
                {_("Contacts")}
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}
