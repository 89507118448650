import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";
import ReactCodeInput from "react-code-input";

import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Loader from "../common/Loader";
import { postUserLoggedAsCredential } from "../../api";
import { validateEmail, cleanEmail, logout } from "../../utils";
import { setFolderFetched, setAuthTokenUser } from "../../store";
import _ from "../../i18n";
import styles from "./LoggedAs.module.scss";

const LoggedAs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    code: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const handleBlurEmail = (e) => {
    if (!validateEmail(cleanEmail(email))) {
      setErrors({ ...errors, email: _("validate_email") });
    } else {
      setErrors({ ...errors, email: "" });
    }
  };

  const validate = () => {
    let success = true;
    if (!validateEmail(cleanEmail(email))) {
      setErrors({ ...errors, email: _("validate_email") });
      success = false;
    }
    if (code.length < 6) {
      setErrors({ ...errors, code: _("validate_code") });
      success = false;
    } else {
      setErrors({ ...errors, code: "" });
    }

    return success;
  };

  const handleLogin = () => {
    if (!validate()) {
      return null;
    }

    const data = {
      email: auth.user.mainEmail,
      code,
      loggedAsEmail: email,
    };

    setIsSaving(true);
    postUserLoggedAsCredential(data)
      .then((resp) => {
        logout(false);
        dispatch(setAuthTokenUser(resp.data));
        dispatch(setFolderFetched(false));
        navigate("/home");
        setIsSaving(false);
      })
      .catch((e) => {
        if (e.response?.status === 400) {
          toast.error(_("invalid_credentials"));
        } else {
          if (e?.response?.status >= 500) {
            toast.error(_("server_error"));
          } else {
            toast.error(_("error"));
          }
        }
        setIsSaving(false);
      });
  };

  return (
    <div className="grid-container">
      <div className={styles.content}>
        <h1 className={styles.title}>{_("logged_as")} :</h1>

        <FormInput
          name="email"
          value={email}
          label={_("email")}
          error={errors.email}
          className="ttp-input-lg"
          labelClassName="ttp-label-lg"
          autocomplete="username"
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => handleBlurEmail(e)}
        />

        <label className="ttp-label ttp-label-lg">Votre code</label>
        <div className={styles.codeBox}>
          <ReactCodeInput
            type="number"
            fields={6}
            value={code}
            onChange={(value) => setCode(value)}
            autoFocus={false}
            className={styles.codeInput}
            inputStyle={{
              marginRight: "0.463rem",
              width: "34px",
              borderRadius: "4px",
              fontSize: "14px",
              height: "44px",
              backgroundColor: "#F8F9FA",
              border: "1px solid #B6BFC8",
              textAlign: "center",
            }}
          />
        </div>
        {errors.code && <span className="ttp-error">{errors.code}</span>}

        <div className={styles.actions}>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={handleLogin}>{_("signin")}</Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoggedAs;
