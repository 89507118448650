import React from "react";
import _ from "../../../i18n";
import styles from "../CreateOffice.module.scss";

const StepTwo = ({ data }) => {
  const apps = ["Blog", "FAQ", "Accounting", "oFFFcourse"];
  const nextApps = ["Webinar", "Webtools"];

  const getSize = () => {
    const size = data.size.split("-");
    return size.length > 1
      ? `${_("of")} ${size[0]} ${_("to")} ${size[1]}`
      : `${_("more_than")} ${size[0]}`;
  };
  return (
    <>
      <div className={styles.final}>
        <h3 className={styles.final_title}>{_("membership_includes")} :</h3>

        <div className={styles.final_body}>
          <div className={`${styles.benefits} ${styles.portals}`}>
            <div className={styles.benefits_title}>
              <span className={styles.benefits_icon}>
                <i className="icon-ttp-check" />
              </span>{" "}
              2 {_("portals")}
            </div>

            <div className={`${styles.benefits_text} ${styles.first_portal}`}>
              <h4>{_("public_portal")}</h4>

              <p>{_("public_portal_txt")}</p>
            </div>

            <div className={styles.benefits_text}>
              <h4>{_("collaborator_portal")}</h4>

              <p>{_("collaborator_portal_txt")}</p>
            </div>
          </div>

          <div className={`${styles.benefits} ${styles.benefits_apps}`}>
            <div className={styles.benefits_title}>
              <span className={styles.benefits_icon}>
                <i className="icon-ttp-check" />
              </span>{" "}
              2 {_("intalled_apps")}
            </div>

            <div className={styles.apps_content}>
              <div>
                <div className={styles.appName}>
                  <img
                    src={"/img/apps/blog.svg"}
                    alt=""
                    with="34"
                    height="34"
                  />
                  <span>Blog</span>
                </div>

                <p>{_("blog_app_txt")}</p>

                <div className={styles.apps_box}>
                  <ul>
                    <li>{_("blog_app_txt_1")}</li>

                    {/* <li>{_("blog_app_txt_2")}</li> */}
                  </ul>
                </div>
              </div>

              <div className={styles.sep}></div>
              <div>
                <div className={styles.appName}>
                  <img
                    src={"/img/apps/offfcourse.svg"}
                    alt=""
                    with="34"
                    height="34"
                  />
                  <div className={styles.app_watch}>
                    <span>oFFFcourse</span>
                    <span>
                      <span className={styles.tag}>WATCH</span>
                    </span>
                  </div>
                </div>

                <p
                  dangerouslySetInnerHTML={{ __html: _("offfcourse_app_txt") }}
                ></p>

                <div className={styles.apps_box}>
                  <h4>Partage Premium oFFFcourse</h4>
                  <ul>
                    <li>
                      Partagez gratuitement chaque abonnement premium oFFFcourse
                      avec jusqu'à 4 collaborateurs (sans attestion ITAA)
                    </li>

                    <li>
                      Une application de diffusion et de mobilité pour regarder
                      aisément les formation en cabinet
                    </li>
                  </ul>
                </div>

                <p>
                  Pour rappel, oFFFcourse est un catalogue de formation en
                  matière fiscale, comptable et de droit en continuelle
                  évolution (2 à 6 productions par semaine en français et
                  néerlandais).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwo;
