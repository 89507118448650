import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";

import Loader from "../../common/Loader";
import Button from "../../common/Button";
import _ from "../../../i18n";
import { importClients } from "../../../api";
import styles from "./ClientModal.module.scss";
import listStyles from "./Client.module.scss";
import Tooltip from "./Tooltip";

const ExpressClientModal = ({
  modalOpen,
  setModalOpen,
  responseData,
  afterSave,
}) => {
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const [rowsData, setRowsData] = useState("");
  const [isImportStep, setIsImportStep] = useState(false);
  const [headData, setHeadData] = useState(null);
  const [bodyData, setBodyData] = useState([]);
  const [originalBodyData, setOriginalBodyData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [headOptions, setHeadOptions] = useState([]);
  const [headValues, setHeadValues] = useState([]);
  const [isHeaderError, setIsHeaderError] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [showInput, setShowInput] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [validatedId, setValidatedId] = useState([]);

  useEffect(() => {
    if (responseData) {
      setBodyData(responseData.body);
      // handleResponseData(responseData);
      // setIsImportStep(true);
    }
  }, [responseData]);

  const handleSave = () => {
    if (rowsData) {
      setIsSaving(true);
      importClients(auth.token, {
        data: rowsData,
      })
        .then((resp) => {
          handleResponseData(resp.data.data);
          setIsImportStep(true);
          setIsSaving(false);
        })
        .catch((e) => {
          setIsSaving(false);
        });
    }
  };

  const validateMultiSave = () => {
    let error = false;
    bodyData.forEach((item) => {
      item.forEach((cell) => {
        if (cell.error) {
          error = true;
        }
      });
    });
    return error;
  };

  const handleResponseData = (data) => {
    const values = [];
    const head = data.head.map((item, index) => {
      values[index] = { value: item, label: item };
      return { value: item, label: item };
    });

    const tab = [];
    data.body.forEach((row, index) => {
      tab[index] = [];
      row.forEach((cell, idx) => {
        if (typeof cell !== "object") {
          tab[index][idx] = {
            error: false,
            value: cell,
            showInput: false,
          };
        } else {
          tab[index][idx] = {
            error: true,
            value: cell.value,
            errorLabel: cell.error,
            showInput: false,
          };
        }
      });
    });
    setHeadValues(values);
    setHeadOptions(head);
    setHeadData(data.head);
    setBodyData(tab);
  };

  const handleMultiSave = () => {
    let data = {
      head: ["id", "owner", ...responseData?.head],
      body: [],
    };

    data.body = bodyData.map((row) => {
      let tab = [];
      tab = data.head.map((key) => (row[key] ? row[key] : ""));
      return tab;
    });

    // const error = validateMultiSave();
    // if (error || isHeaderError) {
    //   return null;
    // }

    const d = new Date();
    let time = d.getTime();
    var file = new File([JSON.stringify(data)], `data-${time}.txt`, {
      type: "text/plain",
    });

    // setIsSaving(true);
    // saveImportClients(auth.token, file)
    //   .then((resp) => {
    //     handleCancel();
    //     setModalOpen(false);
    //     afterSave();
    //     setIsSaving(false);
    //   })
    //   .catch((error) => {
    //     if (error.response) {
    //       handleResponseData(error.response.data.data);
    //     }
    //     setIsSaving(false);
    //   });
  };

  const handleCancel = () => {
    setModalOpen(false);
    setShowTooltip(false);
    setBodyData([]);
  };

  const handleChangeHeader = (e, index) => {
    const tab = {};
    headValues.forEach((item) => {
      tab[item.value] = 0;
    });
    setHeadValues((existingItems) => {
      return existingItems.map((exItem, j) => {
        if (j === index) {
          tab[e.value]++;
          return e;
        } else {
          tab[exItem.value]++;
          return exItem;
        }
      });
    });
    let error = false;
    const keys = Object.keys(tab);
    keys.forEach((key, value) => {
      if (tab[key] > 1) {
        error = true;
      }
    });
    setIsHeaderError(error);
  };

  const handleRemoveRow = (index) => {
    let tab = bodyData.filter((item, idx) => idx !== index);
    setCurrentIndex(null);
    setShowTooltip(false);
    setBodyData(tab);
  };

  const validateUen = (btn, index) => {
    let tab = [...bodyData];
    let item = tab[index];
    item = item.map((cell, key) => {
      if (cell.error && cell.errorLabel === "folder.uen.not.unique") {
        cell.error = false;
      }
      return cell;
    });
    tab[index] = item;
    setBodyData(tab);
    // btn.remove();
  };

  const toggleInputCell = (head, i) => {
    let show = { ...showInput };

    if (!show[head]) show[head] = {};
    if (show[head][`i-${i}`]) {
      show[head][`i-${i}`] = !show[head][`i-${i}`];
    } else {
      show[head][`i-${i}`] = true;
    }
    setShowInput(show);
  };

  const handleInputCell = (value, head, index) => {
    let tab = [...bodyData];
    tab[index][head] = value;
    setBodyData(tab);
  };

  const resetAll = () => {
    setModalOpen(false);
    setShowTooltip(false);
    setBodyData([]);
    setValidatedId([]);
    setCurrentIndex(null);
    setShowTooltip(false);
  };

  const isDataValid = () => {
    let valid = true;
    bodyData.forEach((item) => {
      if (item.id && !validatedId.includes(item.id)) {
        valid = false;
      }
    });
    return valid;
  };

  return (
    <Modal
      width="70vw"
      height="50vh"
      closable={false}
      visible={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>{_("import_clients")}</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          resetAll();
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>
      <div className={styles.modal_body}>
        {isHeaderError && (
          <div className={styles.headerError}>Header must be unique</div>
        )}
        <div class="table-scroll">
          <table className={`${listStyles.table}`}>
            <thead>
              <tr>
                <th></th>
                {responseData?.head.map((item, index) => (
                  <th key={index} width="100" style={{ width: "100px" }}>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {bodyData &&
                bodyData.map((row, i) => (
                  <tr
                    key={`row-${i}`}
                    className={
                      bodyData[i]["id"] &&
                      !validatedId.includes(bodyData[i]["id"])
                        ? styles.tdError
                        : ""
                    }
                  >
                    <td
                      className={styles.remove}
                      onClick={() => handleRemoveRow(i)}
                      title={_("remove")}
                    >
                      <i className="icon-ttp-close"></i>
                    </td>
                    {responseData?.head.map((head, idx) => (
                      <td key={`td${idx}`} style={{ width: "100px" }}>
                        {showInput[head] &&
                        showInput[head][`i-${i}`] &&
                        head !== "uen" ? (
                          <input
                            style={{ width: "100px" }}
                            type="text"
                            className="ttp-input"
                            autocomplete="off"
                            value={row[head]}
                            onBlur={() => toggleInputCell(head, i)}
                            onChange={(e) =>
                              handleInputCell(e.target.value, head, i)
                            }
                          />
                        ) : (
                          <span
                            className={styles.toogle}
                            onClick={(e) => toggleInputCell(head, i)}
                          >
                            {row[head]}
                            {head === "uen" &&
                              bodyData[i]["id"] &&
                              !validatedId.includes(bodyData[i]["id"]) && (
                                <i
                                  className={`icon-ttp-alert-circle ${styles.icon}`}
                                  onClick={() => {
                                    setShowTooltip(true);
                                    setCurrentIndex(i);
                                    // handleRemoveRow(i);
                                  }}
                                ></i>
                              )}
                          </span>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={resetAll}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleMultiSave} disabled={!isDataValid()}>
            {_("save")}
          </Button>
        )}
      </div>

      <Tooltip
        show={showTooltip}
        handleClickOk={() => {
          setValidatedId([...validatedId, bodyData[currentIndex].id]);
          setCurrentIndex(null);
          setShowTooltip(false);
        }}
        currentIndex={currentIndex}
        bodyData={bodyData}
        handleCancel={() => {
          handleRemoveRow(currentIndex);
        }}
      />
    </Modal>
  );
};

export default ExpressClientModal;
