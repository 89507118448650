import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getTops } from "../../../api";
import { TTP_BLOG_URL } from "../../../config"
import styles from "./TopAricles.module.scss";

const TopAricles = () => {

    const token = useSelector((state) => state.auth.token);
    const lng = useSelector((state) => state.params.language);
    const [ tops, setTops ] = useState([]);

    useEffect(() => {
        if (token !== '') {
            getTops({ 
                token: token,
                language: lng
            }).then(resp => {
                setTops(resp.data.data);
            });
        }
    }, [token]);

    const buildUrl = article => {
        return TTP_BLOG_URL + `/${article.language}/article/${article.url}/${article.id}`;
    }
    
    return (<div className={styles.tops}> 
        <div className={styles.title}>
            <span>top</span>publications
        </div>
        { tops.length > 0 && <div className={styles.media} style={{backgroundImage: `url(${tops[0].main_media.fullMediaUrl})`}}></div>}
            
        { tops.length >0 && tops.map((article, index) => (<div className={styles.article} key={article.id}>
                <span className={styles.num}>{index+1}</span>
                <div className={styles.meta}>
                    <span className={styles['meta-category']}>{article.category['name'+ lng.slice(0,1).toUpperCase() + lng.slice(1) ]}</span>
                    <span className={styles['meta-community']} 
                        style={{borderColor: article.category.colorCode}}>FFF
                    </span>
                </div>
                <h3><a href={buildUrl(article)} target="_blank" rel="noreferrer">{article.title}</a></h3>
            </div>))}
    </div>);
}

export default TopAricles;