import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { HeaderUA } from "tamtam-components";

import _ from "../../i18n";
import {
  setAuthUser,
  setAuthToken,
  setExpiresIn,
  setTokenCreatedAt,
  fetchAuthUser,
  setLanguage,
  fetchFiduciary,
  setFolderFetched,
} from "../../store";
import {
  getAuthInfosFromCookie,
  authHasExpired,
  getCookie,
  setCookie,
  logout,
} from "../../utils";
import { APP_ENV, TTP_ONBOARDING_URL } from "../../config";

import Button from "../common/Button";
import styles from "./AccountSuspended.module.scss";

const rightIcons = {
  home: {
    activated: false,
    url: TTP_ONBOARDING_URL,
  },
  profile: {
    activated: false,
    url: "/profile",
  },
  ebox: {
    activated: false,
  },
  search: {
    activated: false,
  },
  notifs: {
    activated: false,
  },
  backoffice: {
    activated: false,
  },
  faq: {
    activated: false,
  },
  apps: {
    activated: false,
  },
};

const AccountSuspended = () => {
  const auth = useSelector((state) => state.auth);
  const { loggedAsPost } = auth;
  const folder = useSelector((state) => state.folder);
  const language = useSelector((state) => state.params.language);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAuthUser = (user) => {
    dispatch(setAuthToken(user.token));
    dispatch(fetchAuthUser(user));
    dispatch(setExpiresIn(user.expiresIn));
    dispatch(setTokenCreatedAt(user.createdAt));
    dispatch(fetchFiduciary());
  };

  useEffect(() => {
    let authInfos = getAuthInfosFromCookie();

    if (!authInfos || authHasExpired(authInfos)) {
      dispatch(setAuthUser(null));

      let dtExpire = new Date();
      dtExpire.setTime(dtExpire.getTime() - 3600000 * 1000);

      setCookie(
        "ttp_auth_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
      setCookie(
        "ttp_community_" + APP_ENV,
        "",
        dtExpire,
        "/",
        "unitedassociates.be"
      );
    }

    const cookieLang = getCookie(`ttp_lang_${APP_ENV}`);
    if (cookieLang) {
      dispatch(setLanguage(cookieLang));
    } else {
      const lng = localStorage.getItem("lng") || "fr";
      dispatch(setLanguage(lng));
    }

    if (authInfos) {
      handleAuthUser(authInfos);
    } else {
      dispatch(setFolderFetched(true));
    }

    $("#app-loader")
      .fadeOut()
      .promise()
      .done(() => $("#app-loader").remove());
  }, []);

  const changeLng = (language) => {
    document.location.href = "/" + language;
  };

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  return (
    <>
      {auth.user && (
        <HeaderUA
          rightIcons={rightIcons}
          app={{
            appName: "United Associates",
            appLogoUrl:
              "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png",
            appUrl: "/",
          }}
          auth={{
            navCommunity: auth.navCommunity,
            user: auth.user,
            token: auth.token,
          }}
          settings={[]}
          lng={language}
          languages={[]}
          env={APP_ENV}
          onLanguageChange={(langue) => changeLng(langue)}
          onLogoutClick={(e) => handleLogout(e)}
          RouterLink={Link}
        />
      )}
      <div className={styles.container}>
        <div className={styles.icon}>
          <i className="icon-ttp-close-outline"></i>
        </div>
        <h3 className={styles.title}>Désolé votre compte est fermé</h3>
        <p className={styles.subtitle}>
          {folder.fetched &&
          folder.fiduciary &&
          folder.fiduciary.subscription &&
          folder.fiduciary.subscription === "CANCELED"
            ? "Vous avez annulé votre abonnement."
            : "Suite au défaut de paiement, votre compte est suspendu."}
        </p>
        {["LEGAL_REPRESENTATIVE", "MANAGER"].includes(loggedAsPost) && (
          <div className={styles.action}>
            <Button
              onClick={() => navigate("/account-activation")}
              variant="primary"
            >
              Réactiver mon compte
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountSuspended;
