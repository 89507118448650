import React from "react";
import classNames from "classnames";

import styles from "./Collaborator.module.scss";
import { IconEye } from "../../common/icons/IconEye";
import { TTP_API_URL } from "../../../config";

const Collaborator = ({ userIndex, user, setCollaborators, collaborators }) => {
  const toggleEnableAvatar = (e) => {
    e.stopPropagation();
    const list = [...collaborators];
    let index = -1;
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === user.id) {
        index = i;
        break;
      }
    }
    if (index > -1) {
      list[index].enableAvatar = !user.enableAvatar;
    }

    setCollaborators(list);
  };
  const renderAvatar = () => {
    return (
      <div
        className={classNames(styles.sidebarAvatar)}
        style={{
          backgroundImage: `url(${
            user.avatarUrl || user.avatar
              ? user.avatarUrl
                ? user.avatarUrl
                : require(`${TTP_API_URL}/${user.avatar}`)
              : "/img/default_avatar.jpg"
          }`,
        }}
      >
        <div
          className={styles.avatarOverlay}
          style={
            user.enableAvatar === true
              ? { display: "none" }
              : { display: "block" }
          }
        ></div>

        <div
          className={classNames(
            styles.iconEye,
            !user.enableAvatar ? styles.disabledAvatar : ""
          )}
          onClick={(e) => toggleEnableAvatar(e)}
          title={user.enableAvatar ? "Disable" : "Enable"}
        >
          <IconEye size="16" />
        </div>
      </div>
    );
  };

  return (
    <div className={classNames(styles.cardCont, "cell small-12 medium-4")}>
      <div className={styles.sidebarAuthor}>
        {renderAvatar()}

        <div
          className={styles.sidebarUsername}
          style={!user.enableAvatar ? { opacity: 0.6 } : {}}
        >
          {user.firstName + " " + user.lastName}
        </div>

        {/* {user.function && (
          <div
            className={styles.sidebarPost}
            style={!user.enableAvatar ? { opacity: 0.6 } : {}}
          >
            {user.function}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Collaborator;
