import React from 'react';

import EventCard from "../EventCard"
import styles from "./OFFFCourse.module.scss";
import { TTP_EVENT_URL } from '../../../config';

import _ from "../../../i18n";

const OFFFCourse = ({events, lng}) => {

    return  <div className="grid-x grid-margin-x news-block">
        <div className="cell small-3">
            <div className={styles.course}>
                <div className={styles.header}>
                    <span>OFFFCOURSE</span>
                    <img src="/img/event.png" width="43" alt="event-logo"/>
                </div>
                <h3>{_("best-training-live-replay")}</h3>
                <p>oFFFcourse rassemble les meilleurs contenus. Des centaines de programmes en live et en replayoFFFcourse rassemble les meilleurs contenus. Des centaines de programmes en live et en replayoFFFcourse rassemble les meilleurs contenus. Des centaines de programmes en live et en replayoFFFcourse rassemble les meilleurs contenus. Des centaines de programmes en live et en replay.</p>
                <a className={styles.all} target="_blank" href={`${TTP_EVENT_URL}/community/forum-for-the-future/9`} rel="noreferrer">{_("all_training")}</a>
            </div>
        </div>
        <div className="cell small-9" style={{display: "flex",flexDirection: "column"}}>
            <div className={styles.titleNext}>Prochainement en live</div>
            <div className="grid-x grid-margin-x" style={{height:"100%"}}>
                {events.map(event => <div className="cell small-4" key={event.id}>
                    <EventCard event={event} language={lng}/>
                </div>)}
            </div>
        </div>

    </div>
}

export default OFFFCourse;