import React from "react";

import styles from "./Newsletter.module.scss";
import { useSelector } from "react-redux";

const Newsletter = ({ data }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <div className={styles.newsletter_section}>
      <div className={styles.title}>
        {data && data[titleAttr] ? data[titleAttr] : "Newsletter title"}{" "}
      </div>
      <div className={styles.description}>
        {data && data[descriptionAttr]
          ? data[descriptionAttr]
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."}
      </div>
      <div className={styles.form}>
        <input
          type="text"
          className={styles.input}
          placeholder="Enter your email"
        />
        <div className={styles.btn}>{"subscribe"}</div>
      </div>
      <div className={styles.notice}>
        {
          "By clicking Sign Up you're confirming that you agree with our Terms and Conditions."
        }
      </div>
    </div>
  );
};

export default Newsletter;
