import React from "react";
import { useSelector } from "react-redux";

import styles from "./Testimonials.module.scss";
import TestimonialsSlider from "./TestimonialsSlider";
import TestimonialCard from "./TestimonialCard";
import TestimonialsGrid from "./TestimonialsGrid";

const Testimonials = ({ testimonials, data, gridDisplay = false }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  // if (testimonials.length === 0) {
  //   return null;
  // }

  return (
    <div className={styles.testimonials_section}>
      <div className={styles.top}>
        <div className={styles.section_title}>{"Témoignages"}</div>
        <div className={styles.title}>
          {data[titleAttr] ? data[titleAttr] : "Lorem ipsum dolor sit amet"}
        </div>
        <div className={styles.description}>
          {data[descriptionAttr]
            ? data[descriptionAttr]
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua"}
        </div>
      </div>
      {testimonials.length < 1 ? (
        <div className={styles.testimonials_list}>
          {[1, 2, 3].map((testimonial) => (
            <div className={styles.cardCont} key={testimonial.id}>
              <TestimonialCard lng={lng} />
            </div>
          ))}
        </div>
      ) : testimonials.length < 4 ? (
        <div className={styles.testimonials_list}>
          {testimonials.map((testimonial) => (
            <div className={styles.cardCont} key={testimonial.id}>
              <TestimonialCard testimonial={testimonial} lng={lng} />
            </div>
          ))}
        </div>
      ) : gridDisplay ? (
        <div className={styles.testimonials_grid}>
          <TestimonialsGrid testimonials={testimonials} lng={lng} />
        </div>
      ) : (
        <TestimonialsSlider testimonials={testimonials} lng={lng} />
      )}
    </div>
  );
};

export default Testimonials;
