import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQuery, useQueryClient } from "react-query";
import debounce from "lodash.debounce";

import { getFiduciaries } from "../../api";
import { ALLOWED_APPS_OPTIONS, DEFAULT_APPS, DEFAULT_TABS } from "../../config";
import _ from "../../i18n";

import Pagination from "../common/Pagination";
import Loader from "../common/Loader";

import FiduciaryModal from "./FiduciaryModal";
import styles from "./ManageApps.module.scss";

const FiduciariesTab = (props) => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [searchName, setSearchName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState({
    property: "createdAt",
    dir: "desc",
  });
  const [showFiduciaryModal, setShowFiduciaryModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const LIMIT = 10;

  let { isLoading, data } = useQuery(
    ["listFiduciaries", token, searchName, sort, currentPage],
    async () => {
      if (token) {
        try {
          const response = await getFiduciaries({
            token,
            status: "VALIDATED",
            page: currentPage,
            limit: LIMIT,
            sort,
            name: searchName,
          });

          return response.data;
        } catch (e) {
          return [];
        }
      }
    }
  );

  const handleSearchChange = (e) => {
    debouncedLoadResults(e.target.value);
  };

  const debouncedLoadResults = debounce((search) => {
    setSearchName(search);
  }, 1000);

  const renderApps = (uaSettings) => {
    let tabAllowedApps = [];
    if (uaSettings.allowedApps) {
      uaSettings.allowedApps.forEach((i) => {
        const row = ALLOWED_APPS_OPTIONS.find((j) => j.value === i);
        if (row) {
          tabAllowedApps.push(row);
        }
      });
    } else {
      tabAllowedApps = ALLOWED_APPS_OPTIONS.filter(
        (i) => i && DEFAULT_APPS.includes(i.value)
      );
    }
    return (
      <div className={styles.apps}>
        {tabAllowedApps.map((app) => {
          return (
            <div className={styles.apps_item}>
              <div className={styles.apps_item_icon}>
                <img src={`img/apps/${app.icon}`} alt="" />
                <span>{app.name}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTabs = (uaSettings) => {
    let tabAllowedTabs = [];
    if (uaSettings.allowedTabs) {
      tabAllowedTabs = [...uaSettings.allowedTabs];
    } else {
      tabAllowedTabs = [...DEFAULT_TABS];
    }
    return (
      <div className={styles.apps}>
        {tabAllowedTabs.map((tab) => {
          return (
            <div className={styles.tabs_item}>
              <span>{tab}</span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div className={styles.filterBar}>
        <span></span>
        <div className={styles.inputSearch}>
          <span className={styles.inputSearch_icon}>
            <i className="icon-ttp-magnifier"></i>
          </span>
          <input
            type="text"
            placeholder={_("search")}
            onChange={(e) => handleSearchChange(e)}
          />
        </div>
      </div>

      <table className={`${styles.table} unstriped`}>
        <thead>
          <tr>
            <th width="10%" className={styles.nowrap}>
              <span>{_("uen")}</span>
              <span className={styles.sortBox}>
                <i
                  className={`icon-ttp-arrow-down ${
                    sort.property === "uen" &&
                    sort.dir === "asc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "uen", dir: "asc" })}
                ></i>
                <i
                  className={`icon-ttp-arrow-up ${
                    sort.property === "uen" &&
                    sort.dir === "desc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "uen", dir: "desc" })}
                ></i>
              </span>
            </th>
            <th width="30%">
              <span>Nom</span>
              <span className={styles.sortBox}>
                <i
                  className={`icon-ttp-arrow-down ${
                    sort.property === "name" &&
                    sort.dir === "asc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "name", dir: "asc" })}
                ></i>
                <i
                  className={`icon-ttp-arrow-up ${
                    sort.property === "name" &&
                    sort.dir === "desc" &&
                    styles.iconActive
                  }`}
                  onClick={() => setSort({ property: "name", dir: "desc" })}
                ></i>
              </span>
            </th>
            <th width="15%">Config</th>
            <th width="20%">Apps</th>
            <th width="20%">Tab space</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={7} className={styles.centerCell}>
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#B2BCC6"}
                />
              </td>
            </tr>
          ) : data?.data && data.data.length > 0 ? (
            data.data.map((item) => {
              let tmpLR = null;
              item.allLegalRepresentative.forEach((lr) => {
                if (lr.roleType === "TMP_LR") {
                  tmpLR = lr;
                }
              });
              return (
                <tr key={item.id}>
                  <td>{item.uen}</td>
                  <td>{item.name}</td>
                  <td>{item?.uaSettings?.title}</td>
                  <td>{renderApps(item.uaSettings)}</td>
                  <td>{renderTabs(item.uaSettings)}</td>
                  <td>
                    <span
                      className={`${styles.action} icon-ttp-pencil`}
                      onClick={(e) => {
                        setCurrentItem(item);
                        setShowFiduciaryModal(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={6} className={styles.centerCell}>
                Liste vide (aucune fiduciaire ne répond aux filtres)
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {data?.nbResult && (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data.nbResult}
          pageSize={LIMIT}
          onPageChange={(page) => setCurrentPage(page)}
        />
      )}
      <FiduciaryModal
        modalOpen={showFiduciaryModal}
        setModalOpen={setShowFiduciaryModal}
        fiduciary={currentItem}
        refreshList={() => queryClient.invalidateQueries("listFiduciaries")}
      />
    </>
  );
};

export default FiduciariesTab;
