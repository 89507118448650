import React from "react";
import { AppCard } from "../../";

const FutureApp = () => {
  const apps = [
    {
      name: "Webtools",
      icon: "webtools",
      desc: "Accéder à une bibliothèque de webtools les plus populaires.  Utilisez ces contenus pour votre organisation et créez un tool center pour vos clients.",
      dispo: "Disponible: Fin 2023",
    },
    {
      name: "FAQ",
      icon: "faq",
      desc: "Accéder à une bibliothèque de questions-réponses les plus populaires.  Utilisez ces contenus pour votre organisation et créez un centre d’assistance pour vos clients.",
      dispo: "Disponible: Fin 2023",
    },
    {
      name: "Paper",
      icon: "paper",
      desc: "La recherche académique n'a jamais été aussi facile : trouvez tous les articles, livres et magazines dont vous avez besoin.",
      dispo: "Disponible: Fin 2023",
    },
    {
      name: "Survey",
      icon: "survey",
      desc: "Disposez des résultats des dernières enquêtes sectorielles et d'outils partagés d'évaluation.  Créez vos propres enquêtes en quelques clics.",
      dispo: "Disponible: Fin 2023",
    },
    {
      name: "SMS",
      icon: "sms",
      desc: "Envoyez des SMS à vos collaborateurs ou vos clients sans aucune difficulté.",
      dispo: "Disponible: Fin 2023",
    },
  ];
  return (
    <div className="grid-x grid-margin-x grid-margin-y">
      {apps.map((app, index) => (
        <div className="cell small-12 medium-6" key={`futre-${index}`}>
          <AppCard app={app} type="future" />
        </div>
      ))}
    </div>
  );
};

export default FutureApp;
