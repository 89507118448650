import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import { ModalConfirm } from "tamtam-components";

import Button from "../common/Button";
import Loader from "../common/Loader";
import styles from "./AiPrompt.module.scss";
import _ from "../../i18n";
import { getAiPrompts, saveAiPrompt, removeAiPrompt } from "../../api";

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#fff",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #CED4DB",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#DFE2E6",
    },
    padding: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
    color: state.isSelected ? "#FFFFFF" : "#6D7F92",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
  }),
};

const TYPE_OPTIONS = [
  {
    value: "TITLE",
    label: "Améliorer le titre",
    prompt:
      "You are a creative title generator. Your task is to take an input title and generate a more attractive, engaging, and click-worthy title while maintaining the core message. Provide only the new title text without any additional formatting or punctuation. Do not enclose the title in quotation marks. Please the new title shoulb be in the same language as the title given in the input",
  },
  {
    value: "TRANSLATE",
    label: "Traduire un article",
    prompt:
      "You will be provided with an HTML content that contains links, images, and the special pattern [~~~~]. Your task is to translate only the textual content into %s while preserving the HTML structure and the [~~~~] pattern exactly as they are.",
  },
  {
    value: "PRESS_RELEASE",
    label: "Générer un article depuis un communiqué de presse",
    prompt:
      'You are an AI assistant that generates articles based on press releases. Please generate a detailed article with a title and content based on the provided press release. The article should be informative, well-structured, and maintain the key points from the press release. Use appropriate HTML tags to structure the content, including <h1> for the title, <h2> for subtitles, <p> for paragraphs, <ul> or <ol> for lists if needed, and <blockquote> for any notable quotes. Return the result in JSON format with keys "title" and "content". The "content" should contain the full HTML-structured article body.generate the content in the same langage as the input provided',
  },
  {
    value: "LINKEDIN_POST",
    label: "Post LinkedIn",
    prompt:
      "Pouvez-vous me proposer un post linkedin avec emoji... à partir de cet article",
  },
];

const AiPrompts = () => {
  const queryClient = useQueryClient();
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const { token, user } = auth;
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [promptType, setPromptType] = useState(null);
  const [promptText, setPromptText] = useState("");
  const [promptOrganization, setPromptOrganization] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [errors, setErrors] = useState({
    organization: "",
    type: "",
    promptText: "",
  });

  useEffect(() => {
    if (user?.uaRoles) {
      const tab = user.uaRoles.map((i) => {
        return {
          value: i.organization.id,
          label: i.organization.abbreviation
            ? i.organization.abbreviation
            : i.organization.name,
        };
      });
      setOrganizationOptions(tab);
    }
  }, [user]);

  const fetchAiPrompts = useQuery(["fetchAiPrompts", token], async () => {
    if (token) {
      let items = {};
      TYPE_OPTIONS.forEach((i) => {
        items[i.value] = [];
      });
      try {
        const res = await getAiPrompts({
          token,
        });

        res.data.data.forEach((i) => {
          items[i.type].push(i);
        });

        return items;
      } catch (error) {
        return items;
      }
    }
  });

  const handleCancel = () => {
    setPromptOrganization(null);
    setPromptType(null);
    setPromptText("");
    setErrors({ organization: "", type: "", promptText: "" });
    setAddModalOpen(false);
    setCurrentItem(null);
  };

  const validate = () => {
    let tabErrors = { ...errors };
    tabErrors.organization = !promptOrganization ? _("required_field") : "";
    tabErrors.type = !promptType ? _("required_field") : "";
    tabErrors.promptText = promptText.length === 0 ? _("required_field") : "";

    setErrors(tabErrors);

    return tabErrors.organization || tabErrors.type || tabErrors.promptText
      ? true
      : false;
  };

  const handleSave = async () => {
    let error = validate();
    if (error) {
      return null;
    }

    setIsSaving(true);

    const data = {
      token: auth.token,
      organization: promptOrganization.value,
      type: promptType.value,
      promptText,
    };

    if (currentItem) {
      data.id = currentItem.id;
    }

    saveAiPrompt(data)
      .then((resp) => {
        queryClient.invalidateQueries("fetchAiPrompts");
        setTimeout(() => {
          toast.success(_("successfully_added"));
          handleCancel();
        }, 1000);
      })
      .catch((e) => {})
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handlePromptDelete = async () => {
    try {
      setDeleting(true);
      await removeAiPrompt(token, currentItem.id);
      queryClient.invalidateQueries("fetchAiPrompts");
      setDeleting(false);
      setModalConfirmOpen(false);
      setCurrentItem(null);
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.titles}>
            <div className={styles.title}>Liste des prompts IA</div>
            <div className={styles.subtitle}>
              Ajouter des prompts personnalisés par communauté
            </div>
          </div>

          <div className={styles.top_actions}>
            <Button
              onClick={() => setAddModalOpen(true)}
              variant="primary"
              size="sm"
            >
              Ajouter
            </Button>
          </div>
        </div>
        {fetchAiPrompts.isLoading ? (
          [...Array(4).keys()].map((i) => (
            <div key={`fetch-${i}`} className={styles.fetchItem}>
              <Skeleton height={44} />
            </div>
          ))
        ) : (
          <div>
            {TYPE_OPTIONS.map((type, idx) => (
              <div key={idx} className={styles.item}>
                <h3 className={styles.item_title}>{type.label}</h3>

                <p className={styles.item_subtitle}>Prompt par défaut:</p>
                <p className={styles.item_box}>{type.prompt}</p>

                {fetchAiPrompts.data[type.value].length > 0 && (
                  <>
                    <p className={styles.item_subtitle}>Prompt personnalisé:</p>
                    <table className={styles.table}>
                      <tr>
                        <th width="30%">Communauté</th>
                        <th width="70%">Prompt</th>
                        <th></th>
                      </tr>
                      {fetchAiPrompts.data[type.value].map((prompt) => {
                        return (
                          <tr key={`prom${prompt.id}`}>
                            <td>
                              {prompt.organization.abbreviation
                                ? prompt.organization.abbreviation
                                : prompt.organization.name}
                            </td>
                            <td>{prompt.content}</td>
                            <td className={styles.btns}>
                              <button
                                className={styles.btn_action}
                                onClick={() => {
                                  const org = organizationOptions.filter(
                                    (i) => i.value == prompt.organization.id
                                  );
                                  const type = TYPE_OPTIONS.filter(
                                    (i) => i.value == prompt.type
                                  );
                                  setCurrentItem(prompt);
                                  setPromptText(prompt.content);
                                  setPromptOrganization(org[0]);
                                  setPromptType(type[0]);
                                  setAddModalOpen(true);
                                }}
                              >
                                <i className="icon-ttp-edit" />
                              </button>

                              <button
                                className={styles.btn_action}
                                onClick={() => {
                                  setCurrentItem(prompt);
                                  setModalConfirmOpen(true);
                                }}
                              >
                                <i className="icon-ttp-trash" />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <Modal
        isOpen={addModalOpen}
        // onRequestClose={() => resetData()}
        className={{
          base: styles.modalContent,
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modal}>
          <div className={styles.header}>
            {currentItem ? "Modifier" : "Ajout d'un prompt"}
          </div>
          <div
            className={styles.close}
            onClick={() => {
              setAddModalOpen(false);
              setCurrentItem(null);
            }}
          >
            <i className="icon-ttp-close"></i>
          </div>

          <div className={styles.body}>
            <div className={`${styles.formControl} `}>
              <label className="ttp-label">Communauté</label>
              <Select
                styles={selectStyles}
                options={organizationOptions}
                value={promptOrganization}
                onChange={(e) => setPromptOrganization(e)}
              />
              <span className="ttp-error">{errors.organization}</span>
            </div>

            <div className={`${styles.formControl} `}>
              <label className="ttp-label">Type</label>
              <Select
                styles={selectStyles}
                options={TYPE_OPTIONS}
                value={promptType}
                onChange={(e) => setPromptType(e)}
              />
              <span className="ttp-error">{errors.type}</span>
            </div>

            <label className="ttp-label">
              Votre prompt
              <span className="star">*</span>
            </label>

            <textarea
              rows={6}
              value={promptText}
              onChange={(e) => setPromptText(e.target.value)}
              className="ttp-input"
            ></textarea>
            <span className="ttp-error">{errors.promptText}</span>
          </div>

          <div className={styles.actions}>
            <Button variant="default" onClick={handleCancel}>
              {_("cancel")}
            </Button>

            {isSaving ? (
              <Button
                variant="primary"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button onClick={handleSave}>{_("save")}</Button>
            )}
          </div>
        </div>
      </Modal>

      <ModalConfirm
        type="delete"
        isOpen={modalConfirmOpen}
        onCancel={() => {
          setModalConfirmOpen(false);
          setCurrentItem(null);
        }}
        onConfirm={() => handlePromptDelete()}
        inProcess={deleting}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
};

export default AiPrompts;
