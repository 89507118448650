import React from "react";
import styles from "./Values.module.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";

const Values = ({ data, valuesList, valuesVideo }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <>
      <div className={styles.values}>
        <div className={styles.section_title}>
          {data && data[titleAttr] ? data[titleAttr] : "Nos valeurs"}
        </div>
        <div className={styles.section_desc}>
          {data && data[descriptionAttr]
            ? data[descriptionAttr]
            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
        </div>
        <div className={styles.flex}>
          {valuesList.length > 0 ? (
            <div className={classNames(styles.right, "grid-x")}>
              {valuesList.map((val, i) => (
                <div
                  className={classNames(
                    styles.cardCont,
                    "cell small-12 medium-6"
                  )}
                  key={i}
                >
                  <div className={styles.number}>{i + 1}</div>
                  <div className={styles.title}>{val.title}</div>
                  <div className={styles.desc}>{val.description}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className={classNames(styles.right, "grid-x")}>
              {["one", "two", "three", "four"].map((val, i) => (
                <div
                  className={classNames(
                    styles.cardCont,
                    "cell small-12 medium-6"
                  )}
                  key={i}
                >
                  <div className={styles.number}>{i + 1}</div>
                  <div className={styles.title}>{`Value ${val}`}</div>
                  <div className={styles.desc}>
                    {"Lorem ipsum dolor sit amet, consectetur adipiscing elit."}
                  </div>
                </div>
              ))}
            </div>
          )}
          {valuesVideo && valuesVideo.length > 0 ? (
            <div className={styles.left}>
              <video width="400" src={valuesVideo} controls></video>
            </div>
          ) : (
            <div
              className={styles.noVideo}
              style={{ backgroundImage: `url(img/no_video.png)` }}
            ></div>
          )}
        </div>
      </div>
    </>
  );
};

export default Values;
