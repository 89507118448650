import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { useQuery } from "react-query";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Avatar } from "tamtam-components";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Tabs from "../../common/Tabs";
import Button from "../../common/Button";
import Loader from "../../common/Loader";

import { SELECT_STYLES, LANGUAGES } from "../../../config";
import { getUsersSettings, saveUsersSettings } from "../../../api";
import _ from "../../../i18n";

import BlogConfig from "./BlogConfig";
import BlogAuthorsModal from "./BlogAuthorsModal";
import styles from "./InstalledApp.module.scss";

const BlogModal = ({ showModal, setShowModal }) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [currentTab, setCurrentTab] = useState(0);

  const handleClose = () => {
    setCurrentTab(0);
    setShowModal(false);
  };

  return (
    <Modal
      width="80vw"
      height="50vh"
      closable={false}
      visible={showModal}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        handleClose();
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>
        <i className="icon-ttp-settings"></i> Blog
      </div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          handleClose();
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>
      <div className={styles.modal_content}>
        <Tabs theme="line-theme" onChangeTab={(e) => setCurrentTab(e)}>
          <div label={"Configuration"}>
            <BlogConfig showModal={currentTab === 0} closeModal={handleClose} />
          </div>
          <div label={"Droits d’utilisateurs"}>
            <BlogAuthorsModal
              showModal={currentTab === 1}
              closeModal={handleClose}
            />
          </div>
        </Tabs>
      </div>
    </Modal>
  );
};

export default BlogModal;
