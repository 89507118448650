import React from "react";

const IconValidateCheck = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_59465_935)">
        <path
          d="M16.5 8.30648V8.99648C16.4991 10.6138 15.9754 12.1875 15.007 13.4828C14.0386 14.7782 12.6775 15.7258 11.1265 16.1844C9.57557 16.643 7.91794 16.5879 6.40085 16.0274C4.88376 15.4669 3.58849 14.4311 2.70822 13.0743C1.82795 11.7175 1.40984 10.1125 1.51626 8.49872C1.62267 6.8849 2.24791 5.34872 3.29871 4.11928C4.34951 2.88984 5.76959 2.03302 7.34714 1.67659C8.92469 1.32016 10.5752 1.48323 12.0525 2.14148"
          stroke="#06D9B1"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M16.5 3L9 10.5075L6.75 8.2575"
          stroke="#06D9B1"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_59465_935">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconValidateCheck;
