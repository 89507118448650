import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import classNames from "classnames";
import $ from "jquery";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Loader from "../common/Loader";
import AgreationNumber from "../common/AgreationNumber";
import _ from "../../i18n";

import { LANGUAGES, AGREATION_OPTIONS } from "../../config";
import { getClientCredential } from "../../api";
import { setAuthToken } from "../../store";

import LeftSide from "../Login/LeftSide";
import styles from "./CollaboratorLimit.module.scss";

const CollaboratorLimit = () => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const navigate = useNavigate();
  const [name, setName] = useState("");

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "name") {
        setName(decodeURIComponent(pair[1]));
      }
    }
  }, []);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <LeftSide />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.topButtons}>
            <span>Déjà membre?</span>
            <span
              onClick={() => navigate("/login")}
              className={styles.register}
            >
              S’identifier
            </span>
          </div>

          <div className={styles.content}>
            <h1 className={styles.title}>{_("signup")}</h1>

            <p className={styles.successMsg}>
              <br />

              <span>
                {_("email_link_size_reached").replace("%name%", name)}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollaboratorLimit;
