import React from "react";
import styles from "./Packs.module.scss";
import classNames from "classnames";
import { useSelector } from "react-redux";
import CheckIcon from "../icons/CheckIcon";
import IconClose from "../../../../../../../common/icons/IconClose";
// import PacksDetails from "./PacksDetails";

const Packs = ({
  publicPortal,
  packsList,
  featuresCategoriesList,
  noTitles = false,
}) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  const packsArray = [
    {
      title: "Basic plan",
      subtitle: "Lorem ipsum dolor sit amet",
      monthlyPrice: 19,
      yearlyPrice: 199,
      features: [
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
      ],
    },
    {
      title: "Business plan",
      subtitle: "Lorem ipsum dolor sit amet",
      monthlyPrice: 29,
      yearlyPrice: 299,
      features: [
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
      ],
    },
    {
      title: "Enterprise plan",
      subtitle: "Lorem ipsum dolor sit amet",
      monthlyPrice: 49,
      yearlyPrice: 499,
      features: [
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
        "Feature text goes here",
      ],
    },
  ];

  const allFeaturesList = (categories) => {
    return categories
      .map((category) => {
        if (category.features) {
          return category.features;
        }
        return [];
      })
      .flat(Infinity);
  };

  return (
    <>
      <div className={styles.packs_section}>
        {!noTitles && (
          <>
            <div className={styles.section_title}>{"packs"}</div>
            <div className={styles.title}>
              {"Choisissez le Pack qui vous convient"}
            </div>
            <div className={styles.description}>
              {"Lorem ipsum dolor sit amet, consectetur adipiscing elit. "}
            </div>
          </>
        )}
        <div
          className={classNames(styles.list, noTitles ? styles.noMargin : "")}
        >
          {packsList?.length > 0
            ? packsList.map((pack, i) => (
                <div className={classNames(styles.cardCont)} key={pack.id}>
                  <div className={styles.pack_card}>
                    <div className={styles.title}>{pack[titleAttr]}</div>
                    <div className={styles.subtitle}>
                      {pack[descriptionAttr]}
                    </div>
                    <div className={styles.monthly}>
                      <strong>{`$${pack.monthlyPrice}`}</strong>
                      {"/mo"}
                    </div>
                    <div
                      className={styles.yearly}
                    >{`or $${pack.yearlyPrice} yearly`}</div>
                    <div className={styles.btn}>{"Get started"}</div>
                    <ul className={styles.features}>
                      {pack.features
                        .filter((el) => el.available)
                        .map((feature) => (
                          <li key={feature.id}>
                            <CheckIcon />{" "}
                            {
                              allFeaturesList(featuresCategoriesList).filter(
                                (el) => el.id === feature.id
                              )[0][titleAttr]
                            }
                          </li>
                        ))}
                      {pack.features
                        .filter((el) => !el.available)
                        .map((feature) => (
                          <li key={feature.id}>
                            <IconClose />{" "}
                            {
                              allFeaturesList(featuresCategoriesList).filter(
                                (el) => el.id === feature.id
                              )[0][titleAttr]
                            }
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              ))
            : packsArray.map((pack, i) => (
                <div className={classNames(styles.cardCont)} key={pack.id}>
                  <div className={styles.pack_card}>
                    <div className={styles.title}>{pack.title}</div>
                    <div className={styles.subtitle}>{pack.subtitle}</div>
                    <div className={styles.monthly}>
                      <strong>{`$${pack.monthlyPrice}`}</strong>
                      {"/mo"}
                    </div>
                    <div
                      className={styles.yearly}
                    >{`or $${pack.yearlyPrice} yearly`}</div>
                    <div className={styles.btn}>{"Get started"}</div>
                    <ul className={styles.features}>
                      {pack.features.map((feature) => (
                        <li key={feature.id}>
                          <CheckIcon /> {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </>
  );
};

export default Packs;
