import React from "react";
import { useSelector } from "react-redux";

import styles from "./Faq.module.scss";
import FaqList from "./FaqList";

const Faq = ({ faqs, data }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  if (!faqs) return null;

  return (
    <div className={styles.faq_section} id="faq_section">
      <div className={styles.section_title}>{"Faq"}</div>
      <div className={styles.title}>
        {data[titleAttr] ? data[titleAttr] : "Faq title"}
      </div>
      <div className={styles.description}>
        {data[descriptionAttr]
          ? data[descriptionAttr]
          : "Lorem ipsum dolor sit amet, consectetur adipiscing elit"}
      </div>
      <FaqList faqs={faqs} lng={lng} />
    </div>
  );
};

export default Faq;
