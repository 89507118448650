import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import Select from "react-select";

import { saveRate } from "../../api";
import _ from "../../i18n";
import {
  RATE_TYPES,
  PAID_APPS,
  PAID_OPTIONS,
  SELECT_STYLES,
} from "../../config";

import Loader from "../common/Loader";
import Button from "../common/Button";

import styles from "./RatePopOver.module.scss";

const RATE_TYPE_OPTIONS = RATE_TYPES.map((rate) => {
  return { value: rate, label: _(rate) };
});

const STATUS_OPTIONS = [
  { value: "ENABLED", label: _("enabled") },
  { value: "DISABLED", label: _("disabled") },
];

const APPS_OPTIONS = PAID_APPS.map((app) => {
  return { value: app, label: _(app) };
});

const APP_PAID_OPTIONS = PAID_OPTIONS.map((option) => {
  return { value: option, label: _(option) };
});

const RatePopOver = ({ currentRate, closePopOver }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [isSaving, setIsSaving] = useState(false);
  const [rateType, setRateType] = useState(RATE_TYPE_OPTIONS[0]);
  const [rateFrom, setRateFrom] = useState("");
  const [rateTo, setRateTo] = useState("");
  const [rateMonthly, setRateMonthly] = useState("");
  const [rateAnnualy, setRateAnnualy] = useState("");
  const [app, setApp] = useState(null);
  const [appOtpion, setAppOption] = useState(null);
  const [status, setStatus] = useState(STATUS_OPTIONS[0]);

  useEffect(() => {
    if (currentRate) {
      setRateFrom(currentRate.nbFrom);
      setRateTo(currentRate.nbTo);
      setRateMonthly(currentRate.monthPrice);
      setRateAnnualy(currentRate.annualPrice);
      setRateType(
        RATE_TYPE_OPTIONS.filter((ro) => ro.value === currentRate.type)[0]
      );
      setStatus(
        STATUS_OPTIONS.filter(
          (status) => status.value === currentRate.status
        )[0]
      );
      if (currentRate.app) {
        setApp(APPS_OPTIONS.filter((app) => app.value === currentRate.app)[0]);
      }
      if (currentRate.appOption) {
        setAppOption(
          APP_PAID_OPTIONS.filter(
            (app) => app.value === currentRate.appOption
          )[0]
        );
      }
    }
  }, [currentRate]);

  const validate = () => {
    let errors = [];

    if (!rateType) {
      errors.push(_("rate_type_required"));
    }

    if (
      rateType &&
      rateType.value === "COLLABORATOR" &&
      (!rateFrom || !rateTo)
    ) {
      errors.push(_("rate_range_required"));
    }

    if (!rateMonthly || !rateAnnualy) {
      errors.push(_("rate_price_required"));
    }

    if (rateMonthly && rateAnnualy && rateMonthly >= rateAnnualy) {
      errors.push(_("rate_price_invalid"));
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      type: rateType.value,
      nbFrom: rateFrom,
      nbTo: rateTo,
      app: app ? app.value : "",
      appOtpion: appOtpion ? appOtpion.value : "",
      monthPrice: rateMonthly,
      annualPrice: rateAnnualy,
      status: status.value,
    };

    if (currentRate) {
      data.id = currentRate.id;
    }

    setIsSaving(true);
    saveRate(token, data)
      .then((response) => {
        setIsSaving(false);
        closePopOver();
        toast.success(_("successfully_saved"));
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    closePopOver();
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>

        <div>
          <label className="ttp-label">{_("rate_type")}</label>
          <Select
            styles={SELECT_STYLES}
            options={RATE_TYPE_OPTIONS}
            value={rateType}
            onChange={(e) => setRateType(e)}
          />
        </div>

        {rateType?.value === "COLLABORATOR" && (
          <div className={styles.formRow}>
            <div>
              <label className="ttp-label">{_("number_from")}</label>
              <input
                type="text"
                className="ttp-input"
                autocomplete="off"
                value={rateFrom}
                onChange={(e) => setRateFrom(e.target.value)}
              />
            </div>

            <div>
              <label className="ttp-label">{_("to")}</label>
              <input
                type="text"
                className="ttp-input"
                autocomplete="off"
                value={rateTo}
                onChange={(e) => setRateTo(e.target.value)}
              />
            </div>
          </div>
        )}

        {rateType.value === "APP" && (
          <div>
            <label className="ttp-label">{_("app")}</label>
            <Select
              styles={SELECT_STYLES}
              options={APPS_OPTIONS}
              value={app}
              onChange={(e) => setApp(e)}
            />
          </div>
        )}

        {rateType.value === "OPTION" && (
          <div>
            <label className="ttp-label">{_("option")}</label>
            <Select
              styles={SELECT_STYLES}
              options={APP_PAID_OPTIONS}
              value={appOtpion}
              onChange={(e) => setAppOption(e)}
            />
          </div>
        )}

        <div className={styles.formRow}>
          <div>
            <label className="ttp-label">{_("rate_monthly")}</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={rateMonthly}
              onChange={(e) => setRateMonthly(e.target.value)}
            />
          </div>

          <div>
            <label className="ttp-label">{_("rate_annualy")}</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={rateAnnualy}
              onChange={(e) => setRateAnnualy(e.target.value)}
            />
          </div>
        </div>

        <div>
          <label className="ttp-label">{_("status")}</label>
          <Select
            styles={SELECT_STYLES}
            options={STATUS_OPTIONS}
            value={status}
            onChange={(e) => setStatus(e)}
          />
        </div>

        <div className={styles.actions}>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={save}>
              {currentRate ? _("update") : _("add")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default RatePopOver;
