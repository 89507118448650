import axios from "axios";

import { TTP_API_URL } from "../config";

import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
  throwCatchedError,
} from "../utils";

let getThemesCTS;
let getDomainsListCTSLabel;
let getPagesCTS;

export const getThemesList = ({ search, token, limit }) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  let requestCancellationToken = getRequestCancellationToken(
    getThemesCTS,
    cancellationTokenSource
  );
  getThemesCTS = cancellationTokenSource;

  const requestUrl = `${TTP_API_URL}/blog/theme`;

  let filters = [];

  if (search !== null) {
    filters = [
      {
        property: "titleFr",
        value: search,
        operator: "like",
      },
      {
        property: "titleEn",
        value: search,
        operator: "like",
        filter: "or",
      },
      {
        property: "titleNl",
        value: search,
        operator: "like",
        filter: "or",
      },
    ];
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*,icon,media,pages,domains",
  };
  if (limit) {
    params.limit = limit;
  } else {
    params.nolimit = 1;
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const saveDomain = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/domain`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("titleFr", data.titleFr);
  formData.append("titleNl", data.titleNl);
  formData.append("titleEn", data.titleEn);
  formData.append("organization", data.organization);
  if (data.icon) {
    formData.append("icon", data.icon);
  }

  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  formData.append("isEditable", data.isEditable ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await axios.post(requestUrl, formData);
  if (data.icon) {
    var iconFormData = new FormData();
    iconFormData.append("access_token", token);
    iconFormData.append("id", data.icon);
    iconFormData.append("isUsed", 1);
    await axios.post(`${TTP_API_URL}/media/webtool-icon`, iconFormData);
  }

  return res;
};

export const saveTheme = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/theme`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("titleFr", data.titleFr);
  formData.append("titleNl", data.titleNl);
  formData.append("titleEn", data.titleEn);
  formData.append("organization", data.organization);
  formData.append("isManual", data.isManual);
  // formData.append("app", data.app);
  // formData.append("domain", data.domain);
  if (data.domain) {
    data.domain.map((item, i) => {
      return formData.append(`domainTheme[${i}]`, item);
    });
  }

  if (data.icon) {
    formData.append("icon", data.icon);
  }

  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }

  if (data.coverFile) {
    formData.append(`mediaTheme[0][yPos]`, data.yPos);
    if (data.coverFile instanceof File) {
      formData.append(`mediaTheme[0][file]`, data.coverFile);
      formData.append(`mediaTheme[0][name]`, data.coverFile.name);
    } else {
      if (data.mediaThemeId !== null && undefined !== data.mediaThemeId) {
        formData.append(`mediaTheme[0][id]`, data.mediaThemeId);
      }
    }
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await axios.post(requestUrl, formData);
  if (data.icon) {
    var iconFormData = new FormData();
    iconFormData.append("access_token", token);
    iconFormData.append("id", data.icon);
    iconFormData.append("isUsed", 1);
    await axios.post(`${TTP_API_URL}/media/webtool-icon`, iconFormData);
  }

  return res;
};

export const getDomainsList = (
  { token, community, customFilter = null },
  cancellationTokenSource = null
) => {
  let requestCancellationToken = getRequestCancellationToken(
    getDomainsListCTSLabel,
    cancellationTokenSource
  );

  const requestUrl = `${TTP_API_URL}/blog/domain`;

  let filters = [];

  if (community) {
    if (community === 9) {
      filters.push({
        property: "organization",
        value: community,
        operator: "eq",
      });
    } else {
      filters.push({
        property: "isEditable",
        value: 1,
        operator: "eq",
      });
    }
  }

  if (customFilter !== null && Array.isArray(customFilter)) {
    filters.push(...customFilter);
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*,icon",
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getWebtoolIcons = (token) => {
  const sort = [
    {
      property: "label",
      dir: "asc",
    },
  ];
  const fields = ["*"];
  const requestUrl = `${TTP_API_URL}/media/webtool-icon`;

  return axios
    .get(requestUrl, {
      params: {
        access_token: token,
        sort: JSON.stringify(sort),
        fields: fields.join(","),
        nolimit: 1,
      },
    })
    .catch(function (thrown) {
      throwCatchedError(thrown);
    });
};

export const removePage = (token, pageId) => {
  const requestUrl = `${TTP_API_URL}/blog/page/${pageId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const removeTheme = (token, themeId) => {
  const requestUrl = `${TTP_API_URL}/blog/theme/${themeId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const removeDomain = (token, domainId) => {
  const requestUrl = `${TTP_API_URL}/blog/domain/${domainId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const savePage = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/blog/page`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("titleFr", data.titleFr);
  formData.append("titleNl", data.titleNl);
  formData.append("titleEn", data.titleEn);
  formData.append("organization", data.organization);
  formData.append("theme", data.theme);

  if (data.icon) {
    formData.append("icon", data.icon);
  }

  formData.append("isPrivate", data.isPrivate ? 1 : 0);
  if (data.privateGroups) {
    formData.append("privateGroups", data.privateGroups);
  }

  if (data.coverFile) {
    formData.append(`mediaPage[0][yPos]`, data.yPos);
    if (data.coverFile instanceof File) {
      formData.append(`mediaPage[0][file]`, data.coverFile);
      formData.append(`mediaPage[0][name]`, data.coverFile.name);
    } else {
      if (data.mediaPageId !== null && undefined !== data.mediaPageId) {
        formData.append(`mediaPage[0][id]`, data.mediaPageId);
      }
    }
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  const res = await axios.post(requestUrl, formData, {});
  if (data.icon) {
    var iconFormData = new FormData();
    iconFormData.append("access_token", token);
    iconFormData.append("id", data.icon);
    iconFormData.append("isUsed", 1);
    await axios.post(`${TTP_API_URL}/media/webtool-icon`, iconFormData);
  }

  return res;
};

export const getPages = ({ token, search }) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  let requestCancellationToken = getRequestCancellationToken(
    getPagesCTS,
    cancellationTokenSource
  );
  getPagesCTS = cancellationTokenSource;

  const requestUrl = `${TTP_API_URL}/blog/page`;

  let filters = [];
  if (search) {
    filters = [
      {
        property: "titleFr",
        value: search,
        operator: "like",
      },
      {
        property: "titleEn",
        value: search,
        operator: "like",
        filter: "or",
      },
      {
        property: "titleNl",
        value: search,
        operator: "like",
        filter: "or",
      },
    ];
  }
  let params = {
    access_token: token,
    filter: JSON.stringify(filters),
    fields: "*,icon,media,media_cropped,theme",
    nolimi: 1,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};
