import React from "react";
import {
  MediaAppPublic,
  BlogAppPublic,
  TeamAppPublic,
  ContactAppPublic,
  SpecialtyAppPublic,
} from "../../PortalApps";

const Classic = ({
  handlePortalBlocChange,
  portals,
  setAddMediaModalOpened,
  medias,
  setMedias,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  titleAttr,
  handlePortalBlocTitleChange,
  sortCollaborators,
  collaborators,
  setCollaborators,
  handleGeoChange,
  addPushPinOnClick,
  handleChangeSchedule,
  handleApplySchedule,
  contactEmailError,
  setContactEmailError,
  contactPhoneError,
  setContactPhoneError,
  specialties,
  folderSpecialties,
  setFolderSpecialties,
  fiduciaryConfig,
}) => {
  return (
    <>
      <MediaAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        setAddMediaModalOpened={setAddMediaModalOpened}
        medias={medias}
        setMedias={setMedias}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
      />
      <BlogAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
      />
      <SpecialtyAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        specialties={specialties}
        folderSpecialties={folderSpecialties}
        setFolderSpecialties={setFolderSpecialties}
        maxPicks={fiduciaryConfig.classicDesign.specialties.maxPicks}
        maxFavorites={fiduciaryConfig.classicDesign.specialties.maxFavorites}
        allowFavorites={
          fiduciaryConfig.classicDesign.specialties.allowFavorites
        }
      />
      <TeamAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        sortCollaborators={sortCollaborators}
        collaborators={collaborators}
        setCollaborators={setCollaborators}
      />
      <ContactAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        handleGeoChange={handleGeoChange}
        addPushPinOnClick={addPushPinOnClick}
        handleChangeSchedule={handleChangeSchedule}
        handleApplySchedule={handleApplySchedule}
        contactEmailError={contactEmailError}
        setContactEmailError={setContactEmailError}
        contactPhoneError={contactPhoneError}
        setContactPhoneError={setContactPhoneError}
      />
    </>
  );
};

export default Classic;
