import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { getAuthors } from "../../../api";
import AuthorCard from "./AuthorCard";
import { TTP_BLOG_URL } from '../../../config';
import _ from "../../../i18n";

import styles from "./OurAuthors.module.scss";

const OurAuthors = () => {

    const token = useSelector((state) => state.auth.token);
    const lng = useSelector((state) => state.params.language);
    const [ authors, setAuthors ] = useState([]);

    useEffect(() => {
        if (token) {
            getAuthors(
                token,
                9
            ).then(resp => {
                setAuthors(resp.data.data);
            });
        }
    }, [token]);

    return (<div className={styles.authors}> 
        <div className={styles.title}>
            <span>Nos</span>autheurs
        </div>
        {  authors.map((author, index) =>  <AuthorCard author={author} lng={lng} key={author.id}/> )}
        <a className={styles.all} href={`${TTP_BLOG_URL}/${lng}/authors`} target="_blank" rel="noreferrer">{_('all_authors')}</a>
    </div>);
}

export default OurAuthors;