import React, { useState } from "react";
import { useSelector } from "react-redux";

import {
  MemberMediaAppPublic,
  AboutAppPublic,
  BlogAppPublic,
  TeamAppPublic,
  ContactAppPublic,
  PartnersAppPublic,
  ServicesAppPublic,
  NumbersAppPublic,
  SpecialtyAppPublic,
  TestimonialsAppPublic,
  FaqsAppPublic,
} from "../../PortalApps";
import MediaSlider from "./MemberBlocs/MediaSlider";
import EditContainer from "./MemberBlocs/EditContainer";
import ConfigModal from "./ConfigModal";
import SingleService from "./MemberBlocs/SingleService";
import About from "./MemberBlocs/About";
import News from "./MemberBlocs/News";
import Team from "./MemberBlocs/Team";
import Contact from "./MemberBlocs/Contact";
import Clients from "./MemberBlocs/Clients";
import Services from "./MemberBlocs/Services";
import Stats from "./MemberBlocs/Stats";
import Specialties from "./MemberBlocs/Specialties";
import Testimonials from "./MemberBlocs/Testimonials";
import Faq from "./MemberBlocs/Faq";

const MemberV2 = ({
  handlePortalBlocChange,
  portals,
  memberMedias,
  setMemberMedias,
  orderMemberSettings,
  titleAttr,
  handlePortalBlocTitleChange,
  partners,
  setPartners,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  aboutImage,
  setAboutImage,
  handleAboutPageBlocChange,
  handleAboutPageBlocDescriptionChange,
  handleAboutPageBlocTitleChange,
  valuesList,
  setValuesList,
  valuesVideo,
  setValuesVideo,
  galleryMedias,
  setGalleryMedias,
  services,
  setServices,
  sortServices,
  setNumbersList,
  numbersList,
  numbersImage,
  setNumbersImage,
  specialties,
  folderSpecialties,
  setFolderSpecialties,
  testimonials,
  setTestimonials,
  sortTestimonials,
  faqs,
  setFaqs,
  sortFaqs,
  sortCollaborators,
  collaborators,
  setCollaborators,
  handleGeoChange,
  addPushPinOnClick,
  handleChangeSchedule,
  handleApplySchedule,
  contactEmailError,
  setContactEmailError,
  contactPhoneError,
  setContactPhoneError,
}) => {
  const [editSection, setEditSection] = useState(null);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  return (
    <>
      <EditContainer
        isVisible={portals.public.config.memberMedia.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.memberMedia.activated,
            "memberMedia"
          )
        }
        edit={() => setEditSection("memberMedia")}
      >
        <MediaSlider images={memberMedias.slice(0, 5)} />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.partners.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.partners.activated,
            "partners"
          )
        }
        edit={() => setEditSection("partners")}
      >
        <Clients
          list={partners}
          data={portals.public.config.partners}
          version={"v2"}
        />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.about.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.about.activated,
            "about"
          )
        }
        edit={() => setEditSection("about")}
      >
        <About image={aboutImage} data={portals.public.config.about} />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.services.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.services.activated,
            "services"
          )
        }
        edit={() => setEditSection("services")}
      >
        <Services
          data={portals.public.config.services}
          services={services.filter((el) => el.show).slice(0, 3)}
        />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.numbers.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.numbers.activated,
            "numbers"
          )
        }
        edit={() => setEditSection("numbers")}
      >
        <Stats
          data={portals.public.config.numbers}
          list={numbersList}
          image={numbersImage}
          version={"v2"}
        />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.specialty.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.specialty.activated,
            "specialty"
          )
        }
        edit={() => setEditSection("specialty")}
      >
        <Specialties
          data={portals.public.config.specialty}
          types={specialties ? specialties : []}
          folderData={folderSpecialties}
        />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.testimonials.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.testimonials.activated,
            "testimonials"
          )
        }
        edit={() => setEditSection("testimonials")}
      >
        <Testimonials
          data={portals.public.config.testimonials}
          testimonials={testimonials.filter((el) => el.show).slice(0, 2)}
        />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.faqs.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.faqs.activated, "faqs")
        }
        edit={() => setEditSection("faqs")}
      >
        <Faq
          data={portals.public.config.faqs}
          faqs={faqs.filter((el) => el.show).slice(0, 3)}
        />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.news.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.news.activated, "news")
        }
        edit={() => setEditSection("news")}
      >
        <News data={portals.public.config.news} version={"v2"} />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.team.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.team.activated, "team")
        }
        edit={() => setEditSection("team")}
      >
        <Team
          data={portals.public.config.team}
          team={collaborators.filter((el) => el.enableAvatar).slice(0, 8)}
        />
      </EditContainer>
      <EditContainer
        isVisible={portals.public.config.contact.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.contact.activated,
            "contact"
          )
        }
        edit={() => setEditSection("contact")}
      >
        <Contact
          navCommunity={fiduciary}
          publicPortal={portals.public}
          version={"v2"}
          onEdit={editSection === "contact"}
        />
      </EditContainer>
      <ConfigModal
        isOpen={editSection !== null}
        close={() => setEditSection(null)}
      >
        {editSection === "memberMedia" && (
          <MemberMediaAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            medias={memberMedias}
            setMedias={setMemberMedias}
            maxPicks={orderMemberSettings.v1.media.maxPick}
            inModal={true}
          />
        )}

        {editSection === "partners" && (
          <PartnersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            partners={partners}
            setPartners={setPartners}
            maxPicks={orderMemberSettings.v2.partners.maxPick}
            inModal={true}
          />
        )}
        {editSection === "about" && (
          <AboutAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            aboutImage={aboutImage}
            setAboutImage={setAboutImage}
            handleAboutPageBlocChange={handleAboutPageBlocChange}
            handleAboutPageBlocTitleChange={handleAboutPageBlocTitleChange}
            handleAboutPageBlocDescriptionChange={
              handleAboutPageBlocDescriptionChange
            }
            valuesList={valuesList}
            setValuesList={setValuesList}
            valuesVideo={valuesVideo}
            setValuesVideo={setValuesVideo}
            galleryMedias={galleryMedias}
            setGalleryMedias={setGalleryMedias}
            inModal={true}
          />
        )}
        {editSection === "services" && (
          <ServicesAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            services={services}
            setServices={setServices}
            sortServices={sortServices}
            maxPicks={orderMemberSettings.v2.services.maxPick}
            version={"v2"}
            inModal={true}
          />
        )}
        {editSection === "numbers" && (
          <NumbersAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            setNumbersList={setNumbersList}
            numbersList={numbersList}
            maxPicks={orderMemberSettings.v2.stats.maxPick}
            allowImg={false}
            numbersImage={numbersImage}
            setNumbersImage={setNumbersImage}
            inModal={true}
          />
        )}
        {editSection === "specialty" && (
          <SpecialtyAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            specialties={specialties}
            folderSpecialties={folderSpecialties}
            setFolderSpecialties={setFolderSpecialties}
            maxPicks={orderMemberSettings.v2.specialties.maxPick}
            maxFavorites={orderMemberSettings.v2.specialties.maxFavorites}
            allowFavorites={orderMemberSettings.v2.specialties.allowFavorites}
            inModal={true}
          />
        )}
        {editSection === "testimonials" && (
          <TestimonialsAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            testimonials={testimonials}
            setTestimonials={setTestimonials}
            sortTestimonials={sortTestimonials}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            maxPicks={orderMemberSettings.v2.testimonials.maxPick}
            inModal={true}
          />
        )}
        {editSection === "faqs" && (
          <FaqsAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            faqs={faqs}
            setFaqs={setFaqs}
            sortFaqs={sortFaqs}
            maxPicks={orderMemberSettings.v2.faq.maxPick}
            inModal={true}
          />
        )}
        {editSection === "news" && (
          <BlogAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            inModal={true}
          />
        )}
        {editSection === "team" && (
          <TeamAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            titleAttr={titleAttr}
            handlePortalBlocTitleChange={handlePortalBlocTitleChange}
            descriptionAttr={descriptionAttr}
            handlePortalBlocDescriptionChange={
              handlePortalBlocDescriptionChange
            }
            sortCollaborators={sortCollaborators}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            inModal={true}
          />
        )}
        {editSection === "contact" && (
          <ContactAppPublic
            handlePortalBlocChange={handlePortalBlocChange}
            portals={portals}
            handleGeoChange={handleGeoChange}
            addPushPinOnClick={addPushPinOnClick}
            handleChangeSchedule={handleChangeSchedule}
            handleApplySchedule={handleApplySchedule}
            contactEmailError={contactEmailError}
            setContactEmailError={setContactEmailError}
            contactPhoneError={contactPhoneError}
            setContactPhoneError={setContactPhoneError}
            inModal={true}
          />
        )}
      </ConfigModal>
    </>
  );
};

export default MemberV2;
