import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import { getMedias, getMediasByID } from "../../api";
import { TTP_API_URL } from "../../config";
import styles from "./PublicPortal.module.scss";

const Media = ({ community, info }) => {
  const token = useSelector((state) => state.auth.token);
  const lng = useSelector((state) => state.params.language);
  const [nbr, setNbr] = useState(0);

  let { isLoading, data } = useQuery(
    ["getMediasInTheNews", token, community],
    async () => {
      if (token && community && info.list && info.list.length > 0) {
        const response = await getMediasByID({
          token,
          ids: info.list,
        });
        return response.data.data;
      }
    }
  );

  let hide = data && data.length === 1 ? styles.hide : "";

  let settings = {
    dots: true,
    dotsClass: `${styles.slick_dots} ${hide}`,
    // infinite: true,
    arrows: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 10000,
    key: 11,
    beforeChange: () => {
      setNbr(nbr + 1);
    },
  };

  if (data && data.length === nbr) {
    settings.autoplay = false;
  }

  if (isLoading || !data) {
    return (
      <div className={styles.mediaCaroussel}>
        <div
          style={{
            backgroundColor: "#18a0fb",
            backgroundSize: "contain",
            width: "100%",
            height: "50rem",
          }}
        />
      </div>
    );
  }

  let imgDivs = (data || []).map((media) => (
    <div
      // className="slider-image"
      key={`med-${media.id}`}
    >
      <div
        style={{
          backgroundImage: `linear-gradient(0deg, rgba(9, 22, 38, 0.6), rgba(9, 22, 38, 0.6)),url(${
            media.fullMediaUrl
              ? media.fullMediaUrl
              : `${TTP_API_URL}/${media.webPath}`
          })`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          width: "100%",
          height: "50rem",
        }}
      ></div>
    </div>
  ));

  return (
    <div className={styles.mediaCaroussel}>
      {imgDivs.length > 0 ? <Slider {...settings}>{imgDivs}</Slider> : null}
    </div>
  );
};

export default Media;
