import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import Select from "react-select";

import { savePackApp, saveApplication } from "../../../../api";
import _ from "../../../../i18n";
import { SELECT_STYLES } from "../../../../config";

import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import Tabs from "../../../common/Tabs";

import styles from "./AppPopOver.module.scss";

const AppPopOver = ({
  currentApp,
  currentPack,
  closePopOver,
  onSave,
  type,
  parentApp,
  applications,
}) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const language = useSelector((state) => state.params.language);
  const nameAttr = `name${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const [isSaving, setIsSaving] = useState(false);
  const [name, setName] = useState({
    fr: "",
    nl: "",
    en: "",
  });
  const [description, setDescription] = useState({
    fr: "",
    nl: "",
    en: "",
  });
  const [uaApplication, setUaApplication] = useState(null);

  useEffect(() => {
    if (currentApp) {
      setName({
        fr: currentApp.nameFr,
        nl: currentApp.nameNl,
        en: currentApp.nameEn,
      });
      if (currentApp.type === "APP") {
        const value = applications.filter(
          (i) => i.id === currentApp.uaApplication.id
        );
        if (value) {
          setUaApplication(value[0]);
        }
      }
    }
  }, [currentApp]);

  const validate = () => {
    let errors = [];

    if (type === "APP" && !uaApplication) {
      errors.push(_("app_required"));
    }

    let languageError = false;
    currentPack.languages.forEach((lng) => {
      if (!name[lng]) {
        languageError = true;
      }
    });

    if (languageError) {
      errors.push(_("app_name_required"));
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      name,
      description,
      uaPack: currentPack.id,
      type,
    };

    if (uaApplication) {
      data.uaApplication = uaApplication.id;
    }

    if (parentApp) {
      data.parent = parentApp.id;
    }

    if (currentApp) {
      data.id = currentApp.id;
    }

    setIsSaving(true);
    savePackApp(token, data)
      .then(async (response) => {
        if (type === "APP" && !currentApp && uaApplication) {
          try {
            const resp = await saveApplication(token, {
              id: uaApplication.id,
              isUsed: 1,
            });
          } catch (e) {}
        }
        setIsSaving(false);
        onSave();
        toast.success(_("successfully_saved"));
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    closePopOver();
  };

  const handleAppChange = (e) => {
    setUaApplication(e);
    setName({
      fr: e.nameFr,
      nl: e.nameNl,
      en: e.nameEn,
    });
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>

        {type === "APP" ? (
          <>
            <h3>{currentApp ? "Modifier" : "Ajouter une application"}</h3>

            <div>
              <label className="ttp-label">Application</label>
              <Select
                styles={SELECT_STYLES}
                options={applications}
                formatOptionLabel={(app) => (
                  <div className={styles.select_app_option}>
                    <img
                      width="20"
                      src={`/img/apps/${app.icon}`}
                      alt="app-image"
                    />
                    <span>{app[nameAttr]}</span>
                  </div>
                )}
                isOptionDisabled={(option) => option.isUsed}
                getOptionValue={(option) => option.id}
                value={uaApplication}
                onChange={handleAppChange}
              />
            </div>

            <div>
              <label className="ttp-label">{_(`name`) + " FR"}</label>
              <input
                type="text"
                className="ttp-input"
                autocomplete="off"
                value={name.fr}
                onChange={(e) => setName({ ...name, fr: e.target.value })}
              />
            </div>

            <div>
              <div className={styles.langTab}>
                <label className="ttp-label">{_(`name`) + " NL"}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={name.nl}
                  onChange={(e) => setName({ ...name, nl: e.target.value })}
                />
              </div>
            </div>
            <div>
              <div className={styles.langTab}>
                <label className="ttp-label">{_(`name`) + " EN"}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={name.en}
                  onChange={(e) => setName({ ...name, en: e.target.value })}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <h3>
              {type === "CATEGORY" &&
                (currentApp
                  ? "Modifier une catégorie"
                  : "Ajouter une catégorie")}
              {type === "SUB_CATEGORY" &&
                (currentApp
                  ? "Modifier une sous-catégorie"
                  : "Ajouter une sous-catégorie")}
              {type === "OPTION" &&
                (currentApp ? "Modifier une option" : "Ajouter une option")}
            </h3>
            <Tabs theme="wtheme">
              {currentPack.languages.map((lang) => (
                <div
                  key={`langtab${lang}`}
                  label={lang}
                  icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${lang}.png`}
                >
                  <div>
                    <div className={styles.langTab}>
                      <label className="ttp-label">
                        {_(`name`) + " " + lang.toUpperCase()}
                      </label>
                      <input
                        type="text"
                        className="ttp-input"
                        autocomplete="off"
                        value={name[lang]}
                        onChange={(e) =>
                          setName({ ...name, [lang]: e.target.value })
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label className="ttp-label">
                      {_(`description`) + " " + lang.toUpperCase()}
                    </label>
                    <textarea
                      rows="4"
                      className="ttp-input"
                      value={description[lang]}
                      onChange={(e) =>
                        setDescription({
                          ...description,
                          [lang]: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              ))}
            </Tabs>
          </>
        )}

        <div className={styles.actions}>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={save}>
              {currentApp ? _("update") : _("add")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AppPopOver;
