import _ from "../../i18n";

import styles from "./Pack.module.scss";

const Formule = ({ formula }) => {
  if (!formula) {
    return null;
  }
  return (
    <div
      // className={`${styles.formula} ${
      //   type === "pro" ? styles.formula_pro : ""
      // }`}
      className={`${styles.formula} ${styles.formula_pro}`}
    >
      <span>{_("formula")}</span>
      {formula.name}
    </div>
  );
};

export default Formule;
