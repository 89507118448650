import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

import styles from "./Themes.module.scss";
import ThemesLayout from "../ThemesLayout";
import _ from "../../i18n";
import { getThemesList, getPages } from "../../api";
import ThemeItem from "./ThemeItem";
import Sidebar from "./Sidebar";

const Themes = () => {
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const { token } = auth;
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [themes, setThemes] = useState([]);
  const [themeSearch, setThemeSearch] = useState("");
  const [pageSearch, setPageSearch] = useState("");
  const [isPageSearchResult, setIsPageSearchResult] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const handleShowSidebar = (theme) => {
    setSelectedTheme(theme);
    setSidebarOpened(true);
  };
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const sortAndSetThemes = (list) => {
    setThemes(
      list
        .sort((a, b) => {
          // sort themes
          const nameA = a[titleAttr].toLowerCase();
          const nameB = b[titleAttr].toLowerCase();

          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        })
        .map((element) => {
          if (element.pages) {
            // sort pages
            return {
              ...element,
              pages: element.pages.sort((a, b) => {
                const nameA = a[titleAttr].toLowerCase();
                const nameB = b[titleAttr].toLowerCase();

                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              }),
            };
          }
          return element;
        })
    );
  };

  const groupPagesByTheme = (pagesList) => {
    let themesList = [];
    pagesList.forEach((page) => {
      if (page.theme) {
        if (themesList.filter((el) => el.id === page.theme.id).length > 0) {
          themesList = themesList.map((theme) => {
            if (theme.id === page.theme.id) {
              return {
                ...theme,
                pages: [...theme.pages, page],
              };
            }
            return theme;
          });
        } else {
          themesList.push({
            ...page.theme,
            pages: [page],
          });
        }
      }
    });
    sortAndSetThemes(themesList);
  };

  useQuery(["fetchThemes", token, themeSearch, pageSearch], async () => {
    if (
      token &&
      (themeSearch.trim().length === 0 || themeSearch.trim().length > 3) &&
      pageSearch.trim().length === 0
    ) {
      try {
        setIsPageSearchResult(false);
        setIsFetching(true);
        const res = await getThemesList({
          token,
          search: themeSearch.trim().length > 3 ? themeSearch : null,
        });
        sortAndSetThemes(res.data.data);
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false);
        console.error(error);
      }
    }
  });

  useQuery(["fetchPages", token, pageSearch], async () => {
    if (token && pageSearch.trim().length > 3) {
      try {
        setIsFetching(true);
        const res = await getPages({ token, search: pageSearch });
        groupPagesByTheme(res.data.data);
        setIsPageSearchResult(true);
        setIsFetching(false);
      } catch (error) {
        setIsFetching(false);
        console.error(error);
      }
    }
  });

  const afterThemeSave = ({ val, isEdit }) => {
    if (isEdit) {
      sortAndSetThemes(
        themes.map((theme) => {
          if (theme.id === val.id) {
            return val;
          }
          return theme;
        })
      );
    } else {
      sortAndSetThemes([...themes, val]);
    }
  };

  const afterPageDelete = ({ id, themeId }) => {
    sortAndSetThemes(
      themes.map((theme) => {
        if (theme.id === themeId) {
          return {
            ...theme,
            pages: theme.pages.filter((page) => page.id !== id),
          };
        }
        return theme;
      })
    );
  };

  const afterThemeDelete = ({ id }) => {
    sortAndSetThemes(themes.filter((el) => el.id !== id));
  };

  const afterPageSave = ({ val, themeId, isEdit }) => {
    sortAndSetThemes(
      themes.map((theme) => {
        if (theme.id === themeId) {
          if (isEdit) {
            return {
              ...theme,
              pages: theme.pages.map((page) => {
                if (page.id === val.id) {
                  return val;
                }
                return page;
              }),
            };
          } else {
            return {
              ...theme,
              pages: [...(theme.pages ? theme.pages : []), val],
            };
          }
        }
        return theme;
      })
    );
  };
  return (
    <ThemesLayout>
      <>
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.titles}>
              <div className={styles.title}>{_("theme_title")}</div>
              <div className={styles.subtitle}>{_("theme_sub_title")}</div>
            </div>
            <div className={styles.searches}>
              <input
                placeholder={_("theme_search_placeholder")}
                value={themeSearch}
                onChange={(e) => {
                  setThemeSearch(e.target.value);
                  setPageSearch("");
                }}
              />
              <input
                placeholder={_("page_search_placeholder")}
                value={pageSearch}
                onChange={(e) => {
                  setPageSearch(e.target.value);
                  setThemeSearch("");
                }}
              />
            </div>
          </div>
          {isFetching
            ? [...Array(4).keys()].map((i) => (
                <div key={`themefetch-${i}`} className={styles.fetchItem}>
                  <Skeleton height={44} />
                </div>
              ))
            : themes.map((theme) => (
                <ThemeItem
                  key={`theme-${theme.id}`}
                  theme={theme}
                  showSidebar={() => handleShowSidebar(theme)}
                  afterPageSave={afterPageSave}
                  afterPageDelete={afterPageDelete}
                  afterThemeDelete={afterThemeDelete}
                  isPageSearchResult={isPageSearchResult}
                />
              ))}
        </div>
        <div className={styles.bubbles}>
          <div
            onClick={() => {
              setSelectedTheme(null);
              setSidebarOpened(true);
            }}
          >
            <i className="icon-ttp-plus-outline"></i>
            <span style={{ paddingLeft: "1rem" }}>{_("add")}</span>
          </div>
        </div>

        <Sidebar
          isOpened={sidebarOpened}
          hideSidebar={() => {
            setSidebarOpened(false);
            setSelectedTheme(null);
          }}
          selectedTheme={selectedTheme}
          afterThemeSave={afterThemeSave}
        />
      </>
    </ThemesLayout>
  );
};

export default Themes;
