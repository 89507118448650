import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { v4 as uuidv4 } from "uuid";

import styles from "./ServicePopOver.module.scss";
import tabstyles from "../tabs/Portals/Portals.module.scss";
import _ from "../../../i18n";
import Button from "../../common/Button";
import Loader from "../../common/Loader";
import Tabs from "../../common/Tabs";
import IconLoading from "../../common/icons/IconLoading";
import { saveMedia, saveService } from "../../../api";
import Switch from "../../common/Switch/Switch";
import { ModalConfirm } from "tamtam-components";

import { IconTrash } from "../../common/icons/IconTrash";

const RenderDropZone = ({
  section,
  setSections,
  sections,
  setErrors,
  token,
  fiduciary,
}) => {
  const [isUploadingSection, setIsUploadingSection] = useState(null);

  const handleDropZone = async ({ acceptedFiles, fileRejections, id }) => {
    if (fileRejections) {
      setErrors([_("wrong_file_format")]);
    }
    if (acceptedFiles.length > 0) {
      setErrors([]);
      setIsUploadingSection(id);
      try {
        const res = await saveMedia({
          token,
          community: fiduciary,
          file: acceptedFiles[0],
        });

        setSections(
          sections.map((element) => {
            if (element.id === id) {
              return {
                ...element,
                image: res.data.data.fullMediaUrl,
              };
            }
            return element;
          })
        );
        setIsUploadingSection(null);
      } catch (error) {
        setIsUploadingSection(null);
        setErrors([_("file_upload_error")]);
        console.error(error);
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles, fileRejections) =>
      handleDropZone({ acceptedFiles, fileRejections, id: section.id }),
  });
  return (
    <div
      className={styles.dropZone}
      style={{ backgroundImage: `url(${section.image})` }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {section.image.length === 0 && (
        <>
          <div className={styles.text}>
            <span>{_("image_import_text")}</span>
          </div>
        </>
      )}

      {isUploadingSection && isUploadingSection === section.id && (
        <div className={styles.uploading_cover}>
          <IconLoading />
        </div>
      )}
    </div>
  );
};

const ServicePopOver = ({
  isOpen,
  onClose,
  currentElement,
  languages,
  afterServiceSave,
  setCurrentElement,
  hasPageConfig,
}) => {
  const token = useSelector((state) => state.auth.token);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState({ fr: "", en: "", nl: "" });
  const [description, setDescription] = useState({ fr: "", en: "", nl: "" });
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [banner, setBanner] = useState({
    descriptionFr: "",
    descriptionEn: "",
    descriptionNl: "",
    titleFr: "",
    titleEn: "",
    titleNl: "",
  });
  const [introduction, setIntroduction] = useState({
    activated: false,
    descriptionFr: "",
    descriptionEn: "",
    descriptionNl: "",
    titleFr: "",
    titleEn: "",
    titleNl: "",
  });
  const [sections, setSections] = useState([]);
  const [currentSection, setCurrentSection] = useState(null);

  const titleAttr = (language) => {
    return `title${language.charAt(0).toUpperCase() + language.slice(1)}`;
  };

  const descriptionAttr = (language) => {
    return `description${language.charAt(0).toUpperCase() + language.slice(1)}`;
  };

  useEffect(() => {
    if (currentElement) {
      setImage(currentElement.image);
      setTitle({
        fr: currentElement.titleFr,
        en: currentElement.titleEn,
        nl: currentElement.titleNl,
      });
      setDescription({
        fr: currentElement.descriptionFr,
        en: currentElement.descriptionEn,
        nl: currentElement.descriptionNl,
      });

      if (currentElement.pageInfo) {
        const tmp = currentElement.pageInfo;
        if (tmp.banner) {
          setBanner({
            ...banner,
            descriptionFr: tmp.banner.descriptionFr
              ? tmp.banner.descriptionFr
              : "",
            descriptionEn: tmp.banner.descriptionEn
              ? tmp.banner.descriptionEn
              : "",
            descriptionNl: tmp?.banner?.descriptionNl
              ? tmp?.banner?.descriptionNl
              : "",
            titleFr: tmp.banner.titleFr ? tmp.banner.titleFr : "",
            titleEn: tmp.banner.titleEn ? tmp.banner.titleEn : "",
            titleNl: tmp.banner.titleNl ? tmp.banner.titleNl : "",
          });
        }
        if (tmp.introduction) {
          setIntroduction({
            ...introduction,
            activated: tmp.introduction.activated
              ? tmp.introduction.activated
              : false,
            descriptionFr: tmp.introduction.descriptionFr
              ? tmp.introduction.descriptionFr
              : "",
            descriptionEn: tmp.introduction.descriptionEn
              ? tmp.introduction.descriptionEn
              : "",
            descriptionNl: tmp?.introduction?.descriptionNl
              ? tmp?.introduction?.descriptionNl
              : "",
            titleFr: tmp.introduction.titleFr ? tmp.introduction.titleFr : "",
            titleEn: tmp.introduction.titleEn ? tmp.introduction.titleEn : "",
            titleNl: tmp.introduction.titleNl ? tmp.introduction.titleNl : "",
          });
        }
        if (tmp.sections && tmp.sections.length > 0) {
          setSections(tmp.sections);
        }
      }
    }
  }, [currentElement]);

  const addSection = () => {
    setSections([
      ...sections,
      {
        id: uuidv4(),
        descriptionFr: "",
        descriptionEn: "",
        descriptionNl: "",
        titleFr: "",
        titleEn: "",
        titleNl: "",
        image: "",
      },
    ]);
  };

  const filesUpload = async (acceptedFiles, fileRejections) => {
    if (fileRejections) {
      setErrors([_("wrong_file_format")]);
    }
    if (acceptedFiles.length > 0) {
      setErrors([]);
      setIsUploading(true);
      try {
        const res = await saveMedia({
          token,
          community: fiduciary,
          file: acceptedFiles[0],
        });
        setImage(res.data.data.fullMediaUrl);
        setIsUploading(false);
      } catch (error) {
        setIsUploading(false);
        setErrors([_("file_upload_error")]);
        console.error(error);
      }
    }
  };

  const resetData = () => {
    setTitle({ fr: "", en: "", nl: "" });
    setDescription({ fr: "", en: "", nl: "" });
    setImage("");
    setErrors([]);
    setIsUploading(false);
    setIsSaving(false);
    setCurrentElement(null);
    setBanner({
      descriptionFr: "",
      descriptionEn: "",
      descriptionNl: "",
      titleFr: "",
      titleEn: "",
      titleNl: "",
    });
    setIntroduction({
      activated: false,
      descriptionFr: "",
      descriptionEn: "",
      descriptionNl: "",
      titleFr: "",
      titleEn: "",
      titleNl: "",
    });
    setSections([]);
    onClose();
  };

  const { getRootProps, getInputProps } = useDropzone({
    noKeyboard: true,
    accept: "image/*",
    onDrop: filesUpload,
  });

  const validate = () => {
    const err = [];
    languages.forEach((lng) => {
      if (title[lng.value].length === 0 && description[lng.value].length > 0) {
        err.push(_("description_without_title"));
        return;
      }
    });
    if (
      title.fr.length === 0 &&
      title.en.length === 0 &&
      title.nl.length === 0
    ) {
      err.push(_("add_at_least_one_title"));
    }
    if (image.length === 0) {
      err.push(_("add_image"));
    }
    setErrors(err);
    return err.length === 0;
  };

  const save = async () => {
    if (!validate()) {
      return;
    }
    setIsSaving(true);
    try {
      const data = {
        titleFr: title.fr,
        titleNl: title.nl,
        titleEn: title.en,
        descriptionFr: description.fr,
        descriptionEn: description.en,
        descriptionNl: description.nl,
        organization: fiduciary.id,
        image,
        pageInfo: JSON.stringify({
          banner,
          introduction,
          sections,
        }),
      };
      if (currentElement) {
        data.id = currentElement.id;
      }
      const res = await saveService(token, data);
      afterServiceSave(
        currentElement
          ? { ...res.data.data, show: currentElement.show }
          : { ...res.data.data, show: false }
      );
      setIsSaving(false);
      resetData();
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteSection = () => {
    if (currentSection)
      setSections(
        sections.filter((section) => section.id !== currentSection.id)
      );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => resetData()}
        className={{
          base: styles.modalContent,
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={styles.modalOverlay}
      >
        <div
          className={classNames(
            styles.modal,
            hasPageConfig ? styles.modal_large : ""
          )}
        >
          <div className={styles.header}>
            {_(currentElement ? "edit_service" : "create_service")}
          </div>
          <div className={styles.close} onClick={() => resetData()}>
            <i className="icon-ttp-close"></i>
          </div>

          <div className={styles.body}>
            {errors.length > 0 && (
              <ul className={styles.errors}>
                {errors.map((el, i) => (
                  <li key={i}>{el}</li>
                ))}
              </ul>
            )}
            {!hasPageConfig && (
              <>
                <div
                  className={styles.dropZone}
                  style={{ backgroundImage: `url(${image})` }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  {image.length === 0 && (
                    <>
                      <div className={styles.text}>
                        <span>{_("image_import_text")}</span>
                      </div>
                    </>
                  )}

                  {isUploading && (
                    <div className={styles.uploading_cover}>
                      <IconLoading />
                    </div>
                  )}
                </div>

                <Tabs theme="serviceTheme">
                  {languages.map((language) => (
                    <div
                      key={`langtab${language.value}`}
                      label={language.label}
                      icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                    >
                      <div className={tabstyles.langTab}>
                        <label className={tabstyles.configLabel}>
                          {_(`title_${language.value}`)}
                        </label>

                        <input
                          className={tabstyles.formInput}
                          value={title[language.value]}
                          onChange={(e) =>
                            setTitle({
                              ...title,
                              [language.value]: e.target.value,
                            })
                          }
                        />
                        <label
                          className={`${tabstyles.configLabel} ${tabstyles.descriptionLabel}`}
                        >
                          {_(`description_${language.value}`)}
                        </label>
                        <textarea
                          rows="4"
                          className={tabstyles.descriptionInput}
                          value={description[language.value]}
                          onChange={(e) =>
                            setDescription({
                              ...description,
                              [language.value]: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  ))}
                </Tabs>
              </>
            )}

            {hasPageConfig && (
              <div className={styles.pageContent}>
                {/* <div className={styles.pageContent_title}>
                {_("$pageName$_page_content").replace(
                  "$pageName$",
                  _("service")
                )}
              </div> */}
                <div className={styles.section}>
                  <div className={styles.top}>
                    <div className={styles.name}>{_("banner")}</div>
                  </div>
                  <Tabs theme="serviceTheme">
                    {languages.map((language) => (
                      <div
                        key={`langtab${language.value}`}
                        label={language.label}
                        icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                      >
                        <div className={tabstyles.langTab}>
                          <label className={tabstyles.configLabel}>
                            {_(`title_${language.value}`)}
                          </label>

                          <input
                            className={tabstyles.formInput}
                            value={banner[titleAttr(language.value)]}
                            onChange={(e) =>
                              setBanner({
                                ...banner,
                                [titleAttr(language.value)]: e.target.value,
                              })
                            }
                          />
                          <label
                            className={`${tabstyles.configLabel} ${tabstyles.descriptionLabel}`}
                          >
                            {_(`description_${language.value}`)}
                          </label>
                          <textarea
                            rows="4"
                            className={tabstyles.descriptionInput}
                            value={banner[descriptionAttr(language.value)]}
                            onChange={(e) =>
                              setBanner({
                                ...banner,
                                [descriptionAttr(language.value)]:
                                  e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </Tabs>
                </div>
                <div className={styles.section}>
                  <div className={styles.top}>
                    <div className={styles.name}>{_("Introduction")}</div>
                    <Switch
                      isChecked={introduction.activated}
                      onChange={(e) =>
                        setIntroduction({
                          ...introduction,
                          activated: !introduction.activated,
                        })
                      }
                    />
                  </div>

                  {introduction.activated && (
                    <Tabs theme="serviceTheme">
                      {languages.map((language) => (
                        <div
                          key={`langtab${language.value}`}
                          label={language.label}
                          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                        >
                          <div className={tabstyles.langTab}>
                            <label className={tabstyles.configLabel}>
                              {_(`title_${language.value}`)}
                            </label>

                            <input
                              className={tabstyles.formInput}
                              value={introduction[titleAttr(language.value)]}
                              onChange={(e) =>
                                setIntroduction({
                                  ...introduction,
                                  [titleAttr(language.value)]: e.target.value,
                                })
                              }
                            />
                            <label
                              className={`${tabstyles.configLabel} ${tabstyles.descriptionLabel}`}
                            >
                              {_(`description_${language.value}`)}
                            </label>
                            <textarea
                              rows="4"
                              className={tabstyles.descriptionInput}
                              value={
                                introduction[descriptionAttr(language.value)]
                              }
                              onChange={(e) =>
                                setIntroduction({
                                  ...introduction,
                                  [descriptionAttr(language.value)]:
                                    e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      ))}
                    </Tabs>
                  )}
                </div>
                <Button
                  className={styles.addMedia}
                  size={"sm"}
                  onClick={() => addSection()}
                >
                  {_("add_section")}
                </Button>
                {sections.map((section) => (
                  <>
                    <div key={section.id} className={styles.section_container}>
                      <div className={styles.section_action}>
                        <Button
                          size={"sm"}
                          variant="danger"
                          onClick={() => {
                            setCurrentSection(section);
                          }}
                        >
                          {_("delete")}
                        </Button>
                      </div>
                      <div className={styles.section_wrap}>
                        <div className={styles.left}>
                          <Tabs theme="serviceTheme">
                            {languages.map((language) => (
                              <div
                                key={`langtab${language.value}`}
                                label={language.label}
                                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
                              >
                                <div className={tabstyles.langTab}>
                                  <label className={tabstyles.configLabel}>
                                    {_(`title_${language.value}`)}
                                  </label>

                                  <input
                                    className={tabstyles.formInput}
                                    value={section[titleAttr(language.value)]}
                                    onChange={(e) =>
                                      setSections(
                                        sections.map((element) => {
                                          if (element.id === section.id) {
                                            return {
                                              ...element,
                                              [titleAttr(language.value)]:
                                                e.target.value,
                                            };
                                          }
                                          return element;
                                        })
                                      )
                                    }
                                  />

                                  <label
                                    className={`${tabstyles.configLabel} ${tabstyles.descriptionLabel}`}
                                  >
                                    {_(`description_${language.value}`)}
                                  </label>
                                  <textarea
                                    rows="4"
                                    className={tabstyles.descriptionInput}
                                    value={
                                      section[descriptionAttr(language.value)]
                                    }
                                    onChange={(e) =>
                                      setSections(
                                        sections.map((element) => {
                                          if (element.id === section.id) {
                                            return {
                                              ...element,
                                              [descriptionAttr(language.value)]:
                                                e.target.value,
                                            };
                                          }
                                          return element;
                                        })
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            ))}
                          </Tabs>
                        </div>
                        <div className={styles.right}>
                          <RenderDropZone
                            section={section}
                            setSections={setSections}
                            sections={sections}
                            setErrors={setErrors}
                            token={token}
                            fiduciary={fiduciary}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}
          </div>

          <div className={styles.controls}>
            <Button onClick={() => resetData()} variant="default">
              {_("cancel")}
            </Button>

            <Button
              disabled={isUploading}
              variant="primary"
              onClick={() => save()}
            >
              {isSaving ? (
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              ) : (
                _("save")
              )}
            </Button>
          </div>
        </div>
      </Modal>
      <ModalConfirm
        type="delete"
        isOpen={currentSection}
        onCancel={() => {
          setCurrentSection(null);
        }}
        onConfirm={() => handleDeleteSection()}
        // inProcess={deleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </>
  );
};

export default ServicePopOver;
