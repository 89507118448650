import React, { useState } from "react";
import styles from "./NewsletterModal.module.scss";
import FormInput from "../FormInput";
import Button from "../Button";
import _ from "../../../i18n";

const CollaboratorFrom = ({
  collaboratorList,
  setCollaboratorList,
  senders,
  setSenders,
  setIsVisible,
}) => {
  const [newCollaborator, setNewCollaborator] = useState({
    label: "",
    email: "",
    status: "Failed",
  });
  const [labelValue, setLabelValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "email") {
      setEmailValue(value);
      setNewCollaborator((prevState) => ({
        ...prevState,
        email: value,
      }));
    } else {
      setLabelValue(value);
      setNewCollaborator((prevState) => ({
        ...prevState,
        label: value,
      }));
    }
  };

  const handleValidateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(
      !emailRegex.test(newCollaborator.email) ? _("validate_email") : null
    );
  };

  let disabled =
    emailError || newCollaborator.email === "" || newCollaborator.label === "";

  const handleSaveCollaborator = () => {
    if (!senders.find((sender) => sender.email === newCollaborator.email)) {
      setSenders([newCollaborator, ...senders]);
    }
    setCollaboratorList(
      collaboratorList.filter((item) => item.email !== newCollaborator.email)
    );

    clearInputs();
    setIsVisible(false);
  };

  const clearInputs = () => {
    setEmailValue("");
    setLabelValue("");
  };

  return (
    <div className={styles.collab_block}>
      <div className={styles.block_close}>
        <i
          className="icon-ttp-trash"
          onClick={() => {
            clearInputs();
          }}
        />
      </div>
      <FormInput
        name="email"
        label={_("email_address")}
        labelClassName={styles.form_collab_label}
        className={styles.form_collab_input}
        handleChange={handleChangeInput}
        onKeyUp={handleValidateEmail}
        error={emailError}
        value={emailValue}
        required
      />
      <FormInput
        name="label"
        label={_("full_name")}
        labelClassName={styles.form_collab_label}
        className={styles.form_collab_input}
        handleChange={handleChangeInput}
        value={labelValue}
        required
      />
      <Button
        disabled={disabled}
        onClick={handleSaveCollaborator}
        className={disabled ? styles.btn_add_collab : ""}
      >
        {_("add_to_my_list")}
      </Button>
    </div>
  );
};

export default CollaboratorFrom;
