import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import classNames from "classnames";

import { deleteService, getServices } from "../../api";
import _ from "../../i18n";
import ServiceContent from "../common/ServiceContent";
import styles from "./ManageServices.module.scss";
import Button from "../common/Button";
import ServicePopOver from "../Settings/ServicePopOver";

const ManageServices = () => {
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [openPopOver, setOpenPopOver] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [servicesList, setServicesList] = useState([]);

  let { isLoading } = useQuery(["listServices", token], async () => {
    if (token) {
      try {
        const response = await getServices(token, 9, null, true);
        if (response.data.data) {
          setServicesList(response.data.data);
        }
      } catch (e) {
        return null;
      }
    }
  });

  const onConfirmServiceDelete = async () => {
    await deleteService(auth.token, currentService.id);
    setServicesList(
      servicesList.filter((item) => item.id !== currentService.id)
    );
    setCurrentService(null);
    setDeleteModalOpen(false);
  };

  return (
    <div className={styles.servicesPage}>
      <div className={styles.header}>
        <div>
          <h1>{_("services_biblio")}</h1>
          <span>{_("manage_public_services")}</span>
        </div>
        <Button
          className={styles.addMedia}
          onClick={() => setOpenPopOver(true)}
        >
          {_("add_new_service")}
        </Button>
      </div>
      {isLoading && <div className="lmask"></div>}
      {!isLoading && servicesList.length === 0 && (
        <div
          style={{
            textAlign: "center",
            maxWidth: "600px",
            margin: "0 auto 60px",
            color: "#2C394B",
            fontSize: "2rem",
          }}
        >
          <img alt="not found" src="/img/notFound.svg" />
          <div>{_("nothing_found")}</div>
        </div>
      )}
      {servicesList.length > 0 && (
        <div className={classNames(styles.list, "grid-x")}>
          {servicesList.map((service) => (
            <div
              className={classNames(
                styles.cardCont,
                "cell small-12 medium-6 large-3"
              )}
            >
              <ServiceContent
                openModal={() => setOpenPopOver(true)}
                setCurrentService={setCurrentService}
                service={service}
                lng={lng}
                openConfirmation={() => setDeleteModalOpen(true)}
                allowDelete={true}
                allowEdit={true}
                home={true}
              />
            </div>
          ))}
        </div>
      )}

      <ModalConfirm
        type="delete"
        isOpen={deleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={onConfirmServiceDelete}
        title={_("delete_confirm")}
        text={_("text_delete_service")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
      {openPopOver && (
        <ServicePopOver
          closePopOver={() => {
            setOpenPopOver(false);
            setCurrentService(null);
          }}
          //   community={9}
          token={auth.token}
          servicesList={servicesList}
          setServicesList={setServicesList}
          currentService={currentService}
          isPrivate={false}
          paramLng={lng}
          positionStyle={styles.positionPopOver}
        />
      )}
    </div>
  );
};

export default ManageServices;
