import React, { useState } from "react";
import EditContainer from "../EditContainer";
import MediaSlider from "../MediaSlider";
import Clients from "../Clients";
import About from "../About";
import Services from "../Services";
import Stats from "../Stats";
import Specialties from "../Specialties";
import Testimonials from "../Testimonials";
import Faq from "../Faq";
import News from "../News";
import Team from "../Team";
import Contact from "../Contact";
import { useSelector } from "react-redux";
import History from "../History";
import { Reorder } from "framer-motion";
import RecorderEditContainer from "../ReorderEditContainer";
import { arrayMerge } from "../../../../../../../../utils";
import Newsletter from "../Newsletter";
import Packs from "../Packs";

const HomePage = ({
  currentTab,
  portals,
  memberMedias,
  partners,
  handlePortalBlocChange,
  handlePortalBlocReorder,
  setEditSection,
  editSection,
  collaborators,
  faqs,
  services,
  aboutImage,
  numbersList,
  numbersImage,
  specialties,
  folderSpecialties,
  testimonials,
}) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const page = "homePage";
  const sections = {
    partners: {
      name: "partners",
      key: "partners",
      data: portals.public.config.partners,
      component: (
        <Clients
          list={partners}
          data={portals.public.config.partners}
          version={"v3"}
        />
      ),
    },
    about: {
      name: "about",
      key: "about",
      data: portals.public.config.about,
      component: (
        <About image={aboutImage} data={portals.public.config.about} />
      ),
    },
    services: {
      name: "services",
      key: "services",
      data: portals.public.config.services,
      component: (
        <Services
          data={portals.public.config.services}
          services={services.filter((el) => el.show).slice(0, 3)}
        />
      ),
    },
    numbers: {
      name: "numbers",
      key: "numbers",
      data: portals.public.config.numbers,
      component: (
        <Stats
          data={portals.public.config.numbers}
          list={numbersList}
          image={numbersImage}
          version={"v3"}
        />
      ),
    },

    specialty: {
      name: "specialty",
      key: "specialty",
      data: portals.public.config.specialty,
      component: (
        <Specialties
          data={portals.public.config.specialty}
          types={specialties ? specialties : []}
          folderData={folderSpecialties}
        />
      ),
    },
    packs: {
      name: "packs",
      key: "packs",
      data: portals.public.config.packs,
      component: <Packs publicPortal={portals.public} />,
    },
    testimonials: {
      name: "testimonials",
      key: "testimonials",
      data: portals.public.config?.testimonials,
      component: (
        <Testimonials
          data={portals.public.config.testimonials}
          testimonials={testimonials.filter((el) => el.show).slice(0, 3)}
        />
      ),
    },
    faqs: {
      name: "faqs",
      key: "faqs",
      data: portals.public.config?.faqs,
      component: (
        <Faq
          data={portals.public.config.faqs}
          faqs={faqs.filter((el) => el.show).slice(0, 3)}
        />
      ),
    },
    news: {
      name: "news",
      key: "news",
      data: portals.public.config?.news,
      component: <News data={portals.public.config.news} version={"v3"} />,
    },
    team: {
      name: "team",
      key: "team",
      data: portals.public.config?.team,
      component: (
        <Team
          data={portals.public.config.team}
          team={collaborators.filter((el) => el.enableAvatar).slice(0, 8)}
        />
      ),
    },
    newsletter: {
      name: "newsletter",
      key: "newsletter",
      data: portals.public.config.newsletter,
      component: <Newsletter data={portals.public.config.newsletter} />,
    },
    contact: {
      name: "contact",
      key: "contact",
      data: portals.public.config.contact,
      component: (
        <Contact
          navCommunity={fiduciary}
          publicPortal={portals.public}
          version={"v3"}
        />
      ),
    },
  };

  const orderedKeys = arrayMerge(
    portals.public.config?.[page]?.order,
    Object.keys(sections)
  );

  const [items, setItems] = useState(orderedKeys);
  const setOrder = (updatedOrder) => {
    setItems(updatedOrder);
    handlePortalBlocReorder(updatedOrder, page + "|order");
  };

  return currentTab === "HOME" ? (
    <>
      <EditContainer
        isVisible={portals.public.config.memberMedia.activated}
        setVisibility={() => {
          handlePortalBlocChange(
            !portals.public.config.memberMedia.activated,
            "memberMedia"
          );
        }}
        edit={() => setEditSection("memberMedia")}
      >
        <MediaSlider images={memberMedias.slice(0, 5)} />
      </EditContainer>
      <Reorder.Group axis="y" values={items} onReorder={setOrder}>
        {items.map((item) => (
          <RecorderEditContainer
            key={item}
            item={item}
            isVisible={
              sections[item].alwaysVisible
                ? true
                : portals.public.config?.[page]?.[item]
            }
            setVisibility={
              sections[item].alwaysVisible
                ? null
                : () =>
                    handlePortalBlocChange(
                      !portals.public.config?.[page]?.[item],
                      page + "|" + sections[item].key
                    )
            }
            edit={() => setEditSection(sections[item].name)}
          >
            {sections[item].component}
          </RecorderEditContainer>
        ))}
      </Reorder.Group>
      {/* <EditContainer
        isVisible={portals.public.config.partners.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.partners.activated,
            "partners"
          )
        }
        edit={() => setEditSection("partners")}
      >
        <Clients
          list={partners}
          data={portals.public.config.partners}
          version={"v3"}
        />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.about.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.about.activated,
            "about"
          )
        }
        edit={() => setEditSection("about")}
      >
        <About image={aboutImage} data={portals.public.config.about} />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.services.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.services.activated,
            "services"
          )
        }
        edit={() => {
          setEditSection("services");
        }}
      >
        <Services
          data={portals.public.config.services}
          services={services.filter((el) => el.show).slice(0, 3)}
        />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.numbers.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.numbers.activated,
            "numbers"
          )
        }
        edit={() => setEditSection("numbers")}
      >
        <Stats
          data={portals.public.config.numbers}
          list={numbersList}
          image={numbersImage}
          version={"v3"}
        />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.specialty.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.specialty.activated,
            "specialty"
          )
        }
        edit={() => setEditSection("specialty")}
      >
        <Specialties
          data={portals.public.config.specialty}
          types={specialties ? specialties : []}
          folderData={folderSpecialties}
        />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.testimonials.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.testimonials.activated,
            "testimonials"
          )
        }
        edit={() => setEditSection("testimonials")}
      >
        <Testimonials
          data={portals.public.config.testimonials}
          testimonials={testimonials.filter((el) => el.show).slice(0, 2)}
        />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.faqs.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.faqs.activated, "faqs")
        }
        edit={() => setEditSection("faqs")}
      >
        <Faq
          data={portals.public.config.faqs}
          faqs={faqs.filter((el) => el.show).slice(0, 3)}
        />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.news.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.news.activated, "news")
        }
        edit={() => setEditSection("news")}
      >
        <News data={portals.public.config.news} version={"v3"} />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.team.activated}
        setVisibility={() =>
          handlePortalBlocChange(!portals.public.config.team.activated, "team")
        }
        edit={() => setEditSection("team")}
      >
        <Team
          data={portals.public.config.team}
          team={collaborators.filter((el) => el.enableAvatar).slice(0, 8)}
        />
      </EditContainer> */}
      {/* <EditContainer
        isVisible={portals.public.config.contact.activated}
        setVisibility={() =>
          handlePortalBlocChange(
            !portals.public.config.contact.activated,
            "contact"
          )
        }
        edit={() => setEditSection("contact")}
      >
        <Contact
          navCommunity={fiduciary}
          publicPortal={portals.public}
          version={"v3"}
          onEdit={editSection === "contact"}
        />
      </EditContainer> */}
      {/* {renderHomeModals()} */}
    </>
  ) : null;
};

export default HomePage;
