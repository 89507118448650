import React from "react";

export const IconEye = ({ size = 51, black }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8215 7.38398C14.988 6.02089 13.8674 4.92566 12.4598 4.0983C11.052 3.27092 9.56549 2.85718 8 2.85718C6.43451 2.85718 4.94793 3.27076 3.54018 4.0983C2.1324 4.92566 1.01183 6.02089 0.178505 7.38398C0.0595122 7.59229 0 7.79766 0 8.00009C0 8.20252 0.0595122 8.40789 0.178505 8.61613C1.01183 9.97928 2.1324 11.0746 3.54018 11.9018C4.94793 12.7293 6.43451 13.1427 8 13.1427C9.56552 13.1427 11.052 12.7306 12.4598 11.9063C13.8674 11.0819 14.9882 9.98513 15.8215 8.61613C15.9405 8.40789 16 8.20261 16 8.00009C16 7.79766 15.9405 7.59229 15.8215 7.38398ZM6.08469 4.94198C6.6174 4.40928 7.25577 4.14284 7.99991 4.14284C8.1189 4.14284 8.22016 4.18462 8.30353 4.2679C8.38678 4.35121 8.42863 4.45244 8.42863 4.57146C8.42863 4.69058 8.38678 4.79175 8.30372 4.87493C8.22047 4.95834 8.11915 4.99999 8.00012 4.99999C7.48825 4.99999 7.05062 5.18162 6.68757 5.54464C6.3244 5.90787 6.14292 6.34532 6.14292 6.85719C6.14292 6.97637 6.10114 7.07748 6.01786 7.16085C5.93446 7.24429 5.83332 7.28591 5.7143 7.28591C5.59518 7.28591 5.49401 7.24419 5.41064 7.16085C5.32724 7.07748 5.28558 6.97625 5.28558 6.85719C5.28558 6.11321 5.55202 5.47471 6.08469 4.94198ZM11.8795 10.9154C10.686 11.6384 9.39289 12.0002 8 12.0002C6.60714 12.0002 5.31401 11.6387 4.12057 10.9154C2.92714 10.1922 1.93457 9.22042 1.14293 8.00009C2.04768 6.59528 3.18161 5.54461 4.54469 4.84832C4.18162 5.46727 4.00002 6.13698 4.00002 6.85716C4.00002 7.95847 4.39146 8.90018 5.17412 9.68304C5.95679 10.4659 6.89885 10.8571 8 10.8571C9.10124 10.8571 10.043 10.4657 10.8259 9.68304C11.6087 8.9004 12 7.95847 12 6.85716C12 6.13698 11.8184 5.46718 11.4553 4.84832C12.8183 5.54461 13.9523 6.59538 14.857 8.00009C14.0655 9.22042 13.073 10.1922 11.8795 10.9154Z"
      fill={black ? "#000" : "#fff"}
    />
  </svg>
);
