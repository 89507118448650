import { Avatar } from "tamtam-components";

import { getDateLabel, purifyString } from "../../utils";
import _ from "../../i18n";

import styles from "./FiduciaryModal.module.scss";

const HISTORY_TEXT = {
  STATUS_VALIDATED: "Status validé",
  STATUS_PENDING_AFTER_VALIDATED: "Status remis à en attente après validation",
  STATUS_REJECTED: "Status rejeté",
  STATUS_DELETED: "Supprimé",
};

const HistoryItem = ({ item }) => {
  const renderHistoryItem = (st) => {
    let txt = item.msg;
    if (HISTORY_TEXT[item.msg]) {
      txt = HISTORY_TEXT[item.msg];
    }
    const help = purifyString(txt)
      .replace(/&nbsp;/g, " ")
      .trim();
    if (help.length > 150) {
      return (
        <span className="history-old-value">{help.substr(0, 150) + "..."}</span>
      );
    }
    return <span className="history-old-value">{help}</span>;
  };

  const renderUpdatedBy = () => {
    if (!item.creator) {
      return null;
    }

    return (
      <div className={styles.history_updatedby}>
        <Avatar
          firstName={item.creator.firstName}
          lastName={item.creator.lastName}
        />
      </div>
    );
  };

  const dateLabel = getDateLabel(item.date).split(",");
  return (
    <div className={`${styles.history_item} grid-x`}>
      <div className="small-6">
        <div className={styles.history_box}>
          {renderUpdatedBy()}
          <ul className={styles.history_box_content}>
            <li>{renderHistoryItem()}</li>
          </ul>
          <div className={styles.history_box_circle}></div>
          <div className={styles.history_box_date}>
            <span>{dateLabel[0]}</span>
            <span>{dateLabel[1]}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryItem;
