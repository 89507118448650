import axios from "axios";

import { TTP_API_URL } from "../config";

export const savePack = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("name", data.name);
  formData.append("descriptionFr", data.description.fr);
  formData.append("descriptionNl", data.description.nl);
  formData.append("descriptionEn", data.description.en);
  formData.append("status", data.status);
  formData.append("hasSlider", data.hasSlider);
  formData.append("sliderLabelFr", data.sliderLabelFr);
  formData.append("sliderLabelNl", data.sliderLabelNl);
  formData.append("sliderLabelEn", data.sliderLabelEn);
  formData.append("sliderTextFr", data.sliderTextFr);
  formData.append("sliderTextNl", data.sliderTextNl);
  formData.append("sliderTextEn", data.sliderTextEn);
  formData.append("sliderMin", data.sliderMin);
  formData.append("sliderMax", data.sliderMax);
  formData.append("sliderStep", data.sliderStep);
  formData.append("languages", data.languages);

  if (data.id) {
    formData.append("id", data.id);
  }
  return await axios.post(requestUrl, formData);
};

export const savePackSettings = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack/save-settings`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.uaPack);

  if (data.formulaApps.length > 0) {
    data.formulaApps.forEach((item, i) => {
      if (item.id) {
        formData.append(`uaPackFormulaApp[${i}][id]`, item.id);
      }
      formData.append(
        `uaPackFormulaApp[${i}][uaPackFormula]`,
        item.uaPackFormula
      );
      formData.append(
        `uaPackFormulaApp[${i}][uaPackApp]`,
        item.uaPackApp.appId
      );
      formData.append(`uaPackFormulaApp[${i}][type]`, item.uaPackApp.type);
      formData.append(
        `uaPackFormulaApp[${i}][value]`,
        item.uaPackApp.value ?? ""
      );
      formData.append(
        `uaPackFormulaApp[${i}][suffixFr]`,
        item.uaPackApp.suffixFr ?? ""
      );
      formData.append(
        `uaPackFormulaApp[${i}][suffixNl]`,
        item.uaPackApp.suffixNl ?? ""
      );
      formData.append(
        `uaPackFormulaApp[${i}][suffixEn]`,
        item.uaPackApp.suffixEn ?? ""
      );
      if (item.uaPackApp.options) {
        item.uaPackApp.options.forEach((option, idx) => {
          if (option.id) {
            formData.append(
              `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][id]`,
              option.id
            );
          }
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][value]`,
            option.value
          );
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][suffixFr]`,
            option.suffixFr
          );
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][suffixNl]`,
            option.suffixNl
          );
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][suffixEn]`,
            option.suffixEn
          );
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][monthPrice]`,
            option.monthPrice
          );
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][annualPrice]`,
            option.annualPrice
          );
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][monthMemberPrice]`,
            option.monthMemberPrice
          );
          formData.append(
            `uaPackFormulaApp[${i}][uaPackSelectOption][${idx}][annualMemberPrice]`,
            option.annualMemberPrice
          );
        });
      }
    });
  }

  return await axios.post(requestUrl, formData);
};

export const getApplications = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-application`;

  const sort = [
    {
      property: "nameFr",
      dir: "asc",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      sort: JSON.stringify(sort),
      limit: 200,
    },
  });
};

export const getPacks = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack`;

  // const sort = [
  //   {
  //     property: "nbFrom",
  //     dir: "desc",
  //   },
  // ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      // sort: JSON.stringify(sort),
      limit: 200,
    },
  });
};

export const getPack = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack`;

  const filter = [
    {
      property: "id",
      value: id,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: "*,formulas,appsList",
      filter: JSON.stringify(filter),
    },
  });
};

export const deletePack = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const savePackFormula = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack-formula`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("name", data.name);
  formData.append("monthPrice", data.monthPrice);
  formData.append("monthMemberPrice", data.monthMemberPrice);
  formData.append("annualPrice", data.annualPrice);
  formData.append("annualMemberPrice", data.annualMemberPrice);
  formData.append("uaPack", data.uaPack);
  formData.append("status", data.status);

  formData.append("monthStepPrice", data.monthStepPrice);
  formData.append("monthMemberStepPrice", data.monthMemberStepPrice);
  formData.append("annualStepPrice", data.annualStepPrice);
  formData.append("annualMemberStepPrice", data.annualMemberStepPrice);

  if (data.id) {
    formData.append("id", data.id);
  }
  return await axios.post(requestUrl, formData);
};

export const deletePackFormula = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack-formula/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const savePackApp = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack-app`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("nameFr", data.name.fr);
  formData.append("nameNl", data.name.nl);
  formData.append("nameEn", data.name.en);
  formData.append("descriptionFr", data.description.fr);
  formData.append("descriptionNl", data.description.nl);
  formData.append("descriptionEn", data.description.en);
  formData.append("uaPack", data.uaPack);
  formData.append("type", data.type);
  if (data.uaApplication) {
    formData.append("uaApplication", data.uaApplication);
  }
  if (data.id) {
    formData.append("id", data.id);
  }
  if (data.parent) {
    formData.append("parent", data.parent);
  }
  return await axios.post(requestUrl, formData);
};

export const deletePackApp = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack-app/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const saveApplication = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-application`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", data.id);
  formData.append("isUsed", data.isUsed);

  return await axios.post(requestUrl, formData);
};

export const getHomePacks = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-pack/home`;

  const sort = [
    {
      property: "order",
      dir: "asc",
    },
  ];
  const filter = [
    {
      property: "status",
      value: "ENABLED",
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: "*,formulas,appsList",
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
      nolimit: 1,
    },
  });
};
