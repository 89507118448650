import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useQuery } from "react-query";

import styles from "./News.module.scss";
import {
  getBlogFFFArticles,
  getBlogOrgArticles,
  getNewsLetterArticles,
  getPublicArticlesPortal,
} from "../../../../../../../../api";
import _ from "../../../../../../../../i18n";
import ArticleList from "./ArticleList";

const fetchNlArticlesByOrg = async ({
  period,
  org,
  token,
  monthValue,
  year,
  formattedString,
  maxArticles,
  articles,
}) => {
  try {
    const res = await getNewsLetterArticles({
      token,
      communityId: org,
      month: monthValue,
      year,
      currentDate: formattedString,
      period,
    });

    articles.push(
      ...res.data.data.articles.filter(
        (el) => !articles.map((art) => art.id).includes(el.id)
      )
    );
  } catch (error) {
    // console.error(error);
  }
  if (articles.length < maxArticles && period !== "MONTH") {
    // Switch to the next period value
    let nextPeriod;
    if (period === "WEEK") {
      nextPeriod = "TWO_WEEKS";
    } else if (period === "TWO_WEEKS") {
      nextPeriod = "MONTH";
    }

    // Recursive call with updated period
    return await fetchNlArticlesByOrg({
      period: nextPeriod,
      org,
      token,
      monthValue,
      year,
      formattedString,
      maxArticles,
      articles,
    });
  }

  return articles;
};

const fetchPublicArticlesByOrg = async ({
  token,
  communityId,
  limit,
  language,
  articles,
}) => {
  try {
    const res = await getPublicArticlesPortal({
      token,
      communityId,
      limit,
      language,
      notIds: articles.length > 0 ? articles.map((el) => el.id) : null,
    });
    articles.push(...res.data.data);
  } catch (error) {}
  return articles;
};

const fetchArticles = async ({ navCommunity, token, lng, version }) => {
  let articles = [];
  const maxArticles = version === "v1" ? 2 : 3;
  const currentDate = new Date();
  const monthValue = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, "0");
  const day = String(currentDate.getDate()).padStart(2, "0");
  const hours = String(currentDate.getHours()).padStart(2, "0");
  const minutes = String(currentDate.getMinutes()).padStart(2, "0");
  const seconds = String(currentDate.getSeconds()).padStart(2, "0");
  const sixMonthDate = moment()
    .subtract(6, "months")
    .format("YYYY-MM-DD HH:mm:ss");

  const formattedString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  // fetch the programmed newsletter articles of the organization starting by week then two weeks then month
  if (version === "v1") {
    articles = await fetchNlArticlesByOrg({
      period: "WEEK",
      org: 9,
      token,
      monthValue,
      year,
      formattedString,
      maxArticles,
      articles,
    });
    if (articles.length < maxArticles) {
      // fetch fff public articles
      articles = await fetchPublicArticlesByOrg({
        token,
        communityId: 9,
        limit: maxArticles - articles.length,
        language: lng,
        articles,
      });
    }
  }

  if (version === "v2") {
    articles = await fetchNlArticlesByOrg({
      period: "WEEK",
      org: navCommunity.id,
      token,
      monthValue,
      year,
      formattedString,
      maxArticles,
      articles,
    });

    if (articles.length < maxArticles) {
      // fetch fff newsletter articles
      articles = await fetchNlArticlesByOrg({
        period: "WEEK",
        org: 9,
        token,
        monthValue,
        year,
        formattedString,
        maxArticles,
        articles,
      });
    }

    if (articles.length < maxArticles) {
      // fetch public articles of the organization
      articles = await fetchPublicArticlesByOrg({
        token,
        communityId: navCommunity.id,
        limit: maxArticles - articles.length,
        language: lng,
        articles,
      });
    }

    if (articles.length < maxArticles) {
      // fetch fff public articles
      articles = await fetchPublicArticlesByOrg({
        token,
        communityId: 9,
        limit: maxArticles - articles.length,
        language: lng,
        articles,
      });
    }
  }

  if (version === "v3") {
    try {
      let tmpRes = await getBlogOrgArticles({
        token,
        communityId: navCommunity.id,
        date: sixMonthDate,
        language: lng,
        limit: maxArticles,
      });
      articles = [...articles, ...tmpRes.data.data];
    } catch (error) {
      console.error(error);
    }

    if (articles.length < maxArticles) {
      try {
        let tmpRes = await getBlogFFFArticles({
          token,
          communityId: navCommunity.id,
          language: lng,
          limit: maxArticles - articles.length,
        });
        articles = [...articles, ...tmpRes.data.data];
      } catch (error) {
        console.error(error);
      }
    }
  }

  return articles.slice(0, maxArticles);
};

const News = ({ data, version }) => {
  const lng = useSelector((state) => state.params.language);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  const { data: articles } = useQuery(
    ["getPublicPortalArticles", token, fiduciary, lng, version],
    async () => {
      if (token && fiduciary) {
        const list = await fetchArticles({
          navCommunity: fiduciary,
          token,
          lng,
          version,
        });
        return list;
      }
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.section_title}>{_("News")}</div>
      <div className={styles.title}>{data[titleAttr]}</div>
      <div className={styles.description}>{data[descriptionAttr]}</div>
      <ArticleList articles={articles} lng={lng} />
    </div>
  );
};

export default News;
