import classNames from "classnames";
import React, { useEffect } from "react";
import styles from "./style.module.scss";
import { TTP_API_URL } from "../../../../../../../../../config";
import { useSelector } from "react-redux";
import ArrowDownIcon from "../../icons/ArrowDownIcon";

const PageTabs = ({ tabs, currentTab, setCurrentTab }) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  return (
    <div className={styles.nav}>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${
            fiduciary?.avatarUrl
              ? fiduciary?.avatarUrl
              : fiduciary?.avatarWebPath
              ? TTP_API_URL + "/" + fiduciary.avatarWebPath
              : ""
          })`,
        }}
      ></div>
      <ul className={classNames(styles.wraper, "tabs")}>
        {Object.keys(tabs).map((key) =>
          tabs[key].name === "SERVICES" && tabs[key].items?.length > 0 ? (
            <li
              key={tabs[key].name}
              className={
                currentTab.slice(0, -1) === tabs[key].name.slice(0, -1)
                  ? classNames(styles.active, styles.menu)
                  : styles.menu
              }
            >
              <div className={styles.group}>
                <span>{tabs[key].label}</span>
                <ArrowDownIcon />
              </div>
              <ul className={classNames(styles.subMenu)}>
                {tabs[key].items.map((item) => (
                  <li
                    key={item.name}
                    className={
                      currentTab === item.name
                        ? classNames(styles.active, styles.menu)
                        : styles.menu
                    }
                  >
                    <span onClick={() => setCurrentTab(item.name)}>
                      {item.label}
                    </span>
                  </li>
                ))}
              </ul>
            </li>
          ) : tabs[key].name !== "SERVICES" ? (
            <li
              key={tabs[key].name}
              className={
                currentTab === tabs[key].name
                  ? classNames(styles.active, styles.item)
                  : styles.item
              }
            >
              <span onClick={() => setCurrentTab(tabs[key].name)}>
                {tabs[key].label}
              </span>
            </li>
          ) : null
        )}
      </ul>
    </div>
  );
};

export default PageTabs;
