import classNames from "classnames";
import React from "react";
import styles from "./Testimonial.module.scss";
import { IconEye } from "../../common/icons/IconEye";
import { IconPen } from "../../common/icons/IconPen";
import { IconTrash } from "../../common/icons/IconTrash";
import { useSelector } from "react-redux";

const Testimonial = ({ testimonial, onDelete, onEdit, onHide }) => {
  const lng = useSelector((state) => state.params.language);
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <div
      className={classNames(
        styles.testimonial_card,
        !testimonial.show ? styles.testimonial_card_hide : ""
      )}
    >
      <div className={styles.actions}>
        <div
          className={classNames(
            styles.icon,
            !testimonial.show ? styles.icon_hide : ""
          )}
          onClick={() => onHide(testimonial)}
        >
          <IconEye black />
        </div>
        <div className={styles.icon} onClick={() => onEdit(testimonial)}>
          <IconPen />
        </div>
        <div className={styles.icon} onClick={() => onDelete(testimonial)}>
          <IconTrash />
        </div>
      </div>
      <div className={styles.title}>{testimonial[descriptionAttr]}</div>
      <div className={styles.author}>{testimonial.author}</div>
      <div className={styles.headline}>{testimonial.headline}</div>
    </div>
  );
};

export default Testimonial;
