import { useEffect, useState } from "react";

import _ from "../../../../i18n";
import Loader from "../../../common/Loader";
import Button from "../../../common/Button";
import Tabs from "../../../common/Tabs";

import styles from "./ModalDelegationType.module.scss";
import { useSelector } from "react-redux";
import { saveDelegationType } from "../../../../api";
import { toast } from "react-toastify";

const Form = ({ currentItem, refetch, setIsFormTabActive }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState({});
  const INIT_FORM = {
    id: null,
    titleFr: "",
    titleEn: "",
    titleNl: "",
    abbreviationFr: "",
    abbreviationEn: "",
    abbreviationNl: "",
    descriptionFr: "",
    descriptionEn: "",
    descriptionNl: "",
    isNlSender: false,
  };
  const [form, setForm] = useState(INIT_FORM);
  let isSynchro = currentItem !== null ? currentItem.isSynchro || false : false;

  useEffect(() => {
    setForm(
      currentItem === null
        ? INIT_FORM
        : {
          id: currentItem.id,
          titleFr: currentItem.titleFr,
          titleEn: currentItem.titleEn,
          titleNl: currentItem.titleNl,
          abbreviationFr: currentItem.abbreviationFr,
          abbreviationEn: currentItem.abbreviationEn,
          abbreviationNl: currentItem.abbreviationNl,
          descriptionFr: currentItem.descriptionFr,
          descriptionEn: currentItem.descriptionEn,
          descriptionNl: currentItem.descriptionNl,
          isNlSender: currentItem.isNlSender || false,
        },
    );
  }, [currentItem]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const isFormValid = () => {
    let messages = {};
    const REQUIRED_FIELD = _("REQUIRED_FIELD"); //"Ce champ est obligatoire.";
    const TOO_SHORT = _("TOO_SHORT"); //"Ce champ doit contenir au moins 2 caractères.";
    const TOO_LONG = _("TOO_LONG"); //"Ce champ ne peut pas contenir plus de 10 caractères.";
    for (let key in form) {
      if (
        key !== "id" &&
        key !== "descriptionEn" &&
        key !== "descriptionNl" &&
        key !== "descriptionFr" &&
        key !== "isNlSender"
      ) {
        messages[key] = form[key].trim().length < 2 ? TOO_SHORT : "";
        if (
          (key === "abbreviationEn" ||
            key === "abbreviationNl" ||
            key === "abbreviationFr") && !isSynchro
        ) {
          messages[key] = form[key].trim().length > 9 ? TOO_LONG : "";
        }

        if (form[key].trim().length === 0) {
          messages[key] = REQUIRED_FIELD;
        }
      }
    }

    setErrors(messages);

    return (
      messages.titleFr.length === 0 &&
      messages.titleEn.length === 0 &&
      messages.titleNl.length === 0 &&
      messages.abbreviationFr.length === 0 &&
      messages.abbreviationEn.length === 0 &&
      messages.abbreviationNl.length === 0
    );
  };

  const save = () => {
    if (isFormValid()) {
      setIsSaving(true);
      const payload = {
        organization: navCommunity.id,
        ...form,
        isNlSender: form.isNlSender ? 1 : 0,
      };
      saveDelegationType(token, payload)
        .then((resp) => {
          if (resp.data.result === "OK") {
            toast.success(_("successfully_saved"));
            refetch();
            setIsFormTabActive(false);
          } else {
            toast.error(_("error"));
          }
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
        })
        .finally(() => {
          setIsSaving(false);
        });
    }
  };

  return (
    <>
      <Tabs theme="wtheme">
        <div
          key={`langtabfr`}
          label={_("french")}
          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
        >
          <div>
            <div>
              <label className="ttp-label">{_(`title`) + " FR"}</label>
              <input
                type="text"
                className="ttp-input"
                autoComplete="off"
                value={form.titleFr}
                name="titleFr"
                onChange={handleChange}
                disabled={isSynchro}
              />
              <span className={styles.error}>{errors.titleFr}</span>
            </div>
          </div>
          <div>
            <div>
              <label className="ttp-label">{_(`abbreviation`) + " FR"}</label>
              <input
                type="text"
                className="ttp-input"
                autoComplete="off"
                value={form.abbreviationFr}
                name="abbreviationFr"
                onChange={handleChange}
                disabled={isSynchro}
              />
              <span className={styles.error}>{errors.abbreviationFr}</span>
            </div>
          </div>
          <div>
            <label className="ttp-label">{_(`description`) + " FR"}</label>
            <textarea
              rows="4"
              className="ttp-input"
              value={form.descriptionFr}
              name="descriptionFr"
              onChange={handleChange}
              disabled={isSynchro}
            />
            <span className={styles.error}>{errors.descriptionFr}</span>
          </div>
          <div>
            <label className="ttp-label">
              <input
                type="checkbox"
                name="isNlSender"
                checked={form.isNlSender}
                onChange={handleChange}
                style={{ marginRight: "5px" }}
              />
              {" " + _(`Mark_as_sender`)}
            </label>
          </div>
        </div>

        <div
          key={`langtabnl`}
          label={_("dutch")}
          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
        >
          <div>
            <div>
              <label className="ttp-label">{_(`title`) + " NL"}</label>
              <input
                type="text"
                className="ttp-input"
                autoComplete="off"
                value={form.titleNl}
                name="titleNl"
                onChange={handleChange}
                disabled={isSynchro}
              />
              <span className={styles.error}>{errors.titleNl}</span>
            </div>
          </div>
          <div>
            <div>
              <label className="ttp-label">{_(`abbreviation`) + " NL"}</label>
              <input
                type="text"
                className="ttp-input"
                autoComplete="off"
                value={form.abbreviationNl}
                name="abbreviationNl"
                onChange={handleChange}
                disabled={isSynchro}
              />
              <span className={styles.error}>{errors.abbreviationNl}</span>
            </div>
          </div>
          <div>
            <label className="ttp-label">{_(`description`) + " NL"}</label>
            <textarea
              rows="4"
              className="ttp-input"
              value={form.descriptionNl}
              name="descriptionNl"
              onChange={handleChange}
              disabled={isSynchro}
            />
            <span className={styles.error}>{errors.descriptionNl}</span>
          </div>
          <div>
            <label className="ttp-label">
              <input
                type="checkbox"
                name="isNlSender"
                checked={form.isNlSender}
                onChange={handleChange}
                style={{ marginRight: "5px" }}
              />
              {" " + _(`Mark_as_sender`)}
            </label>
          </div>
        </div>

        <div
          key={`langtaben`}
          label={_("english")}
          icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
        >
          <div>
            <div>
              <label className="ttp-label">{_(`title`) + " EN"}</label>
              <input
                type="text"
                className="ttp-input"
                autoComplete="off"
                value={form.titleEn}
                name="titleEn"
                onChange={handleChange}
                disabled={isSynchro}
              />
              <span className={styles.error}>{errors.titleEn}</span>
            </div>
          </div>
          <div>
            <div>
              <label className="ttp-label">{_(`abbreviation`) + " EN"}</label>
              <input
                type="text"
                className="ttp-input"
                autoComplete="off"
                value={form.abbreviationEn}
                name="abbreviationEn"
                onChange={handleChange}
                disabled={isSynchro}
              />
              <span className={styles.error}>{errors.abbreviationEn}</span>
            </div>
          </div>
          <div>
            <label className="ttp-label">{_(`description`) + " EN"}</label>
            <textarea
              rows="4"
              className="ttp-input"
              value={form.descriptionEn}
              name="descriptionEn"
              onChange={handleChange}
              disabled={isSynchro}
            />
            <span className={styles.error}>{errors.descriptionEn}</span>
          </div>
          <div>
            <label className="ttp-label">
              <input
                type="checkbox"
                name="isNlSender"
                checked={form.isNlSender}
                onChange={handleChange}
                style={{ marginRight: "5px" }}
              />
              {" " + _(`Mark_as_sender`)}
            </label>
          </div>
        </div>
      </Tabs>

      <div className={styles.actions}>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={save}>{_("add")}</Button>
        )}
      </div>
    </>
  );
};

export default Form;
