import React from "react";

const IconRefresh = ({ size = 20 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size}>
      <path d="M10.042 15.583q-2.334 0-3.959-1.625T4.458 10q0-2.333 1.625-3.958t3.959-1.625q1.375 0 2.52.614 1.146.615 1.896 1.636v-2.25h1.084v4.271h-4.25V7.604h2.52q-.604-.958-1.593-1.531-.99-.573-2.177-.573-1.875 0-3.188 1.312Q5.542 8.125 5.542 10q0 1.875 1.312 3.188Q8.167 14.5 10.042 14.5q1.75 0 3-1.156t1.437-2.844h1.104q-.166 2.167-1.76 3.625-1.594 1.458-3.781 1.458Z" />
    </svg>
  );
};

export default IconRefresh;
