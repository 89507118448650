import React from "react";
import Modal from "react-modal";

import styles from "./ConfigModal.module.scss";

const ConfigModal = ({ children, isOpen, close }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => close()}
      className={{
        base: styles.modalContent,
        afterOpen: styles.modalContentAfterOpen,
        beforeClose: styles.modalContentBeforeClose,
      }}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <div className={styles.close} onClick={() => close()}>
          <i className="icon-ttp-close"></i>
        </div>

        <div className={styles.body}>{children}</div>
      </div>
    </Modal>
  );
};

export default ConfigModal;
