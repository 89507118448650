import React, { useEffect, useState } from "react";

import _ from "../../../i18n";
import styles from "./Client.module.scss";
import ClientExpress from "./ClientExpress";
import ClientForm from "./ClientForm";
import ClientImport from "./ClientImport";

const ClientBox = (props) => {
  const [currentTab, setCurrentTab] = useState("MANUAL"); // EXPRESS

  useEffect(() => {
    if (props.currentItem) {
      setCurrentTab("MANUAL");
    }
  }, [props.currentItem]);

  return (
    <div className={`${styles.form}`}>
      <ul className={styles.tabs} style={{ justifyContent: "space-around" }}>
        {/* <li
          className={`${styles.tab} ${
            currentTab === "EXPRESS" && styles.active
          }`}
          onClick={() => setCurrentTab("EXPRESS")}
        >
          {_("express")}
        </li> */}
        <li
          className={`${styles.tab} ${
            currentTab === "MANUAL" && styles.active
          }`}
          onClick={() => setCurrentTab("MANUAL")}
        >
          {_("manual")}
        </li>
        <li
          className={`${styles.tab} ${
            currentTab === "IMPORT" && styles.active
          }`}
          onClick={() => setCurrentTab("IMPORT")}
        >
          {_("import_xls_file")}
        </li>
      </ul>

      {/* {currentTab === "EXPRESS" && <ClientExpress afterSave={props.afterSave} />} */}

      {currentTab === "MANUAL" && (
        <ClientForm
          afterSave={props.afterSave}
          currentItem={props.currentItem}
          setCurrentItem={props.setCurrentItem}
        />
      )}
      {currentTab === "IMPORT" && <ClientImport afterSave={props.afterSave} />}
    </div>
  );
};

export default ClientBox;
