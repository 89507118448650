import React, { useState } from "react";
import EditContainer from "../EditContainer";
import Banner from "../Banner";
import History from "../History";
import SingleService from "../SingleService";
import ServicePopOver from "../../../../../../ServicePopOver";
import _ from "../../../../../../../../i18n";
const ServicePage = ({ services, service, setServices }) => {
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [currentService, setCurrentService] = useState(null);
  const [currentSection, setCurrentSection] = useState(null);
  const languages = [
    { value: "fr", label: _("french") },
    { value: "en", label: _("english") },
    { value: "nl", label: _("dutch") },
  ];

  const afterServiceSave = (val) => {
    if (services.map((el) => el.id).includes(val.id)) {
      setServices(
        services.map((el) => {
          if (el.id === val.id) {
            return val;
          }
          return el;
        })
      );
    } else {
      setServices([val, ...services]);
    }
  };

  const onEdit = (service) => {
    setCurrentService(service);
    setOpenServiceModal(true);
  };

  return (
    <>
      <EditContainer isVisible={true} edit={() => onEdit(service)}>
        <Banner data={service.pageInfo?.banner} />
      </EditContainer>
      <EditContainer isVisible={service.pageInfo.introduction.activated}>
        <History data={service.pageInfo.introduction} hideTitle={true} />
      </EditContainer>

      {service.pageInfo.sections.map((section) => (
        <EditContainer isVisible={true}>
          <SingleService
            service={section}
            singleServiceImage={{
              fullMediaUrl: section.image ?? "img/no_image.png",
            }}
          />
        </EditContainer>
      ))}

      <ServicePopOver
        languages={languages}
        isOpen={openServiceModal}
        onClose={() => setOpenServiceModal(false)}
        afterServiceSave={afterServiceSave}
        currentElement={currentService}
        setCurrentElement={setCurrentService}
        hasPageConfig={true}
        setCurrentSection={setCurrentSection}
      />
      {/* <ModalConfirm
        type="delete"
        isOpen={currentSection}
        onCancel={() => {
          setCurrentSection(null);
        }}
        onConfirm={() => handleDeleteSection()}
        inProcess={deleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      /> */}
    </>
  );
};

export default ServicePage;
