import { useState } from "react";
// import Slider from "@mui/material/Slider";

import _ from "../../i18n";
// import { YesIcon, NoIcon } from "../common/Icons";

import Tabs from "../common/Tabs";
import styles from "./Pack.module.scss";

const Pack = ({ offerType, pack, onSelect, onEdit }) => {
  const [currentTab, setCurrentTab] = useState("standard");

  return (
    <div className={styles.pack}>
      <div className={styles.pack_top}>
        <span>
          Pack
          <br />
          {pack.name}
        </span>
        <span
          className={`${styles.pack_status} ${
            styles[pack.status.toLowerCase()]
          }`}
        >
          {_(pack.status.toLowerCase())}
        </span>
      </div>
      <div className={`${styles.pack_bottom}`}>
        <Tabs theme="wtheme">
          <div
            key={`langtabfr`}
            label={_("french")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/fr.png`}
          >
            <div className={styles.langTab}>{pack.descriptionFr}</div>
          </div>
          <div
            key={`langtabnl`}
            label={_("dutch")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/nl.png`}
          >
            <div className={styles.langTab}>{pack.descriptionNl}</div>
          </div>
          <div
            key={`langtabfr`}
            label={_("english")}
            icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/en.png`}
          >
            <div className={styles.langTab}>{pack.descriptionEn}</div>
          </div>
        </Tabs>
        <div className={styles.pack_actions}>
          <div
            className={styles.pack_btn}
            onClick={() => {
              onEdit(pack);
            }}
          >
            <i className="icon-ttp-edit" /> {_("edit")}
          </div>
          <div
            className={styles.pack_btn}
            onClick={() => {
              if (onSelect) {
                onSelect(pack);
              }
            }}
          >
            <i className="icon-ttp-eye" /> Voir le pack
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pack;
