import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import { toast } from "react-toastify";

import _ from "../../i18n";
import { saveFolderNote } from "../../api";

import Loader from "../common/Loader";
import Button from "../common/Button";
import FormInput from "../common/FormInput";
import styles from "./FiduciaryModal.module.scss";

const NoteModal = ({ modalOpen, setModalOpen, fiduciary, refreshList }) => {
  const auth = useSelector((state) => state.auth);
  const [isSaving, setIsSaving] = useState(false);

  const [note, setNote] = useState("");
  const [noteError, setNoteError] = useState("");

  const handleChange = (e) => {
    let message = e.target.value.length === 0 ? _("required_field") : "";
    setNoteError(message);
    setNote(e.target.value);
  };

  const resetForm = () => {
    setNote("");
  };

  const handleSave = async () => {
    if (!note) {
      setNoteError(_("required_field"));
      return null;
    }
    let data = fiduciary.note ? fiduciary.note : [];
    const date = new Date();

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Month is 0-based
    const day = String(date.getUTCDate()).padStart(2, "0");

    const hour = String(date.getUTCHours()).padStart(2, "0");
    const minute = String(date.getUTCMinutes()).padStart(2, "0");
    const second = String(date.getUTCSeconds()).padStart(2, "0");

    const strDate =
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;

    data.push({
      date: strDate,
      msg: note,
      creator: {
        id: auth.user.id,
        firstName: auth.user.firstName,
        lastName: auth.user.lastName,
      },
    });

    setIsSaving(true);
    saveFolderNote(auth.token, {
      note: JSON.stringify(data),
      id: fiduciary.id,
    })
      .then((resp) => {
        setIsSaving(false);
        if (resp.data.result === "OK") {
          refreshList();
          toast.success(_("successfully_added"));
          setModalOpen(false);
          resetForm();
        }
      })
      .catch((e) => {
        setIsSaving(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  return (
    <Modal
      width="50vw"
      height="50vh"
      closable={false}
      visible={modalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation();
        setModalOpen(false);
      }}
      maskClosable={false}
      destroyOnClose={true}
    >
      <div className={styles.modal_header}>{_("add_note")}</div>
      <div
        className={styles.modal_close}
        onClick={(e) => {
          e.stopPropagation();
          setModalOpen(false);
        }}
      >
        <i className="icon-ttp-close"></i>
      </div>

      <div className={styles.content}>
        <FormInput
          type="textarea"
          rows={4}
          value={note}
          label={"Votre note"}
          autoComplete="none"
          onChange={(e) => handleChange(e)}
        ></FormInput>
        {noteError && <span className="ttp-error">{noteError}</span>}
      </div>
      <div className={styles.actions}>
        <Button variant="default" onClick={() => setModalOpen(false)}>
          {_("cancel")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("save")}</Button>
        )}
      </div>
    </Modal>
  );
};

export default NoteModal;
