import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  flex: "auto",
};

const smallStyle = {
  height: " 3.25rem",
  width: " 3.25rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100%",
  backgroundColor: "#F8F9FA",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DropzoneComponent({
  multiple,
  files,
  setFiles,
  defaultImg,
  small,
  hasBorder,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  });

  const bgImg = files.length ? files[0].preview : defaultImg;

  const style = useMemo(
    () => ({
      ...(small ? smallStyle : baseStyle),
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...{
        backgroundImage: "URL(" + bgImg + ")",
        border: hasBorder || small ? "1px dashed #B2BCC6" : "none",
      },
    }),
    [isDragActive, isDragReject, isDragAccept, bgImg, small]
  );

  // clean up
  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} multiple={multiple} />
      <div
        className="icon-picture"
        style={{
          display: files.length > 0 ? "none" : "block",
          marginTop: small ? "5px" : "",
        }}
      >
        <i className="icon-ttp-picture"></i>
      </div>
    </div>
  );
}

export default DropzoneComponent;
