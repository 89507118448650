import React from "react";

const IconInfo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
      <path
        d="M9.667 13.583h.666V9h-.666ZM10 7.979q.167 0 .302-.125.136-.125.136-.292 0-.187-.126-.312-.124-.125-.312-.125-.167 0-.302.125-.136.125-.136.292 0 .187.126.312.124.125.312.125Zm0 9.188q-1.479 0-2.781-.563-1.302-.562-2.281-1.542-.98-.979-1.542-2.281-.563-1.302-.563-2.781 0-1.5.563-2.792.562-1.291 1.542-2.27.979-.98 2.281-1.542Q8.521 2.833 10 2.833q1.5 0 2.792.563 1.291.562 2.27 1.542.98.979 1.542 2.281.563 1.302.563 2.781t-.563 2.781q-.562 1.302-1.542 2.281-.979.98-2.281 1.542-1.302.563-2.781.563Zm0-.667q2.708 0 4.604-1.896T16.5 10q0-2.708-1.896-4.604T10 3.5q-2.708 0-4.604 1.896T3.5 10q0 2.708 1.896 4.604T10 16.5Zm0-6.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconInfo;
