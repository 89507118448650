import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";

import _ from "../../../i18n";
import FormInput from "../FormInput";
import Switch from "../Switch/Switch";
import Button from "../Button";
import Loader from "../Loader";

import styles from "./Sidebar.module.scss";
import { createSpecialty, createSpecialtyType } from "../../../api";
import SidebarIcon from "../SidebarIcon";
import { SELECT_STYLES } from "../../../config";

const Sidebar = ({
  isOpened,
  hideSidebar,
  parent,
  currentType,
  setCurrentType,
  createType,
  currentItem,
  specialties,
  setSpecialties,
}) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const [name, setName] = useState({ fr: "", en: "", nl: "" });
  const [isMultiSelect, setIsMultiSelect] = useState(true);
  const [hasChoices, setHasChoices] = useState(false);
  const [isActivated, setIsActivated] = useState(true);
  const [saving, setSaving] = useState(false);
  const [isOpenIconList, setIsOpenIconList] = useState(false);
  const [icon, setIcon] = useState(null);
  const [nbrFavorites, setNbrFavorites] = useState({ label: 1, value: 1 });

  useEffect(() => {
    if (currentItem) {
      setName({
        fr: currentItem.nameFr,
        en: currentItem.nameEn,
        nl: currentItem.nameNl,
      });
      if ([true, false].includes(currentItem.isActivated)) {
        setIsActivated(currentItem.isActivated);
      }
      if (currentItem.icon) {
        setIcon(currentItem.icon);
      }
      if (currentItem.nbrFavorites) {
        setNbrFavorites({
          label: currentItem.nbrFavorites,
          value: currentItem.nbrFavorites,
        });
      }
    }
  }, [currentItem]);

  const close = (e) => {
    setName({ fr: "", en: "", nl: "" });
    setIsMultiSelect(true);
    setHasChoices(false);
    setIcon(null);
    setNbrFavorites({ label: 1, value: 1 });
    hideSidebar(e);
  };

  const validate = () => {
    let errors = [];

    if (
      name.fr.trim() === "" &&
      name.en.trim() === "" &&
      name.nl.trim() === ""
    ) {
      errors.push(_("title_required"));
    }

    if (createType === "specialty" && !icon && !parent) {
      errors.push(_("icon_required"));
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      name,
    };

    if (createType === "type") {
      data.hasChoices = hasChoices;
      data.isActivated = isActivated;
    }

    if (createType === "specialty" && !parent) {
      data.icon = icon.id;
      data.type = currentType.id;
      data.isMultiSelect = isMultiSelect;
      data.nbrFavorites = nbrFavorites.value;
    }

    if (parent) {
      data.parent = parent.id;
    }

    if (currentItem) {
      data.id = currentItem.id;
    }

    setSaving(true);
    if (createType === "type") {
      try {
        const response = await createSpecialtyType({ token, data });
        if (currentItem) {
          setSpecialties([
            ...specialties.map((type) => {
              if (type.id === currentItem.id) {
                return currentItem.specialties
                  ? {
                      ...response.data.data,
                      hasChoices: response.data.data.hasChoices === 1,
                      isActivated: response.data.data.isActivated === 1,
                      specialties: currentItem.specialties,
                    }
                  : response.data.data;
              }
              return type;
            }),
          ]);
        } else {
          setCurrentType({
            ...response.data.data,
            hasChoices: response.data.data.hasChoices === 1,
            isActivated: response.data.data.isActivated === 1,
          });
          setSpecialties([
            ...(specialties ? specialties : []),
            {
              ...response.data.data,
              hasChoices: response.data.data.hasChoices === 1,
              isActivated: response.data.data.isActivated === 1,
            },
          ]);
        }
        close(e);
        setSaving(false);
      } catch (error) {
        setSaving(false);
      }
    } else {
      try {
        const response = await createSpecialty({ token, data });
        if (parent) {
          const spec = {
            ...response.data.data,
            isMultiSelect: response.data.data.isMultiSelect === 1,
          };
          if (currentItem) {
            setCurrentType({
              ...currentType,
              specialties: currentType.specialties.map((item) => {
                if (item.id === parent.id) {
                  return {
                    ...item,
                    specialties: item.specialties.map((specialty) => {
                      if (specialty.id === currentItem.id) {
                        return spec;
                      }
                      return specialty;
                    }),
                  };
                }
                return item;
              }),
            });
            setSpecialties([
              ...specialties.map((element) => {
                if (element.id === currentType.id) {
                  return {
                    ...element,
                    specialties: element.specialties.map((item) => {
                      if (item.id === parent.id) {
                        return {
                          ...item,
                          specialties: item.specialties.map((specialty) => {
                            if (specialty.id === currentItem.id) {
                              return spec;
                            }
                            return specialty;
                          }),
                        };
                      }
                      return item;
                    }),
                  };
                }
                return element;
              }),
            ]);
          } else {
            setCurrentType({
              ...currentType,
              specialties: currentType.specialties.map((item) => {
                if (item.id === parent.id) {
                  return {
                    ...item,
                    specialties: item.specialties
                      ? [...item.specialties, spec]
                      : [spec],
                  };
                }
                return item;
              }),
            });
            setSpecialties([
              ...specialties.map((element) => {
                if (element.id === currentType.id) {
                  return {
                    ...element,
                    specialties: element.specialties.map((item) => {
                      if (item.id === parent.id) {
                        return {
                          ...item,
                          specialties: item.specialties
                            ? [...item.specialties, spec]
                            : [spec],
                        };
                      }
                      return item;
                    }),
                  };
                }
                return element;
              }),
            ]);
          }
        } else {
          const spec = {
            ...response.data.data,
            isMultiSelect: response.data.data.isMultiSelect === 1,
            icon,
          };
          if (currentItem) {
            setCurrentType({
              ...currentType,
              specialties: currentType.specialties.map((specialty) => {
                if (specialty.id === currentItem.id) {
                  return { ...spec, specialties: currentItem.specialties };
                }
                return specialty;
              }),
            });
            setSpecialties([
              ...specialties.map((element) => {
                if (element.id === currentType.id) {
                  return {
                    ...element,
                    specialties: currentType.specialties.map((specialty) => {
                      if (specialty.id === currentItem.id) {
                        return {
                          ...spec,
                          specialties: currentItem.specialties,
                        };
                      }
                      return specialty;
                    }),
                  };
                }
                return element;
              }),
            ]);
          } else {
            setCurrentType({
              ...currentType,
              specialties: currentType.specialties
                ? [...currentType.specialties, spec]
                : [spec],
            });
            setSpecialties([
              ...specialties.map((element) => {
                if (element.id === currentType.id) {
                  return {
                    ...element,
                    specialties: currentType.specialties
                      ? [...currentType.specialties, spec]
                      : [spec],
                  };
                }
                return element;
              }),
            ]);
          }
        }

        close(e);
        setSaving(false);
      } catch (error) {
        setSaving(false);
      }
    }
  };

  return (
    <>
      <div
        className={`${styles.mask} ${isOpened ? "" : styles.hide}`}
        onClick={(e) => close(e)}
      ></div>
      <div className={`${styles.sidebar} ${isOpened && styles.display}`}>
        <div onClick={(e) => close(e)} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>
        <div className={styles.title}>
          {createType === "type"
            ? _("create_specialty_type")
            : parent
            ? _("create_specialty")
            : _("create_specialty_bloc")}
        </div>
        <div className={styles.form}>
          {createType === "specialty" && !parent && (
            <div className={styles.selectIconArea}>
              <div
                id="select-icon"
                className={`${styles.selectIcon} ${
                  icon ? "icon-selected" : ""
                }`}
                onClick={() => setIsOpenIconList(true)}
              >
                {icon ? (
                  <div className="icon-area">
                    <i className={`icon webtool-${icon.label}`} />
                    <p>{icon.label}</p>
                  </div>
                ) : null}
                <span className={icon ? "hide" : ""}>{_("select_icon")}</span>
              </div>
            </div>
          )}
          <FormInput
            name="name_fr"
            value={name.fr}
            label={_("name_fr")}
            autoComplete="none"
            onChange={(e) => {
              setName({
                ...name,
                fr: e.target.value,
              });
            }}
          ></FormInput>
          <FormInput
            name="name_en"
            value={name.en}
            label={_("name_en")}
            autoComplete="none"
            onChange={(e) => {
              setName({
                ...name,
                en: e.target.value,
              });
            }}
          ></FormInput>
          <FormInput
            name="name_nl"
            value={name.nl}
            label={_("name_nl")}
            autoComplete="none"
            onChange={(e) => {
              setName({
                ...name,
                nl: e.target.value,
              });
            }}
          ></FormInput>
          {createType === "type" && !currentItem && (
            <div className={styles.isMulti}>
              <label> {_("has_choices")} </label>
              <Switch
                isChecked={hasChoices}
                onChange={(e) => setHasChoices(e)}
              />
            </div>
          )}
          {createType === "type" && currentItem && (
            <div className={styles.isMulti}>
              <label> {_("isActivated")} </label>
              <Switch
                isChecked={isActivated}
                onChange={(e) => setIsActivated(e)}
              />
            </div>
          )}
          {createType === "specialty" && !currentItem && !parent && (
            <>
              <div className={styles.isMulti}>
                <label> {_("multiselect")} </label>
                <Switch
                  isChecked={isMultiSelect}
                  onChange={(e) => setIsMultiSelect(e)}
                />
              </div>
            </>
          )}
          {isMultiSelect && createType === "specialty" && !parent && (
            <div className={styles.isMulti}>
              <label> {_("number_favorites_allowed")} </label>
              <Select
                menuPlacement="top"
                styles={SELECT_STYLES}
                options={[...Array(10).keys()].map((el) => {
                  return { label: el + 1, value: el + 1 };
                })}
                isSearchable={false}
                name="name"
                value={nbrFavorites}
                onChange={(e) => setNbrFavorites(e)}
              />
            </div>
          )}
        </div>

        <div className={styles.actions}>
          <Button variant="default" onClick={(e) => close(e)}>
            {_("cancel")}
          </Button>
          {saving ? (
            <Button
              disabled={true}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <Loader
                color={"#fff"}
                style={{
                  height: "10px",
                  width: "79px",
                }}
              />
            </Button>
          ) : (
            <Button onClick={(e) => save(e)}>{_("save")}</Button>
          )}
        </div>
      </div>
      <SidebarIcon
        isOpened={isOpenIconList}
        hideSidebar={(e) => {
          e.stopPropagation();
          setIsOpenIconList(false);
        }}
        setIcon={(item) => setIcon(item)}
        sidebarClass="sidebar"
        token={token}
      />
    </>
  );
};

export default Sidebar;
