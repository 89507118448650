import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { ModalConfirm } from "tamtam-components";
import { Link, useNavigate } from "react-router-dom";

import { deletePack, getPacks } from "../../api";
import _ from "../../i18n";

import Button from "../common/Button";
import Sidebar from "./Sidebar";
import Pack from "./Pack";
// import PackPopOver from "./PackPopOver";
import styles from "./ManagePacks.module.scss";

const ManagePacks = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [openPopOver, setOpenPopOver] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentPack, setCurrentPack] = useState(null);
  const [deleting, setDeleting] = useState(false);

  let listPacks = useQuery(["listPacks", token], async () => {
    if (token) {
      try {
        const response = await getPacks(token);
        return response.data.data;
      } catch (e) {
        return null;
      }
    }
  });

  const onConfirmRateDelete = async () => {
    try {
      setDeleting(true);
      await deletePack(auth.token, currentPack.id);
      queryClient.invalidateQueries("listPacks");
      setCurrentPack(null);
      setDeleteModalOpen(false);
    } catch (e) {}
    setDeleting(false);
  };

  return (
    <div className={styles.ratesPage}>
      <div className={styles.header}>
        <div>
          <h1>{_("packs_list")}</h1>
          <span>{_("manage_packs")}</span>
        </div>
      </div>
      {listPacks.isLoading && <div className="lmask"></div>}
      {!listPacks.isLoading && !listPacks.data && (
        <div
          style={{
            textAlign: "center",
            maxWidth: "600px",
            margin: "0 auto 60px",
            color: "#2C394B",
            fontSize: "2rem",
          }}
        >
          <img alt="not found" src="/img/notFound.svg" />
          <div>{_("nothing_found")}</div>
        </div>
      )}

      {!listPacks.isLoading && listPacks.data && (
        <div className="grid-x grid-margin-x grid-margin-y">
          {listPacks.data.map((pack) => (
            <div className="cell small-12 medium-6 large-4" key={pack.id}>
              <Pack
                pack={pack}
                onEdit={(p) => {
                  setCurrentPack(p);
                  setSidebarOpened(true);
                }}
                onSelect={() => navigate(`/packs/${pack.id}`)}
              />
            </div>
          ))}
        </div>
      )}

      <ModalConfirm
        type="delete"
        isOpen={deleteModalOpen}
        inProcess={deleting}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={onConfirmRateDelete}
        title={_("delete_confirm")}
        text={_("text_delete_rate")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
      <div className={styles.bubbles}>
        <div
          onClick={() => {
            setSidebarOpened(true);
          }}
        >
          <i className="icon-ttp-plus-outline"></i>
          <span style={{ paddingLeft: "1rem" }}>{_("add")}</span>
        </div>
      </div>
      <Sidebar
        isOpened={sidebarOpened}
        hideSidebar={() => {
          setSidebarOpened(false);
          setCurrentPack(null);
          queryClient.invalidateQueries("listPacks");
        }}
        sidebarClass="sidebar"
        currentPack={currentPack}
        setCurrentPack={setCurrentPack}
      />

      {/* {openPopOver && (
        <PackPopOver
          closePopOver={() => {
            setOpenPopOver(false);
            setCurrentPack(null);
            queryClient.invalidateQueries("listPacks");
          }}
          currentPack={currentPack}
        />
      )} */}
    </div>
  );
};

export default ManagePacks;
