import React, { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Select from "react-select";

import _ from "../../i18n";
import { getWebtoolIcons } from "../../api";
import styles from "./SidebarIcon.module.scss";

const statusOptions = [
  { value: "ALL", label: _("all") },
  { value: "USED", label: _("used") },
  { value: "UNUSED", label: _("unused") },
];
const SELECT_STYLES = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#e6e6e6" : "#F8F9FA",
    boxShadow: "none",
    border: state.isFocused ? "1px solid #2495E1" : "1px solid #B2BCC6",
    "&:hover": {
      borderColor: state.isFocused ? "#18A0FB" : "#B2BCC6",
    },
    padding: 0,
    width: "fit-content",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#6D7F92",
    fontWeight: 400,
  }),
  menuList: (provided, state) => ({
    ...provided,
    paddingTop: "0",
    paddingBottom: "0",
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: "5px",
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
  }),
  option: (provided, state) => ({
    ...provided,
    textAlign: "left",
    fontSize: "12px",
    lineHeight: "14px",
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    backgroundColor: data.color ? data.color : "#F1F2F4",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    fontSize: ".75rem",
    textTransform: "uppercase",
    color: "inherit",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "14px",
    lineHeight: "16px",
    color: "#29394D",
    display: "contents",
  }),
};

const SidebarIcon = (props) => {
  const { isOpened, sidebarClass = "sidebar" } = props;
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const [searchValue, setSearchValue] = useState("");
  const [status, setStatus] = useState(statusOptions[0]);

  let { isLoading, data } = useQuery(
    ["getWebtoolIcons", auth.token],
    async () => {
      if (auth.token) {
        const response = await getWebtoolIcons(auth.token);

        return response.data.data;
      }
    }
  );

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  let filteredItems = [];
  if (!isLoading && data) {
    let filteredData = data;
    if (status.value === "USED") filteredData = data.filter((el) => el.isUsed);
    if (status.value === "UNUSED")
      filteredData = data.filter((el) => !el.isUsed);
    filteredItems = filteredData.filter((item) => {
      return (
        (item[titleAttr] &&
          item[titleAttr].toLowerCase().includes(searchValue.toLowerCase())) ||
        (item.label &&
          item.label.toLowerCase().includes(searchValue.toLowerCase()))
      );
    });
  }

  const selectIcon = (item) => {
    props.setIcon(item);
    props.hideSidebar();
  };

  return (
    <>
      <div
        className={`${styles.mask} ${isOpened ? "" : styles.hide}`}
        onClick={() => props.hideSidebar()}
      ></div>
      <div
        className={`${styles[sidebarClass]} ${isOpened ? styles.display : ""}`}
      >
        <div onClick={() => props.hideSidebar()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>

        <div className={styles.title}>{_("select_icon")}</div>

        {isLoading &&
          [...Array(40).keys()].map((key) => (
            <li
              key={key}
              className="fetching-icons card-item cell small-6 medium-3"
              style={{ backgroundColor: "#eee" }}
            />
          ))}

        {!isLoading && data && (
          <>
            <div className={styles.filters}>
              <div className={styles.search}>
                <input
                  type="text"
                  placeholder={_("search_placeholder")}
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
              <Select
                styles={SELECT_STYLES}
                options={statusOptions}
                isSearchable={false}
                value={status}
                onChange={(e) => setStatus(e)}
              />
            </div>
            <ul className="grid-x grid-margin-x">
              {filteredItems.map((item) => (
                <li
                  key={item.id}
                  className={`${styles.cardItem} cell small-6 medium-3`}
                  onClick={() => selectIcon(item)}
                >
                  <i className={`icon webtool-${item.label}`} />
                  <span title={item[titleAttr] || item.label}>
                    {item[titleAttr] || item.label}
                  </span>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </>
  );
};

export default SidebarIcon;
