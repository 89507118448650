import React from "react";

const IconArrowDown = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.41205 6.91107C4.73748 6.58563 5.26512 6.58563 5.59056 6.91107L10.0013 11.3218L14.412 6.91107C14.7375 6.58563 15.2651 6.58563 15.5906 6.91107C15.916 7.23651 15.916 7.76414 15.5906 8.08958L10.5906 13.0896C10.2651 13.415 9.73748 13.415 9.41205 13.0896L4.41205 8.08958C4.08661 7.76414 4.08661 7.23651 4.41205 6.91107Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconArrowDown;
