import classNames from "classnames";
import React from "react";
import styles from "./Faq.module.scss";
import { IconEye } from "../../common/icons/IconEye";
import { IconPen } from "../../common/icons/IconPen";
import { IconTrash } from "../../common/icons/IconTrash";
import { useSelector } from "react-redux";

const Faq = ({ faq, onDelete, onEdit, onHide }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  return (
    <div
      className={classNames(
        styles.faq_card,
        !faq.show ? styles.faq_card_hide : ""
      )}
    >
      <div className={styles.title}>{faq[titleAttr]}</div>
      <div className={styles.actions}>
        <div
          className={classNames(styles.icon, !faq.show ? styles.icon_hide : "")}
          onClick={() => onHide(faq)}
        >
          <IconEye black />
        </div>
        <div className={styles.icon} onClick={() => onEdit(faq)}>
          <IconPen />
        </div>
        <div className={styles.icon} onClick={() => onDelete(faq)}>
          <IconTrash />
        </div>
      </div>
    </div>
  );
};

export default Faq;
