import React from "react";
import styles from "./News.module.scss";
import moment from "moment";
import { API_DATE_FORMAT } from "../../../../../../../../config";
import _ from "../../../../../../../../i18n";

const ArticleCard = ({ article, lng }) => {
  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  return (
    <div className={styles.articleCard}>
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${
            article.main_media ? article.main_media.fullMediaUrl : ""
          })`,
        }}
      ></div>
      <div className={styles.content}>
        {article.category && (
          <div className={styles.category}>{article.category[nameAttr]}</div>
        )}
        <div className={styles.title}>
          {article.title.length > 50
            ? article.title.substring(0, 50) + "..."
            : article.title}
        </div>
        {article.introduction && (
          <div className={styles.description}>
            {article.introduction.length > 50
              ? article.introduction.substring(0, 50) + "..."
              : article.introduction}
          </div>
        )}
        {article.author && article.author.length > 0 && (
          <div className={styles.author}>
            <div
              className={styles.img}
              style={{
                backgroundImage: `url(${
                  article.author[0].avatarUrl ? article.author[0].avatarUrl : ""
                })`,
              }}
            ></div>
            <div className={styles.info}>
              <div
                className={styles.name}
              >{`${article.author[0].firstName} ${article.author[0].lastName}`}</div>
              <div className={styles.published}>
                {moment(article.publishedAt, API_DATE_FORMAT)
                  .locale("en")
                  .format("DD MMM YYYY")}
                {article.readTime && (
                  <>
                    <div className={styles.dot}></div>
                    {`${article.readTime} ${_("readTime")}`}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleCard;
