import axios from "axios";

import { TTP_API_URL } from "../config";
import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
  throwCatchedError,
} from "../utils";

let getGroupsCTS;
let getCollaboratorsCTS;
let getEventListCTS;
let getUsersSettingsCTS;
let getContactsCTS;

export const getCollaborators = ({
  token,
  sort,
  search,
  start,
  limit,
  page,
  organizationId,
  isConfigNewsletter = false,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  // getGroupsCTS
  let requestCancellationToken = getRequestCancellationToken(
    getCollaboratorsCTS,
    cancellationTokenSource
  );
  getCollaboratorsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "organization.id",
      value: organizationId,
      operator: "eq",
    },
  ];
  if (isConfigNewsletter) {
    filter.push({ property: "uaStatus", value: "VALIDATED", operator: "like" });
  }
  if (search) {
    filter.push({ property: "name", value: search, operator: "like" });
    filter.push({ property: "email.main", value: 1, operator: "eq" });
  }

  let params = {
    access_token: token,
    sort: JSON.stringify([sort]),
    fields: "*,role,roles,userSettings",
    start: page ? (page - 1) * limit : start,
    filter: JSON.stringify(filter),
    limit: limit && limit,
    nolimit: !limit && 1,
    workspace: "ua",
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getTmpCollaborators = ({ token, organizationId, status }) => {
  let requestUrl = `${TTP_API_URL}/organization/tmp-role`;

  const filter = [
    {
      property: "organization",
      value: organizationId,
      operator: "eq",
    },
    {
      property: "status",
      value: status,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: "*",
      filter: JSON.stringify(filter),
      nolimit: 1,
      workspace: "ua",
    },
  });
};

export const getUserByEmail = (token, email) => {
  let fields = ["*", "email", "phone", "role", "roles"];

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "email",
      value: email,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
      workspace: "ua",
    },
  });
};

export const checkUserByEmail = (token, email) => {
  let requestUrl = `${TTP_API_URL}/organization/user/getCollaboratorByEmail`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      email,
    },
  });
};

export const getUsersByEmail = (token, emails) => {
  let fields = ["*", "email", "phone", "role", "roles"];

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "email",
      value: emails,
      operator: "in",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
      workspace: "ua",
    },
  });
};

export const getUserById = (token, id) => {
  let fields = ["*", "email", "phone", "role", "roles"];

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "id",
      value: id,
      operator: "eq",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      fields: fields.join(","),
      workspace: "ua",
    },
  });
};

export const detachUserAssociations = (token, userId) => {
  const requestUrl = `${TTP_API_URL}/organization/user/detach-user-associations/${userId}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const deleteTmpCollab = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/tmp-role/${id}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const saveCollaborator = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/user`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email[0][email]", data.email);
  formData.append("firstName", data.firstname);
  formData.append("lastName", data.lastname);
  formData.append("language", data.language);
  formData.append("isEmployee", 1);
  formData.append("isSecure", 1);

  if (data.phone) {
    formData.append("phone[0][number]", data.phone);
  } else {
    formData.append("user_type", "contact");
  }

  // if (data.exist) {
  //   formData.append("role[0][exist]", data.exist);
  // }

  if (data.roleId) {
    formData.append("role[0][id]", data.roleId);
    formData.append("role[0][type]", data.type);
    if (data.typeStatus) {
      formData.append("role[0][typeStatus]", data.typeStatus);
    }
  } else {
    formData.append("tmpRole[0][type]", data.type);
    if (data.typeStatus) {
      formData.append("tmpRole[0][typeStatus]", data.typeStatus);
    }
  }

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.emailId) {
    formData.append("email[0][id]", data.emailId);
  }

  if (data.phoneId) {
    formData.append("phone[0][id]", data.phoneId);
  }

  return axios.post(requestUrl, formData);
};

export const importCollaborators = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/recognizer/user`;

  var formData = new FormData();
  formData.append("access_token", token);

  if (data.data) {
    formData.append("data", data.data);
  }

  if (data.file) {
    formData.append("file", data.file);
  }

  return axios.post(requestUrl, formData);
};

export const saveImportCollaborators = (token, file) => {
  let requestUrl = `${TTP_API_URL}/organization/users/ua-import`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("file", file);

  return axios.post(requestUrl, formData);
};

export const getEventList = (token, userId, lng, limit = 3) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getEventListCTS,
    cancellationTokenSource
  );

  getEventListCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/event/event/get-list-for-user`;

  const fields = [
    "id",
    "type",
    "nameFr",
    "nameNl",
    "nameEn",
    "placeFr",
    "placeNl",
    "placeEn",
    "descriptionFr",
    "descriptionNl",
    "descriptionEn",
    "urlBannerFr",
    "urlBannerNl",
    "urlBannerEn",
    "eventDate",
    "slotsCount",
    "client",
    "memberPrice",
    "nonMemberPrice",
    "languages",
    "isReplayable",
    "accreditationHours",
    "status",
    "replayStatus",
    "labelFr",
    "labelNl",
    "labelEn",
    "speakers-abstract",
    "user-registered",
    "eventCycles",
  ];

  let params = {
    access_token: token,
    fields: fields.join(","),
    userId: userId,
    communityIds: 9,
    languages: lng,
    period: "future",
    limit: limit,
    start: 0,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const saveUser = (token, data) => {
  let requestUrl = `${TTP_API_URL}/organization/user`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email[0][email]", data.email);
  formData.append("phone[0][number]", data.phone);
  formData.append("firstName", data.firstName);
  formData.append("lastName", data.lastName);
  formData.append("language", data.language);
  formData.append("gender", data.gender);
  if (data.password) formData.append("password", data.password);
  if (data.confirmPassword)
    formData.append("passwordConfirm", data.confirmPassword);
  if (data.agreation) formData.append("agreation", data.agreation);
  formData.append("isSecure", 1);
  formData.append("uaStatus", "VALIDATED");

  if (data.id) {
    formData.append("id", data.id);
  }

  if (data.role) {
    formData.append("role[0][type]", data.role.type);
    formData.append("role[0][organization]", data.role.organization);
  }

  if (data.organization_id) {
    formData.append("organization_id", data.organization_id);
  }

  return axios.post(requestUrl, formData);
};

export const addUserExpress = ({ token, emails, fiduciaryId, language }) => {
  let requestUrl = `${TTP_API_URL}/organization/user/addExpress`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("emails", emails.join(","));
  formData.append("fiduciaryId", fiduciaryId);
  formData.append("language", language);
  return axios.post(requestUrl, formData);
};
export const saveAvatarToApi = (token, avatar) => {
  const requestUrl = `${TTP_API_URL}/organization/user/upload/profileAvatar`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("profile_avatar", avatar);

  return axios.post(requestUrl, formData);
};

export const getExpressUser = ({ token, fiduciaryId, status }) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  let requestCancellationToken = getRequestCancellationToken(
    getEventListCTS,
    cancellationTokenSource
  );
  let params = {
    access_token: token,
    fiduciaryId: fiduciaryId,
    status,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  let requestUrl = `${TTP_API_URL}/organization/user/addExpress`;
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const changePassword = ({ token, key, password, passwordConfirm }) => {
  let requestUrl = `${TTP_API_URL}/organization/user/newPassword`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("key", key);
  formData.append("password", password);
  formData.append("passwordConfirm", passwordConfirm);

  return axios.post(requestUrl, formData);
};

export const emailReminder = ({
  token,
  id,
  uid,
  language,
  fid,
  type = "COLLABORATOR",
}) => {
  let requestUrl = `${TTP_API_URL}/organization/user/emailReminder`;
  var formData = new FormData();
  formData.append("access_token", token);
  if (uid) formData.append("uid", uid);
  if (id) formData.append("id", id);
  if (fid) formData.append("fid", fid);
  formData.append("language", language);
  formData.append("type", type);
  return axios.post(requestUrl, formData);
};

export const getUsersSettings = ({
  token,
  page = 0,
  word = "",
  organizationId,
  cancellationTokenSource = null,
}) => {
  let requestCancellationToken = getRequestCancellationToken(
    getUsersSettingsCTS,
    cancellationTokenSource
  );

  const fields = [
    "*",
    "email",
    "avatar",
    "blogRoleInOrganization",
    "role",
    "defaultSignature",
    "metas",
    "author",
  ];

  let requestUrl = `${TTP_API_URL}/organization/user/blog-setting-users?type=BLOG`;

  let filter = [];

  if (word !== "" && word.length >= 3) {
    filter.push({
      property: "name",
      value: word,
      operator: "like",
    });
  }

  let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(","),
    limit: 100,
    start: page * 100,
    organization_id: organizationId,
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);

  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const saveUsersSettings = (token, data, organizationId) => {
  const requestUrl = `${TTP_API_URL}/blog/blog-role/multi-save`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("source", "ua");
  formData.append("organization_id", organizationId);

  data.forEach((user, index) => {
    formData.append(`users[${index}][id]`, user.id);
    formData.append(`users[${index}][role]`, user.role);
    formData.append(`users[${index}][mandated]`, user.mandated);
    formData.append(`users[${index}][allowCreateTags]`, user.allowCreateTags);
    user.languages.forEach((lng) => {
      formData.append(`users[${index}][allowedLanguages][]`, lng);
    });
  });
  return axios.post(requestUrl, formData);
};

export const setEmailMain = (id, token) => {
  const requestUrl = `${TTP_API_URL}/profile/email/main`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", id);
  return axios.post(requestUrl, formData);
};

export const setPhoneMain = (id, token) => {
  const requestUrl = `${TTP_API_URL}/profile/phone/main`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("id", id);
  return axios.post(requestUrl, formData);
};

export const updateUser = async (token, field, data) => {
  const requestUrl = `${TTP_API_URL}/organization/user`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append(
    field === "email" || field === "phone" ? "user" : "id",
    data.id
  );
  if (field === "name") {
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
  }
  if (field === "birthday") {
    formData.append("birthday", data.birthday);
  }
  if (field === "gender") {
    formData.append("gender", data.gender);
  }
  if (field === "language") {
    formData.append("language", data.language);
  }
  if (field === "license") {
    formData.append("agreation", data.agreation);
  }
  if (field === "email") {
    formData.append(`email`, data.email.email);
    formData.append(`main`, data.email.main);
    const emailRequestUrl = `${TTP_API_URL}/profile/email`;

    const res = await axios.post(emailRequestUrl, formData);
    if (res.data.data.main === 1) {
      await setEmailMain(res.data.data.id, token);
    }

    return res;
  }
  if (field === "phone") {
    formData.append(`number`, data.phone.number);
    formData.append(`main`, data.phone.main);
    const phoneRequestUrl = `${TTP_API_URL}/profile/phone`;

    const res = await axios.post(phoneRequestUrl, formData);
    if (res.data.data.main === 1) {
      await setPhoneMain(res.data.data.id, token);
    }

    return res;
  }
  if (field === "password") {
    formData.append(`oldPassword`, data.oldPassword);
    formData.append(`password`, data.password);
    formData.append(`passwordConfirm`, data.passwordConfirm);
    const passwordRequestUrl = `${TTP_API_URL}/organization/user/changePassword`;

    return axios.post(passwordRequestUrl, formData);
  }

  return axios.post(requestUrl, formData);
};

export const deleteExpressUser = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/user/delete-user-express/${id}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const deleteEmailApi = (id, token) => {
  const requestUrl = `${TTP_API_URL}/profile/email/${id}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const deletePhoneApi = (id, token) => {
  const requestUrl = `${TTP_API_URL}/profile/phone/${id}`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const resetPassword = (token, email) => {
  let requestUrl = `${TTP_API_URL}/organization/user/resetPassword`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  formData.append("source", "ua");

  return axios.post(requestUrl, formData);
};

export const saveContact = async (token, data, communityId) => {
  let requestUrl = `${TTP_API_URL}/mailing/contact/ua-save`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email[0][email]", data.email);
  formData.append("firstName", data.firstname);
  formData.append("lastName", data.lastname);
  formData.append("gender", data.gender);
  formData.append("language", data.language);
  formData.append("user_type", "contact");
  formData.append("is_contact", 1);
  formData.append("client", communityId);
  formData.append("details", JSON.stringify(data.details));

  if (data.phone) {
    formData.append("phone[0][number]", data.phone);
  }

  if (data.userID) {
    formData.append("id", data.userID);
  }

  if (data.emailId) {
    formData.append("email[0][id]", data.emailId);
  }

  if (data.phoneId) {
    formData.append("phone[0][id]", data.phoneId);
  }

  if (data.type) {
    formData.append("type", data.type);
  }

  /*var contactData = new FormData();
  contactData.append("access_token", token);
  contactData.append("client", communityId);
  contactData.append("details", JSON.stringify(data.details));
  if (!data.fetchedUser) {
    const userRes = await axios.post(requestUrl, formData);
    if (userRes && userRes.data.data.id) {
      contactData.append("id", userRes.data.data.id);
    }
  } else {
    contactData.append("id", data.fetchedUser.id);
  }

  if (data.fetchedFolder) {
    contactData.append("folderId", data.fetchedFolder.id);
  }
  if (data.oldFetchedFolder) {
    contactData.append("oldFolderId", data.oldFetchedFolder);
  }*/

  return axios.post(requestUrl, formData);
};

export const getContacts = ({
  token,
  sort,
  search,
  start,
  limit,
  page,
  communityId,
  filterGroups,
  excludeGroups,
  contactNl,
  fields,
}) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getContactsCTS,
    cancellationTokenSource
  );
  getContactsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/mailing/contact`;
  const filter = [
    { property: "client.id", value: communityId, operator: "eq" },
  ];
  if (search) {
    filter.push(
      ...[
        { property: "name", value: search, operator: "like" },
        { property: "email.main", value: 1, operator: "eq" },
      ]
    );
  }

  if (filterGroups && filterGroups.length > 0) {
    filter.push({
      property: "group.id",
      value: [...filterGroups],
      operator: "in",
    });
  }
  if (excludeGroups && excludeGroups.length > 0) {
    filter.push({
      property: "group.id",
      value: [...excludeGroups],
      operator: "nin",
    });
  }
  if (contactNl) {
    filter.push({
      property: "contactClient.type",
      value: "CONTACT_NL",
      operator: "eq",
    });
  }

  let params = {
    filter: JSON.stringify(filter),
    access_token: token,
    fields: fields ?? "*,contactClient,email,phone,group",
    start: page ? (page - 1) * limit : start,
    limit,
  };

  if (sort) {
    params["sort"] = JSON.stringify([sort]);
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const deleteContact = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/contact-client/${id}`;

  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};

export const emailAcceptInvitation = (token, uid, id, email, fiduciaryId) => {
  let requestUrl = `${TTP_API_URL}/profile/email/accept-invitation`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  if (uid) {
    formData.append("uid", uid);
  }
  if (id) {
    formData.append("id", id);
  }
  if (fiduciaryId) {
    formData.append("fid", fiduciaryId);
  }

  return axios.post(requestUrl, formData);
};

export const emailRejectInvitation = (token, uid, id, email, fiduciaryId) => {
  let requestUrl = `${TTP_API_URL}/profile/email/reject-invitation`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  if (uid) {
    formData.append("uid", uid);
  }
  if (id) {
    formData.append("id", id);
  }
  if (fiduciaryId) {
    formData.append("fid", fiduciaryId);
  }

  return axios.post(requestUrl, formData);
};

export const saveRole = (token, organizationId) => {
  let requestUrl = `${TTP_API_URL}/organization/user/addTmpRole`;
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("organizationId", organizationId);
  return axios.post(requestUrl, formData);
};

export const saveImportContacts = (token, file, communityId) => {
  let requestUrl = `${TTP_API_URL}/mailing/contact/ua-import`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("file", file);
  formData.append("client", communityId);

  return axios.post(requestUrl, formData);

  /*
  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("client", communityId);
  data.forEach((item, i) => {
    formData.append(`firstName[${i}]`, item[0]);
    formData.append(`lastName[${i}]`, item[1]);
    formData.append(`email[${i}]`, item[2]);
    formData.append(`phone[${i}]`, item[3]);
    formData.append(`language[${i}]`, item[4]);
    formData.append(`uen[${i}]`, item[5].replace(/\.|\s|BE|be/g, ""));
    formData.append(`Uname[${i}]`, item[6]);
    formData.append(`fonction[${i}]`, item[7]);
    formData.append(
      `details[${i}]`,
      JSON.stringify({
        uen: item[5].replace(/\.|\s|BE|be/g, ""),
        name: item[6],
        fonction: item[7],
        isClient: false,
      })
    );
  });
  return axios.post(requestUrl, formData);
  */
};

export const getGroupByName = (token, name, communityId) => {
  let cancellationTokenSource = generateCancellationTokenSource();

  let requestCancellationToken = getRequestCancellationToken(
    getGroupsCTS,
    cancellationTokenSource
  );
  getGroupsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/mailing/group`;
  let params = {
    access_token: token,
    fields: "*",
  };

  if (name) {
    params.filter = JSON.stringify([
      {
        property: "name",
        value: name,
        operator: "like",
      },
      { property: "client.id", value: communityId, operator: "eq" },
    ]);
  }

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const addContactToGroup = (token, data, communityId) => {
  let requestUrl = `${TTP_API_URL}/mailing/group/create-contacts`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("client", communityId);
  formData.append("category", "CONTACT");
  formData.append("group[name]", data.group.name);
  if (data.group.id) {
    formData.append("group[id]", data.group.id);
  }
  data.contacts.forEach((element, i) => {
    formData.append(`contactsIds[${i}]`, element);
  });

  return axios.post(requestUrl, formData);
};

export const removeContactFromGroup = (token, data) => {
  let requestUrl = `${TTP_API_URL}/mailing/group/delete-contacts`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("category", "CONTACT");
  if (data.group.id) {
    formData.append("groupId", data.group.id);
  }
  data.contacts.forEach((element, i) => {
    formData.append(`contactsIds[${i}]`, element);
  });

  return axios.post(requestUrl, formData);
};

export const getGroups = ({ token, communityId }) => {
  let requestUrl = `${TTP_API_URL}/mailing/group`;
  const filter = [
    { property: "client.id", value: communityId, operator: "eq" },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      filter: JSON.stringify(filter),
      nolimit: 1,
    },
  });
};

export const clientAcceptInvitation = (token, uid, email, fiduciaryId) => {
  let requestUrl = `${TTP_API_URL}/profile/email/accept-client-invitation`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  if (uid) {
    formData.append("uid", uid);
  }
  if (fiduciaryId) {
    formData.append("fid", fiduciaryId);
  }

  return axios.post(requestUrl, formData);
};

export const clientRejectInvitation = (token, uid, email, fiduciaryId) => {
  let requestUrl = `${TTP_API_URL}/profile/email/reject-client-invitation`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  if (uid) {
    formData.append("uid", uid);
  }
  if (fiduciaryId) {
    formData.append("fid", fiduciaryId);
  }

  return axios.post(requestUrl, formData);
};

export const getThemeGroups = ({
  token,
  clientId = null,
  customFilter = null,
}) => {
  const requestUrl = `${TTP_API_URL}/mailing/group`;
  let filter = [
    {
      property: "client.id",
      value: clientId,
      operator: "eq",
    },
  ];

  if (customFilter !== null && Array.isArray(customFilter)) {
    filter.push(...customFilter);
  }

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      nolimit: 1,
      fields: "id, name",
      filter: JSON.stringify(filter),
    },
  });
};
export const getUsers = ({ token, search, organizationId }) => {
  let cancellationTokenSource = generateCancellationTokenSource();
  // getGroupsCTS
  let requestCancellationToken = getRequestCancellationToken(
    getCollaboratorsCTS,
    cancellationTokenSource
  );
  getCollaboratorsCTS = cancellationTokenSource;

  let requestUrl = `${TTP_API_URL}/organization/user`;

  const filter = [
    {
      property: "organization.id",
      value: organizationId,
      operator: "eq",
    },
  ];

  if (search) {
    filter.push({ property: "name", value: search, operator: "like" });
    filter.push({ property: "email.main", value: 1, operator: "eq" });
  }

  let params = {
    access_token: token,
    fields: "*",
    start: 0,
    filter: JSON.stringify(filter),
    limit: 20,
    workspace: "ua",
  };

  let requestConfig = getRequestConfig(params, requestCancellationToken);
  return axios.get(requestUrl, requestConfig).catch(function (thrown) {
    throwCatchedError(thrown);
  });
};

export const getUserHasFiduciaryPlan = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-membership/has-fiduciary-plan`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};

export const saveOECCBBMember = async (token, data, communityId) => {
  let requestUrl = `${TTP_API_URL}/mailing/contact/oeccbb-save`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("email[0][email]", data.email);
  formData.append("firstName", data.firstname);
  formData.append("lastName", data.lastname);
  formData.append("user_type", "contact");
  formData.append("is_contact", 1);
  formData.append("client", communityId);

  formData.append("year", data.year);
  formData.append("formula", data.formula);

  if (data.phone) {
    formData.append("phone[0][number]", data.phone);
  }

  if (data.userID) {
    formData.append("id", data.userID);
  }

  if (data.emailId) {
    formData.append("email[0][id]", data.emailId);
  }

  if (data.phoneId) {
    formData.append("phone[0][id]", data.phoneId);
  }
  formData.append("uen", data.uen);
  formData.append("organization", data.organization);
  formData.append("address", data.address);
  formData.append("postalCode", data.postalCode);
  formData.append("country", data.city);
  formData.append("billingSubjectToVAT", data.subjectToVAT ? 1 : 0);

  return axios.post(requestUrl, formData);
};

export const checkContacts = (token, file, communityId) => {
  let requestUrl = `${TTP_API_URL}/mailing/contact/ua-check`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("file", file);
  formData.append("client", communityId);

  return axios.post(requestUrl, formData);
};
