import axios from "axios";

import { TTP_API_URL } from "../config";

export const getIcons = (token) => {
  const sort = [
    {
      property: "label",
      dir: "asc",
    },
  ];
  const fields = ["*"];
  const requestUrl = `${TTP_API_URL}/media/webtool-icon`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      sort: JSON.stringify(sort),
      fields: fields.join(","),
      nolimit: 1,
    },
  });
};
