import React, { PureComponent } from "react";

import OrganizationInfos from "./OrganizationInfos";
import Media from "./Media";
import styles from "./PublicPortal.module.scss";
import Subheader from "./Subheader";

export default class Infos extends PureComponent {
  render() {
    const { community, info, page, blocs } = this.props;

    return (
      <section className={styles.portalInfos}>
        <Media community={community} info={info} />
        <div className={styles.cover}>
          <Subheader blocs={blocs} community={community} />
          <OrganizationInfos
            white
            page={page}
            community={community}
            info={info}
          />
        </div>
      </section>
    );
  }
}
