import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";

import AppLoader from "../../common/Loader/AppLoader";
import {
  URL_HASH_KEY,
  TTP_OFFFCOURSE_URL,
  TTP_EMAILING_URL,
  TTP_BLOG_URL,
  TTP_ONBOARDING_URL,
} from "../../../config";
import styles from "./DrawerIframe.module.scss";
import { decryptWithAES, logout } from "../../../utils";

// const encryptWithAES = (text) => {
//   return CryptoJS.AES.encrypt(text, URL_HASH_KEY).toString();
// };

const encryptAES = string => {
  var encryptMethod = "AES-256-CBC";
  var aesNumber = encryptMethod.match(/\d+/)[0];
  var encryptMethodLength = parseInt(aesNumber);

  var iv = CryptoJS.lib.WordArray.random(16);
  var salt = CryptoJS.lib.WordArray.random(256);
  var iterations = 999;
  var encryptMethodLength = encryptMethodLength / 4; // example: AES number is 256 / 4 = 64
  var hashKey = CryptoJS.PBKDF2(URL_HASH_KEY, salt, {
    hasher: CryptoJS.algo.SHA512,
    keySize: encryptMethodLength / 8,
    iterations: iterations,
  });

  var encrypted = CryptoJS.AES.encrypt(string, hashKey, {
    mode: CryptoJS.mode.CBC,
    iv: iv,
  });
  var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

  var output = {
    ciphertext: encryptedString,
    iv: CryptoJS.enc.Hex.stringify(iv),
    salt: CryptoJS.enc.Hex.stringify(salt),
    iterations: iterations,
  };

  return CryptoJS.enc.Base64.stringify(
    CryptoJS.enc.Utf8.parse(JSON.stringify(output))
  );
};

const DrawerIframe = props => {
  const auth = useSelector(state => state.auth);
  const {
    src,
    params,
    isBlog,
    isOFFFCourse,
    isNewsletter,
    setActiveApp,
  } = props;
  const lng = useSelector(state => state.params.language);
  const [newsletterSendingUrl, setNewsletterSendingUrl] = useState("");
  const [isNlBlog, setIsNlBlog] = useState(false);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    const urlParams = new URLSearchParams(window.location.search);
    const params = urlParams.get("params");
    if (params) {
      const decryptedParams = JSON.parse(decryptWithAES(params));
      const arrayParams = Object.keys(decryptedParams).map(key => ({
        id: key,
        value: decryptedParams[key],
      }));
      handleShowNewsletterIframe(arrayParams);
    }
    window.addEventListener("message", function(event) {
      if (event.data === "logout") {
        logout();
      }
    });
  }, []);

  useEffect(
    () => {
      if (isOFFFCourse) {
        window.addEventListener("message", function(e) {
          const event = e?.data?.event;
          const message = e?.data?.message;

          let param = [...params, "token=" + auth.token];
          if (auth.user) {
            param = [
              ...params,
              "token=" + auth.token,
              "userId=" + auth.user.id,
            ];
          }

          if (event === "OPEN_WEBINAR") {
            let webinarUrl =
              message.indexOf("?") === -1
                ? message +
                  "?params=" +
                  encodeURIComponent(encryptAES(param.join()))
                : message +
                  "&params=" +
                  encodeURIComponent(encryptAES(param.join()));

            let drawerIframe = document.getElementById("drawer_iframe");
            drawerIframe.src = webinarUrl;
          }

          if (event === "OPEN_OFFFCOURSE") {
            let offfcourseUrl =
              TTP_OFFFCOURSE_URL +
              "/watch?lng=" +
              lng +
              "?params=" +
              encodeURIComponent(encryptAES(param.join()));

            let drawerIframe = document.getElementById("drawer_iframe");
            drawerIframe.src = offfcourseUrl;
          }
        });
      }
    },
    [isOFFFCourse]
  );

  useEffect(
    () => {
      if (src && isBlog) {
        document.getElementById("iframe-loader").style.display = "block";
      }
    },
    [src, isBlog]
  );

  useEffect(
    () => {
      const urlParams = new URLSearchParams(window.location.search);
      const params = urlParams.get("params");
      const APP = urlParams.get("APP");
      const idApproval = urlParams.get("idApproval");
      const action = urlParams.get("action");
      const ebox = urlParams.get("ebox");
      if (APP === "NEWSLETTER" && idApproval && action) {
        const decryptedIdApproval = decryptWithAES(idApproval);
        const decryptedAction = decryptWithAES(action);
        const arrayParams = [
          { id: "idApproval", value: decryptedIdApproval },
          { id: "action", value: decryptedAction },
        ];
        handleShowNewsletterIframe(arrayParams);
      } else if (APP === "NEWSLETTER" && ebox) {
        const arrayParams = [{ id: "ebox", value: ebox }];
        handleShowNewsletterIframe(arrayParams);
      } else if (isNewsletter) {
        if (params) {
          const decryptedParams = JSON.parse(decryptWithAES(params));
          const arrayParams = Object.keys(decryptedParams).map(key => ({
            id: key,
            value: decryptedParams[key],
          }));
          handleShowNewsletterIframe(arrayParams);
        } else {
          handleShowNewsletterIframe();
        }
      }
    },
    [isNewsletter]
  );
  useEffect(
    () => {
      if (isNlBlog) {
        const newUrl = TTP_ONBOARDING_URL + "/collaborator?APP=NEWSLETTER";
        window.history.pushState({}, "", newUrl);
        setActiveApp("NEWSLETTER");
      }
    },
    [isNlBlog]
  );

  const handleMessage = event => {
    let url = TTP_BLOG_URL;
    if (url.endsWith("/")) {
      url = url.slice(0, -1);
    }
    if (event.origin !== url) {
      return;
    }
    if (event.data.from_blog) {
      setIsNlBlog(true);
    }
  };
  const handleShowNewsletterIframe = (arrayParams = []) => {
    let newsletterId = null;
    let isUaNewsletter = false;
    let scheduledDeliveryAt = null;
    let idApproval = null;
    let action = null;
    let ebox = false;

    arrayParams.forEach(({ id, value }) => {
      switch (id) {
        case "id":
          newsletterId = value;
          break;
        case "newsletter":
          isUaNewsletter = value === "true";
          break;
        case "scheduledDeliveryAt":
          scheduledDeliveryAt = value;
          break;
        case "idApproval":
          idApproval = value;
          break;
        case "action":
          action = value;
          break;
        case "ebox":
          ebox = value;
          break;
        default:
          break;
      }
    });

    let param = [
      ...params,
      "token=" + auth.token,
      "newsletterId=" + newsletterId,
      "isUaNewsletter=" + isUaNewsletter,
      "scheduledDeliveryAt=" + scheduledDeliveryAt,
      "idApproval=" + idApproval,
      "action=" + action,
      "isNlBlog=" + isNlBlog,
      "ebox=" + ebox,
    ];

    if (auth.user) {
      param = [
        ...params,
        "token=" + auth.token,
        "userId=" + auth.user.id,
        "newsletterId=" + newsletterId,
        "isUaNewsletter=" + isUaNewsletter,
        "scheduledDeliveryAt=" + scheduledDeliveryAt,
        "idApproval=" + idApproval,
        "action=" + action,
        "isNlBlog=" + isNlBlog,
        "ebox=" + ebox,
      ];
    }

    let nlSrcUrl = TTP_EMAILING_URL + "/newsletter-ua/BASIC";
    let srcUrl =
      nlSrcUrl.indexOf("?") === -1
        ? nlSrcUrl + "?params=" + encodeURIComponent(encryptAES(param.join()))
        : nlSrcUrl + "&params=" + encodeURIComponent(encryptAES(param.join()));
    setNewsletterSendingUrl(srcUrl);
  };

  if (src) {
    let param = [...params, "token=" + auth.token];
    if (auth.user) {
      param = [...params, "token=" + auth.token, "userId=" + auth.user.id];
    }

    let srcUrl =
      src.indexOf("?") === -1
        ? src + "?params=" + encodeURIComponent(encryptAES(param.join()))
        : src + "&params=" + encodeURIComponent(encryptAES(param.join()));

    return (
      <>
        {/* <div id="iframe-loader" class={styles.loader}></div> */}
        {isBlog && <AppLoader id="iframe-loader" />}

        <iframe
          id="drawer_iframe"
          allow="camera;microphone;autoplay;fullscreen"
          className={styles.iframe}
          allowFullScreen
          src={srcUrl}
          title="TAMTAM portal"
          onLoad={() => {
            if (document.getElementById("iframe-loader")) {
              document.getElementById("iframe-loader").style.display = "none";
            }
          }}
        />
      </>
    );
  } else if (isNewsletter || isNlBlog) {
    return (
      <>
        <iframe
          id="drawer_iframe"
          allow="camera;microphone;autoplay;fullscreen"
          className={styles.iframe}
          allowFullScreen
          src={newsletterSendingUrl}
          title="TAMTAM portal"
          onLoad={() => {
            if (document.getElementById("iframe-loader")) {
              document.getElementById("iframe-loader").style.display = "none";
            }
          }}
        />
      </>
    );
  } else {
    return <div style={{ minHeight: "70vh" }} />;
  }
};

export default DrawerIframe;
