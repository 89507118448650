import React from "react";
import {
  MediaAppPublic,
  BlogAppPublic,
  TeamAppPublic,
  ContactAppPublic,
  SpecialtyAppPublic,
  ServicesAppPublic,
  TestimonialsAppPublic,
  FaqsAppPublic,
} from "../../PortalApps";

const Custom = ({
  handlePortalBlocChange,
  portals,
  setAddMediaModalOpened,
  medias,
  setMedias,
  descriptionAttr,
  handlePortalBlocDescriptionChange,
  titleAttr,
  handlePortalBlocTitleChange,
  specialties,
  folderSpecialties,
  setFolderSpecialties,
  fiduciaryConfig,
  services,
  setServices,
  sortServices,
  sortCollaborators,
  collaborators,
  setCollaborators,
  testimonials,
  setTestimonials,
  sortTestimonials,
  faqs,
  setFaqs,
  sortFaqs,
  handleGeoChange,
  addPushPinOnClick,
  handleChangeSchedule,
  handleApplySchedule,
  contactEmailError,
  setContactEmailError,
  contactPhoneError,
  setContactPhoneError,
}) => {
  return (
    <>
      <MediaAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        setAddMediaModalOpened={setAddMediaModalOpened}
        medias={medias}
        setMedias={setMedias}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
      />
      <SpecialtyAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        specialties={specialties}
        folderSpecialties={folderSpecialties}
        setFolderSpecialties={setFolderSpecialties}
        maxPicks={fiduciaryConfig.personalizedDesign.specialties.maxPicks}
        maxFavorites={
          fiduciaryConfig.personalizedDesign.specialties.maxFavorites
        }
        allowFavorites={
          fiduciaryConfig.personalizedDesign.specialties.allowFavorites
        }
      />
      <ServicesAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        services={services}
        setServices={setServices}
        sortServices={sortServices}
        maxPicks={fiduciaryConfig.personalizedDesign.services.maxPicks}
      />
      <BlogAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
      />
      <TeamAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        sortCollaborators={sortCollaborators}
        collaborators={collaborators}
        setCollaborators={setCollaborators}
      />
      <TestimonialsAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        testimonials={testimonials}
        setTestimonials={setTestimonials}
        sortTestimonials={sortTestimonials}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        maxPicks={fiduciaryConfig.personalizedDesign.testimonials.maxPicks}
      />
      <FaqsAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        titleAttr={titleAttr}
        handlePortalBlocTitleChange={handlePortalBlocTitleChange}
        descriptionAttr={descriptionAttr}
        handlePortalBlocDescriptionChange={handlePortalBlocDescriptionChange}
        faqs={faqs}
        setFaqs={setFaqs}
        sortFaqs={sortFaqs}
        maxPicks={fiduciaryConfig.personalizedDesign.faq.maxPicks}
      />
      <ContactAppPublic
        handlePortalBlocChange={handlePortalBlocChange}
        portals={portals}
        handleGeoChange={handleGeoChange}
        addPushPinOnClick={addPushPinOnClick}
        handleChangeSchedule={handleChangeSchedule}
        handleApplySchedule={handleApplySchedule}
        contactEmailError={contactEmailError}
        setContactEmailError={setContactEmailError}
        contactPhoneError={contactPhoneError}
        setContactPhoneError={setContactPhoneError}
      />
    </>
  );
};

export default Custom;
