import React, { useState } from "react";
import { Reorder } from "framer-motion";
import RecorderEditContainer from "../ReorderEditContainer";
import Contact from "../Contact";
import { useSelector } from "react-redux";
import Faq from "../Faq";
import Newsletter from "../Newsletter";
import { BannerAppPublic } from "../../../../PortalApps";
import EditContainer from "../EditContainer";
import Banner from "../Banner";

const NewsPage = ({
  portals,
  currentTab,
  handlePortalBlocChange,
  handlePortalBlocReorder,
  editSection,
  setEditSection,
  faqs,
}) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const sections = {
    faqs: {
      name: "faqs",
      key: "faqs",
      data: portals.public.config?.faqs,
      component: (
        <Faq
          data={portals.public.config.faqs}
          faqs={faqs.filter((el) => el.show).slice(0, 3)}
        />
      ),
    },
    newsletter: {
      name: "newsletter",
      key: "newsletter",
      data: portals.public.config.newsletter,
      component: <Newsletter data={portals.public.config.newsletter} />,
    },
    contact: {
      name: "contact",
      key: "contact",
      data: portals.public.config.contact,
      component: (
        <Contact
          navCommunity={fiduciary}
          publicPortal={portals.public}
          version={"v3"}
        />
      ),
    },
  };
  const orderedKeys = portals.public.config?.newsPageOrder
    ? portals.public.config.newsPageOrder
    : Object.keys(sections);

  const [items, setItems] = useState(orderedKeys);
  const setOrder = (updatedOrder) => {
    setItems(updatedOrder);
    handlePortalBlocReorder(updatedOrder, "newsPageOrder");
  };
  return currentTab === "NEWS" ? (
    <>
      <EditContainer
        isVisible={true}
        edit={() => {
          console.log(portals.public.config?.newsPage);
          setEditSection("newsBanner");
        }}
      >
        <Banner data={portals.public.config?.newsPage?.banner} />
      </EditContainer>
      <Reorder.Group axis="y" values={items} onReorder={setOrder}>
        {items.map((item) => (
          <RecorderEditContainer
            key={item}
            item={item}
            isVisible={
              sections[item]?.alwaysVisible
                ? true
                : sections[item].data?.activated
            }
            setVisibility={
              sections[item].alwaysVisible
                ? null
                : () => {
                    console.log(sections[item]);
                    handlePortalBlocChange(
                      !sections[item].data?.activated,
                      sections[item].key
                    );
                  }
            }
            edit={() => setEditSection(sections[item].name)}
          >
            {sections[item].component}
          </RecorderEditContainer>
        ))}
      </Reorder.Group>
    </>
  ) : null;
};

export default NewsPage;
