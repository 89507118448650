import React from "react";
import { useSelector } from "react-redux";

import styles from "./Team.module.scss";
import _ from "../../../../../../../../i18n";
import classNames from "classnames";

const Team = ({ data, team }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <div className={styles.container}>
      <div className={styles.section_title}>{_("Team")}</div>
      <div className={styles.title}>{data[titleAttr]}</div>
      <div className={styles.description}>{data[descriptionAttr]}</div>
      <div className={classNames(styles.list, "grid-x")}>
        {team.map((member, i) => (
          <div
            key={i}
            className={classNames(styles.cardCont, "cell small-6 medium-3")}
          >
            <div className={styles.member_card}>
              <div
                className={styles.img}
                style={{
                  backgroundImage: `url(${
                    member.avatarUrl
                      ? member.avatarUrl
                      : "https://tamtam.s3.eu-west-1.amazonaws.com/default_avatar.webp"
                  })`,
                }}
              ></div>
              <div
                className={styles.name}
              >{`${member.firstName} ${member.lastName}`}</div>
              <div className={styles.function}>
                {member.function ? member.function : ""}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
