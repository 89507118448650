import React from 'react';
import Slider from "react-slick"
import {sanitize} from "../../../utils";
import { TTP_BLOG_URL } from '../../../config';
import styles from './CarouselItem.module.scss';


const setting = { 
    slidesToShow: 5, 
    slidesToScroll: 5,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            
          }
        },
        {
            breakpoint: 780,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
}

export const CarouselCategory = ({categories, lng}) => {

    const buildUrl = (category) => {
        const name = getCategoryName(category);
        return TTP_BLOG_URL + `/${lng}/category/${sanitize(name)}/${category.id}`
    }
  
    const getCategoryName = (category) =>  category[`name${lng.charAt(0).toUpperCase()}${lng.slice(1)}`];
    

    return (<div className="news-block">
        <Slider {...setting}>
            { categories.map(category => ( 
                <div key={`sld-cat-${category.id}`}>
                    <div className={styles.category}  key={category.id}
                                    style={{background: category.colorCode}} >
                        <a href={buildUrl(category)} target="_blank" rel="noreferrer"> {getCategoryName(category)} </a>
                    </div>
                </div>) 
            )}
        </Slider>
    </div>);


}

export const CarouselChain = ({chains, lng}) => {

  const buildUrl = (chain) => {
      const name = getChainName(chain);
      return TTP_BLOG_URL + `/${lng}/chain/${sanitize(name)}/${chain.id}`
  }

  const getChainName = (chain) =>  chain[`name${lng.charAt(0).toUpperCase()}${lng.slice(1)}`];

  return <div className="news-block">
      <Slider {...setting}>
          { chains.map(chain => (
              <div key={`sld-chin-${chain.id}`}>
                  <a href={buildUrl(chain)} target="_blank" rel="noreferrer"><div className={styles.chain} key={chain.id}
                      style={{backgroundImage: `url(${chain.mediaChain[0].avatarUrl})`}} >
                  </div></a>
              </div>) 
          )}
      </Slider>
  </div>
}

