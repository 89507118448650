import React from "react";
import styles from "./Gallery.module.scss";
import _ from "../../../../../../../../i18n";
import { useSelector } from "react-redux";
import MediaList from "./MediaList";

const Gallery = ({ galleryMedias, setGalleryMedias, data }) => {
  const language = useSelector((state) => state.params.language);
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  const descriptionAttr = `description${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  return (
    <>
      <div className={styles.container}>
        <div className={styles.section_title}>{_("gallery")}</div>
        <div className={styles.title}>
          {data && data[titleAttr] ? data[titleAttr] : "Tinterdum velit"}
        </div>
        <div className={styles.description}>
          {data && data[descriptionAttr]
            ? data[descriptionAttr]
            : "Turpis egestas pretium aenean pharetra magna"}
        </div>
      </div>
      {galleryMedias.length > 0 ? (
        <MediaList
          medias={galleryMedias}
          setMedias={setGalleryMedias}
          language={language}
          isUsed={false}
        />
      ) : (
        <div className={styles.grid}>
          {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
            <figure className={styles.item}>
              <img src="img/no_image.png" alt="" />
            </figure>
          ))}
        </div>
      )}
    </>
  );
};

export default Gallery;
