import React from "react";
import styles from "./EditContainer.module.scss";
import IconEye from "../icons/IconEye";
import IconPen from "../icons/IconPen";
import classNames from "classnames";

const EditContainer = ({ children, isVisible, setVisibility, edit }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.actions}>
        {isVisible && edit && (
          <li title="Edit" onClick={() => edit()}>
            <IconPen />
          </li>
        )}
        {setVisibility && (
          <li
            className={!isVisible ? styles.hide : ""}
            title={isVisible ? "Hide" : "Show"}
            onClick={() => setVisibility()}
          >
            <IconEye />
          </li>
        )}
      </ul>
      <div
        className={classNames(styles.content, !isVisible ? styles.hidden : 0)}
      >
        {children}
      </div>
    </div>
  );
};

export default EditContainer;
