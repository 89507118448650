const faqNl = {
  continue: "Doorgaan",
  step_company_details: "Bedrijfsgegevens",
  step_collaborator: "Medewerkers",
  step_clients: "Klanten",
  step_contacts: "Andere contacten",
  step_students: "Studenten",
  add_collaborator_title: "Medewerkers toevoegen",
  add_student_title: "Studenten toevoegen",
  express: "Nadrukkelijk",
  manual: "Handmatig",
  import_xls_file: "Bestand importeren (.xls)",
  email_address: "E-mailadres",
  email: "E-mail",
  firstname: "Voornaam",
  lastname: "Achternaam",
  add: "Toevoegen",
  cancel: "Annuleren",
  english: "English",
  french: "French",
  dutch: "Dutch",
  phone_number: "Telefoon",
  collaborator_role: "Rol van medewerker",
  language: "Taal",
  LEGAL_REPRESENTATIVE: "Wettelijke vertegenwoordiger",
  MANAGER: "Manager",
  OFFICIAL: "Toevoegen",
  EXTERNAL: "Extern",
  ADMINISTRATOR: "Beheerder",
  validate_email: "Geef alstublieft een geldig email adres",
  required_field: "Dit veld is verplicht",
  required_2_characters: "Geef minimaal 2 tekens op",
  collaborators_list: "Lijst met medewerkers",
  import_collaborators: "Werknemers importeren",
  import_contacts: "Contacten importeren",
  edit: "Bewerking",
  delete: "Verwijderen",
  delete_confirm: "Bevestiging verwijderen",
  text_delete_collaborator:
    "Weet je zeker dat je deze bijdrager wilt verwijderen?",
  no: "No",
  yes: "Yes",
  add_contact_title: "Andere contacten toevoegen",
  contacts_list: "Contactlijst",
  finish: "Eindigen",
  gender: "Geslacht",
  text_delete_service: "Weet u zeker dat u deze service wilt verwijderen?",
  service_title: "Diensttitel",
  service_description: "Dienstbeschrijving",
  select_icon: "Selecteer icoon",
  is_private: "is privé",
  add_service: "Dienst toevoegen",
  validate_service_title: "Titels zijn verplicht",
  validate_service_title_length: "Titels moeten 3 tekens of meer bevatten",
  validate_service_icon: "Icoon is vereist",
  something_went_wrong: "Er is iets fout gegaan",
  update_service: "Update van de dienst",
  opening_time: "Planning",
  office_closed: "Gesloten",
  all_training: "Alle trainingen",
  all_authors: "alle auteurs",
  no_article_found: "Geen items gevonden",
  welcome_to: "Welkom bij",
  home_description:
    "Creëer uw digitale basis en sluit u aan bij het nieuwe technologische netwerk van economische en juridische beroepen.",
  subscribe: "Abonneer u op het United Associates netwerk",
  already_subscribed: "Ik ben al geabonneerd",
  home_footer:
    "De technologische basis is ontwikkeld door Tamtam international.",
  validate: "Valideren",
  male: "Man",
  female: "Vrouw",
  mobile_phone_number: "Mobiel telefoonnummer",
  email_validated_successfully: "E-mailadres succesvol gevalideerd",
  account_exist_login:
    "Dit account bestaat al in het Tamtam-universum:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li> < li><small>- Forum For the Future</small></li></ul>Voer uw wachtwoord in",
  account_exist_pwd:
    "Dit account bestaat al in het Tamtam-universum:<br/><ul><li><small>- oFFFcouse</small></li><li><small>- United Associates</small></li> < li><small>- Forum For the Future</small></li></ul>Stel een wachtwoord in",
  password_weak: "Het wachtwoord is zwak!",
  password_not_match: "Vul nogmaals dezelfde waarde in",
  password: "Wachtwoord",
  confirm_password: "Bevestig wachtwoord",
  numeroAgreation: "Erkenningsnummer (economische beroepen)",
  check_your_email: "Controleer uw e-mailinbox om uw adres te valideren",
  already_sent_email:
    "We hebben al een bevestigingsmail naar uw inbox gestuurd.",
  resend_confirmation_email: "Bevestigingsmail opnieuw verzenden",
  create_account: "Account aanmaken",
  signup: "Registreer",
  signin: "Aanmelden",
  forgot_password: "Vergeten?",
  must_accept_conditions: "U moet onze servicevoorwaarden accepteren",
  itaa: "ITAA Administratiekantoor",
  ire_audit: "IRE-accountantskantoor",
  law_office: "Advocatenkantoor",
  notarial_office: "Notarieel kantoor",
  other: "Anders...",
  validate_phone: "Het telefoonnummer is ongeldig",
  title_office_step_1: "Uw kantoorgegevens",
  subTitle_office_step_1:
    "De contactgegevens van de praktijk worden gebruikt voor de facturering. Grootte bepaalt uw abonnement.",
  title_office_step_2:
    "U hebt gekozen voor een lidmaatschap van een bedrijf tussen %1% en %2% werknemers",
  subTitle_office_step_2:
    "Met dit lidmaatschap profiteert u van onbeperkte toegang in verhouding tot uw grootte en tot een reeks inhoud en toepassingen.",
  checkbox_person_title: "kantoor",
  checkbox_person_subTitle: "natuurlijk persoon",
  checkbox_person_description:
    "Uitoefening als natuurlijk persoon veronderstelt dat u geen gebruik maakt van een rechtspersoon om uit te oefenen",
  checkbox_office_title: "kantoor",
  checkbox_office_subTitle: "bedrijf",
  checkbox_office_description:
    "Uitoefening in een bedrijf veronderstelt dat u een rechtspersoon gebruikt om uit te oefenen.",
  checkbox_fiduciary_title: "Kantoor",
  checkbox_fiduciary_subTitle: "ITAA Fiduciair",
  checkbox_itaa_title: "Kantoor",
  checkbox_itaa_subTitle: "ITAA Fiduciair",
  checkbox_ire_audit_title: "Bedrijf",
  checkbox_ire_audit_subTitle: "IRE-audit",
  checkbox_law_office_title: "Advocatenkantoor",
  checkbox_law_office_subTitle: "Advocaten",
  checkbox_notarial_office_title: "Bedrijf",
  checkbox_notarial_office_subTitle: "Notarieel onderzoek",
  checkbox_other_subTitle: "Overige...",
  app_blog_desc:
    "Gebruik de Blog-applicatie voor gratis toegang tot economisch, juridisch, boekhoudkundig en fiscaal nieuws. Gebruik deze inhoud voor uw organisatie en vul deze aan met uw eigen inhoud of samengestelde inhoud.",
  app_blog_details_1_title: "Al het nieuws uit de branche",
  app_blog_details_1_subTitle:
    "Inbegrepen in uw abonnement de FFF Blog en zijn kanalen",
  app_blog_details_2_title:
    "Blog transformeren in een schoon communicatiemiddel",
  app_blog_details_2_subTitle: "2 van de 3 tools inbegrepen in uw abonnement",
  app_blog_tools_1_title: "Schrijf je artikelen",
  app_blog_tools_1_desc:
    "Voeg een persoonlijke laag toe aan je nieuws. Met deze optie kun je je eigen blog maken, naast die van de FFF en haar kanalen.",
  app_blog_tools_2_title: "Externe artikelen invoegen",
  app_blog_tools_2_desc:
    "Gerefereerde externe artikelen die u aanspreken: dit is cureren, beschikbaar via deze optie.",
  app_blog_tools_3_title: "Nieuwsbrieven maken",
  app_blog_tools_3_desc:
    "Stel een selectie van artikelen samen voor uw mailinglijst. Communiceren via een nieuwsbrief was nog nooit zo eenvoudig.",
  app_faq_desc:
    "Toegang tot een bibliotheek met de meest populaire vragen en antwoorden. Gebruik deze inhoud voor uw organisatie en creëer een ondersteuningscentrum voor uw klanten.",
  app_faq_details_1_title: "Alle vragen en antwoorden in de branche",
  app_faq_details_1_subTitle:
    "Inbegrepen in uw abonnement de FAQ FFF en zijn kanalen",
  app_faq_details_2_title: "Transformeer FAQ in een schone communicatietool",
  app_faq_details_2_subTitle: "2 van de 2 tools inbegrepen in uw abonnement",
  app_faq_tools_1_title: "Maak uw veelgestelde vragen",
  app_faq_tools_1_desc:
    "Voeg een persoonlijke laag toe aan je FAQ. Met deze optie kun je je eigen FAQ's maken, naast die van de FFF en zijn kanalen.",
  app_faq_tools_2_title: "Maak uw helpdesk",
  app_faq_tools_2_desc:
    "Neem de kennis op die in de veelgestelde vragen staat om uw klanten een helpdesk te bieden in een 'volg de gids'-indeling",
  app_webinar_desc:
    "Toegang tot een online trainingsbibliotheek voor het hele bedrijf op basis van de oFFFcourse-bibliotheek en die van zijn kanalen. Afhankelijk van je abonnement heb je een of meer premium toegangen (met certificering) en onbeperkt delen. Vul vervolgens de beschikbare formaties van je eigen formaties in .",
  app_webinar_details_1_title:
    "Alle opleidingen in de sector: een educatieve hefboom",
  app_webinar_details_2_title: "Deel mijn Premium",
  app_webinar_details_2_subTitle:
    "Met deze optie kunt u zonder certificering delen met 4 leden van uw bedrijf een premium abonnement, 8 leden 2 premium abonnementen, enz...",
  app_webinar_tools_1_title: "Deel mijn premium",
  app_webinar_tools_1_desc:
    "Met deze optie kunt u uw premium abonnement delen met alle leden van uw bedrijf.",
  app_webinar_tools_2_title: "Bied premium aan iedereen",
  app_webinar_tools_2_desc:
    "Met deze optie kunt u de bibliotheek delen met alle leden van het bedrijf terwijl u hun programma's en certificeringen beheert.",
  app_webinar_tools_3_title: "Maak mijn eigen webinars",
  app_webinar_tools_3_desc:
    "Met deze optie kunt u uw eigen webinars maken en deze delen met uw medewerkers.",
  app_webinar_tools_4_title: "Deel webinars met mijn klanten",
  app_webinar_tools_4_desc:
    "Met deze optie kun je webinars delen met je klanten via het klantenportaal.",
  app_webtools_desc:
    "Toegang tot een bibliotheek met de meest populaire webtools. Gebruik deze inhoud voor uw organisatie en creëer een toolcentrum voor uw klanten.",
  app_webtools_details_1_title: "Alle branche-webtools",
  app_webtools_details_1_subTitle:
    "Inbegrepen in uw abonnement de FFF Webtools",
  app_webtools_details_2_title:
    "Verander webtools in een bronnenbibliotheek voor uw klanten",
  app_webtools_details_2_subTitle:
    "2 van de 3 tools inbegrepen in uw abonnement",
  app_webtools_tools_1_title: "Maak uw webtools",
  app_webtools_tools_1_desc:
    "Voeg een persoonlijke laag toe aan je webtools. Met deze optie kun je je eigen webtools maken, naast die van de FFF en haar kanalen.",
  app_webtools_tools_2_title: "Maak uw gereedschapscentrum",
  app_webtools_tools_2_desc:
    "Incorporeer de tools vermeld in webtools om uw klanten een helpdesk te bieden in een 'volg de gids'-formaat",
  app_event_desc:
    "Toegang tot een persoonlijke trainingsbibliotheek voor het hele bedrijf op basis van de bibliotheek van oFFFcourse-ventilatieopeningen en die van zijn kanalen. Voltooi vervolgens de catalogus van deze ventilatieopeningen door uw eigen ventilatieopeningen toe te voegen",
  app_event_details_1_title: "Alle branche-evenementen",
  app_event_details_1_subTitle:
    "Alleen de gratis cursussen van oFFFcourse en zijn kanalen zijn inbegrepen in uw abonnement",
  app_event_details_2_title:
    "Organiseer uw eigen trainingen voor uw klanten en medewerkers",
  app_event_details_2_subTitle: "2 van de 3 tools inbegrepen in uw abonnement",
  app_event_tools_1_title: "Maak mijn eigen gratis evenementen",
  app_event_tools_1_desc:
    "Met deze optie kunt u uw eigen gratis evenementen maken en uitnodigingen en de organisatie ervan beheren.",
  app_event_tools_2_title: "Maak mijn eigen betaalde evenementen",
  app_event_tools_2_desc:
    "Met deze optie kun je je eigen betaalde evenementen maken en uitnodigingen en de organisatie ervan beheren.",
  app_accounting_desc:
    "Organiseer de presentatie van uw jaarwerk aan uw klanten met behulp van de nieuwe presentatietool in diavoorstellingsformaat (Dashboard). Vul vervolgens de gepersonaliseerde presentatie in.",
  app_accounting_details_1_title:
    "Al uw klanten profiteren van professionele rapportage",
  app_accounting_details_2_title:
    "Transformeer je werk in een educatieve en communicatieve operatie",
  app_accounting_details_2_subTitle:
    "2 van de 3 tools inbegrepen in uw abonnement",
  app_accounting_tools_1_title:
    "Verleng uw presentatie door gebruik te maken van een weergave- en verhoudingsbatterij",
  app_accounting_tools_1_desc:
    "Met deze optie kunt u aan uw dia's een weergave van de jaarrekening over meerdere jaren toevoegen, met diverse analyses en vergelijkingen.",
  app_accounting_tools_2_title: "Deel de presentatie op het klantenportaal",
  app_accounting_tools_2_desc:
    "Met deze optie kunt u uw diavoorstelling, analyses en vergelijkingen delen met uw klant op zijn persoonlijke portaal.",
  app_accounting_tools_3_title: "Genereer uw eigen presentatiestandaarden",
  app_accounting_tools_3_desc:
    "Met deze optie kunt u uw presentaties maken en personaliseren volgens uw behoeften en die van uw klanten.",
  version_beta: "Bètaversie",
  offered_by_ua: "Aangeboden door UA",
  until: "Tot",
  business_num: "Bedrijfsnummer",
  approval_num: "Goedkeuringsnummer",
  abbreviation: "Afkorting",
  url_link: "URL-link",
  address: "adres",
  activity_area: "Activiteitsgebied",
  my_portals: "Mijn portalen",
  installed_app: "AppStore",
  future_app: "Aankomende app",
  customer_records: "Klantgegevens",
  other_contacts: "Contacten",
  other_contact: "Andere contacten",
  more_than: "meer dan",
  of: "van",
  close: "dichtbij",
  step_payment: "Betaling",
  waiting_for_payment: "De bestelling wacht op betaling",
  waiting_for_payment_subtitle:
    "Het bestelformulier is per e-mail naar u verzonden. Om uw lidmaatschap sneller te laten verlopen, gaat u direct naar uw betaling door de voorkeur te geven aan online betalingen.",
  successful_payment: "uw betaling is succesvol uitgevoerd",
  command_details: "Details van de bestelling",
  facture_details: "Factuurgegevens",
  membership_to_ua_for: "Lidmaatschap van het United Associates-netwerk voor",
  membership_ends_in: "Dit lidmaatschap eindigt op",
  description: "Beschrijving",
  price: "Prijs",
  quantity: "Aantal stuks",
  tva_rate: "BTW-tarief",
  tvac_amount: "BTW bedrag",
  membership_cabinet_of: "Lidmaatschap voor een firma van",
  to: "tot",
  collaborator: "Samenwerker",
  collaborators: "Medewerkers",
  client: "Klant",
  clients: "Klanten",
  download_command: "Bestelling downloaden",
  download_invoice: "Factuur downloaden",
  pay_here: "Betaal hier",
  choose_payment_method: "Kies je betaalmethode",
  code_will_expire: "De QR-code van Payconiq is verlopen",
  in: "in",
  online_payment: "Online betaling",
  selected: "Geselecteerd",
  choose: "Kiezen",
  bank_payment: "Overschrijving",
  use_this_card: "Betaal met deze kaart",
  new_card: "Nieuwe creditcard",
  card_number: "Kaartnummer",
  expiration_date: "Vervaldatum",
  remember_card: "Registreer mijn kaart",
  payment_denied: "Geweigerde betaling",
  payment_denied_p:
    "Het spijt ons dat we niet positief kunnen reageren op uw betalingsverzoek.",
  retry_payment: "probeer de betaling opnieuw",
  summary: "Samenvatting",
  subscription_choice: "abonnement keuze",
  joining_ua_for: "Lidmaatschap van het United associates-netwerk voor",
  number_employees: "Aantal werknemers",
  between: "Tussen",
  and: "en",
  company_data: "Bedrijfsgegevens",
  payment: "betaling",
  payment_date: "Betaaldatum",
  payment_mode: "Betalingsmiddel",
  amount: "Hoeveelheid",
  status: "Statuut",
  payment_made: "Betaling gedaan",
  subscribed_to_ua: "U bent een United Associates-abonnee",
  back: "rug",
  my_profile: "Mijn profiel",
  add_new_email: "Voeg een e-mailadres toe",
  edit_license_number: "Licentienummer wijzigen",
  set_as_principal: "Stel dit adres in als primair",
  edit_license_number_p:
    "Het goedkeuringsnummer is handig om ons in staat te stellen u bepaalde toegang te geven. Dit nummer is ook essentieel in het kader van erkende opleidingen voor de afgifte van certificaten.",
  ITAA_trust_office: "ITAA Trustkantoor",
  IRE_audit_firm: "IRE accountantskantoor",
  law_office: "Advocatenkantoor",
  notarial_office: "Notarieel kantoor",
  other: "Ander",
  manage_information: "Beheer uw persoonlijke gegevens",
  photo: "Foto",
  photo_p: "Personaliseer je account door een foto toe te voegen",
  first_last_name: "Achternaam / voornaam",
  principale: "Primair",
  non_principale: "Niet primair",
  principale_email_p:
    "Uw primaire adres is het adres dat het systeem gebruikt om contact met u op te nemen en informatie aan u door te geven. Door het gebruik van meerdere e-mailadressen kunt u inloggen, ongeacht het e-mailadres dat voor hetzelfde profiel (account) wordt gebruikt.",
  password: "Wachtwoord",
  medium_password: "Het wachtwoord is gemiddeld!",
  license_number: "Erkenningsnummer (economische beroepen)",
  phone_number_1: "Telefoonnummer",
  office: "Kantoor",
  phone_number_1_p:
    "Uw primaire telefoonnummer zou idealiter een mobiel nummer moeten zijn. Het zal de beveiliging verbeteren door middel van identificatie met twee factoren.",
  add_new_phone_number: "Een telefoonnummer toevoegen",
  birth_date: "Geboortedatum",
  gender_1: "Geslacht",
  reject: "Weigeren",
  put_on_pending: "In de wacht zetten",
  ua_status_successfully: "Status is succesvol gewijzigd",
  ua_validate_confirm: "Wijziging bevestigen",
  text_ua_validate_confirm: "Wilt u de vertrouwenspersoon valideren?",
  text_ua_put_on_pending_confirm:
    "Wilt u de vertrouwenspersoon in de wacht zetten?",
  text_ua_reject_confirm: "Wilt u de fiduciaire afwijzen?",
  save: "Opslaan",
  empty_list: "Lege lijst",
  all: "Allemaal",
  validated: "Gevalideerd",
  pending: "In afwachting",
  rejected: "Afgewezen",
  accepted: "Geaccepteerd",
  created: "Gemaakt",
  reminder: "Herinnering",
  change_password: "Changer le mot de passe",
  reminder_email_sent_successfully:
    "De herinneringsmail is succesvol verzonden",
  reset_password: "Stel een wachtwoord in",
  user_added: "gebruiker(s) toegevoegd",
  user_added_with_error:
    "gebruiker(s) toegevoegd. Controleer resterende adressen",
  import_clients: "klanten importeren",
  CHIEF_EDITOR: "Hoofdredacteur",
  AUTEUR: "Auteur",
  AUTHOR_MANDATED: "Auteur gemandateerd",
  REDACTEUR: "Bewerker",
  REDACTOR_MANDATED: "Gemandateerde redacteur",
  modal_avatar_title: "Afbeelding aanpassen",
  save: "Opslaan",
  change_image: "Afbeelding wijzigen",
  zoom: "Zoom",
  rotation: "Rotatie",
  edit_name: "Bewerk voornaam en achternaam",
  change_password: "Wachtwoord wijzigen",
  old_password: "Oud wachtwoord",
  set_as_principal_phone: "Stel dit nummer in als primair",
  edit_birthday: "Verjaardag bewerken",
  birthday: "Verjaardag",
  saving_changes: "Wijzigingen opslaan ...",
  edit_gender: "Geslacht bewerken",
  edit_language: "Taal bewerken",
  uen_label_number: "Voer het nummer in",
  oldPassword_incorrect: "Oud wachtwoord onjuist",
  email_not_unique: "E-mail al in gebruik",
  add_media: "Nieuwe media toevoegen",
  in_progress: "Bezig",
  send: "Sturen",
  password_changed_succesfully: "Wachtwoord succesvol opgeslagen",
  reset_pwd_check_your_email:
    "Er is een bericht naar uw e-mailbox verzonden. Raadpleeg deze en volg de instructies.",
  successfully_saved: "Succesvol opgeslagen",
  title_exist_fiduciary_confirm: "Bevestiging",
  text_exist_fiduciary:
    "Deze trustee bestaat in de database van Tamtam. Weet u zeker dat u wilt doorgaan?",
  search: "Search ...",
  save_confirm: "Bevestiging opslaan",
  text_save_group:
    "Wilt u de geselecteerde contacten aan deze groep toevoegen?",
  text_delete_group: "Wil je de contactpersoon uit deze groep verwijderen?",
  group_filter: "Groepen filteren",
  groups: "Groepen",
  search_group: "Groep zoeken",
  add_from_biblio: "Toevoegen vanuit bibliotheek",
  biblio_services: "Diensten Bibliotheek",
  confirm: "Bevestigen",
  empty_biblio: "De bibliotheek is leeg",
  categorie: "Categorie",
  author_type: "Type auteurs",
  boekhouder: "Bevoegd Boekhouder",
  tax_advice: "Belastingadvies",
  advocaat: "advocaat",
  notaris: "Notaris",
  adviseur: "Adviseur",
  hoogleraar: "Professor",
  journalist: "Journalist",
  anderen: "Anders",
  article_feed: "Artikelfeed",
  both: "Alle (beide)",
  my_articles: "Publicaties van mijn firma",
  fff_articles: "FFF Nieuws",
  relevance: "Relevantie",
  city: "Dorp",
  zipCode: "Postcode",
  company_logo: "Bedrijfslogo",
  help_center_app: "helpcentrum",
  dashboard_app: "Dashboard",
  powerteam_app: "Powerteam",
  watch_app: "Watch",
  carousel: "Carrousel",
  terms_of_use: "Gebruiksvoorwaarden",
  manage_cookies: "Cookies beheren",
  invalid_value: "Ongeldige waarde",
  add_confirm: "Bevestiging toevoegen",
  text_add_exist_collaborator:
    "Deze bijdrager behoort tot een andere organisatie. De velden worden uitgeschakeld totdat de uitnodiging is geaccepteerd. Weet u zeker dat u deze wilt toevoegen?",
  text_popover_add_exist_collaborator:
    "Deze medewerker is van een andere organisatie. Weet u zeker dat u hem wilt toevoegen? Zo niet, dan kunt u deze regel verwijderen",
  existing_medias: "Mediatheek",
  splitsen: "Splitsen",
  samenvoegen: "Samenvoegen",
  fill_with_default_values: "Vul met standaardwaarde",
  valid_data: "Verifieer de gegevens",
  select_header: "Selecteer kop",
  map_fields: "Kaartvelden",
  verify_finalize: "Verifiëren en afronden",
  services_biblio: "Dienstenbibliotheek",
  manage_public_services: "Beheer openbare diensten hier",
  add_new_service: "Nieuwe dienst toevoegen",
  text_popover_add_exist_client:
    "Deze klant hoort bij een andere organisatie. Weet u zeker dat u deze wilt toevoegen? Zo niet, dan kunt u deze regel verwijderen",
  country_code: "Landcode",
  text_add_exist_client:
    "Deze klant is van een andere organisatie. Weet je zeker dat je het wilt toevoegen?",
  webtools_app: "Webtool",
  our_fields: "Onze velden",
  columns_in_file: "Kolommen in uw bestand",
  preview: "Voorbeeld",
  map_columns: "Kolommen op de kaart",
  reduce: "Verminderen",
  show_tools: "Toon gerelateerde tools",
  SKILLS: "Vaardigheden",
  TECHNOLOGY: "Technologie",
  CLIENTELE: "Klanten",
  AREA: "Gebied",
  BOOKKEEPING: "Boekhouding",
  ADJUSTMENT_OF_THE_ANNUAL_ACCOUNTS: "Aanpassing van de jaarrekening",
  PERIODIC_SITUATION: "Periodieke situatie",
  FINANCIAL_PLAN: "Financieel plan",
  BUSINESS_VALUATION: "Bedrijfswaardering",
  UBO: "UBO",
  PAYROLL_MANAGEMENT_EMPLOYEE: "Loonadministratie (werknemer)",
  PAYROLL_MANAGEMENT_ADMINISTRATOR: "Salarisadministratie (Beheerder)",
  MANAGEMENT_ALTERNATIVE_REMUNERATION: "Alternatief beloningsbeheer",
  PLAN_CAFETERIA: "Cafetariaplan",
  SPLIT_SALARY: "Gesplitst salaris",
  IMPATRIATES: "Impatriaten",
  BUSINESS_CREATION: "Bedrijfscreatie",
  CORPORATE_REORGANIZATION: "Bedrijfsreorganisatie",
  RECEIVERSHIP_PROCEEDINGS: "Invorderingsprocedure",
  BUSINESS_LIQUIDATION: "Bedrijfsliquidatie",
  TRANSFER_BUSINESS: "Overdracht van bedrijf",
  FAMILY_OFFICE: "Familiekantoor",
  TAX_RETURNS_IPP: "Belastingaangifte aan het IPP",
  TAX_RETURNS_ISOC: "Belastingaangifte aan ISOC",
  TAX_DECLARATIONS_INR: "INR belastingaangiften",
  TAX_DECLARATIONS_IPM: "Belastingaangifte aan IPM",
  VAT_RETURN: "BTW teruggave",
  TAX_REPRESENTATION: "Fiscale vertegenwoordiging",
  ADMINISTRATIVE_LITIGATION: "Administratieve geschillen",
  LEGAL_LITIGATION: "Juridische geschillen",
  RULING: "Uitspraak (voorafgaande uitspraken)",
  WEALTH_PLANNING: "Rijkdomsplanning",
  GDPR_TIP: "AVG-tip",
  STRATEGIC_CONSULTING: "Strategisch advies",
  DIGITAL_STRATEGY: "Digitale strategie",
  LBO: "LBO",
  M_A: "M&A",
  DRAFTING_STATUTES: "Opstellen van statuten",
  DRAFTING_AGREEMENTS: "Opstellen overeenkomsten",
  AUDIT_ACCOUNTS: "Accountcontrole",
  ENERGY_AUDIT: "Energie-audit",
  CSR: "MVO",
  HORUS: "Horus",
  XPERT_M: "Xpert-M",
  WINBOOKS: "Winboeken",
  EXACT_ONLINE: "Exact online",
  POPSY_ALLEGRO: "Klaproos - Allegro",
  YUKI: "Yuki",
  BOB_SOFTWARE: "Bob-software",
  OCTOPUS: "Octopus",
  ODOO: "odoo",
  BILLTOBOX: "BillToBox",
  BILLIT: "BillIt",
  FALCON: "Valk",
  WINBOOKS_CONNECT: "Winbooks verbinden",
  LIBERAL_PROFESIONAL: "liberale professional",
  INDIVIDUALS: "Natuurlijke personen",
  SMALL_COMPANIES: "Kleine bedrijven",
  MEDIUM_SIZED_COMPANIES: "Middelgrote bedrijven",
  LARGE_COMPANIES: "Grote bedrijven",
  SMALL_NON_PROFIT_ORGANIZATIONS: "Kleine vzw's",
  MEDIUM_AND_LARGE_NON_PROFIT_ORGANIZATIONS: "Middelgrote en grote VZW",
  NON_RESIDENTS: "Niet ingezetenen",
  FOREIGN_COMPANIES: "Buitenlandse bedrijven",
  DOCTOR: "Arts",
  PROFESSIONS: "Vrije beroepen",
  PHYSIOTHERAPIST: "Fysio",
  PHARMACIST: "Apotheker",
  DENTISTS: "Tandartsen",
  CULTURE: "Cultuur",
  ARTISTS: "Artiesten",
  PERFORMING_ARTS: "Uitvoerende kunst",
  YOUTH_ASSISTANCE: "Jeugdhulp",
  National: "Nationaal",
  Regional: "Regionaal",
  Local: "Lokaal",
  ACCOUNTING_AUDITING: "Boekhouding & controle",
  TAX_ADVICE: "Belastingadvies",
  SOCIAL_COUNCIL: "Sociale Raad",
  LEGAL_ADVICE: "Juridisch advies",
  ACCOMPANIMENT: "Begeleiding",
  CERTIFICATION: "Certificering",
  CONSULTING: "Overleg plegen",
  SUPPORTED_ACCOUNTING_SOFTWARE: "Ondersteunde boekhoudsoftware",
  DIGITAL_PLATFORMS: "Digitale platforms",
  TYPE_OF_PROFESSIONAL: "Type vakman",
  SECTOR: "Sector",
  specialty: "specialiteit",
  manage_fiduciary: "Beheer fiduciair",
  manage_specialties: "Beheer specialiteiten",
  manage_apps: "Apps beheren",
  contact_messages: "Contactberichten",
  fiduciary_contact_messages: "Fiduciaire contactberichten",
  nothing_found: "Niets gevonden",
  sent_at: "Verzonden op",
  name: "Naam",
  select_message_please: "Selecteer een bericht aub",
  manage_fiduciary_specialties: "Beheer fiduciaire specialiteiten",
  text_delete_item: "Weet je zeker dat je dit item wilt verwijderen?",
  add_specialty: "Voeg specialiteit toe",
  skills: "Vaardigheden",
  subject: "Onderwerp",
  message: "Bericht",
  send_message: "Bericht verzenden",
  add_specialty_bloc: "Voeg specialiteitsblok toe",
  title_required: "Titel vereist",
  icon_required: "Icoon vereist",
  create_specialty_type: "Maak speciaal type aan",
  create_specialty: "Maak een specialiteit aan",
  create_specialty_bloc: "Maak een speciaal blok",
  name_fr: "Naam Fr",
  name_en: "Naam En",
  name_nl: "Naam Nl",
  has_choices: "Unieke keuze",
  isActivated: "Geactiveerd",
  multiselect: "Meervoudige selectie",
  number_favorites_allowed: "Aantal toegestane favorieten",
  search_placeholder: "Zoeken...",
  reply: "Antwoord",
  at: "bij",
  successfully_sent: "Succesvol verzonden",
  subject_to_vat: "Onderworpen aan BTW",
  default_domain: "Standaard domein",
  custom_subdomain: "Aangepast subdomein",
  create_group: "Groep aanmaken",
  Day: "Dag",
  Morning: "Morgen",
  Afternoon: "Middag",
  MON: "MAA",
  TUE: "DIN",
  WED: "WOE",
  TUR: "DON",
  FRI: "VRI",
  SAT: "ZAT",
  SUN: "ZON",
  Apply_for_all_days: "Alle dagen aanvragen",
  "Geen werk": "Geen werk",
  select_category: "Kies een categorie",
  select_type: "Selecteer Type",
  no_options: "Geen opties",
  Specialties: "Specialiteiten",
  specialties_subtitle:
    "Specificeer uw vaardigheden, technologieën, type klantenkring en geografisch gebied. Controleer niet alles...",
  title_fr: "Franse titel",
  title_nl: "Engelse titel",
  title_nl: "Nederlandse titel",
  description_fr: "Franse beschrijving",
  description_nl: "Engelse beschrijving",
  description_nl: "Nederlandse omschrijving",
  article_feed_tooltip: "Selecteer de oorsprong van artikelen om weer te geven",
  scope_tooltip:
    "Elk artikel heeft een bereik-informatie. Filter de artikelen om weer te geven op basis van het bereik",
  relevance_tooltip:
    "Geef alleen artikelen weer met een relevantie van minimaal...",
  manage_account: "Mijn abonnement beheren",
  public_domain: "Publiek domein",
  public_domain_rules:
    "Subdomein moet uniek zijn en mag geen spaties of speciale tekens bevatten",
  suggestion: "Suggestie:",
  invalid_subdomain:
    "Ongeldig subdomein, subdomein mag geen spaties of speciale tekens bevatten. Controleer het voorgestelde subdomein hieronder",
  subdomain_already_exists: "Subdomein bestaat al",
  newsletter_preferences_title: "Selecteer uw nieuwsbriefvoorkeuren",
  plans_newsletter_title: "L'APP' NEWSLETTERS - (In)Train uw klanten",
  articles_option_title: "Artikelfeed",
  articles_option_1: "FFF Nieuws",
  articles_option_2: "Publicaties van mijn bedrijf",
  articles_option_3: "Frequentie van verzending",
  customization_option_title: "Aanpassing",
  customization_option_1: "Mijn bedrijfslogo",
  customization_option_2: "Sjabloonthema",
  basic: "Basis",
  plans_newsletter_1_max_articles_fff: "2 artikelen",
  plans_newsletter_1_max_my_articles: "",
  light_mode: "Lichtmodus",
  edito: "Bewerken",
  plans_newsletter_2_max_articles_fff: "Tot 6 artikelen",
  plans_newsletter_2_max_my_articles: "Maximaal 2 artikelen",
  personalized: "Gepersonaliseerd",
  plans_newsletter_3_max_articles_fff: "Tot 8 artikelen",
  plans_newsletter_3_max_my_articles: "Tot 8 artikelen",
  light_dark_mode: "Lichte of donkere modus",
  maand: "Maand",
  voorgedefinieerd: "voorgedefinieerd",
  by_choice: "uw keuze",
  by_week: "Wekelijks",
  by_two_weeks: "Tweemaandelijks",
  by_month: "Maandelijks",
  monday: "Maandag",
  tuesday: "Dinsdag",
  wednesday: "Woensdag",
  thursday: "Donderdag",
  friday: "Vrijdag",
  saturday: "Zaterdag",
  sunday: "Zondag",
  define_sender: "Kies de afzender",
  program_nl_title: "Uw mailing plannen (Plan ",
  manage_sending_emails:
    "Hier kies je de afzender die je gaat gebruiken om je nieuwsbrief te versturen",
  first_two_weeks: "De eerste twee weken",
  second_two_weeks: "De tweede twee weken",
  first_week: "Week 1",
  second_week: "Week 2",
  third_week: "Week 3",
  fourth_week: "Week 4",
  manage_newsletter_campaign: "Beheer nieuwsbrief campagne",
  plans_newsletter_desc:
    "Ontwikkel met een eenvoudige, doeltreffende toepassing een geïnformeerde relatie met uw klanten dankzij een geautomatiseerde, aanpasbare nieuwsbrieftool. In een paar klikken kunt u nieuwsbrieven van hoge kwaliteit schrijven en uw meest veeleisende klanten op de hoogte houden. Met een snelle en eenvoudige set-up, een effectief validatiesysteem en regelmatige automatische mailings wordt uw bedrijf proactief onder de aandacht gebracht bij uw klanten. Maak optimaal gebruik van fiscale informatie, toon uw kennis en deel deze met uw klanten",
  frequency_desc:
    "Wekelijks, tweewekelijks of maandelijks: selecteer de frequentie waarmee uw nieuwsbrieven automatisch worden verzonden.",
  fff_news_desc:
    "Afhankelijk van de optie die u kiest, kunt u tot 8 relevante artikelen, automatisch of gepersonaliseerd, naar uw doelgroep sturen. Elke nieuwsbrief moet uiteraard worden gevalideerd door de hoofdredacteur (de nieuwsbriefbeheerder van de zaakwaarnemer)",
  my_pub_desc:
    "Maak en deel uw eigen nieuwsbrieven met uw personeel en klanten",
  pub_logo_desc:
    "Toon uw logo op elk van uw nieuwsbrieven om uw expertise onder de aandacht te brengen bij uw klanten en collega's.",
  theme_desc:
    "De kleuren van uw nieuwsbrieven aanpassen en de lezerservaring zo dicht mogelijk bij uw bedrijf laten aansluiten?",
  unique_mode: "Unieke modus",
  customized_mode: "aanpasbare modus",
  week: "Week",
  week_desc_1: "Weken 1 en 3",
  week_desc_2: "Weken 2 en 4",
  newsletter_app_txt:
    "Houd je klanten direct op de hoogte van het meest relevante fiscale nieuws door te profiteren van een bibliotheek met artikelen met hoge toegevoegde waarde. Genereer met één klik op de knop een nieuwsbrief die het imago van uw zaakwaarnemer weerspiegelt en wordt gevoed door de beste experts in de sector.",
  app_newsletter_details_1_title: "2 pakketten beschikbaar",
  app_newsletter_details_1_detail1:
    "Basis: Een nieuwsbrief van 8 automatisch samengestelde artikelen die je met één klik in je eigen tempo verstuurt.",
  app_newsletter_details_1_detail2:
    "Redactioneel: Maak je eigen editorial en selecteer de artikelen die het meest relevant zijn voor je klantenkring",
  app_newsletter_details_1_detail3:
    "Volledig aanpasbaar: Profiteer van een tool voor nieuwsbriefbeheer die je volledig kunt aanpassen en distribueren in je eigen tempo.",
  client_portal: "Klantenportaal",
  configuration: "Configuratie",
  portals: "portalen",
  public_portal: "Portaal openbaar",
  public_portal_txt:
    "Een venster op de wereldexterieur in een enkele klik. U kunt uw aanwezigheid op internet publiceren zonder moeite. Er is een miniconfiguratie, uw fiduciaire bestaat in een look & feel « United Associates » en de klanten die u kent zoek naar informatie over de basis van uw bureau: lokalisatie, actualiteit, uitrusting, heures d'ouverture... L'acces est public, aucune authentication n'est necessaire.",
  collaborator_portal: "Medewerkersportaal",
  collaborator_portal_txt:
    "Dit portaal is altijd geactiveerd. Het stelt je in staat om te werken in een omgeving waar elk van je medewerkers toegang heeft tot de verschillende UA-bronnen en deze deelt. Toegang is mogelijk voor alle medewerkers die zijn ingelogd.",
  portal_collaborator_title: "Welkom bij het samenwerkingsportaal",
  portal_collaborator_txt:
    "Uw medewerkersportal is niet actief. Vraag uw manager om de samenwerkingsportal te activeren",
  program_nl_desc: "Bepaal hier de verzendcriteria voor uw pack",
  choose_date: "Verzenddatum van je nieuwsbrief",
  choose_date_desc: "Stel hier de verzenddatum van je nieuwsbrief in",
  performance_of: "Optreden van",
  hour_abr: "u",
  hours: "uren",
  minutes: "minuten",
  over: "van de",
  no_data_found: "Geen gegevens gevonden",
  lite: "Lite",
  recipient: "ontvanger",
  senders: "afzender",
  wrong_file_format: "Verkeerd bestandsformaat (moet een afbeelding zijn)",
  file_upload_error: "Fout bij uploaden van bestand",
  description_without_title: "Je hebt een beschrijving zonder titel toegevoegd",
  add_at_least_one_title: "Titel vereist",
  add_image: "Vereiste afbeelding",
  edit_service: "Dienst bewerken",
  create_service: "Maak een dienst",
  image_import_text: "Sleep hier een afbeelding of importeer een bestand",
  classic_design: "Klassiek ontwerp",
  customizable_design: "Aanpasbaar ontwerp",
  testimonials: "Getuigenissen",
  add_testimonial: "Voeg een getuigenis toe",
  add_faq: "Veelgestelde vragen toevoegen",
  answer_without_question: "Je hebt een antwoord toegevoegd zonder vraag",
  question_without_answer: "Je hebt een onbeantwoorde vraag toegevoegd",
  question_required: "Verplichte vraag",
  edit_faq: "Veelgestelde vragen bewerken",
  create_faq: "Veelgestelde vragen maken",
  question_fr: "Franse vraag",
  question_en: "Engelse vraag",
  question_nl: "Nederlandse vraag",
  answer_fr: "Frans antwoord",
  answer_en: "Antwoord in het Engels",
  answer_nl: "Nederlands antwoord",
  testimonial_required: "Vereiste getuigenis",
  author_required: "Auteur vereist",
  edit_testimonial: "Bewerk getuigenis",
  create_testimonial: "Maak een getuigenis",
  testimonial_fr: "Franse getuigenis",
  testimonial_en: "getuigenis in het Engels",
  testimonial_nl: "Nederlandse getuigenis",
  author: "Auteur",
  headline: "Titel",
  invitation_successfully: "Uitnodiging geaccepteerd. Log in!",
  invitation_pwd_successfully:
    "Uitnodiging geaccepteerd. Stel een wachtwoord in!",
  invitation_reject: "Uitnodiging afgewezen.",
  collaborator_exist: "Deze bijdrager bestaat in uw organisatie",
  university: "Universiteit",
  role: "Rol",
  students: "Studenten",
  students_list: "Lijst met studenten",
  recipients_desc:
    "Kies zelf het aantal medewerkers en klanten die zullen kunnen profiteren van uw nieuwsbrieven.",
  senders_desc: "Kies wie uw nieuwsbrieven mag versturen.",
  users_imported_with_errors:
    "Sommige opnames zijn succesvol opgeslagen, bij andere is een fout opgetreden. Probeer het nog eens",
  import_students: "Studenten importeren",
  show_more: "Zie meer",
  invitation_confirm: "Bevestiging",
  invitation_confirm_text:
    "Weet je zeker dat je een uitnodiging wilt sturen om een ​​premium te kopen?",
  invitation_sent: "Uitnodiging succesvol verzonden",
  share_all_confirm_text:
    "Weet je zeker dat je de premie met alle medewerkers wilt delen?",
  details_pack_lite:
    "Lite: Stuur één nieuwsbrief per maand naar je klanten. Pakket beperkt tot 25 klanten.",
  details_pack_perso:
    "Volledig gepersonaliseerd: Geef je personeel de kans om in nauw contact te blijven met hun klanten. Stuur elke week een nieuwsbrief met 8 artikelen. Tot 10.000 mailings per week.",
  themes_pages: "Themes & Pages",

  theme_title: "Lijst met thema's en pagina's",
  theme_sub_title: "Thema's en pagina's toevoegen of configureren",
  theme_search_placeholder: "Zoek de thema's",
  page_search_placeholder: "Pagina's zoeken",
  pages: "pages",
  dropzone_text:
    "Sleep bestanden hierheen en zet ze neer, of klik om de bestanden te selecteren die u wilt downloaden.",
  invalid_page_title: "Ongeldige paginatitel",
  invalid_private_groups: "Je moet groepen voor abonnees specificeren",
  errors: "Fouten",
  subscribers_groups: "Abonnee groepen",
  invalid_domain_title: "Ongeldige domeintitel",
  invalid_theme_title: "Ongeldige thematitel",
  invalid_theme_domain: "Selecteer themadomein",
  add_domain: "Een domein toevoegen",
  edit_domain: "Bewerk het domein",
  edit_theme: "Bewerk het thema",
  add_theme: "Voeg een thema toe",
  theme_isdefault: "Standaard thema",
  is_editable: "Is bewerkbaar",
  used: "Gebruikt",
  unused: "Niet gebruikt",
  modal_delete_title: "Bevestiging verwijderen",
  modal_delete_text: "Weet u zeker dat u dit item wilt verwijderen?",
  add_page: "Een pagina toevoegen",
  linked_pages: "Gelinkte pagina's",
  domains: "domeinen",
  domain_title: "Lijst met domeinen",
  domain_sub_title: "Domeinen toevoegen of configureren",
  packs_list: "Lijst met pakketten",
  manage_packs: "Beheer pakketten",
  add_new_pack: "Voeg een nieuw pakket toe",
  formula: "Formule",
  add_app: "Een applicatie toevoegen",
  has_slider: "Heeft een schuifregelaar?",
  slider_min: "Minimale waarde",
  slider_max: "Maximale waarde",
  slider_step: "Waarde verhogen",
  slider_label: "Slidertitel",
  slider_text: "Sliderbeschrijving",
  delegate: "Delegeren",
  delegations: "Delegaties",
  title_delegation: "Delegatiebeheer",
  title_delegation_type: "Delegatietypen",
  delegatie_type: "Delegatietype",
  for_count_clients: "Voor %count% klanten",
  titel: "Titel",
  REQUIRED_FIELD: "Dit veld is verplicht.",
  TOO_SHORT: "Dit veld moet minimaal 2 tekens bevatten.",
  TOO_LONG: "Dit veld mag niet meer dan 10 tekens bevatten.",
  checking_contact_existence:
    "(Het bestaan ​​van een contactpersoon controleren...)",
  add_to_contacts: "Toevoegen aan contacten",
  text_add_contact: "Wilt u deze persoon toevoegen aan de contactlijst ?",
  source: "Source",
  FFF_SITE: "Forum for the future Website",
  SITE_ART_CONSULT: "Art Consultant Website",
  SITE_COMPTA_MEDECIN: "Comptabilité Médecin Website",
  SITE_COMPTA_CONSULTANT: "Comptabilité Consultant Website",
  SITE_COMPTA_VETERINAIRE: "Comptabilité Vétérinaire Website",
  SITE_COMPTA_AVOCAT: "Comptabilité Avocat Website",
  SITE_DEG: "DEG & PARTNERS Website",
  SITE_COMPTA_DENTISTE: "Comptabilité Dentiste Website",
  order_member_design: "Bestel lidontwerp",
  manage_contacts: "Manage contacts",
  contact_added_successfully: "Contact added successfully",
  contact_already_exist: "This contact already exists",
  standard: "Standaard",
  articles: "artikelen",
  paid: "betaald",
  new_sender: "Nieuwe afzender",
  full_name: "Volledige naam",
  add_to_my_list: "Toevoegen aan mijn lijst",
  manage_membership: "Lijst met lidmaatschappen",
  memberships: "Lidmaatschappen",
  upload_max_file_size:
    "Bestandsgrootte overschrijdt de limiet van %maxFileSize%.",
  Mark_as_sender: "Markeren als afzender",
  not_subject_to_vat: "Niet onderworpen aan BTW",
  organization: "Organisatie",
  year: "Jaar",
  request_sent: "Verzoek verzonden",
  request_sent_successfully: "Verzoek succesvol verzonden",
  customization_request: "Aanpassingsverzoek",
  customization_request_confirm_title: "Aanpassingsverzoek voor domeinnaam",
  customization_request_confirm_text:
    "Wilt u een personalisatieverzoek indienen?",
  or: "Of",
  save_priority: "Sla de nieuwe bestelling op",
  logged_as: "Inloggen als",
  deleted: "Verwijderd",
  add_note: "Voeg een notitie toe",
  theme_ismanual: "Is een handleiding",
  unsubscribe: "Uitschrijven",
  confirm_unsubscribe_msg: "Bevestig dat u uw e-mailadres",
  from_future_mails_from: "wilt afmelden voor toekomstige e-mails van",
  unsubscribed_msg: "Je bent uitgeschreven !",
  warning_unsubscribe_msg: "U zult geen e-mails meer ontvangen van",
  unsubscribed_by_accident: ", per ongeluk uitgeschreven ?",
  subscribed_msg: "aanmelden",
  unsubscribe_from_ua: "Afmelden voor United Associate",

};
export default faqNl;
