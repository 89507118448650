import React from "react";

const IconUpload = ({ size }) => {
  return (
    <svg
      width={size}
      heigh={size}
      viewBox="0 0 51 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_17186_5817)">
        <path
          d="M40.0082 19.2282C39.9029 11.5353 33.6121 5.30908 25.8945 5.30908C22.4615 5.30908 19.1541 6.55791 16.5817 8.82556C14.2859 10.8493 12.7154 13.5537 12.0934 16.5229C12.048 16.5224 12.003 16.5221 11.958 16.5221C5.36449 16.5221 0 21.8864 0 28.4801C0 35.0737 5.36449 40.4381 11.9581 40.4381H20.3096C20.9037 40.4381 21.3856 39.9564 21.3856 39.362C21.3856 38.7677 20.9037 38.2859 20.3096 38.2859H11.9581C6.55104 38.2859 2.15214 33.887 2.15214 28.4801C2.15214 23.0731 6.55104 18.6742 11.9581 18.6742C12.2462 18.6742 12.5507 18.6897 12.8892 18.7215C13.463 18.7752 13.9781 18.3671 14.0563 17.7955C14.4438 14.9553 15.846 12.343 18.0049 10.4399C20.1839 8.51897 22.9858 7.46113 25.8944 7.46113C32.4909 7.46113 37.8574 12.8277 37.8574 19.4241C37.8574 19.6616 37.8397 19.9064 37.8211 20.1656L37.8131 20.2757C37.7907 20.5923 37.9092 20.9026 38.1371 21.1237C38.3646 21.3447 38.6779 21.4539 38.9941 21.4223C39.2803 21.3934 39.5691 21.3789 39.8523 21.3789C44.5135 21.3789 48.3058 25.1711 48.3058 29.8324C48.3058 34.4935 44.5136 38.2857 39.8523 38.2857H31.0704C30.4762 38.2857 29.9943 38.7675 29.9943 39.3618C29.9943 39.9562 30.4762 40.4379 31.0704 40.4379H39.8523C45.7002 40.4379 50.4579 35.6804 50.4579 29.8324C50.4578 24.0369 45.7846 19.3119 40.0082 19.2282Z"
          fill="white"
        />
        <path
          d="M25.8949 10.3667C21.3953 10.3667 17.5435 13.7293 16.9353 18.1884C16.855 18.7772 17.2673 19.3197 17.856 19.4C17.9054 19.4067 17.9544 19.4101 18.0028 19.4101C18.5325 19.4101 18.9941 19.0188 19.0675 18.4794C19.531 15.0814 22.4661 12.5188 25.8948 12.5188C26.4892 12.5188 26.9709 12.0371 26.9709 11.4428C26.971 10.8485 26.4892 10.3667 25.8949 10.3667Z"
          fill="white"
        />
        <path
          d="M30.788 31.5412L26.8737 28.0446C26.199 27.4415 25.1809 27.4416 24.5059 28.0445L20.5916 31.5413C20.1484 31.9372 20.1101 32.6174 20.506 33.0607C20.9019 33.5038 21.5821 33.5423 22.0254 33.1464L24.6138 30.8342V44.0725C24.6138 44.6669 25.0955 45.1486 25.6898 45.1486C26.2841 45.1486 26.7659 44.6669 26.7659 44.0725V30.834L29.3543 33.1463C29.5595 33.3297 29.8157 33.4198 30.0708 33.4198C30.3667 33.4198 30.6611 33.2987 30.8738 33.0606C31.2695 32.6173 31.2311 31.9372 30.788 31.5412Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_17186_5817">
          <rect width="50.4579" height="50.4579" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconUpload;
