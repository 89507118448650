import axios from "axios";
import $ from "jquery";
import {
  TTP_API_URL,
  CLIENT_CREDENTIAL,
  PUBLIC_CLIENT_CREDENTIAL,
} from "../config";

export const getTTPUser = ({ userId, token }) => {
  const filter = [
    {
      property: "id",
      value: userId,
      operator: "eq",
    },
  ];

  const fields = [
    "*",
    "email",
    "phone",
    "birthday",
    "avatar",
    "description",
    "contactSocialNetworks",
    "cover",
    "url",
    "role",
    "roles",
    "uaRoles",
    "blogRole",
    "communities",
    "userSettings",
    "numeroAgreation",
  ];

  const requestUrl = `${TTP_API_URL}/organization/user`;

  /*let params = {
    access_token: token,
    filter: JSON.stringify(filter),
    fields: fields.join(",")
  };*/

  return $.ajax({
    type: "GET",
    async: false,
    url: requestUrl,
    data: `access_token=${token}&fields=${fields.join(
      ","
    )}&filter=${JSON.stringify(filter)}&workspace=ua`,
  });

  /*return axios.get(requestUrl, {
    //cancelToken: getLatestArticlesSourceToken.token,
    params
  });*/
};

export const getPublicClientCredential = () => {
  const requestUrl = `${TTP_API_URL}/token`;

  return $.ajax({
    type: "POST",
    async: false,
    url: requestUrl,
    data: PUBLIC_CLIENT_CREDENTIAL,
  });
};

export const getClientCredential = () => {
  const requestUrl = `${TTP_API_URL}/token`;

  return $.ajax({
    type: "POST",
    async: false,
    url: requestUrl,
    data: CLIENT_CREDENTIAL,
  });
};

export const postUserCredential = (data) => {
  const requestUrl = `${TTP_API_URL}/token`;

  var formData = new FormData();
  formData.append("username", data.email);
  formData.append("password", data.password);
  formData.append("grant_type", "password");
  formData.append("scope", "ttp");
  formData.append("client_id", CLIENT_CREDENTIAL.client_id);
  formData.append("client_secret", CLIENT_CREDENTIAL.client_secret);

  return axios.post(requestUrl, formData);
};

export const postUserEmail = (token, email, language) => {
  const requestUrl = `${TTP_API_URL}/profile/email/ua-add`;

  var formData = new FormData();
  formData.append("email", email);
  formData.append("language", language);
  formData.append("access_token", token);

  return axios.post(requestUrl, formData);
};

export const getSelectedOrganization = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/user/get-selected-organization`;

  return $.ajax({
    type: "GET",
    async: false,
    url: requestUrl,
    data: `access_token=${token}&fields=${[
      "id",
      "uen",
      "name",
      "url",
      "abbreviation",
    ].join(",")}`,
  });

  /*return axios.get(requestUrl, {
    params: {
      access_token: token,
      fields: ["id", "uen", "name", "url", "abbreviation"].join(",")
    }
  });*/
};

export const postUserLoggedAsCredential = (data) => {
  const requestUrl = `${TTP_API_URL}/token`;

  var formData = new FormData();
  formData.append("username", data.email);
  formData.append("loggedAsEmail", data.loggedAsEmail);
  formData.append("code", data.code);
  formData.append("grant_type", "password");
  formData.append("grant_access_type", "logged_as");
  formData.append("scope", "ttp");
  formData.append("client_id", CLIENT_CREDENTIAL.client_id);
  formData.append("client_secret", CLIENT_CREDENTIAL.client_secret);

  return axios.post(requestUrl, formData);
};
