const SECTORS = [
  {
    id: 1,
    title: "AGRICULTURE, SYLVICULTURE ET P\u00caCHE",
  },
  {
    id: 2,
    title: "INDUSTRIES EXTRACTIVES",
  },
  {
    id: 3,
    title: "INDUSTRIE MANUFACTURI\u00c8RE",
  },
  {
    id: 4,
    title:
      "PRODUCTION ET DISTRIBUTION D'\u00c9LECTRICIT\u00c9, DE GAZ, DE VAPEUR ET D'AIR CONDITIONN\u00c9",
  },
  {
    id: 5,
    title:
      "PRODUCTION ET DISTRIBUTION D'EAU, ASSAINISSEMENT, GESTION DES D\u00c9CHETS ET D\u00c9POLLUTION",
  },
  {
    id: 6,
    title: "CONSTRUCTION,",
  },
  {
    id: 7,
    title:
      "COMMERCE DE GROS ET DE DETAIL, R\u00c9PARATION DE VEHICULES AUTOMOBILES ET DE MOTOCYCLES",
  },
  {
    id: 8,
    title: "TRANSPORTS ET ENTREPOSAGE",
  },
  {
    id: 9,
    title: "H\u00c9BERGEMENT ET RESTAURATION",
  },
  {
    id: 10,
    title: "INFORMATION ET COMMUNICATION",
  },
  {
    id: 11,
    title: "ACTIVIT\u00c9S FINANCI\u00c8RES ET D'ASSURANCE,",
  },
  {
    id: 12,
    title: "ACTIVIT\u00c9S IMMOBILI\u00c8RES",
  },
  {
    id: 13,
    title:
      "ACTIVIT\u00c9S SP\u00c9CIALIS\u00c9ES, SCIENTIFIQUES ET TECHNIQUES ",
  },
  {
    id: 14,
    title: "ACTIVIT\u00c9S DE SERVICES ADMINISTRATIFS ET DE SOUTIEN  ",
  },
  {
    id: 15,
    title: "ADMINISTRATION PUBLIQUE ET DEFENSE, SECURIE SOCIALE OBLIGATOIRE",
  },
  {
    id: 16,
    title: "ENSEIGNEMENT",
  },
  {
    id: 17,
    title: "SANT\u00c9 HUMAINE ET ACTION SOCIALE",
  },
  {
    id: 18,
    title: "ARTS, SPECTACLES ET ACTIVIT\u00c9S R\u00c9CR\u00c9ATIVES  ",
  },
  {
    id: 19,
    title: "AUTRES ACTIVIT\u00c9S DE SERVICES",
  },
  {
    id: 20,
    title:
      "ACTIVIT\u00c9S DES M\u00c9NAGES EN TANT QU'EMPLOYEURS, ACTIVIT\u00c9S INDIFF\u00c9RENCI\u00c9ES DES M\u00c9NAGES EN TANT QUE PRODUCTEURS DE BIENS ET SERVICES POUR USAGE PROPRE  ",
  },
  {
    id: 21,
    title: "ACTIVIT\u00c9S DES ORGANISMES EXTRA-TERRITORIAUX",
  },
  {
    id: 22,
    title: "Culture et production animale, chasse et services annexes",
    parent: 1,
  },
  {
    id: 23,
    title: "Sylviculture et exploitation foresti\u00e8re",
    parent: 1,
  },
  {
    id: 24,
    title: "P\u00eache et aquaculture",
    parent: 1,
  },
  {
    id: 25,
    title: "Extraction de houille et de lignite",
    parent: 2,
  },
  {
    id: 26,
    title: "Extraction d'hydrocarbures",
    parent: 2,
  },
  {
    id: 27,
    title: "Extraction de minerais m\u00e9talliques",
    parent: 2,
  },
  {
    id: 28,
    title: "Autres industries extractives",
    parent: 2,
  },
  {
    id: 29,
    title: "Services de soutien aux industries extractives",
    parent: 2,
  },
  {
    id: 30,
    title: "Industries alimentaires",
    parent: 3,
  },
  {
    id: 31,
    title: "Fabrication de boissons",
    parent: 3,
  },
  {
    id: 32,
    title: "Fabrication de produits \u00e0 base de tabac",
    parent: 3,
  },
  {
    id: 33,
    title: "Fabrication de textiles",
    parent: 3,
  },
  {
    id: 34,
    title: "Industrie de l'habillement",
    parent: 3,
  },
  {
    id: 35,
    title: "Industrie du cuir et de la chaussure",
    parent: 3,
  },
  {
    id: 36,
    title:
      "Travail du bois et fabrication d'articles en bois et en li\u00e8ge, \u00e0 l'exception des meubles; fabrication d'articles en vannerie et sparterie",
    parent: 3,
  },
  {
    id: 37,
    title: "Industrie du papier et du carton",
    parent: 3,
  },
  {
    id: 38,
    title: "Imprimerie et reproduction d'enregistrements",
    parent: 3,
  },
  {
    id: 39,
    title: "Cok\u00e9faction et raffinage",
    parent: 3,
  },
  {
    id: 40,
    title: "Industrie chimique",
    parent: 3,
  },
  {
    id: 41,
    title: "Industrie pharmaceutique",
    parent: 3,
  },
  {
    id: 42,
    title: "Fabrication de produits en caoutchouc et en plastique",
    parent: 3,
  },
  {
    id: 43,
    title: "Fabrication d'autres produits min\u00e9raux non m\u00e9talliques",
    parent: 3,
  },
  {
    id: 44,
    title: "M\u00e9tallurgie",
    parent: 3,
  },
  {
    id: 45,
    title:
      "Fabrication de produits m\u00e9talliques, \u00e0 l'exception des machines et des \u00e9quipements",
    parent: 3,
  },
  {
    id: 46,
    title:
      "Fabrication de produits informatiques, \u00e9lectroniques et optiques",
    parent: 3,
  },
  {
    id: 47,
    title: "Fabrication d'\u00e9quipements \u00e9lectriques",
    parent: 3,
  },
  {
    id: 48,
    title: "Fabrication de machines et d'\u00e9quipements n.c.a.",
    parent: 3,
  },
  {
    id: 49,
    title:
      "Construction et assemblage de v\u00e9hicules automobiles, de remorques et de semi-remorques",
    parent: 3,
  },
  {
    id: 50,
    title: "Fabrication d'autres mat\u00e9riels de transport",
    parent: 3,
  },
  {
    id: 51,
    title: "Fabrication de meubles",
    parent: 3,
  },
  {
    id: 52,
    title: "Autres industries manufacturi\u00e8res",
    parent: 3,
  },
  {
    id: 53,
    title: "R\u00e9paration et installation de machines et d'\u00e9quipements",
    parent: 3,
  },
  {
    id: 54,
    title:
      "Production et distribution d'\u00e9lectricit\u00e9, de gaz, de vapeur et d'air conditionn\u00e9",
    parent: 4,
  },
  {
    id: 55,
    title: "Captage, traitement et distribution d'eau",
    parent: 5,
  },
  {
    id: 56,
    title: "Collecte et traitement des eaux us\u00e9es",
    parent: 5,
  },
  {
    id: 57,
    title:
      "Collecte, traitement et \u00e9limination des d\u00e9chets; r\u00e9cup\u00e9ration",
    parent: 5,
  },
  {
    id: 58,
    title: "D\u00e9pollution et autres services de gestion des d\u00e9chets",
    parent: 5,
  },
  {
    id: 59,
    title: "Construction de b\u00e2timents; promotion immobili\u00e8re",
    parent: 6,
  },
  {
    id: 60,
    title: "G\u00e9nie civil",
    parent: 6,
  },
  {
    id: 61,
    title: "Travaux de construction sp\u00e9cialis\u00e9s",
    parent: 6,
  },
  {
    id: 62,
    title:
      "Commerce de gros et de d\u00e9tail et r\u00e9paration v\u00e9hicules automobiles et de motocycles",
    parent: 7,
  },
  {
    id: 63,
    title:
      "Commerce de gros, \u00e0 l'exception des v\u00e9hicules automobiles et des motocycles",
    parent: 7,
  },
  {
    id: 64,
    title:
      "Commerce de d\u00e9tail, \u00e0 l'exception des v\u00e9hicules automobiles et des motocycles",
    parent: 7,
  },
  {
    id: 65,
    title: "Transports terrestres et transport par conduites",
    parent: 8,
  },
  {
    id: 66,
    title: "Transports par eau",
    parent: 8,
  },
  {
    id: 67,
    title: "Transports a\u00e9riens",
    parent: 8,
  },
  {
    id: 68,
    title: "Entreposage et services auxiliaires des transports",
    parent: 8,
  },
  {
    id: 69,
    title: "Activit\u00e9s de poste et de courrier",
    parent: 8,
  },
  {
    id: 70,
    title: "H\u00e9bergement",
    parent: 9,
  },
  {
    id: 71,
    title: "Restauration",
    parent: 9,
  },
  {
    id: 72,
    title: "\u00c9dition",
    parent: 10,
  },
  {
    id: 73,
    title:
      "Production de films cin\u00e9matographiques, de vid\u00e9o et de programmes de t\u00e9l\u00e9vision; enregistrement sonore et \u00e9dition musicale",
    parent: 10,
  },
  {
    id: 74,
    title:
      "Programmation et diffusion de programmes de radio et de t\u00e9l\u00e9vision",
    parent: 10,
  },
  {
    id: 75,
    title: "T\u00e9l\u00e9communications",
    parent: 10,
  },
  {
    id: 76,
    title: "Services dinformation",
    parent: 10,
  },
  {
    id: 77,
    title:
      "Activit\u00e9s des services financiers, hors assurance et caisses de retraite",
    parent: 11,
  },
  {
    id: 78,
    title:
      "Assurance, r\u00e9assurance et caisses de retraite, \u00e0 l'exclusion des assurances sociales obligatoires",
    parent: 11,
  },
  {
    id: 79,
    title: "Activit\u00e9s auxiliaires de services financiers et d'assurance",
    parent: 11,
  },
  {
    id: 80,
    title: "Activit\u00e9s immobili\u00e8res",
    parent: 12,
  },
  {
    id: 81,
    title: "Activit\u00e9s juridiques et comptables",
    parent: 13,
  },
  {
    id: 82,
    title: "Activit\u00e9s des si\u00e8ges sociaux; conseil de gestion",
    parent: 13,
  },
  {
    id: 83,
    title:
      "Activit\u00e9s d'architecture et d'ing\u00e9nierie; activit\u00e9s de contr\u00f4le et analyses techniques",
    parent: 13,
  },
  {
    id: 84,
    title: "Recherche-d\u00e9veloppement scientifique",
    parent: 13,
  },
  {
    id: 85,
    title: "Publicit\u00e9 et \u00e9tudes de march\u00e9",
    parent: 13,
  },
  {
    id: 86,
    title:
      "Autres activit\u00e9s sp\u00e9cialis\u00e9es, scientifiques et techniques",
    parent: 13,
  },
  {
    id: 87,
    title: "Activit\u00e9s v\u00e9t\u00e9rinaires",
    parent: 13,
  },
  {
    id: 88,
    title: "Activit\u00e9s de location et location-bail",
    parent: 14,
  },
  {
    id: 89,
    title: "Activit\u00e9s li\u00e9es \u00e0 l'emploi",
    parent: 14,
  },
  {
    id: 90,
    title:
      "Activit\u00e9s des agences de voyage, voyagistes, services de r\u00e9servation et activit\u00e9s connexes",
    parent: 14,
  },
  {
    id: 91,
    title: "Enqu\u00eates et s\u00e9curit\u00e9",
    parent: 14,
  },
  {
    id: 92,
    title: "Services relatifs aux b\u00e2timents; am\u00e9nagement paysager",
    parent: 14,
  },
  {
    id: 93,
    title:
      "Services administratifs de bureau et autres activit\u00e9s de soutien aux entreprises",
    parent: 14,
  },
  {
    id: 94,
    title:
      "Administration publique et d\u00e9fense; s\u00e9curit\u00e9 sociale obligatoire",
    parent: 15,
  },
  {
    id: 95,
    title: "Enseignement",
    parent: 16,
  },
  {
    id: 96,
    title: "Activit\u00e9s pour la sant\u00e9 humaine",
    parent: 17,
  },
  {
    id: 97,
    title:
      "Activit\u00e9s m\u00e9dico-sociales et sociales avec h\u00e9bergement",
    parent: 17,
  },
  {
    id: 98,
    title: "Action sociale sans h\u00e9bergement",
    parent: 17,
  },
  {
    id: 99,
    title: "Activit\u00e9s cr\u00e9atives, artistiques et de spectacle",
    parent: 18,
  },
  {
    id: 101,
    title:
      "Biblioth\u00e8ques, archives, mus\u00e9es et autres activit\u00e9s culturelles",
    parent: 18,
  },
  {
    id: 102,
    title: "Organisation de jeux de hasard et d'argent",
    parent: 18,
  },
  {
    id: 103,
    title: "Activit\u00e9s sportives, r\u00e9cr\u00e9atives et de loisirs",
    parent: 18,
  },
  {
    id: 104,
    title: "Activit\u00e9s des organisations associatives",
    parent: 19,
  },
  {
    id: 105,
    title:
      "R\u00e9paration d'ordinateurs et de biens personnels et domestiques",
    parent: 19,
  },
  {
    id: 106,
    title: "Autres services personnels",
    parent: 19,
  },
  {
    id: 107,
    title:
      "Activit\u00e9s des m\u00e9nages en tant qu'employeurs de personnel domestique",
    parent: 20,
  },
  {
    id: 108,
    title:
      "Activit\u00e9s indiff\u00e9renci\u00e9es des m\u00e9nages en tant que producteurs de biens et services pour usage propre",
    parent: 20,
  },
  {
    id: 109,
    title: "Activit\u00e9s des organisations et organismes extraterritoriaux",
    parent: 21,
  },
  {
    id: 110,
    title: "Programmation, conseil et autres activit\u00e9s informatiques",
    parent: 10,
  },
];
export default SECTORS;
