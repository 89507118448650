import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { read, utils } from "xlsx";

import _ from "../../../i18n";
import ClientModal from "./ClientModal";
import styles from "./ClientModal.module.scss";
import listStyles from "./Client.module.scss";
import Button from "../../common/Button";

const ClientImport = (props) => {
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  const [modalOpen, setModalOpen] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) return null;

    const data = await acceptedFiles[0].arrayBuffer();
    const workbook = read(data);
    const ws = workbook.Sheets[workbook.SheetNames[0]];
    setResponseData(
      utils.sheet_to_json(ws, {
        header: 1,
        blankrows: false,
        defval: "",
        raw: false,
      })
    );
    setModalOpen(true);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept:
      "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  return (
    <div className={props.addFormClass ? listStyles.form : ""}>
      <div
        className={`${listStyles.form_content} ${
          props.addFormClass && listStyles.form_content_pad
        } ${isSaving && listStyles.loading}`}
      >
        <div className={styles.titles}>
          <h4>Import (.xls)</h4>
        </div>
        <div {...getRootProps()} className={styles.dropzone}>
          <div>
            <input {...getInputProps()} />
            <i className="icon-ttp-cloud-upload"></i>
            <p>Cliquez ou glisser et déposer votre fichier içi</p>
            <p>Format (csv, xls, xlsx)</p>
          </div>
        </div>
        <div className={styles.prototype}>
          <Link
            to={`/import_proto/Ex_import_folder_${language}.xlsx`}
            target="_blank"
            download
          >
            Télécharger le modèle
          </Link>
        </div>

        <div className={styles.orSep}>
          <span>{_("or")}</span>
        </div>

        <div className={styles.pasteBox} onClick={() => setModalOpen(true)}>
          Collez votre contenu ici ...
        </div>
        {props.setSideBar && (
          <div className={listStyles.left}>
            <Button
              variant="default"
              onClick={() => props.setSideBar("DEFAULT")}
            >
              {_("cancel")}
            </Button>
          </div>
        )}
      </div>
      <ClientModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        responseData={responseData}
        setResponseData={setResponseData}
        afterSave={props.afterSave}
      />
    </div>
  );
};

export default ClientImport;
