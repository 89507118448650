import React, { useEffect, useState } from "react";

import _ from "../../../../i18n";
import styles from "./Stepper.module.scss";

const Stepper = ({ steps, currentStep, setStep, isClickabled }) => {
  const handleClick = (index) => {
    if (!isClickabled) {
      return null;
    }
    setStep(index + 1);
  };
  return (
    <div className={styles.container}>
      <div className="grid-container">
        <ul>
          {steps.map((step, index) => (
            <li
              key={`step${index}`}
              className={`${index + 1 <= currentStep && styles.active} ${
                isClickabled && styles.hover
              }`}
              onClick={(e) => handleClick(index)}
            >
              <span className={styles.icon}>
                {index + 1 < currentStep ? (
                  // <i className="icon-ttp-check"></i>
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="16" cy="16" r="16" fill="#18A0FB" />
                    <g clip-path="url(#clip0_45878_22443)">
                      <path
                        d="M14.5393 21.9999C14.1662 21.9999 13.8134 21.8254 13.5866 21.5261L10.5236 17.4809C10.1252 16.9548 10.2289 16.2056 10.755 15.8071C11.282 15.4083 12.0306 15.5127 12.4292 16.0385L14.4438 18.6987L19.5107 10.5631C19.8596 10.0033 20.5962 9.83209 21.1571 10.1804C21.7173 10.5291 21.8884 11.2663 21.5394 11.8265L15.5538 21.4368C15.3458 21.7709 14.9869 21.9811 14.5938 21.9989C14.5754 21.9996 14.5574 21.9999 14.5393 21.9999Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_45878_22443">
                        <rect
                          width="12"
                          height="12"
                          fill="white"
                          transform="translate(10 10)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  index + 1
                )}
              </span>
              <p>{step}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Stepper;
