import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import styles from "./Settings.module.scss";

const TabLink = () => {
  const navigate = useNavigate();

  // location.pathname

  return (
    <ul className={styles.contentTab}>
      <li
        className={classNames(
          styles.contentTab_item,
          location.pathname === "/settings" && styles.contentTab_item_active
        )}
        onClick={() => navigate("/settings")}
      >
        Mon compte
      </li>
      <li
        className={classNames(
          styles.contentTab_item,
          location.pathname === "/my-packs" && styles.contentTab_item_active
        )}
        onClick={() => navigate("/my-packs")}
      >
        Mes packs
      </li>
    </ul>
  );
};

export default TabLink;
