import React from "react";
import styles from "./EditContainer.module.scss";
import IconEye from "../icons/IconEye";
import IconPen from "../icons/IconPen";
import classNames from "classnames";
import { Reorder, useMotionValue } from "framer-motion";
import { useRaisedShadow } from "../../use-raised-shadow";

const RecorderEditContainer = ({
  children,
  isVisible,
  setVisibility,
  edit,
  key,
  item,
}) => {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  return (
    <Reorder.Item key={key} value={item} style={{ boxShadow, y }}>
      <div className={styles.container}>
        <ul className={styles.actions}>
          {isVisible && (
            <li title="Edit" onClick={() => edit()}>
              <IconPen />
            </li>
          )}
          {setVisibility && (
            <li
              className={!isVisible ? styles.hide : ""}
              title={isVisible ? "Hide" : "Show"}
              onClick={() => setVisibility()}
            >
              <IconEye />
            </li>
          )}
        </ul>
        <div
          className={classNames(styles.content, !isVisible ? styles.hidden : 0)}
        >
          {children}
        </div>
      </div>
    </Reorder.Item>
  );
};

export default RecorderEditContainer;
