import axios from "axios";
import { TTP_API_URL } from "../config";

export const getIdentitiesVerificationStatus = token => {
  const requestUrl = `${TTP_API_URL}/mailing/registered-mails`;

  return axios.get(requestUrl, {
    params: {
      access_token: token,
    },
  });
};

export const registerEmail = (token, email) => {
  const requestUrl = `${TTP_API_URL}/mailing/verify-email`;
  const formData = new FormData();
  formData.append("access_token", token);
  formData.append("email", email);
  return axios.post(requestUrl, formData);
};
