import React from "react";
import styles from "./Clients.module.scss";
import { useSelector } from "react-redux";

const Clients = ({ list, data, version }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {data[titleAttr] ? data[titleAttr] : "Nos partenaire"}
      </div>

      <div className={styles.list}>
        {list.length > 0
          ? list
              .slice(0, version === "v2" ? 2 : list.length)
              .map((img, i) => (
                <div
                  className={styles.item}
                  key={i}
                  style={{ backgroundImage: `url(${img})` }}
                ></div>
              ))
          : [1, 2, 3, 4, 5].map((val, i) => (
              <div
                className={styles.item}
                style={{ backgroundImage: `url(img/no_image.png)` }}
              ></div>
            ))}
      </div>
    </div>
  );
};

export default Clients;
