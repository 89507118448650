import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import AvatarEditor from "react-avatar-editor";

import Loader from "../Loader";
import Button from "../Button";
import _ from "../../../i18n";

import styles from "./UserAvatarModal.module.scss";

class UserAvatarModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userAvatar: null,
      scale: 1,
      rotate: 0,
      isUpdating: false,
    };

    this.fileInput = null;

    this.close = this.close.bind(this);
    this.saveAvatar = this.saveAvatar.bind(this);
    this.handleScale = this.handleScale.bind(this);
    this.handleRotate = this.handleRotate.bind(this);
    this.chooseImage = this.chooseImage.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { avatar, visible } = this.props;
    if (avatar && visible && prevProps.visible !== visible) {
      this.setState({
        userAvatar: avatar,
      });
    }
  }

  close() {
    this.setState({
      userAvatar: null,
      scale: 1,
      rotate: 0,
      isUpdating: false,
    });

    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  chooseImage() {
    this.fileInput && this.fileInput.click();
  }

  handleScale(e) {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  }

  handleRotate(e) {
    const rotate = parseFloat(e.target.value);
    this.setState({ rotate });
  }

  handleFileChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        userAvatar: e.target.files[0],
      });
    }
  }

  saveAvatar() {
    const { onSaveAvatar } = this.props;

    const canvas = this.editor.getImageScaledToCanvas();
    canvas.toBlob((blob) => {
      this.setState({ isUpdating: true });
      onSaveAvatar(blob);
      this.close();
    });
  }

  renderAvatarEditor() {
    const { userAvatar, scale, rotate } = this.state;
    const { uaSettings } = this.props;

    return (
      <div className={styles.canvas}>
        <AvatarEditor
          ref={(editor) => (this.editor = editor)}
          style={{ width: "300px", height: "300px" }}
          image={userAvatar}
          color={[0, 0, 0, 0.6]}
          width={uaSettings ? 198 : 250}
          height={uaSettings ? 100 : 250}
          border={uaSettings ? [51, 100] : 50}
          borderRadius={uaSettings ? 0 : 250}
          crossOrigin="anonymous"
          scale={parseFloat(scale)}
          rotate={rotate}
        />

        <div className={styles.sliders}>
          <Button onClick={this.chooseImage} variant="secondary">
            {_("change_image")}
          </Button>
          <div className={styles.slider}>
            <div className={styles.slider__top}>
              <div className={styles.slider__name}>{_("zoom")}</div>
              <div className="avatar-modal__slider-value">{scale}</div>
            </div>
            <div className={styles.slider__input}>
              <input
                name="scale"
                type="range"
                className="scale-range"
                onChange={this.handleScale}
                value={scale}
                min="0.1"
                max="2"
                step="0.1"
              />
            </div>
          </div>
          <div className={styles.slider}>
            <div className={styles.slider__top}>
              <div className={styles.slider__name}>{_("rotation")}</div>
              <div className="avatar-modal__slider-value">{rotate}</div>
            </div>
            <div className={styles.slider__input}>
              <input
                name="scale"
                type="range"
                className="scale-range"
                onChange={this.handleRotate}
                value={rotate}
                min="-180"
                max="180"
                step="1"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { visible } = this.props;
    const { isUpdating } = this.state;

    return (
      <Modal
        isOpen={visible}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.close}
        className={{
          base: styles.modalContent,
          afterOpen: styles.modalContentAfterOpen,
          beforeClose: styles.modalContentBeforeClose,
        }}
        overlayClassName={styles.modalOverlay}
      >
        <div className={styles.modal}>
          <div className={styles.header}>{_("modal_avatar_title")}</div>
          <div className={styles.close} onClick={this.close}>
            <i className="icon-ttp-close"></i>
          </div>

          <div className={styles.body}>
            <input
              ref={(ref) => (this.fileInput = ref)}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={this.handleFileChange}
            />
            {this.state.userAvatar && this.renderAvatarEditor()}
          </div>

          <div className={styles.controls}>
            <Button
              onClick={() => {
                if (this.props.onCancel) this.props.onCancel();
                this.close();
              }}
              variant="default"
            >
              {_("cancel")}
            </Button>

            {isUpdating ? (
              <Button
                variant="primary"
                style={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
              >
                <Loader
                  style={{
                    height: "10px",
                  }}
                  color={"#fff"}
                />
              </Button>
            ) : (
              <Button onClick={this.saveAvatar} variant="primary">
                {_("save")}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    lng: store.params.lng,
  };
};

export default connect(mapStateToProps)(UserAvatarModal);
