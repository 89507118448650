import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import styles from "./Testimonials.module.scss";
import TestimonialCard from "./TestimonialCard";

const TestimonialsGrid = ({ testimonials, lng }) => {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 450: 1, 750: 2, 1025: 3 }}>
      <Masonry>
        {testimonials.map((testimonial) => (
          <div className={styles.cardCont} key={testimonial.id}>
            <TestimonialCard testimonial={testimonial} lng={lng} />
          </div>
        ))}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default TestimonialsGrid;
