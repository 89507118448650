import { useNavigate } from "react-router-dom";

import styles from "./Welcome.module.scss";
import _ from "../../i18n";

const Welcome = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.banner}>
        <h1 className={styles.title}>
          {_("welcome_title")}
          <span className={styles.title_name}>
            United <span>Associates</span>
          </span>
        </h1>
        <h2 className={styles.subtitle}>{_("home_title")}</h2>
        {/* <p className={styles.free}>{_("free_test_period")}</p> */}
        <button
          style={{ marginTop: "6rem" }}
          className={styles.banner_action}
          onClick={() => navigate("/join")}
        >
          Complétez votre compte{" "}
          <svg
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M-1.94364e-07 9.01304C-8.70198e-08 8.39099 0.50368 7.88672 1.125 7.88672L16.875 7.88672C17.4963 7.88672 18 8.39099 18 9.01305C18 9.6351 17.4963 10.1394 16.875 10.1394L1.125 10.1394C0.503679 10.1394 -3.01709e-07 9.6351 -1.94364e-07 9.01304Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.2006 0.329893C8.63994 -0.109964 9.35225 -0.109964 9.79159 0.329893L17.6666 8.21417C18.1059 8.65402 18.1059 9.36717 17.6666 9.80703L9.79159 17.6913C9.35225 18.1312 8.63994 18.1312 8.2006 17.6913C7.76126 17.2514 7.76126 16.5383 8.2006 16.0984L11.7508 12.544C13.7007 10.5919 13.7007 7.42929 11.7508 5.47714L8.2006 1.92276C7.76126 1.4829 7.76126 0.76975 8.2006 0.329893Z"
              fill="white"
            />
          </svg>
        </button>

        <div className={styles.banner_bottom}>
          <div className={styles.banner_bottom_left}>
            Le savoir et la connaissance sont les richesses de votre professions
            : <span>développez-les !</span>
          </div>
          <div className={styles.banner_bottom_right}>
            United Associates est le seul réseau technologique qui vous permet
            naturellement de partager les connaissances et le savoir au sein de
            votre cabinet, entre confrères, avec vos clients et vos
            fournisseurs.
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
