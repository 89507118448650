import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";
import Select from "react-select";

import { savePackFormula } from "../../../../api";
import _ from "../../../../i18n";
import { SELECT_STYLES } from "../../../../config";

import Loader from "../../../common/Loader";
import Button from "../../../common/Button";

import styles from "./FormulaPopOver.module.scss";

const STATUS_OPTIONS = [
  { value: "ENABLED", label: _("enabled") },
  { value: "DISABLED", label: _("disabled") },
];

const PackPopOver = ({ currentFormula, currentPack, closePopOver, onSave }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const [isSaving, setIsSaving] = useState(false);
  const [status, setStatus] = useState(STATUS_OPTIONS[0]);
  const [name, setName] = useState("");
  const [monthPrice, setMonthPrice] = useState("");
  const [monthMemberPrice, setMonthMemberPrice] = useState("");
  const [annualPrice, setAnnualPrice] = useState("");
  const [annualMemberPrice, setAnnualMemberPrice] = useState("");

  const [monthStepPrice, setMonthStepPrice] = useState("");
  const [monthMemberStepPrice, setMonthMemberStepPrice] = useState("");
  const [annualStepPrice, setAnnualStepPrice] = useState("");
  const [annualMemberStepPrice, setAnnualMemberStepPrice] = useState("");

  useEffect(() => {
    if (currentFormula) {
      setName(currentFormula.name);
      setMonthPrice(String(currentFormula.monthPrice));
      setMonthMemberPrice(String(currentFormula.monthMemberPrice));
      setAnnualPrice(String(currentFormula.annualPrice));
      setAnnualMemberPrice(String(currentFormula.annualMemberPrice));
      setMonthStepPrice(String(currentFormula.monthStepPrice));
      setMonthMemberStepPrice(String(currentFormula.monthMemberStepPrice));
      setAnnualStepPrice(String(currentFormula.annualStepPrice));
      setAnnualMemberStepPrice(String(currentFormula.annualMemberStepPrice));

      setStatus(
        STATUS_OPTIONS.filter(
          (status) => status.value === currentFormula.status
        )[0]
      );
    }
  }, [currentFormula]);

  const validate = () => {
    let errors = [];

    if (!name) {
      errors.push(_("formula_name_required"));
    }

    if (!monthPrice || !monthMemberPrice) {
      errors.push(_("month_price_required"));
    }

    if (!annualPrice || !annualMemberPrice) {
      errors.push(_("annual_price_required"));
    }

    return errors;
  };

  const save = async (e) => {
    let errors = validate();
    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      name,
      monthPrice,
      monthMemberPrice,
      annualPrice,
      annualMemberPrice,
      monthStepPrice,
      monthMemberStepPrice,
      annualStepPrice,
      annualMemberStepPrice,
      uaPack: currentPack.id,
      status: status.value,
    };

    if (currentFormula) {
      data.id = currentFormula.id;
    }

    setIsSaving(true);
    savePackFormula(token, data)
      .then((response) => {
        setIsSaving(false);
        onSave();
        toast.success(_("successfully_saved"));
      })
      .catch((e) => {
        setIsSaving(false);
      });
  };

  const handleClose = () => {
    closePopOver();
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>

        <h3>{currentFormula ? "Modifier" : "Ajouter une formule"}</h3>

        <div className={styles.formRow}>
          <div>
            <label className="ttp-label">{_("name")}</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label className="ttp-label">{_("status")}</label>
            <Select
              styles={SELECT_STYLES}
              options={STATUS_OPTIONS}
              value={status}
              onChange={(e) => setStatus(e)}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div>
            <label className="ttp-label">{_("rate_monthly")}</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={monthPrice}
              onChange={(e) => setMonthPrice(e.target.value)}
            />
          </div>

          <div>
            <label className="ttp-label">Prix par mois pour les membres</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={monthMemberPrice}
              onChange={(e) => setMonthMemberPrice(e.target.value)}
            />
          </div>
        </div>

        <div className={styles.formRow}>
          <div>
            <label className="ttp-label">Prix annuel</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={annualPrice}
              onChange={(e) => setAnnualPrice(e.target.value)}
            />
          </div>

          <div>
            <label className="ttp-label">Prix annuel pour les membres</label>
            <input
              type="text"
              className="ttp-input"
              autocomplete="off"
              value={annualMemberPrice}
              onChange={(e) => setAnnualMemberPrice(e.target.value)}
            />
          </div>
        </div>

        {currentPack.hasSlider === 1 && (
          <>
            <h4 className={styles.slider_price}>Prix par itération slider</h4>
            <div className={styles.formRow}>
              <div>
                <label className="ttp-label">{_("rate_monthly")}</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={monthStepPrice}
                  onChange={(e) => setMonthStepPrice(e.target.value)}
                />
              </div>

              <div>
                <label className="ttp-label">
                  Prix par mois pour les membres
                </label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={monthMemberStepPrice}
                  onChange={(e) => setMonthMemberStepPrice(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.formRow}>
              <div>
                <label className="ttp-label">Prix annuel</label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={annualStepPrice}
                  onChange={(e) => setAnnualStepPrice(e.target.value)}
                />
              </div>

              <div>
                <label className="ttp-label">
                  Prix annuel pour les membres
                </label>
                <input
                  type="text"
                  className="ttp-input"
                  autocomplete="off"
                  value={annualMemberStepPrice}
                  onChange={(e) => setAnnualMemberStepPrice(e.target.value)}
                />
              </div>
            </div>
          </>
        )}

        <div className={styles.actions}>
          {isSaving ? (
            <Button
              variant="primary"
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Loader
                style={{
                  height: "10px",
                }}
                color={"#fff"}
              />
            </Button>
          ) : (
            <Button onClick={save}>
              {currentFormula ? _("update") : _("add")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PackPopOver;
