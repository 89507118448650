import React, { useEffect } from "react";

const ProfileWidget = (props) => {
  useEffect(() => {
    if (
      window.TTPProfileWidget !== undefined &&
      document &&
      document.getElementById("TTP_PROFILE_WIDGET")
    ) {
      window.TTPProfileWidget.mount(
        props,
        document.getElementById("TTP_PROFILE_WIDGET")
      );
    }

    return () => {
      if (
        window.TTPProfileWidget !== undefined &&
        document &&
        document.getElementById("TTP_PROFILE_WIDGET")
      ) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        window.TTPProfileWidget.unmount(
          document.getElementById("TTP_PROFILE_WIDGET")
        );
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="TTP_PROFILE_WIDGET"></div>;
};

export default ProfileWidget;
