import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { useQuery } from "react-query";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Avatar } from "tamtam-components";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import Button from "../../common/Button";
import Loader from "../../common/Loader";

import { SELECT_STYLES, LANGUAGES } from "../../../config";
import { getUsersSettings, saveUsersSettings } from "../../../api";
import _ from "../../../i18n";

// import styles from "./WatchSharing.module.scss";
import styles from "./InstalledApp.module.scss";

const roleOptions = [
  { value: "AUTHOR", label: _("AUTHOR") },
  { value: "AUTHOR-MANDATED", label: _("AUTHOR_MANDATED") },
  { value: "REDACTOR", label: _("REDACTOR") },
  { value: "REDACTOR-MANDATED", label: _("REDACTOR_MANDATED") },
  { value: "CHIEF_EDITOR", label: _("CHIEF_EDITOR") },
];

const BlogAuthorsModal = ({ showModal, closeModal }) => {
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const lng = useSelector((state) => state.params.language);
  const auth = useSelector((state) => state.auth);

  const [currentApp, setCurrentApp] = useState("blog");
  const [isSaving, setIsSaving] = useState(false);
  const [usersData, setUsersData] = useState(null);

  const { token } = auth;

  let { isLoading, data } = useQuery(
    ["getUserRightsData", token, showModal],
    async () => {
      if (token && showModal) {
        try {
          const response = await getUsersSettings({
            token,
            language: lng,
            word: "",
            organizationId: auth.navCommunity.id,
          });

          if (response.data?.data) {
            const tab = {};
            response.data.data.forEach((user) => {
              let tmpRole = null;
              let tmpLng = [];
              if (
                user.blogRoleInOrganization &&
                user.blogRoleInOrganization.length > 0
              ) {
                let role = user.blogRoleInOrganization[0].role;
                if (user.blogRoleInOrganization[0].mandated) {
                  role += "-MANDATED";
                }
                tmpRole = roleOptions.filter((i) => i.value === role);

                if (
                  user.blogRoleInOrganization[0].preferences &&
                  user.blogRoleInOrganization[0].preferences.allowedLanguages
                ) {
                  LANGUAGES.forEach((lng) => {
                    if (
                      user.blogRoleInOrganization[0].preferences.allowedLanguages.includes(
                        lng.value
                      )
                    ) {
                      tmpLng.push(lng);
                    }
                  });
                }
              }
              tab[user.id] = {
                role: tmpRole && tmpRole.length === 1 ? tmpRole[0] : null,
                lng: tmpLng.length > 0 ? tmpLng : null,
              };
            });
            setUsersData(tab);

            return response.data.data;
          } else {
            return [];
          }
        } catch (e) {
          return [];
        }
      }
    },
    { cacheTime: 0 }
  );

  const handleSave = () => {
    let errorLng = false;
    let data = [];
    for (const userId in usersData) {
      if (usersData[userId].role) {
        if (!usersData[userId].lng) {
          errorLng = true;
          break;
        } else {
          let tmpRole = usersData[userId].role.value.split("-");
          let mandated = tmpRole.length === 2 ? true : false;
          let tabLng = usersData[userId].lng.map((lng) => lng.value);

          data.push({
            id: userId,
            role: tmpRole[0],
            mandated: mandated ? 1 : 0,
            allowCreateTags: 0,
            languages: tabLng,
          });
        }
      }
    }

    if (errorLng) {
      toast.error("Veuillez sélectionner les langues autorisées");
      return false;
    }

    setIsSaving(true);
    saveUsersSettings(token, data, auth.navCommunity.id).then((res) => {
      setIsSaving(false);
      toast.success(_("successfully_saved"));
    });
  };

  const handleRoleChange = (e, userId) => {
    const data = { ...usersData };
    data[userId].role = e;
    setUsersData(data);
  };

  const handleLanguageChange = (e, userId) => {
    const data = { ...usersData };
    data[userId].lng = e;
    setUsersData(data);
  };

  return (
    <div>
      <h3>
        Configurez les droits d’utilisateurs liés à l’application : {currentApp}
      </h3>

      {isLoading ? (
        <div style={{ marginTop: "2rem" }}>
          <Skeleton count={4} height={50} />
        </div>
      ) : data && data.length > 0 ? (
        <div className="">
          <div className={`${styles.userRow} ${styles.userRow_header}`}>
            <span className={styles.emptyAvatar}></span>
            <span className={styles.userRow_infos}></span>
            <span className={classNames(styles.roleSelect, styles.alignCenter)}>
              Droit utilisateur
            </span>
            <span className={classNames(styles.alignCenter, styles.lngSelect)}>
              Langues autorisées
            </span>
            <span className={classNames(styles.alignCenter, styles.countArt)}>
              Articles
            </span>
          </div>
          {data.map((user) => (
            <div key={`user-${user.id}`} className={styles.userRow}>
              <Avatar
                avatarUrl={user.avatarUrl ? user.avatarUrl : ""}
                firstName={user.firstName}
                lastName={user.lastName}
                showInfo={false}
              />
              <div className={styles.userRow_infos}>
                <span>{user.firstName + " " + user.lastName}</span>
                <span>{user.mainEmail}</span>
              </div>
              <Select
                styles={SELECT_STYLES}
                options={roleOptions}
                className={styles.roleSelect}
                isSearchable={false}
                isClearable={true}
                value={usersData[user.id] ? usersData[user.id].role : null}
                onChange={(e) => handleRoleChange(e, user.id)}
              />
              <Select
                styles={SELECT_STYLES}
                options={LANGUAGES}
                className={styles.lngSelect}
                isMulti
                isSearchable={false}
                isClearable={false}
                value={usersData[user.id] ? usersData[user.id].lng : null}
                onChange={(e) => handleLanguageChange(e, user.id)}
              />
              <span className={styles.countArt}>
                {user.author ? user.author.articleCounter : 0}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div>{_("nothing_found")}</div>
      )}

      <div className={styles.actions2}>
        <Button variant="default" onClick={() => closeModal()}>
          {_("close")}
        </Button>
        {isSaving ? (
          <Button
            variant="primary"
            style={{ paddingTop: "15px", paddingBottom: "15px" }}
          >
            <Loader
              style={{
                height: "10px",
              }}
              color={"#fff"}
            />
          </Button>
        ) : (
          <Button onClick={handleSave}>{_("save")}</Button>
        )}
      </div>
    </div>
  );
};

export default BlogAuthorsModal;
