import React, { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";

import Button from "../common/Button";
import _ from "../../i18n";
import { emailRejectInvitation } from "../../api";
import { fetchClientToken } from "../../store";

import LeftSide from "./LeftSide";
import styles from "./Login.module.scss";

const RejectInvitation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const auth = useSelector((state) => state.auth);
  const [isRejected, setIsRejected] = useState(false);
  const [uid, setUid] = useState(0);
  const [id, setId] = useState(0);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "uid") {
        setUid(decodeURIComponent(pair[1]));
      } else if (pair[0] === "id") {
        setId(decodeURIComponent(pair[1]));
      }
    }

    if (!auth.clientToken) {
      dispatch(fetchClientToken());
    }
  }, []);

  const handleNoClick = () => {
    setIsRejected(true);
  };

  const handleYesClick = () => {
    if (uid || id) {
      setIsSaving(true);
      emailRejectInvitation(auth.clientToken, uid, id)
        .then((resp) => {
          toast.success(_("invitation_rejected_successfully"));
          navigate("/");
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          )
            toast.error(_(e.response.data.errors[0].message));
          else toast.error(_("error"));
          navigate("/");
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.error(_("error"));
      navigate("/");
    }
  };

  return (
    <div>
      <div className="grid-x">
        <div className={classNames(styles.leftSide, "cell medium-4 small-12")}>
          <LeftSide />
        </div>
        <div className={classNames(styles.rightSide, "cell medium-8 small-12")}>
          {isSaving && <div className="lmask"></div>}
          <div className={styles.content}>
            {isRejected ? (
              <p className={styles.title}>
                Consulter votre email pour accepter l'invitation
              </p>
            ) : (
              <>
                <h1 className={styles.title}>{_("reject_invitation")}</h1>
                <p>Voulez vous vraiment rejeter l'invitation ? </p>
                <div className={styles.actions}>
                  <Button variant="default" onClick={handleNoClick}>
                    No
                  </Button>
                  <Button onClick={handleYesClick}>Yes</Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectInvitation;
