import axios from "axios";

import { TTP_API_URL } from "../config";
import {
  getRequestCancellationToken,
  getRequestConfig,
  generateCancellationTokenSource,
} from "../utils";

let getServicesCTS;

export const saveRate = async (token, data) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-rate`;

  var formData = new FormData();
  formData.append("access_token", token);
  formData.append("type", data.type);
  formData.append("nbFrom", data.nbFrom);
  formData.append("nbTo", data.nbTo);
  formData.append("monthPrice", data.monthPrice);
  formData.append("annualPrice", data.annualPrice);
  formData.append("status", data.status);

  if (data.app) {
    formData.append("app", data.app);
  }

  if (data.appOtpion) {
    formData.append("appOtpion", data.appOtpion);
  }

  if (data.id) {
    formData.append("id", data.id);
  }
  return await axios.post(requestUrl, formData);
};

export const getRates = (token) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-rate`;

  const sort = [
    {
      property: "nbFrom",
      dir: "desc",
    },
  ];

  return axios.get(requestUrl, {
    params: {
      access_token: token,
      sort: JSON.stringify(sort),
      limit: 200,
    },
  });
};

export const deleteRate = (token, id) => {
  const requestUrl = `${TTP_API_URL}/organization/ua-rate/${id}`;
  var formData = new FormData();

  formData.append("access_token", token);
  formData.append("_method", "delete");

  return axios.post(requestUrl, formData);
};
