import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import md5 from "crypto-js/md5";

import styles from "./Payment.module.scss";

import _ from "../../../i18n";
import { addUpgradeOrder } from "../../../api";
import { TTP_PAYMENT_URL } from "../../../config";

import Button from "../../common/Button";

const Upgrade = () => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const language = useSelector((state) => state.params.language);
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [countCollaborators, setCountCollaborators] = useState(0);
  const [hashKey, setHashKey] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "count") {
        setCountCollaborators(parseInt(pair[1]));
      }
      if (pair[0] === "key") {
        setHashKey(pair[1]);
      }
    }
  }, []);

  useEffect(() => {
    if (token && fiduciary && countCollaborators > 0 && hashKey) {
      if (md5(countCollaborators + "UACONTROL").toString() !== hashKey) {
        toast.error("Invalid parameters");
        navigate("/settings");
        return null;
      }
      setIsLoading(true);
      addUpgradeOrder({
        token,
        organizationId: fiduciary.id,
        language,
        countCollaborators,
        period: "YEARLY",
      })
        .then((response) => {
          if (response.data.data) {
            setOrder(response.data.data);
            setShowIframe(true);
            setTimeout(() => setIsLoading(false), 800);
          }
        })
        .catch((e) => {
          setIsLoading(false);
          toast.error(_("error_add_order"));
        });
    }
  }, [token, fiduciary, countCollaborators, hashKey]);

  useEffect(() => {
    window.addEventListener("message", function (e) {
      const eventId = e.data.event;
      if (eventId === "PAYMENT_OK") {
        setShowIframe(false);
      } else if (eventId === "RETRY_PAYMENT") {
        location.reload();
      }
    });
  }, []);

  if (isLoading) {
    return (
      <div className={styles.payment}>
        <div className="lmask"></div>
      </div>
    );
  }

  return (
    <div className={styles.payment}>
      <div className={styles.title}>
        <h4>{_("waiting_for_payment")}</h4>
        <span>{_("waiting_for_payment_subtitle")}</span>
      </div>

      {showIframe && (
        <iframe
          className={styles.iframe}
          src={`${TTP_PAYMENT_URL}/paymentMethode?orderID=${order.id}&token=${auth.token}&embedded=1`}
          title="UA"
        />
      )}

      <div className={styles.actionBar}>
        <Button
          variant="default"
          icon="ttp-double-arrow-left"
          onClick={() => navigate("/settings")}
        >
          {_("cancel").toUpperCase()}
        </Button>

        {/* <Button onClick={() => {}} iconRight="ttp-double-arrow-right">
          {_("continue").toUpperCase()}
        </Button> */}
      </div>
    </div>
  );
};

export default Upgrade;
