import _ from "../../i18n";

import styles from "./Pack.module.scss";

const PriceTag = ({ pack, formula, billingPeriod, offerType = "member" }) => {
  if (!formula) {
    return null;
  }
  const getMonthPrice = () => {
    let monthMemberPrice = formula.monthMemberPrice;
    let monthPrice = formula.monthPrice;
    if (pack.hasSlider === 1) {
      if (pack.sliderValue > pack.sliderMin) {
        const step = (pack.sliderValue - pack.sliderMin) / pack.sliderStep;
        monthMemberPrice += step * formula.monthMemberStepPrice;
        monthPrice += step * formula.monthStepPrice;
      }
    }

    if (offerType === "member") {
      return (
        <>
          {monthMemberPrice === 0 ? (
            <div className={styles.price}>{"Gratuit"}</div>
          ) : (
            <div className={styles.price}>
              {monthMemberPrice}
              <span>€ /mois</span>
            </div>
          )}
          <div className={styles.price_member}>
            <span className={styles.barred}>{monthPrice}</span>
            prix membre
          </div>
        </>
      );
    } else {
      return (
        <>
          {monthPrice === 0 ? (
            <div className={styles.price}>{"Gratuit"}</div>
          ) : (
            <div className={styles.price}>
              {monthPrice}
              <span>€ /mois</span>
            </div>
          )}
        </>
      );
    }
  };

  const getYearPrice = () => {
    let annualMemberPrice = formula.annualMemberPrice;
    let annualPrice = formula.annualPrice;
    if (pack.hasSlider === 1) {
      if (pack.sliderValue > pack.sliderMin) {
        const step = (pack.sliderValue - pack.sliderMin) / pack.sliderStep;
        annualMemberPrice += step * formula.annualMemberStepPrice;
        annualPrice += step * formula.annualStepPrice;
      }
    }
    if (offerType === "member") {
      return (
        <>
          {annualMemberPrice === 0 ? (
            <div className={styles.price}>
              {"Gratuit"}
              <span> pour les membres</span>
            </div>
          ) : (
            <div className={styles.price}>
              {annualMemberPrice}
              <span>€ /an</span>
            </div>
          )}
          <div className={styles.price_member}>
            <span className={styles.barred}>{annualPrice}</span>
            prix membre
          </div>
        </>
      );
    } else {
      return (
        <>
          {annualPrice === 0 ? (
            <div className={styles.price}>
              {"Gratuit"}
              <span> pour les membres</span>
            </div>
          ) : (
            <div className={styles.price}>
              {annualPrice}
              <span>€ /an</span>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.formula_bottom}>
      {billingPeriod === "MONTHLY" ? (
        <div className={`${styles.formula_price} ${styles.formula_price_left}`}>
          <div className={styles.price_box}>{getMonthPrice()}</div>
          <p className={styles.price_help}>Facturé mensuellement</p>
        </div>
      ) : (
        <div className={styles.formula_price}>
          <div className={styles.price_box}>{getYearPrice()}</div>
          <p className={styles.price_help}>Facturé annuellement</p>
        </div>
      )}
    </div>
  );
};

export default PriceTag;
