import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";

import styles from "./Stats.module.scss";

const Stats = ({ list, data, version, image }) => {
  const lng = useSelector((state) => state.params.language);
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;

  return (
    <div
      className={classNames(
        styles.stats_section,
        version === "v2" ? styles.v2 : ""
      )}
    >
      <div
        className={styles.img}
        style={{
          backgroundImage: `url(${
            image ? image.fullMediaUrl : "img/no_image.png"
          })`,
        }}
      ></div>
      <div className={styles.right}>
        <div className={styles.top}>
          <div className={styles.right_title}>{"En quelques chiffres"}</div>
          <div className={styles.title}>
            {data[titleAttr] ? data[titleAttr] : "Diam vel quam"}
          </div>
          <div className={styles.description}>
            {data[descriptionAttr]
              ? data[descriptionAttr]
              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
          </div>
        </div>

        <div className={classNames(styles.list, "grid-x")}>
          {list.length > 0
            ? list
                .slice(0, version === "v2" ? 2 : list.length)
                .map((stat, i) => (
                  <div
                    className={classNames(
                      styles.cardCont,
                      "cell small-12 medium-6"
                    )}
                    key={i}
                  >
                    <div className={styles.card}>
                      <div className={styles.card_title}>{stat.title}</div>
                      <div className={styles.card_description}>
                        {stat.description}
                      </div>
                    </div>
                  </div>
                ))
            : ["Pretium", "Adipiscing", "Sagittis", "Imperdiet"].map(
                (stat, i) => (
                  <div
                    className={classNames(
                      styles.cardCont,
                      "cell small-12 medium-6"
                    )}
                  >
                    <div className={styles.card}>
                      <div className={styles.card_title}>{(i + 1) * 100}+</div>
                      <div className={styles.card_description}>{stat}</div>
                    </div>
                  </div>
                )
              )}
        </div>
      </div>
    </div>
  );
};

export default Stats;
