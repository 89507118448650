import React from "react";
import classNames from "classnames";
import styles from "./Media.module.scss";
import { TTP_API_URL } from "../../../../../../../../../config";

const Media = ({
  media,
  language,
  setMedias,
  medias,
  isUsed,
  setSingleImage,
}) => {
  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;
  return (
    <div
      className={classNames(styles.media)}
      onClick={() => {
        if (setSingleImage) {
          setSingleImage(media);
        }
        if (isUsed) {
          setMedias(medias.filter((item) => item.id !== media.id));
        } else {
          setMedias(
            medias.map((item) => {
              if (item.id === media.id) {
                return { ...item, checked: !media.checked };
              } else {
                return item;
              }
            })
          );
        }
      }}
    >
      {
        <div
          className={styles.mediaContent}
          style={{
            backgroundImage: `url(${
              media.fullMediaUrl
                ? media.fullMediaUrl
                : TTP_API_URL + "/" + media.webPath
            })`,
          }}
        ></div>
      }
      <div className={styles.overlay}>
        <div className={styles.infos}>
          <h3>{media[titleAttr]}</h3>
        </div>
      </div>
    </div>
  );
};

export default Media;
