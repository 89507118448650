import React, { useEffect, useState, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import {
  COUNTRIES,
  LEGAL_FORM,
  TVA_TYPE,
  DATA_BE_ID,
  DATA_BE_KEY,
  LANGUAGES,
} from "../../config";
import Button from "../common/Button";

import FormInput from "../common/FormInput";
import FormSelect from "../common/FormSelect";
import _ from "../../i18n";
import styles from "./CompanyForm.module.scss";
import SECTORS from "../../config/sectors";
import {
  saveFiduciary,
  subscribeFiduciary,
  saveCommunityPortalsSettings,
} from "../../api";
import { setFiduciary, setNavCommunity } from "../../store";
import { sanitizeSubdomain } from "../../utils";

const CompanyForm = ({ setCurrentStep, currentStep }) => {
  const auth = useSelector((state) => state.auth);
  const lng = useSelector((state) => state.params.language);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  const defaultLanguage = LANGUAGES.filter((l) => l.value === lng);

  const [files, setFiles] = useState([]);
  const [id, setId] = useState(null);
  const [logo, setLogo] = useState("");
  const [contactId, setContactId] = useState("");
  const [contactEmailId, setContactEmailId] = useState("");
  const [uen, setUen] = useState("");
  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [url, setUrl] = useState("");
  const [address1, setAddress1] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState({ value: "BE", label: COUNTRIES.BE });
  const [legalForm, setLegalForm] = useState({
    value: "SRL",
    label: LEGAL_FORM.SRL,
  });
  const [tva, setTva] = useState({
    value: "VAT_SYSTEM_VAT",
    label: TVA_TYPE.VAT_SYSTEM_VAT,
  });
  const [languagePreferences, setLanguagePreferences] =
    useState(defaultLanguage);
  const [sector, setSector] = useState({
    value: 81,
    label: SECTORS.filter((s) => s.id === 81)[0].title,
  });

  const [isValidForm, setIsValidForm] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const [uenError, setUenError] = useState("");
  const [nameError, setNameError] = useState("");
  const [address1Error, setAddress1Error] = useState("");
  const [cityError, setCityError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [languagePreferencesError, setLanguagePreferencesError] = useState("");
  const [isDisabed, setIsDisabed] = useState(false);

  const [portals, setPortals] = useState({
    collaborator: {
      enabled: false,
      config: {
        apps: [],
        widgets: [],
      },
    },
    public: {
      enabled: false,
      config: {
        media: { activated: false, list: [] },
        news: {
          titleFr: "",
          descriptionFr: "",
          titleEn: "",
          descriptionEn: "",
          titleNl: "",
          descriptionNl: "",
          activated: false,
          type: "BOTH",
          relevance: 3,
        },
        team: {
          titleFr: "",
          descriptionFr: "",
          titleEn: "",
          descriptionEn: "",
          titleNl: "",
          descriptionNl: "",
          activated: false,
          list: [],
        },
        services: {
          titleFr: "",
          descriptionFr: "",
          titleEn: "",
          descriptionEn: "",
          titleNl: "",
          descriptionNl: "",
          activated: false,
          list: [],
        },
        contact: {
          activated: false,
          email: "",
          phone: "",
          latitude: "50.8503396",
          longitude: "4.3517103",
          schedule: {
            MON: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            TUE: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            WED: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            TUR: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            FRI: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            SAT: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
            SUN: {
              MORNING: { FROM: "", TO: "" },
              AFTERNOON: { FROM: "", TO: "" },
            },
          },
        },
      },
    },
    client: {
      enabled: false,
      config: {
        apps: [],
        widgets: [],
      },
    },
  });

  useEffect(() => {
    handleValidation();
  });

  useEffect(() => {
    if (fiduciary) {
      setId(fiduciary.id);
      setContactId(fiduciary.legalRepresentative.id);
      setContactEmailId(fiduciary.legalRepresentative.email[0].id);
      setUen(fiduciary.uen);
      setName(fiduciary.name);
      setAbbreviation(fiduciary.abbreviation ? fiduciary.abbreviation : "");
      if (fiduciary.website) setUrl(fiduciary.website);
      setAddress1(fiduciary.address1);
      setZipCode(fiduciary.zipCode);
      setCity(fiduciary.city);
      setCountry({
        value: fiduciary.country,
        label: COUNTRIES[fiduciary.country],
      });
      if (fiduciary.legalForm) {
        setLegalForm({
          value: fiduciary.legalForm,
          label: LEGAL_FORM[fiduciary.legalForm],
        });
      }
      if (fiduciary.tva) {
        setTva({ value: fiduciary.tva, label: TVA_TYPE[fiduciary.tva] });
      }
      if (fiduciary.languagePreferences) {
        const lngPref = Array.isArray(fiduciary.languagePreferences)
          ? fiduciary.languagePreferences
          : fiduciary.languagePreferences.split(",");
        if (lngPref.length > 0 && lngPref[0] !== "") {
          setLanguagePreferences(
            lngPref.map((lng) => {
              return { label: _(lng), value: lng };
            })
          );
        }
      }
      setIsAccepted(true);
      setLogo(fiduciary.avatarUrl);
      if (fiduciary.sector && fiduciary.sector.id) {
        setSector({
          value: fiduciary.sector.id,
          label: fiduciary.sector.titleFr,
        });
      }
    }
  }, [fiduciary]);

  const handleCheckboxClick = () => {
    setIsAccepted(!isAccepted);
  };

  const handleValidation = () => {
    let isValid = true;
    const formattedUen = uen.replace(
      /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
      "BE $2.$3.$4"
    );
    if (
      !isAccepted ||
      tva === "" ||
      uen === "" ||
      name === "" ||
      zipCode === "" ||
      country === "" ||
      address1 === "" ||
      languagePreferences.length === 0 ||
      sector.length === 0 ||
      legalForm.length === 0 ||
      tva.length === 0 ||
      !/^[a-zA-Z]{2} (\d{4})[. ]?(\d{3})[. ]?(\d{3})$/.test(formattedUen)
    ) {
      isValid = false;
    }

    setIsValidForm(isValid);
  };

  const handleSave = async (latLng) => {
    const languages = languagePreferences.map((l) => l.value);

    const data = {
      token: auth.token,
      files: files,
      id: id,
      contactId: contactId,
      contactEmailId: contactEmailId,
      name: name,
      uen: uen,
      abbreviation: abbreviation,
      address1: address1,
      zipCode: zipCode,
      city: city,
      url: url,
      country: country ? country.value : "",
      tva: tva ? tva.value : "",
      sector: sector ? sector.value : "",
      legalForm: legalForm ? legalForm.value : "",
      languagePreferences: languages.join(","),
    };

    if (
      id &&
      ((fiduciary.uaMode !== "UNIVERSITY" &&
        fiduciary.onBoardingStep === "PAYMENT") ||
        (fiduciary.onBoardingStep === "CREATED" &&
          fiduciary.uaMode === "UNIVERSITY"))
    ) {
      data.onBoardingStep = "COMPANY";
    }

    setLoader(true);
    saveFiduciary(data)
      .then((resp) => {
        setLoader(false);
        if (resp.data.result === "OK") {
          if (
            id &&
            ((fiduciary.uaMode !== "UNIVERSITY" &&
              fiduciary.onBoardingStep === "PAYMENT") ||
              (fiduciary.onBoardingStep === "CREATED" &&
                fiduciary.uaMode === "UNIVERSITY"))
          ) {
            subscribeFiduciary(auth.token, {
              uen,
              id,
              userId: auth.user.id,
            })
              .then((resp) => {})
              .catch((e) => {});
          }
          if (latLng) {
            try {
              saveCommunityPortalsSettings({
                token: auth.token,
                communityId: id,
                data: {
                  portals: {
                    ...portals,
                    public: {
                      ...portals.public,
                      config: {
                        ...portals.public.config,
                        contact: {
                          ...portals.public.config.contact,
                          latitude: latLng.lat,
                          longitude: latLng.lng,
                        },
                      },
                    },
                  },
                  subdomain: sanitizeSubdomain(name),
                },
              });
            } catch (e) {}
          }
          dispatch(setFiduciary(resp.data.data));
          dispatch(setNavCommunity(resp.data.data));
          toast.success(_("successfully_added"));
          setCurrentStep(currentStep + 1);
        }
      })
      .catch((e) => {
        setLoader(false);
        if (
          e.response &&
          e.response.data &&
          e.response.data.errors &&
          e.response.data.errors.length > 0
        )
          toast.error(_(e.response.data.errors[0].message));
        else toast.error(_("error"));
      });
  };

  const handleSubmit = () => {
    let address = address1 + " " + zipCode + ", " + city + ", ";
    address += country ? country.label : "Belgique";
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => handleSave(latLng))
      .catch((error) => handleSave());
  };

  const handleChange = (e) => {
    let name = e.target.name;
    eval("set" + name.charAt(0).toUpperCase() + name.slice(1))(e.target.value);
    handleValidation();
  };

  const handleUenChange = (e) => {
    setUen(e.target.value);
    setIsDisabed(false);
    handleValidation();
  };

  const handleNameChange = (e) => {
    let message = e.target.value.length === 0 ? _("required_field") : "";
    setNameError(message);
    setName(e.target.value);
    handleValidation();
  };

  const handleAddressChange = (address) => {
    let message = address.length === 0 ? _("required_field") : "";
    setAddress1Error(message);
    setAddress1(address);
    handleValidation();
  };

  const handleUenKeyUp = (e) => {
    let message = "";
    const formattedUen = e.target.value.replace(
      /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
      "BE $2.$3.$4"
    );

    if (!/^[a-zA-Z]{2} (\d{4})[. ]?(\d{3})[. ]?(\d{3})$/.test(formattedUen)) {
      message = _("Invalid numéro (BE XXXX.XXX.XXX)");
    } else {
      message = "";
    }
    if (e.target.value.length === 0) message = _("required_field");
    setUenError(message);
  };

  const requiredField = (e) => {
    let name = e.target.name;
    let message = e.target.value.length === 0 ? _("required_field") : "";
    eval("set" + name.charAt(0).toUpperCase() + name.slice(1) + "Error")(
      message
    );
  };

  const handleLanguageBlur = (e) => {
    if (languagePreferences.length === 0)
      setLanguagePreferencesError(_("required_field"));
  };

  const handleLanguageChange = (option) => {
    setLanguagePreferences(option);
    if (option.length === 0) setLanguagePreferencesError(_("required_field"));
    else setLanguagePreferencesError("");
  };

  const handleBlur = (e) => {
    requiredField(e);
    handleUenKeyUp(e);
    const formattedUen = e.target.value.replace(
      /(BE|be)? ?(\d{4})[. ]?(\d{3})[. ]?(\d{3})/,
      "BE $2.$3.$4"
    );
    if (/^[a-zA-Z]{2} (\d{4})[. ]?(\d{3})[. ]?(\d{3})$/.test(formattedUen)) {
      setUenError("");
      setLoader(true);
      axios
        .get(`https://api.data.be/2.0/companies/${e.target.value}/info`, {
          headers: {
            API_ID: DATA_BE_ID,
            API_KEY: DATA_BE_KEY,
          },
        })
        .then((res) => {
          setLoader(false);
          const data = res.data;
          if (data.valid) {
            setIsDisabed(true);
            setUen(data.vat_formatted);
            setName(data.company_name);
            if (data.website) setUrl(data.website);
            if (data.addresses.length > 0) {
              if (data.addresses[0].full_address)
                setAddress1(data.addresses[0].full_address);
              if (data.addresses[0].zip_code)
                setZipCode(data.addresses[0].zip_code);
              if (data.addresses[0].municipality)
                setCity(data.addresses[0].municipality);
              if (
                data.addresses[0].country_code &&
                COUNTRIES[data.addresses[0].country_code]
              ) {
                setCountry({
                  value: data.addresses[0].country_code,
                  label: COUNTRIES[data.addresses[0].country_code],
                });
              }
            }
          } else {
            setName("");
            setAddress1("");
            setZipCode("");
            setCity("");
            setCountry({ value: "BE", label: COUNTRIES.BE });
          }
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };

  const handleSelect = (address) => {
    setAddress1(address);
    geocodeByAddress(address)
      .then((results) => results[0].address_components)
      .then((addressComponents) => {
        const country = addressComponents.filter((address) =>
          address.types.includes("country")
        );
        const zipCode = addressComponents.filter((address) =>
          address.types.includes("postal_code")
        );
        const city = addressComponents.filter(
          (address) =>
            address.types.includes("political") &&
            address.types.includes("locality")
        );
        if (city.length > 0) setCity(city[0].long_name);
        if (zipCode.length > 0) setZipCode(zipCode[0].long_name);
        if (country.length > 0 && COUNTRIES[country[0].short_name])
          setCountry({
            value: country[0].short_name,
            label: COUNTRIES[country[0].short_name],
          });
      })
      .catch((error) => console.error("Error", error));
  };

  // const onDrop = useCallback((acceptedFiles) => {
  //   // Do something with the files
  // }, []);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
  });

  const bgImg = files.length ? files[0].preview : logo;
  const style = useMemo(
    () => ({
      ...{
        backgroundImage: "URL(" + bgImg + ")",
      },
    }),
    [isDragActive, isDragReject, isDragAccept, bgImg]
  );

  return (
    <div className={`${styles.page} grid-container`}>
      {loader ? <div className="lmask"></div> : null}
      <h1 className={styles.title}>
        Remplissez les données de votre entreprise
      </h1>
      <p className={styles.subTitle}>
        Les données sont pré remplies conformément à votre procédure d’adhésion.
      </p>
      <div className="grid-x grid-margin-x">
        <div className="d-flex-col cell large-3">
          <label className="ttp-label">{_("company_logo")}</label>
          <div className={styles.dropzone} {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <div
              className="icon-picture"
              style={{
                display: files.length > 0 ? "none" : "block",
              }}
            >
              <i className="icon-ttp-picture"></i>
            </div>
          </div>
        </div>
        <div className="cell small-12 large-9 grid-x grid-margin-x">
          <div className="cell small-12 large-4">
            <FormInput
              name="uen"
              required={true}
              placeholder="BE XXXX.XXX.XXX"
              label={_("business_num")}
              info={_("uen_info")}
              error={uenError}
              value={uen}
              disabled="disabled"
              autoComplete="none"
              handleBlur={(e) => handleBlur(e)}
              onKeyUp={(e) => handleUenKeyUp(e)}
              onChange={(e) => handleUenChange(e)}
            ></FormInput>
          </div>

          <div className="cell small-12 large-4">
            <FormInput
              name="name"
              value={name}
              required={true}
              label={_("company_name")}
              error={nameError}
              autoComplete="none"
              disabled={isDisabed}
              handleBlur={(e) => requiredField(e)}
              onChange={(e) => handleNameChange(e)}
            ></FormInput>
          </div>

          <div className="cell small-12 large-4">
            <FormInput
              name="abbreviation"
              value={abbreviation}
              label={_("abbreviation")}
              onChange={(e) => handleChange(e)}
              autoComplete="none"
            ></FormInput>
          </div>
          <div className="cell large-12">
            <FormInput
              name="url"
              value={url}
              label={_("url_link")}
              autoComplete="none"
              onChange={(e) => handleChange(e)}
            ></FormInput>
          </div>
        </div>
      </div>

      <hr className={styles.separator} />

      <div className="grid-x grid-margin-x">
        <div className="cell small-12 large-6">
          <label className="ttp-label">
            {_("address")}
            <span className="star">*</span>
          </label>
          <PlacesAutocomplete
            value={address1}
            onChange={handleAddressChange}
            onSelect={handleSelect}
            searchOptions={{
              componentRestrictions: { country: ["be"] },
            }}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div className="group-input">
                <input
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: "location-search-input",
                  })}
                  autoComplete="none"
                  name="address1"
                  disabled={isDisabed}
                  onBlur={(e) => {
                    requiredField(e);
                  }}
                />
                <span className="ttp-error">{address1Error}</span>
                {suggestions.length > 0 ? (
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            )}
          </PlacesAutocomplete>
        </div>
        <div className="cell small-12 large-6 grid-x grid-margin-x">
          <div className="cell small-12 large-4">
            <FormInput
              name="zipCode"
              value={zipCode}
              label={_("zipCode")}
              required={true}
              error={zipCodeError}
              autoComplete="none"
              disabled={isDisabed}
              handleBlur={(e) => requiredField(e)}
              onChange={(e) => handleChange(e)}
            ></FormInput>
          </div>
          <div className="cell small-12 large-4">
            <FormInput
              name="city"
              value={city}
              label={_("city")}
              required={true}
              error={cityError}
              autoComplete="none"
              disabled={isDisabed}
              handleBlur={(e) => requiredField(e)}
              onChange={(e) => handleChange(e)}
            ></FormInput>
          </div>
          <div className="cell small-12 large-4">
            <FormSelect
              name="country"
              value={country}
              label={_("country")}
              required={true}
              disabled={isDisabed}
              handleChange={(option) => setCountry(option)}
              options={Object.entries(COUNTRIES).map(([k, v]) => {
                return { value: k, label: v };
              })}
            />
          </div>
        </div>
      </div>

      <hr className={styles.separator} />

      <div className="grid-x grid-margin-x">
        <div className="cell small-12 large-3">
          <FormSelect
            name="legalForm"
            value={legalForm}
            label={_("legal_form")}
            required={true}
            handleChange={(option) => setLegalForm(option)}
            options={Object.entries(LEGAL_FORM).map(([k, v]) => {
              return { value: k, label: v };
            })}
          />
        </div>
        <div className="cell small-12 large-3">
          <FormSelect
            name="sector"
            value={sector}
            required={true}
            label={_("activity_area")}
            handleChange={(option) => setSector(option)}
            options={SECTORS.map((sect) => {
              return { value: sect.id, label: sect.title };
            })}
          />
        </div>
        <div className="cell small-12 large-3">
          <FormSelect
            name="tva"
            value={tva}
            label={_("Type de TVA")}
            required={true}
            handleChange={(option) => setTva(option)}
            options={Object.entries(TVA_TYPE).map(([k, v]) => {
              return { value: k, label: v };
            })}
          />
        </div>
        <div className="cell small-12 large-3">
          <FormSelect
            isMulti
            name="languagePreferences"
            value={languagePreferences}
            label={_("Préférences de langues")}
            required={true}
            options={LANGUAGES}
            error={languagePreferencesError}
            handleBlur={handleLanguageBlur}
            handleChange={handleLanguageChange}
          />
        </div>
      </div>
      <div className={styles.actionBar}>
        <div className="grid-container">
          <Button
            variant="default"
            icon="ttp-double-arrow-left"
            onClick={() => setCurrentStep(1)}
          >
            {_("back").toUpperCase()}
          </Button>
          <span></span>
          <div className="d-flex">
            {!id && (
              <label className={styles.checkbox} onClick={handleCheckboxClick}>
                <input
                  type="checkbox"
                  checked={isAccepted}
                  onChange={handleCheckboxClick}
                />
                J'ai lu et j'accepte <span>les conditions d’utilisation</span>
              </label>
            )}
            <Button
              onClick={handleSubmit}
              iconRight="ttp-double-arrow-right"
              disabled={isValidForm ? false : true}
            >
              {_("continue").toUpperCase()}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyForm;
