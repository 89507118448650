import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import styles from "./MediaPopOver.module.scss";
import tabstyles from "../tabs/Portals/Portals.module.scss";
import _ from "../../../i18n";
import Button from "../../common/Button";
import Tabs from "../../common/Tabs";
import AddSingleMedia from "../../common/AddSingleMedia";

const MediaPopOver = ({
  isOpen,
  onClose,
  currentElement,
  languages,
  afterMediaSave,
  setCurrentElement,
}) => {
  const auth = useSelector((state) => state.auth);
  const fiduciary = useSelector((state) => state.folder.fiduciary);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState({ fr: "", en: "", nl: "" });
  const [description, setDescription] = useState({ fr: "", en: "", nl: "" });
  const [errors, setErrors] = useState([]);
  const [addMediaModalOpened, setAddMediaModalOpened] = useState(false);

  useEffect(() => {
    if (currentElement) {
      setImage(currentElement.image);
      setTitle({
        fr: currentElement.titleFr,
        en: currentElement.titleEn,
        nl: currentElement.titleNl,
      });
      setDescription({
        fr: currentElement.descriptionFr,
        en: currentElement.descriptionEn,
        nl: currentElement.descriptionNl,
      });
    }
  }, [currentElement]);

  const resetData = () => {
    setTitle({ fr: "", en: "", nl: "" });
    setDescription({ fr: "", en: "", nl: "" });
    setImage("");
    setErrors([]);
    setCurrentElement(null);
    onClose();
  };

  const validate = () => {
    const err = [];
    languages.forEach((lng) => {
      if (title[lng.value].length === 0 && description[lng.value].length > 0) {
        err.push(_("description_without_title"));
        return;
      }
    });
    if (
      title.fr.length === 0 &&
      title.en.length === 0 &&
      title.nl.length === 0
    ) {
      err.push(_("add_at_least_one_title"));
    }
    if (image.length === 0) {
      err.push(_("add_image"));
    }
    setErrors(err);
    return err.length === 0;
  };

  const save = async () => {
    if (!validate()) {
      return;
    }
    afterMediaSave({
      id: currentElement ? currentElement.id : uuidv4(),
      image,
      titleFr: title.fr,
      titleEn: title.en,
      titleNl: title.nl,
      descriptionFr: description.fr,
      descriptionEn: description.en,
      descriptionNl: description.nl,
      show: true,
    });
    resetData();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => resetData()}
      className={{
        base: styles.modalContent,
        afterOpen: styles.modalContentAfterOpen,
        beforeClose: styles.modalContentBeforeClose,
      }}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.modal}>
        <div className={styles.header}>
          {_(currentElement ? "edit_media" : "create_media")}
        </div>
        <div className={styles.close} onClick={() => resetData()}>
          <i className="icon-ttp-close"></i>
        </div>

        <div className={styles.body}>
          {errors.length > 0 && (
            <ul className={styles.errors}>
              {errors.map((el, i) => (
                <li key={i}>{el}</li>
              ))}
            </ul>
          )}
          <div
            className={styles.dropZone}
            style={{ backgroundImage: `url(${image.fullMediaUrl})` }}
            onClick={() => setAddMediaModalOpened(true)}
          >
            {image.length === 0 && (
              <>
                <div className={styles.text}>
                  <span>{_("image_import_text")}</span>
                </div>
              </>
            )}
          </div>

          <Tabs theme="serviceTheme">
            {languages.map((language) => (
              <div
                key={`langtab${language.value}`}
                label={language.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/img/flags/${language.value}.png`}
              >
                <div className={tabstyles.langTab}>
                  <label className={tabstyles.configLabel}>
                    {_(`title_${language.value}`)}
                  </label>

                  <input
                    className={tabstyles.formInput}
                    value={title[language.value]}
                    onChange={(e) =>
                      setTitle({
                        ...title,
                        [language.value]: e.target.value,
                      })
                    }
                  />
                  <label
                    className={`${tabstyles.configLabel} ${tabstyles.descriptionLabel}`}
                  >
                    {_(`description_${language.value}`)}
                  </label>
                  <textarea
                    rows="4"
                    className={tabstyles.descriptionInput}
                    value={description[language.value]}
                    onChange={(e) =>
                      setDescription({
                        ...description,
                        [language.value]: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            ))}
          </Tabs>
        </div>

        <div className={styles.controls}>
          <Button onClick={() => resetData()} variant="default">
            {_("cancel")}
          </Button>

          <Button variant="primary" onClick={() => save()}>
            {_("save")}
          </Button>
        </div>
        <AddSingleMedia
          isOpen={addMediaModalOpened}
          close={() => {
            setAddMediaModalOpened(false);
          }}
          token={auth.token}
          community={fiduciary}
          setImage={setImage}
        />
      </div>
    </Modal>
  );
};

export default MediaPopOver;
