import classNames from "classnames";
import { useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { getFolderSpecialties, getSpecialties } from "../../api";
import _ from "../../i18n";
import IconArrowDown from "../common/icons/IconArrowDown";
import IconCheck from "../common/icons/IconCheck";
import styles from "./PublicPortal.module.scss";

const colors = [
  { r: 255, g: 250, b: 236, h: "#FFECB9" },
  { r: 241, g: 247, b: 236, h: "#DDEDCF" },
  { r: 242, g: 238, b: 255, h: "#EAE7F9" },
  { r: 248, g: 252, b: 254, h: "#E2EBEF" },
  { r: 255, g: 240, b: 228, h: "#FFE4C3" },
  { r: 236, g: 248, b: 252, h: "#C9EBF5" },
  { r: 255, g: 240, b: 240, h: "#FDDEDE" },
  { r: 240, g: 255, b: 252, h: "#CFEDE6" },
  { r: 238, g: 247, b: 255, h: "#D6E5FB" },
  { r: 255, g: 247, b: 238, h: "#F8E4CC" },
];

const Specialty = ({ community, info, lng }) => {
  const auth = useSelector((state) => state.auth);
  const { token } = auth;

  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const nameAttr = `name${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  const [currentType, setCurrentType] = useState(null);
  const [all, setAll] = useState(true);
  const [onlyFavorites, setOnlyFavorites] = useState(true);
  const [showMoreSpecs, setShowMoreSpecs] = useState([]);

  let { isLoading, data: types } = useQuery(
    ["getSpecialties", token],
    async () => {
      try {
        if (token) {
          const response = await getSpecialties({
            token,
          });
          if (
            response.data.data.filter((el) => el.isActivated).length === 0 ||
            response.data.data.filter((el) => el.specialties).length === 0
          )
            return null;
          return response.data.data.filter((el) => el.specialties);
        }
      } catch (error) {}
    }
  );

  let { isLoading: FSLoading, data: folderData } = useQuery(
    ["folderSpecialties", token, community],
    async () => {
      if (token && community) {
        const response = await getFolderSpecialties({
          token,
          communityId: community.id,
        });
        return response.data.data[0];
      }
    }
  );

  const listSpecs = () => {
    const specs =
      folderData && folderData.specialties
        ? folderData.specialties.split(",").map((el) => parseInt(el))
        : [];
    const favorites =
      folderData && folderData.favorites
        ? folderData.favorites.split(",").map((el) => parseInt(el))
        : [];

    if (all) {
      const allSpecialties = types
        .map((type) => {
          return type.specialties
            ? type.specialties.map((sp) => {
                return {
                  ...sp,
                  type: {
                    id: type.id,
                    hasChoices: type.hasChoices,
                    nameEn: type.nameEn,
                    nameFr: type.nameFr,
                    nameNl: type.nameNl,
                  },
                };
              })
            : [];
        })
        .flat(1);
      if (favorites.length > 0) {
        const sorted = [];

        allSpecialties.forEach((spec) => {
          const subSpecs = spec.specialties
            ? spec.specialties.filter((subSpec) => specs.includes(subSpec.id))
            : [];
          if (subSpecs.length > 0 && !spec.type.hasChoices) {
            sorted.push({
              ...spec,
              hasFavorites:
                subSpecs.filter((el) => favorites.includes(el.id)).length > 0,
              specialties: subSpecs.map((el) => {
                if (favorites.includes(el.id)) {
                  return { ...el, isFavorite: true };
                }
                return { ...el, isFavorite: false };
              }),
            });
          }
          if (spec.type.hasChoices && specs.includes(spec.id)) {
            sorted.push({
              ...spec,
              nameFr: spec.type.nameFr,
              nameEn: spec.type.nameEn,
              nameNl: spec.type.nameNl,
              hasFavorites: true,
              specialties: [{ ...spec, isFavorite: true }],
            });
          }
        });

        return onlyFavorites
          ? sorted.filter((el) => el.hasFavorites)
          : [
              ...sorted.filter((el) => el.hasFavorites),
              ...sorted.filter((el) => !el.hasFavorites),
            ];
      }

      const sorted = [];

      allSpecialties.forEach((spec) => {
        const subSpecs = spec.specialties
          ? spec.specialties.filter((subSpec) => specs.includes(subSpec.id))
          : [];
        if (subSpecs.length > 0 && !spec.type.hasChoices) {
          sorted.push({
            ...spec,
            specialties: subSpecs.map((el) => {
              return { ...el, isFavorite: false };
            }),
          });
        }
        if (spec.type.hasChoices && specs.includes(spec.id)) {
          sorted.push({
            ...spec,
            nameFr: spec.type.nameFr,
            nameEn: spec.type.nameEn,
            nameNl: spec.type.nameNl,
            hasFavorites: true,
            specialties: [{ ...spec, isFavorite: true }],
          });
        }
      });
      return onlyFavorites ? sorted.slice(0, 4) : sorted;
    } else if (currentType) {
      const allSpecialties = currentType.specialties
        ? currentType.specialties.map((sp) => {
            return {
              ...sp,
              type: {
                id: currentType.id,
                hasChoices: currentType.hasChoices,
                nameEn: currentType.nameEn,
                nameFr: currentType.nameFr,
                nameNl: currentType.nameNl,
              },
            };
          })
        : [];

      const hasFavorites =
        allSpecialties
          .map((spec) =>
            spec.specialties
              ? spec.specialties.map((subSpec) => subSpec.id)
              : []
          )
          .flat(1)
          .filter((el) => favorites.includes(el)).length > 0;

      if (favorites.length > 0 && hasFavorites) {
        const sorted = [];

        allSpecialties.forEach((spec) => {
          const subSpecs = spec.specialties
            ? spec.specialties.filter((subSpec) => specs.includes(subSpec.id))
            : [];
          if (subSpecs.length > 0 && !spec.type.hasChoices) {
            sorted.push({
              ...spec,
              hasFavorites:
                subSpecs.filter((el) => favorites.includes(el.id)).length > 0,
              specialties: subSpecs.map((el) => {
                if (favorites.includes(el.id)) {
                  return { ...el, isFavorite: true };
                }
                return { ...el, isFavorite: false };
              }),
            });
          }
          if (spec.type.hasChoices && specs.includes(spec.id)) {
            sorted.push({
              ...spec,
              nameFr: spec.type.nameFr,
              nameEn: spec.type.nameEn,
              nameNl: spec.type.nameNl,
              hasFavorites: true,
              specialties: [{ ...spec, isFavorite: true }],
            });
          }
        });

        return onlyFavorites
          ? sorted.filter((el) => el.hasFavorites)
          : [
              ...sorted.filter((el) => el.hasFavorites),
              ...sorted.filter((el) => !el.hasFavorites),
            ];
      }

      const sorted = [];

      allSpecialties.forEach((spec) => {
        const subSpecs = spec.specialties
          ? spec.specialties.filter((subSpec) => specs.includes(subSpec.id))
          : [];
        if (subSpecs.length > 0 && !spec.type.hasChoices) {
          sorted.push({
            ...spec,
            specialties: subSpecs.map((el) => {
              return { ...el, isFavorite: false };
            }),
          });
        }
        if (spec.type.hasChoices && specs.includes(spec.id)) {
          sorted.push({
            ...spec,
            nameFr: spec.type.nameFr,
            nameEn: spec.type.nameEn,
            nameNl: spec.type.nameNl,
            hasFavorites: true,
            specialties: [{ ...spec, isFavorite: true }],
          });
        }
      });

      return onlyFavorites ? sorted.slice(0, 4) : sorted;
    }
  };

  const getTypes = () => {
    const specs =
      folderData && folderData.specialties
        ? folderData.specialties.split(",").map((el) => parseInt(el))
        : [];

    const filtered = types
      .filter((type) => {
        const hasCheckedSpecs =
          type.specialties
            .map((spec) => [
              spec.id,
              ...(spec.specialties ? spec.specialties.map((el) => el.id) : []),
            ])
            .flat(1)
            .filter((id) => specs.includes(id)).length > 0;

        if (hasCheckedSpecs) return type;
        else return null;
      })
      .filter(Boolean);
    return filtered;
  };

  if (isLoading || FSLoading || !types || types.length === 0 || !folderData) {
    return null;
  }
  return (
    <section id="fiduciary-specialty" className={styles.specialtySection}>
      <h2 className={styles.title}>{info[titleAttr]}</h2>
      <p className={styles.description}>{info[descriptionAttr]}</p>
      <ul className={styles.types}>
        <li
          onClick={() => {
            setAll(true);
            setCurrentType(null);
          }}
          className={all ? styles.activeType : ""}
        >
          {_("all")}
        </li>
        {getTypes().map((type) => (
          <li
            className={
              currentType && currentType.id === type.id ? styles.activeType : ""
            }
            onClick={() => {
              setAll(false);
              setCurrentType(type);
            }}
          >
            {type[nameAttr]}
          </li>
        ))}
      </ul>
      <div className={styles.specsList}>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4 }}>
          <Masonry>
            {listSpecs()
              .map((spec, i) => {
                return {
                  ...spec,
                  color: colors[i % colors.length],
                };
              })
              .map((specialty) => (
                <div
                  className={styles.specialtyCard}
                  style={{
                    backgroundColor: `rgba(${specialty.color.r},${specialty.color.g},${specialty.color.b},0.3)`,
                  }}
                  id={`specialty_${specialty.id}`}
                  onMouseOver={() => {
                    document.getElementById(
                      `specialty_${specialty.id}`
                    ).style.backgroundColor = `rgba(${specialty.color.r},${specialty.color.g},${specialty.color.b},1)`;
                  }}
                  onMouseLeave={() => {
                    document.getElementById(
                      `specialty_${specialty.id}`
                    ).style.backgroundColor = `rgba(${specialty.color.r},${specialty.color.g},${specialty.color.b},0.3)`;
                  }}
                >
                  <div className={styles.iconContainer}>
                    <div
                      className={styles.iconCircle}
                      style={{
                        backgroundColor: specialty.color.h,
                      }}
                    >
                      {specialty.icon && (
                        <i className={`icon webtool-${specialty.icon.label}`} />
                      )}
                    </div>
                  </div>
                  <div className={styles.specTitle}>{specialty[nameAttr]}</div>
                  {specialty.hasFavorites && (
                    <ul className={styles.favoritesList}>
                      {specialty.specialties
                        .filter((el) => el.isFavorite)
                        .map((subSpec) => (
                          <li
                            style={{
                              backgroundColor: specialty.color.h,
                            }}
                          >
                            <span>{subSpec[nameAttr]}</span>
                            <div className={styles.svg}>
                              <IconCheck />
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  {(!specialty.hasFavorites ||
                    showMoreSpecs.includes(specialty.id)) && (
                    <ul className={styles.othersList}>
                      {specialty.specialties
                        .filter((el) => !el.isFavorite)
                        .map((subSpec) => (
                          <li>{subSpec[nameAttr]}</li>
                        ))}
                    </ul>
                  )}

                  {specialty.hasFavorites &&
                    specialty.specialties.filter((el) => !el.isFavorite)
                      .length > 0 && (
                      <div
                        className={classNames(
                          styles.showMoreBtn,
                          showMoreSpecs.includes(specialty.id)
                            ? styles.showMoreBtn_active
                            : ""
                        )}
                        onClick={() =>
                          setShowMoreSpecs(
                            showMoreSpecs.includes(specialty.id)
                              ? showMoreSpecs.filter(
                                  (el) => el !== specialty.id
                                )
                              : [...showMoreSpecs, specialty.id]
                          )
                        }
                      >
                        <span>
                          {showMoreSpecs.includes(specialty.id)
                            ? _("reduce")
                            : "+" +
                              specialty.specialties.filter(
                                (el) => !el.isFavorite
                              ).length +
                              ` ${_("skills")}`}
                        </span>
                        <IconArrowDown />
                      </div>
                    )}
                </div>
              ))}
          </Masonry>
        </ResponsiveMasonry>

        {onlyFavorites && (
          <div className={styles.viewMore}>
            <hr />
            <button
              onClick={() => {
                setOnlyFavorites(false);
              }}
            >
              View More <i className="icon-ttp-chevron-down " />
            </button>
            <hr />
          </div>
        )}
      </div>
    </section>
  );
};

export default Specialty;
