import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import classNames from "classnames";

import _ from "../../../../i18n";
import styles from "./Modal.module.scss";
import clientStyles from "../Client.module.scss";
import { useQuery } from "react-query";
import { ModalConfirm } from "tamtam-components";
import List from "./List";
import {
  deleteContact,
  getContactClient,
  saveClientContact,
} from "../../../../api";
import Loader from "../../../common/Loader";
import ContactSelect from "./ContactSelect";
import ContactForm from "./ContactForm";

const ModalContact = ({ folderIds, folders, refetchClients, closeModal }) => {
  const { token, navCommunity } = useSelector((state) => state.auth);

  const [contact, setContact] = useState(null);
  const [inputSearch, setInputSearch] = useState("");
  const [currentItem, setCurrentItem] = useState(null);
  const [currentDeleteItem, setCurrentDeleteItem] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState("LIST");

  useEffect(() => {
    if (contact && contact.__isNew__) {
      setInputSearch(contact.value);
      setCurrentTab("FORM");
    }
  }, [contact]);

  const handleClose = () => {
    closeModal();
  };

  let { isLoading, data, refetch } = useQuery(
    ["listContactClient", token, folderIds],
    async () => {
      if (token) {
        try {
          const response = await getContactClient(
            token,
            navCommunity.id,
            folderIds
          );
          return response.data;
        } catch (e) {
          return null;
        }
      }
    }
  );

  const handleSave = async (id) => {
    setIsSaving(true);
    saveClientContact({
      token,
      navCommunityId: navCommunity.id,
      contactId: id ?? contact.value,
      folderId: folderIds[0],
    })
      .then((resp) => {
        if (resp.data.result === "OK") {
          toast.success(_("successfully_saved"));
          refetch();
          refetchClients();
        } else {
          toast.error(_("error"));
        }
      })
      .catch((e) => {
        if (e.response?.data?.errors?.message)
          toast.error(_(e.response.data.errors.message));
        else toast.error(_("error"));
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleDeleteAction = async () => {
    if (currentDeleteItem) {
      setIsDeleting(true);
      deleteContact(token, currentDeleteItem.id)
        .then((resp) => {
          refetch();
          refetchClients();
          toast.success(_("successfully_deleted"));
          setIsDeleting(false);
          setIsOpenDeleteModal(false);
        })
        .catch((e) => {
          toast.success(_("error"));
        })
        .finally(() => {
          setIsDeleting(false);
          setIsOpenDeleteModal(false);
          setCurrentDeleteItem(null);
        });
    }
  };

  const getFoldersName = () => {
    return folderIds.length > 1 ? (
      folders
        .filter((d) => folderIds.includes(d.id))
        .map((f) => <li>{f.name}</li>)
    ) : (
      <>
        {_("client")}: {folders[0].name}
      </>
    );
  };

  const renderLinkedContact = () => {
    const currentFolder =
      folderIds.length !== 1
        ? null
        : folders.find((f) => f.id === folderIds[0]);
    if (currentFolder?.legalRepresentative?.id) {
      return (
        <div className={styles["linked-contact"]}>
          <strong>{_("linked_contact")}:</strong>
          {/*`Nom: ${currentFolder.legalRepresentative.lastName} Prénom: ${currentFolder.legalRepresentative.firstName} Email: ${currentFolder.legalRepresentative.mainEmail}`*/}
          <table className={`${clientStyles.table} unstriped`}>
            <thead>
              <tr>
                <th>
                  <span>{_("firstname")}</span>
                </th>
                <th>
                  <span>{_("lastname")}</span>
                </th>
                <th>{_("email")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{currentFolder.legalRepresentative.firstName}</td>
                <td>{currentFolder.legalRepresentative.lastName}</td>
                <td>{currentFolder.legalRepresentative.mainEmail}</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleContactAfterSave = (contact) => {
    if (contact?.id) {
      setContact({
        value: contact.id,
        label: `${contact.firstName} ${contact.lastName}`,
      });
      handleSave(contact.id);
    }
  };

  return (
    <div>
      <div
        className={classNames(styles.popOver, styles.positioning)}
        onClick={(e) => e.stopPropagation()}
      >
        <div onClick={() => handleClose()} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>
        <div className={styles.title}>
          <h3>{_("manage_contacts")}</h3>
          <div className={styles.info}>
            {folderIds.length > 1 ? (
              <>
                {_("for_count_clients").replace("%count%", folderIds.length)}
                <ul>{getFoldersName()}</ul>
              </>
            ) : (
              <span>{getFoldersName()}</span>
            )}
          </div>
        </div>
        {currentTab === "LIST" ? (
          <>
            <div className="d-flex ">
              <div className="ttp-form-group w-100">
                <label className="ttp-label">{_("Contacts")}</label>
                <ContactSelect
                  isMulti={false}
                  selectedCollaborator={contact}
                  onChange={setContact}
                  communityId={navCommunity.id}
                />
              </div>

              <div
                className={classNames(styles.btTab, "ml-2")}
                onClick={() => {
                  if (contact) {
                    if (contact?.__isNew__) {
                      setCurrentTab("FORM");
                    } else {
                      if (!isSaving) handleSave();
                    }
                  }
                }}
              >
                {isSaving ? (
                  <Loader
                    style={{
                      height: "5px",
                    }}
                    color={"#fff"}
                  />
                ) : (
                  <i className="icon-ttp-plus"></i>
                )}
              </div>
            </div>
            {renderLinkedContact()}
            <List
              isLoading={isLoading}
              data={data}
              setCurrentItem={setCurrentItem}
              setCurrentDeleteItem={setCurrentDeleteItem}
              setIsOpenDeleteModal={setIsOpenDeleteModal}
            />
          </>
        ) : (
          <ContactForm
            setCurrentTab={setCurrentTab}
            afterSave={handleContactAfterSave}
          />
        )}
      </div>
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        onCancel={() => {
          setIsOpenDeleteModal(false);
        }}
        onConfirm={() => handleDeleteAction()}
        inProcess={isDeleting}
        title={_("delete_confirm")}
        text={_("text_delete_collaborator")}
        labelNo={_("no")}
        labelYes={_("yes")}
        labelError={_("error")}
      />
    </div>
  );
};

export default ModalContact;
