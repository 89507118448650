import React from "react";

import styles from "./PublicPortal.module.scss";

const LeftBanner = ({ type, title, text, buttons }) => {
  return (
    <div className={styles.stickyBanner}>
      <div className={styles.leftBanner}>
        <h3>{type}</h3>
        <h2>{title}</h2>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default LeftBanner;
