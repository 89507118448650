import React, { useRef } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";

import styles from "./MediaSlider.module.scss";
import _ from "../../../../../../../../i18n";
import LeftArrowIcon from "../icons/LeftArrowIcon";
import RightArrowIcon from "../icons/RightArrowIcon";
import OECCBBIcon from "../icons/OECCBBIcon";
import classNames from "classnames";

const MediaSlider = ({ images }) => {
  const lng = useSelector((state) => state.params.language);
  const slider = useRef();

  let settings = {
    dots: true,
    dotsClass: `${styles.slick_dots}`,
    infinite: true,
    arrows: false,
    speed: 1000,
    autoplay: false,
    // autoplaySpeed: 10000,
    key: 11,
  };

  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;

  const imgDivs =
    images.length > 0 ? (
      images.map((media, i) => (
        <div key={`med-${i}`}>
          <div
            className={styles.media_slide}
            style={{
              backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(${media.image.fullMediaUrl}) `,
            }}
          >
            <div className={styles.title}>{media[titleAttr]}</div>
            <div className={styles.description}>{media[descriptionAttr]}</div>
          </div>
        </div>
      ))
    ) : (
      <div>
        <div
          className={styles.media_slide}
          style={{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(img/no_image.png) `,
          }}
        >
          <div className={styles.title}>
            {"Facilisis volutpat est velit egestas dui"}
          </div>
          <div className={styles.description}>
            {
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Tellus orci ac auctor augue mauris."
            }
          </div>
        </div>
      </div>
    );

  // const imgDivs =  ( <div>
  //      <div
  //        className={styles.media_slide}
  //        style={{backgroundColor: "red"}}
  //       //  style={{
  //       //    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%), url(img/no_image.png) `,
  //       //  }}
  //      >
  //        <div className={styles.title}>{"fqsfdsdqf"}</div>
  //        <div className={styles.description}>{"fsdfsf"}</div>
  //      </div>
  //    </div>);

  return (
    <div className={classNames(styles.media_slider, "portail-slider")}>
      <Slider {...settings} ref={slider}>
        {imgDivs}
      </Slider>

      <div
        className={styles.slider_btn}
        onClick={() => slider?.current?.slickPrev()}
      >
        <LeftArrowIcon />
      </div>
      <div
        className={classNames(styles.slider_btn, styles.right_btn)}
        onClick={() => slider?.current?.slickNext()}
      >
        <RightArrowIcon />
      </div>
      <div className={styles.member_badge}>
        <div className={styles.left}>
          <div className={styles.blue}>{_("member_of")}</div>
          <div className={styles.black}>{_("order_of_experts")}</div>
        </div>
        <OECCBBIcon />
      </div>
    </div>
  );
};

export default MediaSlider;
