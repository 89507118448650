import React, { useState } from "react";

import _ from "../../i18n";

import ConfigTab from "./ConfigTab";
import FiduciariesTab from "./FiduciariesTab";
import styles from "./ManageApps.module.scss";

const ManageApps = (props) => {
  const [currentTab, setCurrentTab] = useState("CONFIGURATION"); // FIDUCIARY
  const [showConfigModal, setShowConfigModal] = useState(false);

  return (
    <div className="grid-container">
      <h1 className={styles.pageTitle}>
        Liste des configurations / fiduciaires
      </h1>
      <p className={styles.pageHelpTitle}>
        Gérer ici les applications des fiduciaires
      </p>

      <div className={styles.header_btns}>
        <button
          className={`${styles.header_btn} ${
            currentTab === "CONFIGURATION" ? styles.header_btn_active : ""
          }`}
          onClick={() => setCurrentTab("CONFIGURATION")}
        >
          Configuration
        </button>
        <button
          className={`${styles.header_btn} ${
            currentTab === "FIDUCIARY" ? styles.header_btn_active : ""
          }`}
          onClick={() => setCurrentTab("FIDUCIARY")}
        >
          Fiduciaires
        </button>
      </div>

      {currentTab === "CONFIGURATION" && (
        <ConfigTab
          showConfigModal={showConfigModal}
          setShowConfigModal={setShowConfigModal}
        />
      )}
      {currentTab === "FIDUCIARY" && <FiduciariesTab />}

      {currentTab === "CONFIGURATION" && (
        <div className={styles.bubbles}>
          <div
            onClick={() => {
              setShowConfigModal(true);
            }}
          >
            <i className="icon-ttp-plus-outline"></i>
            <span style={{ paddingLeft: "1rem" }}>{_("add")}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageApps;
