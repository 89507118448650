import { useSelector } from "react-redux";
import styles from "./styles.module.scss";
import {
  getDescriptionAttr,
  getTitleAttr,
} from "../../../../../../../../utils/others";

const Banner = ({ data }) => {
  const lng = useSelector((state) => state.params.language);
  const titleAttr = `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
  const descriptionAttr = `description${
    lng.charAt(0).toUpperCase() + lng.slice(1)
  }`;
  return (
    <div className={styles.banner}>
      <div className={styles.title}>
        {data && data[titleAttr] ? data[titleAttr] : "Loream title"}
      </div>
      <div className={styles.subtitle}>
        {data && data[descriptionAttr]
          ? data[descriptionAttr]
          : "Loream subtitle"}
      </div>
    </div>
  );
};

export default Banner;
