export const getTitleAttr = (lng) => {
  return `title${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
};

export const getDescriptionAttr = (lng) => {
  return `description${lng.charAt(0).toUpperCase() + lng.slice(1)}`;
};

export const arrayMerge = (mainArray, arrayToAdd) => {
  if (mainArray) {
    for (let element of arrayToAdd) {
      if (!mainArray.includes(element)) {
        mainArray.push(element);
      }
    }
    return mainArray;
  }
  return arrayToAdd;
};
