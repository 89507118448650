import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useQuery } from "react-query";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FooterUA } from "tamtam-components";
import { getPrivacyRubrics } from "../../api";

import _ from "../../i18n";
import { fetchPrivacyRubrics, setPrivacyRubrics } from "../../store";

const Footer = ({ publicPortalSwitch }) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const language = useSelector((state) => state.params.language);
  let fiduciary = useSelector((state) => state.folder.fiduciary);
  const [portalSwitch, setPortalSwitch] = useState(null);
  const { loggedAs, loggedAsPost } = auth;
  const dispatch = useDispatch();
  const privacyRubrics = useSelector((state) => state.privacy.items);

  let { isLoading } = useQuery(["getPrivacyRubrics", auth.token], async () => {
    if (auth.token) {
      try {
        const response = await getPrivacyRubrics({
          token: auth.token,
          communityId: 9,
        });
        if (response.data.data) {
          dispatch(setPrivacyRubrics(response.data.data));
        }
      } catch (error) {}
    }
  });

  useEffect(() => {
    if (publicPortalSwitch) {
      setPortalSwitch(publicPortalSwitch);
    }
  }, [publicPortalSwitch]);

  useEffect(() => {
    if (
      fiduciary &&
      fiduciary.uaStatus === "VALIDATED" &&
      fiduciary.onBoardingStep === "COMPLETE"
    ) {
      let tab = {
        items: [],
      };

      if (loggedAs !== "CLIENT") {
        if (["LEGAL_REPRESENTATIVE", "MANAGER"].includes(loggedAsPost)) {
          tab.items.push({
            key: "SETTINGS",
            label: "Configuration",
            url: "/settings",
          });
          if (
            fiduciary.hubPreferences &&
            fiduciary.hubPreferences.portals?.public.enabled &&
            fiduciary.subdomain
          ) {
            tab.items.push({
              key: "PUBLIC",
              label: "Portail public",
              url: fiduciary.subdomain,
            });
          }
        }
        if (fiduciary.hubPreferences && fiduciary.hubPreferences.portals) {
          if (fiduciary.hubPreferences.portals.collaborator.enabled) {
            tab.items.push({
              key: "COLLABORATOR",
              label: "Portail collaborateur",
              url: "/collaborator",
            });
          }
          if (
            ["LEGAL_REPRESENTATIVE", "MANAGER"].includes(loggedAsPost) &&
            fiduciary.hubPreferences.portals.client?.enabled
          ) {
            tab.items.push({
              key: "CLIENT",
              label: "Portail client",
              url: "/client",
            });
          }
        }
      } else {
        tab.items.push({
          key: "CLIENT",
          label: "Portail client",
          url: "/client",
        });
      }
      setPortalSwitch(tab.items);
    }
  }, [fiduciary]);

  const titleAttr = `title${
    language.charAt(0).toUpperCase() + language.slice(1)
  }`;

  const policy = [];
  const cookies = [];

  let privacyRubs = [];
  if (privacyRubrics.length > 0) {
    if (
      privacyRubrics.filter((el) => el.termsOfUse).length > 0 &&
      privacyRubrics
        .filter((el) => el.termsOfUse)[0]
        .versions.filter((ver) => ver.name === "UNITED_ASSOCIATES").length > 0
    ) {
      policy.push({
        label: _("terms_of_use"),
        url: "/privacy/terms_of_use",
      });
    }

    privacyRubrics.forEach((el) => {
      if (el.isCookie) {
        if (
          el.versions &&
          el.showInFooter &&
          el.versions[0].status === "PUBLISHED"
        ) {
          cookies.push({
            label:
              el.versions[0][titleAttr].length > 25
                ? el.versions[0][titleAttr].substring(0, 25) + "..."
                : el.versions[0][titleAttr],
            url: `/privacy/cookies?rub=${el.id}`,
          });
        } else if (!el.versions) {
          cookies.push({
            label: _("manage_cookies"),
            url: `/privacy/cookies`,
          });
        }
      }
      if (
        el.showInFooter &&
        el.versions[0].status === "PUBLISHED" &&
        !el.isCookie &&
        !el.termsOfUse
      )
        privacyRubs.push({
          label:
            el.versions[0][titleAttr].length > 25
              ? el.versions[0][titleAttr].substring(0, 25) + "..."
              : el.versions[0][titleAttr],
          url: `/privacy?rub=${el.id}`,
        });
    });
  }

  return (
    <FooterUA
      primaryLogo={
        "https://tamtam.s3.eu-west-1.amazonaws.com/cdn/img/logo/ua.png"
      }
      secondaryLogo={auth.navCommunity ? auth.navCommunity.avatarUrl : ""}
      firstList={portalSwitch}
      secondList={privacyRubs.length > 0 ? privacyRubs : null}
      thirdList={cookies.length > 0 ? cookies : null}
      fourthList={policy.length > 0 ? policy : null}
      navigateTo={(url) => {
        if (
          url.includes("privacy") ||
          url.includes("/client") ||
          url.includes("/collaborator") ||
          url.includes("/settings")
        ) {
          navigate(url);
        } else {
          window.location.href = "http://" + url;
        }
      }}
    />
  );
};

export default Footer;
