import React from "react";

const LocationIcon = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M24.0002 4C16.0123 4 9.51367 10.4986 9.51367 18.4864C9.51367 28.3996 22.4776 42.9526 23.0296 43.5672C23.548 44.1446 24.4532 44.1436 24.9708 43.5672C25.5226 42.9526 38.4866 28.3996 38.4866 18.4864C38.4864 10.4986 31.988 4 24.0002 4ZM24.0002 25.775C19.9812 25.775 16.7117 22.5054 16.7117 18.4864C16.7117 14.4675 19.9813 11.198 24.0002 11.198C28.019 11.198 31.2886 14.4676 31.2886 18.4865C31.2886 22.5054 28.019 25.775 24.0002 25.775Z'
        fill='black'
      />
    </svg>
  );
};

export default LocationIcon;
