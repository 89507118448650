import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import classNames from "classnames";
import $ from "jquery";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";

import FormInput from "../common/FormInput";
import Button from "../common/Button";
import Loader from "../common/Loader";
import _ from "../../i18n";

import { getClientCredential } from "../../api";
import { setAuthToken, setAuthTokenUser } from "../../store";

import LeftSide from "../Login/LeftSide";
import styles from "./ExpiredLink.module.scss";

const GENDER_OPTIONS = [
  { value: "MALE", label: _("male") },
  { value: "FEMALE", label: _("female") },
];

const ExpiredLink = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    // for (var i = 0; i < vars.length; i++) {
    //   var pair = vars[i].split("=");
    //   if (pair[0] === "uid") {
    //     setId(decodeURIComponent(pair[1]));
    //   }
    // }

    if (!auth.token) {
      getClientCredential().then((resp) => {
        const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
        const token = helpResp.token.access_token;
        dispatch(setAuthToken(token));
      });
    }
  }, []);

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <LeftSide />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.topButtons}>
            <span>Déjà membre?</span>
            <span
              onClick={() => navigate("/login")}
              className={styles.register}
            >
              S’identifier
            </span>
          </div>

          <div className={styles.content}>
            <h1 className={styles.title}>{_("signin")}</h1>

            <p className={styles.successMsg}>
              <br />

              <span>{_("email_link_expired")}</span>
            </p>

            <div className={styles.actions}>
              <Button onClick={() => navigate("/login")}>{_("signin")}</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpiredLink;
