import React from "react";
import { ReactBingmaps } from "react-bingmaps";

const Map = ({ latitude, longitude }) => {
  return (
    <ReactBingmaps
      bingmapKey="AlfqRT9EH4KtKEzZQp4yhxAIWxiujabG1jpSuUJaQ_GWxN9ONI_MQvd_e2kJpK5H"
      center={[parseFloat(latitude), parseFloat(longitude)]}
      navigationBarMode={"compact"}
      zoom={14}
      mapTypeId={"aerial"}
      mapOptions={{ disableScrollWheelZoom: true }}
      pushPins={[
        {
          location: [parseFloat(latitude), parseFloat(longitude)],
          option: {
            color: "#18A0FB",
            //icon: "/img/pin.png"
            //icon: "https://unpkg.com/leaflet@1.3.3/dist/images/marker-icon-2x.png"
          },
        },
      ]}
    />
  );
};

export default Map;
