import React from "react";
import _ from "../../../i18n";
import styles from "./Button.module.scss";

const StatusButton = ({ status, handleClick }) => {
  const info = {
    PENDING: {
      icon: "loader",
      text: "en attente",
    },
    VALIDATED: {
      icon: "check-circle",
      text: "accepted",
    },
    VALIDATED_EXPRESS: {
      icon: "check-circle",
      text: "en cours",
    },
    CREATED: {
      icon: "check-circle",
      text: "accepted",
    },
    REJECTED: {
      icon: "forbidden",
      text: "rejected",
    },
  };
  return status === "IN_PROGRESS" ? (
    <div className={`${styles["btn-status"]} ${styles[status.toLowerCase()]}`}>
      <div className={styles.front}>{_("in_progress")}</div>
    </div>
  ) : info[status] ? (
    <div className={`${styles["btn-status"]} ${styles[status.toLowerCase()]}`}>
      <div className={styles.front}>
        <i className={`icon-ttp-${info[status].icon}`}></i>
        {_(info[status].text)}
      </div>
      <div className={styles.back} onClick={handleClick}>
        <i className="icon-ttp-send"></i>
        {_("reminder")}
      </div>
    </div>
  ) : (
    _(status.toLowerCase())
  );
};

export default StatusButton;
