import React, { useState } from "react";

import _ from "../../../i18n";

import Stepper from "./Stepper";
import PackForm from "./PackForm";
import styles from "./Sidebar.module.scss";
import FormulaPack from "./FormulaPack";
import AppPack from "./AppPack";
import FormulaAppPack from "./FormulaAppPack";

const Sidebar = ({ isOpened, hideSidebar, currentPack, setCurrentPack }) => {
  const [step, setStep] = useState(1);

  const handleClose = () => {
    hideSidebar();
    setStep(1);
  };

  const handleSavePack = (p) => {
    setCurrentPack(p);
    setStep(2);
  };

  return (
    <>
      <div className={`${styles.mask} ${isOpened ? "" : styles.hide}`}></div>
      <div className={`${styles.sidebar} ${isOpened && styles.display}`}>
        <div onClick={handleClose} className={styles.close}>
          <i className="icon-ttp-close"></i>
        </div>
        <div className={styles.title}>
          {currentPack ? "Pack " + currentPack.name : _("add_new_pack")}
        </div>

        <Stepper
          steps={["Pack", "Formules", "Applications", "Paramétrage"]}
          currentStep={step}
          setStep={setStep}
          isClickabled={currentPack ? true : false}
        />

        <div className={styles.content}>
          {step === 1 && (
            <PackForm
              currentPack={currentPack}
              onCancel={handleClose}
              onSave={handleSavePack}
            />
          )}
          {step === 2 && (
            <FormulaPack
              currentPack={currentPack}
              onBack={() => setStep(1)}
              onSave={() => setStep(3)}
            />
          )}
          {step === 3 && (
            <AppPack
              currentPack={currentPack}
              onBack={() => setStep(2)}
              onNext={() => setStep(4)}
            />
          )}
          {step === 4 && (
            <FormulaAppPack
              currentPack={currentPack}
              onBack={() => setStep(3)}
              onSave={handleClose}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
