import React from "react";

const PhoneIcon = () => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M34 41.9996C30.6926 41.9966 27.4366 41.1792 24.52 39.6196L23.62 39.1196C17.4015 35.7762 12.3034 30.678 8.96 24.4596L8.46 23.5596C6.85964 20.6264 6.01426 17.341 6 13.9996V12.6596C5.99916 11.5935 6.42392 10.5712 7.18 9.81956L10.56 6.43956C10.8883 6.10876 11.3516 5.94932 11.8139 6.00808C12.2762 6.06684 12.685 6.33716 12.92 6.73956L17.42 14.4596C17.8751 15.2453 17.7437 16.2391 17.1 16.8796L13.32 20.6596C13.0061 20.97 12.9329 21.4496 13.14 21.8396L13.84 23.1596C16.3541 27.8164 20.1786 31.634 24.84 34.1396L26.16 34.8596C26.55 35.0666 27.0296 34.9934 27.34 34.6796L31.12 30.8996C31.7604 30.256 32.7542 30.1246 33.54 30.5796L41.26 35.0796C41.6624 35.3146 41.9328 35.7234 41.9914 36.1856C42.0502 36.648 41.8908 37.1114 41.56 37.4396L38.18 40.8196C37.4284 41.5756 36.406 42.0004 35.34 41.9996H34Z'
        fill='black'
      />
    </svg>
  );
};

export default PhoneIcon;
