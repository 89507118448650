import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Button from "../common/Button";
import _ from "../../i18n";

import {
  getClientCredential,
  clientAcceptInvitation,
  clientRejectInvitation,
} from "../../api";
import { setAuthToken } from "../../store";

import LeftSide from "../Login/LeftSide";
import styles from "./Invitation.module.scss";

const Invitation = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState("");
  const [fiduciaryId, setfiduciaryId] = useState(null);
  const [fiduciaryName, setfiduciaryName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    var query = window.location.search.substring(1);
    var vars = query.split("&");

    let fidId = null;
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      if (pair[0] === "uid") {
        setUserId(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "email") {
        setEmail(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "fid") {
        fidId = decodeURIComponent(pair[1]);
        setfiduciaryId(decodeURIComponent(pair[1]));
      }
      if (pair[0] === "name") {
        setfiduciaryName(decodeURIComponent(pair[1]));
      }
    }

    if (!auth.token) {
      getClientCredential().then((resp) => {
        const helpResp = typeof resp === "string" ? JSON.parse(resp) : resp;
        const token = helpResp.token.access_token;
        dispatch(setAuthToken(token));
      });
    }
  }, []);

  const handleAccept = () => {
    if (userId) {
      setIsSaving(true);
      clientAcceptInvitation(auth.token, userId, email, fiduciaryId)
        .then((resp) => {
          if (resp.data.data && resp.data.data.redirectLink) {
            navigate(resp.data.data.redirectLink);
          }
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
          navigate("/");
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.error(_("error"));
      navigate("/");
    }
  };

  const handleReject = () => {
    if (userId) {
      setIsSaving(true);
      clientRejectInvitation(auth.token, userId, email, fiduciaryId)
        .then((resp) => {
          toast.success(_("invitation_reject"));
          setTimeout(() => {
            navigate("/");
          }, 1000);
        })
        .catch((e) => {
          if (
            e.response &&
            e.response.data &&
            e.response.data.errors &&
            e.response.data.errors.length > 0
          ) {
            toast.error(_(e.response.data.errors[0].message));
          } else {
            toast.error(_("error"));
          }
        })
        .finally(() => setIsSaving(false));
    } else {
      toast.error(_("error"));
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.leftSide}>
          <LeftSide />
        </div>
        <div className={styles.rightSide}>
          <div className={styles.topButtons}>
            <span>Déjà membre?</span>
            <span
              onClick={() => navigate("/login")}
              className={styles.register}
            >
              S’identifier
            </span>
          </div>

          <div className={styles.content}>
            <h1 className={styles.title}>Invitation</h1>

            <p>
              Vous êtes invité à rejoindre l'équipe <b>{fiduciaryName}</b> sur
              United Associates en tant que client
            </p>

            <div className={styles.actions}>
              <Button onClick={() => handleReject()} variant="danger">
                Refuser
              </Button>
              <Button onClick={() => handleAccept()}>Accepter</Button>
            </div>

            {isSaving && <div className="lmask"></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitation;
